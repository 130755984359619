import gql from 'graphql-tag';

export default gql`
    query {
        listEntitlements {
            body,
            statusCode      
        }
    }  
`;
