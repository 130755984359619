import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

import image1 from '../../../../../assets/img/plugandplay/how-fb-works-inside/1.png';
import image2 from '../../../../../assets/img/plugandplay/how-fb-works-inside/2.png';
import image3 from '../../../../../assets/img/plugandplay/how-fb-works-inside/3.png';
import image4 from '../../../../../assets/img/plugandplay/how-fb-works-inside/4.png';
import image5 from '../../../../../assets/img/plugandplay/how-fb-works-inside/5.png';
import image6 from '../../../../../assets/img/plugandplay/how-fb-works-inside/6.png';
import image7 from '../../../../../assets/img/plugandplay/how-fb-works-inside/7.png';
import image8 from '../../../../../assets/img/plugandplay/how-fb-works-inside/8.png';
import image9 from '../../../../../assets/img/plugandplay/how-fb-works-inside/9.png';
import image10 from '../../../../../assets/img/plugandplay/how-fb-works-inside/10.png';

const sections = [
    {
        available: true,
        sectionName: 'The Auction Process',
    },
    {
        available: true,
        sectionName: 'Not All Impressions Are Created Equally',
    },
    {
        available: true,
        sectionName: 'The Clustering Process',
    },
    {
        available: true,
        sectionName: 'Implications and Actionable Steps',
    },
];

const title = 'How Facebook Works On The Inside';

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div className="plug-block">  
                        <h2 className="mb-4">{title}</h2>
                            <div className="plug-block-subheading">In today's teaching</div>

                            <ul>
                                <li>Thoughts</li>
                                <li>Actionable steps</li>
                            </ul>
                        </div>

                        <div ref={refs[0]} className="plug-block">
                            <div className="plug-block-subheading">The Auction Process</div>

                            <img src={image1} alt="" />

                            <p>
                                The Facebook advertising platform is an auction process where bidders (advertisers)
                                compete with each other for impressions on the platform. In a closed auction sale
                                bidders are not aware of other bidders' bids.
                            </p>

                            <div style={{ height: 350 }}>
                                <iframe
                                    id="videoPlayer"
                                    src="https://videos.sproutvideo.com/embed/4d9dd7ba1f1de1c0c4/7f4b80dbff653764?type=hd"
                                    frameBorder="0"
                                    allowFullScreen
                                    width="100%"
                                    height="100%"
                                />
                            </div>

                            <p>
                                This is what an auction looks like in real life: different people are bidding,
                                driving the price up. The auction ends when no one else is willing to pay more for
                                the item being auctioned.
                            </p>

                            <p>
                                With Facebook, you’re auctioning - along with Coca Cola, Amazon and even your local
                                pet store - and Facebook is the auctioneer who determines who wins.
                            </p>

                            <p>
                                But what is being auctioned? People's attention: impression. Facebook sells that
                                attention and it is selling it to the highest bidder. This is how CPMs are
                                determined (how much you pay for 1,000 impressions). This can be as low as $1 to as
                                expensive as $70.
                            </p>

                            <img src={image2} alt="" />
                        </div>

                        <div className="plug-block">
                            <div ref={refs[1]} className="plug-block-subheading">
                                Not All Impressions Are Created Equally
                            </div>

                            <p>
                                Let's imagine this scenario, you are a high end maternity store in New York and you
                                have the ability to choose who sees your ad. Would you serve it to single pregnant
                                women living in the Bronx, or wealthy expectant mothers in Central Park South?
                            </p>

                            <p>
                                Now let's take this a little bit further. If you had the ability to ship worldwide
                                you wouldn't pay the same to appear in a developing country where the buying power
                                of the population is not the same.
                            </p>

                            <p>
                                So now we've established that you would pay more for some impressions than others,
                                this translates in an auction process by creating more competition. You were not the
                                first to have this idea! More people want to reach the people with buying power and
                                fewer people want to reach the people with less buying power, this makes reaching
                                the 'better buyers' more expensive to reach and that is why you see different CPMs
                                throughout countries, interests, cities, audiences, etc.
                            </p>

                            <p>
                                Facebook goes a little bit further and classifies those impressions according to the
                                people that will see them and the behavior and actions they're likely to take. This
                                means they charge you differently according to what they think the person behind the
                                screen is likely to do.
                            </p>

                            <p>
                                That is why they created the objectives and when for example you're going for
                                'conversions' you're competing for impressions that are more likely to convert than
                                others on other objectives, but because most advertisers are going for impressions
                                this makes it much more expensive.
                            </p>

                            <p>
                                For instance the same audience if you use a conversion campaign you will get a $12
                                CPM and if you use reach you would get a $3 CPM. But the people are not the same and
                                even if you get four times as much exposure for the same money you will find that
                                with reach you will get little-to-no conversions.
                            </p>
                        </div>

                        <div className="plug-block">
                            <div ref={refs[2]} className="plug-block-subheading">
                                The Clustering Process
                            </div>

                            <p>
                                Now that we understand the auction process, there's an even more important phenomena
                                that occurs on Facebook that is not so clear and has a lot to do with all the work
                                they've made to make sure their platform converts like no other and they're
                                correctly connecting advertisers with users and giving the most accurate match they
                                can.
                            </p>

                            <p>
                                This is why you see those memes that Facebook listens to you or that it knows what
                                you want even before you do: the algorithms have become so good they predict human
                                behavior.
                            </p>

                            <img src={image3} alt="" />
                            <img src={image4} alt="" />

                            <p>
                                And although this is not true, the fact that people feel this way speaks to how good
                                their system is at knowing what you want.
                            </p>

                            <p>
                                Facebook isn't a deterministic system which means that the same inputs will not give
                                the same outputs. The same audience with the same ads will perform drastically
                                differently each time.
                            </p>

                            <p>
                                Another phenomena you'll quickly notice is that no matter how broad you go, you
                                never reach your whole audience and depending what you spend your frequency would be
                                around 1.5, this broadly speaking means that out of two people that see your advert,
                                one of them sees it twice.
                            </p>

                            <p>Why would this happen to your audience when your audience has millions in it?</p>

                            <img src={image5} alt="" />

                            <p>
                                This indicates what we refer to as the clustering process: where Facebook would make
                                subclusters of the audience you selected (no matter how big or small) and would
                                reach them more. This process explains both the non-determinism and the frequency
                                aspect. It also explains why targeting "Broad" or "Lookalike audiences" works. And
                                it is mainly because
                                {' '}
                            </p>

                            <div style={{ height: 350 }}>
                                <iframe
                                    id="videoPlayer"
                                    src="https://videos.sproutvideo.com/embed/4d9dd7ba1f1de5c4c4/da7c64df53c97050?type=hd"
                                    frameBorder="0"
                                    allowFullScreen
                                    width="100%"
                                    height="100%"
                                />
                            </div>

                            <p>
                                Let's examine this video and see a little bit of what is going on. At first they put
                                four squares in random positions, then they measure the distance of each square to
                                each circle and they paint the circle the color of the square it is the closest.
                                After that the squares move to the center of the group that has its color and the
                                process repeats itself until none of the squares moves in an iteration.
                            </p>

                            <p>
                                Now that we've understood this process it will be easier to understand some of what
                                happens behind-the-scenes at Facebook. They have a lot of data and it's all sorted
                                in something similar to those circles. Each circle represents one account, the
                                closer the circle to another, the more they will look alike (Same taste in music,
                                love for dogs, food, places, etc) the further away the less they look alike.
                            </p>

                            <p>Now let's say this is your audience, and each dot is one user.</p>

                            <img src={image6} alt="" />

                            <p>
                                What Facebook will do is try and send an ad randomly to those groups. Each arrow is
                                one of your ads that was shown to the person it is pointing to.
                            </p>

                            <p>
                                As these are different people, not everyone will react the same to your ad. Let's
                                say the cyan ones just skip the ad, the pink one stopped two seconds to read it, the
                                orange one clicked on it and then went back to instagram and the yellow one went in,
                                stayed on the website and bought the product.
                            </p>

                            <img src={image7} alt="" />

                            <p>
                                After seeing this and Facebook knowing that you told them to optimize for
                                conversions, they will start optimizing by narrowing down and doing a smaller
                                cluster, so instead of going for the whole audience again they will try to show the
                                ads to similar people because it is more likely that they will also buy and Facebook
                                will create a second iteration that looks like this:
                            </p>

                            <p>
                                Now they are running your ads to people that are more likely to convert and they've
                                narrowed down to what fits your product.
                            </p>
                            <img src={image8} alt="" />

                            <p>
                                This is why even if you do broad targeting for a female product like swimwear you'll
                                get more women seeing the ads even though you've told Facebook that the gender
                                doesn't matter. It is why for some products we sell, most of the users are android
                                even though most of the users in the country use iPhone. This is a distribution you
                                will never see, but when you think about our target audience for this product it
                                does make sense.
                            </p>

                            <img src={image9} alt="" />

                            <p>Facebook always always has more iPhone impressions.</p>

                            <img src={image10} alt="" />
                        </div>

                        <div className="plug-block">
                            <div ref={refs[3]} className="plug-block-subheading">
                                Implications and Actionable Steps
                            </div>

                            <p>
                                These two concepts have many implications: and understanding them will lead to
                                understanding some of the events below, and how you can avoid them.
                            </p>

                            <p>
                                <b>
                                    1. The same audience will not perform the same each time - and not even close some
                                    times:
                                </b>
                            </p>

                            <p>
                                But you can get it to perform similarly, you just need to find 'the good cluster'
                                for your product again. Let's look at this a little further. Remember that yellow on
                                this example bought and made that cluster 'your' cluster.
                            </p>
                            <img src={image7} alt="" />

                            <p>
                                <b>2. My audience stopped working:</b>
                            </p>

                            <p>
                                It’s not always that the audience stops working: but that your ads have fatigued, or
                                the market has changed. But your audience could also be responsible: your cluster
                                has moved too far away from where it started. To fix this, try creating new ad sets
                                with the same audience.
                            </p>

                            <p>
                                <b>3. This interest should work:</b>
                            </p>

                            <p>
                                If you feel like you have a very good interest in mind but it is not working, keep
                                in mind that you could've just gotten unlucky. Now this is ONLY if you have a tested
                                product that is working on other ad sets, etc. If it is not, the likelihood is that
                                what is not working is the product, the ads (copy and creative) or the funnel.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
