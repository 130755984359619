import React from 'react';
import './style.scss';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';

const sections = [
    {
        available: true, sectionName: 'Things to keep in mind',
    },
    {
        available: true, sectionName: 'Step 1: Set Up ClickFunnels',
    },
    {
        available: true, sectionName: 'Step 2: Download The Templates',
    },
    {
        available: true, sectionName: 'Step 3: Choose Your Template',
    },
    {
        available: true, sectionName: 'Step 4: Customize Your Template',
    },
    {
        available: true, sectionName: 'Step 5: Create Your Custom Thank You Page',
    },
    {
        available: true, sectionName: 'Step 5: Link Domain To ClickFunnels',
    },
    {
        available: true, sectionName: 'Step 6: Connect to Calendly',
    },
    { available: true, sectionName: 'Step 6: Put Pixel On Funnel' },

];

const title = "Website Setup"

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]}  className="plug-block"> 
                            <div className="examples-block">
                                <p className="bold">Things to keep in mind:</p>

                                <ul>
                                    <li>
                                        This entire process should NOT take more than a day.
                                    </li>
                                    <li>
                                        Clients care about results & value conveyed, not your website.
                                    </li>
                                    <li>
                                        Make sure you change the font, logo, services, background video/photo and a few other things about the site to make it original and yours.
                                    </li>
                                </ul>
                            </div>

                            <p>Please remember that you should be focused on signing clients and not stuff like your website. I got to $8,000+ a month with no website and not even an agency name… it shouldn’t be a huge focus of yours. </p>

                            <p>That’s why I built you your own plug n play website ready to use today with just a few tweaks. </p>
                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">
                                Step 1: Set Up ClickFunnels

                            </div>

                            <p>
                                If you haven’t already - Make sure you get a 14 day free trial of ClickFunnels using this link:
                            </p>

                            <a href="https://growyouragency.com/clickfunnelstrail" target="_blank" rel="noopener noreferrer"><p>https://growyouragency.com/clickfunnelstrail</p></a>

                            <p>
                                ClickFunnels is where we host our agency website, on-boarding, scheduling link etc. It’s a key piece of software.
                            </p>
                        </div>

                        <div ref={refs[2]} className="plug-block">
                            <div className="plug-block-subheading">
                                Step 2: Download The Templates

                            </div>

                            <p>
                                To make sure that everyone has its own unique website and that it fits your brand well, we have created 5 completely different professionally designed website templates for you to choose. Inside of each template, we have created 4 variations with different colors. This means you have 20+ website variations you can choose from!
                            </p>

                            <p>
                                This way, your agency can have a professional-looking website without having to hire an expensive designer. Plus, you can easily edit it yourself.
                            </p>

                            <p>
                                Once you’ve signed up to ClickFunnels, you can download the website templates by clicking
                                {' '}
                                <a href="https://app.clickfunnels.com/funnels/10877517/share/zz7wslbokconuv8l" target="_blank" rel="noopener noreferrer">HERE</a>
                            </p>
                        </div>

                        <div ref={refs[3]} className="plug-block">
                            <div className="plug-block-subheading">
                                Step 3: Choose Your Template

                            </div>

                            <p>When you open the funnel on Clickfunnels, you’re going to see all the different pages inside of it. Each page represents a different website template. </p>

                            <p>
                                As you can see, you can choose from the 5 different templates, with 4 different color variations each, leaving you with 20 different website templates to choose from.
                            </p>

                            <img src="https://img.genflow.com/1902x877/gya/plugandplays/website-1-481befed.jpg" alt="" />

                            <p>
                                Let’s take a look on each one of them:
                            </p>

                            <p className="bold">
                                Template #1: 3D Video Banner Website
                            </p>

                            <img src="https://img.genflow.com/1905x931/gya/plugandplays/website-2-0112475e.jpg" alt="" />

                            <ul>
                                <li>
                                    Live example:
                                    {' '}
                                    <a href="https://imangadzhi.clickfunnels.com/template-1" target="_blank" rel="noopener noreferrer">https://imangadzhi.clickfunnels.com/template-1</a>
                                </li>
                            </ul>

                            <p className="bold">
                                Template #2: Local Businesses Agency Clean Website
                            </p>

                            <img src="https://img.genflow.com/1902x938/gya/plugandplays/website-3-98464312.jpg" alt="" />

                            <ul>
                                <li>
                                    Live example:
                                    {' '}
                                    <a href="https://imangadzhi.clickfunnels.com/template-2">https://imangadzhi.clickfunnels.com/template-2</a>
                                </li>
                            </ul>

                            <p>
                                The overall clean design of this template conveys a professional and modern look for your agency.
                            </p>

                            <p>
                                This template is a great one for agencies that work with local businesses. It is all tailored to that specific niche. Although you can also customize it to fit your specific niche, if needed.
                            </p>

                            <p className="bold">
                                Template #3: Minimalistic Advertising Agency
                            </p>

                            <img src="https://img.genflow.com/1900x874/gya/plugandplays/website-4-09b68b44.jpg" alt="" />


                            <ul>
                                <li>
                                    Live example:
                                    {' '}
                                    <a href="https://imangadzhi.clickfunnels.com/template-3v2" target="_blank" rel="noopener noreferrer">https://imangadzhi.clickfunnels.com/template-3v2</a>
                                </li>
                            </ul>

                            <p>
                                This template takes a more minimalistic approach, while still conveying the professional and cutting-edge look. If the fancy 3D video or colorful style doesn’t fit your brand, this template comes as an ideal option.
                            </p>

                            <p className="bold">
                                Template #4: Performance Focused Agency Website
                            </p>

                            <img src="https://img.genflow.com/1903x820/gya/plugandplays/website-5-96a8cb72.jpg" alt="" />

                            <ul>
                                <li>
                                    Live example:
                                    {' '}
                                    <a href="https://imangadzhi.clickfunnels.com/template-4v1" target="_blank" rel="noopener noreferrer">https://imangadzhi.clickfunnels.com/template-4v1</a>
                                </li>
                            </ul>

                            <p>
                                The variety of the graphs presented on this template convey a performance-focused image to your brand. Not only that, but the one-color look it’s a great feature for agencies that want to have a strong branding.
                            </p>

                            <p>
                                Now, don’t forget to take a look at all the different color layouts for each one of the templates, and then choose the one that you like the most and that better fits your brand.
                            </p>

                        </div>


                        <div ref={refs[3]} className="plug-block">
                            <div className="plug-block-subheading">
                                Step 4: Customize Your Template

                            </div>


                            <p>
                                On all the website templates there are elements that can (and must) be customized. The biggest one being the content, on all of them.

                            </p>

                            <p>
                                Doing that is as simple as clicking the “edit page” button for the page that you want to customize.
                            </p>

                            <img src="https://img.genflow.com/1343x732/gya/plugandplays/website-6-c1ac1203.jpg" alt="" />

                            <p>
                                Then, on the edit page, you just have to change the text of the page - just like you would do on Google Docs or any text editing software.
                            </p>

                            <p>
                                We have some templates that are great for people who already have clients, since there are sections to add your clients’ logos, as well as the total ad spend your agency has managed to date.
                            </p>

                            <p>
                                In case you have no clients to show yet, my suggestion is that you change those sections to general statistics from the platform, such as the total budget being spent yearly by advertisers on Facebook, the types of businesses that advertise on it, etc. You can see an example of this on template #3.
                            </p>

                            <p>
                                On template #1, you can also change the video on the top of the page. To do that, you just have to click on “settings” then “background”. Then, where it says “YouTube URL” you just add the URL for the video you want to have there. It has to be hosted on YouTube. You can just upload it to a private channel.
                            </p>

                            <img src="https://img.genflow.com/1920x825/gya/plugandplays/website-7-fe535ab6.jpg" alt="" />

                            <p>
                                Don’t forget to
                                {' '}
                                <span className="bold">always</span>
                                {' '}
                                use royalty free videos that you do have the license to use commercially. You can find thousands of them on video repositories such as pexels.com or pixabay.com.
                            </p>

                            <p>
                                Important: Make sure you swap out the SEO details for each page.
                            </p>

                            <p>
                                You can do that by clicking on “Settings” then “SEO Meta Data”.
                            </p>

                            <img src="https://img.genflow.com/659x468/gya/plugandplays/website-8-fb463cd7.jpg" alt="" />

                            <p>
                                One thing we do not recommend you customize is the colors of the template. That would require a bit of knowledge on CSS, because they are configured using custom CSS.
                            </p>

                            <p>
                                After selecting and editing the template of your choice, I suggest you remove all the other pages from the funnel to keep things organized. You can do that by clicking on “Remove from funnel” inside each page that you are not currently using.
                            </p>

                            <img src="https://img.genflow.com/927x565/gya/plugandplays/website-9-c3eee993.jpg" alt="" />

                            <p>
                                Don’t worry, this won’t delete the other pages completely. They will still be available under the “Other funnel steps” dropdown at the bottom left of the page.
                            </p>

                        </div>

                        <div ref={refs[4]} className="plug-block">
                            <div className="plug-block-subheading">
                                Step 5: Create Your Custom Thank You Page

                            </div>

                            <p>
                                Now you are going to create a custom thank you page that users are redirected to after they schedule a call with you or your team.
                            </p>

                            <p>
                                I didn’t include pre-made thank you pages because that would mean having 40 different pages inside this same funnel, which would get too confusing for most people.
                            </p>

                            <p>
                                If you followed the steps above correctly, you should only have 1 page in your funnel and we are now going to create the second (and last) page, which keeps things way more simple than having 40 different pages.
                            </p>

                            <p>In order to create this new page, we are going to duplicate the already existing page so that we can have the same design style. To do so, just select your page and click on “clone funnel step”.</p>

                            <img src="https://img.genflow.com/1349x682/gya/plugandplays/website-10-d7fd109e.jpg" alt="" />

                            <p>
                                After the new page is cloned, you can click on “add to funnel”. This will bring your page to your funnel steps.
                            </p>

                            <img src="https://img.genflow.com/1302x677/gya/plugandplays/website-11-80d2630e.jpg" alt="" />

                            <p>
                                I suggest you then go to the “Publishing” tab and rename this page as “Thank You Page” as well as the Path to “thank-you” (this will be the url of your page). Don’t forget to click on “Update Funnel Step” once that’s done.
                            </p>

                            <img src="https://img.genflow.com/954x279/gya/plugandplays/website-12-0b6dadf4.jpg" alt="" />

                            <p>Now, click on “edit page” to edit your thank you page. Here, I suggest you delete all the sections but the first one and the footer. We won’t really need the other ones, since the only purpose of this page is to let users know that their call is scheduled. </p>

                            <p>
                                You can delete sections by hovering over them and clicking on the little trash bin icon, just like on the screenshot below:
                            </p>

                            <img src="https://img.genflow.com/1920x838/gya/plugandplays/website-13-ddd46cdf.jpg" alt="" />

                            <p>
                                Once you deleted the other sections and only have the first one and the footer remaining, you can also delete the button from the first section by hovering over it and clicking again on delete:
                            </p>

                            <img src="https://img.genflow.com/1906x823/gya/plugandplays/website-14-a91fe4f4.jpg" alt="" />

                            <p>
                                Finally, all we need to do now is change the text on the page. Here we just like to let people know that their call was scheduled and give them a quick heads up about it. Here’s how it looks like:
                            </p>

                            <img src="https://img.genflow.com/1920x831/gya/plugandplays/website-15-bb5b5809.jpg" alt="" />

                            <p>Now, the one last thing we need to do here is installing the Facebook “Schedule” Conversion Event that will be triggered every time someone loads this page. This way, Facebook knows that when a user loads this page, it means that they booked a call with us. </p>

                            <p>
                                To do that it’s super simple. You just click on “settings” and then paste the following piece of code into the window that pops up:
                            </p>


                            {'<script> '}
                            <br />
                            fbq('track', 'Schedule');
                            <br />
                            {'</script> '}

                            <img src="https://img.genflow.com/1920x774/gya/plugandplays/website-16-203dc91a.jpg" alt="" />

                            <p>
                                Once that’s done, close the pop up and hit save. Your thank you page is now ready.
                            </p>


                        </div>


                        <div ref={refs[5]} className="plug-block">
                            <div className="plug-block-subheading">
                                Step 5: Link Domain To ClickFunnels

                            </div>

                            <p>
                                Straight the box ClickFunnels gives you an ugly domain like: iman-gadzhi.clickfunnels.com Instead we want a clean link, something like: iman-gadzhi.com
                            </p>

                            <p>
                                For that, the team at ClickFunnels already created really comprehensive tutorials for each different domain provider available out there, so I recommend you follow their tutorial - it will be the easier way to do it.
                            </p>

                            <p>
                                ClickFunnels tutorial for adding your external custom domain to ClickFunnels:
                                {' '}
                                <a href="https://help.clickfunnels.com/hc/en-us/articles/360005899634-Adding-Your-External-Custom-Domain-to-ClickFunnels">Click HERE</a>
                            </p>

                            <p>
                                ClickFunnels tutorial for integrating your subdomain with ClickFunnels:
                                {' '}
                                <a href="https://help.clickfunnels.com/hc/en-us/articles/360005898654-Integrating-Your-Subdomain-Into-ClickFunnels" target="_blank" rel="noopener noreferrer">Click HERE</a>
                            </p>

                            <p>
                                After you linked your domain, here’s how to set your funnel page as the homepage of your website:
                                {' '}
                                <a href="https://help.clickfunnels.com/hc/en-us/articles/360005985273-Selecting-Default-Pages-for-Your-Custom-Domain-404-and-Home-Page" target="_blank" rel="noopener noreferrer">Click HERE</a>
                            </p>
                        </div>


                        <div ref={refs[6]} className="plug-block">
                            <div className="plug-block-subheading">
                                Step 6: Connect to Calendly

                            </div>

                            <p>
                                You would’ve already set up your Calendly account in the ‘A Craftsman's Tools’ module.
                            </p>

                            <p>
                                Now it’s time to embed it on our website page.
                            </p>

                            <p>
                                To do so, just log into your Calendly account and copy the link for the event you created. You can do so by clicking on “Copy link” on the specific event.
                            </p>

                            <img src="https://img.genflow.com/320x314/gya/plugandplays/website-17-43ca00f1.jpg" alt="" style={{ width: '50%' }} />

                            <p>With that copied, head out to your clickfunnels website edit page and scroll down until you see the call booking section for the website template you chose. On the editing view, it will show up as “Custom Javascript / HTML”.</p>

                            <img src="https://img.genflow.com/1920x808/gya/plugandplays/website-18-48e48133.jpg" alt="" />

                            <p>
                                Click on it, then click on “Open Code Editor” on the window that pops up. Now, you just have to replace the link after the “URL =” part of the code, with the link you copied from Calendly.
                            </p>
                            <img src="https://img.genflow.com/759x503/gya/plugandplays/website-19-4b3785b3.jpg" alt="" />

                            <p>
                                Make sure you keep the quotation marks (“”). With that done, just exit the code editor window by clicking on the X and then save your page by clicking on “Save” at the top right corner.
                            </p>

                            <p>
                                Now, go back to Calendly and click on the little gear icon on your event and then click “edit”
                            </p>

                            <img src="https://img.genflow.com/382x325/gya/plugandplays/website-20-7e89c8c8.jpg" alt="" style={{ width: '50%' }} />

                            <p>Under the Additional Options, open the Confirmation Page tab. There, select “Redirect to an external site” and paste your Thank You Page’s URL on the Redirect URL</p>

                            <img src="https://img.genflow.com/985x460/gya/plugandplays/website-21.65b2b1ef.jpg" alt="" />

                            <p>
                                You can get your thank you page URL on ClickFunnels:
                            </p>

                            <img src="https://img.genflow.com/1322x379/gya/plugandplays/website-22-b2e580ab.jpg" alt="" />

                            <p>
                                Make sure you have the right funnel step selected, otherwise you’ll get the URL that links to another step.
                            </p>
                        </div>

                        <div ref={refs[7]} className="plug-block">
                            <div className="plug-block-subheading">
                                Step 6: Put Pixel On Funnel

                            </div>

                            <p>
                                We want to be able to pixel track potential clients that come to our website and also tracks who books in a Discovery Call with us.
                            </p>

                            <p>
                                To do so, all you need to do is go to the
                                {' '}
                                <a href="https://business.facebook.com/events_manager2/list/pixel" target="_blank" rel="noopener noreferrer">Events Manager</a>
                                {' '}
                                tab on your Facebook Business Manager and copy your pixel ID that can be found here:
                            </p>

                            <img src="https://img.genflow.com/1408x591/gya/plugandplays/website-23-472857bc.jpg" alt="" />

                            <p>
                                Now, all you have to do is replace your pixel ID on the two highlighted parts of the code below:
                            </p>

                            {'<!-- Facebook Pixel Code -->'}
                            {' '}
                            <br />
                            {'<script>'}
                            {' '}
                            <br />
                            !function(f,b,e,v,n,t,s)
                            {' '}
                            <br />
                            {'{if(f.fbq)return;n=f.fbq=function(){n.callMethod?'}
                            {' '}
                            <br />
                            {'n.callMethod.apply(n,arguments):n.queue.push(arguments)};'}
                            {' '}
                            <br />
                            {'if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version=\'2.0\';'}
                            {' '}
                            <br />
                            n.queue=[];t=b.createElement(e);t.async=!0;
                            {' '}
                            <br />
                            t.src=v;s=b.getElementsByTagName(e)[0];
                            {' '}
                            <br />
                            {'s.parentNode.insertBefore(t,s)}(window, document,\'script\','}
                            {' '}
                            <br />
                            {'\'https://connect.facebook.net/en_US/fbevents.js\');'}
                            {' '}
                            <br />
                            {'fbq(\'init\', \'{YOUR-PIXEL-ID-GOES-HERE}\');'}
                            {' '}
                            <br />
                            {'fbq(\'track\', \'PageView\');'}
                            {' '}
                            <br />
                            {'</script>'}
                            {' '}
                            <br />
                            {'<noscript>'}
                            {' '}
                            <br />
                            {`<img height="1" width="1" style="display:none" 
                                src="https://www.facebook.com/tr?id={YOUR-PIXEL-ID-GOES-HERE}&ev=PageView&noscript=1"/>`}
                            {' '}
                            <br />
                            {'</noscript>'}
                            {' '}
                            <br />
                            {'<!-- End Facebook Pixel Code -->'}
                            {' '}
                            <br />
                            <br />

                            <p>
                                With that done, just copy the whole code above and paste it inside the “Head Tracking Code” field on your funnel settings tab:
                            </p>

                            <img src="https://img.genflow.com/1314x776/gya/plugandplays/website-24-80d1eade.jpg" alt="" />

                            <p>
                                With that, your website is live and working.
                            </p>
                        </div>


                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
