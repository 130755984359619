

const phases = [
    {
        title: 'Laying Your Foundations',
        body: '',
        link: '/course/3351/phase/145',
        searchTerms: 'Foundations',
    },
    {
        title: 'Mindset',
        body: '',
        link: '/course/3351/phase/146',
        searchTerms: 'mind, mindset',
    },
    {
        title: 'Systems & Processes',
        body: '',
        link: '/course/3351/phase/149',
        searchTerms: 'Systems',
    },
    {
        title: 'Finding Leads & Setting Meetings',
        body: '',
        link: '/course/3351/phase/151',
        searchTerms: 'Leads,Meetings',
    },
    {
        title: 'Sales & Follow UpSales & Follow Up',
        body: '',
        link: '/course/3351/phase/154',
        searchTerms: 'Sales,UpSales',
    },
    {
        title: 'Service Delivery (In-Depth)',
        body: '',
        link: '/course/3351/phase/182',
        searchTerms: 'Service Delivery',
    },
    {
        title: 'Next Evolution',
        body: '',
        link: '/course/3351/phase/183',
        searchTerms: 'Next, Evolution',
    },
    {
        title: 'Operational Excellency 101 Powered By AgenciFlow',
        body: '',
        link: '/course/3351/phase/184',
        searchTerms: 'AgenciFlow',
    },

];

export default phases;
