import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import { Link } from 'react-router-dom';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        available: true,
        sectionName: 'What is the facebook pixel and how does it work?',
    },
    {
        available: true,
        sectionName: 'How to install a Facebook pixel? From creation to website - Plug n play',
    },
    {
        available: true,
        sectionName: 'Which events to install?',
    },
    {
        available: true,
        sectionName: 'How to use the events manager',
    },
    {
        available: true,
        sectionName: 'How to test your events and troubleshoot your tracking',
    },
    {
        available: true,
        sectionName: 'Death of custom conversions',
    },
    {
        available: true,
        sectionName: 'What is the conversions API and how to set it up - Plug n play',
    },
    {
        available: true,
        sectionName: 'iOS 14 plug n play',
    },
    {
        available: true,
        sectionName: 'Google analytics, google tag manager and Facebook ads [Advanced] Plug n play',
    },
    {
        available: true,
        sectionName: 'UTM Tracking plug n play',
    },
    {
        available: true,
        sectionName: 'Action step',
    },
];

const title = 'All About Tracking';

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (

        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                // selectedSection={selectedSection}
                                // setSelectedSection={onSelectSection}
                                // filter={filter}
                                // selectFilter={selectFilter}
                                // subFilter={subFilter}
                                // selectSubFilter={selectSubFilter}

                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]} className="plug-block">
                            <h2 className="mb-4">{title}</h2>                        

                            <div className="plug-block-subheading">
                                What is the facebook pixel and how does it work?
                            </div>

                            <p>
                                A piece of code for your website that lets you measure, optimize and build audiences for your ad campaigns. - Facebook
                            </p>

                            <p>
                                The Facebook pixel is essentially the eyes of Facebook on your website, it's what lets Facebook know what the people that are coming through the ads are doing.
                            </p>

                            <p>
                                It sends back all this information to Facebook and that is how it appears on your dashboard.
                            </p>

                            <p>
                                This is done via a 'cookie' a piece of code that runs on your browser that stores and sends information every time an event happens.
                            </p>

                            <p>
                                It also lets you create custom audiences of people that have visited your website or done a specific action, we will go over this on Retargeting and Audiences modules.
                            </p>

                            <p>
                                All this information is not only used for you: it is also used by Facebook to train their models and to know customer behaviour, what they like, what they don't and they put them in buckets of people interested in some things. That is why whenever you want to buy something you see many ads of that category. The more information Facebook collects the better they will be at delivering ads. Your incentives are aligned" Facebook wants you to spend more on ads, so they try to keep you happy trying to give you what you want. With that being said this won't make up for a store or a funnel that doesn't convert or an offer that people don't want.
                            </p>

                            <p>
                                There are two schools of thought when it comes to the Facebook pixel:
                            </p>

                            <p className="bold">
                                Facebook Pixel is God
                            </p>

                            <div className="examples-block">
                                <p>
                                    Facebook pixel is key, basically a 'high quality' pixel has good data, meaning it's filled with buyers, people with buying power that are interested on the specific offer you're running.
                                </p>

                                <p>Facebook uses the data on your pixel to optimize your ads meaning that the better data you have in it the better people they will find and the more purchases you'll have, or leads you'll generate. This also considers that if you get 'bad' data the performance of your ads will drop considerably.</p>

                                <p>
                                    If you want to be on the safe side this means that you should really care about the quality of your traffic, and for example not 'let in' bad quality traffic of non-buyers. This supports our earlier thesis of only optimizing for conversions and selecting the event you most want like purchases or leads or calls.
                                </p>

                                <p>
                                    This 'school of thought' also entails that if you have more than one offer (service or product) you should have a pixel per offer and not mix them up. As the person that buys X product is very different from the one that buys Y product.
                                </p>
                            </div>

                            <p className="bold">
                                It's not that important

                            </p>

                            <div className="examples-block">
                                <p>
                                    The other point of view doesn't rest as much importance on the pixel. And considers it just as a reporting tool and as a way of giving Facebook data. You could argue that in this scenario the 'optimization' happens on the ad level and the pixel doesn't hold the importance others defend. We also have stories that defend this thesis and consider that a lot of the times performance can be flipped from bad to good, just by improving your ads, copy, funnel, ad structure, audiences, etc. With the same pixel.

                                </p>


                            </div>

                            <p>The truth most probably lies somewhere in the middle.</p>
                        </div>

                        <div className="plug-block" ref={refs[1]}>
                            <div className="plug-block-subheading">
                                How to install a Facebook pixel? From creation to website - Plug n play
                            </div>

                            <p>
                                Link for the plug n play:
                            </p>

                            <Link to="/resources/plug-and-play/website-setup">Website Setup</Link>

                        </div>

                        <div className="plug-block" ref={refs[2]}>
                            <div className="plug-block-subheading">Which events to install?</div>

                            <p>
                                As you saw in the plug n play, we use events to track people that did a desired action. We map out all the important actions of our funnel with these events.
                            </p>

                            <p>
                                For e-commerce: View content (Someone viewed a product page) → Add to cart → Initiate checkout → Purchase
                            </p>

                            <p>
                                For a lead funnel: View content (Someone got to the landing page) → Lead or completed registration (Someone submitted their information)
                            </p>

                            <p>
                                An event is triggered once a person lands on the specific page where the code is located.
                            </p>

                            <p>
                                This can be as simple or complicated as you want, as you'll see in the 'iOS14' plug n play we now have a 7 event limit and you'll choose their priority.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/allAboutTracking1.png" alt="" />

                            <p>
                                You can install whichever standard event you want from the list you can find here:
                            </p>

                            <a className="break-words" href="https://www.facebook.com/business/help/402791146561655?id=1205376682832142" target="_blank" rel="noopener noreferrer">https://www.facebook.com/business/help/402791146561655?id=1205376682832142</a>

                            <img src="https://img.genflow.com/gya/plugandplays/allAboutTracking2.png" style={{ width: '50%' }} alt="" />
                        </div>

                        <div className="plug-block" ref={refs[3]}>
                            <div className="plug-block-subheading">
                                How to use the events manager
                            </div>

                            <p>
                                Facebook as you've seen in the plug n play Facebook has a section called events manager, in here you'll find everything you need to know about the pixel and its activity.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/allAboutTracking3.png" alt="" />

                            <p>
                                In the overview section you'll see all the events that have been triggered in the pixel. If you've just installed the pixel this will be mostly empty or empty, keep in mind that the data takes a while to get to it. Where it says Pixel/Conversions API you'll find a list of the events where they are being triggered from, either browser which means pixel or server which means conversions API and how many times they've been triggered.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/allAboutTracking4.png" style={{ width: '50%' }} alt="" />

                            <p>
                                You'll also find some warnings that will tell you where you're going wrong in your tracking. In this account, you can see we have some information missing on an event that is affecting the purchase event. This is something that is not of concern as the volume tracked by the conversions API is minimum. Refer to the module "iOS Move To Privacy & Implications" for more information on the topic.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/allAboutTracking5.png" alt="" />

                            <p>
                                When you click on any event you'll see a graph that charts how many events of that nature have happened each day. This information will help you troubleshoot your tracking. Don't take this information as a way to gage progress as this is not only from ads but from all the traffic on the website. You can be concerned when this goes to 0 and you will need to check if there's issues with your tracking or simply no one is going to the page where you installed that event.
                            </p>
                        </div>

                        <div className="plug-block" ref={refs[4]}>
                            <div className="plug-block-subheading">
                                How to test your events and troubleshoot your tracking
                            </div>

                            <p className="bold">
                                'Test Events'
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/allAboutTracking6.png" alt="" />

                            <p>
                                Facebook runs automatic diagnostics and will tell you what you need to fix or take care of. They have simple guides for each problem and they are varied so you can ask the community if you were not able to resolve something from here.
                            </p>

                            <p>
                                We will pass to something far more important which is making sure the event's you installed were properly installed, a way to verify it and also different ways of troubleshooting your tracking when you see something not working.
                            </p>

                            <p>
                                As you know an event triggers once an user loads a page on their browser, it can also be a button, think on the add to cart button, that usually doesn't send you to a new page.
                            </p>

                            <p>
                                To test that the right events are being triggered on the pages you want them to be triggered you can use the 'Test events' tool on the events manager.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/allAboutTracking7.png" alt="" />

                            <p>
                                Put your clients website in there and open the website. Then interact with the website and check that the events are properly working, they should show up like this:
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/allAboutTracking8.png" alt="" />

                            <p>
                                As you can see we interacted with the website and tested the view content and add to cart event and you can see it was correctly triggered. Note: This won't work if you have an ad blocker or a browser that blocks cookies or tracking by default. For example I use firefox for ads and it wasn't working because of it.
                            </p>

                            <p className="bold">
                                'Facebook pixel helper'
                            </p>

                            <p>
                                The
                                <a href="https://chrome.google.com/webstore/detail/facebook-pixel-helper/fdgfkebogiimcoedlicjlajpkdmockpc?hl=en" target="_blank" rel="noopener noreferrer">Facebook pixel helper</a>
                                {' '}
                                is a chrome browser extension that runs in the background and detects the pixel on the site, this tool was developed by Facebook.
                            </p>

                            <p>
                                Once you've added the extension to your browser it will appear here:
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/allAboutTracking9.png"  alt="" />

                            <p>
                                Then you're all set, the way to use it is simple, you visit any site and it will tell you if it has a pixel. Most sites that have a pixel have advertised before, you can use this as a clue to know when prospecting if that company has been running ads or is interested in them.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/allAboutTracking10.png" alt="" />

                            <p>
                                When you visit a page with one pixel or more, the extension will be colored blue and when you click on it you can see the id's of the pixel that are in there. After installing the pixel you can use the tool to make sure it was well installed and it is now firing on the website.
                            </p>

                            <p>
                                Note: The Facebook pixel helper doesn't work with ad blockers or browsers that block cookies or trackers.
                            </p>

                            <p>
                                When you interact with the website and start triggering event they'll show up on the pixel helper
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/allAboutTracking11.png" alt="" />

                            As you can see in this example the initiate checkout event was triggered, this is because I loaded the checkout page on a store.

                            <img src="https://img.genflow.com/gya/plugandplays/allAboutTracking12.png" alt="" />

                            <p>
                                If you click on the dropdown of the event you'll see the 'payload', some of the information that is being sent back to facebook. It also sends the click id, the user id and some others that are not being displayed right now. What is important to notice is that it is correctly picking up the items I am going to checkout with its value and currency. If you're not seeing correct data it means you need to fix it, refer back to your code and see if everything is well set up.
                            </p>

                            <p>
                                Note: Please note that the value when is not custom and it's defaulted by Shopify it will not include shipping.
                            </p>
                        </div>

                        <div className="plug-block" ref={refs[5]}>
                            <div className="plug-block-subheading">
                                Death of custom conversions
                            </div>

                            <p>
                                There was a time where custom conversions were popular, essentially they counted how many people loaded a specific URL and would also refer back to the ads to know from which ad came from.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/allAboutTracking13.png" alt="" />

                            <p>
                                You can still create them by going into the create button and selecting 'Create Custom Conversions'
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/allAboutTracking14.png" alt="" />

                            <p>
                                Then you can name your conversion and put a description on it, then you select your pixel and put the URL of the page. You can give a category for example purchase to this "Upsell 2" conversion example. Hit create.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/allAboutTracking15.png" alt="" />

                            <p>Custom conversions are great in theory but tracking is flawed: repeat page refreshes or other accidental navigation back to key pages can inflate metrics. Reporting is also difficult to streamline as they cannot be sorted into the same columns as other metrics.</p>
                        </div>

                        <div className="plug-block" ref={refs[6]}>
                            <div className="plug-block-subheading">
                                What is the conversions API and how to set it up - Plug n play
                            </div>

                            <p>Link for the plug n play: </p>
                            <Link to="/resources/plug-and-play/the-conversion-api">The Conversion API</Link>
                        </div>

                        <div className="plug-block" ref={refs[7]}>
                            <div className="plug-block-subheading">
                                iOS 14 plug n play

                            </div>

                            <p>Link for the plug n play: </p>
                            <Link to="/resources/plug-and-play/ios14-update">iOS14 Update</Link>
                        </div>

                        <div className="plug-block" ref={refs[8]}>
                            <div className="plug-block-subheading">
                                Google analytics, google tag manager and Facebook ads [Advanced] Plug n play


                            </div>

                            <p>Link for the plug n play: </p>
                            <Link to="/resources/plug-and-play/tag-manager-setup">Tag Manager Setup</Link>
                        </div>

                        <div className="plug-block" ref={refs[9]}>
                            <div className="plug-block-subheading">
                                UTM Tracking plug n play


                            </div>

                            <p>Link for the plug n play: </p>
                            <Link to="/resources/plug-and-play/true-tracking">True Tracking</Link>
                        </div>

                        <div className="plug-block" ref={refs[10]}>
                            <div className="plug-block-subheading">
                                Action step


                            </div>

                            <p>
                                Download the pixel helper and go through your favorite online stores and funnels. Learn which kind of events they have set up and verify that they're properly set up with the pixel helper.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
