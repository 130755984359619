import React, { useEffect, useState } from 'react';

// reactstrap components
import {
    Card, Col, Container, Row,
} from 'reactstrap';

// core components
import 'react-responsive-modal/styles.css';

import { useLogout } from '@genflow/web-auth';
import { useHistory } from 'react-router';
import moment from 'moment';
import { useConfig } from '@genflow/core';
import { useQuery } from '@apollo/react-hooks';
import Footer from '../../../components/Footer';
import CardHeader from './components/CardHeader';
import AdminUserItem from './components/AdminUserItem';
import AdminUserPayments from './components/AdminUserPayments';

import './style.scss';
import getAdminClientCustomerPayments from '../../../api/getAdminClientCustomerPayments';
import getCognitoUserAttribute from '../../../utils/getCognitoUserAttribute';

export default () => {
    const logout = useLogout();
    const history = useHistory();

    // const [openModal, setOpenModal] = useState(false);

    // const [clients, setClients] = useState(tempClients);
    const [selectedUser, setSelectedUser] = useState(null);

    const { vendorUuid } = useConfig();
    const {
        data, error, loading,
    } = useQuery(getAdminClientCustomerPayments, {
        variables: {
            vendorUuid,
            status: 'AWAITING_APPROVAL',
        },
    });

    const users = data?.getAdminClientCustomerPayments?.data?.filter(({ cognitoUser }) => !!cognitoUser);

    useEffect(() => {
        if (!selectedUser && users?.length > 0) {
            setSelectedUser(users?.[0]);
        }
    }, [users, selectedUser]);

    return (
        <>
            <main className="main-admin-page">
                <section className="section-profile-cover section-shaped my-0">
                    <Container>
                        <Row>
                            <Col lg={12} className="text-center">
                                <h1>Admin.</h1>
                                <div>Manage user payments and community moderation.</div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <div
                    style={{
                        margin: '1rem auto 2rem ',
                        padding: '0 10rem',
                    }}
                >
                    <Row>
                        <Col md={3}>
                            <Card
                                className="shadow"
                                style={{
                                    height: '60vh',
                                    overflowY: 'scroll',
                                }}
                            >
                                <CardHeader
                                    LeftComponent={(
                                        <div
                                            style={{
                                                fontSize: 28,
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Users
                                        </div>
                                    )}
                                />

                                {users?.map(user => (
                                    <AdminUserItem
                                        user={user}
                                        selected={selectedUser?.cognitoUser?.Username === user?.cognitoUser?.Username}
                                        onClick={() => setSelectedUser(user)}
                                    />
                                ))}
                            </Card>
                        </Col>
                        <Col md={9}>
                            {
                                !!selectedUser && (
                                    <Card
                                        className="shadow"
                                        style={
                                            {
                                                // height: '20vh',
                                            }
                                        }
                                    >
                                        <CardHeader
                                            LeftComponent={(
                                                <div>
                                                    <div
                                                        style={{
                                                            fontSize: 38,
                                                            fontWeight: '750',
                                                        }}
                                                    >
                                                        {`${getCognitoUserAttribute(selectedUser?.cognitoUser, 'given_name')} ${getCognitoUserAttribute(selectedUser?.cognitoUser, 'family_name')}`}
                                                    </div>

                                                    <div
                                                        style={{
                                                            backgroundColor: 'black',
                                                            display: 'inline',
                                                            color: 'white',
                                                            padding: '0.2rem 0.5rem',
                                                            borderRadius: '1rem',
                                                            fontSize: '0.7rem',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        {getCognitoUserAttribute(selectedUser?.cognitoUser, 'email')}
                                                    </div>

                                                    <div
                                                        style={{
                                                            marginTop: '1rem',
                                                        }}
                                                    >
                                                        {`Joined ${moment(selectedUser?.cognitoUser?.UserCreateDate).format('dddd, MMMM Do YYYY HH:mm')}`}
                                                    </div>
                                                </div>
                                            )}
                                        />

                                        {
                                            !loading && !!data && (
                                                <AdminUserPayments
                                                    key={selectedUser}
                                                    selectedUser={selectedUser}
                                                />
                                            )
                                        }
                                    </Card>
                                )
                            }
                        </Col>
                    </Row>
                </div>
            </main>

            <Footer />
        </>
    );
};
