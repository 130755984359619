import React, { useContext, useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { get, parseInt } from 'lodash';
import moment from 'moment';
import CurrencyInput from 'react-currency-input-field';
import { Spinner } from 'reactstrap';
import { OUTREACH_TRACKER_DATA_LABEL } from '../../../../../../constants/outreachTracker';
import styles from './styles';
import { SummaryContext } from '../../providers/SummaryProvider';
import withSummaryList from "./withSumaryList";

const SummaryItem = ({
    isInput, name, value, currency, onKeyDown, isCurrencyValue,
}) => {
    const [editedValue, setEditedValue] = useState(null);
    const [editTitleLoading, setEditTitleLoading] = useState(false);
    useEffect(() => {
        if (!editedValue) {
            setEditTitleLoading(false);
        }
    }, [editedValue]);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '1rem 0.5rem',
        }}
        >
            <div style={{
                fontSize: '.9rem',
                width: '50%',
                // backgroundColor: 'red'
            }}
            >
                {name}
            </div>

            {
                isInput
                    ? (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: '45%',
                        }}
                        >
                            <CurrencyInput
                            // key={currency}
                                className="form-control"
                                prefix={currency}
                                placeholder={`${currency}1,000`}
                                decimalsLimit={2}
                                onValueChange={val => setEditedValue(val)}
                                style={{
                                    flex: 1,
                                    textAlign: 'right',
                                    ...(editedValue !== null ? {
                                        border: 'solid 1px #1882de',
                                    } : {

                                    }),
                                }}
                                value={editedValue || value}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        setEditTitleLoading(true);
                                        onKeyDown(parseInt(editedValue), () => {
                                            console.log('CLEAR');
                                            setEditedValue(null);
                                        });
                                    }
                                }}
                            />
                            {
                                editTitleLoading && (
                                    <Spinner
                                        color="primary"
                                        type="grow"
                                        style={{ marginLeft: 10 }}
                                    />
                                )
                            }
                        </div>
                    )
                    : (
                        <div style={{
                            fontSize: '1.1rem',
                            fontWeight: '500',
                        }}
                        >
                            {isCurrencyValue ? `${currency} ${value.toLocaleString()}` : value}
                        </div>
                    )
            }
        </div>
    );
};


const SummaryList = ({
    classes,
    yearView,
    currency,
    month,
}) => {
    const {
        getSummaryData, addSummaryMetadata, year, ENABLED_OUTREACH_COLUMN_FIELDS,
    } = useContext(SummaryContext);
    const FILTERED_ENABLED_OUTREACH_COLUMN_FIELDS = ENABLED_OUTREACH_COLUMN_FIELDS?.filter(item => item?.dataLabel !== OUTREACH_TRACKER_DATA_LABEL);
    const summaryData = getSummaryData(yearView ? null : month);
    return (
        <Paper className={classes.root}>
            <div
                style={
                    {
                        padding: '0.5rem',
                        textAlign: 'center',
                        color: 'white',
                        fontWeight: 'bold',
                        backgroundColor: '#1882de',
                        borderTopLeftRadius: '0.25rem',
                        borderTopRightRadius: '0.25rem',
                    }
                }
            >
                {yearView ? 'Year Summary' : 'Month Summary'}
            </div>
            <div style={{ padding: '0.5rem', height: '100%' }} className="grey800Bg">
                {
                    FILTERED_ENABLED_OUTREACH_COLUMN_FIELDS?.map(({ displayLabel, dataLabel, isCurrencyValue }) => (
                        <SummaryItem
                            name={displayLabel}
                            value={summaryData[dataLabel]}
                            isCurrencyValue={isCurrencyValue}
                            currency={currency}
                        />
                    ))
                }

                <SummaryItem
                    name="Attempts to Meetings"
                    value={`${
                        ((get(summaryData, 'meetingsSet', 0) * 100
                            / get(summaryData, 'attempts', 0)) || 0).toFixed(0)
                    }%`}
                />

                <SummaryItem
                    name="Meetings to Clients"
                    value={`${
                        ((get(summaryData, 'clientsClosed', 0) * 100
                            / get(summaryData, 'meetingsSet', 0)) || 0).toFixed(0)
                    }%`}
                />

                {/* <SummaryItem */}
                {/*    key={`${month} ${year} - net cash flow`} */}
                {/*    name="Net Cash Flow" */}
                {/*    value={`${currency} ${netCashFlow.toLocaleString()}`} */}
                {/* /> */}

                {/* <SummaryItem */}
                {/*    key={`${month} ${year} - dividend  released`} */}
                {/*    name="Dividends Released" */}
                {/*    value={dividendsReleased} */}
                {/*    isInput={!yearView} */}
                {/*    currency={currency} */}
                {/*    onKeyDown={(val, onComplete) => { */}
                {/*        addSummaryMetadata(month, */}
                {/*            { dividendsReleased: val }, onComplete); */}
                {/*    }} */}
                {/* /> */}

                {/* <SummaryItem */}
                {/*    key={`${month} ${year} - retained earnings`} */}
                {/*    name="Retained Earnings" */}
                {/*    value={`${currency} ${retainedEarnings.toLocaleString()}`} */}
                {/* /> */}

                {/* <SummaryItem */}
                {/*    key={`${month} ${year} - opening balance`} */}
                {/*    name="Opening Balance" */}
                {/*    value={openingBalance} */}
                {/*    isInput={!yearView} */}
                {/*    currency={currency} */}
                {/*    onKeyDown={(val, onComplete) => { */}
                {/*        addSummaryMetadata(month, */}
                {/*            { openingBalance: val }, onComplete); */}
                {/*    }} */}
                {/* /> */}

                {/* <SummaryItem */}
                {/*    key={`${month} ${year} - closing balance`} */}
                {/*    name="Closing Balance" */}
                {/*    value={closingBalance} */}
                {/*    isInput={!yearView} */}
                {/*    currency={currency} */}
                {/*    onKeyDown={(val, onComplete) => { */}
                {/*        addSummaryMetadata(month, */}
                {/*            { closingBalance: val }, onComplete); */}
                {/*    }} */}
                {/* /> */}
            </div>
        </Paper>
    );
};

export default withSummaryList(withStyles(styles)(SummaryList));
