import React from 'react';

const withMonth = Component => ({ ...props }) => {
    // console.log('in month', summary);
    // if (!summary) return <></>;

    // const sum = Object.entries(summary).map(([label, value]) => ({ label, value }));

    return <Component{...props} />;
};

export default withMonth;
