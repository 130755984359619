import Suitcase from './img/suitcase.png';
import Calculator from './img/calculator.png';
import Budget from './img/budget.png';

const tools = [
    {
        title: 'Financial Planner',
        body: 'Track your revenue, expenses, cash flow and more with this planner designed specifically for agency owners.',
        link: '/tools/financial-planner',
        icon: 'ni ni-money-coins',
        tooltip: 'tooltip2',
        searchTerms: '',
    },
    {
        title: 'Lifetime Value Calculator',
        body: 'Calculate the average lifetime value of your client’s customers based on average sale value, cost of goods, returns and referrals.',
        link: '/tools/lifetime-value-calculator',
        icon: 'ni ni-chart-pie-35',
        tooltip: 'tooltip4',
        searchTerms: '',
    },
    {
        title: 'Plan of Attack',
        body: 'Your go-to schedule and to do list.',
        link: '/tools/plan-of-attack',
        icon: 'ni ni-calendar-grid-58',
        tooltip: 'tooltip3',
        searchTerms: '',
    },
    {
        title: 'Outreach Tracker',
        body: 'Use this tool to track your outreach and to hold yourself accountable.',
        link: '/tools/outreach-tracker',
        icon: 'ni ni-send',
        tooltip: 'tooltip5',
        searchTerms: '',
        disabled: false,
    },
    {
        title: 'Audience Size Budget Calculator',
        body: 'Calculate the amount you’ll need to spend for your clients based on the size of their audience.',
        link: '/tools/audience-size-budget-calculator',
        icon: 'ni ni-single-02',
        tooltip: 'tooltip6',
        searchTerms: '',
    },
    {
        title: 'Lead Generation Success Calculator',
        body: 'Evaluate the success of your lead generation campaign with this calculator.',
        link: '/tools/lead-generation-success-calculator',
        icon: 'ni ni-spaceship',
        tooltip: 'tooltip7',
        searchTerms: '',
    },
    {
        title: 'Client Revenue Calculator',
        body: "Calculate the estimated revenue of your client's business based on website traffic, average order value and their industry. ",
        link: '/tools/client-revenue-calculator',
        iconFile: Calculator,
        tooltip: 'tooltip7',
        searchTerms: '',
    },
    {
        title: 'Local Business ROI Calculator',
        body: 'Calculate the estimated lifetime & day #1 Return on Investment (ROI) of your marketing efforts for your local business clients.',
        link: '/tools/local-business-roi',
        iconFile: Suitcase,
        tooltip: 'tooltip7',
        searchTerms: '',
    },
    {
        title: 'Breakeven ROAS Calculator',
        body: 'Calculate the Breakeven Return On Ad Spend for your clients with and without your service fee, based on the expected ad spend and gross margins.',
        link: '/tools/breakeven-roas-calculator',
        iconFile: Budget,
        tooltip: 'tooltip7',
        searchTerms: '',
    },
    {
        title: 'Ads Checker',
        body: 'Check your Facebook advert copy to ensure compliance.',
        href: 'https://growyouragency.com/facebook-ads-checker',
        icon: 'ni ni-check-bold',
        tooltip: 'tooltip1',
        searchTerms: '',
        disabled: true,
    },
];

export default tools;
