import React from 'react';

const PROGRESS = [
    {
        label: 1,
        value: 0,
    },
    {
        label: 2,
        value: 50,
    },
    {
        label: 3,
        value: 100,
    },
];

const OnboardingProgress = ({ completed }) => {
    const animate = {
        transition: 'width 1s ease-in-out',
    };

    const progressBar = {
        width: `${completed}%`,
    };

    return (
        <div className="h-[60px] flex justify-center items-center relative" style={{ width: '80%' }} >
            <div className="h-[4px]  bg-[#393939]" style={{ width: '100%' }}>
                <div className="h-full bg-[#1777D9]" style={{ ...animate, ...progressBar }} />
            </div>
            <div className="absolute flex row justify-between w-full m-auto left-0 right-0">
                {
                    PROGRESS?.map(({ label, value }) => (
                        <div className="h-10 w-10 md:h-12 md:w-12 rounded-full flex justify-center items-center" style={{ ...{ backgroundColor: completed >= value ? '#1777D9' : '#393939' }, ...animate }}>
                            <span className="text-white">{label}</span>
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default OnboardingProgress;
