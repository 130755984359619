import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        available: true,
        sectionName: 'Introduction',
    },
];

const title = 'Launching Your First Ad';

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]}  className="plug-block">  
                        <h2 className="mb-4">{title}</h2>
                            <div className="plug-block-subheading">
                                Introduction:
                            </div>

                            <p>
                                Launching your first ad is a daunting proposition.
                            </p>
                            <p>
                                But don’t worry! We’ve created a walkthrough and checklist to guide you through it.
                            </p>

                            <p>
                                Here’s what you’ll need:
                            </p>

                            <div className="examples-block">
                                <ul>
                                    <li>Copy</li>
                                    <li>
                                        Image or video


                                    </li>
                                    <li>
                                        Landing page
                                    </li>
                                    <li>
                                        Audience you want to use or test
                                    </li>
                                </ul>
                            </div>

                            <p>
                                Ready? Let's go!
                            </p>

                            <div style={{ height: 350 }}>
                                <iframe
                                    id="videoPlayer"
                                    className="sproutvideo-player"
                                    src="https://videos.sproutvideo.com/embed/4d9dd8b61613e7c2c4/0d557b794fd502ca?playerTheme=dark&amp;playerColor=263238"
                                    frameBorder="0"
                                    allowFullScreen
                                    width="100%"
                                    height="100%"
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
