import React from 'react';
import { CoreProvider } from '@genflow/core';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks';
import { ThemeProvider } from '@material-ui/core/styles';

import config from '../config';
import apolloClient from './apolloClient';
import theme from './theme';
import { UserDataProvider } from './UserDataProvider';
import { OnboardingProvider } from './OnboardingProvider';

const Providers = ({ children }) => (
    <ThemeProvider theme={theme}>
        <CoreProvider config={config}>
            <ApolloProvider client={apolloClient}>
                <ApolloHooksProvider client={apolloClient}>
                    <UserDataProvider>
                        <OnboardingProvider>
                            { children }
                        </OnboardingProvider>
                    </UserDataProvider>
                </ApolloHooksProvider>
            </ApolloProvider>
        </CoreProvider>
    </ThemeProvider>
);

export default Providers;
