import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import compose from 'lodash/fp/compose';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import CurrencySelector from '../../../../components/CurrencySelector';
import ScrollTabs from '../../../../components/scrollTabs';
import MonthView from './components/MonthView';
import withOutreachTracker from './withOutreachTracker';
import styles from './styles';
import CustomLoader from '../../../../components/CustomLoader';
import YearSummary from './components/YearSummary';

const MONTHS_ARRAY = moment.months();
const OutreachTracker = ({
    classes,
    labels,
    anchorEl,
    onMenuClose,
    years,
    selectYear,
    selected,
    handleSelect,
    updateData,
    deleteRow,
    year,
    currency,
    loading,
}) => (
    <div className="w-10/12 mx-auto">
        <main className="profile-page text-white">
            <section className="section-profile-cover section-shaped my-10 sm:my-12">
                <Container>
                    <Row>
                        <Col lg={12} className="text-left pt-4">
                            <h1 className="hidden sm:block">Outreach Tracker.</h1>
                            <h3 style={{fontSize: '26px'}} className="block sm:hidden">Outreach Tracker.</h3>
                        </Col>
                    </Row>
                </Container>
            </section>
        </main>
        <CustomLoader loading={loading}>
            <div style={{ marginTop: '1rem' }}>
                <CurrencySelector />
                <Menu
                    className={classes.yearMenu}
                    open={!!anchorEl}
                    anchorEl={anchorEl}
                    onClose={onMenuClose}
                >
                    {years.map(yearOption => (
                        <MenuItem
                            key={yearOption}
                            selected={Number(yearOption) === Number(year)}
                            onClick={() => selectYear(yearOption)}
                            dense
                            style={{ maxHeight: 50 }}
                        >
                            { yearOption }
                        </MenuItem>
                    ))}
                </Menu>

                {/* div to handle scroll tab arrow spacing when few labels */}
                <div style={selected <= 1 ? {} : {}}>
                    <ScrollTabs
                        className={classes.tabs}
                        value={selected}
                        handleChange={handleSelect}
                        labels={selected > 1 ? labels : [...labels.slice(0, 2), 'Month View']}
                    />
                </div>

                {
                    selected === 1
                    && (
                        <YearSummary
                            year={year}
                            currency={currency}
                            allMonthLabels={MONTHS_ARRAY}
                        />
                    )
                }

                {MONTHS_ARRAY
                    .map((month, i) => (
                        <MonthView
                            selected={selected}
                            index={i + 2}
                            // data={values}
                            year={year}
                            month={month}
                            updateData={updateData}
                            deleteRow={deleteRow}
                            key={month}
                            currency={currency}
                        />
                    ))
                }
            </div>
        </CustomLoader>
    </div>
);

export default compose(
    withOutreachTracker,
    withStyles(styles),
)(OutreachTracker);
