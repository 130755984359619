import React, {
    useContext, useEffect, useMemo, useState,
} from 'react';
import {
    Card, Col, Container, Row,
} from 'reactstrap';
import useCourse from '@genflow/web-courses/hooks/useCourse';
import { useConfig } from '@genflow/core';
import { get, groupBy, parseInt } from 'lodash';
import './style.scss';
import { ChevronDownIcon, ChevronUpIcon, LockClosedIcon } from '@heroicons/react/outline';
import { PlayIcon } from '@heroicons/react/solid';
import useCompleteLesson from '@genflow/web-courses/hooks/useCompleteLesson';
import { UserDataContext } from '../../../../providers/UserDataProvider';
import useAccess from '../../../../hooks/useAccess';
import NavBar from '../../../../components/Navbar/NavBar';
import RecordingsNavigationPane from './components/RecordingsNavigationPane';
import CustomLoader from '../../../../components/CustomLoader';
import { trackEvent } from '../../../../utils/useAnalytics';
import brand from '../../../../components/Navbar/brand.svg';


const MonthsArray = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

const QA_TYPES = [
    {
        label: 'Agency Navigator',
        value: 'agencyNavigator',
        active: true,
        id: 356,
    },
    {
        label: 'Apex',
        value: 'apex',
        active: true,
        id: 357,
    },
];


export default () => {
    const [activeType, setActiveType] = useState(QA_TYPES?.[0]?.value);
    const [openSection, setOpenSection] = useState(false);

    const {
        setShowAccessModal,
    } = useContext(UserDataContext);
    const {
        vendorUuid,
        resourceLibraryProductID,
        resourcesQARecordingsID,
        agencyNavigatorCourseProductID,
        stripeProductPlans,
    } = useConfig();
    const {
        data, loading, error, authoriseError,
    } = useCourse(vendorUuid, resourceLibraryProductID, agencyNavigatorCourseProductID);

    const { completeLesson, data: completedLessons } = useCompleteLesson(resourceLibraryProductID);

    const activeQAID = useMemo(() => QA_TYPES?.find(item => item?.value === activeType)?.id, [activeType]);

    const qaRecordingsSection = get(data, 'sections', []).find(({ id }) => parseInt(id) === activeQAID);

    const sectionWithVideos = useMemo(() => ({
        ...qaRecordingsSection,
        lessons: qaRecordingsSection?.lessons?.filter(lesson => !!lesson?.assets?.[0]?.url?.length),
    }), [qaRecordingsSection]);

    const [selectedSection, setSelectedSection] = useState(null);
    const [selectedVideo, setSelectedVideo] = useState(null);

    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, []);


    const videosByDate = (obj) => {
        const videos = obj.map(({
            id, order, name, assets, meta_data,
        }) => ({
            id,
            name,
            order,
            assets,
            url: get(assets, '[0].embedUrl', ''),
            title: get(assets, '[0].title', ''),
            thumbnail: get(assets, '[0].thumbnail', ''),
            sproutId: get(assets, '[0].sproutId', ''),
            type: meta_data && JSON.parse(meta_data)?.program,
        }));

        // order array by item.order
        return videos.sort((a, b) => ((a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0)));
    };

    // Assumes 4 digit year (e.g. 2021)
    const groupedByYear = groupBy(get(sectionWithVideos, 'lessons', []), ({ name }) => get(name.match(/\d{4}/), '[0]'));
    const yearsAscending = Object.keys(groupedByYear).sort((a, b) => parseInt(b) - parseInt(a));
    const sections = [];

    yearsAscending
        .forEach((year) => {
            const monthGroupObj = groupBy(
                groupedByYear[year],
                ({ name }) => get(name.match(/\b([a-z]+)\b/gi),
                    '[0]'),
            );

            const resultOrder = Object.keys(monthGroupObj).sort(
                (a, b) => MonthsArray.indexOf(a) - MonthsArray.indexOf(b),
            );

            sections.push({
                name: year,
                months: resultOrder.map(month => ({
                    name: month,
                    videos: videosByDate(monthGroupObj[month]),
                })),
            });
        });


    const videos = sections
        .find(({ name: year }) => year === selectedSection?.year)
        ?.months?.find(({ name: month }) => month === selectedSection?.month)?.videos || [];

    useEffect(() => {
        if (!loading && !selectedSection) {
            setSelectedSection({
                year: sections?.[0]?.name,
                month: sections?.[0]?.months?.reverse()?.[0]?.name,
            });
        }
    }, [loading, sections, selectedSection, sectionWithVideos]);

    useEffect(() => {
        if (!loading) {
            setSelectedSection({
                year: sections?.[0]?.name,
                month: sections?.[0]?.months?.reverse()?.[0]?.name,
            });
        }
    }, [activeType]);

    const onSelectVideo = (video) => {
        trackEvent({
            action: 'Q&A_View_Recording',
            category: 'Session_Name',
            label: video?.name,
        });
        setSelectedVideo(video);
    };

    const onSelectSection = (section) => {
        setSelectedVideo(null);
        setSelectedSection(section);
    };

    useEffect(() => {
        if (selectedVideo) {
            if (get(selectedVideo, 'sproutId')) {
                console.log('your sprout id', get(selectedVideo, 'sproutId'));
                const player = new window.SV.Player({ videoId: get(selectedVideo, 'sproutId') });
                // if (autoPlay && playerLoaded) {
                player.play();
                // }
            }
        }
    }, [selectedVideo]);

    // qa recording access
    const access = useAccess('/resources/qa')?.validAccess;

    const completedSelectedVideo = useMemo(() => !!completedLessons.find(
        ({ id: cId }) => parseInt(cId) === parseInt(selectedVideo?.id),
    ), [selectedVideo, completedLessons]);

    return (
        <CustomLoader loading={loading}>
            <NavBar />
            <main className="w-10/12 mx-auto text-white">
                <section className="section-profile-cover section-shaped my-0">
                    <Container>
                        <Row>
                            <Col lg={12} className="text-center my-12">
                                <h2>Q&A Recordings</h2>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <Container className="content-container">
                    <Row className="flex flex-col sm:flex-row">
                        <div className="w-full sm:w-1/4 mr-0 sm:mr-4">
                            {
                                QA_TYPES?.filter(({ active }) => active)?.map(({ label, value }) => {
                                    const active = activeType === value;
                                    return (
                                        <>
                                            <div
                                                onClick={() => {
                                                    setActiveType(value);
                                                    setOpenSection(active ? !openSection : true);
                                                }}
                                                className="bg-[#1C1C1C] rounded-full titleText mb-4 flex justify-center items-center row center font-semibold hover:cursor-pointer"
                                            >
                                                {label}
                                                {(active && openSection) ? <ChevronUpIcon className="h-4 w-4 ml-2 text-white " /> : <ChevronDownIcon className="h-4 w-4 ml-2 text-white  " /> }
                                            </div>

                                            {active && openSection && (
                                                <div>
                                                    <RecordingsNavigationPane
                                                        selectedSection={selectedSection}
                                                        setSelectedSection={onSelectSection}
                                                        sections={sections}
                                                    />
                                                </div>
                                            )}
                                        </>
                                    );
                                })
                            }
                        </div>
                        <Col className="w-full sm:w-2/3 pb-10">
                            <Card
                                className="recording-items"
                            >
                                <div className={`flex justify-between items-center ${selectedVideo && 'm-2'}`}>
                                    <h3>
                                        {
                                            selectedVideo
                                                ? selectedVideo?.title || selectedVideo?.name
                                                : null
                                        }
                                    </h3>
                                    { selectedVideo && (
                                        <div className="flex flex-row items-center">
                                            <div
                                                className="blkButton text-white body4 h-fit px-4 py-2 cursor-pointer"
                                                onClick={() => completeLesson(selectedVideo?.id, !completedSelectedVideo)}
                                            >
                                                {completedSelectedVideo ? ' Completed' : ' Mark as complete'}
                                            </div>
                                            <button
                                                className="ml-1 md:ml-3 h-7 w-7 bg-black rounded-full border-0 text-black leading-none font-semibold outline-none focus:outline-none"
                                                onClick={() => setSelectedVideo(null)}
                                                type="button"
                                            >
                                                <span
                                                    className="text-[#434343] hover:text-[#D3D3D3] hover:animate-pulse outline-none focus:outline-none"
                                                >
                                                    ×
                                                </span>
                                            </button>
                                        </div>
                                    )}
                                </div>
                                <Row className="grey800Bg rounded-lg mt-4 lg:mt-0">
                                    {selectedVideo && (
                                        <div className="sprout videoHeight">
                                            <iframe
                                                id="videoPlayer"
                                                className="sproutvideo-player"
                                                src={`${get(selectedVideo, 'url', '')}?autoPlay=false`}
                                                frameBorder="0"
                                                allowFullScreen
                                                width="100%"
                                                height="100%"
                                                title="video"
                                            />
                                        </div>
                                    )}
                                    {
                                        !selectedVideo
                                        && (videos).map((video) => {
                                            const completedVideo = !!completedLessons.find(({ id: cId }) => parseInt(cId) === parseInt(video.id));
                                            return (
                                                <Col
                                                    className="plug-and-play-card text flex flex-row "
                                                    md={4}
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    <div
                                                        className="shadow p-4 sm:p-8 pt-8 flex flex-col sm:flex-row items-start sm:items-center"
                                                        style={{
                                                            borderRadius: '7px',
                                                        }}
                                                    >
                                                        <div className="custom-control custom-checkbox hover:cursor-pointer mb-4 mr-4">
                                                            <input
                                                                className="custom-control-input m-0"
                                                                checked={completedVideo}
                                                                key={`lesson-check-${video.id}-${completedVideo}`}
                                                                id={`lesson-check-${video.id}`}
                                                                type="checkbox"
                                                                onChange={() => {
                                                                    completeLesson(video?.id, !completedVideo);
                                                                }}
                                                            />
                                                            <label
                                                                className="custom-control-label"
                                                                htmlFor={`lesson-check-${video.id}`}
                                                            />
                                                        </div>
                                                        <div
                                                            className="plug-and-play-card text flex flex-col md:flex-row"
                                                            onClick={() => {
                                                                if (!access && video?.type === 'Apex') {
                                                                    setShowAccessModal(true);
                                                                    return;
                                                                }
                                                                onSelectVideo(video);
                                                            }}
                                                        >
                                                            <div className="grey700Bg relative sprout w-70 sm:w-48 h-50 sm:h-28 rounded-md">
                                                                <div className="absolute z-20 w-full h-full bg-black bg-opacity-25 flex justify-center items-center ">
                                                                    <PlayIcon className="h-8 w-8 text-white" />
                                                                </div>
                                                                <img src={video?.thumbnail || brand} alt="" className="w-[100%] h-[100%]" />
                                                            </div>
                                                            <div className="flex flex-col items-start ml-0 sm:ml-8 mb-auto lg:w-[45%]">
                                                                <div className="body4 grey500 mt-4 sm:mt-0">
                                                                    {(!access && video?.type === 'Apex') && <LockClosedIcon className="h-4 w-4 mr-1.5 inline" aria-hidden="true" />}
                                                                    Q&A Recording
                                                                </div>
                                                                <h5 className="text-white my-4 sm:mt-1 sm:my-0 ">
                                                                    {video?.title || video?.name}
                                                                </h5>
                                                            </div>
                                                            <div
                                                                onClick={() => {
                                                                    if (!access && video?.type === 'Apex') {
                                                                        setShowAccessModal(true);
                                                                        return;
                                                                    }
                                                                    onSelectVideo(video);
                                                                }}
                                                                className="blkButton h-fit body4 text-white py-2 px-4 mb-auto sm:ml-auto cursor-pointer"
                                                            >
                                                                View Recording
                                                            </div>
                                                        </div>

                                                    </div>
                                                </Col>
                                            );
                                        })
                                    }
                                </Row>
                            </Card>

                        </Col>
                    </Row>
                </Container>
            </main>

        </CustomLoader>
    );
};
