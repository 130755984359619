import React from 'react';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { ThemeProvider } from '@material-ui/core/styles';
import pickerTheme from '../../pickerTheme';

const TaskForm = ({
    event,
    handleStartEndChange,
}) => (
    <>
        <MuiPickersUtilsProvider utils={MomentUtils} className="underline-36">
            <ThemeProvider theme={pickerTheme} className="underline-36">
                <br />
                <DateTimePicker
                    variant="inline"
                    label="Date / Time"
                    value={new Date(event?.startDateTime)}
                    onChange={handleStartEndChange}
                    fullWidth
                    ampm={false}
                    format="dddd, MMMM Do YYYY"
                    style={{ marginTop: 20}}
                    className="underline-36"
                />
            </ThemeProvider>
        </MuiPickersUtilsProvider>
    </>
);

export default TaskForm;
