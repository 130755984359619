import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        available: true,
        sectionName: 'Traffic Warmth',
    },
    {
        available: true,
        sectionName: 'Types of traffic:',
    },
    {
        available: true,
        sectionName: 'Thoughts on BOF, MOF, TOF',
    },
    {
        available: true,
        sectionName: 'Traffic in Practice',
    },
];

const title = 'Traffic Warmth';

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]}  className="plug-block"> 
                            <div className="plug-block-subheading">Traffic Warmth</div>

                            <p>
                                In marketing, you’ll frequently hear the terms ‘cold traffic’ or ‘warm traffic’: this is used to differentiate the nature and quality of ‘traffic’ (users, clicks coming to your website) that comes into your funnel.
                            </p>

                            <div className="examples-block">
                                <p>
                                    Remember: Not every click is created equal. Not every user is in the same mental space to make a purchasing decision.
                                </p>
                            </div>

                            <p>
                                Generally, the ‘hotter’ the traffic, the more likely the user is to convert.
                            </p>

                            <p>
                                But it’s not always the case: in this Plug & Play you’ll learn why, and common gimmicks or misconceptions.
                            </p>
                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">Types of traffic:</div>

                            <p className="bold">
                                Cold Traffic
                            </p>

                            <p>
                                Cold traffic consists of people that have never heard of your client's brand before, which in theory make up for the majority of people and they are the least likely to buy. Because they have not heard about your brand, you have to introduce them to the brand, products and/or services.
                            </p>

                            <p>
                                According to standard marketing theory you must make an effort to interest them in the product and brand and attempt to build a relationship with them to convert them into warm/hot traffic.
                            </p>

                            <p>
                                Cold traffic needs a specific type of ads, these ads have to be more explanatory, specially if it is not evident how the product works.
                            </p>

                            <p>
                                Let's take a look at this ad:
                            </p>


                            <img src="https://img.genflow.com/gya/plugandplays/trafficWarmth1.png" style={{ width: '50%' }} alt="" />

                            <p>
                                If it is your first time seeing this ad, you will probably be very confused about what this is. Is it an attachment for bicycles? What is that 'in', 'out' on the device? What is that white button in the middle of the device?
                            </p>

                            <p>
                                Or this advert:
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/trafficWarmth2.png" style={{ width: '50%' }} alt="" />

                            <p>
                                If you weren’t familiar with the brand, would you understand what the Oura ring did?
                            </p>

                            <p>
                                Likewise:
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/trafficWarmth3.png" style={{ width: '50%' }} alt="" />

                            <p>
                                Now, consider this advert:
                            </p>


                            <div style={{ height: 420, marginBottom: '1rem' }}>
                                <iframe
                                    id="videoPlayer"
                                    className="sproutvideo-player"
                                    src="https://videos.sproutvideo.com/embed/d39dd7bd1f1defcf5a/2b0c7fcf4ed38cc1?playerTheme=dark&amp;playerColor=263238"
                                    frameBorder="0"
                                    allowFullScreen
                                    width="100%"
                                    height="100%"
                                />
                            </div>

                            <p>
                                This advert clearly explains the nature of the product and informs a cold audience.
                            </p>

                            <p className="bold">
                                Warm Traffic
                            </p>

                            <p>
                                People who recognise your brand, have engaged with your content, or visited your website constitute warm traffic (in practice, more often than not, it implies website visitors).
                            </p>

                            <p>
                                You can further divide this category according to what the user did on your website. For example, if they added a product to a cart, or spent longer than a certain amount of time on your landing page.
                            </p>

                            <p>
                                More often than not, this is overkill in practical applications - your division of audiences will be less efficient than Facebook’s - but it’s important to understand this further distinction.
                            </p>

                            <p>
                                As warm traffic users are already familiar with your brand, the nature of your advertising should be directed more towards persuasion to purchase/ convert, rather than to inform.
                            </p>

                            <p>
                                There are multiple ways to achieve this:
                            </p>

                            <p className="bold">
                                Discounts
                            </p>

                            <p>
                                Retargeting ads frequently feature discounts. It’s an easy and efficient way to push conversions.
                            </p>

                            <p className="bold">
                                List new/ interesting/ different features
                            </p>

                            <p>
                                This is your chance to distinguish your product/ service from others in the market. You might wish to distinguish a specific feature, or list several.
                            </p>

                            <p className="bold">
                                New/emotive angles:
                            </p>

                            <p>
                                Emotive angles and storylines are highly-effective angles in retargeting.
                            </p>

                            <p>
                                For example, in the below advert, a story is (briefly) told, making the product more relatable and aspirational:
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/trafficWarmth4.png" style={{ width: '50%' }} alt="" />

                            <p className="bold">Hot Traffic</p>

                            <p>
                                Previous buyers form Hot traffic.
                            </p>

                            <p>
                                It is an underutilised traffic source - but someone who has purchased from a brand is more likely to purchase again, than someone who has never purchased.
                            </p>

                            <p>
                                For many ecommerce brands, it’s incredibly-effective to retarget previous converted users from campaigns.
                            </p>

                            <p>
                                In many industries, for example Saas, companies are happy to pay astronomical amounts to acquire a customer in the first place - often more than will be returned in the first year - because of their high lifetime value.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/trafficWarmth5.png" alt="" />
                        </div>

                        <div ref={refs[2]} className="plug-block">
                            <div className="plug-block-subheading">Thoughts on BOF, MOF, TOF</div>

                            <p>
                                A common strategy looks like this:
                            </p>

                            <p>
                                TOF: Top of Funnel
                            </p>
                            <p>
                                Completely cold traffic in one campaign. This means interests-based targeting, look alike and generally broad audiences. The messaging is usually focused on awareness.
                            </p>

                            <p>
                                MOF: Middle of Funnel
                            </p>
                            <p>
                                ‘Lighter’ retargeting with the campaign containing audiences not-as-close to purchasing at nearer the bottom of the funnel. For example, users who have engaged with one of your ads (or an organic piece of content), followed you on Instagram, or liked your page on Facebook; and occasionally users who have visited your website and not taken an action such as adding to cart.
                            </p>

                            <p>
                                BOF: Bottom of Funnel
                            </p>
                            <p>
                                A campaign comprising audiences close to purchase. For example, users who have added to the cart or initiated checkout in the last 7 and 3 days respectively.
                            </p>

                            <p>
                                However, at IAG Media, we have found better results NOT using this strategy. Put simply: this is overcomplicated and leads to numerous problems. For example, towards the BOF, your audiences will be so small and exclusive that you will suffer with high CPMs, and miss out on conversions that you would have otherwise received with a wider targeting, such as website visitors from a further time window.
                            </p>

                            <p>
                                Furthermore, opting for engagement - rather than conversions - at the TOF will most likely lead Facebook to drive low-quality traffic towards your adverts (traffic that is more likely to yield engagement) rather than traffic more likely to convert, as in a more simple campaign.
                            </p>

                            <p>
                                There is also further danger that low-quality traffic will ruin your Facebook pixel.
                            </p>
                        </div>

                        <div ref={refs[3]} className="plug-block">
                            <div className="plug-block-subheading">Traffic in Practice</div>

                            <p>
                                Let’s review traffic types in practice:
                            </p>

                            <ul>
                                <li>
                                    Cold campaign: Cold interests, Lookalike Audiences and broad audiences.
                                </li>
                                <li>
                                    Hot campaign: All website visitors, people who have engaged with any content
                                </li>
                            </ul>

                            <p>
                                As you can see we retarget almost everyone that has had come in touch with the ads and the brand. This is usually your best converting audience and you want to be able to use it as much as possible.
                            </p>

                            <p>
                                Your ROAS with a bigger retargeting audience that uses for example 60 days as opposed to 7 days will be lower but overall your performance will be much greater as you will be making more money.
                            </p>

                            <p>
                                What matters more after reviewing all these concepts is that they hold true for the messaging and the marketing angles. You can get away with using the same ads for cold and retargeting, but chances are that your results will improve if you have messaging that makes sense for each group.
                            </p>

                            <p>
                                We've already seen ads that follow this idea and make sense for each type of audience so my best advice is that you make unique retargeting copy and creatives and unique cold ads.
                            </p>

                            <p>
                                That way you tailor the messaging to the warmth of the audience and improve your results. As a rule of thumb keep in mind that for cold audiences you are presenting the product and you have to explain how it works and why someone should get it, usually better done through a video.
                            </p>

                            <p>
                                For warmer audiences you want to improve the offer, show other features, testimonials or make the brand look more professional and trustworthy.
                            </p>

                            <p>
                                As a final piece of advice you can also switch the landing page of the cold vs retargeting audience, here are some examples:
                            </p>

                            <ul>
                                <li>
                                    For colds on some stores you send traffic to a catalogue page, on retargeting you can send people directly to a product page.
                                </li>
                                <li>
                                    For cold on info product funnels you can send to an opt in page, for retargeting you can send straight to a sales page.
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
