import gql from 'graphql-tag';

export default gql`
    query ($vendorUuid: ID!, $clientCustomerUuid: ID!){
        getClientCustomerPayments(vendorUuid: $vendorUuid, clientCustomerUuid: $clientCustomerUuid) {
            errors
            data {
                id
                createdTimestamp
                updatedTimestamp
                paymentTitle
                paymentType
                totalValue
                totalCurrency
                metadata
            }
        }
    }
`;
