import React, {
    useState, useEffect, useContext, Fragment, useRef,
} from 'react';
import {
    MenuIcon, XIcon, ExternalLinkIcon,
    LockClosedIcon, SearchIcon,
} from '@heroicons/react/outline';
import { Link } from 'react-router-dom';
import Headroom from 'headroom.js';
import { useMutation } from '@apollo/react-hooks';
import {
    Disclosure, Menu, Transition,
} from '@headlessui/react';
import { ChevronDownIcon, UserCircleIcon } from '@heroicons/react/solid';

import { useIsLoggedIn, useLogout } from '@genflow/web-auth';
import { useHistory } from 'react-router';
import useCourse from '@genflow/web-courses/hooks/useCourse';
import { useConfig } from '@genflow/core';
import withSearch, { ResultBox } from '../search';
import { UserDataContext } from '../../providers/UserDataProvider';
import useAdmin from '../../hooks/useAdmin';
import brand from './brand.svg';
import Loader from '../Loader';
import { uploadImage } from '../../utils/uploadImage';
import upsertUserProfileData from '../../api/upsertUserProfileData';
import './index.css';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const NavBar = ({
    value, onChange, searchResults, resultAnchor,
}) => {
    let access = false;
    const { vendorUuid, agencyNavigatorCourseProductID } = useConfig();
    const { loading, authoriseError } = useCourse(vendorUuid, agencyNavigatorCourseProductID, {

    });
    const isLoggedIn = useIsLoggedIn(true);
    access = authoriseError === null && loading !== true;

    console.log('access', access);

    const {
        userProfile, userProfileData, setUserProfile, userToken, setShowAccessModal,
    } = useContext(UserDataContext);

    const [dropdown, setDrop] = useState(null);

    const history = useHistory();
    const logout = useLogout();
    const { isAdmin } = useAdmin();

    useEffect(() => {
        (async () => {
            const headroom = new Headroom(document.getElementById('navbar-main'));
            // initialise
            headroom.init();
        })();
    }, []);

    const onClickLogout = () => {
        logout().then(() => {
            // clear user profile from local storage
            setUserProfile({});
            history.push('/');
        });
    };

    const onClickAdmin = () => {
        history.push('/admin');
    };

    const onClickProfile = () => {
        history.push('/profile');
    };

    const categories = [
        {
            name: 'Upcoming Q&A',
            link: '/upcoming-q-a',
            access: true, // access to all entitlements
        },
        {
            name: 'Q&A Recordings',
            link: '/resources/qa',
            access: true, // access to all entitlements
        },
    ];

    const [upsertProfileData] = useMutation(upsertUserProfileData, {});
    const [imageLoading, setImageLoading] = useState(false);
    const [image, setImage] = useState('');


    const handleChange = (e) => {
        setImageLoading(true);
        uploadImage(e?.target?.files[0], userToken).then((res) => {
            if (res?.fileUrl) {
                setImageLoading(false);
                setImage(res?.fileUrl);

                setUserProfile({
                    ...userProfile,
                    data: { ...userProfile?.data, image: res?.fileUrl },
                });

                upsertProfileData({
                    variables: {
                        data: JSON.stringify({
                            ...userProfile?.data, image: res?.fileUrl,
                        }),
                    },
                });
            }
        }).catch((e) => {
            setImageLoading(false);
        });
    };

    const inputRef = useRef(null);
    const mobileInputRef = useRef(null);
    const focused = document?.activeElement === inputRef?.current || document?.activeElement === mobileInputRef?.current;

    return (
        <Disclosure as="nav" className=" text-white" id="navbar-main">
            {({ open }) => (
                <>
                    <div className="w-10/12 mx-auto">
                        <div className="flex items-center justify-between h-16">
                            <div className="flex items-center">
                                <div className="flex-shrink-0">
                                    <Link to="/" className="mr-20 block  h-8 w-auto">
                                        <img
                                            alt="..."
                                            // src="https://img.genflow.com/810x210/gya/gyamainbrandlogo.png"
                                            src={brand}
                                        />
                                    </Link>
                                </div>
                                <div className="hidden xl:block">
                                    <div className="flex items-center pt-2 space-x-4">
                                        { access ? (
                                            <Link to="/tools" className="body3 grey500 hover:text-white px-3 py-2 ">
                                                Tools
                                            </Link>
                                        )
                                            : (
                                                <p className="body3 grey500 hover:text-white px-3 py-2 cursor-pointer">
                                                    {!access && <LockClosedIcon className="h-5 w-5 mr-2 inline" aria-hidden="true" />}
                                                    Tools
                                                </p>
                                            )
                                        }
                                        { access ? (
                                            <a
                                                href="https://www.facebook.com/groups/agencynavigator/"
                                                className="grey500 hover:text-white px-3 py-2 flex flex-row"
                                            >
                                                <div className="body3  mr-1">
                                                    Community
                                                </div>
                                                <div>
                                                    <ExternalLinkIcon className="h-5 w-5" aria-hidden="true" />
                                                </div>
                                            </a>
                                        )

                                            : (
                                                <p className="body3 grey500 hover:text-white px-3 py-2 cursor-pointer">
                                                    {!access && <LockClosedIcon className="h-5 w-5 mr-2 inline" aria-hidden="true" />}
                                                    Community
                                                </p>
                                            )
                                        }
                                        { access ? (
                                            <Menu as="div" className="relative inline-block text-left">
                                                <>
                                                    <Menu.Button>
                                                        <div className="pl-3 py-2 w-full h-full flex justify-center grey500 hover:text-white">
                                                            <p className="body3">Q&A Calls</p>
                                                            <ChevronDownIcon className="-mr-1 ml-auto h-5 w-5" aria-hidden="true" />
                                                        </div>
                                                    </Menu.Button>

                                                    <Transition
                                                        as={Fragment}
                                                        enter="transition ease-out duration-100"
                                                        enterFrom="transform opacity-0 scale-95"
                                                        enterTo="transform opacity-100 scale-100"
                                                        leave="transition ease-in duration-75"
                                                        leaveFrom="transform opacity-100 scale-100"
                                                        leaveTo="transform opacity-0 scale-95"
                                                    >
                                                        <div className="flex flex row pr-3" style={{ marginLeft: '0rem' }}>

                                                            <Menu.Items className="origin-top-right absolute z-10 right-0 mt-2 w-56 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                {categories
                                                            && (
                                                                <div className="grey800Bg text-white rounded-lg py-1">
                                                                    {categories.map((cat, index) => (
                                                                        <Menu.Item key={cat.name}>
                                                                            {({ active }) => (
                                                                                <Link to={!cat?.access ? '#' : cat?.link} onClick={() => !cat?.access && setShowAccessModal(true)} className="cursor-pointer">
                                                                                    <div
                                                                                        onClick={() => {
                                                                                            setDrop(cat.name);
                                                                                        }}
                                                                                        className={
                                                                                            classNames(
                                                                                                active ? ' white' : 'grey500',
                                                                                                'block px-4 py-2 text-sm',
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        {!cat?.access && <LockClosedIcon className="h-5 w-5 mr-2 inline" aria-hidden="true" />}
                                                                                        {cat.name}
                                                                                    </div>
                                                                                </Link>
                                                                            )
                                                                            }
                                                                        </Menu.Item>
                                                                    ))}

                                                                </div>
                                                            )
                                                                }
                                                            </Menu.Items>
                                                        </div>

                                                    </Transition>
                                                </>
                                            </Menu>
                                        )

                                            : (
                                                <p className="body3 grey500 hover:text-white px-3 py-2 cursor-pointer">
                                                    {!access && <LockClosedIcon className="h-5 w-5 mr-2 inline" aria-hidden="true" />}
                                                    Q&A Calls
                                                </p>
                                            )
                                        }
                                        { access ? (
                                            <a
                                                href="/resources/plug-and-play"
                                                className="body3 grey500 0 hover:text-white px-3 py-2"
                                            >
                                                Plug & Play
                                            </a>
                                        )

                                            : (
                                                <p className="body3 grey500 hover:text-white px-3 py-2 cursor-pointer">
                                                    {!access && <LockClosedIcon className="h-5 w-5 mr-2 inline" aria-hidden="true" />}
                                                    Plug & Play
                                                </p>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="align-items-lg-center ml-lg-auto xl:mt-2 hidden xl:block">
                                <div className="nav-search">
                                    <div className="flex flex-row items-center rounded-full bg-[#393939] pl-2">
                                        <div className="flex items-center">
                                            {
                                                access
                                                    ? (<SearchIcon className="h-4 w-4 mr-1 inline grey500" aria-hidden="true" />)
                                                    : (<LockClosedIcon className="h-4 w-4 mr-1 inline grey500" aria-hidden="true" />)
                                            }
                                        </div>
                                        <input
                                            ref={inputRef}
                                            placeholder="Search"
                                            type="search"
                                            value={value}
                                            onChange={onChange}
                                            disabled={!access}
                                            className="grey500 rounded-full bg-[#393939] text-white text-sm focus:ring-blue-500 focus:outline-none block w-full py-1.5 pr-1.5"
                                        />
                                    </div>
                                </div>
                                {!!focused && <ResultBox anchor={resultAnchor} results={searchResults} />}
                            </div>


                            <div className="navbar-nav-hover align-items-lg-center profile-nav-dropdown ml-auto hidden xl:block">
                                <div className="flex flex-row items-center">
                                    <div className="flex justify-center mr-2">
                                        <div className="mt-6 mb-5 self-center bg-[#393939] w-[50px] h-[50px] flex justify-center items-center rounded-full relative">
                                            {
                                                (image || userProfile?.data?.image
                                                    ? <img src={image || userProfile?.data?.image} alt="" className={`w-[50px] h-[50px] rounded-full object-cover object-top opacity-${(userProfile?.data?.image || image) ? 1 : 0}`} />
                                                    : <UserCircleIcon className="grey500" width={50} height={50} iconsize="2rem" />
                                                )
                                            }
                                            <label id="image" htmlFor="nav-image" className="text-white flex items-center justify-center w-[50px] h-[50px] rounded-full absolute z-10 top-0 left-0">
                                                {imageLoading && <Loader />}
                                                <input type="file" id="nav-image" alt="+ add photo" width="48" height="48" onChange={handleChange} className="h-0 w-0 opacity-0" />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="body3 text-white mr-1">
                                        {userProfile?.data?.name && access ? (
                                            <div>
                                                {userProfile?.data?.name}
                                            </div>
                                        ) : null
                                        }
                                    </div>
                                    <div className="flex flex row mr-2 pt-1">
                                        <Menu as="div" className="relative inline-block text-left">
                                            <div>
                                                <Menu.Button
                                                    className="w-full"
                                                >
                                                    <ChevronDownIcon className="-mr-1 ml-auto h-5 w-5 grey500" aria-hidden="true" />
                                                </Menu.Button>
                                            </div>

                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items className="origin-top-right absolute z-10 right-0 mt-2 w-56 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                    {!!isLoggedIn && (
                                                        <div className="grey800Bg text-white rounded-lg py-1">
                                                            <Menu.Item>
                                                                {({ active }) => (
                                                                    <div
                                                                        onClick={onClickProfile}
                                                                        className={
                                                                            classNames(
                                                                                active ? ' white' : 'grey500',
                                                                                'block px-4 py-2 text-sm cursor-pointer',
                                                                            )
                                                                        }
                                                                    >
                                                                        My Profile
                                                                    </div>
                                                                )}
                                                            </Menu.Item>
                                                            {
                                                                isAdmin && (
                                                                    <>
                                                                        {isAdmin
                                                    && (
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <div
                                                                    onClick={onClickAdmin}
                                                                    className={
                                                                        classNames(
                                                                            active ? ' white' : 'grey500',
                                                                            'block px-4 py-2 text-sm cursor-pointer',
                                                                        )
                                                                    }
                                                                >
                                                                    Admin
                                                                </div>
                                                            )}
                                                        </Menu.Item>
                                                    )}
                                                                    </>
                                                                )
                                                            }

                                                            <Menu.Item>
                                                                {({ active }) => (
                                                                    <div
                                                                        onClick={onClickLogout}
                                                                        className={
                                                                            classNames(
                                                                                active ? ' white' : 'grey500',
                                                                                'block px-4 py-2 text-sm cursor-pointer',
                                                                            )
                                                                        }
                                                                    >
                                                                        Log out
                                                                    </div>
                                                                )}
                                                            </Menu.Item>

                                                        </div>
                                                    )}
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>
                                    </div>
                                </div>
                            </div>

                            <div className="flex row items-center">
                                <div className="align-items-lg-center ml-lg-auto xl:hidden">
                                    <div className="nav-search">
                                        <div className="flex flex-row items-center rounded-full bg-[#393939] pl-2">
                                            <div className="flex items-center">
                                                {
                                                    access
                                                        ? (<SearchIcon className="h-4 w-4 mr-1 inline grey500" aria-hidden="true" />)
                                                        : (<LockClosedIcon className="h-4 w-4 mr-1 inline grey500" aria-hidden="true" />)
                                                }
                                            </div>
                                            <input
                                                ref={mobileInputRef}
                                                placeholder="Search"
                                                type="search"
                                                value={value}
                                                onChange={onChange}
                                                disabled={!access}
                                                className="grey500 rounded-full bg-[#393939] text-white text-sm focus:ring-blue-500 focus:outline-none block w-full py-1.5 pr-1.5"
                                            />
                                        </div>
                                    </div>
                                    {!!focused && <ResultBox anchor={resultAnchor} results={searchResults} />}
                                </div>

                                <div className="-mr-2 flex xl:hidden">
                                    {/* Mobile menu button */}
                                    <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                        <span className="sr-only">Open main menu</span>
                                        {open ? (
                                            <XIcon className="block h-6 w-6" aria-hidden="true" />
                                        ) : (
                                            <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                                        )}
                                    </Disclosure.Button>
                                </div>
                            </div>

                        </div>
                    </div>

                    <Disclosure.Panel className="xl:hidden md:w-10/12 md:mx-auto">
                        <div className="px-2 pt-2 pb-3 space-y-1 md:px-0">
                            {
                                access
                                    ? (
                                        <Disclosure.Button
                                            as="a"
                                            href="/tools"
                                            className="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium"
                                        >
                                            Tools
                                        </Disclosure.Button>
                                    )
                                    : (
                                        <p className="body3 grey500 hover:text-white px-3 py-2 cursor-pointer">
                                            {!access && <LockClosedIcon className="h-5 w-5 mr-2 inline" aria-hidden="true" />}
                                            Tools
                                        </p>
                                    )
                            }
                            {
                                access
                                    ? (
                                        <Disclosure.Button
                                            as="a"
                                            href="https://www.facebook.com/groups/agencynavigator"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                                        >
                                            Community
                                        </Disclosure.Button>
                                    )
                                    : (
                                        <p className="body3 grey500 hover:text-white px-3 py-2 cursor-pointer">
                                            {!access && <LockClosedIcon className="h-5 w-5 mr-2 inline" aria-hidden="true" />}
                                            Community
                                        </p>
                                    )
                            }
                            {
                                categories?.map(cat => (
                                    <div>
                                        {
                                            access
                                                ? (
                                                    <Disclosure.Button
                                                        onClick={() => !cat?.access && setShowAccessModal(true)}
                                                        as="a"
                                                        href={!cat?.access ? '#' : cat?.link}
                                                        className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                                                    >
                                                        {!cat?.access && <LockClosedIcon className="h-5 w-5 mr-2 inline" aria-hidden="true" />}
                                                        {cat?.name}
                                                    </Disclosure.Button>
                                                )
                                                : (
                                                    <p className="body3 grey500 hover:text-white px-3 py-2 cursor-pointer">
                                                        {!access && <LockClosedIcon className="h-5 w-5 mr-2 inline" aria-hidden="true" />}
                                                        {cat?.name}
                                                    </p>
                                                )
                                        }
                                    </div>
                                ))
                            }
                            {
                                access
                                    ? (
                                        <Disclosure.Button
                                            as="a"
                                            href="/resources/plug-and-play"
                                            className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                                        >
                                            Plug & Play
                                        </Disclosure.Button>
                                    )
                                    : (
                                        <p className="body3 grey500 hover:text-white px-3 py-2 cursor-pointer">
                                            {!access && <LockClosedIcon className="h-5 w-5 mr-2 inline" aria-hidden="true" />}
                                            Plug & Play
                                        </p>
                                    )
                            }
                        </div>
                        <div className="pt-4 pb-3 border-t border-gray-700">
                            <div className="flex items-center px-5 md:px-0">
                                <div className="flex justify-center flex-shrink-0">
                                    <div className="self-center bg-[#393939] w-[50px] h-[50px] flex justify-center items-center rounded-full relative">
                                        {
                                            (image || userProfile?.data?.image
                                                ? <img src={image || userProfile?.data?.image} alt="" className={`w-[50px] h-[50px] rounded-full object-cover object-top opacity-${(userProfile?.data?.image || image) ? 1 : 0}`} />
                                                : <UserCircleIcon className="grey500" width={50} height={50} iconsize="2rem" />
                                            )
                                        }
                                        <label id="image" htmlFor="nav-image" className="text-white flex items-center justify-center w-[50px] h-[50px] rounded-full absolute z-10 top-0 left-0">
                                            {imageLoading && <Loader />}
                                            <input type="file" id="nav-image" alt="+ add photo" width="48" height="48" onChange={handleChange} className="h-0 w-0 opacity-0" />
                                        </label>
                                    </div>
                                </div>
                                <div className="ml-3">
                                    <div className="text-base font-medium text-white">
                                        {userProfile?.data?.name && access ? (
                                            <div>
                                                {userProfile?.data?.name}
                                            </div>
                                        ) : null
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="mt-3 px-2 space-y-1 md:px-0">
                                <Disclosure.Button
                                    as="div"
                                    onClick={onClickProfile}
                                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                                >
                                    My Profile
                                </Disclosure.Button>
                                { isAdmin
                    && (
                        <Disclosure.Button
                            as="div"
                            onClick={onClickAdmin}
                            className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 cursor-pointer"
                        >
                            Admin
                        </Disclosure.Button>
                    )
                                }
                                <Disclosure.Button
                                    as="div"
                                    onClick={onClickLogout}
                                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 cursor-pointer"
                                >
                                    Log out
                                </Disclosure.Button>
                            </div>
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    );
};

export default withSearch(NavBar);
