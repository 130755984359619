import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

import image1 from '../../../../../assets/img/plugandplay/facebook-rules/1.png';
import image2 from '../../../../../assets/img/plugandplay/facebook-rules/2.png';
import image3 from '../../../../../assets/img/plugandplay/facebook-rules/3.png';
import image4 from '../../../../../assets/img/plugandplay/facebook-rules/4.png';
import image5 from '../../../../../assets/img/plugandplay/facebook-rules/5.png';
import image6 from '../../../../../assets/img/plugandplay/facebook-rules/6.png';
import image7 from '../../../../../assets/img/plugandplay/facebook-rules/7.png';
import image8 from '../../../../../assets/img/plugandplay/facebook-rules/8.png';
import image9 from '../../../../../assets/img/plugandplay/facebook-rules/9.png';
import image10 from '../../../../../assets/img/plugandplay/facebook-rules/10.png';
import image11 from '../../../../../assets/img/plugandplay/facebook-rules/11.png';
import image12 from '../../../../../assets/img/plugandplay/facebook-rules/12.png';
import image13 from '../../../../../assets/img/plugandplay/facebook-rules/13.png';
import image14 from '../../../../../assets/img/plugandplay/facebook-rules/14.png';
import image15 from '../../../../../assets/img/plugandplay/facebook-rules/15.png';

const sections = [
    {
        available: true,
        sectionName: 'What are Facebook rules, how to create them & which ones we use',
    },
    {
        available: true,
        sectionName: 'Automations Outside the Business Manager',
    },
    {
        available: true,
        sectionName: 'Our Naming Conventions Explained',
    },
];

const title = 'Facebook Rules, Automations & Naming conventions';

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]}  className="plug-block">  
                        <h2 className="mb-4">{title}</h2>
                            <div className="plug-block-subheading">
                                What are Facebook rules, how to create them & which ones we use
                            </div>
                            <p>
                                If you've followed this course closely, you will remember that we haven’t really
                                stated any fixed rules about how you should go about advertising. We think about
                                advertising as more of an art than a science and while science is riddled with hard
                                rules and a=b type situations, art is meant more to break the rules.
                            </p>
                            <p>
                                One of the missions of this bonus section is to not leave any concept unexplored. So
                                here are some of the things you can do in the business manager to accelerate
                                workflow and automate some of the ads managing process.
                            </p>
                            <p>This is how to think about a Facebook rule:</p>
                            <p>
                                You give a function (rule) some input and it will make some decisions based on that.
                            </p>
                            <img src={image1} alt="" />
                            <p>Let's explore each of these concepts while creating an actual Facebook rule:</p>
                            <p>
                                {' '}
                                <b>Input: </b>
                                {' '}
                                The input of a Facebook rule is what you want to apply the rule onto.
                                In this case it is one of the three core components of the ads manager:
                            </p>
                            <ul>
                                <li>Campaigns</li>
                                <li>Ad sets</li>
                                <li>Ads</li>
                            </ul>
                            <img src={image2} alt="" />
                            <p>
                                You also have to choose within those to which ones you wish to apply the rule to,
                                this is done through conditions. All the conditions have to be numeric and can only
                                be compared to fixed numbers. Meaning you are comparing one variable (like CPA,
                                ROAS, CPM, CTR, Amount spent) to a number that you manually input.
                            </p>
                            <img src={image3} alt="" />
                            <p>
                                You can also compare text, for example you could choose to only apply the rule in
                                campaigns that have in their name [IAG]
                            </p>
                            <img src={image4} alt="" />
                            <p>
                                You can combine conditions where two or more of them have to match for the campaign,
                                ad set or ad to be a valid input of the rule.
                            </p>
                            <img src={image5} alt="" />
                            There are many metrics you can choose from:
                            <div style={{ height: 350 }}>
                                <iframe
                                    id="videoPlayer"
                                    src="https://videos.sproutvideo.com/embed/799dd7ba1c14edc2f0/cedc27d3ed68bece?type=hd"
                                    frameBorder="0"
                                    allowFullScreen
                                    width="100%"
                                    height="100%"
                                />
                            </div>
                            <p>
                                <b>Rule:</b>
                                {' '}
                                The rule is divided into two parts, the schedule which tells you how
                                often it is going to run and the action which describes what it is going to do.
                            </p>
                            <p>
                                For the schedule you can run it every 30 minutes, daily or custom (once a week or at
                                different times each day)
                            </p>
                            <img src={image6} alt="" />
                            <p>
                                For the action you can adjust the budget, turn ad sets on and off or adjust manual
                                bids.
                            </p>
                            <img src={image7} alt="" />
                            <p>
                                <b>Output:</b>
                                {' '}
                                The output would be the action reflected on the ads manager ONLY in
                                the campaigns, ad sets or ads that matched the conditions you specified. Every time
                                a rule runs (according to the schedule) you will get an email notifying you of the
                                changes made.
                            </p>
                            <p>
                                <b>Example:</b>
                                {' '}
                                Let's explore an example so you can get a clear idea on how these
                                rules are used and how they work.
                            </p>
                            <p>
                                You can create a rule that - every 30 minutes -checks all ad sets to see if they've
                                spent at least 80 pounds AND have gotten at least one purchase to double their
                                budget. In this case their budget can only be doubled once a day.
                            </p>
                            <img src={image8} alt="" />
                            <p>
                                This rule reviews all the historic campaigns of the account and turns off all of
                                them that have [IAG] on their name.
                            </p>
                            <img src={image9} alt="" />
                            <p>
                                This rule turns off all campaigns that are below breakeven ROAS on the past 7 days.
                                It does so at the very beginning of every week.
                            </p>
                            <img src={image10} alt="" />
                            <p>
                                This rule notifies you at the end of the day which ad sets are below break even
                                ROAS.
                            </p>
                            <img src={image11} alt="" />
                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">Automations Outside the Business Manager</div>

                            <p>
                                Facebook Business has an API that allows developers and apps to control business
                                managers and accounts. This has spawned hundreds of software companies that help
                                with the management of the ad accounts with various claims like AI managing your
                                campaigns or better audiences or better strategies, etc. A lot of these tools can be
                                useful and I've heard great things about some. The main purpose of this article is
                                to let you know that IAG Media does not use
                                {' '}
                                <b>any</b>
                                {' '}
                                tool.
                            </p>

                            <p>
                                If we ever start using a tool it will be reported in the article that accompanies
                                this module.
                            </p>

                            <img src={image12} alt="" />
                        </div>

                        <div ref={refs[2]} className="plug-block">
                            <div className="plug-block-subheading">Our Naming Conventions Explained</div>

                            <p>
                                Naming conventions are important to have a clear understanding of what is what.
                                Especially when more than one person is going to manage or look at the account, this
                                has to be done by everyone every time they create or duplicate a campaign.
                            </p>

                            <h3>For Campaigns:</h3>

                            <p>
                                [IAG] Countries we're advertising to / Type of campaign # if there are more of that
                                type / Cold or hot (retargeting): Event we're optimizing for - offer
                            </p>
                            <img src={image13} alt="" />
                            <h3>For Ad Sets:</h3>

                            <p>
                                Interest or audience / placements / demographics - type of ad bundle # if it has
                                been duplicated which is this one
                            </p>

                            <p>
                                For lookalikes we just use LAL the percentage and the countries initials: LAL 1%
                                US,GB
                            </p>

                            <p>
                                For placements auto means automatic placements, we sometimes use Feeds and Stories
                                only as well.
                            </p>

                            <p>
                                Demographics we would put if there's something special like let's say only women
                            </p>

                            <p>
                                Type of ad bundle refers to what kind of ads are inside, this usually means if it's
                                the first round we've done ads or if its a bundle of the best ads in the past, etc
                            </p>
                            <img src={image14} alt="" />

                            <h3>For Ads:</h3>

                            <p>
                                We have the image or video and the headline. In this specific case we used IAG at
                                the beginning as well just because they had some ads running before we came in.
                            </p>

                            <img src={image15} alt="" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
