import { Auth } from 'aws-amplify';
import env from '../env';
import config from '../config';

const { url, region, type } = config[env].aws;

export default {
    url,
    region,
    auth: {
        type,
        credentials: () => Auth.currentCredentials(),
    },
    disableOffline: true,
};
