import React from 'react';
import './style.scss';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';
import CreatingYourCampaignImage from '../../../../../assets/img/plugandplay/creating-your-campaign.gif';
import CreatingYourAdsetImage from '../../../../../assets/img/plugandplay/creating-your-adset.png';
import CreatingYourAdsetImage1 from '../../../../../assets/img/plugandplay/creating-your-adset-1.png';
import CreatingYourAdsetImage2 from '../../../../../assets/img/plugandplay/creating-your-adset-2.png';
import CreatingYourAdsetImage3 from '../../../../../assets/img/plugandplay/creating-your-adset-3.png';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';

const sections = [
    {
        available: true, sectionName: 'Before you start',
    },
    {
        available: true, sectionName: 'Creating your campaign',
    },
    {
        available: true, sectionName: 'Creating your adset',
    },
    {
        available: true, sectionName: 'Creating your ad',
    },
    {
        available: true, sectionName: 'Creating your instant form',
    },
];

const title = "FaBo Local Businesses"

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (   
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                // selectedSection={selectedSection}
                                // setSelectedSection={onSelectSection}
                                // filter={filter}
                                // selectFilter={selectFilter}
                                // subFilter={subFilter}
                                // selectSubFilter={selectSubFilter}

                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]} className="plug-block">  
                        <h2 className="mb-4">{title}</h2> 

                        <div className="plug-block-subheading">Things to have ready before hand:</div>

                        <p>Have a clear cut offer that you have discussed with your client, never fall into promising more than he is willing to give and avoid problems by having loose ends.</p>
                        <p>Have your copy ready, as you saw on the ‘Becoming a Copy Writing King’ module yous should already have 2-3 high quality texts already written out.</p>
                        <p>Have your images/video ready, they must be original and eye-catching remember you are trying to make people stop from what they are doing.</p>


                    </div>

                    <div className="alert alert-primary" role="alert">
                        <blockquote className="blockquote">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi lacinia ligula nec ultrices viverra. Sed ut cursus nulla, et auctor nunc. Suspendisse quis convallis arcu, id varius massa. Phasellus est arcu, rutrum nec nulla sed, convallis sollicitudin justo. Aliquam porta orci diam, vel tempor risus semper condimentum
                            <footer className="blockquote-footer text-white">Iman Gadzhi</footer>
                        </blockquote>
                    </div>

                    <div className="alert alert-secondary text-gray-dark" role="alert">
                        <blockquote className="blockquote">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi lacinia ligula nec ultrices viverra. Sed ut cursus nulla, et auctor nunc. Suspendisse quis convallis arcu, id varius massa. Phasellus est arcu, rutrum nec nulla sed, convallis sollicitudin justo. Aliquam porta orci diam, vel tempor risus semper condimentum
                            <footer className="blockquote-footer">Iman Gadzhi</footer>
                        </blockquote>
                    </div>

                    <div ref={refs[1]} className="plug-block">
                        <div className="plug-block-subheading">Creating your campaign:</div>

                        <p>
                            As we have discussed before, we&nbsp;
                            <strong>only</strong>
                            {' '}
                            use the lead generation objective or conversion objective nothing else, nothing more. Lead generation for leads inside of Facebook, conversion optimized for leads when having an external clickfunnels landing page.
                        </p>

                        <p>
                            In this case we will create a native leadform inside of Facebook.
                        </p>
                        <ol>
                            <li>Go to your ads manager and on the top right corner ‘click’ on create. (green Button)</li>
                            <li>
                                Select Lead Generation and put a telling name on it.
                                <img src={CreatingYourCampaignImage} className="plug-and-play-image" />
                            </li>
                        </ol>


                    </div>
                    <div ref={refs[2]}  className="plug-block">
                        <div className="plug-block-subheading">Creating your adset:</div>

                        <p>Before putting a name on your adset do these steps:</p>

                        <ol>
                            <li>Select your clients Facebook Page on the drop down menu. If it is not there and your client has already given your business manager access, go to your business manager settings and follow this GIF.</li>
                            <li>
                                <strong>For the audience:</strong>
                                {' '}
                                Pin your clients brick and mortar business address, you can type it in or pin it on the map. Go 1-3 miles radious from it, think about how much are people willing to travel to go to your clients business, if it is a fancy restaurant you can go for 3, if it’s a gym you can go for 1 and so on. Make sure you have more than 50k people on your radius.
                                <img src={CreatingYourAdsetImage} className="plug-and-play-image" />
                            </li>
                            <li>
                                <strong>Only</strong>
                                {' '}
                                do obvious targeting, there is no need to narrow down, split test and none of what we consider high level targeting. Only do obvious things and keep it as broad as possible. With obvious things I am refering to gender and age, sometimes adding really broad obvious interests. For instance a hair salon contest should be sent to women, that’s it nothing else nothing more. A gym you can have a higher budget adset for men and a lower one for women. Don’t get fancy here.
                                <img src={CreatingYourAdsetImage1} className="plug-and-play-image" />
                                <p>This is enough targeting</p>
                                <img src={CreatingYourAdsetImage2} className="plug-and-play-image" />
                                <i>
                                    Adding very broad interest that do not narrow audience size sometimes lead to better results. Beauty is a 1B+ people interest

                                </i>
                            </li>
                            <li>
                                Placements: Only leave ‘ticked’ Facebook and Instagram feed. It is also okay if you want to leave instagram stories on.
                                <img src={CreatingYourAdsetImage3} className="plug-and-play-image" />
                            </li>
                            <li>
                                Budget, use the budget calculator. Keep in mind you want to give facebook enough money to bring you leads. Leads will vary between $5-$25 a lead. Depends on your niche!
                            </li>
                            <li>
                                Name your adset with a telling name, state the radius you went for, age range, gender, placements, etc. This will come in handy when reviewing after a few days.
                            </li>
                        </ol>

                    </div>

                    <div ref={refs[3]} className="plug-block">
                        <div className="plug-block-subheading">Creating your ad:</div>
                        <ol>
                            <li>Select your clients Facebook and Instagram page.</li>
                            <li>Select ‘Single image or video’.</li>
                            <li>Select your clients image or video.</li>
                            <li>Paste your copy, headline and description.</li>
                            <li>Let the link the same</li>
                            <li>Name you ad on a telling way, describing the image/video and title of your copy</li>
                        </ol>
                    </div>

                    <div ref={refs[4]}  className="plug-block">
                        <div className="plug-block-subheading">Creating your instant form:</div>
                        <ol>
                            <li>Select more volume</li>
                            <li>Use the same headline of the ad</li>
                            <li>State clearly what the offer is about</li>
                            <li>Request email, phone number and name on the form, this will be automatically filled out by Facebook, as we’ll show on the demo</li>
                            <li>
                                <a href="https://docs.google.com/document/d/10OLizMkxnbBTxLTfVRi3RgMIAd_7UF-shcfw-0D2Izw/edit?usp=sharing" target="_blank" rel="noopener noreferrer">Add your privacy and policy website</a>
                                , you can grab this if you are in the Uk, otherwise you can use this website to generate one:
                                {' '}
                                <a href="https://connectio.io/privacy-policy-generator/" target="_blank" rel="noopener noreferrer">Privacy Policy Generator</a>
                            </li>
                            <li>Fill out the thank you page thanking the costumer and reassuring he/she is now part of the offer.</li>
                        </ol>
                    </div>
                </Col>
            </Row>
        </Container>
    </PlugAndPlayWrapper>
)};
