import React from 'react';
import Loader from 'react-loader';

import './style.scss';

export default ({ children, loading, ...props }) => (
    <Loader
        loaded={!loading}
        color="#1882de"
        lines={8}
        length={1}
        width={10}
        radius={30}
        className="custom-loader"
        {...props}
    >
        { children }
    </Loader>
);
