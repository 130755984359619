import React from 'react';
import accounting from 'accounting';
import { PencilIcon } from '@heroicons/react/outline';


export default ({ client, selected, onClick, onEditClick }) => (
    <div
        style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0.5rem 2rem',
            marginBottom: '0.7rem',
            cursor: 'pointer'
        }}
        onClick={onClick}
    >
        <div 
            className="body1 flex row center"
            style={{
                color: selected ? 'white' : '#ADADAD',
            }}
        >
            {client.clientCustomerName}
            {selected && (
                <div onClick={onEditClick}>
                    <PencilIcon className="h-3 w-3 mt-1 ml-2" />
                </div>
            )}
        </div>
{/*        <div style={{
            fontWeight: 'bold',
            color: selected ? 'white' : '#ADADAD'
        }}
        >
            {`${
                accounting
                    .formatMoney(
                      client?.totalDebits?.USD,
                    )
                    .replace('.00', '')
            }`}
        </div>*/}
    </div>
);
