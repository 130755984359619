import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-responsive-modal';
import {
    Button, Card, Col, Form, FormGroup, Input, Label,
} from 'reactstrap';
import './style.scss';
import CurrencyInput from 'react-currency-input-field';
import { useMutation } from '@apollo/react-hooks';
import { useConfig } from '@genflow/core';
import CreateClientCustomerPaymentMutation from '../../../../../../api/adminCreateUpdateClientCustomerPayment';
import GetClientCustomerPaymentQuery from '../../../../../../api/getClientCustomerPayments';
import GetClientCustomersQuery from '../../../../../../api/getClientCustomers';
import LoadingButton from '../../../../../../components/LoadingButton';
import GetVendorLeaderboardQuery from '../../../../../../api/getVendorLeaderboard';
import getCognitoUserAttribute from '../../../../../../utils/getCognitoUserAttribute';
import AdminCreateUpdateClientCustomerPaymentMutation from '../../../../../../api/adminCreateUpdateClientCustomerPayment';
import getAdminClientCustomerPayments from '../../../../../../api/getAdminClientCustomerPayments';
import { trackEvent } from '../../../../../../utils/useAnalytics';


const InputSection = ({ index, instruction, children }) => (
    <div style={{ marginBottom: '2rem' }}>
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: '1rem',
        }}
        >
            {!!index && (
                <div style={{
                    background: 'black',
                    width: '2.5rem',
                    height: '2.5rem',
                    borderRadius: '2rem',
                    color: 'white',
                    marginRight: '1rem',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontWeight: 'bold',
                }}
                >
                    {index}
                </div>
            )}
            <div style={{ flex: 1 }}>
                {instruction}
            </div>
        </div>
        {children}
    </div>
);

const AdminReviewPaymentModal = ({
    selectedPayment, open, onClose, onReviewDecision, selectedUser,
}) => {
    const { vendorUuid } = useConfig();
    const metadata = selectedPayment ? JSON.parse(selectedPayment.metadata || '{}') : {};

    console.log('selectedPayment', selectedPayment);

    const [adminCreateUpdateClientCustomerPayment, { loading }] = useMutation(AdminCreateUpdateClientCustomerPaymentMutation, {
        refetchQueries: [{
            query: GetClientCustomerPaymentQuery,
            variables: {
                vendorUuid,
                clientCustomerUuid: selectedPayment?.clientCustomerId,
            },
        },
        {
            query: GetClientCustomersQuery,
            variables: {
                vendorUuid,
            },
        },
        {
            query: GetVendorLeaderboardQuery,
            variables: {
                vendorUuid,
            },
        },
        {
            query: getAdminClientCustomerPayments,
            variables: {
                vendorUuid,
                status: 'AWAITING_APPROVAL',
            },
        },
        ],
    });


    const onClickApprove = async () => {
        // eslint-disable-next-line no-restricted-globals
        const confirmed = confirm('Are you sure you would like to APPROVE this payment?');
        if (!confirmed) return;

        trackEvent({
            category: 'Review',
            action: 'Approve_Review',
            label: 'Approve',
        });

        const response = await adminCreateUpdateClientCustomerPayment({
            variables: {
                vendorUuid,
                clientCustomerUuid: selectedPayment?.clientCustomerId,
                clientCustomerPayment: {
                    id: selectedPayment?.id,
                    cognitoSub: getCognitoUserAttribute(selectedUser?.cognitoUser, 'sub'),
                    metadata: JSON.stringify({
                        ...metadata,
                        gyaPaymentStatus: 'APPROVED',
                    }),
                },
            },
        });

        onReviewDecision();
    };

    const onClickReject = async () => {
        // eslint-disable-next-line no-restricted-globals
        const confirmed = confirm('Are you sure you would like to REJECT this payment?');
        if (!confirmed) return;
        const rejectionReason = prompt('Would you like to provide any reason for the rejection?');
        console.log('rejectionReason', rejectionReason);

        trackEvent({
            category: 'Review',
            action: 'Reject_Review',
            label: 'Reject',
        });

        const response = await adminCreateUpdateClientCustomerPayment({
            variables: {
                vendorUuid,
                clientCustomerUuid: selectedPayment?.clientCustomerId,
                clientCustomerPayment: {
                    id: selectedPayment?.id,
                    cognitoSub: getCognitoUserAttribute(selectedUser?.cognitoUser, 'sub'),
                    metadata: JSON.stringify({
                        ...metadata,
                        gyaPaymentStatus: 'REJECTED',
                        rejectionReason,
                    }),
                },
            },
        });

        console.log('response', response);
        onReviewDecision();
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            center
        >
            <div style={{
                // padding: '1rem'
            }}
            >
                <div style={{
                    fontSize: '1.5rem',
                    fontWeight: '750',
                }}
                >
                    {`${getCognitoUserAttribute(selectedUser?.cognitoUser, 'given_name')}'s payment`}
                </div>
                <div style={{
                    marginBottom: '1rem',
                }}
                >
                    Approve or Reject
                </div>

                <InputSection
                    index={1}
                    instruction="Please film a loom with proof of Stripe, PayPal or Bank payment from client. Also refresh the page. (This will NOT be shared with anyone except for GYA, to authentic the deal & add this to your tally for the yearly leaderboard prizes.)"
                >
                    <Input
                        disabled
                        editable={false}
                        placeholder="Loom URL..."
                        type="url"
                        value={metadata?.loomURL}
                    />
                </InputSection>

                <InputSection
                    index={2}
                    instruction="How much is this deal (In $USD)?"
                >
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                    >
                        <CurrencyInput
                            disabled
                            className="form-control"
                            prefix="$"
                            placeholder="$1,000"
                            decimalsLimit={2}
                            style={{
                                width: '10vw',
                                marginRight: '1rem',
                            }}
                            value={selectedPayment?.totalValue}
                        />
                        <Form>
                            <FormGroup check inline>
                                <Label check>
                                    <Input
                                        disabled
                                        type="radio"
                                        name="gyaPaymentType"
                                        checked={metadata?.gyaPaymentType === 'ONE_TIME_FEE'}
                                    />
                                    {' '}
                                    One-time Fee
                                </Label>
                            </FormGroup>
                            <FormGroup check inline>
                                <Label check>
                                    <Input
                                        disabled
                                        type="radio"
                                        name="gyaPaymentType"
                                        checked={metadata?.gyaPaymentType === 'PERFORMANCE_FEE'}
                                    />
                                    {' '}
                                    Performance Fee
                                </Label>
                            </FormGroup>
                            <FormGroup check inline>
                                <Label check>
                                    <Input
                                        disabled
                                        type="radio"
                                        name="gyaPaymentType"
                                        checked={metadata?.gyaPaymentType === 'ROLLING_MONTHLY'}
                                    />
                                    {' '}
                                    Rolling Monthly Retainer
                                </Label>
                            </FormGroup>
                        </Form>

                    </div>
                </InputSection>

                <InputSection
                    index={3}
                    instruction="How did you manage to secure a meeting with this client?"
                >
                    <Input
                        disabled
                        placeholder="I secured a meeting by..."
                        type="textarea"
                        value={metadata?.securingMeetingStrategy}
                    />
                </InputSection>

                <InputSection
                    index={4}
                    instruction="What was your sales process like? One call, two, many?"
                >
                    <Input
                        disabled
                        placeholder="My sales process involved..."
                        type="textarea"
                        value={metadata?.salesProcessStrategy}
                    />
                </InputSection>

                <InputSection
                    index={5}
                    instruction="What objections (if any) did they have and how did you overturn them?"
                >
                    <Input
                        disabled
                        placeholder="Some of the objections I received are..."
                        type="textarea"
                        value={metadata?.receivedObjections}
                    />
                </InputSection>

                <InputSection
                    index={6}
                    instruction="What is one piece of advice you would share this week with the community?"
                >
                    <Input
                        disabled
                        placeholder="One piece of advice I would love to share..."
                        type="textarea"
                        value={metadata?.communityAdvice}
                    />
                </InputSection>

                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    margin: '0 0 1rem 1rem',
                }}
                >
                    <Input
                        disabled
                        type="checkbox"
                        checked={metadata?.isAuthorised}
                    />
                    <div>
                        I authorise GrowYourAgency to share my amazing client win with the community to help other students on their journey & so we can all celebrate wins together.
                    </div>
                </div>

                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                >
                    <LoadingButton
                        color="success"
                        type="button"
                        onClick={onClickApprove}
                    >
                        Approve
                    </LoadingButton>
                    <LoadingButton
                        color="danger"
                        type="button"
                        onClick={onClickReject}
                    >
                        Reject
                    </LoadingButton>

                </div>
            </div>
        </Modal>
    );
};

AdminReviewPaymentModal.propTypes = {

};

export default AdminReviewPaymentModal;
