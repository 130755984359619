import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        available: true, sectionName: 'Paid Traffic',
    },
    {
        available: true, sectionName: 'SEO',
    },
    {
        available: true, sectionName: 'Content Creation',
    },
    {
        available: true, sectionName: 'Social Media Management',
    },
    {
        available: true, sectionName: 'Website Design',
    },
    {
        available: true, sectionName: 'eCommerce',
    },
    {
        available: true, sectionName: 'Real Estate',
    },
    {
        available: true, sectionName: 'Watch Dealers',
    },
    {
        available: true, sectionName: 'Home Improvement',
    },
    {
        available: true, sectionName: 'Saas',
    },
];

const title = "LinkedIn Keywords"

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]}  className="plug-block">  
                        <h2 className="mb-4">{title}</h2>
                            <p>
                                Make sure to include the suggested keywords in your profile headline and summary.
                            </p>

                            <div className="plug-block-subheading">
                                Paid Traffic (Including Facebook & Google Advertising)

                            </div>

                            <ul>
                                <li>
                                    Facebook Advertising
                                </li>
                                <li>Google Advertising</li>
                                <li>
                                    Instagram Advertising
                                </li>
                                <li>
                                    Facebook Ads
                                </li>
                                <li>Google Ads</li>
                                <li>
                                    Instagram Ads
                                </li>
                                <li>Cost per click</li>
                                <li>CBO</li>
                                <li>Funnel</li>
                                <li>Ecommerce</li>
                                <li>
                                    Real estate Facebook advertising
                                </li>
                                <li>
                                    ecommerce website design
                                </li>
                                <li>
                                    ecommerce seo agency
                                </li>
                                <li>
                                    ecommerce seo services
                                </li>
                                <li>ecommerce seo company</li>
                                <li>
                                    ecommerce packaging
                                </li>
                                <li>ecommerce photography</li>
                                <li>
                                    ecommerce uk
                                </li>
                                <li>
                                    ecommerce consultant
                                </li>
                                <li>ecommerce marketing agency</li>
                                <li>
                                    ecommerce manager
                                </li>
                                <li>
                                    zp ecommerce
                                </li>
                                <li>ecommerce ppc</li>
                                <li>
                                    ecommerce seo audit
                                </li>
                                <li>
                                    ecommerce web designers
                                </li>
                                <li>
                                    ecommerce expo
                                </li>
                            </ul>
                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">SEO</div>

                            <ul>
                                <li>
                                    SEO Marketing
                                </li>
                                <li>SEO</li>
                                <li>
                                    On page SEO
                                </li>
                                <li>
                                    SEO Agency
                                </li>
                                <li>
                                    Backlinks
                                </li>
                                <li>
                                    SEO Consultant
                                </li>
                                <li>
                                    SEO Optimisation
                                </li>
                            </ul>
                        </div>

                        <div ref={refs[2]} className="plug-block">
                            <div className="plug-block-subheading">
                                Content Creation
                            </div>

                            <ul>
                                <li>
                                    Content Creator

                                </li>
                                <li>Photography</li>
                                <li>
                                    Videography

                                </li>
                                <li>
                                    Editor
                                </li>
                                <li>
                                    User generated content

                                </li>
                                <li>
                                    TikTok Content

                                </li>
                                <li>
                                    Facebook Content

                                </li>

                                <li>
                                    Instagram Content
                                </li>
                            </ul>
                        </div>

                        <div ref={refs[3]} className="plug-block">
                            <div className="plug-block-subheading">
                                Social Media Management
                            </div>

                            <ul>
                                <li>
                                    Social media manager

                                </li>
                                <li>
                                    Social media management
                                </li>
                                <li>
                                    Instagram growth


                                </li>
                                <li>
                                    TikTok Growth

                                </li>
                                <li>
                                    Twitter management


                                </li>


                            </ul>
                        </div>

                        <div ref={refs[4]} className="plug-block">
                            <div className="plug-block-subheading">
                                Website Design

                            </div>

                            <ul>
                                <li>
                                    Social media manager


                                </li>
                                <li>
                                    Social media management

                                </li>
                                <li>
                                    Instagram growth


                                </li>
                                <li>
                                    TikTok Growth


                                </li>
                                <li>
                                    Twitter management


                                </li>


                            </ul>
                        </div>

                        <div ref={refs[5]} className="plug-block">
                            <div className="plug-block-subheading">
                                eCommerce

                            </div>

                            <ul>
                                <li>
                                    Ecommerce
                                </li>
                                <li>
                                    Ecom
                                </li>
                                <li>
                                    CRO
                                </li>
                                <li>
                                    Checkout
                                </li>
                                <li>
                                    Abandoned carts
                                </li>
                            </ul>
                        </div>

                        <div ref={refs[6]} className="plug-block">
                            <div className="plug-block-subheading">
                                Real Estate
                            </div>

                            <ul>
                                <li>
                                    real estate lawyer

                                </li>
                                <li>
                                    real estate jobs

                                </li>
                                <li>
                                    real estate attorney

                                </li>
                                <li>
                                    real estate apprenticeship

                                </li>
                                <li>
                                    how to become a real estate agent

                                </li>
                                <li>
                                    real estate agent salary uk
                                </li>
                                <li>
                                    real estate license uk
                                </li>
                                <li>
                                    real estate agent salary
                                </li>
                                <li>
                                    real estate developer
                                </li>
                                <li>
                                    real estate band
                                </li>
                                <li>
                                    real estate law
                                </li>
                                <li>
                                    real estate finance
                                </li>
                                <li>
                                    real estate courses
                                </li>
                            </ul>
                        </div>

                        <div ref={refs[7]} className="plug-block">
                            <div className="plug-block-subheading">
                                Watch Dealers

                            </div>

                            <ul>
                                <li>
                                    swiss watch dealers
                                </li>
                                <li>
                                    watch dealers


                                </li>
                                <li>
                                    watch dealers london


                                </li>
                                <li>
                                    watch dealers uk


                                </li>
                                <li>
                                    vintage watch dealers


                                </li>
                                <li>
                                    rolex watch dealers

                                </li>
                                <li>
                                    hatton garden watch dealers

                                </li>
                                <li>
                                    watch dealers near me
                                </li>
                                <li>
                                    second hand watch dealers

                                </li>
                                <li>
                                    antique watch dealers uk

                                </li>
                                <li>
                                    vintage watch dealers uk

                                </li>
                                <li>
                                    watch dealers manchester

                                </li>
                                <li>
                                    vintage watch dealers london

                                </li>
                                <li>
                                    swiss watch dealers review
                                </li>
                            </ul>
                        </div>

                        <div ref={refs[8]} className="plug-block">
                            <div className="plug-block-subheading">
                                Home Improvement

                            </div>

                            <ul>
                                <li>
                                    home improvement loans

                                </li>
                                <li>
                                    home improvement grants

                                </li>
                                <li>
                                    home improvement loan

                                </li>
                                <li>
                                    home improvement loans uk

                                </li>
                                <li>
                                    government home improvement

                                </li>
                                <li>
                                    disability grants for home improvement

                                </li>
                                <li>
                                    home improvement loan calculator

                                </li>
                                <li>
                                    loan for home improvement
                                </li>
                                <li>
                                    total home improvement

                                </li>
                                <li>
                                    best home improvement loans

                                </li>
                                <li>
                                    home improvement companies

                                </li>
                                <li>
                                    home improvement mortgage

                                </li>
                                <li>
                                    home improvement grants wales

                                </li>
                                <li>
                                    home improvement ideas uk

                                </li>
                                <li>
                                    home improvement centre
                                </li>
                                <li>
                                    home improvement contractors
                                </li>
                                <li>
                                    home improvement protection
                                </li>
                                <li>
                                    home improvement center
                                </li>
                                <li>
                                    home improvement episodes
                                </li>
                                <li>
                                    home improvement blog
                                </li>
                                <li>
                                    home improvement financing
                                </li>
                            </ul>
                        </div>

                        <div ref={refs[8]} className="plug-block">
                            <div className="plug-block-subheading">
                                Saas
                            </div>

                            <ul>
                                <li>
                                    saas funding

                                </li>
                                <li>
                                    saas fee

                                </li>
                                <li>
                                    saas marketing

                                </li>
                                <li>
                                    saas sales

                                </li>
                                <li>
                                    contact saas

                                </li>
                                <li>
                                    saas email

                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
