import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import { Link } from 'react-router-dom';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        available: true,
        sectionName: 'Why should you care about creatives?',
    },
    {
        available: true,
        sectionName: 'What sort of differences can the creatives make?',
    },
    {
        available: true,
        sectionName: 'How creative can you get? Our method to come up with angles on the spot.',
    },

];

const title = 'Science Behind A Creative That Sells';

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]}  className="plug-block">  
                        <h2 className="mb-4">{title}</h2>
                            <div className="plug-block-subheading">Why should you care about creatives?</div>

                            <p>
                                We are not a creative agency, and although IAG Media had its roots a while ago as a creative agency where I would go out and do photoshoots for clients and create content for them, I quickly realized that it didn't have any discernible ROI. Or does it?
                            </p>

                            <p>As I've mentioned many times by now, every single one of us is on a leveled playing field. This means that we all have access to the same tools on the ads manager, same audiences and we can all do the same things inside the ads manager. Now, like in everything in life there's some clear winners and some clear losers. One of the biggest things that differentiate the winners from the losers in marketing is the marketing message. The marketing message is how you choose to communicate with prospective customers. It is the idea behind the copy, the idea behind the image or video, the idea behind the funnel.</p>

                            <p>
                                Every product that sells has a group of people that will buy. This group of people buy from them not only because of their product but also how it is branded. If the message speaks to them and they feel like they're been spoken to, they will most likely buy from them. The main point I want to get across is that one of the most important things in marketing in general is the angle, the way you communicate and more specifically in our marketing world this is the copy written for the ad, the image or video that you run and the funnel.
                            </p>

                            <p>
                                If what you write is inline with what your target audience has in mind then you're in a good spot. People will resonate with the product, will know it's right for them, they can even feel identified with the texts and images, almost as if they were written precisely for them. Of course a lot of these processes are subconscious. As we've seen on the copy module, the way you write is very important and has to be precisely calculated, the angle that you use, the words you choose, the length of the text, the colors, the emojis, everything enters into play.
                            </p>

                            <p>
                                To see definitive proof of the impact that your copy and creative makes you will need to look at the CTR's. CTR stands in for Click Through Rate and is one of the many metrics that Facebook uses to determine the effectiveness of your ad. In simpler terms CTR is the amount of people that click your ad out of 100 in percentage, if your CTR is 1% this means that 1 in 100 people clicked your ad. The formula looks something like this.
                            </p>

                            <p>
                                $CTR = (Clicks/Impressions)*100$
                            </p>

                            <p>
                                There is also another metric that is very important in regards to how good the ads are. This metric is called CPM which stands for Cost Per 1000 impressions. In other words it tells you how much you're paying Facebook for them to show your ads to 1000 people. This is how the formula looks like.
                            </p>

                            <p>
                                $CPM = (Adspend*1000)/Impressions$
                            </p>

                            <p>Let's have an example where you had 3000 impressions, and your ad spend is $20. If we plug in the numbers in the formula we get $6.66. If our ad spend had been $30 it would've been $10 and so on...</p>
                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">What sort of differences can the creatives make?</div>

                            <p>
                                Now that we've established what these two metrics are, let's show how they relate to the creative itself. CTR is directly correlated with how good your ad is. For example a good ad will make 3 out of 100 people (on average) click your ad, meanwhile a mediocre ad can get only 1 to click. A bad ad will get 0,5 or 0,3.
                            </p>

                            <p>
                                Doing some lazy mathematics and not taking into account other variables that come into play that we will touch on soon this almost means that the good ad will make 3 times more money than the mediocre one. As there are 3 times as many people getting into the funnel. (Be it ecomm store, info-funnel, lead page, squeeze page, tripwire, whatever it is) With similar logic, if you're paying Facebook $12 dollars in CPM and someone else is paying $6 dollars then your ad will be shown to half as many people and with the same lazy mathematics this means you'd be making half the money the other person is making.
                            </p>

                            <p>
                                This starts to become interesting when you look at the two metrics together. Let's compare two scenarios. In scenario #1 you're very good with your ads and you're getting a 3% CTR, people really like your ad, which in turn means that Facebook really likes it as well. Facebook gives you a CPM of $6 dollars. On scenario #2 you're very bad with your ads and luck isn't really on your side, your CTR is 1% and Facebook is giving you a CPM of $12. Because these two metrics are directly correlated by one variable (impressions) Let's look at the formula again.
                            </p>

                            <p>
                                This means they compound. In scenario 1 you're getting 3 times as many people to click and your ads are being shown to twice as many people. This means in the end that 6 times as many people are going through your funnel. This is massive and in the end is what really differentiates good marketers from bad ones, it is what differentiates good agencies from bad ones, so you better make sure you're on the right side of this play.
                            </p>

                            <p>
                                To clean up some of the math you have to take into account that these numbers only translate into sales or leads if the conversion rate of your funnel holds up. To make it even more crazy if you improve this metrics but you also improve the conversion rate of your funnel also compounds, for instance let's pick the last example again and let's also say that by changing the copy on your product page (in the case of an ecommerce store) changing the images and layout you manage to get your conversion rate from 1% to 2%. This will ultimately mean that you'll make 12 times more money. These are the key differences and the fundamentals on how the numbers work, if you get this right the money will follow as you've seen. Clearly this is not a matter of just knowing the theory you have to actually be able to go out there and make this happen. We've covered many things that will help you with that throughout this module and we will continue to do so on the next ones.
                            </p>

                            <p>
                                Before we get into some of the more actionable tips to improve your creatives/ads let's quickly visit what conversion rate means as we haven't defined it before.
                            </p>

                            <p>
                                Conversion similarly to CTR is a percentage that means how many people did a desired action in your funnel. It is often referred to as the conversion rate on a purchase, which means how many people buy out of 100. A 1% conversion rate means that out of 100 people that visit a funnel 1 buys.
                            </p>

                            <p>
                                The formula looks like this:
                            </p>

                            <p>
                                $ConvRate = purchases/visitors$
                            </p>

                            <p>
                                This is outside the control of the ads and the ads manager. Although that is not exactly true as if you send people to a website that is totally different from what you offered in the ad, conversion rate will be hit. Think about it as if you were sent to a website because you wanted to buy skincare but you end up on a motorcycle store, most likely you won't buy and conversion rate will be hit.
                            </p>

                            <p>
                                We will talk more in depth about all this and do a recap on the module the three king metrics. But for now keep in mind that they are extremely important, that they work together and that you should improve them to make your ads work.
                            </p>
                        </div>

                        <div ref={refs[2]} className="plug-block">
                            <div className="plug-block-subheading">How creative can you get? Our method to come up with angles on the spot.</div>

                            <p>
                                Not everyone is very creative and can come up with different angles on the spot. But someone has to do it. Even if it is not you, there has to be someone on your team that is good at this, if there isn't or you don't have a team yet you will need to learn the skill yourself. Everything can be learnt! Be open to that idea and never close off to anything. I can't count the amount of times I've seen people closing up to new concepts because they label themselves as incapable from the get go. "I am not good at maths", "I am not good with computers", "I am not good with language". I am not going to deny that there's many things that will be more difficult for you than others, but a lot of the time the mere fact that you are close to the new concepts is the reason why you are not good at them. So let's get creative. One of the best ways of coming up with new angles for creatives and copy is inspiration, you have to be constantly seeking for new ideas on the internet, on your day to day and specially on your previous experiences. To get started you can start browsing the ads library:
                                <a href="https://www.facebook.com/ads/library" target="_blank" rel="noopener noreferrer">https://www.facebook.com/ads/library</a>
                            </p>

                            <p>
                                You can also read books like this one, they give you a good sense and principles of advertising that will be there even if it's TV ads, posters on the street, radio, social media ads, VR ads in the future or whatever it is
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/scienceCreative1.png" alt="" />

                            <p>
                                Let's run through a couple examples seemingly difficult on how you could come up with 3 different angles that will appeal to different people and why:
                            </p>

                            <p>
                                Product: A device that helps you train your breathing
                            </p>

                            <p>
                                Let's think about three different angles for this one, for it you have to think about three main things. [1] Scenarios where people would use the product or service, [2] What kind of people would use the product or service and [3] How and why the people would use the product or service.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/scienceCreative2.png" alt="" />

                            <p>
                                <span className="bold">Angle 1:</span>
                                {' '}
                                Advertised to high performing athletes that want to get an edge over the competition. This seems the angle they're going for, they market it as a way of getting an edge over the competition. You can appeal to this kind of audience with an angle that mentions competition and how top athletes use the device to train their respiratory system, you can have many sub-niches that will count as different angles as well. For example specifically appealing to track runners saying that it can help with their max VO2 or cyclist or others.
                            </p>

                            <p>
                                Example: "After 1 week I set a new Functional Threshold Power during a race, whilst matching my previous maximum 20 minute power output for an entire 40 minutes."
                            </p>

                            <p>
                                <span className="bold">Angle 2:</span>
                                {' '}
                                Biohackers that care about their well being, to this audience you'll need to appeal with the longevity and well being angle explaining all the sciency stuff behind the device. Ideally mentioning for example that it can lower your resting heart rate or strengthen your lungs.
                            </p>

                            <p>
                                Example: “IMT using threshold devices improves inspiratory muscle strength, exercise capacity and quality of life, decreases dyspnea.”
                            </p>

                            <p>
                                Ideally you'd have a landing page or a page where you explain the benefits and give actual sources on why this product works.
                            </p>

                            <p>
                                <span className="bold"> Angle 3:</span>
                                {' '}
                                Fitness and convenience enthusiast. You can also market it as a way to replace part of your workouts or something to use in recovery days for normal day to day enthusiasts that don't compete. In this case the angle should be less aggressive and more geared toward convenience and technology.
                            </p>

                            <p>
                                Example: "Boost your recovery! Improve muscle oxygenation from the comfort of your home with only 20 minutes a day and speed up recovery while improving relaxation at the same time" A good idea would also be to write an example workout and progression of the workout over time.
                            </p>

                            <p>
                                <span className="bold">Angle 4:</span>
                                {' '}
                                Asthma and COPD, for people that suffer from these conditions you would have to angle specifically at the conditions and explain how it helps, adding testimonials of people that benefited from the device is very important. Also adding science back up is a must as people that have specific illnesses are very aware about them and their mechanisms of action.
                            </p>

                            <p>
                                Angle 5: Covid angle? for the "health aware population'' you can angle it as a way to strengthen your lungs so they are better equipped when they face adversity, etc.
                            </p>

                            <p>
                                Product: Sun protective clothing
                            </p>

                            <p>
                                <span className="bold">Angle 1:</span>
                                {' '}
                                The first angle is geared towards people that live in very sunny weather. In this case you'll need to focus on the sun protection side of things but mostly mention everyday activities that they can relate to and how the product is useful for them. Like going to the store on a sunny day or going to the beach.
                            </p>

                            <p>
                                <span className="bold">Angle 2:</span>
                                {' '}
                                As the main feature of this product is it's sun protection, the second angle is geared toward skin cancer. This could have two approaches, one more fear based that explains that skin cancer is really bad or one more preventative that doesn't go in depth about skin cancer itself but how the product helps prevent it. You can angle it as something like, does your clothes need sunscreen as well? It is clear that the target is someone concerned about the sun as it assumes they use sunscreen in their skin. Warning: I don't think the sun causes skin, do your own research on the matter. Don't use google as it usually won't bring the factual results but more what would get clicks
                            </p>

                            <p>
                                <span className="bol">Angle 3:</span>
                                {' '}
                                Fishers. For fishing as a hobby or job, one of the main things is that you will need to be in the sun for extended periods of time on average 6 hours. You can use their lingo for this angle and on the creative side, for example show boats and fish and people using the clothes while fishing.
                            </p>

                            <p>
                                <span className="bold">Angle 4:</span>
                                {' '}
                                Outdoor sports and adventures. For example beach volleyball, surfing, trekking, etc. Show more active stuff, talk about the piece being breathable and the added benefits of the sun protection.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/scienceCreative3.png" alt="" />

                            <p>
                                I could go on and on about angles for these products or for any for that matter, remember that you want to think about the people and their interaction with the product. You can also combine some of the angles and create new ones that appeal to a broader audience. This is useful when you are dealing with extremely niche audiences that are very small. Although there's a few niches that are "too small", believe me we've worked in some weird niches and there's people for everything!
                            </p>

                            <p className="bold">
                                How often should you think and create new creatives/angles?
                            </p>

                            <p>
                                The very short answer is when they stop working. And this opens up to a broader conversation of strategy in general. We will go more in depth about this after we've explained how to launch ads and manage them, but as a rule of thumb remember that you don't touch what is working, in business, in life and especially when running ads. Now this doesn't mean that you need to wait for everything to go to sh&it before you try new ads, you want to have an active approach to it. There's another rule and that is that everything no matter how good your ad is, will one day die. It could last a week, a month or even a year, but they will die and if you're not ready for it you'll have to pay the consequences, literally as you'll lose money on the ads and potential money on lost returns. So how do you front run their inevitable demise? Simply, you try ads as often as you can. In the agency we refresh our ads every month for retargeting (which since iOS 14 we don't run much of) and for cold weather with test new ads approximately once a month, although we do it more often with clients that can provide creatives faster. This could mean once every two weeks. To be very specific, testing new angles means testing new ideas, new copy, new images, new videos. We will go in depth on how to structure these tests on the ads manager. Before that what you'll need to have ready when you're going to test is a new batch of ads. This could be one and up to 4 at a time.
                            </p>

                            <p className="bold">
                                Examples of good vs bad creatives
                            </p>

                            <p>
                                Plug n play:
                                {' '}
                                <Link to="/resources/plug-and-play/ad-selection">View here</Link>
                            </p>

                            <p className="bold">
                                Action items
                            </p>

                            <p>
                                With one of your clients or one of your favorite brands create 4 angles that market to different people, you don't have to write the copy yet or know how to do it. For now just think about the general idea, the marketing message.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
