import React from 'react';

export default ({ LeftComponent, RightComponent }) => (
    <div style={{
        padding: '2rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom:'0.5rem',
        marginBottom: '0.7rem'
    }}
    >
        {LeftComponent}
        {RightComponent}
    </div>
);
