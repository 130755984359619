import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-responsive-modal';
import { Button, Card, Input } from 'reactstrap';
import './style.scss';

const FirstPaymentSuccessModal = ({ open, onClose }) => (
    <Modal
        open={open}
        onClose={onClose}
        center
    >
        <div style={{
            // padding: '1rem'
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }}
        >
            <div style={{
                fontSize: '1.5rem',
                fontWeight: '750',
                // textAlign: 'center'
            }}
            >
                First Client Payment! ✓
            </div>
            <div style={{
                // fontSize: '1.5rem',
                // fontWeight: '750',
                // textAlign: 'center',
                margin: '2rem 0',
            }}
            >
                Congrats on signing your new client! Our team will
                {' '}
                <span style={{
                    color: '#65B456',
                    fontWeight: 'bold',
                }}
                >
                    green light
                </span>
                {' '}
                your de-brief as long as the evidence all checks out within the next 24-48 hours.
                <br />
                <br />
                From there, it’ll automatically show up on your profile as well as public leaderboards! The top 10 students a year win MASSIVE prizes.
            </div>
            <Button color="primary" type="button" onClick={onClose}>
                Let's Get It!
            </Button>
        </div>
    </Modal>
);

FirstPaymentSuccessModal.propTypes = {

};

export default FirstPaymentSuccessModal;
