import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        available: true, sectionName: 'Week 1 - Monday',
    },
    {
        available: true, sectionName: 'Week 1 - Wednesday',
    },
    {
        available: true, sectionName: 'Week 1 - Thursday',
    },
    {
        available: true, sectionName: 'Week 2 - Monday',
    },
    {
        available: true, sectionName: 'Week 2 - Wednesday',
    },
    {
        available: true, sectionName: 'Week 2 - Thursday',
    },

];

const title = "Example Linkedin Content Schedule"
export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title="Example Linkedin Content Schedule">
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                // selectedSection={selectedSection}
                                // setSelectedSection={onSelectSection}
                                // filter={filter}
                                // selectFilter={selectFilter}
                                // subFilter={subFilter}
                                // selectSubFilter={selectSubFilter}

                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]} className="plug-block">
                            <h2 className="mb-4">Example Linkedin Content Schedule</h2>
                            
                          
                            <div className="plug-block-subheading">
                                Week 1 - Monday
                            </div>

                            <img src="https://img.genflow.com/gya/plugandplays/example-linkedin-content-schedule-1-d5f1f591-jpg.jpg" alt="" style={{ marginBottom: '1rem' }} />

                            <p>
                                What is the value of a (positive or negative) review?
                            </p>

                            <p>
                                It’s actually possible to quantify using THIS method.
                            </p>

                            <p>
                                Whilst most digital marketers agree positive reviews are vital for social proof - there’s always been a reluctance to ascribe an actual value to reviews.
                            </p>

                            <p>
                                But according to a Google consumer survey from 2015 - there’s an easy way to measure the impact of a negative review.
                            </p>

                            <p>
                                The survey found that a single negative review appearing in your SERP can turn off up to 22% of customers.
                            </p>

                            <p>
                                It also found that:
                            </p>

                            <ul>
                                <li>
                                    Three negative reviews can put off 59% of customers
                                </li>
                                <li>Four negative reviews can put off 70% of customers</li>
                            </ul>

                            <p>
                                Meanwhile if your business has excellent reviews - customers will typically spend 31% more.
                            </p>

                            <p>
                                If you’re considering implementing a review or feedback system on your website, or offering an incentive for customers to provide honest feedback - you can use this as a guideline for your investment.
                            </p>

                            <p>
                                Has this post changed your mind on the importance of positive reviews? Comment below and let me know 👇
                            </p>

                            <p>
                                #socialproof #CRO
                            </p>
                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">Week 1 - Wednesday</div>

                            <img src="https://img.genflow.com/gya/plugandplays/example-linkedin-content-schedule-2-4cc93c8f-jpg.jpg" alt="" style={{ marginBottom: '1rem' }} />

                            <p>
                                Do you agree?
                            </p>

                            <p>I think copywriting - like any other digital marketing practice - has the potential to be used irresponsibly. </p>

                            <p>
                                #copywriting
                            </p>

                        </div>
                        <div ref={refs[2]} className="plug-block">
                            <div className="plug-block-subheading">
                                Week 1 - Thursday
                            </div>

                            <img src="https://img.genflow.com/gya/plugandplays/example-linkedin-content-schedule-3-c7232cc3-jpg.jpg" alt="" style={{ marginBottom: '1rem' }} />

                            <p>
                                If you’re not doing this - you might as well not be running Facebook ads.
                            </p>

                            <p>
                                Right now, head over to your Facebook page settings and click ‘Page Moderation’.
                            </p>

                            <p>
                                It’s an easy trick that will stop negative and spam comments from ruining your adverts.
                            </p>

                            <p>
                                What’s your favourite Facebook advertising hack?
                            </p>

                            <p>
                                #FacebookAds
                            </p>

                        </div>
                        <div ref={refs[3]} className="plug-block">
                            <div className="plug-block-subheading">Week 2 - Monday</div>

                            <p>
                                Looking for recommendations!
                            </p>

                            <p>
                                I love finding new and ethical brands to support when shopping online. Does anyone have any new vegan/ sustainable businesses they’d like to recommend?
                            </p>

                            <p>
                                Tag them in the comments!
                            </p>

                        </div>

                        <div ref={refs[4]} className="plug-block">
                            <div className="plug-block-subheading">
                                Week 2 - Wednesday
                            </div>

                            <img src="https://img.genflow.com/gya/plugandplays/example-linkedin-content-schedule-4-4b3f212a-jpg.jpg" alt="" style={{ marginBottom: '1rem' }} />

                            <p>
                                Anyone got any tips for dealing with colleagues who do not respect your professional boundaries?
                            </p>

                            <p>(Even if he is a good boy)</p>


                        </div>

                        <div ref={refs[5]} className="plug-block">
                            <div className="plug-block-subheading">
                                Week 2 - Thursday
                            </div>
                            <p>
                                Some secrets are just too good to keep.
                            </p>

                            <p>
                                Keep reading to find the Cart Abandonment subject line that increased our client’s open rates from 20% to 51%.
                            </p>

                            <p>
                                Although we don’t offer CRO as a service at our agency, a couple of weeks ago, one of our new clients asked us to have a look through their system as a favour.
                            </p>
                            <p>
                                We suggested a couple of tweaks - all of which have had small incremental benefits (from what we can track so far).
                            </p>
                            <p>
                                But the subject line has resulted in the greatest difference.
                            </p>
                            <p>
                                Previously, they had used a suggested Klayvio template.
                            </p>
                            <p>
                                We updated it to read:
                            </p>
                            <p>
                                “%FIRST.NAME% - everything OK?”
                            </p>
                            <p>
                                Do you have any suggestions for one that would work better?
                            </p>
                            <p>
                                Let me know
                            </p>
                        </div>

                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
