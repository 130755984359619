import gql from 'graphql-tag';

export default gql`
    mutation ($vendorUuid: ID!,
        $clientCustomerUuid: ID!) {
            deleteClientCustomer(
                vendorUuid: $vendorUuid,
                clientCustomerUuid: $clientCustomerUuid ) {
                errors            
        }
    }
`;
