/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import {
    Card,
    CardBody,
    Col,
    Container,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
} from 'reactstrap';
import accounting from 'accounting';
import Footer from '../../../components/Footer';
import '../../../assets/scss/simple-tools.scss';
import GYAFooterScene from '../../../assets/img/footer-scene-gya.png';
import CurrencySelector from '../../../components/CurrencySelector';
import { UserDataContext } from '../../../providers/UserDataProvider';

export default () => {
    const { userCurrency, getCurrencyLabel } = useContext(UserDataContext);

    const getCurrencyLabel1 = (userCurrency) => {
        if (userCurrency === '€'){
            return 'EUR'
        } else if (userCurrency === '£'){
            return 'GBP'
        } else if (userCurrency === '$'){
            return 'USD'
        } 
    }


    // On First Touch Point
    const [oftp, setOFTP] = useState({
        local: 4000,
        ecomm: 1000,
    });
    const updateOFTP = update => setOFTP({ ...oftp, ...update });

    // Re targetting
    const [rt, setRT] = useState({
        local: 1000,
        ecomm: 1000,
    });
    const updateRT = update => setRT({ ...rt, ...update });

    // On First Touch Point
    const [adminOFTP, setAdminOFTP] = useState({
        local: 2,
        ecomm: 1,
    });
    const updateAdminOFTP = update => setAdminOFTP({ ...adminOFTP, ...update });

    // Re targetting
    const [adminRT, setAdminRT] = useState({
        local: 5,
        ecomm: 5,
    });
    const updateAdminRT = update => setAdminRT({ ...adminRT, ...update });

    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, []);

    const resultOFTP = {
        local: (oftp.local * adminOFTP.local) / 1000,
        ecomm: (oftp.ecomm * adminOFTP.ecomm) / 1000,
    };

    const resultRT = {
        local: (rt.local * adminRT.local) / 1000,
        ecomm: (rt.ecomm * adminRT.ecomm) / 1000,
    };
    return (
        <>
            <main className="simple-tools-page text-white">
                <section className="section-profile-cover section-shaped my-10 sm:my-12">
                    <Container>
                        <Row>
                            <Col lg={12} className="text-center">
                                <h1 className="hidden sm:block">Audience Size Budget Calculator</h1>
                                <h3 style={{fontSize: '26px'}} className="block sm:hidden">Audience Size Budget Calculator</h3>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="section section-lg pt-lg-3 pt-8">
                    <Container>
                        <Row className="mb-4 shadow shadow--hover">
                            <Col lg={12}>
                                <Row className="flex flex-col sm:flex-row w-11/12 sm:w-8/12 md:w-10/12 mx-auto shadow-md card">
                                    <Col lg={7} className="w-full sm:w-7/12  p-4 sm:p-8">
                                        <div className="left-input-card ">
                                            <CardBody>
                                                <CurrencySelector />
                                                <div className="mt-8 block sm:hidden"></div>
                                                <div className="flex flex-col sm:flex-row items-start sm:items-end body1">
                                                    <strong style={{paddingBottom: '0.2rem'}}>On First Touch Point</strong>
                                                    <div className="input-subtitle body4 ml-0 sm:ml-1 pb-1" style={{fontSize: '13px'}}>Audience Size</div>
                                                </div>
                                                <Row className="flex flex-col sm:flex-row items-start sm:items-end justify-between py-4 body2">
                                                    <Col sm={9} className="tool-form-label mb-0">
                                                        Local Businesses
                                                    </Col>
                                                    <Col sm={3} className="tool-form-input w-full sm:w-auto">
                                                        <InputGroup>
                                                            <Input
                                                                placeholder="4000"
                                                                type="number"
                                                                value={oftp.local}
                                                                className="inputBox w-full sm:w-28"
                                                                style={{borderBottomColor: "#434343", marginBottom: "0px"}}
                                                                onChange={e => updateOFTP({ local: e.target.value })
                                                                }
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                                <Row className="tool-form-row mb-4 flex flex-col sm:flex-row items-start sm:items-end justify-between py-4 body2">
                                                    <Col sm={9} className="tool-form-label mb-0">
                                                        Ecomm & Info Product
                                                    </Col>
                                                    <Col sm={3} className="tool-form-input w-full sm:w-auto">
                                                        <InputGroup>
                                                            <Input
                                                                placeholder="1000"
                                                                type="number"
                                                                value={oftp.ecomm}
                                                                className="inputBox w-full sm:w-28"
                                                                style={{borderBottomColor: "#434343", marginBottom: "0px"}}
                                                                onChange={e => updateOFTP({ ecomm: e.target.value })
                                                                }
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                </Row>

                                                <div className="flex flex-col sm:flex-row items-start sm:items-end body1">
                                                    <strong style={{paddingBottom: '0.2rem'}}>On Re-targeting</strong>
                                                    <div className="input-subtitle body4 ml-0 sm:ml-1 pb-1" style={{fontSize: '13px'}}>Audience Size</div>
                                                </div>

                                                <Row className="tool-form-row flex flex-col sm:flex-row items-start sm:items-end justify-between py-4 body2">
                                                    <Col sm={9} className="tool-form-label">
                                                        Local Businesses
                                                    </Col>
                                                    <Col sm={3} className="tool-form-input w-full sm:w-auto">
                                                        <InputGroup>
                                                            <Input
                                                                placeholder="1000"
                                                                type="number"
                                                                value={rt.local}
                                                                className="inputBox w-full sm:w-28"
                                                                style={{borderBottomColor: "#434343", marginBottom: "0px"}}
                                                                onChange={e => updateRT({ local: e.target.value })
                                                                }
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                                <Row className="tool-form-row mb-4 flex flex-col sm:flex-row items-start  sm:items-end justify-between py-4 body2">
                                                    <Col sm={9} className="tool-form-label">
                                                        Ecomm & Info Product
                                                    </Col>
                                                    <Col sm={3} className="tool-form-input w-full sm:w-auto">
                                                        <InputGroup>
                                                            <Input
                                                                placeholder="1000"
                                                                type="number"
                                                                value={rt.ecomm}
                                                                className="inputBox w-full sm:w-28"
                                                                style={{borderBottomColor: "#434343", marginBottom: "0px"}}
                                                                onChange={e => updateRT({ ecomm: e.target.value })
                                                                }
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                                <div className="flex items-start sm:items-end flex-col sm:flex-row">
                                                    <strong style={{paddingBottom: '0.2rem'}}>Admin Table</strong>
                                                    <div className="input-subtitle body4 ml-0 sm:ml-1 pb-1 body4smaller">
                                                        for every 1,000 audience size on first touch point,
                                                        {' '}
                                                        {getCurrencyLabel1(userCurrency)}
                                                        {' '}
                                                        spent per day
                                                    </div>
                                                </div>
                                                <Row className="tool-form-row flex flex-col sm:flex-row items-start body2 sm:items-end justify-between py-4">
                                                    <Col sm={9} className="tool-form-label">
                                                        Local Businesses
                                                    </Col>
                                                    <Col sm={3} className="tool-form-input  w-full sm:w-auto">
                                                        <InputGroup className="flex flex-row items-end bg-transparent inputBox" style={{borderBottomColor: "#434343", marginBottom: "0px"}}>
                                                            <InputGroupAddon addonType="prepend" className="bg-transparent inputBox" style={{ marginBottom: "0px"}}>
                                                                <InputGroupText>{userCurrency}</InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder="2"
                                                                type="number"
                                                                value={adminOFTP.local}
                                                                className="bg-transparent p-2 pb-0 w-full sm:w-24 inputBox"
                                                                style={{marginBottom: "0px"}}
                                                                onChange={e => updateAdminOFTP({ local: e.target.value })
                                                                }
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                                <Row className="tool-form-row mb-4 flex flex-col sm:flex-row items-start sm:items-end body2 justify-between py-4">
                                                    <Col sm={9} className="tool-form-label">
                                                        Ecomm & Info Product
                                                    </Col>
                                                    <Col sm={3} className="tool-form-input w-full sm:w-auto">
                                                        <InputGroup className="flex flex-row items-end bg-transparent inputBox" style={{borderBottomColor: "#434343", marginBottom: "0px"}}>
                                                            <InputGroupAddon addonType="prepend" className="bg-transparent inputBox" style={{ marginBottom: "0px"}}>
                                                                <InputGroupText>{userCurrency}</InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder="1"
                                                                type="number"
                                                                value={adminOFTP.ecomm}
                                                                className="bg-transparent p-2 pb-0 w-full sm:w-24 "
                                                                style={{borderBottomColor: "#434343", marginBottom: "0px"}}
                                                                onChange={e => updateAdminOFTP({ ecomm: e.target.value })
                                                                }
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                                <div className="flex items-start sm:items-end flex-col sm:flex-row">
                                                    <strong style={{paddingBottom: '0.2rem'}}>Admin Table</strong>
                                                    <div className="input-subtitle body4 ml-0 sm:ml-1 pb-1 body4smaller">
                                                        for every 1,000 audience size on re-targeting,
                                                        {' '}
                                                        {getCurrencyLabel1(userCurrency)}
                                                        {' '}
                                                        spent per day
                                                    </div>
                                                </div>
                                                <Row className="tool-form-row flex flex-col sm:flex-row items-start sm:items-end body2 justify-between py-4">
                                                    <Col sm={9} className="tool-form-label">
                                                        Local Businesses
                                                    </Col>
                                                    <Col sm={3} className="tool-form-input w-full sm:w-auto">
                                                        <InputGroup className="flex flex-row items-end bg-transparent inputBox" style={{borderBottomColor: "#434343", marginBottom: "0px"}}>
                                                            <InputGroupAddon addonType="prepend" className="bg-transparent inputBox" style={{ marginBottom: "0px"}}>
                                                                <InputGroupText>{userCurrency}</InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder="5"
                                                                type="number"
                                                                value={adminRT.local}
                                                                className="bg-transparent p-2 pb-0 w-full sm:w-24 "
                                                                style={{borderBottomColor: "#434343", marginBottom: "0px"}}
                                                                onChange={e => updateAdminRT({ local: e.target.value })
                                                                }
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                                <Row className="tool-form-row mb-4 flex flex-col sm:flex-row items-start sm:items-end body2 justify-between py-4">
                                                    <Col sm={9} className="tool-form-label">
                                                        Ecomm & Info Product
                                                    </Col>
                                                    <Col sm={3} className="tool-form-input w-full sm:w-auto">
                                                        <InputGroup className="flex flex-row items-end bg-transparent inputBox" style={{borderBottomColor: "#434343", marginBottom: "0px"}}>
                                                            <InputGroupAddon addonType="prepend" className="bg-transparent inputBox" style={{ marginBottom: "0px"}}>
                                                                <InputGroupText>{userCurrency}</InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder="5"
                                                                type="number"
                                                                value={adminRT.ecomm}
                                                                className="bg-transparent p-2 pb-0 w-full sm:w-24"
                                                                style={{borderBottomColor: "#434343", marginBottom: "0px"}}
                                                                onChange={e => updateAdminRT({ ecomm: e.target.value })
                                                                }
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </div>
                                    </Col>
                                    <Col lg={5} className="right-input-col p-0 w-full sm:w-5/12 md:w-6/12">
                                        <Card className="right-input-card audience flex items-center justify-center p-2">
                                            <CardBody className="p-0">
                                                <div className="result">
                                                    <h2 className="text-white result-title mb-0 hidden sm:block">
                                                        On First Touch Point
                                                    </h2>
                                                    <h3 className="text-white result-title mb-0 block sm:hidden">
                                                        On First Touch Point
                                                    </h3>
                                                    <div className="text-white mb-4">
                                                        {getCurrencyLabel1(userCurrency)}
                                                        /Per Day
                                                    </div>
                                                    <Row className="result-grid-row flex flex-row justify-around">
                                                        <Col sm={6}>
                                                            <div className="result-grid-value">
                                                                {`${accounting
                                                                    .formatMoney(resultOFTP.local, userCurrency)
                                                                    .replace('.00', '')}`}
                                                            </div>
                                                            <div className="result-grid-label">
                                                                Local Businesses
                                                            </div>
                                                        </Col>
                                                        <Col sm={6}>
                                                            <div className="result-grid-value">
                                                                {`${accounting
                                                                    .formatMoney(resultOFTP.ecomm, userCurrency)
                                                                    .replace('.00', '')}`}
                                                            </div>
                                                            <div className="result-grid-label">
                                                                Ecomm & Info Product
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className="result">
                                                    <h2 className="text-white result-title mb-0 hidden sm:block">
                                                        On Re-targeting
                                                    </h2>
                                                    <h3 className="text-white result-title mb-0 block sm:hidden">
                                                        On Re-targeting
                                                    </h3>
                                                    <div className="text-white mb-4">
                                                        {getCurrencyLabel1(userCurrency)}
                                                        /Per Day
                                                    </div>
                                                    <Row className="result-grid-row flex flex-row justify-around">
                                                        <Col sm={6}>
                                                            <div className="result-grid-value">
                                                                {`${accounting
                                                                    .formatMoney(resultRT.local, userCurrency)
                                                                    .replace('.00', '')}`}
                                                            </div>
                                                            <div className="result-grid-label">
                                                                Local Businesses
                                                            </div>
                                                        </Col>
                                                        <Col sm={6}>
                                                            <div className="result-grid-value">
                                                                {`${accounting
                                                                    .formatMoney(resultRT.ecomm, userCurrency)
                                                                    .replace('.00', '')}`}
                                                            </div>
                                                            <div className="result-grid-label">
                                                                Ecomm & Info Product
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </main>
            <Footer />
        </>
    );
};
