import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        available: true, sectionName: 'Before your start',
    },
    {
        available: true, sectionName: 'Deleting Accounts',
    },
    {
        available: true, sectionName: 'Facebook',
    },
    {
        available: true, sectionName: 'Instagram',
    },
];

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title="Cleaning Your Social Profiles">
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                // selectedSection={selectedSection}
                                // setSelectedSection={onSelectSection}
                                // filter={filter}
                                // selectFilter={selectFilter}
                                // subFilter={subFilter}
                                // selectSubFilter={selectSubFilter}

                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]} className="plug-block">
                            <h2 className="mb-4">Cleaning Your Social Profiles</h2>   

                            <div className="examples-block">
                                <div className="plug-block-subheading">
                                    Before you start:
                                </div>

                                <p>What you’ll need:</p>

                                <ul>
                                    <li>
                                        Your primary email & login information
                                    </li>
                                    <li><a href="https://backgroundchecks.org/justdeleteme/#" target="_blank" rel="noopener noreferrer">BackgroundChecks.org</a></li>
                                    <li>
                                        Canva Account
                                    </li>
                                </ul>
                            </div>

                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">
                                Deleting Accounts

                            </div>

                            <p>
                                You may wish to completely delete or deactivate past social media accounts that you no longer use.
                            </p>

                            <p>
                                This is most often the case with Twitter: it is difficult to perform outreach using this platform, and many users no longer use their account - other than for browsing.
                            </p>

                            <p>
                                <a href="https://backgroundchecks.org/justdeleteme/#" target="_blank" rel="noopener noreferrer">BackgroundChecks.org</a>
                                {' '}
                                provides a list of account settings pages that you can click into directly to delete your account.
                            </p>

                            <p>
                                Make sure you are logged into your social media account before clicking onto the link.
                            </p>
                        </div>

                        <div ref={refs[2]} className="plug-block">
                            <div className="plug-block-subheading">
                                Facebook

                            </div>

                            <p>
                                You may wish to create a separate Facebook account for your outreach, vs personal use.
                            </p>

                            <p>
                                Remember: if you are going to use an account for Facebook advertising, Facebook prefers accounts with history and friends, rather than accounts set up primarily for this purpose.
                            </p>

                            <p>If you choose to have a separate account, or you do not wish to use your Facebook account for outreach, apply the following settings. </p>

                            <p>
                                In the
                                {' '}
                                <a href="https://www.facebook.com/settings?tab=privacy" target="_blank" rel="noopener noreferrer">Facebook Privacy Tab</a>
                                {' '}
                                change:
                            </p>

                            <ul>
                                <li>
                                    Who can see your future posts? > Friends
                                </li>
                                <li>
                                    Limit the audience for posts you've shared with friends of friends or Public? > Limit
                                </li>
                                <li>
                                    Who can see the people, Pages and lists you follow? > Only Me
                                </li>
                                <li>
                                    Who can send you friend requests? > Friends of Friends
                                </li>
                                <li>
                                    Who can see your friends list? > Only Me
                                </li>
                                <li>
                                    Who can look you up using the email address you provided? > Only Me
                                </li>
                                <li>
                                    Who can look you up using the phone number you provided? > Only Me
                                </li>
                                <li>Do you want search engines outside of Facebook to link to your Profile? > No (Untick)</li>
                            </ul>
                        </div>

                        <div ref={refs[3]} className="plug-block">
                            <div className="plug-block-subheading">
                                Instagram

                            </div>

                            <p className="bold">
                                Personal Account
                            </p>

                            <p>
                                If you wish to keep a private Instagram account, implement the following privacy settings:
                            </p>

                            <p>
                                Go to the
                                {' '}
                                <a href="https://www.instagram.com/accounts/privacy_and_security/" target="_blank" rel="noopener noreferrer">Privacy and Security settings:</a>
                            </p>

                            <ul>
                                <li>
                                    Private Account > Yes (Select)
                                </li>
                            </ul>

                            <p>
                                Your profile picture and bio are still visible. Either delete these or keep them consistent with your professional profiles elsewhere.
                            </p>

                            <p>You may wish to change your name and username subtly to remove reference to your real/ searchable name. For example, use a nickname, or replace letters with words.</p>

                            <p className="bold">
                                Outreach Account
                            </p>

                            <p>You may wish to create a separate account for the sole purposes of outreach/ use your current account and clean it for use. </p>

                            <ul>
                                <li>
                                    Update your bio, refer to the agency you own, and what you do. Don’t worry about SEO or keywords: keep it fun and relevant to the platform.
                                </li>
                                <li>
                                    Insert the URL to your agency
                                </li>
                                <li>
                                    Update your profile picture to a professional one, it should be clear and you should be identifiable
                                </li>
                                <li>
                                    Remove any tagged posts that you do not wish to be seen publicly, and in the Privacy and Security settings, change
                                    {' '}
                                    <span className="bold">Photos of you </span>
                                    {' '}
                                    to add manually
                                </li>
                            </ul>

                            <p className="bold">
                                Agency Account
                            </p>

                            <p>
                                If you create an account for your agency, make sure it is publicly viewable.
                            </p>

                            <ul>
                                <li>
                                    Include a high-quality, cropped photo of your logo as the profile photo.
                                </li>
                                <li>
                                    The bio can be more explicitly-business orientated than the personal one
                                </li>
                                <li>
                                    Insert the URL to your agency
                                </li>
                                <li>
                                    On your agency website, link back to the Instagram account, this will help it rank on Google
                                </li>
                            </ul>

                            <p className="bold">
                                9-Grid/ Highlights
                            </p>

                            <p>Regardless of whether you use a personal or agency account, you may wish to use a 9-Grid or Story Highlights to create the impression of an active account, without having to post regularly. </p>

                            <p>
                                For a personal account:
                            </p>

                            <ul>
                                <li>
                                    Post a mixture of work, in-situ shots, such as behind the desk or your office; and personal but relevant posts. For example, travel if you work in this niche
                                </li>
                            </ul>

                            <p>
                                For an agency account:
                            </p>

                            <ul>
                                <li>
                                    Post a mixture of work shots, profiles of yourself/ contractors, relevant industry statistics
                                </li>
                                <li>
                                    Create a ‘results’ highlight
                                </li>
                            </ul>

                            <p>
                                For example:
                            </p>

                            <img src="https://img.genflow.com/1326x1482/gya/plugandplays/cleaning-socials-1-f92f25c1.jpg" alt="" />
                        </div>

                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
