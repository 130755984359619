import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        available: true,
        sectionName: 'In today\'s teaching',
    },
    {
        available: true,
        sectionName: 'The Audiences We Use',
    },
    {
        available: true,
        sectionName: 'Retargeting Strategy',
    },
];

const title = 'Re-Targeting 101';

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]}  className="plug-block">  
                        <h2 className="mb-4">{title}</h2>
                            <div className="plug-block-subheading">
                                In today's teaching
                            </div>

                            <ul>
                                <li>
                                    The Audiences We Use
                                </li>
                                <li>
                                    Retargeting strategy
                                </li>
                                <li>
                                    Other valid retargeting strategies
                                </li>
                                <li>
                                    Workflow on retargeting
                                </li>
                            </ul>
                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">
                                The Audiences We Use
                            </div>

                            <p>
                                We've already gone through all the audiences that can be created on how to do it. We've also briefly mentioned in the past our retargeting structure and how it looks like on the business manager. In this module we will be showing exactly our campaigns and screenshots of some of the ones we've run.
                            </p>

                            <p>
                                Remember that since iOS14, retargeting has lost a lot of value and simpler strategies have been more effective. We've had a simple strategy for years now, but right now it is the best you can do. We will still show some of the other ones but keep in mind that in general retargeting isn't doing as much and the best is to always have cold working.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/reTargeting101-1.png" alt="" />

                            <p>
                                For retargeting we only use 2 audiences, one sourced on the website and the other sourced on Facebook.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/reTargeting101-2.png" alt="" />

                            <p>
                                For the website we use All Pixel 180 days and for Facebook and instagram we use All engagers 90 days. What this means:
                            </p>

                            <p>
                                All pixel 180: On this audience you will find everyone that has visited your website in the past 6 months. We know it is a long time but we've found this to work the best, you can play around with the timeframes and try to make it shorter, try 90, 60 and 30 days. In our opinion making it shorter will make you lose some sales that are on KPI, but if you find your retargeting tight on ROAS you can try using a smaller audience.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/reTargeting101-3.png" alt="" />

                            <p>
                                Facebook and instagram page engagement: This audience is composed of everyone who visited your profile. Engaged with any of the ads. Shares, clicks, etc.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/reTargeting101-4.png" alt="" />
                            <img src="https://img.genflow.com/gya/plugandplays/reTargeting101-5.png" alt="" />
                        </div>

                        <div ref={refs[2]} className="plug-block">
                            <div className="plug-block-subheading">
                                Retargeting Strategy
                            </div>

                            <p>
                                The actual strategy and structure is very simple.
                            </p>

                            <p className="bold">Structure</p>

                            <p>
                                We have one campaign for retargeting and we have 2 ad sets in it, one ad set has the All pixel audience and the other has the engagers audience (this is the only case in which we have more than one audience in an ad set).
                            </p>

                            <p>
                                That's it very simple
                            </p>

                            <p className="bold">Angles</p>

                            <p>
                                Because it is retargeting, our ads are almost always different from cold. Objections from people that have already expressed some interest in the product are different from the ones for people that are not familiar with it. Our retargeting ads for info products usually also lead to a different page, for example a sales page or book a call page instead of going back to videos or opt in funnels.
                            </p>

                            <p>For ecommerce instead of showing the same videos, we run ads with professional pictures and listings of features to make that final push for the ads.</p>

                            <p>
                                Here is a good example of Cold vs Retargeting ads
                            </p>

                            <p>
                                Cold is a video that explains the features of the product, copy has features as well and overall is a more get to know the product kinda ad, more than build trust or push for the purchase ad.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/reTargeting101-6.png" style={{ width: '60%' }} alt="" />

                            <p>
                                On retargeting they are pushing for the sale with a discount and showing the app and the packaging to build trust on the product
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/reTargeting101-7.png" style={{ width: '60%' }} alt="" />

                            <div className="examples-block">
                                <p>
                                    Pro tip: To find competitors retargeting ads, you can go on their website, facebook and instagram pages to get retargeted. If you live in a country were they don't run ads, you can go to their ads library and find the oldest ads, these are usually the best of their retargeting ads that have been working for a while.
                                </p>
                            </div>

                            <p className="bold">
                                Other valid retargeting strategies
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/reTargeting101-8.png" style={{ width: '60%' }} alt="" />

                            <p>
                                You can play around with different audiences and structures and be more specific on the way you run these types of campaigns. For instance it is pretty common to do retargeting strategies for videos and how much of the videos have been seen.
                            </p>

                            <p>
                                This is the other strategy we've seen and have tested on the past
                            </p>

                            <p>
                                Retargeting campaign:
                            </p>

                            <p>
                                It uses very small windows and it differentiates each step of the funnel, you can see there View Content, Initiate Checkout, Page View, Add to Cart, etc. It also excludes the others on each step, for example ATC has excluded VS, IC, PV and Pur (purchases).
                            </p>

                            <p>
                                In this strategy you can turn off any of the ad sets that are underperforming.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/reTargeting101-9.png" alt="" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
