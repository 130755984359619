import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';


const sections = [
    {
        available: true, sectionName: 'Things to keep in mind:',
    },
];

const title = "Objection Handling"

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]}  className="plug-block">  
                        <h2 className="mb-4">{title}</h2>
                            <div className="plug-block-subheading">
                                Things to keep in mind:
                            </div>

                            <p>
                                You need to know the difference between an objection and a genuine logistical question because there is a huge difference.
                            </p>

                            <p>
                                A logistical question is when they ask if you can break up payments monthly, how communication works, more about the service, etc.
                            </p>

                            <p>
                                An objection is a ‘no’ masked in excuses or invalid problems in order to dance around the main issue at hand which is that they either don’t have enough money or they don’t see the value.
                            </p>

                            <p>
                                Really important you know the difference between the two.
                            </p>

                            <p>
                                <span className="bold">IMPORTANT:</span>
                                Never leave the call/meeting without either a yes, a no or a follow up call within 72 hours.
                            </p>

                            <ul>
                                <li>
                                    General chat for a few minutes
                                </li>
                                <li>
                                    Crack some jokes, lighten the mood
                                </li>
                                <li>
                                    Try to find some commonalities.
                                </li>
                                <li>
                                    Don’t make this part longer than 2-3 minutes


                                </li>
                            </ul>

                            <p className="bold">
                                <strong>
                                    ‘I need to check with my business partner/spouse’


                                </strong>
                            </p>

                            <ul>
                                <li>
                                    <span className="bold">[Only if Zoom Call]</span>
                                    {' '}
                                    No worries, that’s actually why I record every single meeting just in case someone else needs to be involved in the decision. I’ll send this recording to you right after our call. Let’s set up a quick 15 minute follow up meeting in 2 days so you have enough time to think about it.
                                </li>
                                <li>That’s understandable, have a chat with them & just remember that because they weren’t on this call with us, they may not share the same excitement so you really gotta show them the value of taking this conversation further. Let’s pencil in a quick 15 minute follow up call 3 days from now?</li>
                            </ul>

                            <p className="bold">
                                <strong>
                                    ‘I’ve worked with other agencies before and they never seem to get the results they promise’
                                </strong>
                            </p>

                            <ul>
                                <li>
                                    [Only if you have clients] It’s funny, every single one of my clients said the exact same thing. I think finding the right agency is like finding a partner, you have to go through a lot of bad apples until you find the right one… but once you find the right one, you realise it was worth it.


                                </li>
                            </ul>

                            <p className="bold">
                                <strong>
                                    ‘It’s not the right time…’
                                </strong>
                            </p>

                            <ul>
                                <li>
                                    When will it be the right time to have{" "}
                                    <span className="bold">[outcome from emotional buy in section]</span>
                                    {' '}
                                    then?
                                </li>
                                <li>
                                    I don’t think you’ll ever feel like it’s the right time. That said, the longer you leave this, the longer your competitors get an edge on you and the longer you leave money on the table.
                                </li>
                            </ul>

                            <p className="bold"><strong>‘I can’t afford it’</strong></p>

                            <ul>
                                <li>
                                    You can’t afford NOT to. Let me ask you a question, what do you think the opportunity cost is of NOT moving forward with this?
                                </li>
                            </ul>

                            <p className="bold">

                                ‘How much time do I have to decide?’

                            </p>

                            <ul>
                                <li>
                                    How much time do you need? What does your gut instinct tell you?
                                </li>
                                <li>
                                    As I said earlier in the call, it’s a yes or no at the end of this call. Maybe I haven’t explained the service well enough. Are there any other questions I could answer to help make this easier for you?
                                </li>
                            </ul>

                            <p className="bold">
                                <strong>
                                    ‘I need some more time to think about it’


                                </strong>
                            </p>

                            <ul>
                                <li>
                                    Absolutely, that’s understandable. Quick question though, what’s there to think about? When I mapped out how we get results for our clients, you can see why it’s an absolute no brainer. Maybe there’s something I didn’t explain well enough?
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
