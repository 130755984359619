import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    paper: {
        width: 400,
        padding: theme.spacing(2, 3, 3),
    },
    popper: {
        minWidth: 250,
        maxWidth: 500,
        maxHeight: '75vh',
        boxShadow: theme.shadows[5],
        overflow: 'scroll',
        zIndex: 9999,
    },
}));
