/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

import image1 from '../../../../../assets/img/plugandplay/google-display-network/1.png';
import image2 from '../../../../../assets/img/plugandplay/google-display-network/2.png';
import image3 from '../../../../../assets/img/plugandplay/google-display-network/3.png';
import image4 from '../../../../../assets/img/plugandplay/google-display-network/4.png';
import image5 from '../../../../../assets/img/plugandplay/google-display-network/5.png';
import image6 from '../../../../../assets/img/plugandplay/google-display-network/6.png';
import image7 from '../../../../../assets/img/plugandplay/google-display-network/7.png';
import image8 from '../../../../../assets/img/plugandplay/google-display-network/8.png';
import image9 from '../../../../../assets/img/plugandplay/google-display-network/9.png';
import image10 from '../../../../../assets/img/plugandplay/google-display-network/10.png';
import image11 from '../../../../../assets/img/plugandplay/google-display-network/11.png';
import image12 from '../../../../../assets/img/plugandplay/google-display-network/12.png';

const sections = [
    {
        available: true,
        sectionName: 'Introduction',
    },
    {
        available: true,
        sectionName: 'How To Create a Google Display Campaign',
    },
];

const title = 'Google Display Network';

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]}  className="plug-block">  
                        <h2 className="mb-4">{title}</h2>
                            <div className="plug-block-subheading">Introduction</div>

                            <p>
                                The Google Display Network, also known as GDN, is one of the networks that has the
                                largest inventories in the internet.
                            </p>

                            <p>
                                In case you don’t know, the Google Display Network comprises advertising banners
                                that you see pretty much on every website of the internet.
                            </p>

                            <img src={image1} alt="" />

                            <p>
                                And because they’re everywhere, there’s a lot of inventory available to sell, making
                                the CPMs on this network really cheap.
                            </p>

                            <p>
                                It’s one of the best places to retarget your audience with products they have
                                already seen, but haven’t bought yet. You can pretty much follow people all over the
                                internet with these.
                            </p>

                            <p>
                                However, it’s not recommended to use this network for cold traffic unless you are an
                                experienced advertiser.
                            </p>
                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">How To Create a Google Display Campaign</div>
                            <p>
                                Let’s create an example campaign. In this example, let’s say we’re creating a
                                retargeting campaign for a solar panel company that wants to generate leads.
                            </p>
                            <p>Here’s how we do it.</p>
                            <p>First, let’s click on the “+ New Campaign” button on our main dashboard.</p>
                            <img src={image2} alt="" />
                            <p>
                                The first thing Google asks us is the campaign objective. Keep things simple here.
                            </p>
                            <p>For Ecommerce, you’re almost always going to select “Sales”.</p>
                            <p>For lead generation, you’re going to select “leads”.</p>
                            <p>
                                For infoproducts, it can be one of the two, depending on the type of funnel. If
                                you’re sending people straight to a sales page, select sales. If you’re capturing
                                leads, select leads.
                            </p>
                            <p>In our example, we will go with leads.</p>
                            <img src={image3} alt="" />
                            <p>On the campaign type, select “Display”.</p>
                            <img src={image4} alt="" />
                            <p>Then let’s go with “Smart Display” and hit continue</p>
                            <img src={image5} alt="" />
                            <p>Then, let’s name our campaign - keep a simple convention for this.</p>
                            <p>After that we have to select the locations we want the campaign to run.</p>
                            <p>
                                If you’re advertising to English speaking countries, select “english”. But if you’re
                                advertising to non-english speaking countries, you should select the native language
                                of the country you’re advertising to as well as english, because a lot of people
                                still use their browsers in English even though that’s not their native language.
                            </p>
                            <img src={image6} alt="" />
                            <p>For Bidding, select “Conversions” with no target cost.</p>
                            <p>
                                On Budget, input your daily budget. Remember this is a retargeting campaign so it
                                doesn’t need to be a considerable amount of your total advertising spend, around
                                5-10% of your client’s total advertising budget.
                            </p>
                            <p>Do not check the dynamic ads box.</p>
                            <img src={image7} alt="" />
                            <p>Next, lets create our ad groups.</p>
                            <p>
                                This is as simple as choosing a name! Google inputs your targeting here, and we do
                                not recommend changing this.
                            </p>
                            <img src={image8} alt="" />
                            <p>Next, it’s time to create your ads.</p>
                            <p>On Final URL you input the link to your landing page.</p>
                            <p>On Images and logos, you have to upload 4 pictures:</p>
                            <ul>
                                <li>1 Logo with 1:1 resolution</li>
                                <li>1 Logo with 4:1 resolution</li>
                                <li>1 Image with 1:1 resolution</li>
                                <li>1 Image with 1.91:1 resolution</li>
                            </ul>
                            <p>
                                To upload them, you have to click on “+ Images and Logos”. The following window will
                                pop-up where you can upload your logos and images and select which one is which.
                                Let’s start with the logos.
                            </p>
                            <img src={image9} alt="" />
                            After uploading your two logos, click on both to select them.
                            <img src={image10} alt="" />
                            <p>Next, for images, use ‘stock photos’ and search solar.</p>
                            <img src={image11} alt="" />
                            <p>Once your two logos and two images are selected, hit save.</p>
                            <p>For ad copy, start with:</p>
                            <ul>
                                <li>Two Headlines</li>
                                <li>One Long Headline</li>
                                <li>One Description</li>
                            </ul>
                            <p>
                                Try to be straight to the point while sparking the reader's attention to get the
                                click. For example:
                            </p>
                            <img src={image12} alt="" />
                            <p>
                                Once that’s done all you have to do is hit “Create Campaign”. Google will ask you to
                                review your campaign. Once reviewed, hit “Create Campaign” again and that’s it!
                            </p>
                            <p>
                                Your campaign will now go through the review process and as soon as it gets
                                approved, it will start running.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
