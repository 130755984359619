import gql from 'graphql-tag';

export default gql`
query{
  getCustomerUserProfileData {
    data {
      id
      user_id
      product_id
      type
      type_id
      data
      created_at
      updated_at
    }
  }
}
`;
