import React from 'react';
import rowLabels from '../../rowLabels';

const withSummaryList = Component => ({ data, startSymbol, ...props }) => {
    // const dataWith$ = data.map(obj => ({ ...obj, startSymbol }));
    //
    // const orderedData = [];
    //
    // rowLabels.forEach(label => orderedData.push(dataWith$.find(obj => label === obj.label)));

    return <Component {...props} />;
};

export default withSummaryList;
