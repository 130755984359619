const styles = () => ({
    picker: {
        width: '50%',
        marginTop: 16,
    },
    checkbox: {
        marginTop: 16,
    },
    deleteContainer: {
        flexGrow: 1,
    },
    dialogTitle: {
        display: 'flex',
        paddingBottom: 0
    },
    title: {
        flexGrow: 1,
    },
    tabs: {
        margin: '0',
    }
});

export default styles;
