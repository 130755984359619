/* eslint-disable max-len */
import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
// import { Link } from 'react-router-dom';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

import image1 from '../../../../../assets/img/plugandplay/ads-leadgen/1.png';
import image2 from '../../../../../assets/img/plugandplay/ads-leadgen/2.png';
import image3 from '../../../../../assets/img/plugandplay/ads-leadgen/3.png';
import image4 from '../../../../../assets/img/plugandplay/ads-leadgen/4.png';
import image5 from '../../../../../assets/img/plugandplay/ads-leadgen/5.png';
import image6 from '../../../../../assets/img/plugandplay/ads-leadgen/6.png';
import image7 from '../../../../../assets/img/plugandplay/ads-leadgen/7.png';
import image8 from '../../../../../assets/img/plugandplay/ads-leadgen/8.png';
import image9 from '../../../../../assets/img/plugandplay/ads-leadgen/9.png';
import image10 from '../../../../../assets/img/plugandplay/ads-leadgen/10.png';
import image11 from '../../../../../assets/img/plugandplay/ads-leadgen/11.png';
import image12 from '../../../../../assets/img/plugandplay/ads-leadgen/12.png';
import image13 from '../../../../../assets/img/plugandplay/ads-leadgen/13.png';
import image14 from '../../../../../assets/img/plugandplay/ads-leadgen/14.png';
import image15 from '../../../../../assets/img/plugandplay/ads-leadgen/15.png';
import image16 from '../../../../../assets/img/plugandplay/ads-leadgen/16.png';
import image17 from '../../../../../assets/img/plugandplay/ads-leadgen/17.png';
import image18 from '../../../../../assets/img/plugandplay/ads-leadgen/18.png';
import image19 from '../../../../../assets/img/plugandplay/ads-leadgen/19.png';
import image20 from '../../../../../assets/img/plugandplay/ads-leadgen/20.png';
import image21 from '../../../../../assets/img/plugandplay/ads-leadgen/21.png';
import image22 from '../../../../../assets/img/plugandplay/ads-leadgen/22.png';
import image23 from '../../../../../assets/img/plugandplay/ads-leadgen/23.png';
import image24 from '../../../../../assets/img/plugandplay/ads-leadgen/24.png';
import image25 from '../../../../../assets/img/plugandplay/ads-leadgen/25.png';
import image26 from '../../../../../assets/img/plugandplay/ads-leadgen/26.png';
import image27 from '../../../../../assets/img/plugandplay/ads-leadgen/27.png';
import image28 from '../../../../../assets/img/plugandplay/ads-leadgen/28.png';
import image29 from '../../../../../assets/img/plugandplay/ads-leadgen/29.png';
import image30 from '../../../../../assets/img/plugandplay/ads-leadgen/30.png';
import image31 from '../../../../../assets/img/plugandplay/ads-leadgen/31.png';
import image32 from '../../../../../assets/img/plugandplay/ads-leadgen/32.png';
import image33 from '../../../../../assets/img/plugandplay/ads-leadgen/33.png';
import image34 from '../../../../../assets/img/plugandplay/ads-leadgen/34.png';
import image35 from '../../../../../assets/img/plugandplay/ads-leadgen/35.png';
import image36 from '../../../../../assets/img/plugandplay/ads-leadgen/36.png';
import image37 from '../../../../../assets/img/plugandplay/ads-leadgen/37.png';
import image38 from '../../../../../assets/img/plugandplay/ads-leadgen/38.png';
import image39 from '../../../../../assets/img/plugandplay/ads-leadgen/39.png';
import image40 from '../../../../../assets/img/plugandplay/ads-leadgen/40.png';
import image41 from '../../../../../assets/img/plugandplay/ads-leadgen/41.png';
import image42 from '../../../../../assets/img/plugandplay/ads-leadgen/42.png';
import image43 from '../../../../../assets/img/plugandplay/ads-leadgen/43.png';
import image44 from '../../../../../assets/img/plugandplay/ads-leadgen/44.png';
import image45 from '../../../../../assets/img/plugandplay/ads-leadgen/45.png';
import image46 from '../../../../../assets/img/plugandplay/ads-leadgen/46.png';
import image47 from '../../../../../assets/img/plugandplay/ads-leadgen/47.png';
import image48 from '../../../../../assets/img/plugandplay/ads-leadgen/48.png';
import image49 from '../../../../../assets/img/plugandplay/ads-leadgen/49.png';
import image50 from '../../../../../assets/img/plugandplay/ads-leadgen/50.png';
import image51 from '../../../../../assets/img/plugandplay/ads-leadgen/51.png';
import image52 from '../../../../../assets/img/plugandplay/ads-leadgen/52.png';
import image53 from '../../../../../assets/img/plugandplay/ads-leadgen/53.png';
import image54 from '../../../../../assets/img/plugandplay/ads-leadgen/54.png';
import image55 from '../../../../../assets/img/plugandplay/ads-leadgen/55.png';
import image56 from '../../../../../assets/img/plugandplay/ads-leadgen/56.png';
import image57 from '../../../../../assets/img/plugandplay/ads-leadgen/57.png';

const sections = [
    {
        available: true,
        sectionName: 'Accounting',
    },
    {
        available: true,
        sectionName: 'Agency',
    },
    {
        available: true,
        sectionName: 'Car Dealerships',
    },
    {
        available: true,
        sectionName: 'Chiropractic and Physio',
    },
    {
        available: true,
        sectionName: 'Dentist',
    },
    {
        available: true,
        sectionName: 'Flooring',
    },
    {
        available: true,
        sectionName: 'Home Renovation',
    },
    {
        available: true,
        sectionName: 'Hot tubs - Showers',
    },
    {
        available: true,
        sectionName: 'HVAC',
    },
    {
        available: true,
        sectionName: 'Insurance',
    },
    {
        available: true,
        sectionName: 'Lawyers',
    },
    {
        available: true,
        sectionName: 'Real state',
    },
    {
        available: true,
        sectionName: 'Roofing',
    },
    {
        available: true,
        sectionName: 'Solar',
    },
];

const title = 'Examples of ads Leadgen';

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                // selectedSection={selectedSection}
                                // setSelectedSection={onSelectSection}
                                // filter={filter}
                                // selectFilter={selectFilter}
                                // subFilter={subFilter}
                                // selectSubFilter={selectSubFilter}

                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <h2 className="mb-4">{title}</h2> 

                        <div ref={refs[0]} className="plug-block">
                            <h4>Accounting</h4>
                            <img src={image1} alt="" />
                            <img src={image2} alt="" />
                            <img src={image3} alt="" />
                            <img src={image4} alt="" />
                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <h4>Agency</h4>
                            <img src={image5} alt="" />
                            <img src={image6} alt="" />
                            <img src={image7} alt="" />
                        </div>

                        <div ref={refs[2]} className="plug-block">
                            <h4>Car Dealerships</h4>
                            <img src={image8} alt="" />
                            <img src={image9} alt="" />
                            <img src={image10} alt="" />
                            <img src={image11} alt="" />
                        </div>

                        <div ref={refs[3]} className="plug-block">
                            <h4>Chiropractic and Physio</h4>
                            <img src={image12} alt="" />
                            <img src={image13} alt="" />
                        </div>

                        <div ref={refs[4]} className="plug-block">
                            <h4>Dentist</h4>
                            <img src={image14} alt="" />
                            <img src={image15} alt="" />
                        </div>

                        <div ref={refs[5]} className="plug-block">
                            <h4>Flooring</h4>
                            <img src={image16} alt="" />
                            <img src={image17} alt="" />
                        </div>

                        <div ref={refs[6]} className="plug-block">
                            <h4>Home Renovation</h4>
                            <img src={image18} alt="" />
                            <img src={image19} alt="" />
                            <img src={image20} alt="" />
                            <img src={image21} alt="" />
                        </div>

                        <div ref={refs[7]} className="plug-block">
                            <h4>Hot tubs - Showers</h4>
                            <img src={image22} alt="" />
                            <img src={image23} alt="" />
                            <img src={image24} alt="" />
                            <img src={image25} alt="" />
                            <img src={image26} alt="" />
                        </div>

                        <div ref={refs[8]} className="plug-block">
                            <h4>HVAC</h4>
                            <img src={image27} alt="" />
                            <img src={image28} alt="" />
                            <img src={image29} alt="" />
                            <img src={image30} alt="" />
                        </div>

                        <div ref={refs[9]} className="plug-block">
                            <h4>Insurance</h4>
                            <img src={image31} alt="" />
                            <img src={image32} alt="" />
                            <img src={image33} alt="" />
                            <img src={image34} alt="" />
                            <img src={image35} alt="" />
                            <img src={image36} alt="" />
                        </div>

                        <div ref={refs[10]} className="plug-block">
                            <h4>Lawyers</h4>
                            <img src={image37} alt="" />
                            <img src={image38} alt="" />
                            <img src={image39} alt="" />
                            <img src={image40} alt="" />
                            <img src={image41} alt="" />
                            <img src={image42} alt="" />
                        </div>

                        <div ref={refs[11]} className="plug-block">
                            <h4>Real state</h4>
                            <img src={image43} alt="" />

                            <p>Brian buys, Brian sells</p>
                            <img src={image44} alt="" />
                        </div>

                        <div ref={refs[12]} className="plug-block">
                            <h4>Roofing</h4>
                            <img src={image45} alt="" />
                            <img src={image46} alt="" />
                            <img src={image47} alt="" />
                            <img src={image48} alt="" />
                            <img src={image49} alt="" />
                        </div>

                        <div ref={refs[13]} className="plug-block">
                            <h4>Solar</h4>
                            <img src={image50} alt="" />
                            <img src={image51} alt="" />
                            <img src={image52} alt="" />
                            <img src={image53} alt="" />
                            <img src={image54} alt="" />
                            <img src={image55} alt="" />
                            <img src={image56} alt="" />
                            <img src={image57} alt="" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
