import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        available: true,
        sectionName: 'What is a funnel?',
    },
    {
        available: true,
        sectionName: 'What is an offer?',
    },
    {
        available: true,
        sectionName: 'Funnels for local businesses',
    },
    {
        available: true,
        sectionName: 'How important is the offer?',
    },
];

const title = 'Funnels & Offer Fundamentals';

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]}  className="plug-block">  
                        <h2 className="mb-4">{title}</h2>
                            <div className="plug-block-subheading">What is a funnel?</div>

                            <p>
                                A funnel can be better understood as a customer journey. The funnel describes the steps a customer has to go through to convert. A conversion means that a user made the action you want them to do, this usually means a purchase, booking a call, sending their information, etc.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/funnelsOfferFundamentals1.png" alt="" />

                            <p>
                                A funnel is meant to lose people on each step of it, but it is also meant to increase conversion rates. In a funnel the further down a customer goes the more qualified and interested it is.
                            </p>

                            <p>
                                Why don't marketers, instead of losing people on each step, just send people straight to the last step?
                            </p>

                            <p>
                                Although the answer may seem obvious, it is always nice to come back to the principles, evaluate and question them. We've recently removed one of the steps of one of our funnels and this turned out for the better so never stop questioning and trying new things.
                            </p>

                            <p>
                                The reason why it is a multi step process is that each step requires different levels of commitment, and you're slowly asking for more commitment each step of it. Indoctrinating the prospect and leading them further down closer to the purchase or the action you want them to make. You shouldn't be asking for ALL the commitment right away. Think about what it took you to buy AN, you had to trust me first. Each offer has different levels of commitments and this is key to understand.
                            </p>

                            <p>
                                Offers are very closely linked with funnels and your funnel should be designed based on your offer and not the other way around.
                            </p>
                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">What is an offer?</div>

                            <p>
                                An offer is essentially the way you provide value to the customer and because it has value to the customer it means they will be willing to give something in exchange for it.
                            </p>

                            <p>
                                Let's take a very simple example, you want to buy a t-shirt, there are tons of different offers in the market for t-shirts and each company will have a different value proposition to get you to buy the shirt. There's some cheap ones, there's some expensive ones. What makes some people buy the expensive ones over the cheap ones? They all need a t-shirt. Each t-shirt provides different value to different people. Not everyone wants a t-shirt with a Metallica print on it but if you happen to be a fan all of the sudden it has much more value and you'll be willing to pay for it, even pay for it more than you'd just a regular t-shirt.
                            </p>

                            <p>
                                Now that we've established offers provide value to people and that it is different for each individual let's think about commitment. This is a value exchange, you give people something they want, they give you something you want. You're asking for money in exchange for a product? Money has value, the product has value so you make the exchange, if you ask for somebody's email that is a form of value, for someone's time for a call, same thing.
                            </p>

                            <p>
                                Now giving away your email is way easier than giving your time for a chat. Asking for $5000 dollars is different than asking for $5. You start to see that there is more or less friction depending on what you're trying to get from the prospects. This is what is known as commitment: how much does the prospect have to commit to acquire the offer, some offers like a guide in exchange for an email only needs so much convincing and is low commitment on the customer's end. A $5000 dollar a month SMMA service on the other hand needs a little more convincing to do, a better funnel and more than a couple sentences.
                            </p>

                            <p>
                                There's many offers and as such there's even more funnels. We'll present some of the most known ones and proven to work here for local businesses and then for e-commerce and online information.
                            </p>
                        </div>

                        <div ref={refs[2]} className="plug-block">
                            <div className="plug-block-subheading">
                                Funnels for local businesses
                            </div>

                            <p>
                                Will go over this in detail in its own section on Phase 6, '8 Funnels for leadgen and how to set them up' this is just a short overview so you understand the concept. Remember this is the fundamentals module
                            </p>

                            <p className="bold">
                                On-Facebook lead gen ads
                            </p>

                            <p>
                                These are the most effective types of ads you can run right now. It is a short one-step funnel used to gather information about a prospect. Many offers apply to this kind of funnel and the commitment level isn't high. Although that depends on the information you're asking, it is usually name and email or name and phone. With a gym for example you can get this in exchange for a free 3 day trial, with a hair salon in exchange for a free manicure. World is your to think and work offers out with your client, most creative usually wins. With that said don't worry much about it as we will be giving you everything that already works on the Local Businesses phase.
                            </p>

                            <p className="bold">
                                Free guide funnels
                            </p>

                            <p>
                                A lot of times conversion rates or quality can be even better when you put in place a little more complex funnels like a free guide funnel. Basically you're exchanging a free guide your customer will find value on in exchange for emails or phone numbers. Let's keep it going with the gym and salon examples. Let's say for a gym you can have a guide on how to lose weight or prepare low calorie meals, and for the salon you can have a guide on how to maintain your hair healthy. That way you can collect leads.
                            </p>

                            <p className="bold">
                                Messenger funnel
                            </p>

                            <p>
                                Adding to your offer the option of sending a message will make it even easier for the conversion. You can prompt people to send you a message on messenger and then follow up with them about the offer and how they can claim it or use it. A caveat to that is that your client needs to have a solid sales process and follow up with the leads on time.
                            </p>

                            <p className="bold">
                                Whatsapp funnel
                            </p>

                            <p>
                                Facebook bought whatsapp and similarly to the messenger ads you can send people to whatsapp. The one that does better depend a lot on the country or market. Same caveat.
                            </p>

                            <p className="bold">
                                Walk in coupons
                            </p>

                            <p>
                                You can create coupon offers that people can redeem at the place. Although less popular it works in many ways. Your offer needs to be strong enough to not need any nurturing of the lead or human contact.
                            </p>

                            <p className="bold">
                                Free valuations
                            </p>

                            <p>
                                Your customer gets a free valuation of what they're selling or that your client will buy or help sell. Think jewellery, real estate, cars, etc.
                            </p>

                            <p className="bold">
                                Instant valuations
                            </p>

                            <p>Via a quiz or set of questions you can have different flows that give different valuations, then you collect the data and ask for emails or phones to help the person sell that good.</p>
                        </div>

                        <div ref={refs[3]} className="plug-block">
                            <div className="plug-block-subheading">How important is the offer?</div>

                            <p>
                                No matter how good you creative, how good your funnel if people don't want your offer it won't work
                            </p>

                            <p>
                                I decided to make a whole module on this because the offer is king and I see this mistake on my students over and over again. The product, service or offer you're selling needs product market fit for anything else to work. Now if your offer is good and your marketing is bad, it won't sell either. But no matter how good your marketing is a bad offer can't be sold.
                            </p>
                        </div>

                        <div ref={refs[4]} className="plug-block">
                            <div className="plug-block-subheading">How to come up with offers and read the market?</div>

                            <p>
                                No one is better than you who lives and breathes your niche. Even better than your own client because you have the experience of multiple businesses in that niche, your client only has experience on theirs. Coming up with offers to get people through the door or to buy online is a complicated matter. For products and information this is left out to the client and although you can help them fine tune their offer, this is their job, if you come up with the offer or product you're not a marketing agency, you're an info-product business or an ecommerce store practically.
                            </p>

                            <p>
                                For local businesses the story is different, you are ultimately selling the gym, the hair salon, etc. But the offer is easily interchangeable. Don't want to give away a manicure? give aways a haircut, do a contest, see what fits best. We'll see all the offers in depth on phase 6
                            </p>

                            <p>
                                The real test of an offer comes when it is faced with cold traffic
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
