import React, { useState, useEffect, createContext } from 'react';
import moment from 'moment';
import { parseInt, flatten, mergeWith } from 'lodash';
import { OUTREACH_TRACKER_DATA_LABEL } from '../../../../../constants/outreachTracker';

const SummaryContext = createContext(null);
const { Provider, Consumer: SummaryConsumer } = SummaryContext;

const SummaryProvider = ({ children, config }) => {
    const [store, setStore] = useState({ ...config, update: null });

    useEffect(() => {
        // console.log('store here', store);
    }, [store]);

    // Updater
    const update = (key, data) => {
        // console.log('Update called, updating store: ', key, data);
        setStore(prevState => ({
            ...prevState,
            [key]: data,
        }));
    };

    // When config changes, re-initilise
    useEffect(() => {
        setStore({ ...config, update });
        // console.log('Configured store');
    }, [config]);

    const getSummaryData = (month = null) => {
        const isYearSummary = !month;
        const { data, year, ENABLED_OUTREACH_COLUMN_FIELDS } = store;
        const FILTERED_ENABLED_OUTREACH_COLUMN_FIELDS = ENABLED_OUTREACH_COLUMN_FIELDS?.filter(item => item?.dataLabel !== OUTREACH_TRACKER_DATA_LABEL);

        const timeRangeSummaryItems = isYearSummary
            ? data.filter(({ startDateTime }) => {
                const summaryMonthStartYear = moment.utc(startDateTime).format('YYYY');
                return parseInt(year) === parseInt(summaryMonthStartYear);
            })?.filter((item, index, arr) => arr?.map(arrItem => arrItem?.title)?.indexOf(item?.title) === index)
            : [data.find(({ startDateTime }) => {
                const thisMonthName = moment().utc().month(month).format('MMM');
                const summaryMonthStartName = moment(startDateTime).utc().format('MMM');
                const summaryMonthStartYear = moment(startDateTime).utc().format('YYYY');
                return (thisMonthName === summaryMonthStartName)
                    && parseInt(year) === parseInt(summaryMonthStartYear);
            })];


        const parsedTimeRangeSummaryItems = timeRangeSummaryItems.map(timeRangeSummary => JSON.parse(timeRangeSummary?.summaryJSON || '{}'));

        const columnFinanceItems = flatten(parsedTimeRangeSummaryItems.map(columnFinanceData => columnFinanceData?.outreachColumns || []));

        const reducerInit = {};

        FILTERED_ENABLED_OUTREACH_COLUMN_FIELDS.forEach(({ dataLabel }) => reducerInit[dataLabel] = 0);

        const totals = columnFinanceItems.reduce(
            (acc, value) => mergeWith(acc, value, (a, b) => parseInt(a) + parseInt(b)),
            reducerInit,
        );

        delete totals.columnKey;


        return totals;
    };

    return (
        <Provider value={{ ...store, getSummaryData }}>
            {children}
        </Provider>
    );
};


export {
    SummaryContext,
    SummaryProvider,
    SummaryConsumer,
};
