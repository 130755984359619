import React, { useState } from 'react';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import VideoSubSection from './videoSubSection';
import './style.scss';

export default ({
    sectionID,
    subSection,
    number,
    setSelectedVideo,
    selectedVideo,
    onToggleComplete,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="subsection">
            <div className="subsection-title grey700Bg" onClick={toggle}>
                <div className="flex flex-row items-center body5 justify-between w-full">
                    <div className="body3 text-white">
                        {number}
                        .
                        {' '}
                        {subSection.name}
                    </div>
                    <div className="body5 grey500 w-32 flex flex-row items-center justify-end align-end text-right">
                        {subSection.videos.length}
                        {' '}
                        videos
                        {isOpen ? <ExpandLess /> : <ExpandMore />}
                    </div>
                </div>

            </div>

            <div>
                {isOpen && (
                    <>
                        {subSection.videos.map(video => (
                            <VideoSubSection
                                video={video}
                                sectionID={sectionID}
                                subSection={subSection}
                                setSelectedVideo={setSelectedVideo}
                                selectedVideo={selectedVideo}
                                onToggleComplete={onToggleComplete}
                            />
                        ))}
                    </>
                )}
            </div>
        </div>
    );
};
