import React from 'react';
import './style.scss';
import Container from 'reactstrap/lib/Container';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';

const sections = [
    {
        available: true, sectionName: 'Things to keep in mind',
    },
    {
        available: true, sectionName: 'Understand The Business Model',
    },
    {
        available: true, sectionName: 'Get Some Benchmarks',
    },
    {
        available: true, sectionName: 'Marketing Specific Tactics',
    },
    {
        available: true, sectionName: 'Niche Research Questionnaire',
    },
];

const title = "Niche Research"

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]}  className="plug-block">  
                        <h2 className="mb-4">{title}</h2>
                            <div className="plug-block-subheading">Things to keep in mind:</div>

                            <p>It’s 50% about universal sales principals when you’re trying to sell the client, but it’s also 50% about niche specific language you use to understand and close the client.</p>

                            <p>On the flip side, it’s 50% about evergreen marketing & sales principals but also 50% about knowing the marketing tactics specific to the niche you’re working with.</p>

                            <p>It’s for this reason that this Niche Research sheet is so so important.</p>

                            <div className="examples-block">
                                <p><i>Homework: Spend all of tomorrow researching the niche, then create a Google Doc to fill out the questions at the end of this Plug N Play sheet.</i></p>

                                <p><i>Make sure you write down your findings in a notes tab so you can compile them all together in the questions section.</i></p>
                            </div>
                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">Step 1: Understand The Business Model</div>

                            <p>Before you can actually get clients in the niche or get results for them - You need to understand the core of the business model.</p>

                            <p>For this, I recommend you watch some videos online about the business model itself. Youtube is a great place to start.</p>

                            <p>After that, go to Google and type in the same stuff.</p>

                            <p>Some examples of what you’d look up:</p>

                            <ul style={{ listStyle: 'none', padding: 0 }}>
                                <li>‘How to open a gym’</li>
                                <li>‘How to become a realtor’ or ‘How do realtors make money’</li>
                                <li>‘How to open a restaurant’ </li>
                                <li>‘How to become a chiropractor’</li>
                            </ul>

                            <p>You also want to dig deeper and find forums where real business owners in the niche you want to serve talk about the pains they face while running the business, what a regular day looks like for them and other small details.</p>

                        </div>
                        <div ref={refs[2]} className="plug-block">
                            <div className="plug-block-subheading">Step 2: Get Some Benchmarks</div>

                            <p>The next thing you need is to get some benchmarks for how much your average client makes in revenue, what their average profit margins are, what their average monthly expenses are, etc.</p>

                            <p>Google is the best place to search for this.</p>

                            <p>Here’s some examples of what you should look up:</p>

                            <ul style={{ listStyle: 'none', padding: 0 }}>
                                <li>‘How much does the average [your niche] make a year?’</li>
                                <li>‘What’s the average profit margin of [your niche]?’</li>
                                <li>‘How many employees does the average [your niche] have?’</li>
                                <li>‘What sort of expenses does [your niche] have?’</li>
                            </ul>

                            <p>Once again, make sure you’re writing all these answers down so you can compile them in the questions section below.</p>

                        </div>
                        <div ref={refs[3]} className="plug-block">
                            <div className="plug-block-subheading">Step 3: Marketing Specific Tactics</div>

                            <p>Now it’s time for you to learn more about some marketing specific tactics for your niche. You would’ve already picked your service offering earlier in the process. That means that you need to be finding marketing specific tactics related to either Facebook Ads, Google Ads, Content, SEO, Etc.</p>

                            <p>Search on BOTH Google & Youtube:</p>

                            <ul style={{ listStyle: 'none', padding: 0 }}>
                                <li>‘How to get results for [your niche] with [your service]’</li>
                                <li>‘[your niche] marketing case study’</li>
                                <li>‘[your niche] [your service] case study’</li>
                            </ul>

                            <p>You want to look out for what sort of offers did they run that converted well, what sort of pictures/videos and what sort of text worked well?</p>

                            <p>What was the overall strategy?</p>
                        </div>

                        <div ref={refs[4]} className="plug-block">
                            <div className="plug-block-subheading">Niche Research Questionnaire</div>

                            <ol>
                                <li><strong>What is the average revenue of a client in your niche?</strong></li>
                                <p>Example: The average yearly revenue of a small-medium sized restaurant is around $1,600,000/</p>

                                <li><strong>What is the average expenses of a client in your niche?</strong></li>
                                <p>Example: The average yearly expenses of a small-medium sized restaurant is around $1,450,000/</p>


                                <li><strong>How many employees on average does a [your niche] have?</strong></li>
                                <p>Example: The average gym has 5 employees. One front door person to let in members, two personal trainers, one cleaner and one general & sales manager.</p>

                                <li><strong>What is the average profit margin in your niche?</strong></li>
                                <p>Example: Real Estate agents get paid on commission of the house they sell. They don’t sell a lot of houses in a year, but get paid a hefty commission when they do. Between fees they pay their real estate brokerage, advertising fees, staging fees and more - They usually net around 50% of their commission.</p>

                                <li><strong>What is the toughest thing about being a in [niche]?</strong></li>
                                <p>Example: The toughest thing about being an online ecommerce brand is that it’s all digital. That means that online marketing is pretty much the only way they can find the business's products. Therefore the marketing has to be A1.</p>

                                <li><strong>What scares your potential clients the most?</strong></li>
                                <p>Example: The thing that scares accountants the most is not having enough monthly retainers to cover their expenses. Being an accountant offers steady cash flow but when a few retainers drop… all hell breaks loose.</p>

                                <li><strong>What are buzz-phrases in [your niche]</strong></li>
                                <p>Example: In the agency world we have key words we use like ‘retainer’ to describe a monthly fee. When we advertise we use acronyms like CPA (cost per acquisition) or CPL (cost per lead).</p>

                                <li><strong>What is the best offer to run in this specific niche?</strong></li>
                                <p>Example: I’ve decided that I’m going to be doing Facebook advertising for gyms and I’ve come to the conclusion that running a free 1 week trail is the best way to get people through the door. Another good option is - ‘3 for Free’ which means they get three sessions for free.</p>
                            </ol>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};