import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        available: true,
        sectionName: 'How To Get More Facebook Accounts:',
    },
    {
        available: true,
        sectionName: 'How To Log Into The Accounts:',
    },
    {
        available: true,
        sectionName: 'How To Warm Up The Accounts:',
    },
];

const title = 'Facebook Contingency Guide';

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col> 
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]} className="plug-block">  
                        <h2 className="mb-4">{title}</h2>
                            <p>The main purpose of this guide is to help you warm up your profiles, ad accounts, business managers, and pages. </p>

                            <p>
                                If you face multiple bans on Facebook, this could be a sign that you’re not ‘warming up’ your assets prior to putting them to use, and that your contingency structure is fragile.
                            </p>

                            <p>
                                This guide will help you to reduce the number of bans you face, and build a contingency plan if you face more.
                            </p>

                            <div className="plug-block-subheading">How To Get More Facebook Accounts:</div>

                            <p>
                                1. Start with your family members or friends. Ask if someone has an account which is not in use anymore and would like to make some money out of it. State that you’re going to only be using it to access your own company’s business manager and that nothing will ever be posted using their names.
                            </p>

                            <p>
                                2.  There are just a few requirements when buying these accounts: a) they have to be older than 2 years old; and b) they have to have recent activity. Meaning, it cannot be an account that hasn’t logged in for years.
                                <br />
                                <br />
                                {' '}
                                In case the account doesn’t have enough recent activity in the last 2~3 weeks, you can ask the owner to use it normally for two weeks and then you buy it after this period.
                                {' '}
                                <br />
                                <br />
                                It’s also worth mentioning that the closer (geographically) to you the owner lives, the better. Because then Facebook won’t consider your new login as an unusual activity.
                                {' '}
                            </p>

                            <p>
                                3. Now, there’s two possible ways to purchase the accounts: a full purchase or a partial purchase. A full purchase means that after you buy the account, it will be 100% yours and the owner won’t be able to log into it anymore.
                                <br />
                                <br />
                                {' '}
                                A partial purchase means that you buy the account, but the owner can still log into it afterwards. However, they’re only allowed to do so through mobile - while you only log into the account through desktop.
                            </p>

                            <p>
                                4. Regarding pricing, you can offer $50 ~ $75 for a full purchase and $30 ~ $50 for a partial purchase. A great extra tip is always offering a $25 referral bonus for the person that refers you to a buyer. This way, you’ll have a network effect working for you and it will be a lot easier to find people willing to sell their accounts.
                                <br />
                                <br />
                                {' '}
                                Once you do this process enough times, you’ll eventually reach a point that you get so many people referring to you that you’ll always have a waitlist of buyers ready to go.
                                {' '}
                            </p>

                            <p>5. If you want to be safe against identity confirmation that is asked by Facebook every once in a while, it’s worth it to also ask for a picture of the ID from the person you’re buying the account from. Alternatively, you can also ask the person to go through the process of confirming their identity themselves on Facebook before selling you the account. Both work. </p>
                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">
                                How To Log Into The Accounts:
                            </div>

                            <p>
                                1. The most important thing to note here is that you should ALWAYS log into different accounts using a different browser user, to avoid having the same cookies and fingerprints.
                                <br />
                                <br />
                                {' '}
                                You can do that on Chrome by creating a new user by clicking on the profile picture at the top right corner and then clicking “add new”. Each profile should only log into one Facebook account.
                                {' '}
                            </p>

                            <p className="bold">
                                In case you already had a few account bans under your belt in this same computer/IP address, you should consider the following steps:
                            </p>

                            <p>
                                1. Change your IP address. If it is dynamic, just resetting your modem will change it. (You can check your current IP address here:
                                <a href=" https://whatismyipaddress.com/)" target="_blank" rel="noopener noreferrer"> https://whatismyipaddress.com/)</a>
                            </p>

                            <p>
                                2. Change your MAC address. You can do that with the software "Technitium MAC Address Changer". Change the address of your network card in use. Here's a quick tutorial on how to do it:
                                <a href="https://youtu.be/Ft_pTlDYpXg" target="_blank" rel="noopener noreferrer">https://youtu.be/Ft_pTlDYpXg</a>
                            </p>

                            <p>
                                3. Clean up your PC. Recommended software: CCleaner. Select all options for Google Chrome and Internet Explorer. Here's a quick tutorial on how to do it:
                                <a href="https://youtu.be/L0SVQeai5OA" target="_blank" rel="noopener noreferrer">https://youtu.be/L0SVQeai5OA</a>
                            </p>

                            <p>
                                4. Use Ghost Browser to log into your accounts. It's a similar browser to Chrome, but this one let's you create multiple "identities" which have different cookies and fingerprints each. You can download it here and follow their installation instructions:
                                <a href="https://ghostbrowser.com/" target="_blank" rel="noopener noreferrer">https://ghostbrowser.com/</a>
                            </p>
                        </div>

                        <div ref={refs[2]} className="plug-block">
                            <div className="plug-block-subheading">
                                How To Warm Up The Accounts:
                            </div>

                            <p>
                                This is one of the most vital steps that almost everyone skips. You now have your account. You’ve cleaned your computer. And now everyone thinks: “perfect, everything’s ready to go”. And this is how you get your account flagged for unusual activity or banned for circumventing systems.
                            </p>

                            <p>
                                Before starting to use the account for advertising purposes you have to properly warm up your account.
                            </p>

                            <p>
                                This way, you’ll make Facebook see you as a real user who hasn't advertised before and someone who is not a threat to their platform.
                            </p>

                            <p>You can get this exact step-by-step process in the following template we created on Trello: </p>

                            <a href="https://trello.com/b/gE1epNMo/template-13-day-account-warm-up-sop" target="_blank" rel="noopener noreferrer">[TEMPLATE] 13-Day Account Warm Up SOP</a>

                            <p>
                                Once you access it, click on the first card of the first list title “[START HERE] HOW TO USE THIS BOARD” for instructions on how to use the Trello board.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
