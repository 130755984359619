import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';

const CheckBox = ({ checked, onChange }) => {
    // const [active, setActive] = useState(checked);
    //
    // const update = async () => await setTimeout(() => setActive(checked), 1000)
    //
    // useEffect(update, [checked]);

    return (
        <>
            <Checkbox
                onChange={onChange}
                checked={checked}
            />

        </>
    );
};

export default CheckBox;
