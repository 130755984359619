import React, { useState } from 'react';
import {
    ChevronUpIcon, ChevronDownIcon,
} from '@heroicons/react/outline';


const RecordingsNavigationPane = ({ sections, setSelectedSection = () => {}, selectedSection }) => {
    const [isOpen, setIsOpen] = useState(null);
    const [acc, setAcc] = useState(false);


    const toggleDatePicker = (year) => {
        if (isOpen === year) {
            setIsOpen(null);
        } else {
            setIsOpen(year);
        }
    };

    return (
        <div
            className=""
        >
            <ul style={{
                listStyle: 'none',
                paddingLeft: 0,
                textAlign: 'center',
            }}
            >

                {sections.map(({ name: year, months }) => (
                    <>
                        <li>
                            <div
                                onClick={() => {
                                    toggleDatePicker(year);
                                    acc === false ? setAcc(true) : setAcc(false);
                                }}
                                className="flex grey500"
                                style={{
                                    padding: '.5rem',
                                    width: '100%',
                                    fontWeight: 'bold',
                                    ...(
                                    selectedSection?.year === year
                                        ? {
                                            color: 'white',
                                            fontWeight: 'bold',
                                            padding: '.5rem',
                                            borderRadius: '.2rem',
                                            minWidth: '5rem',
                                            textAlign: 'center',
                                        }
                                        : {}
                                    ),
                                }}
                            >
                                {year}
                                {console.log(selectedSection?.year, year)}
                                {selectedSection?.year === year && acc
                                    ? <ChevronUpIcon className="h-4 w-4 mt-1 sm:mt-0 ml-auto text-white" />
                                    : <ChevronDownIcon className="h-4 w-4 mt-1 sm:mt-0 ml-auto text-white" />
                                }
                            </div>
                        </li>
                        <ul style={{
                            listStyle: 'none',
                            paddingLeft: 0,
                            textAlign: 'center',
                        }}
                        >
                            {
                                months.map(({ name: month }) => (
                                    <li
                                        onClick={() => setSelectedSection({ month, year })}
                                        className="hover:cursor-pointer"
                                    >
                                        <div
                                            style={{
                                                cursor: 'pointer',
                                                padding: '.5rem',
                                                display: isOpen === year ? 'flex' : 'none',
                                                width: '100%',
                                                color: '#ADADAD',
                                                fontFamily: 'Inter',
                                                fontSize: '16px',
                                                fontWeight: '600',
                                                lineHeight: '24px',
                                                letterSpacing: '0.20000000298023224px',


                                                ...(
                                                    selectedSection?.year === year
                                                    && selectedSection?.month === month
                                                        ? {

                                                            color: 'white',
                                                            padding: '.5rem',
                                                            minWidth: '5rem',
                                                            fontFamily: 'Inter',
                                                            fontSize: '16px',
                                                            fontWeight: '600',
                                                            lineHeight: '24px',
                                                            letterSpacing: '0.20000000298023224px',
                                                        }
                                                        : {}
                                                ),
                                            }}
                                        >
                                            {month}
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>
                    </>

                ))}
            </ul>
        </div>
    );
};

RecordingsNavigationPane.propTypes = {

};

export default RecordingsNavigationPane;
