import React, {
    useContext, useEffect, useMemo, useState,
} from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import upsertUserProfileData from '../../../api/upsertUserProfileData';
import OnboardingCard from './onboardingTourComponents/OnboardingCard';
import { UserDataContext } from '../../../providers/UserDataProvider';
import { OnboardingContext } from '../../../providers/OnboardingProvider';


export default function OnboardingTour({ open, onClose }) {
    const history = useHistory();

    const {
        activePage, setActivePage, activeInfoType, setShowItem,
    } = useContext(OnboardingContext);

    const { userProfile, setUserProfile } = useContext(UserDataContext);

    const [lastPage, setLastPage] = useState(false);

    const onboardingPages = [
        {
            id: 0,
            title: 'Welcome to Grow your Agency!',
            subtitle: '',
            videoUrl: 'https://api-files.sproutvideo.com/file/ea9fddb61815efc663/dcae0d1edf0802c8/1080.mp4',
            component: OnboardingCard,
            nextButtonTitle: 'Next',
            onNextClick: () => {
                next();
            },
        },
        {
            id: 1,
            title: 'Explore our programs',
            subtitle: `One thing I do need to mention though is that at the end of the day YOU decide how successful you’ll be as a result of this program. We’ll give you all the tools and information you could ever need, and we’ll also give you your very own dedicated Student Success Concierge, but you’re the one who’ll have to take action.\n
            That’s what separates our average students from our best success stories. You all have access to the same information here, it’s purely up to you how you want to use it.\n
            How serious you are about changing your life, or honestly, just how much you want to change. Whether you want to just quit your 9-5, or become a multimillionaire, with these tools you can do it all. So let’s dive into it.
            `,
            component: OnboardingCard,
            nextButtonTitle: 'Join program',
            target: 'course-programs',
            onNextClick: () => {
                if (!lastPage) {
                    history.push('/course/3351');
                }

                next();
            },
            type: 'programs',
        },
        {
            id: 2,
            title: 'Explore your first program',
            subtitle: `First and foremost, you now have access to Agency Navigator.Here inside of it, you’ll find its 8 phases composed of 53 modules in total.Inside the majority of the modules, you’ll also find an accompanying plug-n-play sheet under its download area.\n
            These plug n plays go into excruciating detail showing you exactly what to do on the technical side of things. Everything from how to set up your professional email on GSuite, to how to get paid, put together your sales scripts, launch your first ads, and more.Honestly, I could go on and on because there are 84 plug n plays available.\n
            Not to mention the different TOOLS we give you, like the financial planner & LTV calculator.If you don’t know what that is, don’t worry. You’ll get to that soon enough when we cover the foundations in Phase 1.You’ll also get access to 4 different Q&A calls every single week with our expert coaches.If you’re up to it, you can even watch the recordings of previous weeks, and there are a ton of golden nuggets in there.You can find the entire Q&A call schedule on the menu at the top.\n
            If you want to be part of the first group, start taking action now. Your first step is to watch module 1. Not later, not tomorrow. Now.This is the first step of your new life.\n`,
            component: OnboardingCard,
            nextButtonTitle: 'Watch first video',
            closeButtonTitle: 'Not now',
            onCancelClick: () => {
                if (!lastPage) {
                    history.push('/programs');
                }
                save({ advanceApply: true });
                next();
            },
            onNextClick: () => {
                if (!lastPage) {
                    history.push('/course/3351/phase/');
                }
                save({ firstVideo: true, completed: true });
                onClose();
            },
            type: 'programs',
        },
        {
            id: 3,
            title: 'Apply to advanced programs',
            subtitle: 'If you have business that is doing $10,000+ a month and want to scale to $50k, $70k, $100k+, we recommend to apply for advanced programs that can give you deeper understanding of particular themes. Application includes only 45 mins call with our manager.',
            component: OnboardingCard,
            nextButtonTitle: 'Apply',
            closeButtonTitle: 'Not now',
            target: 'course-programs',
            onCancelClick: () => {
                if (activeInfoType === 'programs') {
                    onClose();
                    return;
                }
                if (!lastPage) {
                    history.push('/upcoming-q-a');
                }
                next(2);
            },
            onNextClick: () => {
                next();
            },
            type: 'programs',
        },
        {
            id: 4,
            title: 'Choose your pain',
            subtitle: 'Choose the problem that you want to get deeper understanding of.',
            component: OnboardingCard,
            nextButtonTitle: 'Apply',
            target: 'course-programs',
            options: [
                {
                    label: 'Scaling to 8-figure agency',
                    option: 'Scaling to 8-figure agency',
                    url: 'https://zoom.us/j/5908563579#success',
                },
                {
                    label: 'Profitable luxury investments',
                    option: 'Profitable luxury investments',
                    url: 'https://zoom.us/j/5908563579#success',
                },
                {
                    label: 'Multiplying your income on blockchain',
                    option: 'Multiplying your income on blockchain',
                    url: 'https://zoom.us/j/5908563579#success',
                },
                {
                    label: 'Becoming an expat',
                    option: 'Becoming an expat',
                    url: 'https://zoom.us/j/5908563579#success',
                },
                {
                    label: 'Customers serving',
                    option: 'Customers serving',
                    url: 'https://zoom.us/j/5908563579#success',
                },
                {
                    label: 'Mental heal & biohacking',
                    option: 'Mental heal & biohacking',
                    url: 'https://zoom.us/j/5908563579#success',
                },
                {
                    label: 'Marketing & SMM',
                    option: 'Marketing & SMM',
                    url: 'https://zoom.us/j/5908563579#success',
                },
            ],
            onNextClick: (activeOption) => {
                window.open(activeOption?.url);
                if (!lastPage) {
                    history.push('/upcoming-q-a');
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }
                save({ painApply: true });
                next();
            },
            type: 'programs',
        },
        {
            id: 5,
            title: 'Discuss your problems with experts',
            subtitle: 'If you don’t get the answers at our programs, you can get them on weekly Q&A sessions with our experts. Agency Navigator calls can be attended by everyone and suit beginner busineses. APEX calls are for agencies with 10,000$/month income and require the same process as advanced programs (if you are already approved on any advanced program, you get the access to APEX calls).',
            component: OnboardingCard,
            nextButtonTitle: 'Reserve',
            closeButtonTitle: 'Not now',
            onNextClick: () => {
                next();
                save({ qa: true });
            },
            onCancelClick: () => {
                if (!lastPage) {
                    history.push('/resources/qa');
                }
                next(2);
                save({ qa: true });
            },
            type: 'q&a',
        },
        {
            id: 6,
            title: 'Choose your question theme',
            subtitle: 'Choose the problem that you want to get deeper understanding of.',
            component: OnboardingCard,
            nextButtonTitle: 'Apply',
            options: [
                {
                    label: 'Accountability',
                    option: 'Accountability',
                    url: 'https://us06web.zoom.us/webinar/register/WN_WfwuEk5lSmqjKYQktPkHYw?fbclid=IwAR14Xa0hvGozX38ahUBVEE0I6JUtea-48X7oLgHGgXR5NFgxPlHggyphytA',
                },
                {
                    label: 'Mindset training',
                    option: 'Mindset training',
                    url: 'https://us06web.zoom.us/webinar/register/WN_BXdoWbdjSh-wuDfKBJHmBg?fbclid=IwAR1lLBQBjgt9Ca1hnjmk0VW6v9WU9nA23jBNxDQOHeIjCXqU89czoBx27cU',
                },
                {
                    label: 'Service delivery',
                    option: 'Service delivery',
                    url: 'https://us06web.zoom.us/webinar/register/WN_mhJ_Q70RRiW0j0RyiWShYw?fbclid=IwAR2FnPF7M8mA5D9iixm4w3LeMLeEKmAVPrRAl68ljjHzaWE-M6FqeMwD0Jw',
                },
                {
                    label: 'Sales & Outreach',
                    option: 'Sales & Outreach',
                    url: 'https://us06web.zoom.us/webinar/register/WN_UbY63v8SQyCJaEal5v-ABw?fbclid=IwAR17ZziSwyW6-W_u9V0kmJ4LJwifolpBnpd67RDy2pBkIurIzLsOMYWVF90',
                },
            ],
            onNextClick: (activeOption) => {
                window.open(activeOption?.url);
                if (!lastPage) {
                    history.push('/resources/qa');
                }
                next();
            },
            type: 'q&a',
        },
        {
            id: 7,
            title: 'Q&A Recordings',
            subtitle: 'If you can’t attend the Q&A session, you will be able to watch its recording in this section.',
            component: OnboardingCard,
            nextButtonTitle: 'Got it',
            onNextClick: () => {
                if (!lastPage) {
                    history.push('/profile');
                }
                next();
            },
            type: 'q&a',
        },
        {
            id: 8,
            title: 'Add your first client',
            subtitle: 'Track your clients and payments on this page. All these payments will also be added  to your result on leaderboard and you will be able to win yearly prizes from GYA. This will NOT be shared with anyone except for GYA',
            component: OnboardingCard,
            nextButtonTitle: 'Add client',
            closeButtonTitle: 'Not now',
            onNextClick: () => {
                setShowItem('addClient');
                save({ addClient: true, completed: true });
                onClose();
            },
            onCancelClick: () => {
                save({ addClient: true });
                if (!lastPage) {
                    history.push('/tools');
                }
                next();
            },
            type: 'profile',
        },
        {
            id: 9,
            title: 'Running the agency became easy',
            subtitle: 'Our tools will help in planning and tracking your revenue and expenses, calculate your budget and so much more. The most popular tool among our users Financial planner.',
            component: OnboardingCard,
            nextButtonTitle: 'Try it now',
            onNextClick: () => {
                if (!lastPage) {
                    history.push('/tools/financial-planner');
                }
                next();

                save({ tools: true });
            },
            type: 'tools',
        },
        {
            id: 10,
            title: 'Track your finances',
            subtitle: 'Here you can fill in your monthly revenue and expenses and track yourmonthly and yearly metrics. Here’s the video how to use it.',
            component: OnboardingCard,
            nextButtonTitle: 'Got it',
            onNextClick: () => {
                next();
            },
            videoUrl: 'https://api-files.sproutvideo.com/file/ea9ed5b5171fe7c163/b564dfede84e524b/1080.mp4',
            type: 'tools',
        },
        {
            id: 11,
            title: 'Start your journey',
            subtitle: 'Congratulations! Your onboarding tour is completed. Now you can make the most of our agency. If you still have qustions, you can press “Info” button on the section you need to know more about.',
            component: OnboardingCard,
            nextButtonTitle: 'Got it',
            onNextClick: () => {
                next();
                save({ completed: true });
            },
        },
    ];

    const filteredOnboardingPages = activeInfoType ? onboardingPages?.filter(page => page?.type === activeInfoType) : onboardingPages;

    useEffect(() => {
        const lastFilteredPage = activePage + 1 === filteredOnboardingPages?.length;
        setLastPage(lastFilteredPage);
    }, [filteredOnboardingPages, activePage]);


    const next = (id) => {
        if (((activePage + id || activePage + 1) >= 0) && ((activePage + id || activePage + 1) < filteredOnboardingPages.length)) {
            if (typeof id === 'number') {
                setActivePage(activePage + id);
                return;
            }
            setActivePage(activePage + 1);
        }

        if (activePage === filteredOnboardingPages.length - 1) {
            save({}, true);
        }
    };

    const [upsertProfileData] = useMutation(upsertUserProfileData, {});

    const save = (obj, close) => {
        let data = { ...userProfile?.data };
        if (obj) {
            data = { ...userProfile?.data, tourCompleted: { ...userProfile?.data?.tourCompleted, ...obj } };
        }

        setUserProfile({
            ...userProfile,
            data,
        });
        upsertProfileData({
            variables: {
                data: JSON.stringify(data),
            },
        });
        if (close) {
            setTimeout(() => onClose(), (350));
        }
    };

    const Page = useMemo(() => activePage < filteredOnboardingPages.length && activePage >= 0 && filteredOnboardingPages?.[activePage].component, [activePage, filteredOnboardingPages]);

    const active = useMemo(() => filteredOnboardingPages?.[activePage], [activePage, filteredOnboardingPages]);


    return (
        <Modal
            open={open}
            onClose={onClose}
            center
            className="grey800Bg"
            showCloseIcon={false}
        >
            <div
                className="fixed inset-0 bg-black-200 overflow-y-auto h-screen w-full z-50 flex justify-center item-center scrollbar-hide"
                id="my-modal"
            >
                <div className="h-screen w-11/12 md:w-7/12 flex justify-center items-center">

                    {activePage < filteredOnboardingPages.length && activePage >= 0 && (
                        <Page
                            key={active?.id}
                            title={active?.title}
                            subtitle={active?.subtitle}
                            close={() => onClose()}
                            nextButtonTitle={active?.nextButtonTitle}
                            closeButtonTitle={active?.closeButtonTitle}
                            target={active?.target}
                            onNextClick={active?.onNextClick}
                            onCancelClick={active?.onCancelClick}
                            options={active?.options}
                            videoUrl={active?.videoUrl}
                        />
                    )}
                </div>
            </div>
        </Modal>
    );
}
