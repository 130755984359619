import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import upsertUserProfileData from '../../../api/upsertUserProfileData';
import { UserDataContext } from '../../../providers/UserDataProvider';

const goals = [
    {
        label: 'Increase income',
        value: 'Increase income',
    },
    {
        label: 'Financially independent',
        value: 'Financially independent',
    },
    {
        label: 'Scale to 7-figures',
        value: 'Scale to 7-figures',
    },
    {
        label: 'Scale to 8-figures',
        value: 'Scale to 8-figures',
    },
];


const OnboardingGoalInfo = ({ next }) => {
    const [goal, setGoal] = useState({});
    const { userProfile, setUserProfile } = useContext(UserDataContext);

    const handleChange = (e) => {
        const { value } = e.target;
        setGoal({
            ...goal,
            [e.target.id]: value,
        });
    };

    const [upsertProfileData] = useMutation(upsertUserProfileData, {});


    const onSubmit = (e) => {
        e.preventDefault();
        setUserProfile({
            ...userProfile,
            data: {
                ...userProfile?.data, ...goal, onboardingCompleted: true,
            },
        });

        upsertProfileData({
            variables: {
                data: JSON.stringify({
                    ...userProfile?.data, ...goal, onboardingCompleted: true,
                }),
            },
        });

        setTimeout(() => next(), (350));
    };

    return (
        <div className="w-full flex flex-col mt-2">
            <form onSubmit={onSubmit}>
                <div className="mb-6 w-full">
                    <label htmlFor="goalObstacle" className="block mb-2 text-sm font-medium text-white w-full ">
                        What’s currently your biggest obstacle to achieving those goals?
                        <textarea type="text" id="goalObstacle" onChange={handleChange} className="rounded-[20px] mt-2 bg-[#393939] text-white text-sm focus:ring-blue-500 focus:outline-none block w-full p-2.5" placeholder="..." required />
                    </label>
                </div>
                <div className="mb-6 w-full">
                    <label htmlFor="goalObjective" className="block mb-2 text-sm font-medium text-white w-full ">
                        What is your 6-month objective with this program? (Both financially and lifestyle wise)
                        <textarea type="text" id="goalObjective" onChange={handleChange} className="rounded-[20px] mt-2 bg-[#393939] text-white text-sm focus:ring-blue-500 focus:outline-none block w-full p-2.5 " placeholder="..." required />
                    </label>
                </div>
                <button type="submit" className="border-2 rounded-full text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium w-full px-5 py-2.5 text-center ">Get started</button>
            </form>
        </div>
    );
};

export default OnboardingGoalInfo;
