/* eslint-disable react/destructuring-assignment */
import React from 'react';
import SubSection from './subSection';
import './style.scss';

export default ({
    subSections,
    setSelectedVideo,
    selectedVideo,
    onToggleComplete,
    sectionID,
}) => (
    <div className="subsections">
        {subSections.map((subSection, index) => (
            <SubSection
                sectionID={sectionID}
                subSection={subSection}
                number={index + 1}
                setSelectedVideo={setSelectedVideo}
                selectedVideo={selectedVideo}
                onToggleComplete={onToggleComplete}
            />
        ))}
    </div>
);
