import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        available: true,
        sectionName: 'Communication channels',
    },
    {
        available: true,
        sectionName: 'Monday loom',
    },
    {
        available: true,
        sectionName: 'Thursday 2 sentences',
    },
    {
        available: true,
        sectionName: 'Biweekly calls',
    },
];

const title = 'Communication Schedule & Keeping Clients In The Loop';

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                // selectedSection={selectedSection}
                                // setSelectedSection={onSelectSection}
                                // filter={filter}
                                // selectFilter={selectFilter}
                                // subFilter={subFilter}
                                // selectSubFilter={selectSubFilter}

                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]} className="plug-block">
                            <h2 className="mb-4">{title}</h2>  



                            <p>
                                Our main communication channel is Agenciflow or slack, we're transitioning yet to using agenciflow at the time of recording. It is a must to have a communication channel open with your client where they can leave all the enquiries about the service delivery and results. It is of the utmost importance to have a separate 'business' communication channel, you don't want to be using WhatsApp or any other chat apps that are intertwined with your personal life, nor your clients. Business is business and whenever you're working you and your team should be 100% focused on it and not distracted by personal life matters. Slack and Agenciflow also allow for multiple people in channels and organize the information in a better way. Each client should have their separate channel and all the work related discussion should go in that channel. Make it clear to your client as it is important for the team to have a full picture of what is going on and for everyone to have full access to the information and be kept on the loop.
                            </p>

                            <p>
                                You'll find that when clients are going to talk about billing related issues or payments they will often send you a private message about it, that is fine and you can keep those conversions on the private messages.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/communicationSchedule1.png" alt="" />

                            <p>
                                Here are some examples of what our clients will often ask and how we usually respond.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/communicationSchedule2.png" alt="" />

                            <p>Things like these are why it is important to have the communication channel open with your clients as there will be last minute things that you or your team need to be on top of.</p>

                            <img src="https://img.genflow.com/gya/plugandplays/communicationSchedule3.png" alt="" />

                            <p>Here are some examples of communication on our end that needs their response: The usual topics are approvals of new material (copy of creatives)</p>

                            <img src="https://img.genflow.com/gya/plugandplays/communicationSchedule4.png" alt="" />
                            <img src="https://img.genflow.com/gya/plugandplays/communicationSchedule5.png" alt="" />
                            <img src="https://img.genflow.com/gya/plugandplays/communicationSchedule6.png" alt="" />
                            <img src="https://img.genflow.com/gya/plugandplays/communicationSchedule7.png" alt="" />

                            <p>
                                Access to some new software that we're trying and didn't include in the onboarding.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/communicationSchedule8.png" alt="" />

                            <p>
                                Resources to improve their website and customer journey.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/communicationSchedule9.png" alt="" />

                            <p>
                                Resources to improve their ads, in case we need images or videos taken.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/communicationSchedule10.png" alt="" />

                            <p>
                                Changes we've implemented on ads, emails, tracking, etc
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/communicationSchedule11.png" alt="" />

                            <p>
                                ROAS Calculations
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/communicationSchedule12.png" alt="" />

                            <p>
                                It is also a way to build your relationships with your clients, you can share your success and they can share theirs
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/communicationSchedule13.png" alt="" />

                            <img src="https://img.genflow.com/gya/plugandplays/communicationSchedule14.png" alt="" />

                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">Monday loom</div>

                            <p>
                                Mondays loom is a simple overview of the results of the last 7 days and what we're doing in the following week. You can talk about changes in the environment, things you need from your client, best ads in the last 7 days or which angle performed best. You should also talk about ideas the team has to improve results either on the landing pages or the ads themselves and involve your client in the process, that way they have a detailed view of what is going on and also feel like the wins are theirs. If they know what is going on they will also be more patient and understanding of the process. Always involve your client.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/communicationSchedule15.png" alt="" />
                            <img src="https://img.genflow.com/gya/plugandplays/communicationSchedule16.png" alt="" />
                            <img src="https://img.genflow.com/gya/plugandplays/communicationSchedule17.png" alt="" />
                        </div>

                        <div ref={refs[2]} className="plug-block">
                            <div className="plug-block-subheading">Thursday 2 sentences</div>

                            <p>
                                On our Thursday 2 sentence updates we write about topics related to this week's progress and results that we spoke about on the Monday loom. It is also a way to force you or your team to communicate with the client and let them know you're working and thinking about their business. This goes a long way and it is greatly appreciated by them. They should feel like you really care about the business and you're spending time every day working for them even if you're not. The most important thing for client retention is definitely results but communication comes close second and even if you're not getting the best results if you communicate to your client the work you're doing and it shows that you're striving to get better results it will be appreciated.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/communicationSchedule18.png" alt="" />
                            <img src="https://img.genflow.com/gya/plugandplays/communicationSchedule19.png" alt="" />

                            <p>
                                Some weeks you won't have much to talk about and that is alright, the important thing is to get a message across even if it is just checking in how their side of the business is doing and if there's anything you can help with on that end.
                            </p>

                            <p>We may also talk about new products or ideas that we have in mind or results of creative, landing page, price or angles testing.</p>
                        </div>

                        <div ref={refs[3]} className="plug-block">
                            <div className="plug-block-subheading">Biweekly calls</div>

                            <p>
                                The biweekly calls are an effective way to check in with your client on a more personal level and discuss high level ideas on the past performance and ways to improve and grow in the future. On these calls we discuss new types of ads and funnels that can be made, things that will improve the backend, the Average Order Value, the Lifetime Value of a Customer. Remember that if your client is making more money they will be happy and your retention rate will greatly improve, their wins are your wins even if they are outside the ads and media buying side of things. a more holistic approach!
                            </p>

                            <p>
                                We don't follow any specific script, but here are some ideas and things you can do on these calls:
                            </p>

                            <ul>
                                <li>
                                    Ask them about how they are personally
                                </li>
                                <li>Break the ice with current state of the world related topics.</li>
                                <li>
                                    Ask them about investments and business related topics
                                </li>
                                <li>
                                    Discuss new ideas for products
                                </li>
                                <li>
                                    Discuss new ideas to improve the funnel and sale process
                                </li>
                                <li>
                                    Talk about past results of the last 14 days
                                </li>
                                <li>Talk about things you've learned with other clients that might be worth exploring with this one</li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
