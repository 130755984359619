import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import { Link } from 'react-router-dom';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        available: true, sectionName: 'Before you start',
    },
    {
        available: true, sectionName: 'Frequency',
    },
    {
        available: true, sectionName: 'What to post/ What NOT to post',
    },
    {
        available: true, sectionName: 'Engagement',
    },
    {
        available: true, sectionName: 'Call to action',
    },
    {
        available: true, sectionName: 'Accompanying media',
    },
    {
        available: true, sectionName: 'Hashtags',
    },
    {
        available: true, sectionName: 'Timings',
    },
    {
        available: true, sectionName: 'Example content schedule',
    },
];

const title = "LinkedIn Content"

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]}  className="plug-block">  
                        <h2 className="mb-4">{title}</h2>
                            <div className="plug-block-subheading">
                                Before you start:
                            </div>

                            <p>
                                What you’ll need:
                            </p>

                            <ul>
                                <li>
                                    <Link to="/resources/plug-and-play/creating-your-linkedin-profile">An optimised LinkedIn account</Link>

                                </li>
                                <li>A Canva.com account</li>
                            </ul>

                            <p>
                                LinkedIn is similar to other other social media channels: content is king. Sharing high-quality content frequently is your chance to:
                            </p>

                            <ul>
                                <li>
                                    Display expertise through sharing informative content
                                </li>
                                <li>
                                    Increase social proof with highly-engaged posts
                                </li>
                                <li>
                                    Distinguish yourself from other LinkedIn users who are primarily using the platform to sell (don’t worry: you will be too, you just don’t want to appear salesy) by offering value
                                </li>
                                <li>
                                    Engage your network and increase inbound leads
                                </li>
                            </ul>
                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">
                                Frequency
                            </div>


                            <p>The frequency you post is determined by how seriously you take outreach on LinkedIn. If you plan to use the platform as your primary source of outreach, you should aim to post two-to-three times a week.</p>

                            <p>
                                But if you’re just performing basic housekeeping, you should aim to populate your account with three-to-five highly-engaged posts, similar to how you would create a nine-grid for your Instagram account.
                            </p>

                        </div>

                        <div ref={refs[2]} className="plug-block">
                            <div className="plug-block-subheading">
                                What to post/ What NOT to post

                            </div>


                            <p>
                                Just like posting on Facebook, Twitter or Instagram, a lot comes down to common sense.
                            </p>

                            <p>
                                Before you post something, ask yourself two questions:

                            </p>

                            <ul>
                                <li>
                                    Is it written in the style that you’d send an email to your friends and family to highlight something?
                                </li>
                                <li>
                                    Would you send it round to colleagues to help them?
                                </li>


                            </ul>
                            {' '}
                            <p>
                                For example:
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/linkedin-content-1-2253e19e-jpg.jpg" alt="" style={{ marginBottom: '1rem', width: '50%' }} />

                            <p>This post offers no discernible value. You wouldn’t send it to your family and friends, and you wouldn’t send it to your colleagues. </p>

                            <p>
                                Whereas this post:
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/linkedin-content-2-f185de59-jpg.jpg" alt="" style={{ marginBottom: '1rem' }} />

                            <p>
                                Offers insight that’s relevant to their audience, and presents value.
                            </p>

                            <p>
                                Here are some content ideas you may find useful:
                            </p>

                            <ul>
                                <li>
                                    Look for relevant/ newsworthy topics and how they might apply to your niche or industry
                                </li>
                                <li>
                                    Include takeaways or action points
                                </li>
                                <li>
                                    Share ‘value’ - actual, tangible points. Don’t look for the sale in your posts.
                                </li>
                                <li>
                                    Share case studies, or recent examples of work - for example, in the Black Friday period, share what worked, and how businesses could include this in their own marketing
                                </li>
                            </ul>

                            <p className="bold">
                                Don’t share uplifting stories. No one likes ‘LinkedIn Influencers’:
                            </p>
                            <img src="https://img.genflow.com/gya/plugandplays/linkedin-content-3-e78d9712-jpg.jpg" alt="" style={{ marginBottom: '1rem' }} />
                        </div>

                        <div ref={refs[3]} className="plug-block">
                            <div className="plug-block-subheading">
                                Engagement

                            </div>


                            <p>
                                Whilst Instagram’s algorithm takes into account hundreds of variables and inputs in deciding whether or not to ‘promote’ a post, LinkedIn is a bit more primitive, and a lot harsher.
                            </p>

                            <p>
                                Posts either get viewed by your followers: or by no one at all.

                            </p>

                            <p>The trick to ensuring your posts don’t get lost is ensuring maximum engagement within the first 15 minutes. </p>

                            <p>
                                There are a few ways to achieve this:
                            </p>

                            <ul>
                                <li>
                                    Share the post to your personal network, including family and friends via WhatsApp. Ask them to like and comment, ASAP.
                                </li>
                                <li>
                                    Form an ‘engagement’ circle with fellow agency owners or other experts in digital marketing and tag them in the comments below. Ask for their thoughts.
                                </li>
                                <li>
                                    Respond to comments - especially from potential prospects. Thank them for their thoughts.
                                </li>
                            </ul>
                        </div>

                        <div ref={refs[4]} className="plug-block">
                            <div className="plug-block-subheading">
                                Call to action

                            </div>

                            <p>Whilst CTAs should always be included in posts - they should invite engagement and discussion NOT asking people to message you (unless relevant) or book in calls. </p>
                        </div>

                        <div ref={refs[5]} className="plug-block">
                            <div className="plug-block-subheading">
                                Accompanying media

                            </div>

                            <p>
                                The majority of posts on LinkedIn are still text-based so posts with accompanying media (like an image, video or GIF) are more likely to stand out in timelines.
                            </p>

                            <p>
                                However, like on any other social media channel, LinkedIn users are naturally suspicious and resistant to graphically-designed posts which look like they’ve come from a brand.
                            </p>
                            <p>
                                Users also don’t tend to respond well to any literal or stock images. For example, writing about funnels and using an image like this:
                            </p>
                            <img src="https://img.genflow.com/gya/plugandplays/linkedin-content-4-1f407a10-jpg.jpg" alt="" style={{ marginBottom: '1rem' }} />

                            <p>
                                Instead, try to use images that appear natural - the ones that you would share in a WhatsApp group. For example:
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/linkedin-content-5-b7f0c21f-jpg.jpg" alt="" style={{ marginBottom: '1rem' }} />

                            <p>
                                Ironically, lower-quality or organic images will often out-perform anything graphically-designed.
                            </p>

                            <p>
                                So if you’re sharing a list of dos and don’ts - try writing them out on a piece of paper, taking a picture, and uploading it, rather than making an image.
                            </p>
                        </div>

                        <div ref={refs[6]} className="plug-block">
                            <div className="plug-block-subheading">
                                Hashtags


                            </div>

                            <p>Hashtags should be used sparingly. Whilst it’s possible to elevate the reach of your post if it’s featured in a ‘trending’, hashtags can look messy and distract from your post. </p>

                            <p>
                                Avoid hyper-competitive hashtags like #business or #motivation as they are unlikely to yield any results.
                            </p>
                        </div>


                        <div ref={refs[7]} className="plug-block">
                            <div className="plug-block-subheading">
                                Timings


                            </div>

                            <p>
                                As LinkedIn is considered a business-only platform, you should avoid posting at all on Saturday or Sunday, or on any day outside of 9am-5pm.
                            </p>

                            <p>
                                Instead, stick to business hours. Hubspot found that posting between 8am to 2pm, Tuesday to Thursday yielded the greatest reach and engagement.

                            </p>
                        </div>

                        <div ref={refs[8]} className="plug-block">
                            <div className="plug-block-subheading">
                                Example content schedule


                            </div>

                            <Link to="/resources/plug-and-play/example-linkedin-content-schedule">Click here to read an example content schedule</Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
