import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        available: true,
        sectionName: 'Ad Selection',
    },
];

const title = 'Ad Selection';

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (

        <PlugAndPlayWrapper title="Creating Your LinkedIn Profile.">
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                // selectedSection={selectedSection}
                                // setSelectedSection={onSelectSection}
                                // filter={filter}
                                // selectFilter={selectFilter}
                                // subFilter={subFilter}
                                // selectSubFilter={selectSubFilter}

                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]} className="plug-block">
                            <h2 className="mb-4">{title}</h2>                        
                            <p>
                                When it comes to the success of modern day ecommerce stores and dropshipping stores who rely heavily on paid traffic, it is vital to create your ad selectively.
                            </p>

                            <p>
                                Below we will go into detail about the do’s and don’t of ads, the creatives and the copy in a few examples so that you can understand in a practical manner.
                            </p>

                            <div className="split-section">

                                <img src="https://img.genflow.com/gya/plugandplays/adSelection1.png" alt="" />
                                <div className="content">
                                    <p className="bold">5 Star Ad</p>

                                    <ul>
                                        <li>
                                            Headline provides a great hook and scroll stop.
                                        </li>
                                        <li>
                                            Very professional indoctrination video with all the benefits of the product and uses
                                            {' '}
                                            <span className="bold">an athlete</span>
                                            {' '}
                                            as an example of how they use it to gain advantage training for competitions.
                                        </li>
                                        <li>
                                            Good engagement, well kept
                                        </li>
                                        <li>Benefits are clear and highlighted with the use of emoji’s to draw attention.</li>
                                    </ul>
                                </div>

                            </div>

                            <div className="split-section">


                                <div className="content">
                                    <p className="bold">1 Star Ad</p>

                                    <ul>
                                        <li>
                                            Copy is too short and it’s not spaced out so it seems like an overwhelming read.

                                        </li>
                                        <li>
                                            Tries to invite & sell people right away to the masterclass. People skim ads.

                                        </li>
                                        <li>
                                            No attention grabbing creative.
                                        </li>
                                        <li>
                                            Then the landing page is no webinar but sales page product extremely expensive at $3000 and they are only using a landing page
                                        </li>
                                    </ul>
                                </div>
                                <img src="https://img.genflow.com/gya/plugandplays/adSelection2.png" alt="" />
                            </div>


                            <div className="split-section">

                                <img src="https://img.genflow.com/gya/plugandplays/adSelection3.jpg" alt="" />
                                <div className="content">
                                    <p className="bold">5 Star Ad</p>

                                    <ul>
                                        <li>
                                            Really unique eye catching picture, which gets attention well.

                                            4
                                            {' '}

                                        </li>
                                        <li>
                                            Emphasises transformation and highlights the use of a specific method (people love systems and buy into them easily)
                                        </li>
                                        <li>
                                            Really well-written long-form copy in the format of a sales letter.

                                        </li>

                                    </ul>
                                </div>

                            </div>

                            <div className="split-section">


                                <div className="content">
                                    <p className="bold">3 Star Ad</p>

                                    <ul>
                                        <li>
                                            Standard copy, nothing special to see here really, but
                                            {' '}
                                            <span className="bold">no mention of an outcome or reason</span>
                                            {' '}
                                            to buy this.
                                        </li>
                                        <li>
                                            Invasive pop ups, on the landing page

                                        </li>
                                        <li>
                                            Good creatives, products sell itself.

                                        </li>
                                    </ul>
                                </div>
                                <img src="https://img.genflow.com/gya/plugandplays/adSelection4.png" alt="" />
                            </div>

                            <div className="split-section">

                                <img src="https://img.genflow.com/gya/plugandplays/adSelection5.png" alt="" />
                                <div className="content">
                                    <p className="bold">5 Star Ad</p>

                                    <ul>
                                        <li>
                                            Great video
                                        </li>
                                        <li>
                                            It’s relevant and gives you a reason to buy - “just in time for spring walks.”

                                        </li>
                                        <li>
                                            The discount provides additional incentive and urgency to push the customer to buy.

                                        </li>

                                    </ul>
                                </div>

                            </div>

                            <div className="split-section">


                                <div className="content">
                                    <p className="bold">3 Star Ad</p>

                                    <ul>
                                        <li>
                                            Collections are never a bad choice as they boast variety, which gives the customer options.

                                        </li>
                                        <li>
                                            The rounded corner frame inside the image makes it seem cheap, rather than a full image.

                                        </li>
                                        <li>
                                            Copy isn’t effective enough and there’s no incentive to buy.


                                        </li>

                                    </ul>
                                </div>
                                <img src="https://img.genflow.com/gya/plugandplays/adSelection6.png" alt="" />
                            </div>

                            <div className="split-section">

                                <img src="https://img.genflow.com/gya/plugandplays/adSelection7.png" alt="" />
                                <div className="content">

                                    <p className="bold">5 Star Ad</p>

                                    <ul>
                                        <li>
                                            Very effective copy


                                        </li>
                                        <li>
                                            Addresses the number 1 objection about the product in the copy - “The number one dislike about trimmer is the tugs of hair and they pride themselves on avoiding that.”


                                        </li>
                                        <li>
                                            Product sells itself and people know the product, no need for a good creative.


                                        </li>

                                    </ul>
                                </div>

                            </div>

                            <div className="split-section">


                                <div className="content">

                                    <p className="bold">3 Star Ad</p>

                                    <ul>
                                        <li>
                                            Good studio shot but definitely won't cut it for cold.


                                        </li>
                                        <li>
                                            Good point is that they're only focused on the german market and their ads are written in German


                                        </li>
                                        <li>
                                            They have interesting bundles with a product that keeps your back upright

                                        </li>
                                        <li>
                                            The function of this product is not known by everyone, so an engaging video would perform much better than a static image.
                                        </li>

                                    </ul>
                                </div>
                                <img src="https://img.genflow.com/gya/plugandplays/adSelection8.png" alt="" />
                            </div>

                            <div className="split-section">

                                <img src="https://img.genflow.com/gya/plugandplays/adSelection9.png" alt="" />
                                <div className="content">

                                    <p className="bold">3 Star Ad</p>

                                    <ul>
                                        <li>
                                            Video is very well done and extremely targeted to fishers.
                                        </li>
                                        <li>
                                            Their focus is on temperature and keeping them protected and dry

                                        </li>
                                        <li>
                                            Fast pacing and good flow of the video.

                                        </li>
                                        <li>
                                            Lading page is their own Facebook page which is strikingly odd
                                        </li>
                                        <li>
                                            “Shop Now” converts better than “Learn More”
                                        </li>

                                    </ul>
                                </div>

                            </div>

                            <div className="split-section">


                                <div className="content">

                                    <p className="bold">5 Star Ad</p>

                                    <ul>
                                        <li>
                                            Straight to the point copy for ecom, always works.

                                        </li>
                                        <li>
                                            I really like the idea of having 3 pictures in one. One regular picture of the product by itself. And two pictures with a model in two different angles.

                                        </li>
                                        <li>
                                            This ad has been running since the beginning of november from 2020 and I always get retargeted by it. It's probably one of their highest grossing ads.

                                        </li>
                                    </ul>
                                </div>
                                <img src="https://img.genflow.com/gya/plugandplays/adSelection10.jpg" alt="" />
                            </div>


                            <div className="split-section">
                                <img src="https://img.genflow.com/gya/plugandplays/adSelection11.png" alt="" />

                                <div className="content">

                                    <p className="bold">5 Star Ad</p>

                                    <ul>
                                        <li>
                                            Short video highlighting features and benefits.

                                        </li>
                                        <li>
                                            The copy is powerful and not only highlights the features, but gives you a reason to buy (“prepare your advice for your next adventure”) and then stacks more incentives. Ie. supports the CDC & 10% OFF to encourage impulse purchases.


                                        </li>

                                    </ul>
                                </div>

                            </div>


                            <div className="split-section">


                                <div className="content">

                                    <p className="bold">3 Star Ad</p>

                                    <ul>
                                        <li>
                                            Average copy, the big difference is in the copy where they say that this is the only true F1 inspired watch.

                                        </li>
                                        <li>
                                            Standard creative, multiple images in one would’ve worked better.

                                        </li>
                                        <li>
                                            Not compelling enough to encourage many purchases.


                                        </li>
                                    </ul>
                                </div>
                                <img src="https://img.genflow.com/gya/plugandplays/adSelection12.png" alt="" />
                            </div>


                            <div className="split-section">
                                <img src="https://img.genflow.com/gya/plugandplays/adSelection13.png" alt="" />

                                <div className="content">

                                    <p className="bold">5 Star Ad</p>

                                    <ul>
                                        <li>
                                            Great ad, with 10k likes people love it.


                                        </li>
                                        <li>
                                            Copy provides the features (ingredients) and then the benefits of each ingredient.
                                        </li>

                                        <li>Focused on the transformation.</li>

                                        <li>
                                            Strong headline and 20% discount incentive.
                                        </li>

                                    </ul>
                                </div>

                            </div>

                            <div className="split-section">


                                <div className="content">

                                    <p className="bold">2 Star Ad</p>

                                    <ul>
                                        <li>
                                            Simple retargeting for bottom of the funnel but  too soon to show me this, plus it seems rushed and unprofessional.


                                        </li>
                                        <li>
                                            Should remind the customer why they need it.

                                        </li>
                                        <li>
                                            Shows by the low engagement.
                                        </li>
                                    </ul>
                                </div>
                                <img src="https://img.genflow.com/gya/plugandplays/adSelection14.png" alt="" />
                            </div>

                            <div className="split-section">

                                <img src="https://img.genflow.com/gya/plugandplays/adSelection15.png" alt="" />
                                <div className="content">


                                    <p className="bold">2 Star Ad</p>

                                    <ul>
                                        <li>
                                            Simple copy, maybe too simple
                                        </li>
                                        <li>
                                            The ad is taking people to their FB page, which is not as effective in driving sales as a good landing page.
                                        </li>
                                        <li>
                                            The learn more button is not as effective.

                                        </li>
                                        <li>
                                            No incentives or unique reasons to buy.
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="split-section">


                                <div className="content">


                                    <p className="bold">2 Star Ad</p>

                                    <ul>
                                        <li>
                                            Poor ad, rushed.

                                        </li>
                                        <li>
                                            Video format is irrelevant for a watch as everyone knows the function of a watch.

                                        </li>
                                        <li>
                                            Poor ad copy just mentioning features. Looks sloppy and had poor engagement. It tells you what it is not how it's different from other watches or brands
                                        </li>
                                        <li>The incentive is strong but it seems too try hard and it's not congruent with the “swiss/italian quality and authenticity.</li>
                                    </ul>
                                </div>
                                <img src="https://img.genflow.com/gya/plugandplays/adSelection16.png" alt="" />
                            </div>

                            <div className="split-section">

                                <img src="https://img.genflow.com/gya/plugandplays/adSelection17.jpg" alt="" />
                                <div className="content">


                                    <p className="bold">5 Star Ad</p>

                                    <ul>
                                        <li>
                                            I really love the creatives from this brand because they make an outstanding job in conveying their value proposition and transmitting their minimalistic brand culture. Not only in the shirt, but also in the ad itself.


                                        </li>
                                        <li>
                                            I think the value-prop written out in the creative is genius and could work really well for brands like Vapor, with something like "The Perfect Shirt For Your Outdoor Adventures" - no need to get fancy, just plain simple value-prop with product picture that conveys what the brand stands for.

                                        </li>
                                        <li>
                                            I've been seeing this ad running for at least a year now.

                                        </li>

                                    </ul>
                                </div>

                            </div>

                            <div className="split-section">


                                <img src="https://img.genflow.com/gya/plugandplays/adSelection18.png" alt="" />

                                <div className="content">
                                    <p className="bold">5 Star Ad</p>

                                    <ul>
                                        <li>
                                            The video is focused on the transformation and not the product. Very strong emotional appeal, everyone wants to achieve their dreams.

                                        </li>
                                        <li>
                                            The ad link redirects to the website's category of the Bonsai Trees.

                                        </li>


                                    </ul>
                                </div>

                            </div>

                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
