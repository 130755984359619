import React from 'react';
import { truncate } from 'lodash';
import { Link } from 'react-router-dom';

import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { docs } from './searchIndex';
import useStyles from './styles';

const ResultBox = ({ anchor, results }) => {
    const classes = useStyles();

    return (
        <Popper
            className={classes.popper}
            open={results?.length > 0}
            anchorEl={anchor}
            placement="bottom"
            style={{ borderRadius: 20, overflow: 'hidden' }}
        >
            <Paper>
                <List style={{ paddingBottom: 0 }}>
                    {results.map(({ ref }, i) => (
                        <ListItem
                            key={ref}
                            button
                            component={Link}
                            to={docs[ref]?.link}
                            style={{ backgroundColor: '#393939', height: '60px', borderBottomColor: `${i === results?.length - 1 ? '#393939' : 'white'}` }}
                        >
                            <ListItemText
                                primary={docs[ref]?.title}
                                secondary={truncate(docs[ref]?.body).replace(/\([^()]*\)/g, '')}
                            />
                        </ListItem>
                    ))}
                </List>
            </Paper>
        </Popper>
    );
};

export default ResultBox;
