import React, { useEffect, useState } from 'react';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import moment from 'moment';

import { uniqWith, sortBy, parseInt } from 'lodash';
import { useMutation, useQuery } from '@apollo/react-hooks';
import GetCalendarEntriesQuery from '../../../../api/getCalendarEntries';
import CreateUpdateCalendarEntryMutation from '../../../../api/createUpdateCalendarEntry';
import DeleteCalendarEntryMutation from '../../../../api/deleteCalendarEntry';

const withPlanOfAttack = PlanOfAttack => (props) => {
    const [year, setYear] = useState(moment().format('YYYY'));
    const [anchorEl, setAnchorEl] = useState(undefined);
    const [selected, setSelected] = useState(1);
    const [view, setView] = useState('year');
    const [date, setDate] = useState(new Date());
    const [draggedEvent, setDraggedEvent] = useState(undefined);
    const [event, setEvent] = useState(undefined);
    const [fabOpen, setFabOpen] = useState(false);
    const {
        data,
        error: calendarEntriesError,
        loading: calendarEntriesLoading,
    } = useQuery(GetCalendarEntriesQuery);

    const calendar = data?.getCalendarEntries?.calendar || {};
    const calendarEntries = (data?.getCalendarEntries?.data || []).map(({ startDateTime, endDateTime, ...rest }) => ({
        ...rest,
        startDateTime: new Date(startDateTime),
        endDateTime: new Date(endDateTime),
    }));


    const [createUpdateCalendarEntry] = useMutation(CreateUpdateCalendarEntryMutation, {
        update(cache, { data: { createUpdateCalendarEntry: { data: createUpdateResponseData } } }) {
            const readResults = cache.readQuery({
                query: GetCalendarEntriesQuery,
                variables: {},
            });

            const updatedEntry = {
                getCalendarEntries: {
                    ...readResults.getCalendarEntries,
                    data: uniqWith([
                        createUpdateResponseData,
                        ...readResults.getCalendarEntries.data,
                    ], ({ PK, SK }, { PK: PK2, SK: SK2 }) => PK === PK2 && SK === SK2),
                },
            };

            cache.writeQuery({
                query: GetCalendarEntriesQuery,
                data: {
                    ...updatedEntry,
                },
            });
        },
    });

    const [deleteCalendarEntry] = useMutation(DeleteCalendarEntryMutation, {
        refetchQueries: [{
            query: GetCalendarEntriesQuery,
        }],
    });

    // useEffect(() => {
    //     if (!calendarEntriesLoading && !!calendarEntries) {
    //         console.log('here is your data', calendarEntries);
    //
    //         const data = calendarEntries
    //             .map(event => ({
    //                 ...event,
    //                 start: new Date(event.startDateTime),
    //                 end: new Date(event.endDateTime),
    //             }));
    //
    //         console.log('data', data)
    //     }
    //
    //     setCalendarEvents(data);
    // }, [calendarEntries, calendarEntriesLoading]);

    // useEffect(() => setCalendarTasks(
    //     toolData
    //         .filter(event => event.type === 'task')
    //         .filter((task) => {
    //             if (taskFilter === 'Pending') {
    //                 return !task.done;
    //             }
    //             if (taskFilter === 'Done') {
    //                 return task.done;
    //             }
    //             return true;
    //         })
    //         .filter(task => moment(task.end).format('YYYY') === year.toString())
    //         .filter(task => (view === 'month' ? moment(task.end).format('MM') === moment(date).format('MM') : true)),
    // ),
    // [toolData, taskFilter, year, view, date]);


    const selectYear = (newYear) => {
        if (!checkTodayYear(newYear) && selected > 1) {
            setSelected(selected - 1); // as "Today" is removed from tab list if not the same year as today
        }

        setYear(newYear);
        setDate(moment([newYear]).toDate());
        onMenuClose();
    };

    const handleTabSelect = (e, newValue) => {
        if (newValue > 0) {
            setSelected(newValue);
            if (newValue === 1) {
                setView('year');
            } else if (isTodayYear && newValue === 2) {
                setDate(new Date());
                setView('day');
            } else {
                setDate(new Date(`${year}-${newValue - (isTodayYear ? 2 : 1)}-01`));
                setView('month');
            }
        } else {
            setAnchorEl(e.currentTarget);
        }
    };

    const onMenuClose = () => setAnchorEl(undefined);


    const years = [2021, 2022, 2023, 2024, 2025];
    const TODAY_LABEL = 'Today';
    const todayYear = parseInt(moment().format('YYYY'));
    const checkTodayYear = checkYear => todayYear === parseInt(checkYear);
    const isTodayYear = checkTodayYear(year);

    const labels = [
        <span>
            <UnfoldMoreIcon />
            {' '}
            {year}
        </span>,
        'Year', TODAY_LABEL, ...moment.months()]
        .filter((item) => {
            if (!isTodayYear && item === TODAY_LABEL) {
                return null;
            }

            return item;
        })
        .filter(Boolean);

    const handleDragStart = (eventToDrag) => {
        setDraggedEvent(eventToDrag);
    };

    const dragFromOutsideItem = () => draggedEvent;

    const moveResizeEvent = ({ event: prevEvent, start, end }) => {
        const newEv = {
            ...prevEvent,
            startDateTime: start.toISOString(),
            endDateTime: end.toISOString(),
        };
        saveEvent(newEv);
    };

    const onDropFromOutside = (eventDroppedFromOutside) => {
        console.log('eventDroppedFromOutside', eventDroppedFromOutside);
        // const event = {
        //     id: draggedEvent.id,
        //     title: draggedEvent.title,
        //     start,
        //     end,
        //     allDay,
        // };
        // setDraggedEvent(null);
        // moveEvent({ event, start, end });
    };

    const newEvent = ({ deselect = () => {}, ...ev }) => {
        console.log('ev', ev);
        setEvent({
            id: moment().toISOString(),
            title: '',
            allDay: false,
            startDateTime: (ev?.startDateTime || ev?.start).startOf('day').toISOString(),
            endDateTime: (ev?.endDateTime || ev?.end).endOf('day').toISOString(),
            isNewEvent: true,
            entryType: view === 'month' ? 'task' : 'event',
            colour: '#1882de',
            deselect,
        });
    };

    const createEvent = (entryType) => {
        const now = moment().startOf('hour');
        setEvent({
            entryType,
            isNewEvent: true,
            startDateTime: now.toDate(),
            endDateTime: now.add(1, 'hours').toDate(),
            allDay: false,
            complete: false,
        });
    };

    const selectEvent = (ev) => {
        setEvent(ev);
    };
    const clearEvent = () => setEvent(undefined);

    const saveEvent = ({ isNewEvent, deselect, ...eventToSave }) => {
        const { __typename, ...entry } = eventToSave;

        const extraQueryParams = {
            optimisticResponse: {
                createUpdateCalendarEntry: {
                    calendar,
                    data: { __typename, ...entry },
                    error: null,
                    __typename: 'CalendarEntryResponse',
                },
            },
        };

        createUpdateCalendarEntry({
            variables: {
                entry: isNewEvent ? {
                    startDateTime: eventToSave.startDateTime,
                    endDateTime: eventToSave.endDateTime,
                    entryType: eventToSave.entryType,
                    title: eventToSave.title,
                    colour: eventToSave.colour,
                    allDay: eventToSave?.allDay || false,
                    complete: eventToSave?.complete || false,
                } : entry,
            },
            ...(isNewEvent ? { } : extraQueryParams),
        }).then(clearEvent);

        if (deselect) deselect();
    };

    const deleteEvent = ({ SK }) => deleteCalendarEntry({
        variables: { entry: { SK } },
    });

    const toggleTask = ({ complete, ...task }) => {
        saveEvent({
            ...task,
            complete: !complete,
        });
    };

    const calendarProps = {
        handleDragStart,
        dragFromOutsideItem,
        onDropFromOutside,
        view,
        date,
        onEventDrop: moveResizeEvent,
        onEventResize: moveResizeEvent,
        onSelectSlot: newEvent,
        onSelectEvent: selectEvent,
        events: calendarEntries,
        loading: calendarEntriesLoading,
    };

    return (
        <PlanOfAttack
            isTodayYear={isTodayYear}
            selected={selected}
            anchorEl={anchorEl}
            years={years}
            onMenuClose={onMenuClose}
            onSelectSlot={newEvent}
            selectEvent={selectEvent}
            selectYear={selectYear}
            year={year}
            handleTabSelect={handleTabSelect}
            labels={labels}
            selectedEvent={event}
            clearEvent={clearEvent}
            saveEvent={saveEvent}
            deleteEvent={deleteEvent}
            tasks={sortBy(calendarEntries
                .filter(({ entryType }) => entryType === 'task'), 'startDateTime')}
            calendarProps={calendarProps}
            toggleTask={toggleTask}
            fabOpen={fabOpen}
            setFabOpen={setFabOpen}
            createEvent={createEvent}
            view={view}
            {...props}
        />
    );
};

export default withPlanOfAttack;
