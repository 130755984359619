import React from 'react';
import { upperCase } from 'lodash';
import { trackEvent } from '../../../utils/useAnalytics';
import './style.scss';

const HostsSection = ({
    id, title, hosts, href, apexAccess,
}) => {
    const [showModal, setShowModal] = React.useState(false);

    const applytoApex = () => {
        window.location.href = 'https://link.growyouragency.com/apex-apply';
        return null;
    };

    const track = (qaTitle, qaDate) => {
        trackEvent({
            action: 'Q&A_Reserve',
            category: 'Session_Name',
            label: qaTitle,
        });
        trackEvent({
            action: 'Q&A_Reserve',
            category: 'Booking_Day',
            label: qaDate,
        });
    };

    const regex = /<p>|<\/p>/g;

    return (
        <>
            <div className="hosts-container w-full lg:w-4/4 px-8 md:px-16 lg:px-0 lg:mx-auto" id={id}>
                <h1 className="text-center" style={{ fontSize: '2rem' }}>{title}</h1>
                {hosts?.map(({
                    title: hostTitle, date, name, img, position, description, link, images
                }, index) => (
                    <div key={index} className="hosts-detail flex-col sm:flex-row my-8 sm:my-0 w-full ">
                        <div className="image-section pr-8 sm:items-center md:justify-center lg:justify-end w-full sm:w-auto lg:w-1/4 mb-4 sm:mb-0 pl-8 sm:pl-0 md:flex-col lg:flex-row">
                            {
                                images?.length && images?.map((image, index)=> (
                                    <img src={image} alt="" className='mb-1 mr-1'  key={index} />
                                ))
                            }
                        </div>
                        <div className="description-section pl-8 w-full lg:w-2/3 flex flex-col sm:py-8">
                            <div className="content">
                                <div className="flex flex-col md:flex-row place-content-between mb-4">
                                    <p className="grey500">{hostTitle}</p>
                                    <p className="">{upperCase(date)}</p>
                                </div>
                                <div className="sm:flex sm:flex-col mb-4 md:hidden">
                                    <h2>
                                        {`${name},`}
                                    </h2>
                                    <h5>
                                    {position}
                                    </h5>
                                </div>
                                <div className="md:flex md:flex-row mb-4 items-end md:flex-wrap hidden">
                                    <h2 className='flex-row'>
                                        {`${name},`}
                                        <h5 className='md:ml-1 inline text-xl'>
                                    {position}
                                    </h5>
                                    </h2>
                                </div>
                                <p>
                                    {!!description ? description?.replace(regex, '') : ''}
                                </p>
                                { id === 'apex'
                                    ? (
                                        <button
                                            type="button"
                                            onClick={() => {
                                                if (apexAccess) {
                                                    window.open(
                                                        link,
                                                        '_blank',
                                                    );
                                                    return;
                                                }
                                                setShowModal(true);
                                            }}
                                            className="blkButton mt-8 hosts-button"
                                        >
                                            Reserve Your Spot
                                        </button>
                                    ) : (
                                        <a href={link} target="_blank" rel="noopener noreferrer">
                                            <button className="blkButton mt-8 hosts-button" onClick={() => track(hostTitle, date)}>Reserve Your Spot</button>
                                        </a>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                )) }
            </div>
            {showModal ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none whitespace-normal text-white"
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            {/* content */}
                            <div className="border-0 rounded-xl shadow-lg relative flex flex-col w-full bg-black outline-none focus:outline-none">
                                {/* header */}
                                <div className="flex items-start justify-between p-10 rounded-t">
                                    <h3 className="text-3xl font-semibold py-8">
                                        You don’t have access to this program!
                                    </h3>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                {/* body */}
                                <div className="relative p-10 flex-auto space-y-4">
                                    <p>
                                        This is an application-only program for Agency Owners doing $10,000+ a month who want to scale to $50k, $70k, $100k+ and use their agencies as a launch pad to get into 8-figures business models.
                                    </p>
                                    <p>
                                        Interested in joining? Schedule a call below with our APEX Student Integration Manager, Max, to apply for a spot.
                                    </p>
                                    <p>
                                        {/* <a href="https://link.growyouragency.com/apex-apply"><b>Click here to apply now</b></a> */}
                                        <button onClick={applytoApex} style={{ width: '155px', padding: '0px' }} className="buttonLgLight h-fit mt-7 hidden sm:flex">Apply Now</button>
                                    </p>
                                </div>
                                {/* footer */}
                                <div className="flex items-center justify-end p-10 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black" />
                </>
            ) : null}
        </>
    );
};

export default HostsSection;
