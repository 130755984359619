import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {
    BrowserRouter, Redirect, Route, Switch,
} from 'react-router-dom';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import ReactGA from 'react-ga4';
import { useIsLoggedIn } from '@genflow/web-auth';

import { useConfig } from '@genflow/core';
import useCourse from '@genflow/web-courses/hooks/useCourse';
import './init';
import './assets/css/tools.css';
import enableLogs from 'utils/enable-logs';
import DreamDashboardSetup from './views/pages/resources/PlugAndPlay/pages/DreamDashboardSetup';
import NicheResearch from './views/pages/resources/PlugAndPlay/pages/NicheResearch';
import LinkedInContentSchedule from './views/pages/resources/PlugAndPlay/pages/LinkedInContentSchedule';
import LinkedinAutomation from './views/pages/resources/PlugAndPlay/pages/LinkedinAutomation';
import LinkedInContent from './views/pages/resources/PlugAndPlay/pages/LinkedInContent';
import LinkedInKeywords from './views/pages/resources/PlugAndPlay/pages/LinkedInKeywords';
import SalesNavigatorIndustryList from './views/pages/resources/PlugAndPlay/pages/SalesNavigatorIndustryList';
import SendingLinkedInMessages from './views/pages/resources/PlugAndPlay/pages/SendingLinkedInMessages';
import UsingSalesNavigator from './views/pages/resources/PlugAndPlay/pages/UsingSalesNavigator';
import EmailPersonalisation from './views/pages/resources/PlugAndPlay/pages/EmailPersonalisation';
import CreateEmailScript from './views/pages/resources/PlugAndPlay/pages/CreateEmailScript';
import LemlistCampaign from './views/pages/resources/PlugAndPlay/pages/LemlistCampaign';
import SetupEmailAccount from './views/pages/resources/PlugAndPlay/pages/SetupEmailAccount';
import SetupLemlistAccount from './views/pages/resources/PlugAndPlay/pages/SetupLemlistAccount';
import AgencySoftwareStack from './views/pages/resources/PlugAndPlay/pages/AgencySoftwareStack';
import FbBusinessAdManager from './views/pages/resources/PlugAndPlay/pages/FbBusinessAdManager';
import WebsiteSetup from './views/pages/resources/PlugAndPlay/pages/WebsiteSetup';
import CleaningSocialProfiles from './views/pages/resources/PlugAndPlay/pages/CleaningSocialProfiles';
import TrackingEmails from './views/pages/resources/PlugAndPlay/pages/TrackingEmails';
import WarmingUpEmailAddress from './views/pages/resources/PlugAndPlay/pages/WarmingUpEmailAddress';
import HowToQualifyClients from './views/pages/resources/PlugAndPlay/pages/HowToQualifyClients';
import FindingLeadsForLocalBusinesses from './views/pages/resources/PlugAndPlay/pages/FindingLeadsForLocalBusinesses';
import FindingLeadsForEcommerce from './views/pages/resources/PlugAndPlay/pages/FindingLeadsForEcommerce';
import ProfileFunnel from './views/pages/resources/PlugAndPlay/pages/ProfileFunnel';
import StoringOrganisingLeads from './views/pages/resources/PlugAndPlay/pages/StoringOrganisingLeads';
import SetupUpWorkAccount from './views/pages/resources/PlugAndPlay/pages/SetupUpWorkAccount';
import HowToFindLeadsUpwork from './views/pages/resources/PlugAndPlay/pages/HowToFindLeadsUpwork';
import UpcomingQAPage from './views/pages/UpcomingQA/index';
import ClientCommunication from './views/pages/resources/PlugAndPlay/pages/ClientCommunication';
import GettingPaid from './views/pages/resources/PlugAndPlay/pages/GettingPaid';
import ObjectionHandling from './views/pages/resources/PlugAndPlay/pages/ObjectionHandling';
import TwoCCSales from './views/pages/resources/PlugAndPlay/pages/TwoCCSales';
import OneCCSales from './views/pages/resources/PlugAndPlay/pages/OneCCSales';
import FreeClientBible from './views/pages/resources/PlugAndPlay/pages/FreeClientBible';
import ClientBible from './views/pages/resources/PlugAndPlay/pages/ClientBible';
import AllAboutTracking from './views/pages/resources/PlugAndPlay/pages/AllAboutTracking';
import KickOffCall from './views/pages/resources/PlugAndPlay/pages/KickOffCall';
import FbAdsFunadmentals from './views/pages/resources/PlugAndPlay/pages/FbAdsFunadmentals';
import FbContingencyGuide from './views/pages/resources/PlugAndPlay/pages/FbContingencyGuide';
import FbHygiene from './views/pages/resources/PlugAndPlay/pages/FbHygiene';
import IOS14 from './views/pages/resources/PlugAndPlay/pages/iOS14';
import GoogleTagManager from './views/pages/resources/PlugAndPlay/pages/GoogleTagManager';
import ConversionAPI from './views/pages/resources/PlugAndPlay/pages/ConversionAPI';
import TrueTracking from './views/pages/resources/PlugAndPlay/pages/TrueTracking';
import FunnelsOfferFundamentals from './views/pages/resources/PlugAndPlay/pages/FunnelsOfferFundamentals';
import CopywritingFundamentals from './views/pages/resources/PlugAndPlay/pages/CopywritingFundamentals';
import ScienceBehindCreatives from './views/pages/resources/PlugAndPlay/pages/ScienceBehindCreatives';
import HowManyOfThings from './views/pages/resources/PlugAndPlay/pages/HowManyOfThings';
import Reporting101 from './views/pages/resources/PlugAndPlay/pages/Reporting101';
import TrafficWarmth from './views/pages/resources/PlugAndPlay/pages/TrafficWarmth';
import InterestsSizes from './views/pages/resources/PlugAndPlay/pages/InterestsSizes';
import CustomAudiences from './views/pages/resources/PlugAndPlay/pages/CustomAudiences';
import LookalikeAudiences from './views/pages/resources/PlugAndPlay/pages/LookalikeAudiences';
import LaunchingYourFirstAd from './views/pages/resources/PlugAndPlay/pages/LaunchingYourFirstAd';
import HowToTestAudiences from './views/pages/resources/PlugAndPlay/pages/HowToTestAudiences';
import LoadBalancingBudgets from './views/pages/resources/PlugAndPlay/pages/LoadBalancingBudgets';
import UtilisingCBOs from './views/pages/resources/PlugAndPlay/pages/UtilisingCBOs';
import TwoKingMetrics from './views/pages/resources/PlugAndPlay/pages/TwoKingMetrics';
import HowToReadDashboard from './views/pages/resources/PlugAndPlay/pages/HowToReadDashboard';
import ReTargeting101 from './views/pages/resources/PlugAndPlay/pages/ReTargeting101';
import CommunicationSchedule from './views/pages/resources/PlugAndPlay/pages/CommunicationSchedule';
import CoreConcepts from './views/pages/resources/PlugAndPlay/pages/CoreConcepts';
import NineFunnels from './views/pages/resources/PlugAndPlay/pages/NineFunnels';
import AutomateLeadFlow from './views/pages/resources/PlugAndPlay/pages/AutomateLeadFlow';
import IndustryChiropractors from './views/pages/resources/PlugAndPlay/pages/IndustryChiropractors';
import IndustryGyms from './views/pages/resources/PlugAndPlay/pages/IndustryGyms';
import OnboardingFunnel from './views/pages/resources/PlugAndPlay/pages/OnboardingFunnel';
import AdSelection from './views/pages/resources/PlugAndPlay/pages/AdSelection';
import ExamplesAdsLeadgen from './views/pages/resources/PlugAndPlay/pages/ExamplesAdsLeadgen';
import FbNativeVsLeadGen from './views/pages/resources/PlugAndPlay/pages/FbNativeVsLeadGen';
import ContentAndCreatives from './views/pages/resources/PlugAndPlay/pages/ContentAndCreatives';
import LeadAutomation from './views/pages/resources/PlugAndPlay/pages/LeadAutomation';
import Tools from './views/pages/Tools';
import ResourcesQARecordings from './views/pages/resources/QARecordings';
import SoundSleep from './views/pages/resources/PlugAndPlay/pages/SoundSleep';
import HowFBWorksInside from './views/pages/resources/PlugAndPlay/pages/HowFBWorksInside';
import IOSPrivacyImplications from './views/pages/resources/PlugAndPlay/pages/IOSPrivacyImplications';
import YoutubeAdsPlaybook from './views/pages/resources/PlugAndPlay/pages/YoutubeAdsPlaybook';
import ScalingHorizVSVertical from './views/pages/resources/PlugAndPlay/pages/ScalingHorizVSVertical';
import ResourcesPlugAndPlay from './views/pages/resources/PlugAndPlay';
import ResourcesVideoLibrary from './views/pages/resources/VideoLibrary';
import Profile from './views/pages/Profile';
import PiracyPolicy from './views/pages/PiracyPolicy';
import Providers from './providers';
import Layout from './components/layout';
import Phases from './views/pages/Phases';
import Courses from './views/pages/Courses';
import Register from './views/pages/auth/Register';
import GoogleShoppingCampaigns from './views/pages/resources/PlugAndPlay/pages/GoogleShoppingCampaigns';
import GoogleSearchNetwork from './views/pages/resources/PlugAndPlay/pages/GoogleSearchNetwork';
import GoogleDisplayNetwork from './views/pages/resources/PlugAndPlay/pages/GoogleDisplayNetwork';
import GoogleAds from './views/pages/resources/PlugAndPlay/pages/GoogleAds';
import FbRules from './views/pages/resources/PlugAndPlay/pages/FbRules';
import FaBoLocalBusinesses from './views/pages/resources/PlugAndPlay/pages/FaBoLocalBusinesses';
import MonkMode from './views/pages/resources/PlugAndPlay/pages/MonkMode';
import CreatingYourLinkedInProfile from './views/pages/resources/PlugAndPlay/pages/CreatingYourLinkedInProfile';
import ClientRevenueCalculator from './views/pages/Tools/ClientRevenueCalculator';
import BreakevenCalculator from './views/pages/Tools/BreakevenCalculator';
import LocalBusinessROI from './views/pages/Tools/LocalBusinessROI';
import PlanOfAttack from './views/pages/Tools/PlanOfAttack';
import FinancialPlanner from './views/pages/Tools/FinancialPlanner';
import OutreachTracker from './views/pages/Tools/OutreachTracker';
import AudienceSizeBudgetCalculator from './views/pages/Tools/AudienceSizeBudgetCalculator';
import LeadGenerationSuccessCalculator from './views/pages/Tools/LeadGenerationSuccessCalculator';
import Login from './views/pages/auth/Login';
import LifetimeValueCalculator from './views/pages/Tools/LifetimeValueCalculator';
import Article from './views/pages/Article';
import CourseOverview from './views/pages/CourseOverview';
import CourseView from './views/pages/CourseView';
import Admin from './views/pages/Admin';
// import IndustrySpecificGyms from './views/pages/resources/PlugAndPlay/pages/IndustrySpecificGyms';
import config from './config';
import { IS_DEV } from 'env';

Bugsnag.start({
    apiKey: '4209ea2b653470f9c870f6c17882ac6e',
    plugins: [new BugsnagPluginReact()],
});

const { TRACKING_ID } = config;
ReactGA.initialize(TRACKING_ID);

const ErrorBoundary = Bugsnag.getPlugin('react')
    .createErrorBoundary(React);


const PrivateRoute = ({ children, ...rest }) => {
    const isLoggedIn = useIsLoggedIn();
    const { vendorUuid, agencyNavigatorCourseProductID } = useConfig();
    const { loading, authoriseError } = useCourse(vendorUuid, agencyNavigatorCourseProductID);

    const hasAccess = authoriseError === null && loading !== true;

    const isOnPage = path => path.indexOf(rest?.path);
    if (
        isLoggedIn === null
        || (!hasAccess && !isOnPage('/privacy-policy'))
    ) {
        return null;
    }

    return (
        <Route
            {...rest}
            render={({ location }) => (isLoggedIn ? (
                children
            ) : (
                <Redirect
                    to={{
                        pathname: '/login',
                        state: { from: location },
                    }}
                />
            ))
            }
        />
    );
};

// Disable logs in production
if (!IS_DEV) {
    enableLogs(false);
}

ReactDOM.render(
    <ErrorBoundary>
        <Providers>
            <BrowserRouter>
                <Layout>
                    <Switch>
                        <Route path="/login" exact render={props => <Login {...props} />} />
                        <Route path="/register" exact render={props => <Register {...props} />} />
                        <PrivateRoute path="/programs" exact>
                            <Courses />
                        </PrivateRoute>
                        <PrivateRoute path="/programs/:remountKey" exact>
                            <Courses />
                        </PrivateRoute>


                        <PrivateRoute path="/Tools" exact>
                            <Tools />
                        </PrivateRoute>

                        <PrivateRoute path="/piracy-policy" exact>
                            <PiracyPolicy />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/qa" exact>
                            <ResourcesQARecordings />
                        </PrivateRoute>
                        <PrivateRoute path="/resources/plug-and-play" exact>
                            <ResourcesPlugAndPlay />
                        </PrivateRoute>
                        <PrivateRoute path="/resources/plug-and-play/creating-your-linkedin-profile" exact>
                            <CreatingYourLinkedInProfile />
                        </PrivateRoute>
                        <PrivateRoute path="/resources/plug-and-play/fa-bo-local-businesses" exact>
                            <FaBoLocalBusinesses />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/facebook-rules-automations-naming" exact>
                            <FbRules />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/google-ads-setup" exact>
                            <GoogleAds />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/google-display-network" exact>
                            <GoogleDisplayNetwork />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/google-search-network" exact>
                            <GoogleSearchNetwork />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/google-shopping-campaigns" exact>
                            <GoogleShoppingCampaigns />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/scaling-horiz-vs-vertical" exact>
                            <ScalingHorizVSVertical />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/youtube-ads-playbook" exact>
                            <YoutubeAdsPlaybook />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/ios-privacy-implications" exact>
                            <IOSPrivacyImplications />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/how-fb-works-inside" exact>
                            <HowFBWorksInside />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/onboarding-funnel" exact>
                            <OnboardingFunnel />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/6-figure-sleep" exact>
                            <SoundSleep />
                        </PrivateRoute>
                        <PrivateRoute path="/resources/plug-and-play/monk-mode" exact>
                            <MonkMode />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/niche-research" exact>
                            <NicheResearch />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/example-linkedin-content-schedule" exact>
                            <LinkedInContentSchedule />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/linkedin-automation" exact>
                            <LinkedinAutomation />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/linkedin-content" exact>
                            <LinkedInContent />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/linkedin-keywords" exact>
                            <LinkedInKeywords />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/sales-navigator-industry-list" exact>
                            <SalesNavigatorIndustryList />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/sending-linkedin-messages" exact>
                            <SendingLinkedInMessages />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/using-sales-navigator" exact>
                            <UsingSalesNavigator />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/email-personalisation" exact>
                            <EmailPersonalisation />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/how-to-create-your-email-script" exact>
                            <CreateEmailScript />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/how-to-set-up-a-lemlist-campaign" exact>
                            <LemlistCampaign />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/how-to-set-up-your-email-account" exact>
                            <SetupEmailAccount />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/how-to-set-up-your-lemlist-account" exact>
                            <SetupLemlistAccount />
                        </PrivateRoute>


                        <PrivateRoute path="/resources/plug-and-play/agency-software-stack" exact>
                            <AgencySoftwareStack />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/fb-business-and-ad-manager" exact>
                            <FbBusinessAdManager />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/website-setup" exact>
                            <WebsiteSetup />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/cleaning-your-social-profiles" exact>
                            <CleaningSocialProfiles />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/tracking-emails-for-manual-outreach" exact>
                            <TrackingEmails />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/warming-up-your-email-address" exact>
                            <WarmingUpEmailAddress />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/how-to-qualify-clients" exact>
                            <HowToQualifyClients />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/finding-leads-for-local-businesses" exact>
                            <FindingLeadsForLocalBusinesses />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/finding-leads-for-ecommerce" exact>
                            <FindingLeadsForEcommerce />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/profile-funnel" exact>
                            <ProfileFunnel />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/storing-and-organising-leads" exact>
                            <StoringOrganisingLeads />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/how-to-set-up-an-upwork-account" exact>
                            <SetupUpWorkAccount />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/how-to-find-leads-upwork" exact>
                            <HowToFindLeadsUpwork />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/client-communication" exact>
                            <ClientCommunication />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/getting-paid" exact>
                            <GettingPaid />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/objection-handling" exact>
                            <ObjectionHandling />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/2cc-sales-script" exact>
                            <TwoCCSales />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/1cc-sales-script" exact>
                            <OneCCSales />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/free-client-bible-template" exact>
                            <FreeClientBible />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/client-bible" exact>
                            <ClientBible />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/all-about-tracking" exact>
                            <AllAboutTracking />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/kick-off-call" exact>
                            <KickOffCall />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/facebook-ads-fundamentals" exact>
                            <FbAdsFunadmentals />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/the-ultimate-facebook-contingency-guide" exact>
                            <FbContingencyGuide />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/facebook-hygiene-and-avoiding-bans" exact>
                            <FbHygiene />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/ios-update" exact>
                            <IOS14 />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/tag-manager-setup" exact>
                            <GoogleTagManager />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/the-conversion-api" exact>
                            <ConversionAPI />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/true-tracking" exact>
                            <TrueTracking />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/funnels-and-offer-fundamentals" exact>
                            <FunnelsOfferFundamentals />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/copywriting-fundamentals" exact>
                            <CopywritingFundamentals />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/science-behind-creatives-that-sells" exact>
                            <ScienceBehindCreatives />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/how-many-of-things" exact>
                            <HowManyOfThings />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/reporting-101" exact>
                            <Reporting101 />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/traffic-warmth" exact>
                            <TrafficWarmth />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/interests" exact>
                            <InterestsSizes />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/custom-audiences" exact>
                            <CustomAudiences />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/lookalike-audiences" exact>
                            <LookalikeAudiences />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/launching-your-first-ad" exact>
                            <LaunchingYourFirstAd />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/how-to-test-audiences-and-find-winners" exact>
                            <HowToTestAudiences />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/load-balancing-budgets" exact>
                            <LoadBalancingBudgets />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/utilising-cbos" exact>
                            <UtilisingCBOs />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/the-two-king-metrics-and-their-math" exact>
                            <TwoKingMetrics />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/dream-dashboard-set-up" exact>
                            <DreamDashboardSetup />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/how-to-read-your-dashboard-for-cold" exact>
                            <HowToReadDashboard />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/re-targeting-101" exact>
                            <ReTargeting101 />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/communication-schedule" exact>
                            <CommunicationSchedule />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/core-concepts-to-keep-in-mind" exact>
                            <CoreConcepts />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/9-funnels-for-lead-gen" exact>
                            <NineFunnels />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/automate-lead-flow" exact>
                            <AutomateLeadFlow />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/industry-chiropractors" exact>
                            <IndustryChiropractors />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/industry-gyms" exact>
                            <IndustryGyms />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/example-ads-leadgen" exact>
                            <ExamplesAdsLeadgen />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/ad-selection" exact>
                            <AdSelection />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/facebook-native-vs-funnel-lead-gen" exact>
                            <FbNativeVsLeadGen />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/content-and-creatives" exact>
                            <ContentAndCreatives />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/plug-and-play/lead-automation" exact>
                            <LeadAutomation />
                        </PrivateRoute>

                        <PrivateRoute path="/resources/videos" exact>
                            <ResourcesVideoLibrary />
                        </PrivateRoute>

                        <PrivateRoute path="/articles/:lessonID" exact>
                            <Article />
                        </PrivateRoute>

                        <PrivateRoute path="/profile" exact>
                            <Profile />
                        </PrivateRoute>

                        <PrivateRoute path="/admin" exact>
                            <Admin />
                        </PrivateRoute>

                        <PrivateRoute path="/upcoming-q-a" exact>
                            <UpcomingQAPage />
                        </PrivateRoute>


                        <Route
                            path="/course/:productID?/phase/:phaseId?/:lesson?/:lessonID?"
                            exact
                            render={props => <CourseView {...props} />}
                        />
                        <Route
                            path="/resources/plug-and-play/:title?"
                            exact
                            render={props => <CreatingYourLinkedInProfile {...props} />}
                        />
                        <Route
                            path="/course/:id?"
                            exact
                            render={props => <Phases {...props} />}
                        />
                        <Route path="/course-overview" exact render={props => <CourseOverview {...props} />} />
                        <PrivateRoute path="/tools/lifetime-value-calculator" exact>
                            <LifetimeValueCalculator />
                        </PrivateRoute>
                        <PrivateRoute path="/tools/lead-generation-success-calculator" exact>
                            <LeadGenerationSuccessCalculator />
                        </PrivateRoute>
                        <PrivateRoute path="/tools/audience-size-budget-calculator" exact>
                            <AudienceSizeBudgetCalculator />
                        </PrivateRoute>
                        <PrivateRoute path="/tools/outreach-tracker" exact>
                            <OutreachTracker />
                        </PrivateRoute>
                        <PrivateRoute path="/tools/financial-planner" exact>
                            <FinancialPlanner />
                        </PrivateRoute>
                        <PrivateRoute path="/tools/plan-of-attack" exact>
                            <PlanOfAttack />
                        </PrivateRoute>

                        <PrivateRoute path="/tools/local-business-roi" exact>
                            <LocalBusinessROI />
                        </PrivateRoute>

                        <PrivateRoute path="/tools/breakeven-roas-calculator" exact>
                            <BreakevenCalculator />
                        </PrivateRoute>

                        <PrivateRoute path="/tools/client-revenue-calculator" exact>
                            <ClientRevenueCalculator />
                        </PrivateRoute>
                        <Redirect to="/programs" />
                    </Switch>
                </Layout>
            </BrowserRouter>
        </Providers>
    </ErrorBoundary>,
    document.getElementById('root'),
);
