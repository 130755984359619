import { useEffect, useState } from 'react';
import { isAdmin as cognitoIsAdmin } from '../utils/auth';

export default () => {
    const [isAdmin, setIsAdmin] = useState();

    useEffect(() => {
        (async () => {
            const admin = await cognitoIsAdmin();
            setIsAdmin(admin);
        })();
    }, []);

    return { isAdmin };
};
