import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import { Link } from 'react-router-dom';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        available: true,
        sectionName: 'Introduction',
    },
    {
        available: true,
        sectionName: 'What kind of custom audiences can you make?',
    },
    {
        available: true,
        sectionName: 'What are "saved" audiences?',
    },
    {
        available: true,
        sectionName: 'Which custom audiences are worth making?',
    },
];

const title = 'Custom Audiences, Exclusions and Combinations, What Is Worth It?';

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                            // selectedSection={selectedSection}
                            // setSelectedSection={onSelectSection}
                            // filter={filter}
                            // selectFilter={selectFilter}
                            // subFilter={subFilter}
                            // selectSubFilter={selectSubFilter}

                            sections={sections}
                            scrollTo={scrollTo}
                            title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]} className="plug-block">
                            <h2 className="mb-4">{title}</h2>

                            <div className="plug-block-subheading">Introduction</div>

                            <p>
                                A custom audience is a set of people that you can send ads to. These audiences allow you to market to people who've already expressed an interest in your brand or product. For example:
                            </p>

                            <ul>
                                <li>
                                    Newsletter subscribers
                                </li>
                                <li>
                                    Website traffic
                                </li>
                                <li>
                                    App traffic
                                </li>
                                <li>
                                    Engagement on Facebook or Instagram
                                </li>
                            </ul>

                            <p>
                                It is a very powerful tool to remind people about your offering and get the easiest and highest ROAS ad sets possible.
                            </p>

                            <p>
                                This means that if you work with a client who already has a strong organic presence, or acquires traffic from other sources (for example SEO), you’ll be in a better position than a client who is starting with zero.
                            </p>

                            <p>
                                Iman Gadzhi’s Instagram following and YouTube subscribers is one of the reasons that he appears… everywhere.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/customAudiences1.png" alt="" />
                            <img src="https://img.genflow.com/gya/plugandplays/customAudiences2.png" alt="" />

                            <p>
                                Of course, these are Google display ads - rather than Facebook ads - but Facebook’s audience network displays videos and adverts in placements outside of Facebook too: like in apps and games.
                            </p>
                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">What kind of custom audiences can you make?</div>

                            <p>
                                There are different sources you can have for this custom audience, and you can only pick one.
                            </p>

                            <p>
                                The your sources section refers to traffic that you own, the Facebook sources refers to traffic that happens inside their apps.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/customAudiences3.png" alt="" />

                            <p>
                                This doesn't mean that you have to choose only one when running the ads, you can choose multiple on your ad set but they have to be created independently.
                            </p>

                            <p>
                                Let's go over the sources and discuss what they do and where they would be useful.
                            </p>

                            <p className="bold">Website</p>

                            <p>
                                You first select the source which would be the Facebook pixel installed in your or your clients website.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/customAudiences4.png" alt="" />
                            <img src="https://img.genflow.com/gya/plugandplays/customAudiences5.png" alt="" />

                            <p>
                                Then events, there will show up a list of the events that have been triggered in your website, which means you can't create a list if no one has triggered the event.
                            </p>

                            <p>
                                You can create a list of everyone that has been on the website (since the pixel was installed).
                            </p>

                            <p>
                                Create lists of visitors by time spent on the website, this is created on percentiles. Which means the top 5% of people that spend more time on the website (it will ignore the bottom 95%) and so on. Which could be useful if you have a client that has a lot of traffic that bounces or isn't interested and closes the website very fast, maybe because they had a source of traffic that brings low quality traffic you don't want to retarget.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/customAudiences6.png" alt="" />

                            <p>
                                You can also create lists of specific URLs. For example - when targeting students for Copy Paste Agency, the higher-level program for GrowYourAgency - we might want to target people that have visited the case study for BUT not new students for whom this wouldn’t be suitable.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/customAudiences77.png" alt="" />

                            <p>
                                You paste the link or links you want to use as your retargeting source
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/customAudiences7.png" alt="" />

                            <p>
                                You can use ‘contains’ which means that at least what you wrote is inside the URL or equals where the URLs have to match exactly.
                            </p>

                            <p>
                                ‘Doesn't contain’ is useful when you want to target 'everyone else' for example if we wanted to target everyone accepting the people that have watched the case study.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/customAudiences8.png" alt="" />

                            <p>
                                Then there's retention which means how long do people stay in this audience. This can go from one day to 180 days. In other words you can think about how long people will see your ads from the moment they go to your website.
                            </p>

                            <p>
                                Broadly-speaking, the more expensive the product, the longer the window - up to 180 days. If the product is cheaper and you would expect a snap decision, 30 days is more appropriate.
                            </p>

                            <p>
                                Finally you add a name and an optional description to the audience which we never use. Our audience names are simple and state the source of the audience and how long do people stay on it. We try not to use abbreviations as we don’t think they are useful and can be confusing when our client is looking in the account. For example we have audiences like All pixel 180 days, IG Engagement 90 days, Add to Cart 7 days, etc. All of them say what they are without any additional information.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/customAudiences9.png" alt="" />

                            <p>
                                You might've noticed there are options to include more people, exclude or for example add conditionals like 'AND' and 'OR'. 'AND' means people must fit both conditions, like for example must've visited two different URLs or 'OR' Which is either or, so they could've visited this one or that one.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/customAudiences10.png" alt="" />

                            <p>
                                If for some reason you need this type of audiences, we recommend you don't create them here, but rather create them independently and then on the ad set make the exclusions or add the audiences you want to run together.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/customAudiences11.png" alt="" />

                            <p>
                                Here are the rest of the audiences that can be created.
                            </p>

                            <div style={{ height: 350 }}>
                                <iframe
                                    id="videoPlayer"
                                    className="sproutvideo-player"
                                    src={'https://videos.sproutvideo.com/embed/449dd8b61612edc4cd/a401349212ab254e?playerTheme=dark&amp;playerColor=263238'}
                                    frameBorder="0"
                                    allowFullScreen
                                    width="100%"
                                    height="100%"
                                />
                            </div>


                            <p>
                                It’s important to note that you do not need to update Facebook audiences: Facebook does this automatically, every few hours, if they are being used in an ad set. This does mean that the size will fluctuate as people leave the window, and others enter it.
                            </p>
                        </div>

                        <div ref={refs[2]} className="plug-block">
                            <div className="plug-block-subheading">What are "saved" audiences?</div>

                            <p>
                                A saved audience is an audience composed of interests, demographics and behaviours.
                            </p>

                            <p>You can’t create lookalike audiences from Saved audiences. </p>

                            <img src="https://img.genflow.com/gya/plugandplays/customAudiences12.png" alt="" />
                        </div>

                        <div ref={refs[3]} className="plug-block">
                            <div className="plug-block-subheading">Custom Audiences, Exclusions and Combinations, What Is Worth It?</div>

                            <p>
                                As explained in the
                                {' '}
                                <Link to="/resources/plug-and-play/how-many-of-things">'How Many of Things: Campaign Structure’</Link>
                                {' '}
                                our approach to retargeting is very straightforward and we would only have two or three ad sets.
                            </p>

                            <ol>
                                <li>
                                    All Pixel data - 180 or 90 or 30 days
                                </li>
                                <li>
                                    All IG engagers - 180 or 90 or 30 days
                                </li>
                                <li>
                                    All FB engagers - 180 or 90 or 30 days
                                </li>
                            </ol>

                            <p>
                                These two engaged audiences include everyone that has interacted with the ads.
                            </p>

                            <p>
                                But let’s look at some other methods and strategies used.
                            </p>

                            <p>
                                This is a standard BOF Lookalike Custom Audience:
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/customAudiences13.png" alt="" />
                            <img src="https://img.genflow.com/gya/plugandplays/customAudiences14.png" alt="" />
                            <img src="https://img.genflow.com/gya/plugandplays/customAudiences15.png" alt="" />
                            <img src="https://img.genflow.com/gya/plugandplays/customAudiences16.png" alt="" />

                            <p>This strategy entails the creation of multiple short term audiences like 7 days, 3 days 14 days and retargeting them by each step. </p>

                            <p>
                                For example only retargeting ‘Initiatecheckouts’ of the past 3 days and excluding purchases. This takes another step into complexity as some people do this and also exclude everyone else. For example if they retarget AddToCart they will exclude the ViewContent, InitiateCheckout, Purchases, etc.
                            </p>

                            <p>
                                This makes sense in theory as you don't want to be overlapping audiences or targeting people who have bought already, however in practice this is not true or practical.
                            </p>

                            <p>
                                CPMs rise high audiences are too small and they simply underperform.
                            </p>

                            <p>
                                They might have higher ROAS but when you compare it to all pixel retargeting it is simply not doing nearly as much in volume.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
