import { createMuiTheme } from '@material-ui/core';

const PRIMARY = '#1882de';
const SECONDARY = '#2dce89';
const TEXT_LIGHT = '#ffffff';
const TEXT_DARK = 'rgba(0, 0, 0, 0.95)';
const SELECTED_BG = 'linear-gradient(35deg, #1882de 0, #1830de 100%)';

const pickerTheme = createMuiTheme({
    palette: {
        primary: {
            main: PRIMARY,
        },
        secondary: {
            main: SECONDARY,
            contrastText: TEXT_LIGHT,
        },
        action: {
            disabled: '#6c6c6c',
            disabledOpacity: 0.65,
            disabledBorder: '1px solid #6c6c6c',
            selected: 'red',
        },
    },
    typography: {
        fontFamily: '-apple-system,system-ui,BlinkMacSystemFont,',
    },
    props: {
        MuiButtonBase: {
            disableRipple: true,
        },
        MuiTabs: {
            TabIndicatorProps: {
                style: {
                    display: 'none',
                },
            },
        },
    },
    overrides: {
        MuiTab: {
            root: {
                fontSize: '0.9rem',
                fontWeight: 700,
                color: TEXT_DARK,
                '&.MuiTab-textColorPrimary': {
                    color: TEXT_DARK,
                },
                '&.MuiTab-textColorPrimary.Mui-selected': {
                    color: TEXT_LIGHT,
                    background: SELECTED_BG,
                },
                '&.PrivateTabIndicator-root-1': {
                    display: 'none',
                },
            },
        },
    },
});

export default pickerTheme;
