import React from 'react';
import Grid from '@material-ui/core/Grid';
import SummaryList from '../SummaryList';

import FinanceColumn from './components/FinanceColumn';

import withMonth from './withMonth';

const Month = ({
    index,
    selected,
    year,
    month,
    currency,
}) => index === selected && (
    <Grid container spacing={2} justify="center">
        <Grid item container spacing={2} xs={12} sm={12} md={12} lg={10}>
            <Grid item xs={12} sm={12} md={3}>
                <FinanceColumn
                    defaultTitle="Revenue"
                    financeItemIndex={0} // index in data structure
                    year={year}
                    month={month}
                    currency={currency}
                    className="grey800Bg"
                    style={{ backgroundColor: '#1C1C1C' }}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
                <FinanceColumn
                    defaultTitle="Software"
                    financeItemIndex={1} // index in data structure
                    year={year}
                    month={month}
                    currency={currency}
                    isExpense
                />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
                <FinanceColumn
                    defaultTitle="Staff"
                    financeItemIndex={2} // index in data structure
                    year={year}
                    month={month}
                    currency={currency}
                    isExpense
                />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
                <FinanceColumn
                    defaultTitle="Other"
                    financeItemIndex={3} // index in data structure
                    year={year}
                    month={month}
                    currency={currency}
                    isExpense
                />
            </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={2}>
            <SummaryList
                currency={currency}
                year={year}
                month={month}
            />
        </Grid>
    </Grid>
);

export default withMonth(Month);
