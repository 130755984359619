import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        available: true, sectionName: 'List',
    },

];

const title = "Sales Navigator Industry List"

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]}  className="plug-block">  
                        <h2 className="mb-4">{title}</h2>
                            <ul>
                                <li>
                                    Accounting
                                </li>
                                <li>
                                    Airlines/Aviation
                                </li>
                                <li>
                                    Alternative Dispute Resolution
                                </li>
                                <li>
                                    Alternative Medicine
                                </li>
                                <li>Animation</li>
                                <li>
                                    Apparel and Fashion
                                </li>
                                <li>
                                    Architecture and Planning
                                </li>
                                <li>Arts and Crafts </li>
                                <li>
                                    Automotive
                                </li>
                                <li>
                                    Aviation and Aerospace
                                </li>
                                <li>
                                    Banking
                                </li>
                                <li>
                                    Biotechnology
                                </li>
                                <li>
                                    Broadcast Media
                                </li>
                                <li>
                                    Building Materials
                                </li>
                                <li>
                                    Business Supplies and Equipment
                                </li>
                                <li>
                                    Capital Markets
                                </li>
                                <li>Chemicals</li>
                                <li>
                                    Civic and Social Organization
                                </li>
                                <li>
                                    Civil Engineering
                                </li>
                                <li>
                                    Commercial Real Estate
                                </li>
                                <li>
                                    Computer and Network Security
                                </li>
                                <li>
                                    Computer Games
                                </li>
                                <li>
                                    Computer Hardware
                                </li>
                                <li>
                                    Computer Networking
                                </li>
                                <li>
                                    Computer Software
                                </li>
                                <li>
                                    Construction
                                </li>
                                <li>Consumer Electronics </li>
                                <li>
                                    Consumer Goods
                                </li>
                                <li>
                                    Consumer Services
                                </li>
                                <li>Cosmetics</li>
                                <li>Dairy</li>
                                <li>
                                    Defense and Space
                                </li>
                                <li>Design</li>
                                <li>
                                    E-Learning
                                </li>
                                <li>
                                    Education Management
                                </li>
                                <li>Electrical/Electronic Manufacturing </li>
                                <li>Entertainment</li>
                                <li>
                                    Environmental Services
                                </li>
                                <li>
                                    Events Services
                                </li>
                                <li>
                                    Executive Office
                                </li>
                                <li>
                                    Facilities Services
                                </li>
                                <li>Farming</li>
                                <li>
                                    Financial Services
                                </li>
                                <li>
                                    Fine Art
                                </li>
                                <li>Fishery</li>
                                <li>
                                    Food and Beverages
                                </li>
                                <li>
                                    Food Production
                                </li>
                                <li>
                                    Fund-Raising
                                </li>
                                <li>Furniture</li>
                                <li>
                                    Gambling and Casinos
                                </li>
                                <li>
                                    Glass, Ceramics and Concrete
                                </li>
                                <li>
                                    Government Administration
                                </li>
                                <li>
                                    Government Relations
                                </li>
                                <li>
                                    Graphic Design
                                </li>
                                <li>
                                    Health, Wellness and Fitness
                                </li>
                                <li>
                                    Higher Education
                                </li>
                                <li>
                                    Hospital and Health Care
                                </li>
                                <li>Hospitality</li>
                                <li>
                                    Human Resources
                                </li>
                                <li>
                                    Import and Export
                                </li>
                                <li>
                                    Individual and Family Services
                                </li>
                                <li>
                                    Industrial Automation
                                </li>
                                <li>
                                    Information Services
                                </li>
                                <li>
                                    Information Technology and Services
                                </li>
                                <li>Insurance</li>
                                <li>
                                    International Affairs
                                </li>
                                <li>
                                    International Trade and Development
                                </li>
                                <li>Internet</li>
                                <li>
                                    Investment Banking
                                </li>
                                <li>
                                    Investment Management
                                </li>
                                <li>Judiciary</li>
                                <li>
                                    Law Enforcement
                                </li>
                                <li>
                                    Law Practice
                                </li>
                                <li>
                                    Legal Services
                                </li>
                                <li>
                                    Legislative Office
                                </li>
                                <li>
                                    Leisure, Travel and Tourism
                                </li>
                                <li>Libraries</li>
                                <li>
                                    Logistics and Supply Chain
                                </li>
                                <li>
                                    Luxury Goods and Jewelry
                                </li>
                                <li>Machinery</li>
                                <li>
                                    Management Consulting
                                </li>
                                <li>Maritime</li>
                                <li>
                                    Market Research
                                </li>
                                <li>
                                    Marketing and Advertising
                                </li>
                                <li>
                                    Mechanical or Industrial Engineering
                                </li>
                                <li>
                                    Media Production
                                </li>
                                <li>
                                    Medical Devices
                                </li>
                                <li>
                                    Medical Practice
                                </li>
                                <li>
                                    Mental Health Care
                                </li>
                                <li>
                                    Military
                                </li>
                                <li>
                                    Mining and Metals
                                </li>
                                <li>
                                    Motion Pictures and Film
                                </li>
                                <li>
                                    Museums and Institutions
                                </li>
                                <li>Music</li>
                                <li>Nanotechnology</li>
                                <li>Newspapers</li>
                                <li>
                                    Nonprofit Organization Management
                                </li>
                                <li>
                                    Oil and Energy
                                </li>
                                <li>
                                    Online Media
                                </li>
                                <li>Outsourcing/Offshoring </li>
                                <li>
                                    Package/Freight Delivery
                                </li>
                                <li>
                                    Packaging and Containers
                                </li>
                                <li>
                                    Paper and Forest Products
                                </li>
                                <li>
                                    Performing Arts
                                </li>
                                <li>Pharmaceuticals</li>
                                <li>Philanthropy </li>
                                <li>
                                    Photography
                                </li>
                                <li>Plastics</li>
                                <li>
                                    Political Organization
                                </li>
                                <li>
                                    Primary/Secondary Education
                                </li>
                                <li>Printing</li>
                                <li>
                                    Professional Training and Coaching
                                </li>
                                <li>
                                    Program Development
                                </li>
                                <li>
                                    Public Policy
                                </li>
                                <li>
                                    Public Relations and Communications
                                </li>
                                <li>
                                    Public Safety
                                </li>

                                <li>
                                    Publishing
                                </li>
                                <li>
                                    Railroad Manufacture
                                </li>
                                <li>Ranching</li>
                                <li>Real Estate </li>
                                <li>
                                    Recreational Facilities and Services
                                </li>
                                <li>
                                    Religious Institutions
                                </li>
                                <li>
                                    Renewables and Environment
                                </li>
                                <li>Research</li>
                                <li>Restaurants</li>
                                <li>Retail</li>
                                <li>
                                    Security and Investigations
                                </li>
                                <li>Semiconductors</li>
                                <li>Shipbuilding</li>
                                <li>Sporting Goods </li>
                                <li>Sports</li>
                                <li>Staffing and Recruiting </li>
                                <li>Supermarkets</li>
                                <li>Telecommunications</li>
                                <li>Textiles</li>
                                <li>
                                    Think Tanks
                                </li>
                                <li>Tobacco</li>
                                <li>
                                    Translation and Localization
                                </li>
                                <li>Transportation/Trucking/Railroad </li>
                                <li>Utilities</li>
                                <li>Venture Capital and Private Equity </li>
                                <li>Veterinary</li>
                                <li>Warehousing</li>
                                <li>Wholesale</li>
                                <li>Wine and Spirits </li>
                                <li>Wireless</li>
                                <li>
                                    Writing and Editing
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
