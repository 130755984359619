import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import { Link } from 'react-router-dom';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        available: true,
        sectionName: 'Introduction:',
    },
    {
        available: true,
        sectionName: 'Home renovation',
    },
    {
        available: true,
        sectionName: 'Accountancy',
    },
];

const title = 'Content & Creatives';

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                // selectedSection={selectedSection}
                                // setSelectedSection={onSelectSection}
                                // filter={filter}
                                // selectFilter={selectFilter}
                                // subFilter={subFilter}
                                // selectSubFilter={selectSubFilter}

                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]} className="plug-block">
                            <h2 className="mb-4">{title}</h2>  

                            <div className="plug-block-subheading">Introduction</div>

                            <p>
                                In the module
                                {' '}
                                <Link to="/resources/plug-and-play/science-behind-creatives-that-sells">
                                    "Science Behind Creatives That Sells"
                                </Link>
                                {' '}
                                we went over a lot of the mindsets and processes to come up with creatives according
                                to the angle and the offer.
                            </p>

                            <p>
                                All the concepts taught in that module apply to local and lead gen clients and you
                                still have to have different angles. Not everyone who wants to sell their home does
                                it for the same reason and not everyone will make the choice to trust you for the
                                same reason.
                            </p>

                            <p>
                                Here we’ll look at some real life examples in different niches to illustrate this.
                            </p>
                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">Home renovation</div>

                            <p>
                                Here are four ads for home renovation with very different angles that will appeal to
                                different people:
                            </p>

                            <div className="split-section">
                                <img src="https://img.genflow.com/gya/plugandplays/contentCreatives1.png" alt="" />

                                <div className="content">
                                    <span>This advert plays on specificity and intrigue.</span>
                                </div>
                            </div>

                            <div className="split-section">
                                <img src="https://img.genflow.com/gya/plugandplays/contentCreatives2.png" alt="" />

                                <div className="content">
                                    <span>This advert contains an extremely compelling and specific discount.</span>
                                </div>
                            </div>

                            <div className="split-section">
                                <img src="https://img.genflow.com/gya/plugandplays/contentCreatives3.png" alt="" />

                                <div className="content">
                                    <span>
                                        This advert also plays on intrigue, with a compelling CTA - that fits the quiz
                                        funnel classification - and a creative that appears interactive.
                                    </span>
                                </div>
                            </div>

                            <div className="split-section">
                                <div style={{ height: 250 }}>
                                    <iframe
                                        id="videoPlayer"
                                        className="sproutvideo-player"
                                        src="https://videos.sproutvideo.com/embed/709dd7be1d1be9c9f9/c401b833ef8069cf?playerTheme=dark&amp;playerColor=263238"
                                        frameBorder="0"
                                        allowFullScreen
                                        width="100%"
                                        height="100%"
                                    />
                                </div>

                                <div className="content">
                                    <span>
                                        This advert plays with credibility, noting the date of their establishment, and
                                        that they are the most trusted in the area.
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="plug-block">
                            <div className="plug-block-subheading">Accountancy</div>

                            <div className="split-section">
                                <img src="https://img.genflow.com/gya/plugandplays/contentCreatives4.png" alt="" />

                                <div className="content">
                                    <span>
                                        As you can see in this ad, this accountant company is going for self-employed
                                        professionals with an angle specifically tailored to their needs asking if they
                                        are dealing with IRS and state tax problems.
                                    </span>
                                </div>
                            </div>

                            <div className="split-section">
                                <img src="https://img.genflow.com/gya/plugandplays/contentCreatives5.png" alt="" />

                                <div className="content">
                                    <span>
                                        On this other angle they're referring to tax refunds for PPI (Payment Protection
                                        Insurance) which is explained on the video, they also mention how they've
                                        reclaimed over $20m
                                    </span>
                                </div>
                            </div>

                            <div className="split-section">
                                <img src="https://img.genflow.com/gya/plugandplays/contentCreatives6.png" alt="" />

                                <div className="content">
                                    <span>
                                        In this other case the angle is going for Healthcare workers specifically
                                        telling them a 'trick' to get tax back for their uniform laundry and union.
                                    </span>
                                </div>
                            </div>

                            <p>
                                The huge variety of adverts here demonstrates the myriad ways to phrase, tailor,
                                find and offer angles to users, depending on the industry or niche.
                            </p>

                            <a href="https://go.growyouragency.com/example-ads-lead-gen">
                                https://go.growyouragency.com/example-ads-lead-gen
                            </a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
