import React, { createContext, useState } from 'react';

const OnboardingContext = createContext(null);
const { Provider, Consumer: OnboardingConsumer } = OnboardingContext;

const OnboardingProvider = ({ children }) => {
    const [activePage, setActivePage] = useState(0);
    const [activeInfoType, setActiveInfoType] = useState('');
    const [showItem, setShowItem] = useState(null);
    const [show, setShow] = useState(true);


    return (
        <Provider
            value={{
                setActivePage,
                activePage,
                setActiveInfoType,
                activeInfoType,
                show,
                setShow,
                setShowItem,
                showItem,
            }}
        >
            {children}
        </Provider>
    );
};

export { OnboardingContext, OnboardingProvider, OnboardingConsumer };
