import React from 'react';
import { trackEvent } from '../../utils/useAnalytics';

const HostsSection = ({
    title, date, img, name, position, id, link, apexAccess, images,
}) => {
    const [showModal, setShowModal] = React.useState(false);

    const applytoApex = () => {
        window.open(
            'https://link.growyouragency.com/apex-apply',
            '_blank',
        );
        return null;
    };

    const track = (qaTitle, qaDate) => {
        trackEvent({
            action: 'Q&A_Reserve',
            category: 'Session_Name',
            label: qaTitle,
        });
        trackEvent({
            action: 'Q&A_Reserve',
            category: 'Booking_Day',
            label: qaDate,
        });
    };

    function extractTime() {
        const pattern = /[0-9]+[A-Za-z][A-Za-z].*UK/i;
        return date?.match(pattern);
    }

    return (
        <>
            <div className="flex flex-col md:flex-row mb-5 justify-between items-center w-[100%]">
                <div className="flex flex-row w-[100%] ">
                    <div className="flex lg:flex-row mr-3 md:mr-6 flex-col">
                        {
                        images?.length && images?.map(image => (
                            <div className="h-[80px] w-[80px] md:h-[96px] md:w-[96px] rounded-[8px] mr-1 mb-1 lg:mb-0 overflow-hidden ">
                                <img className="object-cover h-full w-full" src={image} alt="" />
                            </div>
                        ))
                        }
                    </div>
                    <div className="flex flex-col justify-center w-[100%]">
                        <div className="flex flex-col md:flex-row md:items-center mb-[3px]">
                            <p className="grey500 mr-3 text-base">{title}</p>
                            <p className="bg-white py-[3px] px-[8px] rounded-[20px] font-bold text-[12px] w-[80px] mt-1 text-center">
                                {extractTime()}
                            </p>
                        </div>
                        <div className="flex flex-col md:flex-row md:items-end mt-[3px] md:hidden">
                            <p className="mr-2 text-white text-base md:text-2xl ">
                                {`${name}, `}
                            </p>
                            <p className="text-lg my-0 text-white text-xs md:text-base">
                                {position}
                            </p>
                        </div>
                        <div className="md:flex flex-col md:flex-row md:items-end mt-[3px] hidden">
                            <p className="mr-2 text-white text-base md:text-2xl ">
                                {`${name}, `}
                                <p className="text-lg my-0 text-white text-xs md:text-base inline">
                                    {position}
                                </p>
                            </p>
                        </div>

                        <div className="block md:hidden mt-2">
                            { id === 'apex'
                                ? (
                                    <button type="button" onClick={() => setShowModal(true)} className="border-solid border-2 border-white w-[100px]  h-[30px] rounded-full text-white">Reserve </button>
                                ) : (
                                    <a href={link} target="_blank" rel="noopener noreferrer">
                                        <button type="button" className="border-solid border-2 border-white w-[100px] h-[30px] rounded-full text-white" onClick={() => track(title, date)}>Reserve </button>
                                    </a>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className="hidden md:block">
                    { id === 'apex'
                        ? (
                            <button
                                type="button"
                                onClick={() => {
                                    if (apexAccess) {
                                        window.open(
                                            link,
                                            '_blank',
                                        );
                                        return;
                                    }
                                    setShowModal(true);
                                }}
                                className="border-solid border-2 border-white w-[100px]  h-[40px] rounded-full text-white"
                            >
                                Reserve
                            </button>
                        ) : (
                            <a href={link} target="_blank" rel="noopener noreferrer">
                                <button type="button" className="border-solid border-2 border-white w-[100px] h-[40px] rounded-full text-white" onClick={() => track(title, date)}>Reserve </button>
                            </a>
                        )
                    }
                </div>
            </div>

            {showModal ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none whitespace-normal text-white"
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            {/* content */}
                            <div className="border-0 rounded-xl shadow-lg relative flex flex-col w-full bg-black outline-none focus:outline-none">
                                {/* header */}
                                <div className="flex items-start justify-between p-10 rounded-t">
                                    <h3 className="text-3xl font-semibold py-8">
                                        You don’t have access to this program!
                                    </h3>
                                    <button
                                        type="button"
                                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                <div className="relative p-10 flex-auto space-y-4">
                                    <p>
                                        This is an application-only program for Agency Owners doing $10,000+ a month who want to scale to $50k, $70k, $100k+ and use their agencies as a launch pad to get into 8-figures business models.
                                    </p>
                                    <p>
                                        Interested in joining? Schedule a call below with our APEX Student Integration Manager, Max, to apply for a spot.
                                    </p>
                                    <p>
                                        {/* <a href="https://link.growyouragency.com/apex-apply"><b>Click here to apply now</b></a> */}
                                        <button type="button" onClick={applytoApex} style={{ width: '155px', padding: '0px' }} className="buttonLgLight h-fit mt-7 sm:flex">Apply Now</button>
                                    </p>
                                </div>
                                <div className="flex items-center justify-end p-10 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black" />
                </>
            ) : null}
        </>
    );
};

export default HostsSection;
