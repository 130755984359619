import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import { Link } from 'react-router-dom';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        available: true,
        sectionName: "In today's teaching",
    },
    {
        available: true,
        sectionName: 'Importance of automating lead flow',
    },
];

const title = 'Automate Lead Flow to Your Client';

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                // selectedSection={selectedSection}
                                // setSelectedSection={onSelectSection}
                                // filter={filter}
                                // selectFilter={selectFilter}
                                // subFilter={subFilter}
                                // selectSubFilter={selectSubFilter}

                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]} className="plug-block">
                            <h2 className="mb-4">{title}</h2>                        


                            <div className="plug-block-subheading">In today's teaching</div>

                            <ul>
                                <li>Importance of automating lead flow</li>
                                <li>Lead automation Plug n Play</li>
                            </ul>
                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">Importance of automating lead flow</div>

                            <p>
                                We've spoken about this in other modules about the importance of helping your client
                                make more money, it is in the end the only way to keep them happy and for the long
                                term. We went away and created a complete in detail guide with various methods on
                                how you can help your client make more of the leads you send their way.
                            </p>

                            <p>
                                If you didn't have a system like this in place this is roughly how the scenario
                                would look like. You start running ads and they start getting leads, every few days
                                you or your client download the list from Facebook or from the CRM and they would
                                contact them a few days later. Although this system works, you'll quickly find
                                complains from your client after a while about quality of leads or that they
                                sometimes don't even remember the offer.
                            </p>

                            <p>
                                When you start working with clients that really know how to play the game and
                                understand the value of leads you will see it is a completely different story. They
                                will ask you for the leads as they come, they will call them right away and have
                                follow up systems in place for it. To make the most out of the leads you usually
                                need to call or contact them short after their information has been received. A lot
                                of agency owners would say their job ends doing the ads but that will lead to less
                                retention and actually more stress. the more stress free way of running an agency is
                                with happy clients not with less work.
                            </p>

                            <p>
                                So go ahead and put the system we presented in place for your client, that way as
                                soon as a lead comes, they will get it on their email, phone or even on slack.
                            </p>
                        </div>

                        <div className="plug-block">
                            <div ref={refs[2]} className="plug-block-subheading">
                                Lead automation Plug n Play
                            </div>

                            <Link to="/resources/plug-and-play/lead-automation">View here</Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
