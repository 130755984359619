import React, {
    useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useLocation } from 'react-router-dom';
import { lowerCase } from 'lodash';
import { useIsLoggedIn } from '@genflow/web-auth';
import { useConfig } from '@genflow/core';
import useCourse from '@genflow/web-courses/hooks/useCourse';
import Bugsnag from '@bugsnag/js';
import AccessModal from '../modals/AccessModal';
import OnboardingQuestions from '../../views/pages/onboarding/OnboardingQuestions';
import OnboardingTour from '../../views/pages/onboarding/OnboardingTour';
import { UserDataContext } from '../../providers/UserDataProvider';
import { OnboardingContext } from '../../providers/OnboardingProvider';
import { trackPageView } from '../../utils/useAnalytics';
import NavBar from '../Navbar/NavBar';
import { TRACKABLES } from '../../constants/Trackables';
import getUserProfile from '../../api/getUserProfile';
import { getUser } from '../../utils/auth';


const noNavBarPaths = ['register', 'login', 'lessons', 'resources/qa', 'join', 'piracy-policy'];

function isDisabledNavBarPath(URLString) {
    const x = noNavBarPaths.filter(item => URLString.includes(item));
    return !!x.length;
}

const Layout = ({ children }) => {
    const location = useLocation();
    const [userId, setUserId] = useState(null);
    const { vendorUuid, agencyNavigatorCourseProductID } = useConfig();
    const isLoggedIn = useIsLoggedIn(true);
    const {
        userProfile, setUserProfile, setUserToken, showAccessModal, setShowAccessModal,
    } = useContext(UserDataContext);


    useEffect(() => {
        setBugsnagUser();
    }, [isLoggedIn, userProfile]);

    const setBugsnagUser = async () => {
        try {
            const cognitoUser = await getUser();
            if (cognitoUser?.attributes?.sub) {
                const id = cognitoUser?.attributes?.sub;
                const token = cognitoUser?.signInUserSession?.idToken?.jwtToken;
                setUserToken(token);
                setUserId(id);
                const name = `${cognitoUser?.attributes?.family_name} ${cognitoUser?.attributes?.given_name}`;
                const email = cognitoUser?.attributes?.email;
                Bugsnag.setUser(id, email, name);
            }
        } catch (e) {
            console.log(e);
        }
    };

    // track current url in trackable list whenever url changes
    const analytics = useCallback(() => {
        const pathArr = location?.pathname?.split('/');
        const path = pathArr?.[pathArr?.length - 1];

        const trackable = TRACKABLES?.find(({ value }) => lowerCase(value) === lowerCase(path));
        if (trackable) {
            trackPageView(location.pathname, trackable?.label);
        }
    }, [location.pathname]);

    useEffect(() => {
        analytics();
    }, [analytics]);

    const showNavBar = isLoggedIn && !isDisabledNavBarPath(location.pathname);

    let hasAccess = false;
    const { loading, authoriseError } = useCourse(vendorUuid, agencyNavigatorCourseProductID, {});
    hasAccess = authoriseError === null && loading !== true;

    const {
        activeInfoType, setActiveInfoType, setActivePage, show, setShow,
    } = useContext(OnboardingContext);

    // fetch user profile from admin
    const { data, refetch } = useQuery(getUserProfile);
    const userProfileData = data?.getCustomerUserProfileData?.data?.[0];
    const parsedData = userProfileData?.data ? JSON.parse(userProfileData?.data) : {};

    useEffect(() => {
        if (userId) {
            refetch();
            setShow(true);
        }
    }, [userId]);

    // update local storage after fetch
    useEffect(() => {
        if (userProfileData !== null) {
            const profileData = userProfile?.data;
            setUserProfile({ ...userProfileData, data: { ...profileData, ...parsedData } });
        }
    }, [userProfileData, userId]);

    const showOnboardingQuestion = useMemo(() => ((userProfile?.data?.onboardingCompleted !== true
        && parsedData?.onboardingCompleted !== true) && isLoggedIn && !!userId), [parsedData, userProfile, isLoggedIn, userId]);

    const showOnboarding = userProfile?.data?.tourCompleted?.completed !== true && userProfile?.data?.onboardingCompleted && isLoggedIn && !!userId;


    return (
        <>
            {showNavBar && <NavBar />}
            {/* only open if user has not undergone onboarding */}
            <OnboardingQuestions open={showOnboardingQuestion && !loading} />

            {/* initial open if user has completed onboarding questions, subsequent open on info icon press  */}
            {(show || !!activeInfoType) && !loading && (
                <OnboardingTour
                    open={(showOnboarding || !!activeInfoType) && hasAccess}
                    onClose={() => {
                        setShow(false);
                        setActiveInfoType('');
                        setActivePage(0);
                    }}
                />
            )}

            {/* trigger access modal from any component on the app */}
            {
                showAccessModal && <AccessModal setShowAccessModal={setShowAccessModal} />
            }
            <div>
                {children}
            </div>

            {/*            {showNavBar && <NavBar />}
            <div className="desktop-only">
                {children}
            </div>
            <div className="mobile-only mobile-only-message">
                Please access the platform on a computer or laptop for the most valuable experience.
            </div> */}
        </>
    );
};
export default Layout;
