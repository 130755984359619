/* eslint-disable */
import axios from 'axios';
import {getToken, getUser} from './auth';
import config from "../config";
import { useConfig } from '@genflow/core';
import env from "../env";

const { publishableKey, baseSessionUrl,
    entitlementUrl, entitlementDiscountCheckUrl,
    subscriptionsServiceUrl } = config[env].stripe;

const { stripeProductPlans } = config.stripeProductPlans;    

const isBrowser = typeof window !== `undefined`;

const getSession = (plan, IdToken) => axios.get(`${baseSessionUrl}/${plan}`,{
    timeout: 10000,
    headers: {'Authorization': `Bearer ${IdToken}`}
});

const getEntitlement = (plan, IdToken) => axios.get(`${entitlementUrl}/${plan}`,{
    timeout: 10000,
    headers: {'Authorization': `Bearer ${IdToken}`}
});

export const getIsDiscountedGYAUser = (email) => axios.get(`${entitlementDiscountCheckUrl}/${email}`,{
    timeout: 10000,
});

export const redirectToCheckout = async (plan) => {
    if(isBrowser){
        try{
            const IdToken = await getToken();

            if(!IdToken) return window.location.href = '/';

            const data = await getSession(plan, IdToken.idToken.jwtToken);
            const stripe = await window.Stripe(publishableKey).redirectToCheckout({sessionId: data.data.sessionId});
        }catch(e){
            console.log(e);
        }
    }
};

export const selectSubscription = async () => {
    try {
        const { stripeProductPlans } = config;
        const cognitoUser = await getUser();
        const cognitoEmail = cognitoUser?.attributes?.email;
        const userDiscounts = await getIsDiscountedGYAUser(cognitoEmail);
        await redirectToCheckout(
            userDiscounts?.data?.isDiscounted
                ? stripeProductPlans.agencyNavigatorExistingStudents
                : stripeProductPlans.agencyNavigator,
        );
    } catch (e) {
        console.log(e);
    }
};

export const redirectToHome = async (plan) => {
    if(isBrowser){
        try{
            const IdToken = await getToken();

            if(!IdToken) return window.location.href = '/';

            const data = await getEntitlement(plan, IdToken.idToken.jwtToken);

            console.log(data);

            return window.location.href = '/';
        }catch(e){
            console.log(e);
        }
    }
};

export const getSubscription = async () =>{

    const IdToken = await getToken();

    return axios.get(subscriptionsServiceUrl,{
        timeout: 10000,
        headers: {'Authorization': `Bearer ${IdToken.idToken.jwtToken}`}
    });
};

export const cancelSubscription = async (subscriptionId) => {
    const IdToken = await getToken();

    return axios.get(`${subscriptionsServiceUrl}/${subscriptionId}/cancel`,{
        timeout: 10000,
        headers: {'Authorization': `Bearer ${IdToken.idToken.jwtToken}`}
    });
};
