import gql from 'graphql-tag';

export default gql`
    mutation ($vendorUuid: ID!, $clientCustomer: CreateUpdateClientCustomerInput!){
        createUpdateClientCustomer(vendorUuid: $vendorUuid, clientCustomer: $clientCustomer) {
            errors
            data {
                id
                createdTimestamp
                updatedTimestamp
                clientCustomerName
                totalDebits {
                    USD
                }
                totalCredits {
                    USD
                }
            }
        }
    }

`;
