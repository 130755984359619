import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from 'reactstrap';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles(() => ({
    root: {
        '& > *': {
            marginBottom: 20,
            width: '100%',
        },
        width: '100%',

        maxWidth: 500,
        margin: '0 auto',
        marginTop: 30,
    },

    inputs: {
        '& > *': {
            marginBottom: 20,
            width: '100%',
        },
    },

    button: {
        marginTop: 20,
        marginBottom: 20,
        color: '#fff',
        background: '#060',
        '&:hover': {
            backgroundColor: '#080',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#090',
        },
        '&:focus': {},
    },
}));

export default function BasicTextFields({ handleSubmit }) {
    const [state, setState] = React.useState({
        firstname: '',
        lastname: '',
        street: '',
        city: '',
        postcode: '',
        email: '',
        phone: '',
        accepted: false,
    });

    const handleChange = prop => (event) => {
        setState({ ...state, [prop]: event.target.value });
    };

    const handleChangeCheck = () => {
        setState({ ...state, accepted: !state.accepted });
    };

    const classes = useStyles();

    const canSubmit = () => {
        const {
            firstname,
            lastname,
            street,
            city,
            postcode,
            email,
            phone,
            accepted,
        } = state;

        if (!accepted) {
            return false;
        }
        if (
            !firstname
      || !lastname
      || !street
      || !city
      || !postcode
      || !email
      || !phone
        ) return false;
        return true;
    };

    return (
        <FormControl
            component="fieldset"
            className={classes.root}
            id="form-piracy"
            autoComplete="off"
        >
            <FormLabel component="legend">Assign responsibility</FormLabel>
            <FormGroup className={classes.inputs}>
                <TextField
                    onChange={handleChange('firstname')}
                    name="firstname"
                    label="First name"
                    variant="outlined"
                    required
                />
                <TextField
                    onChange={handleChange('lastname')}
                    name="lastname"
                    label="Last name"
                    variant="outlined"
                    required
                />
                <TextField
                    onChange={handleChange('street')}
                    name="street"
                    label="Street address"
                    variant="outlined"
                    required
                />
                <TextField
                    onChange={handleChange('city')}
                    name="city"
                    label="City"
                    variant="outlined"
                    required
                />
                <TextField
                    onChange={handleChange('postcode')}
                    name="postcode"
                    label="Postcode / ZIP"
                    variant="outlined"
                    required
                />
                <TextField
                    onChange={handleChange('email')}
                    name="email"
                    label="Email"
                    variant="outlined"
                    required
                />
                <TextField
                    onChange={handleChange('phone')}
                    name="phone"
                    label="Phone"
                    variant="outlined"
                    required
                />

                <FormControlLabel
                    control={(
                        <Checkbox
                            checked={state.accepted}
                            onChange={handleChangeCheck}
                            name="accepted"
                        />
                    )}
                    label="I understand that if I share the content in this portal, I will be liable to civil and criminal prosecution."
                />

                <Button
                    color="primary"
                    style={{ width: '155px', padding: '0px' }}
                    className="buttonLgLight h-fit mt-4 md:mt-7"
                    onClick={() => {
                        if (canSubmit()) {
                            handleSubmit(state);
                        }
                    }}
                    disabled={!canSubmit()}
                >
                    Continue
                </Button>
            </FormGroup>
            {/* <FormHelperText>Small text at the bottom here</FormHelperText> */}
        </FormControl>
    );
}
