import React from 'react';
import accounting from 'accounting';
import getCognitoUserAttribute from '../../../../utils/getCognitoUserAttribute';


export default ({ user, selected, onClick }) => (
    <div
        style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '1.5rem 1rem',
            background: selected ? '#1882de' : '#F2F2F2',
            marginBottom: '0.7rem',
            cursor: 'pointer',
        }}
        onClick={onClick}
    >
        <div style={{
            fontWeight: 'bold',
            color: selected ? 'white' : 'black',
        }}
        >
            {`${getCognitoUserAttribute(user?.cognitoUser, 'given_name')} ${getCognitoUserAttribute(user?.cognitoUser, 'family_name')}`}
        </div>
        <div style={{
            fontWeight: 'bold',
            color: selected ? 'white' : 'black',
        }}
        >
            {`${user?.payments?.length} payments`}
        </div>
    </div>
);
