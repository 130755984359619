import React from 'react';
import './style.scss';
import Container from 'reactstrap/lib/Container';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';

const sections = [
    {
        available: true, sectionName: 'Before you start',
    },
    {
        available: true, sectionName: 'The platforms you can use',
    },
    {
        available: true, sectionName: 'The System',
    },
    {
        available: true, sectionName: 'Step 1: Post content & make the market aware of who you are',
    },
    {
        available: true, sectionName: 'Types of Posts',
    },
    {
        available: true, sectionName: 'Step 2: Engage & Connect With Prospects',
    },
    {
        available: true, sectionName: 'Step 3: Massage a conversation into a meeting',
    },
];

const title = "Profile Funnel"

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]}  className="plug-block">  
                        <h2 className="mb-4">{title}</h2>
                            <div className="plug-block-subheading">
                                Before you start:
                            </div>

                            <p>
                                Using a profile funnel is really just a way to use social media to find leads, warm them up & use content to get in-bound or at least for receptive prospects.
                            </p>

                            <p>
                                If I could have just one form of outreach, this would be it. That said, it’s a very slow starter and takes 3-6 months just to even warm up. After that point, it’ll bring in a nice steady stream of leads.
                            </p>

                            <p>
                                A profile funnel is something you really need to stay consistent with. You can’t slack on the content nor the messaging.
                            </p>
                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">
                                The platforms you can use
                            </div>

                            <p>
                                Facebook - This is definitely the gold standard in regards to profile funnels. It’s very easy to find ideal prospects in groups & soon enough you get high quality leads reaching out to you personally.


                            </p>

                            <p>
                                LinkedIn - There’s a behemoth module on this, so I won’t touch on it too much here. This platform has turned into a bit of a cesspool with all the automations, but it’s still powerful if you can build a niche personal brand on here.


                            </p>

                            <p>
                                Instagram - Definitely inferior to Facebook, but still a very powerful place to build a niche personal brand, connect with people, post content/client wins & go from there.

                            </p>

                            <p>Twitter - I honestly didn’t realise until recently what a gold mine Twitter is. Definitely a place where you can build a micro niche brand & bring in a bunch of leads.</p>

                            <p>
                                <span className="bold">Note:</span>
                                {' '}
                                I’ll be using Facebook as the exemplar, but these tactics apply to all platforms.
                            </p>
                        </div>

                        <div ref={refs[2]} className="plug-block">
                            <div className="plug-block-subheading">
                                The System

                            </div>

                            <ol>
                                <li>
                                    Post content & make the market aware of who you are.


                                </li>
                                <li>
                                    Engage & connect with prospects.


                                </li>
                                <li>
                                    Massage a conversation into a meeting.


                                </li>
                            </ol>
                        </div>

                        <div ref={refs[3]} className="plug-block">
                            <div className="plug-block-subheading">
                                Step 1: Post content & make the market aware of who you are.
                            </div>

                            <p>
                                The first step in this process is to optimise your Facebook profile. Here’s the steps in order to do this:
                            </p>

                            <ol>
                                <li>
                                    Remove any of your Facebook friends from high school, college, colleagues, etc so you have a clean Facebook account. Some of the old posts are valuable though as they show that it’s not an entirely brand new account.
                                </li>
                                <li>Remove any photos that aren’t flattering in the biz world. Drinking photos, partying photos, etc.</li>
                                <li>
                                    Make your bio explain exactly what you do & a CTA to book in a demo call, request an audit, whatever your angle is!
                                </li>
                                <li>
                                    Just use a regular banner, not anything too salesy as we don’t want to scare people away when they look at our profile. Once we get to 2,000 friends and have a stronger social presence, then it makes sense to add a banner.


                                </li>
                            </ol>

                            <p>
                                Once you’ve done this - your profile is clean, presentable and ready to start posting content on.
                            </p>

                            <p>
                                When it comes to posting content, the best thing you can do is have a posting schedule. I recommend you wait until you have 300 friends before you commit time to posting content.
                            </p>

                            <p>
                                Once you get to 300 friends, then do short client win posts, questions to your market & punchy posts. No point in committing to heavy posts at this stage.
                            </p>

                            <p>
                                From 1,000+ friends onwards, that’s when it really makes sense to post lengthier stuff and share what’s going on at the agency, long client testimonials, long value posts etc.
                            </p>

                            <p>0-300 Facebook Friends: Do a post every 1-2 weeks, keep adding 10 friends a day & accept as many a day as you like.</p>

                            <p>
                                300-1000 Facebook Friends: Post 3-5 times per week.


                            </p>

                            <p>
                                1,000+ Facebook Friends: Post every day.
                            </p>
                        </div>

                        <div ref={refs[4]} className="plug-block">
                            <div className="plug-block-subheading">
                                Types of Posts:
                            </div>

                            <p>
                                Rather than giving you an exact schedule of what to post on what day, I’ll give you some frameworks and you can mix & match them.
                            </p>

                            <p>
                                <span className="bold">
                                    Client win
                                </span>
                                {' '}
                                - Self explanatory, you post a win from one of your clients.
                            </p>

                            <p>
                                <span className="bold">Pole </span>
                                - You throw up a pole on your FB profile & ask a question.
                            </p>

                            <p>
                                <span className="bold">Value Post</span>
                                {' '}
                                - This is something long & substantial where you share some value with your audience.
                            </p>

                            <p>
                                <span className="bod">Commentary </span>
                                - Share your view on something, keep it punchy. Ask your audience what they think of it?
                            </p>

                            <p>
                                <span className="bold">Comment Stack</span>
                                {' '}
                                - This is where you create a free resource for your audience & tell people to comment a specific word if they want it. This builds what's known as a comment stack.
                            </p>

                            <p>
                                Now keep in mind that you can post multiple of the same type of post each week. I would definitely recommend that you check out some twitter accounts to see how people post punchy, engaging stuff. You want to make sure that it’s 80-90% value, humour, wins & easy to digest content, then 10-20% heavy hitting CTA content.
                            </p>

                            <p>
                                Last thing to remember is you should post some of this content in FB groups where your ideal prospect resides. Just make sure you don’t do client wins or strong call to action posts.
                            </p>
                        </div>

                        <div ref={refs[5]} className="plug-block">
                            <div className="plug-block-subheading">
                                Step 2: Engage & Connect With Prospects
                            </div>

                            <p>On Facebook you can add 10 people per day, but accept as many friend requests as you like. </p>

                            <p>In order to find ideal prospects & also have people start reaching out to you - the process is simple:</p>

                            <ol>
                                <li>
                                    Find 8-10 groups with your ideal customer. You might have to get creative. Bear in mind that some groups won’t let you in unless you’re a chiropractor, gym owner, etc - so getting in the groups will be the hardest part.
                                </li>
                                <li>
                                    Once you’re in - post content a few times per week. Make sure non of it is salesy otherwise the admins will likely kick you out.
                                </li>
                                <li>
                                    Spend 45-60m per day engaging with people in the groups.
                                </li>
                                <li>
                                    Pick out the best 8-10 prospects a day (the limit) to send Fb friend requests to.
                                </li>
                            </ol>
                        </div>

                        <div ref={refs[6]} className="plug-block">
                            <div className="plug-block-subheading">
                                Step 3: Massage a conversation into a meeting
                            </div>

                            <p>
                                This step is really where you need to use more critical thinking on your part. Once someone has accepted your friend request or you’ve accepted someone else’s friend request - put them in your CRM as a lead.
                            </p>

                            <p>I recommend you wait a few days until you reach out otherwise it’ll come off as too intense & once they’re in your CRM anyways, you won’t forget to reach out.</p>

                            <p>
                                The best way to reach out is to mention the commonality of the group, to ask them a question or if they’ve responded to your comment stack, use that.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
