import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import SummaryList from '../SummaryList';

import OutreachColumn from './components/OutreachColumn';

import withMonth from './withMonth';
import { SummaryContext } from '../../providers/SummaryProvider';

const Month = ({
    index,
    selected,
    year,
    month,
    currency,
}) => {
    const {
        ENABLED_OUTREACH_COLUMNS,
    } = useContext(SummaryContext);

    return index === selected && (
        <div className="text-white">
            <div
                className="info-text"
                style={{
                    padding: '0rem 0.5rem 1rem 0.5rem',
                }}
            >
                Track your outreach by updating any field and tapping the
                {' '}
                <i>Enter</i>
                {' '}
                key.
            </div>
            <Grid container spacing={2} justify="center">
                <Grid item container spacing={2} xs={12} sm={12} md={12} lg={9}>
                    {
                        ENABLED_OUTREACH_COLUMNS.map(({ displayLabel, dataLabel }, index) => (
                            <Grid item xs={12} sm={12} md={4}>
                                <OutreachColumn
                                    columnName={displayLabel}
                                    columnKey={dataLabel}
                                    outreachItemIndex={index} // index in data structure
                                    year={year}
                                    month={month}
                                    currency={currency}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={3}>
                    <SummaryList
                        currency={currency}
                        year={year}
                        month={month}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default withMonth(Month);
