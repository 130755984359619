import { PLUG_AND_PLAYS } from '../views/pages/resources/PlugAndPlay/index';

const plugAndPlay = PLUG_AND_PLAYS?.[0]?.plugAndPlays?.map(item => ({
    title: item?.name,
    body: '',
    link: item?.url,
    searchTerms: item?.searchTerms,
}));

export default plugAndPlay;
