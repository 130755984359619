import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';


const sections = [
    {
        available: true, sectionName: 'Things to keep in mind:',
    },
    {
        available: true, sectionName: 'Phase 1: Digging Phase',
    },
    {
        available: true, sectionName: 'Phase 2: Emotional Buy In',
    },
    {
        available: true, sectionName: 'Phase 3: Logic Phase',
    },
    {
        available: true, sectionName: 'Phase 4: Culture Fit',
    },
    {
        available: true, sectionName: 'Phase 5: Close',
    },
];

const title = "2CC Sales Script"

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]}  className="plug-block"> 
                            <div className="plug-block-subheading">
                                Things to keep in mind:
                            </div>

                            <p>Like the 1CC script, this is meant to be moulded and tweaked after every sales call. Now the high level framework of the 2cc is very similar to the 1cc but in a different format. </p>

                            <p>The first call is Phase 1: Digging Phase - you want to get as much info about their biz as possible, then if they’re a good fit explain why you think they’d be a good fit & why it’s worth a longer conversation. This call is extremely laid back.</p>

                            <p>
                                The second call you get straight into Phase 2: Emotional Buy In. Then you actually go back to phase 1 - reiterate what they’ve said then move onto…


                            </p>

                            <p>Phase 3: Logic Phase</p>
                            <p>
                                Phase 4: Culture Fit
                            </p>
                            <p>Phase 5: Close</p>

                            <ul>
                                <li>
                                    General chat for a few minutes
                                </li>
                                <li>
                                    Crack some jokes, lighten the mood
                                </li>
                                <li>
                                    Try to find some commonalities.
                                </li>
                                <li>
                                    Don’t make this part longer than 2-3 minutes


                                </li>
                            </ul>
                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <h2>Call 1</h2>

                            <div className="plug-block-subheading">Phase 1: Digging Phase</div>

                            <p className="bold">
                                1. So, let me give you some clarity on how my 15m demo calls go. First I’ll tell you a little bit about my agency operates, who we’ve worked with & what matters to us, then I’ll ask some questions so I can understand the nuances of your business, then we’ll see whether it’s worth pencilling in a longer format call.
                            </p>

                            <p>
                                Now whatever your angle to the meeting is here, obviously replace it for the demo call and tweak. For example if your angle is a free ad audit, then you’ll tweak this initial meeting script to accommodate for it.
                            </p>

                            <p className="bold">
                                2. Let me tell you a little bit about how we operate at my agency & what’s important to our ethos (list 2-4 things)
                            </p>

                            <p>
                                It’s important when using this demo call format that you actually lead in with this. They don’t know you that well, you haven’t build rapport yet, so it’s best if you start the conversation with this demo call.


                            </p>

                            <p className="bold">
                                3. Sophia, you took the time out of your busy schedule today to have this meeting with me. Why is that?


                            </p>

                            <p>
                                You’re pointing out the obvious here, that the prospect is indeed super busy yet decided to meet with you. This must be because they do have intent on working with you or at least are open to the idea. Remember… if they’re on the call with you, there is a medium to high level of interest!
                            </p>

                            <p className="bold">
                                4. What inspired you to start your business?
                            </p>

                            <p>
                                Very very few agencies actually ask this question. They want the main event without any foreplay. You gotta make them aware that you ACTUALLY care.
                            </p>

                            <p className="bold">
                                5. What sort of customer/client do you serve & at what price point.
                            </p>

                            <p>
                                This is genuine intel that we need to know in order to understand their model.
                            </p>

                            <p className="bold">
                                6. Awesome, I’m going to ask you some more specific questions now so I can understand where your business is at and where you think we can get it to in 12 months time. Does that sound good?
                            </p>

                            <p>
                                You’re about to start asking some questions that make a lot of business owners out there VERY uncomfortable. That’s why it’s best to get compliance from them first.
                            </p>

                            <p className="bold">7. Okay, so what’s the current process of taking a stranger and turning them into a customer?</p>

                            <p>Once again, this gives you an idea of the main channels/traffic sources they use to drive the sales they bring in.</p>

                            <p className="bold">8. How much revenue is the business doing per month?</p>

                            <p>Pretty self explanatory. They might have some resistance to telling you. If they do, compare yourself to a tax advisor - A service provider that will make them money, but needs transparency and metrics in order to do so.</p>

                            <p className="bold">9. How much revenue would you like to be making in 12 months?</p>

                            <p>You’re future pacing and starting to assign a real number to where they could be in 12 months from now. This is very powerful. This also gives you context as to whether they’re being naive or maybe too pessimistic.</p>

                            <p className="bold">10. Have you ever done paid advertising before?</p>

                            <p>This is super important to know so you can get a feel for their past experience with paid advertising. Obviously if you’re offering a different service, slot that in instead.</p>

                            <p className="bold">
                                11. Have you ever worked with an agency before?
                            </p>

                            <p>You want to watch out for this question. Sometimes it can give you redflags. If they’ve worked with an agency before and talk shit about them rather than just some constructive feedback - then you can get a feel for whether they’re a nightmare client or not.</p>

                            <p className="bold">
                                12. Well, after our brief discussion today, I have a high level of certainty we can get you results & I think it’s worth a longer 45m discussion. Let me open up my Calendar & let’s pencil in a time.
                            </p>

                            <p>
                                This is where you open up calendly, set their time zone, punch in their details for them so they get the SMS & email reminders.
                            </p>
                        </div>

                        <div className="plug-block">
                            <h2>Call 2</h2>

                            <div className="plug-block-subheading" ref={refs[2]}>Phase 2: Emotional Buy In</div>

                            <p className="bold">1. Now before we get into this call and talk about scaling your business, I think it’s always important to attach meaning behind action. You’re quite clearly a very successful business owner so why do you even want to go from ‘x’ to ‘x’? And don’t say ‘because I want to grow my business or make money’ - what’s the reason behind it?</p>

                            <p>This is where we move into the emotional buy in section. This section is brief and we almost have to be a little sneaky in the way that we sneak this into conversation. I’ve found business owners don’t respond well to emotional questions so we start by brushing their egos and then we ask the question we want an answer to.</p>

                            <p className="bold">2. Instructions: KEEP DIGGING</p>

                            <p>I can’t give you an exact script because every client is different, but you want to ask 1-3 follow up questions relating to what they just told you about why they want to grow their business. Ask ‘why’ or ‘what would that mean to you?’ and the KEY is don’t make it look like it’s part of the sales process, you’re just genuinely curious yourself about it. </p>

                            <div className="plug-block-subheading">Phase 1: Digging Phase (Reiterate)</div>

                            <p className="bold">So, let me just summarise what we talked about in the last call… (Q3-Q11 re-state)</p>

                            <p>
                                This is why it’s so important to write stuff down, because now you’ll be re-stating what they said about why they started their biz, how much revenue they’re going, where they want to get to, etc
                            </p>

                            <div className="plug-block-subheading" ref={refs[3]}>Phase 3: Logic Phase</div>

                            <p className="bold">
                                1. So as we already established, you’re trying to get from ‘x’ to ‘x’ - would you agree that marketing & getting more leads to your business is how we’re going to make that happen?
                            </p>

                            <p>
                                The business owner has to deal with many different aspects of the business, so once again we want to make it a no-brainer that marketing is the way to do that.
                            </p>

                            <p className="bold">2. Great, so here are your options. You could run a billboard ad, zero feedback or sign of ROI. You could run a TV ad, zero feedback or sign of ROI. You could hand out flyers, zero feedback or sign of ROI. You could do any of those, or you could use Facebook Ads where you know exactly how much is spent, how many leads are brought from that and ultimately how much money is made from it. Would you agree that’s the better option?</p>

                            <p>
                                Once again, you want to polarise all other marketing mediums. If you’re offering a different service then obviously swap it out for paid traffic.
                            </p>

                            <p className="bold">
                                3. So let me explain what we do for clients… [Fill in]
                            </p>

                            <p>
                                This is something I can’t script for you - you’ll need to fill it in yourself and rehearse it hundreds of times in your mind so the execution is clean. Here you explain the result you get for clients & from a high level how you get those results.
                            </p>

                            <p className="bold">
                                4. After everything you’ve told me - let me tell you what the worst case scenario looks like.
                            </p>

                            <p>
                                After everything they’ve told you - you should have an idea for what the worst case scenario looks like. You’re not trying to impress them, you’re trying to be honest here.
                            </p>

                            <p className="bold">
                                5. Now that we’ve covered what the worst case looks like, let me show you what a good scenario looks like. Here’s what we accomplished for our client...
                            </p>

                            <p>
                                Now you show them a scenario that’s slightly above what you think is possible. You can slot in a client case study to back it up. If you don’t have this, cut it out & simply explain with logic how you arrived at that conclusion.
                            </p>

                            <div className="plug-block-subheading" ref={refs[4]}>
                                Phase 4: Culture Fit
                            </div>

                            <p className="bold">
                                1. Now let’s change tune real quick because on the results front - I know it’s a green light. Let’s talk about culture fit.
                            </p>

                            <p>This is where you change the pace of the conversation again & you actually get them to qualify themselves.</p>

                            <p className="bold">
                                2. Let me tell you again about how we operate at my agency & what’s important to our ethos (list 2-4 things)
                            </p>

                            <p>
                                You will be repeating yourself from the first call here, but it’s still super important to run through.
                            </p>

                            <p className="bold">
                                3. My question to you: Why do you think you would be a good fit for our agency culture?
                            </p>

                            <p>This is where you get the client to qualify without sounding like an asshole.</p>

                            <div className="plug-block-subheading" ref={refs[5]}>
                                Phase 5: Close
                            </div>

                            <p className="bold">
                                1. That’s great to hear, I agree we’d be a good culture fit too. So would you like our help to scale you to ‘x’ per month?
                            </p>

                            <p>
                                Now you’re getting compliance from them to pitch your price, but you’re also future pacing the desired result they told you.
                            </p>

                            <p className="bold">
                                2. Great - so here’s how our pricing works (State your price based on pricing modules)
                            </p>

                            <p>
                                This is where you take all the knowledge you’ve received from the pricing modules and you decide on how much to charge them. This is why pen & paper is so important while you’re on calls because you can make notes of how much to charge. There is no correct answer, only you will know.
                            </p>

                            <p className="bold">3. Logistics & Objections</p>

                            <p>Either the client is going to say ‘great’ or ‘let’s do it’ in which case just skip to the next point. If they have more logistical questions or some objections, then use the objection handling PNP (Covered in the next module).</p>

                            <p className="bold">4. Get Payment - ‘Great, so the next step is to settle the invoice so we can get started running your ads & get started making you money ASAP. Can you give me your business name, registered address & VAT if applicable.’ </p>

                            <p>
                                Once you’ve gotten the green light from the client and they want to move forward, you secure payment IMMEDIATELY. I cannot stress this enough, if you leave that meeting whether it’s physical or remote and they haven’t paid the invoice… your chance of signing them has gone down by 70-90%.
                            </p>

                            <p>Some people like to actually take their client’s card details and then bill them. I don’t like to do this… instead I’d rather just send them the invoice and they can pay with card there and then on the spot. Point is, they pay the invoice immediately and they type in their own card details. Sometimes on stripe you’ll get a ‘do not honor’ which means their bank declined the payment. You tell them to call their bank and just mute themselves.</p>

                            <p className="bold">
                                5. On-Board & End
                            </p>

                            <p>
                                Once payment is made, you can take a sigh of relief. They’re now OFFICIALLY a client. Send them the on-boarding link and explain that in the space of 60-90 minutes they’ll be entirely onboarded. Then just wait for a notification that they booked in their strategy session and you’re done!
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
