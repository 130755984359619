import gql from 'graphql-tag';

export default gql`
    mutation ($vendorUuid: ID!,
            $clientCustomerUuid: ID,
            $clientCustomerPayment: CreateUpdateClientCustomerPaymentInput!){
        createUpdateClientCustomerPayment(
            vendorUuid: $vendorUuid,
            clientCustomerUuid: $clientCustomerUuid,
            clientCustomerPayment: $clientCustomerPayment) {
            errors
            data {
                id
                createdTimestamp
                updatedTimestamp
                paymentTitle
                paymentType
                totalValue
                totalCurrency
                metadata
            }
        }
    }

`;
