import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import * as dates from 'react-big-calendar/lib/utils/dates';
import { navigate } from 'react-big-calendar/lib/utils/constants';
import Grid from '@material-ui/core/Grid';
import './styles.css';

const createCalendar = (currentDate) => {
    if (!currentDate) {
        currentDate = moment();
    } else {
        currentDate = moment(currentDate);
    }

    const first = currentDate.clone().startOf('month');
    const last = currentDate.clone().endOf('month');
    const weeksCount = Math.ceil((first.day() + last.date()) / 7);
    const calendar = Object.assign([], { currentDate, first, last });

    for (let weekNumber = 0; weekNumber < weeksCount; weekNumber++) {
        const week = [];
        calendar.push(week);
        calendar.year = currentDate.year();
        calendar.month = currentDate.month();

        for (let day = 7 * weekNumber; day < 7 * (weekNumber + 1); day++) {
            const date = currentDate.clone().set('date', day + 1 - first.day());
            date.calendar = calendar;
            week.push(date);
        }
    }

    return calendar;
};

const CalendarDate = ({
    events, dateToRender, dateOfMonth, onClick, selectedDates,
}) => {
    const today = dateToRender.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
        ? 'today'
        : '';

    if (dateToRender.month() < dateOfMonth.month()) {
        return (
            <button disabled className="date prev-month">
                {dateToRender.date()}
            </button>
        );
    }

    if (dateToRender.month() > dateOfMonth.month()) {
        return (
            <button disabled className="date next-month">
                {dateToRender.date()}
            </button>
        );
    }
    let style = {};
    const eventOnDay = events
        .find(({ startDateTime, endDateTime }) => dateToRender.isBetween(moment(startDateTime), moment(endDateTime), undefined, '[]'));

    if (eventOnDay) {
        style = {
            textAlign: 'center',
            padding: 0,
            margin: 0,
            background: eventOnDay.colour,
            borderRadius: 0,
        };
    }

    const multiDateSelected = !!selectedDates[0]
        && !!selectedDates[1]
        && dateToRender.isBetween(moment(selectedDates[0]), moment(selectedDates[1]), undefined, '[]');

    if (
        multiDateSelected || dateToRender.isSame(selectedDates[0], 'day')
    ) {
        style = { ...style, background: '#434343', borderRadius: 0 };
    }

    return (
        <button
            className={`date in-month ${today}`}
            onClick={() => onClick(dateToRender)}
            style={style}
        >
            {dateToRender.date()}
        </button>
    );
};

const Calendar = ({
    date, onSelectSlot, events, setSelectedDates, selectedDates,
}) => {
    const [calendar, setCalendar] = useState(undefined);
    useEffect(() => {
        setCalendar(createCalendar(date));
    }, []);


    if (!calendar) return null;

    // componentDidUpdate(prevProps) {
    //     if (this.props.date !== prevProps.date) {
    //         this.setState({ calendar: createCalendar(this.props.date) });
    //     }
    // }

    return (
        <div className="month">
            <div className="month-name">
                {calendar.currentDate.format('MMMM').toUpperCase()}
            </div>
            <div className="text-white">
                {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((day, index) => (
                    <span key={index} className="day">
                        {day}
                    </span>
                ))}
            </div>
            {calendar.map((week, index) => (
                <div key={index}>
                    {week.map(date => (
                        <CalendarDate
                            key={date.date()}
                            dateToRender={date}
                            dateOfMonth={calendar.currentDate}
                            selectedDates={selectedDates}
                            onClick={(date) => {
                                const firstDate = selectedDates[0];
                                const secondDate = selectedDates[1];

                                if ((!!firstDate && !secondDate)) {
                                    setSelectedDates([firstDate, date].sort((a, b) => a.diff(b)));
                                } else if ((!!firstDate && !!secondDate) || !firstDate) {
                                    setSelectedDates([date, null]);
                                }
                            }}
                            events={events}
                        />
                    ))}
                </div>
            ))}
        </div>
    );
};


const YearView = ({
    date, onSelectSlot, events, ...props
}) => {
    const year = moment(date).year();
    const months = [];
    const firstMonth = dates.startOf(date, 'year');

    const filteredEvents = events.filter(({ entryType }) => entryType === 'event');

    const [selectedDates, setSelectedDates] = useState([null, null]);

    useEffect(() => {
        if (!!selectedDates[0] && !!selectedDates[1]) {
            onSelectSlot({
                startDateTime: selectedDates[0],
                endDateTime: selectedDates[1],
                allDay: false,
                deselect: () => {
                    setSelectedDates([null, null]);
                },
            });
        }
    }, [selectedDates]);

    for (let i = 0; i < 12; i++) {
        months.push(
            <Calendar
                onSelectSlot={onSelectSlot}
                key={`${year} - ${i + 1}`}
                date={dates.add(firstMonth, i, 'month')}
                events={filteredEvents}
                setSelectedDates={setSelectedDates}
                selectedDates={selectedDates}
            />,
        );
    }

    return (
        <>
            <div
                className="info-text"
                style={{
                    margin: '1rem 0 1rem 2.5rem',
                }}
            >
                Click on two dates to select a range of dates for your yearly plans.
            </div>
            <Grid container justify="center" className="flex-col sm:flex-row">
                {months.map((month, i) => (
                    <Grid item key={`${year} - month${i}`} xs={6} sm={4} md={3} lg={2} style={{color:"black"}} >
                        { month }
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

YearView.range = date => [dates.startOf(date, 'year')];

YearView.navigate = (date, action) => {
    switch (action) {
    case navigate.PREVIOUS:
        return dates.add(date, -1, 'year');

    case navigate.NEXT:
        return dates.add(date, 1, 'year');

    default:
        return date;
    }
};

YearView.title = (date, { localizer }) => localizer.format(date, 'yearHeaderFormat');

export default YearView;
