const styles = () => ({
    paper: {
        width: '100%',
    },
    header: {
        padding: '10 10 20 0',
        display: 'flex',
        background: 'linear-gradient(35deg, #1882de 0, #1830de 100%)',
    },
    todoTitle: {
        flexGrow: 1,
        color: '#fff',
        fontWeight: 700,
        margin: '0.5rem 0',
        fontSize: 22,
    },
    buttonGroup: {
        backgroundColor: 'transparent',
        color: '#000',
        fontSize: 10,
        marginBottom: 10,
        border: 'solid 1px white',
    },
    button: {
        backgroundColor: 'transparent',
        color: '#fff',
        fontSize: 10,
        border: 0,
        '&&:hover': {
            transform: 'none',
            backgroundColor: 'rgba(255,255,255,0.51)',
            color: 'black',
        },
        '&&:focus': {
            outline: 0,
        },
    },
    buttonSelected: {
        backgroundColor: '#fff',
        color: '#000',
        fontSize: 10,
        border: 0,
        '&&:hover': {
            transform: 'none',
            backgroundColor: 'rgba(255,255,255,0.51)',
            color: 'black',
        },
        '&&:focus': {
            outline: 0,
        },
    },
});

export default styles;
