/* eslint-disable import/no-unresolved */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable global-require */
import React, { useEffect, useState } from 'react';
import { useAuth } from '@genflow/web-auth';
import { Redirect, useHistory } from 'react-router';
import { Auth } from 'aws-amplify';
import { DateUtils } from '@aws-amplify/core';
import { MailIcon } from '@heroicons/react/outline';
import { NEEDS_PASSWORD } from '../../../env';
import useStickyState from '../../../utils/useStickyState';
import LoadingButton from '../../../components/LoadingButton';
import { loginPasswordless, login } from '../../../utils/auth';
import CodeInput from './components/CodeInput';

let countdownTrigger;

// console.log("NEEDS_PASSWORD", NEEDS_PASSWORD);

export default () => {
    const { useLogin, useIsLoggedIn } = useAuth();
    useLogin(true);
    const isLoggedIn = useIsLoggedIn(true);
    // const [loggingIn, setLoggingIn] = useState(false);
    const [rememberMe, setRememberMe] = useStickyState(false, 'loginRememberMe');
    const [email, setEmail] = useStickyState(null, 'loginEmail', !rememberMe);
    const [password, setPassword] = useState(null);
    const [validationMessage, setValidationMessage] = useState(null);
    const [loggingIn, setLoggingIn] = useState(false);

    const [unverifiedUser, setUnverifiedUser] = useState(null);
    const [codeValue, setCodeValue] = useState();

    const [countdownSeconds, setCountdownSeconds] = useState(0);
    const [codeResent, setCodeResent] = useState(false);
    const [borderColor, setBorderColor] = useState('#434343');

    const history = useHistory();

    useEffect(() => {
        if (countdownTrigger) {
            clearTimeout(countdownTrigger);
            countdownTrigger = null;
        }
        if (!unverifiedUser) return; // avoid starting the countdown at startup
        if (countdownSeconds < 1) return;

        countdownTrigger = setTimeout(() => {
            setCountdownSeconds(countdownSeconds - 1);
        }, 1000);
    }, [countdownSeconds]);

    if (isLoggedIn === null) return null;

    if (isLoggedIn) {
        return <Redirect to="/" />;
    }

    const handleLogin = async () => {
        if (NEEDS_PASSWORD) {
            await login({
                email, password,
            });
            return;
        }

        if (!!unverifiedUser && !!codeValue) {
            setLoggingIn(true);
            try {
                await Auth.sendCustomChallengeAnswer(unverifiedUser, codeValue);
                // This will throw an error if the user is not yet authenticated:
                const user = await Auth.currentSession();
                console.log('clockDrift', user.clockDrift);
                DateUtils.setClockOffset(DateUtils.setClockOffset(user.clockDrift * 1000 * -1));
                history.push('/home');
            } catch (error) {
                console.log(error);
                if (error.code === 'NotAuthorizedException') {
                    setValidationMessage(
                        'Your code seems to have expired, please try again.',
                    );
                    setUnverifiedUser(null);
                } else {
                    setValidationMessage('Your code seems to be incorrect!');
                    console.warn(error);
                }
            }
        } else {
            setLoggingIn(true);
            const passwordLoginResponse = await loginPasswordless(email).catch(
                (error) => {
                    setLoggingIn(false);
                    setValidationMessage(error);
                },
            );

            setUnverifiedUser(passwordLoginResponse);
            setCountdownSeconds(45); // seconds to resend the code
        }

        setLoggingIn(false);
    };

    const clearValidationMessage = () => {
        if (validationMessage) setValidationMessage(null);
    };

    const resendEmail = () => {
        handleLogin();
        setCodeResent(true);
    };

    const canLogin = unverifiedUser ? codeValue?.length === 6 : !!email;

    return (

        <div className="flex flex-col">
            <div className="mx-auto p-16">
                <img
                    alt="genflow"
                    src="../Logo.svg"
                />
            </div>
            <h2 className="text-white my-7 text-center">Sign in with your credentials</h2>

            <div style={{ width: '378px', height: '268px' }} className="mx-auto grey800Bg text-white">
                <div className="flex flex-col p-4" role="form">
                    <div className="flex flex-row items-center inputBox w-full mt-4" style={{ borderBottomColor: borderColor }}>
                        <MailIcon className="h-5 w-5 mb-2 mr-4" style={{ color: borderColor }} aria-hidden="true" />
                        <input
                            placeholder="Email"
                            type="email"
                            className="border border-transparent bg-transparent pb-2 body3 w-full outlineRemoved"
                            value={email}
                            onChange={(newEmail) => {
                                clearValidationMessage();
                                setEmail(newEmail.target.value);
                            }}
                            onClick={() => {
                                setBorderColor('white');
                            }}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter' && canLogin) {
                                    handleLogin();
                                }
                            }}
                        />
                        {
                            NEEDS_PASSWORD
                            && (
                                <input
                                    placeholder="Password"
                                    type="password"
                                    className="border border-transparent bg-transparent pb-2 body3 w-full outlineRemoved"
                                    value={password}
                                    onChange={(newPass) => {
                                        clearValidationMessage();
                                        setPassword(newPass.target.value);
                                    }}
                                    onClick={() => {
                                        setBorderColor('white');
                                    }}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter' && canLogin) {
                                            handleLogin();
                                        }
                                    }}
                                />
                            )
                        }
                    </div>
                    {!!unverifiedUser && (
                        <>
                            <CodeInput onChange={setCodeValue} />

                            {
                                !codeResent && (
                                    <div
                                        className={`resend-email ${countdownSeconds < 1 ? 'enabled' : ''}`}
                                        onClick={() => countdownSeconds < 1 && resendEmail()}
                                    >
                                        {
                                            countdownSeconds > 0
                                                ? ` Resend email (${countdownSeconds}s)`
                                                : 'Resend email'
                                        }
                                    </div>
                                )
                            }


                        </>
                    )}

                    <div
                        className="custom-control custom-control-alternative custom-checkbox remember-box flex flex-row items-center"
                    >
                        <input
                            checked={rememberMe}
                            className="custom-control-input w-4 h-4"
                            id=" customCheckLogin"
                            type="checkbox"
                            onChange={(e) => {
                                setRememberMe(Boolean(e.target.checked));
                            }}
                        />
                        <label
                            className="custom-control-label ml-3 text-white"
                            htmlFor=" customCheckLogin"
                        >
                            <span>Remember me</span>
                        </label>
                    </div>
                    {!!validationMessage && (
                        <div className="text-center text-red mt-3 mb-3 small">
                            {validationMessage}
                        </div>
                    )}


                    <div className="text-center">
                        <LoadingButton
                            className="createButton body1 mt-8"
                            loading={loggingIn}
                            disabled={!canLogin}
                            color="primary"
                            type="button"
                            onClick={handleLogin}
                        >
                            Sign in
                        </LoadingButton>
                    </div>
                </div>
            </div>
        </div>
    );
};
