import gql from 'graphql-tag';

export default gql`
    query ($vendorUuid: ID!){
        getVendorLeaderboard(vendorUuid: $vendorUuid) {
            data {
                vendorUuid
                leaderboardUuid
                createdTimestamp
                updatedTimestamp
                leaderboard {
                    position
                    totalCredits
                    totalDebits
                    cognitoUser {
                        Username
                        Attributes {
                            Name
                            Value
                        }
                    }
                }
                yourPosition {
                    position
                    totalCredits
                    totalDebits
                    cognitoUser {
                        Username
                        Attributes {
                            Name
                            Value
                        }
                    }
                }
            }
        }
    }
`;
