const styles = () => ({
    container: {
        display: 'flex',
        color: "white", 

    },
    spacer: {
        flexGrow: 1,
    },
    currencySelector: {
        marginRight: 10,
        color: 'white',
        paddingLeft: '3px',
        paddingRight:'9px'
    }
});

export default styles;
