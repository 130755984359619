/* eslint-disable max-len */
import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

import image1 from '../../../../../assets/img/plugandplay/onboarding-funnel/1.png';
import image2 from '../../../../../assets/img/plugandplay/onboarding-funnel/2.png';
import image3 from '../../../../../assets/img/plugandplay/onboarding-funnel/3.png';
import image4 from '../../../../../assets/img/plugandplay/onboarding-funnel/4.png';
import image5 from '../../../../../assets/img/plugandplay/onboarding-funnel/5.png';

const sections = [
    {
        available: true,
        sectionName: 'Step 1',
    },
    {
        available: true,
        sectionName: 'Step 2',
    },
    {
        available: true,
        sectionName: 'Step 3',
    },
    {
        available: true,
        sectionName: 'Step 4',
    },
    {
        available: true,
        sectionName: 'Step 5',
    },
    {
        available: true,
        sectionName: 'Step 6',
    },
];

const title = 'Onboarding Funnel';

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div  className="plug-block">  
                        <h2 className="mb-4">{title}</h2>
                            <p>
                                In order for you to properly onboard clients and give them the best experience, we
                                have created onboarding funnel templates that will allow you to collect all the
                                information you need in order to start working with any new client.
                            </p>

                            <p>
                                Not only that, but we have onboarding funnels for each website template that we
                                provide, this way your design will be consistent throughout the whole customer
                                journey.
                            </p>

                            <p>Here’s how to set it up:</p>
                        </div>

                        <div ref={refs[0]} className="plug-block">
                            <div className="plug-block-subheading">
                                Step 1: Duplicate the template for the onboarding funnel that matches your website
                            </div>

                            <p>You can get the link for all the templates here:</p>

                            <p>
                                <a className="break-words" href="https://docs.google.com/spreadsheets/d/1qMTUF2HlYEqpLwPFnEC7hPlD4xhC9SCTe_lJdE60nwM/edit?usp=sharing ">
                                    https://docs.google.com/spreadsheets/d/1qMTUF2HlYEqpLwPFnEC7hPlD4xhC9SCTe_lJdE60nwM/edit?usp=sharing
                                </a>
                            </p>

                            <p>
                                In order to duplicate the onboarding funnel, all you have to do is access the share
                                link for the funnel that you want to copy. It’s the link on the last column of the
                                spreadsheet.
                            </p>

                            <p>You’ll then get redirected to a page like this one:</p>
                            <img src={image1} alt="" />
                            <p>
                                There, you just have to log into your Clickfunnels account and then the funnel will
                                be cloned inside your account.
                            </p>
                        </div>

                        <div className="plug-block">
                            <div ref={refs[1]} className="plug-block-subheading">
                                Step 2: Editing the funnel settings
                            </div>

                            <p>
                                Open the newly cloned funnel and click on settings at the top right corner. There,
                                just make sure to change the domain to match the domain you’re using in your
                                website, as well as changing the favicon to your own and renaming the path to
                                something more aesthetically pleasing than the default setting.
                            </p>

                            <p>
                                I’d suggest going with “onboarding”, as in the screenshot below. This will set the
                                link to your onboarding funnel to yourdomain.com/onboarding
                            </p>

                            <img src={image2} alt="" />
                        </div>

                        <div className="plug-block">
                            <div ref={refs[2]} className="plug-block-subheading">
                                Step 3: Edit the funnel steps with your own content
                            </div>

                            <p>
                                Now, the major work that needs to be done inside the funnel is going through each
                                one of the 6 steps (you can add more, or delete, if needed) and replacing the videos
                                with your videos.
                            </p>

                            <p>
                                The videos that are inside there are the videos that we actually use for IAG Media,
                                so you can take a look at each one and model them to create your own version.
                            </p>

                            <p>
                                Don’t need to get too sophisticated, tho. I recommend simply recording them using
                                Loom with a webcam + screen recording and that’s it. That shouldn’t take longer than
                                2 hours.
                            </p>

                            <p>Remember: progress, not perfection.</p>

                            <p>
                                Then, you also have to change the particular information in each page, which are:
                            </p>

                            <p>
                                - The Business Manager ID
                                <br />
                                - The Intake Form
                                <br />
                                - The Strategy Session Call Booking
                            </p>
                        </div>

                        <div className="plug-block">
                            <div ref={refs[3]} className="plug-block-subheading">
                                Step 4: Changing the Business Manager ID
                            </div>

                            <p>
                                Changing the Business Manager ID is simple enough. You just have to copy yours at
                                {' '}
                                <a className="break-words"  href="https://business.facebook.com/settings/info">
                                    https://business.facebook.com/settings/info
                                </a>
                                {' '}
                                (make sure you have the right Bussiness Manager selected).
                            </p>

                            <p>
                                Of course this one only applies in case you are offering paid traffic services.
                                Alternatively, you may need to ask something different in this step. It could be
                                access to their Snapchat ad account, Google Ad account, website logins, funnel
                                builder logins - basically whatever you use to do your job.
                            </p>
                        </div>

                        <div className="plug-block">
                            <div ref={refs[4]} className="plug-block-subheading">
                                Step 5: Changing the Intake Form
                            </div>
                            <p>
                                The intake form is where you ask the client for all the relevant information needed
                                for you to work with them. In our case, we ask for:
                            </p>

                            <p>
                                - Company name
                                {' '}
                                <br />
                                - Login credentials for their website, funnel builder, CRM, email autoresponder
                                <br />
                                - A Google Drive folder with all the brand & asset files (logos, pictures for ads,
                                banners, etc)
                                <br />
                                - Links to all their social media pages
                                <br />
                                - Customer avatar questions
                                <br />
                                <ul>
                                    <li>
                                        What does your ideal customer look like? What do they act like, what do they
                                        spend their money on, what do they get up to in their free time... the more
                                        detailed you are, the better our targeting will be!
                                        {' '}
                                    </li>
                                    <li>
                                        What does your ideal customer NOT look like? What are the characteristics that
                                        make them not a good fit for you... be as detailed as you can
                                    </li>
                                    <li>
                                        If you look at your existing customers... what is the common traits between
                                        them? Are there patterns you've noticed?
                                    </li>
                                </ul>
                            </p>

                            <p>You can either set this up on Typeform (paid) or Google Forms (free).</p>

                            <p>
                                You can duplicate our typeform template here:
                                <a className="break-words" href="https://imangadzhi.typeform.com/to/EbZPxa">
                                    https://imangadzhi.typeform.com/to/EbZPxa
                                </a>
                            </p>

                            <p>
                                You can duplicate our Google Form template here:
                                <a className="break-words" href="https://docs.google.com/forms/d/e/1FAIpQLSdZCyOOmFry2nRDe66bjNieYUAp90MxfAhgOGqxW6FPhJDGqQ/viewform?usp=sf_link">
                                    https://docs.google.com/forms/d/e/1FAIpQLSdZCyOOmFry2nRDe66bjNieYUAp90MxfAhgOGqxW6FPhJDGqQ/viewform?usp=sf_link
                                </a>
                            </p>
                        </div>
                        <div className="plug-block">
                            <div ref={refs[5]} className="plug-block-subheading">
                                Step 6: Changing the Calendly scheduler
                            </div>

                            <p>
                                You have already set up your Calendly account earlier as though on the Website Plug
                                N Play.
                            </p>

                            <p>
                                All you need to do now is grab the exact same embed code that you’ve used for your
                                website, and paste it on the onboarding funnel.
                            </p>

                            <p>I’ll show you below how to do that in case you don’t remember.</p>

                            <p>
                                In order to copy the Calendly embed code, just log into your Calendly account and
                                copy the link for the event you created. You can do so by clicking on “Copy link” on
                                the specific event.
                            </p>

                            <img src={image3} alt="" />

                            <p>
                                Then, go back to Clickfunnels. inside the Onboarding Funnel, click on the “Strategy
                                Session” funnel step, and click “edit page”.
                            </p>

                            <img src={image4} alt="" />

                            <p>
                                On the editing view, the calendly embed widget will show up as “Custom Javascript /
                                HTML”.
                            </p>

                            <p>
                                Click on it, then click on “Open Code Editor” on the window that pops up. Now, you
                                just have to replace the link after the “URL =” part of the code, with the link you
                                copied from Calendly.
                            </p>

                            <img src={image5} alt="" />

                            <p>
                                Make sure you keep the quotation marks (“”). With that done, just exit the code
                                editor window by clicking on the X and then save your page by clicking on “Save” at
                                the top right corner.
                            </p>

                            <p>
                                And that’s it!
                                {' '}
                                <br />
                                Your onboarding funnel is now live and ready to start onboarding clients!
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
