import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { Link } from 'react-router-dom';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        available: true, sectionName: 'Before you start',
    },
    {
        available: true, sectionName: 'Creating your account',
    },
    {
        available: true, sectionName: 'Creating a campaign',
    },
    {
        available: true, sectionName: 'Inviting New Connections',
    },
    {
        available: true, sectionName: 'Messaging 1st Connections',
    },

];

const title = "LinkedIn Automation"

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                // selectedSection={selectedSection}
                                // setSelectedSection={onSelectSection}
                                // filter={filter}
                                // selectFilter={selectFilter}
                                // subFilter={subFilter}
                                // selectSubFilter={selectSubFilter}

                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]} className="plug-block">
                            <div className="plug-block-subheading">
                                Before you start:

                            </div>

                            <ul>
                                <li>
                                    <Link to="/resources/plug-and-play/creating-your-linkedin-profile">An optimised LinkedIn account</Link>

                                </li>
                                <li>
                                    <Link to="/resources/plug-and-play/using-sales-navigator">LinkedIn Sales Navigator</Link>
                                    {' '}
                                </li>
                            </ul>

                            <p>Just like with LemList for email outreach, you can automate elements of your outreach using automation software designed for LinkedIn. </p>

                            <p>
                                Although there are multiple softwares, this Plug & Play will help you set up an account with We-Connect.
                            </p>
                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">
                                Creating your account
                            </div>


                            <p>We-Connect offers a 14-day free trial. </p>

                            <p>
                                <a href="https://app.we-connect.io/signup" target="_blank" rel="noopener noreferrer">Click here to create your account</a>
                                {' '}
                                and
                                {' '}
                                <strong>make sure you use the same email and password that you used to login to LinkedIn.</strong>
                            </p>

                            <p>
                                You will be greeted with this:
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/linkedin-automation-1-ef332c9b-jpg.jpg" alt="" style={{ marginBottom: '1rem' }} />

                            <p>
                                You will then be asked to input a PIN that has been sent to the email that you used to login to LinkedIn.
                            </p>

                            <p>
                                Once you have done this, you will have to wait for a short amount of time as We-Connect and LinkedIn sync your data.
                            </p>

                        </div>
                        <div ref={refs[2]} className="plug-block">
                            <div className="plug-block-subheading">
                                Creating a campaign
                            </div>

                            <p>
                                Whilst We-Connect offers multiple campaign types, we will focus on the two most useful:
                            </p>

                            <ul>
                                <li>Invite New Connections</li>
                                <li>
                                    Message 1st Connections
                                </li>
                            </ul>

                            <p>
                                Prior to creating a campaign for either objective, you need to create a list for We-Connect. Note: these are separate and distinct to account or lead lists created on LinkedIn.
                            </p>

                            <p>
                                To create a list, go to the
                                {' '}
                                <a href="https://app.we-connect.io/saved_lists" target="_blank" rel="noopener noreferrer">Saved Lists</a>
                                {' '}
                                section of We-Connect.
                            </p>

                            <p>
                                Click ‘Add List’.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/linkedin-automation-2-cc290027-1-jpg.jpg" alt="" style={{ marginBottom: '1rem', width: '50%' }} />

                            <p>
                                Now, go back to your Sales Navigator and to your
                                {' '}
                                <a href="https://www.linkedin.com/sales/search/saved-searches/people" target="_blank" rel="noopener noreferrer">saved searches</a>
                                .
                            </p>

                            <p>
                                Click on a search you have saved, and copy the URL.
                            </p>
                            <img src="https://img.genflow.com/gya/plugandplays/linkedin-automation-3-c5dc9d85-jpg.jpg" alt="" style={{ marginBottom: '1rem' }} />

                            <p>
                                Copy the URL into ‘Search by URL’.
                            </p>


                            <p>
                                If you are using the campaign objective ‘Invite New Connections’, click ‘2nd & 3rd+ Connections’ and if you are using the ‘Message 1st  Connections’, click ‘1st Connections’ and then click ‘Search’.
                            </p>

                            <p>
                                As results start to appear, click ‘Save’ and name your list according to your naming convention.
                            </p>

                            <p>
                                You will be given this banner:
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/linkedin-automation-4-e0e50ac4-jpg.jpg" alt="" style={{ marginBottom: '1rem' }} />

                            <p>
                                After a couple of hours, check back into the
                                {' '}
                                <a href="https://app.we-connect.io/saved_lists" target="_blank" rel="noopener noreferrer">Saved Lists</a>
                                {' '}
                                section of We-Connect and you should be greeted with your list name along with its status as ‘completed’:

                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/linkedin-automation-5-ce3f09c1-jpg.jpg" alt="" style={{ marginBottom: '1rem' }} />

                            <p>
                                Select either:
                            </p>

                            <ul>
                                <li>
                                    Invite New Connections
                                </li>
                                <li>
                                    Message 1st Connections
                                </li>
                            </ul>

                            <p>
                                And select the prospect list from the previous step.
                            </p>

                            <p>
                                See instructions for each campaign type below.
                            </p>
                        </div>
                        <div ref={refs[3]} className="plug-block">
                            <div className="plug-block-subheading">
                                Inviting New Connections
                            </div>

                            <p>
                                Inviting new connections is a useful but potentially dangerous automation. As we have explored previously, false or lazy personalisation can put other LinkedIn users off your profile.

                            </p>

                            <p>
                                This is obviously only suitable for users whom you have not yet connected with.

                            </p>

                            <p>
                                Once you have selected this campaign type, and the prospect list, you will be given the opportunity to personalise your message:

                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/linkedin-automation-7-8617d5a5-jpg.jpg" alt="" style={{ marginBottom: '1rem' }} />

                            <p>
                                Unlike in more personalised outreach where you can take the time to review each contact, you have to compose a single ‘personalised’ message to be sent to the entire list.
                            </p>

                            <p>
                                Depending on the nature of the saved list, it’s still possible to add a degree of personalisation.
                            </p>

                            <p>
                                For example, in this instance, we are message companies which offer ethical/ vegan products.
                            </p>

                            <div className="examples-block" style={{ marginBottom: '1rem' }}>
                                <p>Hi [FIRST_NAME],</p>

                                <p>
                                    I've been following [COMPANY|your company] for a few months and I'm really impressed by your ethical approach to business. I'd love to connect so we could both benefit from being in each other's networks.
                                </p>

                                <p>
                                    Speak soon, Iman
                                </p>
                            </div>

                            <p className="bold">
                                Follow Ups
                            </p>
                            <p>You can then choose to include automated follow ups, hours or days after a successful connection. </p>

                            <p>
                                N.B. these will only be sent if the user accepts your invitation but does not respond.
                            </p>

                            <p>
                                You may wish to include an automated follow-up one hour after connection which directs the user to a piece of content on your LinkedIn profile.
                            </p>

                            <p>
                                Avoid directing new connections to a case study, or requesting that they book a call: this will most likely lead to them disconnecting with you.
                            </p>

                            <p className="bold">Schedule</p>

                            <p>
                                Leave LinkedIn’s default schedule of Monday-to-Friday, 9am-5pm. If your lead list skews towards one location, consider changing the timezone to reflect this.
                            </p>

                            <p>
                                Likewise, We-Connect’s default message limit of 50 a day should be left, although this can be built up to a maximum of 150 a day, your account is likely to be flagged with unaccepted invitation requests at this rate.
                            </p>
                        </div>

                        <div ref={refs[4]} className="plug-block">
                            <div className="plug-block-subheading">
                                Messaging 1st Connections
                            </div>

                            <p>
                                This campaign objective is the most useful and explicitly-sales-lead option.
                            </p>

                            <p>
                                1st connections are already in your network, do not require to be connected with before messaging, and have been exposed to your profile previously (along with social proof and your content).
                            </p>

                            <p>
                                There is also no 300 character limit on messages, and a greater ability to automate follow-ups which is not dependent on the variable of users accepting your request beforehand.
                            </p>

                            <p>
                                For this campaign type:
                            </p>

                            <ul>
                                <li>
                                    Make sure the saved search that you use to create the list includes 1st connections
                                </li>
                                <li>
                                    If you are simultaneously running a New Connection Campaign, or other campaign, make sure to tick ‘Exclude if a contact is already present on another campaign’ and keep the number of messages across all campaigns less than 150.
                                </li>
                            </ul>

                            <p>Once you select this campaign objective, you will again be given the option to add a personalised message:</p>

                            <img src="https://img.genflow.com/gya/plugandplays/linkedin-automation-8-b9816b8e-jpg.jpg" alt="" style={{ marginBottom: '1rem' }} />

                            <p>
                                As with the previous campaign objectives and other types of outreach automation, this automation stops if a lead replies to your message.
                            </p>

                            <p>
                                We recommend a total of three messages in your campaign:
                            </p>

                            <ul>
                                <li>
                                    Message 1: Campaign Launch Day
                                </li>
                                <li>
                                    Message 2: Campaign Launch Day +2
                                </li>
                                <li>
                                    Message 3: Campaign Launch Day +4
                                </li>
                            </ul>

                            <p>
                                Use the following template to script your messages:
                            </p>

                            <p className="bold">
                                Message 1: Campaign Launch Day
                            </p>
                        </div>

                        <div className="examples-block" style={{ marginBottom: '1rem' }}>
                            <p>
                                Hey [FIRST_NAME|there],
                            </p>

                            <p>
                                Hope you're very well! Quick question: is [COMPANY|your company] running Facebook ads?
                            </p>

                            <p>
                                We're working with quite a few companies like yours in the XNICHE on their digital marketing campaigns and we've noticed that brands that include this in their adverts have a dramatically higher return on ad spend than brands that don't.
                            </p>

                            <p>
                                If you're currently investing in Facebook ads, I'd hate for you to waste any of your marketing budget without implementing this.
                            </p>

                            <p>
                                We've made a quick case study that shows you how to get the most out of your investment. I'll leave the link to it here so you can watch it for free:
                            </p>

                            <p>
                                www.agency.com/case-study
                            </p>

                            <p>
                                Let me know if you have any questions about this - I'll leave a link to my diary below if you'd like to chat through it and see if you could improve your results.
                            </p>

                            <p>
                                calendly.com/my-diary
                            </p>

                            <p>
                                Speak soon,
                            </p>

                            <p>
                                Iman
                            </p>
                        </div>

                        <p className="bold">
                            Message 2: Campaign Launch Day +2
                        </p>

                        <div className="examples-block" style={{ marginBottom: '1rem' }}>
                            <p>
                                Hey [FIRST_NAME|there],
                            </p>

                            <p>
                                Just thought I'd send a quick follow up on the above :)
                            </p>

                            <p>
                                I wanted to share an example of what we discussed before: one of our clients XXX company. We started working with them at the beginning of this year - previously they'd worked with an agency to provide their FB and Google advertising.
                            </p>

                            <p>Within 30 days, we'd increased their ROAS from 0.6 (where they were losing money) to well over 3.0 by implementing some really simple changes in their campaigns. </p>

                            <p>
                                They're now in the position where they can reliably invest thousands of dollars every month to grow their brand - and see it returned.
                            </p>

                            <p>
                                I'd love to help [COMPANY|your company] in the same way and really help you fly.
                            </p>

                            <p>
                                If you've got any time this week, it would be great if you could book a quick call in my diary using the link below. I'll show you how you can get similar results and really turbocharge your ads!
                            </p>

                            <p>
                                calendly.com/my-diary
                            </p>

                            <p>
                                Speak soon,
                            </p>

                            <p>Iman</p>
                        </div>

                        <p className="bold">
                            Message 3: Campaign Launch Day +4
                        </p>

                        <div className="examples-block">
                            <p>
                                Hey [FIRST_NAME|there],
                            </p>

                            <p>Think I've caught you at a bad time :/ </p>

                            <p>
                                No worries - I'd still love to help [COMPANY|your company] and whenever you've got the time, feel free to message me for advice.
                            </p>

                            <p>I'll leave my calendar link again below if you would like to get in touch. </p>

                            <p>
                                calendly.com/my-diary
                            </p>

                            <p>Otherwise, I won't keep bothering you.</p>

                            <p>
                                Best of luck and hopefully speak soon!
                            </p>

                            <p>Iman</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
