import React, { useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';

const OnboardingCard = ({
    onNextClick, onCancelClick, close, title, subtitle, nextButtonTitle = 'next', closeButtonTitle, target, options, videoUrl, completed
}) => {
    const [activeOption, setActiveOption] = useState(0);
    useEffect(() => {
        const divElement = document.getElementById(target);
        console.log({ divElement, target });
        if (divElement) {
            divElement.scrollIntoView({ behavior: 'smooth' });
        }
   },[target]);


   const handleNext = () => {
    if(options){
        onNextClick(options?.[activeOption])  
        return
    }
    onNextClick()
   }

    return (
        <div className="w-full flex flex-col bg-[#393939] rounded-[30px] overflow-hidden z-50">
            <div className="w-full">
                <div className="bg-[#2D2D2D] px-6 py-5 flex row justify-between items-center">
                    <p>{title}</p>
                     <CloseIcon className="text-white/[0.4] hover:text-black cursor-pointer transition-transform" onClick={() => close()} />
                </div>
                <div className="my-4 mx-4">
                    <p className="text-[#ADADAD] text-[14px] md:text-[16px] leading-[20px] max-h-[40vh] md:max-h-[50vh] overflow-auto whitespace-pre-line">{subtitle}</p>


                    {options?.length && (
                        <ul className="mt-5">
                            {
                        options?.map(({ label, value }, i) => (
                            <li
                                className={`cursor-pointer w-full px-4 py-4 rounded-[10px] mb-3 bg-${activeOption === i  ? '[#1777D9]' : '[#515151]'}`}
                                onClick={() => setActiveOption(i)}
                            >
                                {label}
                            </li>
                        ))
                            }
                        </ul>
                    )}

                    {videoUrl && <div className="mt-5 h-[350px] w-full rounded-[10px] overflow-hidden" style={{ background: '#101010' }}>
                            <iframe
                                id="videoPlayer"
                                title="video"
                                className="sproutvideo-player"
                                src={`${videoUrl}?cc=eng&autoPlay=${true}`}
                                style={{ background: '#101010' }}
                                frameBorder="0"
                                allowFullScreen
                                width="100%"
                                height="100%"
                            />
                    </div>}
                </div>
            </div>
            <div className=" flex justify-end w-full mb-6">
                {
                    closeButtonTitle && <button type="button" onClick={() => onCancelClick()} className="border-0 text-white focus:outline-none font-medium l px-5 py-2.5 text-center ">{closeButtonTitle}</button>

                }
                <button type="button" onClick={handleNext} className="bg-[#1777D9] min-w-[164px] border-0 border-none mr-6 rounded-full text-white focus:outline-none font-medium l px-5 py-2.5 text-center ">{nextButtonTitle}</button>
            </div>
        </div>
    );
};

export default OnboardingCard;
