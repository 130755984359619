/* eslint-disable max-len */
import React from 'react';
import './style.scss';
import Container from 'reactstrap/lib/Container';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';

const sections = [
    {
        available: true, sectionName: 'Before you start',
    },
    {
        available: true, sectionName: 'Two Lead Lists',
    },
    {
        available: true, sectionName: 'PipeDrive Setup',
    },
    {
        available: true, sectionName: 'Trello Setup',
    },
];

const title = "Storing And Organising Leads"

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]}  className="plug-block"> 
                            <div className="plug-block-subheading">
                                Before you start:
                            </div>

                            <p>
                                Make a decision as to whether you want to use Pipedrive or Trello as your CRM. At only $15 per month for the ‘essential plan’ - I think that pipedrive is a no brainer. Bear in mind that AgenciFlow will soon have CRM functionality. Considering that most Agency Navigator students have AF - once it comes out, it would probably be worth you cancelling your PipeDrive subscription.
                            </p>

                            <a href="https://www.pipedrive.com/?utm_content=copy_text&utm_medium=partners_program&utm_source=IAG+Media&utm_term=pdp-growyouragency" target="_blank" rel="noopener noreferrer">Get PipeDrive HERE</a>

                            <a href="https://trello.com/" target="_blank" rel="noopener noreferrer">Get Trello HERE</a>


                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">
                                Two Lead Lists:

                            </div>

                            <p>
                                Most people make the mistake of having simply one lead list & then it gets totally congested. You need to make sure to have two lead lists, one for your active leads still going through your sales process and then another one for your idle leads. This second lead list is there to be reactivated 3 months after the last touch point.
                            </p>
                        </div>


                        <div ref={refs[2]} className="plug-block">
                            <div className="plug-block-subheading">
                                PipeDrive Setup:

                            </div>

                            <ol>
                                <li>
                                    Create an account with all your credentials.
                                </li>
                                <li>
                                    Setup two pipelines, the first being ‘Active List’ & the second one ‘Reactivation List’
                                </li>
                                <li>
                                    In the active list, create 8 pipeline stages: Prospect, 1st TP, 2nd TP, 3rd TP, 4th TP, Appointment Booked, Meeting & Closed Client.
                                </li>
                                <li>
                                    In the reactivation list, create 3 pipeline stages: ‘Time Out, Re-Activate & Re-activated’
                                </li>
                                <li>
                                    Each new ‘deal’ is a prospect. Make sure that you fill in the information correctly and keep the deal info up to date.
                                </li>
                            </ol>
                        </div>


                        <div ref={refs[3]} className="plug-block">
                            <div className="plug-block-subheading">
                                Trello Setup:

                            </div>

                            <ol>
                                <li>
                                    Create an account with all your credentials.
                                </li>
                                <li>
                                    Setup two boards, the first being ‘Active List’ & the second one ‘Reactivation List’
                                </li>
                                <li>In the active board, create 8 pipeline lists: Prospect, 1st TP, 2nd TP, 3rd TP, 4th TP, Appointment Booked, Meeting & Closed Client.</li>
                                <li>
                                    In the reactivation board, create 3 pipeline lists: ‘Time Out, Re-Activate & Re-activated’
                                </li>
                                <li>
                                    In the active board, create a card and add these details to it:
                                    <br />
                                    Contact Name:
                                    {' '}
                                    <br />
                                    Business Name:
                                    {' '}
                                    <br />
                                    Date of Added Lead:
                                    {' '}
                                    <br />
                                    First Contact:
                                    {' '}
                                    <br />
                                    <br />
                                    {' '}
                                    <br />
                                    Phone Number:
                                    {' '}
                                    <br />
                                    Email:
                                    {' '}
                                    <br />
                                    Website:
                                    {' '}
                                    <br />
                                    Instagram:
                                    {' '}
                                    <br />
                                    Facebook:
                                </li>
                                <li>
                                    Save this as a template, whenever you want to add a new deal - you’ll be able to use the template.
                                </li>
                                <li>
                                    Make sure all of your sales notes & activities go into the ‘activities’ section of the Trello card.
                                </li>
                            </ol>
                        </div>


                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
