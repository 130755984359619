import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import { Link } from 'react-router-dom';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        available: true,
        sectionName: 'Thoughts',
    },
    {
        available: true,
        sectionName: 'Actionable steps',
    },
];

const title = 'iOS Move To Privacy & Implications';

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div className="plug-block">  
                        <h2 className="mb-4">{title}</h2>
                            <div className="plug-block-subheading">In today's teaching</div>

                            <ul>
                                <li>Thoughts</li>
                                <li>Actionable steps</li>
                            </ul>
                        </div>

                        <div ref={refs[0]} className="plug-block">
                            <div className="plug-block-subheading">Thoughts</div>

                            <p>
                                This is more of a rant that I've been thinking about for a while. You probably have
                                understood what I'm about to say if you carefully watched all of the modules because
                                all the ideas that I'm going to describe now I have roughly gone over in other
                                modules, but here is my consolidated opinion. Because Facebook is an auction process
                                and there's a limited advertising space, Facebook has a monopoly over attention. But
                                it's not sustainable. Too many advertisers coming to the platform busy CPMs will
                                arise and it won't make sense for anybody to advertise, This will of course make
                                sure that the bad players get out because they are not seeing money back so they
                                can't play anymore. This is basic offer and demand forces at play.
                            </p>

                            <p>
                                But what actually happens when there's a monopoly and the other players have to play
                                with the monopoly owner is that they start lowering the margins they're accepting
                                and we are just fighting for scrapes meaning the businesses are competing with each
                                other for less and less profit.
                            </p>

                            <p>
                                In an auction process, Facebook advertising is the more leaders the better. Facebook
                                has clearly understood this very well under the goal is to make it as easy as
                                possible to enter this auction. That is why they started to make advertising as easy
                                as possible. That way the barrier to entry is very low.
                            </p>

                            <p>
                                Facebook ideal scenario because the CPMs cannot get extremely high where it doesn't
                                make sense for anybody to advertise as these will be a not be and auto-regulating
                                system because if there's no money back no one will play again.
                            </p>

                            <p>
                                So there's a limited space to advertise and there's a limited amount of advertisers
                                that can advertise in the platform. The ideal scenario for Facebook then is that
                                only the Best advertisers survive the best advertisers would actually be the ones
                                with the best product, because Facebook wants its users to have the best experience.
                            </p>

                            <p>
                                They made their algorithm so good that you didn't have to do much on the ads manager
                                side things you could just have a CBO where you don't even manage the budget and
                                then have a broad audience when you don't even choose an audience. Of course they
                                would never be able to write the copy for you or optimize the page for you or make
                                sure that the funnel is converting for you but they went as far as they could.
                            </p>

                            <p>
                                Now that Apple is not letting them have as much data as they used to their
                                algorithms are not as good as they were. This means we are almost back at 2018 when
                                we were doing ads. But then the strategy was very very important and also all the
                                copywriting and everything marketing related.
                            </p>

                            <p>
                                To conclude, I see all these iOS move to privacy as a positive thing for agency
                                owners it is making the Plainfield more difficult for the normal business owner to
                                just had to spend a couple minutes to run the ads, because Facebook would do the
                                rest, to a real need for agencies that know the way around trucking and have proper
                                strategies of finding audiences that work and know how to scale them in a way that
                                doesn't break.
                            </p>
                        </div>

                        <div className="plug-block">
                            <div ref={refs[1]} className="plug-block-subheading">
                                Actionable steps
                            </div>

                            <p>
                                Review our UTM tracking plug n play and make sure you have systems in place to know
                                your numbers as best as possible.
                            </p>

                            <Link to="/resources/plug-and-play/true-tracking">View here</Link>

                            <p>
                                <b>Use more interest based audiences:</b>
                                {' '}
                                Because Facebook is having less data,
                                their algorithms are slowly becoming worse as the data they've been trained on
                                becomes older and outdated. This will make your input on audiences more valuable and
                                finding good interests would be as important as it was back in 2018 and 2019
                            </p>

                            <p>
                                <b>Improve your metrics:</b>
                                {' '}
                                The clear needle movers we've spoken about, get a
                                better CTR with better copy, videos and images. Get a better CPM with better ads,
                                offers and audiences. Get a better conversion rate for your client so you make the
                                most out of the traffic you are driving and make them more money.
                            </p>

                            <p>Remember happy client = easy agency.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
