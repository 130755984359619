const styles = theme => ({
    root: {
        width: '100%',
        height: '100%',
        backgroundColor: `#1C1C1C`,
    },
    paper: {
        padding: 16,
    },
    title: {
        textAlign: 'center',
        fontWeight: 700,
        background: 'linear-gradient(35deg, #1882de 0, #1830de 100%)',
        color: '#fff',
        padding: 10,
    },
});

export default styles;
