import React, { useEffect } from 'react';

import { Col, Container, Row } from 'reactstrap';
import Footer from 'components/Footer';
import { useConfig } from '@genflow/core';
import { useParams } from 'react-router';
import useBoughtProducts from '@genflow/web-courses/hooks/useBoughtProducts';
import './style.scss';

export default ({ title, children }) => {
    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;

        const disableRightClick = (e) => {
            e.preventDefault();
        };

        const preventShortCutDevTools = (e) => {
            console.log('e', e);
            const disable = (e.ctrlKey && e.shiftKey && e.key === 'J')
            || (e.metaKey && e.altKey && e.code === 'KeyJ')
            || (e.ctrlKey && e.shiftKey && e.key === 'I')
            || (e.ctrlKey && e.shiftKey && e.key === 'C')
            || (e.ctrlKey && e.key === 'U');

            console.log('disable', disable);
            // if (disable) {
            //
            // }

            return true;
        };

        document.addEventListener('contextmenu', disableRightClick);
        // document.addEventListener('keydown', preventShortCutDevTools);
        return () => {
            document.removeEventListener('contextmenu', disableRightClick);
            // document.removeEventListener('keydown', preventShortCutDevTools);
        };
    }, []);

    return (
        <>
            <main className="w-10/12 mx-auto" onMouseDown={false} onCopy={false} onCut={false}>
                <section className="section-profile-cover section-shaped my-8">
                    <Container>
                        <Row>
                            <Col lg={12} className="text-white">
                                <h2>Plug & Play</h2>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="section section-lg pt-lg-0 mt--4">
                    <Container>
                        <Row>
                            <Col md={12}>

                                {children}

                            </Col>

                        </Row>
                    </Container>
                </section>
            </main>
            <Footer />
        </>
    );
};
