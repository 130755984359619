import React, {
    useContext, useEffect, useRef, useState,
} from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import {
    Input, InputGroup, InputGroupAddon, InputGroupText, Spinner,
} from 'reactstrap';
import CurrencyInput from 'react-currency-input-field';
import moment from 'moment';
import AddOutlined from '@material-ui/icons/AddOutlined';
import EditOutlined from '@material-ui/icons/EditOutlined';
import CloseOutlined from '@material-ui/icons/CloseOutlined';
import { orderBy } from 'lodash';
import styles from './styles';
import withFinanceColumn from './withFinanceColumn';
import { SummaryContext } from '../../../../providers/SummaryProvider';

const FinanceItem = withStyles(styles)(({
    classes, id, name, value, onClickSave, currency,
}) => {
    const isNew = !id;
    const [inputRef, setInputRef] = useState(null);
    const [itemSaveLoading, setItemSaveLoading] = useState(false);
    const [editedName, setEditedName] = useState(name);
    const [editedValue, setEditedValue] = useState(value);
    const [showDeleteItem, setShowDeleteItem] = useState(false);

    useEffect(() => {
        if (inputRef) {
            inputRef.focus();
        } else {
            setItemSaveLoading(false);
        }
    }, [inputRef]);

    const createOrSaveFinanceItem = () => {
        if (!!editedName && !!editedValue) {
            setItemSaveLoading(true);
            onClickSave(editedName, editedValue);
        } else {
            alert('You must enter a item name and value');
        }
    };

    const deleteItem = () => {
        // eslint-disable-next-line no-restricted-globals
        const yes = confirm(`Are you sure you want to delete "${name}"?`);
        if (yes) {
            setItemSaveLoading(true);
            onClickSave(editedName, editedValue, true);
        }
    };

    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            createOrSaveFinanceItem();
        }
    };

    const onInputFocus = () => !isNew && setShowDeleteItem(true);
    const onInputBlur = (e) => {
        !isNew && setTimeout(() => setShowDeleteItem(false), 250)

        // update onblur only if edited name or value has been changed
        if (!!editedName && !!editedValue && (editedName !== name || editedValue !== value)) {
            createOrSaveFinanceItem();
        }
    };

    return (
        <div
            className={classes.financeItemContainer}
            style={
                isNew ? {
                    backgroundColor: '#1C1C1C',
                    borderColor: (editedName?.length > 0 || editedValue?.length) ? 'rgba(24,130,222,0.44)' : '#e2e2e2',
                    borderWidth: (editedName?.length > 0 || editedValue?.length) > 0 ? 2 : 0,
                    borderStyle: 'solid',
                    borderRadius: '0.25rem',
                    marginBottom: '1rem',
                    padding: '0.5rem',
                    width: '100%'
                } : {
                    marginBottom: '1rem',
                }
            }
        >
            <Input
                innerRef={ref => setInputRef(ref)}
                className={classes.financeItemInput}
                placeholder="Item Name"
                type="text"
                value={editedName}
                onChange={e => setEditedName(e.target.value)}
                onKeyDown={onKeyDown}
                onFocus={onInputFocus}
                onBlur={onInputBlur}
            />

            {/* <Input */}
            {/*   className={classes.financeItemInput} */}
            {/*   placeholder="Value" */}
            {/*   type="number" */}
            {/*   value={value} */}
            {/*   onChange={e => updateState({ name, value: e.target.value })} */}
            {/* /> */}


            <CurrencyInput
                key={currency}
                className="form-control grey700Bg"
                prefix={currency}
                placeholder={`${currency}1,000`}
                decimalsLimit={2}
                onValueChange={val => setEditedValue(val)}
                style={{ marginLeft: '0.5rem', textAlign: 'right', flex: 1, width: '50%', paddingRight: '0.3rem', color: 'white', borderRadius: '0.25rem' }}
                value={editedValue}
                onKeyDown={onKeyDown}
                onFocus={onInputFocus}
                onBlur={onInputBlur}
            />

            {
                !itemSaveLoading && isNew && (
                    <AddOutlined
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            paddingLeft: '.5rem',
                            opacity: 0.5,
                            fontSize: '1.8rem',
                        }}
                        onClick={createOrSaveFinanceItem}
                    />
                )
            }

            {
                showDeleteItem && !itemSaveLoading && !isNew && (
                    <CloseOutlined
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            paddingLeft: '.5rem',
                            opacity: 0.5,
                            fontSize: '1.8rem',
                        }}
                        onClick={deleteItem}
                    />
                )
            }

            {
                itemSaveLoading && (
                    <Spinner
                        color="primary"
                        type="grow"
                        style={{ marginLeft: 10 }}
                    />
                )
            }
        </div>
    );
});

const FinanceColumn = ({
    defaultTitle,
    financeItemIndex,
    title, classes, month, updateData, deleteRow, currency,
    isExpense = false,
}) => {
    const { addOrUpdateFinanceItem, updateFinanceItemTitle, data } = useContext(SummaryContext);

    const thisMonthData = data.find(({ startDateTime }) => {
        const thisMonthName = moment().utc().month(month).format('MMM');
        const summaryMonthStartName = moment(startDateTime).utc().format('MMM');
        return (thisMonthName === summaryMonthStartName);
    });

    const [newItem, setNewItem] = useState(null);

    const monthSummaryJSON = JSON.parse(thisMonthData?.summaryJSON || '{}');
    const columnFinanceData = monthSummaryJSON?.financeItems?.[financeItemIndex];
    const columnFinanceItems = orderBy(columnFinanceData?.items || [], ['value'], ['desc']);
    const baseTitle = columnFinanceData?.title || defaultTitle;


    const [editTitle, setEditTitle] = useState(false);
    const [editTitleLoading, setEditTitleLoading] = useState(false);
    const [inputRef, setInputRef] = useState(null);

    useEffect(() => {
        if (inputRef) {
            inputRef.focus();
        }
    }, [inputRef]);


    useEffect(() => {
        if (!editTitle) {
            setEditTitleLoading(false);
        }
    }, [editTitle]);

    return (
        <Paper 
            className={classes.root}
            style={{backgroundColor: "#1C1C1C !important;"}}
        >
            <div
                style={
                    {
                        padding: '0.5rem',
                        textAlign: 'center',
                        color: 'white',
                        fontWeight: 'bold',
                        borderTopLeftRadius: '0.25rem',
                        borderTopRightRadius: '0.25rem',
                        fontFamily: 'Inter',
                        fontSize: '10px',
                        fontWeight: '600',
                        lineHeight: '15px',
                        letterSpacing: '0.4000000059604645px',
                        textTransform: 'uppercase',
                        ...(isExpense ? {
                            backgroundColor: '#CE3153',
                        }
                            : {
                                backgroundColor: '#65B456',
                            }),
                    }
                }
            >
                {isExpense ? 'Expenses' : 'Revenue'}
            </div>
            <div style={{ padding: '0.5rem' }}>
                {
                    editTitle
                        ? (
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                            >
                                <Input
                                    className={classes.inputHeader}
                                    innerRef={ref => setInputRef(ref)}
                                    // className={classes.financeItemInput}
                                    placeholder={`Enter ${isExpense ? 'expense' : 'revenue'} title...`}
                                    type="text"
                                    value={editTitle || baseTitle}
                                    onChange={e => setEditTitle(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            setEditTitleLoading(true);
                                            updateFinanceItemTitle(
                                                month,
                                                financeItemIndex,
                                                editTitle,
                                                () => setEditTitle(null),
                                            );
                                        }
                                    }}
                                />
                                {
                                    editTitleLoading && (
                                        <Spinner
                                            color="primary"
                                            type="grow"
                                            style={{ marginLeft: 10 }}
                                        />
                                    )
                                }
                            </div>
                        )
                        : (
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                            >
                                <Typography
                                    onClick={() => setEditTitle(baseTitle)}
                                    variant="h5"
                                    className={classes.typographyHeader}
                                >
                                    {baseTitle}
                                </Typography>
                                <EditOutlined style={{ opacity: 0.2, width: 15, height: 15 }} />
                            </div>
                        )
                }

                <FinanceItem
                    key={columnFinanceItems?.length}
                    updateState={setNewItem}
                    onClickSave={(name, value) => addOrUpdateFinanceItem({
                        monthName: month,
                        financeItemIndex,
                        financeItemTitle: defaultTitle,
                        name,
                        value,
                        onComplete: () => {
                            console.log('new item should be cleared');
                            setNewItem(null);
                        },
                    })}
                    currency={currency}
                />

                {
                    columnFinanceItems.map(financeItem => (
                        <FinanceItem
                            key={`${financeItem?.id}-${financeItem?.name}-${financeItem?.value}`}
                            id={financeItem?.id}
                            name={financeItem?.name}
                            value={financeItem?.value}
                            // updateState={setNewItem}
                            onClickSave={(name, value, deleteItem = false) => addOrUpdateFinanceItem({
                                id: financeItem.id, // need id to update
                                monthName: month,
                                financeItemIndex,
                                financeItemTitle: defaultTitle,
                                name,
                                value,
                                deleteItem,
                                onComplete: () => {
                                    // console.log('new item should be cleared');
                                    // setNewItem(null);
                                },
                            })}
                            currency={currency}
                        />
                    ))
                }
                {/* <Table */}
                {/*    data={[...data, { */}
                {/*        item: '', */}
                {/*        amount: '', */}
                {/*    }]} */}
                {/*    header */}
                {/*    year={year} */}
                {/*    month={month} */}
                {/*    col="revenue" */}
                {/*    updateData={updateData} */}
                {/*    deleteRow={deleteRow} */}
                {/*    currency={currency} */}
                {/* /> */}
            </div>
        </Paper>
    );
};

export default withFinanceColumn(withStyles(styles)(FinanceColumn));
