/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

import image1 from '../../../../../assets/img/plugandplay/google-search-network/1.png';
import image2 from '../../../../../assets/img/plugandplay/google-search-network/2.png';
import image3 from '../../../../../assets/img/plugandplay/google-search-network/3.png';
import image4 from '../../../../../assets/img/plugandplay/google-search-network/4.png';
import image5 from '../../../../../assets/img/plugandplay/google-search-network/5.png';
import image6 from '../../../../../assets/img/plugandplay/google-search-network/6.png';
import image7 from '../../../../../assets/img/plugandplay/google-search-network/7.png';
import image8 from '../../../../../assets/img/plugandplay/google-search-network/8.png';
import image9 from '../../../../../assets/img/plugandplay/google-search-network/9.png';
import image10 from '../../../../../assets/img/plugandplay/google-search-network/10.png';
import image11 from '../../../../../assets/img/plugandplay/google-search-network/11.png';
import image12 from '../../../../../assets/img/plugandplay/google-search-network/12.png';
import image13 from '../../../../../assets/img/plugandplay/google-search-network/13.png';

const sections = [
    {
        available: true,
        sectionName: 'How To Create a Google Search Campaign',
    },
    {
        available: true,
        sectionName: 'Creating Ad Groups',
    },
];

const title = 'Google Search Network';

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div className="plug-block">  
                        <h2 className="mb-4">{title}</h2>
                            <div className="plug-block-subheading">Introduction</div>
                            <p>
                                The Google Search network is a fantastic place to advertise to users when they are
                                in a ‘buying’ mindset.
                            </p>

                            <p>
                                Unlike Facebook, where people are usually with a “social” mindset while scrolling
                                through their feed, on Google the buying intent is huge. That’s because the users on
                                Google are already actively searching for a service or product - so that’s the
                                perfect opportunity to feature said products or services through adverts.
                            </p>
                        </div>

                        <div ref={refs[0]} className="plug-block">
                            <div className="plug-block-subheading">How To Create a Google Search Campaign</div>

                            <p>
                                In the example here, we’re going to create the ads for a fictitious ecommerce brand
                                that sells sun-protective clothing.
                            </p>

                            <p>
                                So here’s how we do it. First, we just need to hit the “+ New Campaign” button on
                                our main dashboard.
                            </p>

                            <img src={image1} alt="" />

                            <p>
                                The first thing Google asks us is the campaign objective. Keep things simple here.
                                For Ecommerce, you’re almost always going to select “Sales”. For lead generation,
                                you’re going to select “leads”. For infoproducts, it can be one of the two,
                                depending on the type of funnel. If you’re sending people straight to a sales page,
                                select sales. If you’re capturing leads, select leads.
                            </p>

                            <p>In our sun-protective clothing brand example, we will use sales.</p>

                            <img src={image2} alt="" />

                            <p>
                                Then, select “Search” in order to create the campaign on the Google Search Network
                            </p>

                            <img src={image3} alt="" />

                            <p>
                                Then, select “Website visits” (only select another option if you’re not using a
                                website - in case you’re using an app, for example).
                            </p>

                            <img src={image4} alt="" />

                            <p>
                                In the first step of the next, we just have to give our campaign a name. Again, keep
                                it simple.
                            </p>

                            <p>
                                Also, make sure we uncheck the boxes for “Include Google Search partners” and
                                “Include Google Display Network”.
                            </p>

                            <img src={image5} alt="" />

                            <p>
                                In “Targeting and Audiences” we’re first going to select the country you want to
                                advertise to and the language. If you’re advertising to English speaking countries,
                                select “English”. Now, if you’re advertising to non-English speaking countries I
                                recommend you select the native language of the country you’re advertising to
                                {' '}
                                <b> as well as </b>
                                {' '}
                                English, because a lot of people still use their browsers in
                                English even though that’s not their native language.
                            </p>

                            <p>
                                For the “audiences” we’re just going to skip them for now, since we do our targeting
                                only through keywords on the search network.
                            </p>

                            <img src={image6} alt="" />

                            <p>
                                On “Budget” you select your daily budget. Keep in mind that in a month Google tries
                                to spend 30.4x your daily budget.
                            </p>

                            <p>On bidding, I suggest you leave it as “Conversions” with no targeting cost.</p>

                            <img src={image7} alt="" />

                            <p>
                                On the Sitelink extension, you should create at least 4 sitelinks. These sitelinks
                                extensions are those extra links that appear under your main website on the search
                                results page on Google.
                            </p>

                            <img src={image8} alt="" />

                            <p>
                                Ideally, you should keep them closely related to the product/service you’re
                                advertising on your campaign.
                            </p>

                            <p>
                                In this example, we have used “Sun Protective clothing for children”, “Sun
                                protective Clothing for Men”, “Sun protective clothing for Women”, and a “Sales”
                                one.
                            </p>

                            <p>
                                Remember to always use the specific page link on the final URL. For example, on the
                                “sun protective clothing for women” sitelink, you should link for the women’s
                                section of the website.
                            </p>

                            <img src={image9} alt="" />

                            <p>
                                For the call-out extensions, you should add a few quick benefits of your
                                product/service that most appeal to the final users.
                            </p>

                            <img src={image10} alt="" />

                            <p>
                                You can also add a number people can call, if you have a sales team that operate by
                                phones. But note that these are difficult to track without a dedicated number.
                            </p>

                            <img src={image11} alt="" />

                            <p>With that done, just hit “Save & Continue” to go to the next page.</p>
                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">Creating Ad Groups</div>

                            <p>
                                The most important thing here is taking the time to think about the ideal structure
                                for your campaigns.
                            </p>

                            <p>We recommend that you segment your ad groups by themes.</p>

                            <p>I always like to segment the ad groups by themes.</p>

                            <p>In this example:</p>

                            <ul>
                                <li>“sun protective clothing for men”</li>
                                <li>“sun protective clothing for women”</li>
                                <li>“sun protective clothing for kids”</li>
                                <li>“sun protective clothing”</li>
                                <li>
                                    Misc. ad groups with different wordings that people can use to search for our
                                    product. For example, “UPF Clothing” or “Sun clothing”.
                                </li>
                            </ul>

                            <p>
                                After thinking about the structure of each ad group, you have to add the keywords
                                for each one of them. The keywords are the actual words that people have to type in
                                their search in order to trigger your ads.
                            </p>

                            <p>There are multiple match types to help you accomplish that, so for example:</p>

                            <ul>
                                <li>
                                    If you add “sun protective clothing” (with the quotation marks) as your keywords,
                                    Google will recognize that as a phrase match. This means that your ads will appear
                                    on searches that have ALL the words inside the quotation marks, but in no
                                    particular order. So, if you were to search “protective clothing for the sun”,
                                    that would trigger these ads because it has all the keywords under “sun protective
                                    clothing”, just in a different order.
                                </li>
                                <li>
                                    If you want Google to also respect the order your keywords are typed in, you
                                    should then use phrase match. Phrase match is represented by brackets [ ]. So, you
                                    would add your keyword as [sun protective clothing].
                                </li>
                                <li>
                                    In case you don’t add quotation marks or brackets, your keyword will be considered
                                    a broad keyword. We advise against using broad match keywords because they tend to
                                    trigger way too random and broad searches that are not closely related to your
                                    keyword.
                                </li>
                            </ul>

                            <p>
                                With that being said, inside each adset, you should have multiple variations of
                                keywords that sit inside the overall theme of the adset, as in the example below:
                            </p>

                            <img src={image12} alt="" />

                            <p>
                                Remember that you don’t need to add long phrases such as “buy sun protective
                                clothing now” because this search would already trigger our ads since it has all the
                                keywords from “sun protective clothing”.
                            </p>

                            <ul>
                                <li>
                                    <b>Pro tip #1:</b>
                                    {' '}
                                    You should aim to keep the number of keywords between 3-10 per
                                    ad group. It’s not a definite rule, but a good practice.
                                </li>
                                <li>
                                    <b>Pro tip #2:</b>
                                    {' '}
                                    Don’t be too specific when it comes to your keywords. If
                                    they’re too narrow, you won’t have enough search volume in order to spend your
                                    budget in its entirety.
                                </li>
                                <li>
                                    <b>Pro tip #3:</b>
                                    {' '}
                                    It’s important to really do your homework when it comes to
                                    keyword research. Websites like Answer The Public, Uber Suggest, and Google
                                    Keyword Planner are great to get insights on what people are searching related to
                                    the keywords you already have in mind. Using Google’s auto suggest feature is also
                                    a great hack to come up with solid keyword ideas. Don’t go too crazy, though.
                                    Simple keywords such as “sun protective clothing” tend to work better than “UPF
                                    +50 special clothing for fishers in florida”
                                </li>
                            </ul>

                            <p>Once you finish creating all your ad groups, hit “Save and Continue”.</p>

                            <h4>Creating Ad Groups</h4>

                            <p>Now it’s time to write ads for each one of your ad groups.</p>

                            <p>
                                Before writing your ads, always check to which ad group they belong, so that you can
                                create personalized ads for each one of the different types of keywords.
                            </p>

                            <p>
                                Now, here on the ads it’s pretty simple. Just type out your landing page URL under
                                “Final URL”.
                            </p>

                            <p>
                                “Display path” is just for branding, you can add your keyword in there so that
                                people know where they will be redirected to once they click on your ad.
                            </p>

                            <p>
                                On “headlines” you should start out with 3 headlines. Try to make them as closely
                                related to your keywords as possible. In our example, for the ad group “sun
                                protective clothing” I’d add the following headlines: Sun Protective Clothing, UPF
                                +50 Protection, Free 2-Day Shipping.
                            </p>

                            <p>
                                On “descriptions”, start out with 2 descriptions. Try to go over quick benefits from
                                your product here, repeat your main keywords and encourage users to take action.
                            </p>

                            <p>This is what our example would look like</p>

                            <img src={image13} alt="" />

                            <p>Once you’re done writing your ads, hit “save and continue”.</p>

                            <p>
                                Google will then ask you to review your campaign. Make sure everything is right and
                                then just click “Publish”.
                            </p>

                            <p>
                                Your ads are now published and will enter the review process. Once they’re approved,
                                they will start running!
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
