import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
// core components
import useCourse from '@genflow/web-courses/hooks/useCourse';
import { useConfig } from '@genflow/core';
import { get, parseInt } from 'lodash';
import Footer from '../../../../components/Footer';
import './style.scss';
import LazyLoad from 'react-lazyload';

export default () => {
    const [navState, setNavState] = useState({ tabs: 1 });
    const toggleNavs = (e, state, index) => {
        e.preventDefault();
        setNavState({
            [state]: index,
        });
    };
    const {
        vendorUuid,
        resourceLibraryProductID,
        resourcesArticlesSectionID,
        resourcesVideoLibrarySectionID,
        resourcesQARecordingsID,
    } = useConfig();
    const { data } = useCourse(vendorUuid, resourceLibraryProductID);

    const videoLibrarySection = get(data, 'sections', []).find(({ id }) => parseInt(id) === resourcesVideoLibrarySectionID);


    console.log('videoLibrarySection', videoLibrarySection);

    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, []);

    return (
        <>
            <main className="video-library">
                <section className="section-profile-cover section-shaped my-0">
                    <Container>
                        <Row>
                            <Col lg={12} className="text-center">
                                <h1>Video Library.</h1>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="section section-lg pt-lg-0 mt--5" style={{ minHeight: 660 }}>
                    <Container>
                        <Row>
                            {/* {
                                get(videoLibrarySection, 'lessons', []).map(({ id, name, assets }) => {
                                    const url =  "https://www.youtube.com/embed/GlLCA9dArZ4" // get(assets, '[0].url', '');
                                    const thumbnail = get(assets, '[0].thumbnail', '');

                                    return (

                                    );
                                })
                            } */}

                            <Col lg={6}>
                                <LazyLoad height={280}>
                                    <iframe
                                        title="title"
                                        src={"https://www.youtube.com/embed/GlLCA9dArZ4"}
                                        controls
                                        // style={{ backgroundImage: `url('${thumbnail}')` }}
                                    />
                                    <p className="title">From $0 To Millionaire With SMMA (My Agency Journey)</p>
                                </LazyLoad>
                            </Col>
                        </Row>
                    </Container>
                </section>

            </main>
            <div style={{ zIndex: 900 }}>
                <Footer />
            </div>
        </>
    );
};
