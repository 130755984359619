import gql from 'graphql-tag';

export default gql`
    query ($vendorUuid: ID!){
        getClientCustomers(vendorUuid: $vendorUuid) {
            errors
            data {
                id
                createdTimestamp
                updatedTimestamp
                clientCustomerName
                niche
                totalDebits {
                    USD
                }
                totalCredits {
                    USD
                }
            }
        }
    }
`;
