import gql from 'graphql-tag';

export default gql`
    mutation ($entry: DeleteCalendarEntryInput!) {
        deleteCalendarEntry(entry: $entry) {
            calendar {
                PK
                SK
                type
                startDateTime
            }

            data {
                PK
                SK
                startDateTime
                endDateTime
                calendarName
                entryType
                type
                title
                allDay
                complete
                colour
                summaryJSON
            }
        }
    }
`;
