import React from 'react';
import { Button, Form } from 'reactstrap';
import { makeStyles } from '@material-ui/core/styles';
import 'react-activity-feed/dist/index.css';
import { selectSubscription } from '../../../utils/stripe';
import Footer from '../../../components/Footer';
import InputsForm from './InputsForm';
import './styles.scss';

const useStyles = makeStyles(() => ({
    button: {
        marginTop: 20,
        marginBottom: 20,
        color: '#fff',
        background: '#060',
        '&:hover': {
            backgroundColor: '#080',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#090',
        },
        '&:focus': {},
    },
}));

function encode(data) {
    return Object.keys(data)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
        .join('&');
}

const PiracyPolicyPage = () => {
    const classes = useStyles();

    const [playedVideo, setPlayedVideo] = React.useState(false);

    const showNextContent = () => {
        console.log(playedVideo);
        setPlayedVideo(true);
        const continueBtn = document.getElementById('after-video');
        if (continueBtn) {
            continueBtn.style.display = 'none';
        }
    };

    const [state, setState] = React.useState({});

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };

    const handleSubmit = (values) => {
        delete values.accepted;

        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
                'form-name': 'piracy-form',
                ...values,
            }),
        })
            .then((res) => {
                console.log(res);
                window.location.replace('/programs');
            })
            .catch((error) => {
                console.error(error);
                alert('An error has occurred. Please try again.');
            });
    };

    return (
        <>
            <main className="profile-page text-white">
                <section
                    className="section section-lg pt-lg-0 chat-section"
                    style={{ marginTop: '15vh' }}
                >
                    <div className="body-piracy">
                        <h2 className="title">Welcome to GrowYourAgency</h2>

                        <p style={{
                            fontSize: '1.2rem',
                        }}
                        >
                            You’ve done something incredible by joining the GrowYourAgency
                            community: you’ve contributed to the construction of schools in
                            developing countries and the revolution of the education system.
                        </p>

                        <p style={{
                            fontWeight: 800,
                        }}
                        >
                            Important: You must watch this video before continuing.
                        </p>

                        <div className="div-video-piracy">

                            <iframe
                                id="videoPlayer"
                                className="sproutvideo-player"
                                src="https://videos.sproutvideo.com/embed/449ddab8161ae2cfcd/d053f1e07798b383?type=hd"
                                frameBorder="0"
                                allowFullScreen
                                width="100%"
                                height="100%"
                            />

                        </div>

                        <Button
                            color="primary"
                            type="button"
                            onClick={showNextContent}
                            id="after-video"
                            style={{ width: '155px', padding: '0px' }}
                            className="buttonLgLight h-fit mt-4 md:mt-7"
                        >
                            Continue
                        </Button>

                        <div
                            className={`piracy-content-container ${playedVideo ? 'show-content' : 'hide-content'}`}>
                            <p>
                                Whilst we believe that content should not be stolen, at
                                GrowYourAgency, we take intellectual property violations
                                extremely seriously as they have a direct impact on
                                GrowYourAgency’ members contributions to the building schools
                                for those who need them most.
                                {' '}
                            </p>

                            <p>
                                {' '}
                                Before you access this content, we ask that you sign the below
                                contract to affirm that you understand that you must not:
                            </p>

                            <ul className="list-not-to-do">
                                <li>Share your logins with other individuals</li>
                                <li>Record your screen </li>
                                <li>Distribute content elsewhere</li>
                                <li>Copy and paste resources</li>
                                <li>
                                    Do anything that would reasonably be inferred as copyright
                                    infringement
                                </li>
                            </ul>

                            <p>
                                We have implemented a series of advanced security measures which
                                will allow us to track and identify anyone who infringes
                                GrowYourAgency copyright.
                                {' '}
                            </p>

                            <p>
                                We will pursue punitive civil damages and criminal prosecution
                                for anyone suspected of infringing GrowYourAgency copyright.
                            </p>

                            <p>
                                Thank you for your incredible contribution to help children
                                everywhere get the education they deserve.
                            </p>

                            <Form
                                name="piracy-form"
                                method="post"
                                data-netlify="true"
                                data-netlify-honeypot="bot-field"
                                action="/piracypolicy/"
                                className="netlify-form"
                            >
                                <input type="hidden" name="bot-field" onChange={handleChange} />
                                <input type="hidden" name="form-name" value="piracy-form" />

                                <InputsForm handleSubmit={handleSubmit} />
                            </Form>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
};

export default PiracyPolicyPage;
