import { useConfig } from '@genflow/core';
import useCourse from '@genflow/web-courses/hooks/useCourse';
import { get, lowerCase, parseInt } from 'lodash';
import moment from 'moment';
import { useMemo } from 'react';

export default (apexAccess) => {
    const {
        vendorUuid,
        resourceLibraryProductID,
        agencyNavigatorCourseProductID,
        upcomingQACallsID,
    } = useConfig();

    const { data, loading } = useCourse(
        vendorUuid,
        resourceLibraryProductID,
        agencyNavigatorCourseProductID,
    );

    const upcomingQA = get(data, 'sections', []).find(({ id }) => parseInt(id) === upcomingQACallsID);

    const getHosts = index => upcomingQA?.lessons?.[index]?.assets?.sort(
        (a, b) => a?.order - b?.order,
    )?.map((asset) => {
        const metaData = !!asset?.meta_data && JSON.parse(asset?.meta_data);
        const images = [asset?.url, metaData?.image2, metaData?.image3, metaData?.image4]?.filter(Boolean);
        return {
            title: asset?.title,
            date: metaData?.dateInput,
            img: asset?.url,
            name: metaData?.speakerName?.[0],
            position: metaData?.displayName,
            description: asset?.description,
            link: metaData?.url,
            visible: metaData?.isLive,
            images,
            order: asset?.order,
        };
    });

    const hosts = [
        {
            title: 'Agency Navigator',
            id: 'agency-navigator',
            hosts: getHosts(0),
        },
        {
            title: 'APEX',
            id: 'apex',
            hosts: getHosts(1),
        },
    ];

    const currentDay = useMemo(() => moment.utc().format('dddd'), []);
    const todayQA = useMemo(() => {
        const days = hosts?.reduce((acc, item) => {
            if (item?.title === 'APEX' && !apexAccess) {
                acc.push([]);
            } else {
                const hostByType = item?.hosts?.map((hostItem) => {
                    const day = hostItem?.date?.split(' AT ');
                    const time = day?.[1]?.[0];
                    return {
                        ...hostItem,
                        id: item?.id,
                        time,
                        visible: hostItem?.visible,
                    };
                });
                if (hostByType?.length) {
                    acc.push(...hostByType);
                }
            }
            return acc;
        }, []);
        return days?.filter(({ date, visible }) => lowerCase(date)?.includes(lowerCase(currentDay)) && visible);
    }, [hosts]);

    return {
        loading,
        hosts,
        todayQA,
    };
};
