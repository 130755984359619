const rowLabels = [
    'revenue',
    'expenses',
    'netCashFlow',
    'dividendsReleased',
    'retainedEarnings',
    'openingBalance',
    'closingBalance',
];

export default rowLabels;
