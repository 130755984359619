import React from 'react';
import { LockClosedIcon } from '@heroicons/react/outline';
import useCompleteLesson from '@genflow/web-courses/hooks/useCompleteLesson';
import CourseProductLink from './CourseProductLink';
import useAccess from '../hooks/useAccess';


export default ({
    name,
    imageSrc,
    description,
    productID,
    comingSoon,
    brandSrc,
    extraStyling,
    invite,
    extraImgStyling,
    sections,
    progress,
    lesson,
    video,
    heroImg,
}) => {

    const [showModal, setShowModal] = React.useState(false);

    let access = false;

    const { loading, validAccess } = useAccess(productID);

    if (!loading) {
        console.log(validAccess);
        access = validAccess;
    }

    const totalLessons = sections
        .map(({ lessons }) => lessons.length)
        .reduce((acc, value) => acc + value, 0);
    const { totalCompletedLessons } = useCompleteLesson(productID);

    const progressPercentage = totalCompletedLessons * 100 / totalLessons;

    const applytoApex = () => {
        window.location.href = 'https://link.growyouragency.com/apex-apply';
        return null;
    };

    return (
        <>
            <CourseProductLink key={productID} productID={productID} brandSrc={brandSrc} invite={invite} access={access} onClick={setShowModal} progress={progress} currentSection={lesson} currentVideo={video}>
                <div
                    className={`rounded-xl relative courseWidth ${(invite || !access) && 'opacity-50'} opacity-on-hover bounce-on-hover`}
                >
                    <div className="cardheader">
                        <div className="relative">
                            <img
                                src={imageSrc}
                                alt="card"
                                className="rounded-xl"
                            />
                            <div className="body5 text-white absolute bottom-0 left-0 right-0 mx-auto w-full px-2 pb-2">
                                <div className="flex justify-center pb-12 px-6" style={extraStyling}>
                                    {
                                        (brandSrc) && (
                                            <img
                                                src={brandSrc}
                                                alt="brand"
                                                style={{ width: '315px' }}
                                                style={extraImgStyling}
                                            />
                                        )
                                    }
                                    {
                                        (!brandSrc) && (
                                            <div className="mt-6 whitespace-normal h-20 text-center font-bold text-2xl">{name}</div>
                                        )
                                    }
                                </div>
                                <div className="w-full grey600Bg h-2.5 bottom-0 overflow-hidden rounded-xl">
                                    <div className="bg-white h-full" style={{ width: `${progressPercentage}%` }} />
                                </div>
                            </div>
                            {(invite || !access) && (
                                <div className="w-28 bg-black text-white body5 absolute top-4 right-8 flex flex-row items-center justify-center py-2 rounded-md">
                                    <LockClosedIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                                    <div>Invite Only</div>
                                </div>
                            )}
                        </div>
                    </div>

                </div>
            </CourseProductLink>
            {showModal ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none whitespace-normal text-white"
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            {/* content */}
                            <div className="border-0 rounded-xl shadow-lg relative flex flex-col w-full bg-black outline-none focus:outline-none">
                                {/* header */}
                                <div className="flex items-start justify-between p-10 rounded-t">
                                    <h3 className="text-3xl font-semibold py-8">
                                        You don’t have access to this program!
                                    </h3>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                {/* body */}
                                <div className="relative p-10 flex-auto space-y-4">
                                    <p>
                                        This is an application-only program for Agency Owners doing $10,000+ a month who want to scale to $50k, $70k, $100k+ and use their agencies as a launch pad to get into 8-figures business models.
                                    </p>
                                    <p>
                                        Interested in joining? Schedule a call below with our APEX Student Integration Manager, Max, to apply for a spot.
                                    </p>
                                    <p>
                                        {/* <a href="https://link.growyouragency.com/apex-apply"><b>Click here to apply now</b></a> */}
                                        <button onClick={applytoApex} style={{ width: '155px', padding: '0px' }} className="buttonLgLight h-fit mt-7 hidden sm:flex">Apply Now</button>
                                    </p>
                                </div>
                                {/* footer */}
                                <div className="flex items-center justify-end p-10 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black" />
                </>
            ) : null}
        </>
    );
};
