import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import { Link } from 'react-router-dom';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        available: true,
        sectionName: 'Scaling to a proper campaign',
    },
    {
        available: true,
        sectionName: 'What to do next',
    },
    {
        available: true,
        sectionName: 'Action step',
    },
];

const title = 'How To Test Audiences and Find Winners';

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]}  className="plug-block">  
                        <h2 className="mb-4">{title}</h2>
                            <div className="plug-block-subheading">Scaling to a proper campaign</div>

                            <p>
                                Once your ad has been approved it is time to launch more angles. Yyou can launch the rest of your angles one by one waiting for each one to be approved, if any of those angles has been approved in the past, then there is no need to launch that one at a time and you can launch it with others that have been approved. You only need to do one-by-one with ads that have never run in the past.
                            </p>

                            <p>
                                Because we're doing this with one of our own ad accounts as an example we won't be writing from all different angles so just assume that we went in and changed the copy, etc.
                            </p>

                            <p>
                                As for the ad sets we also have ready before hand the interests or lookalikes and others we want to try.
                            </p>

                            <p>
                                To keep the video concise, we will be showing how we do this. After duplicating the ads, we would go ahead and change the copy and creative.
                            </p>

                            <p>
                                Now that ad set has all the angles inside, then we duplicate that ad set and put all the audiences we're testing.
                            </p>

                            <div style={{ height: 350 }}>
                                <iframe
                                    id="videoPlayer"
                                    className="sproutvideo-player"
                                    src="https://videos.sproutvideo.com/embed/119dd8b61613e7ce98/650ea2b80a9b4072?playerTheme=dark&amp;playerColor=263238"
                                    frameBorder="0"
                                    allowFullScreen
                                    width="100%"
                                    height="100%"
                                />
                            </div>
                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">
                                What to do next
                            </div>

                            <p>
                                To know what to do next after these ads have been running for a while we made the next module called
                                {' '}
                                <Link to="/resources/plug-and-play/load-balancing-budgets">'Load Balancing Budgets' </Link>
                                {' '}
                                where we explain how long to let them run, when to kill them and what to do with the winners. If you have done this successfully your next step will be going to the Bonus section to the horizontal and vertical scaling modules.
                            </p>
                        </div>

                        <div ref={refs[2]} className="plug-block">
                            <div className="plug-block-subheading">
                                Action step
                            </div>

                            <p>Replicate what was shown on the video with your own clients or the test campaigns you've been creating so far. You can turn off the ads so they don't spend any money!</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
