import axios from 'axios';
import config from '../config';
import env from '../env';

export async function getSignedUrl(fileName, token) {
    const url = `${config[env].mediaContentUpload}upload-link/${fileName}`;
    return axios.get(url, {
        headers: {
            Authorization: token,
        },
    });
}

export const uploadImage = async (file, token) => {
    const contentType = file.type;
    let data = '';
    try {
        const response = await getSignedUrl(file.name, token);
        data = response?.data;
        await axios.put(data?.uploadLink, file, { headers: { 'Content-Type': contentType } });
    } catch (error) {
        console.log(`error saving with signed url: ${error}`);
    }

    console.log('data2', data);
    return { fileUrl: data?.imageLink };
};

export default { uploadImage };
