import React from 'react';
import { Button, Spinner } from 'reactstrap';
import classnames from 'classnames';

export default ({
    children, loading, disabled, onClick, ...rest
}) => (
    <Button {...rest} disabled={loading || disabled} onClick={!loading && onClick}>
        {!(!loading) && (
            <Spinner
                className={classnames({
                    'position-relative': true,
                    'button-style': false,
                    visible: loading,
                    invisible: !loading,
                })}
                size="sm"
                // type="grow"
            />
        )}
        {!(loading) && (
            <span
                className={classnames({
                    invisible: loading,
                    visible: !loading,
                    'span-style': false,
                })}
            >
                {children}
            </span>
        )}
    </Button>
);
