import React, { useContext, useEffect, useRef, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { Input, Spinner } from 'reactstrap';
import CurrencyInput from 'react-currency-input-field';
import moment from 'moment';
import { get, parseInt } from 'lodash';
import { OUTREACH_TRACKER_DATA_LABEL } from '../../../../../../../../constants/outreachTracker';
import styles from './styles';
import withOutreachColumn from './withOutreachColumn';
import { SummaryContext } from '../../../../providers/SummaryProvider';

const OutreachItem = withStyles(styles)(({
    classes, id, displayLabel, value, onClickSave, currency, isCurrencyValue = false, isEditable = true,
}) => {
    const isNew = !id;
    const [inputRef, setInputRef] = useState(null);
    const [itemSaveLoading, setItemSaveLoading] = useState(false);
    const [editedValue, setEditedValue] = useState(value);
    const [showDeleteItem, setShowDeleteItem] = useState(false);

    useEffect(() => { setEditedValue(value); }, [value]);

    // console.log('displayLabel, editedValue', displayLabel, editedValue);

    useEffect(() => {
        if (inputRef) {
            inputRef.focus();
        } else {
            setItemSaveLoading(false);
        }
    }, [inputRef]);

    const createOrSaveFinanceItem = () => {
        if (!!displayLabel && !!editedValue) {
            setItemSaveLoading(true);
            onClickSave(displayLabel, editedValue);
        } else {
            alert('You must enter a item name and value');
        }
    };

    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            createOrSaveFinanceItem();
        }
    };

    const onInputFocus = () => !isNew && setShowDeleteItem(true);
    const onInputBlur = () => {
        if (!isNew)setTimeout(() => setShowDeleteItem(false), 250);
        updateOnBlur();
    };

    const updateOnBlur = () => {
        if (editedValue !== value) {
            createOrSaveFinanceItem();
        }
    };


    return (
        <div
            className={classes.financeItemContainer}
            style={
                isNew ? {
                    // backgroundColor: '#e2e2e2',
                    borderColor: value !== editedValue ? 'rgba(24,130,222,0.44)' : '#e2e2e2',
                    borderWidth: value !== editedValue ? 2 : 0,
                    borderStyle: 'solid',
                    borderRadius: '0.25rem',
                    marginBottom: '1rem',
                    padding: '0.5rem',
                    alignItem: 'end'
                } : {
                    marginBottom: '1rem',
                }
            }
        >
            <div style={{
                flex: 1,
            }}
            >
                {displayLabel}
            </div>

            {
                isCurrencyValue
                    ? (
                        <CurrencyInput
                            key={currency}
                            className="form-control inputBox pl-2 w-32 ml-auto"
                            prefix={currency}
                            placeholder={`${currency}1,000`}
                            decimalsLimit={2}
                            onValueChange={val => setEditedValue(val)}
                            style={{ textAlign: 'right', flex: 1, borderBottomColor: 'white', marginBottom: '0px' }}
                            value={editedValue}
                            onKeyDown={onKeyDown}
                            onFocus={onInputFocus}
                            onBlur={onInputBlur}
                        />
                    )
                    : (
                        isEditable
                            ? (
                                <Input
                                    type="number"
                                    className="form-control inputBox pl-2 w-32 ml-auto"
                                    placeholder="0"
                                    onChange={e => setEditedValue(e.target.value)}
                                    style={{  textAlign: 'right', flex: 1, borderBottomColor: 'white', marginBottom: '0px' }}
                                    value={editedValue}
                                    onKeyDown={onKeyDown}
                                    onFocus={onInputFocus}
                                    onBlur={onInputBlur}
                                />
                            )
                            : (
                                <div>
                                    {editedValue}
                                </div>
                            )
                    )
            }

            {
                itemSaveLoading && (
                    <Spinner
                        color="primary"
                        type="grow"
                        style={{ marginLeft: 10 }}
                    />
                )
            }
        </div>
    );
});

const Index = ({
    columnName,
    columnKey,
    outreachItemIndex,
    title, classes, month, updateData, deleteRow, currency,
}) => {
    const {
        ENABLED_OUTREACH_COLUMN_FIELDS, updateOutreachItem, updateFinanceItemTitle, data,
    } = useContext(SummaryContext);

    const filteredOutreachColumnFields = ENABLED_OUTREACH_COLUMN_FIELDS?.filter(item => item?.dataLabel !== OUTREACH_TRACKER_DATA_LABEL);
    const outreachColumnTitle = ENABLED_OUTREACH_COLUMN_FIELDS?.filter(item => item?.dataLabel === OUTREACH_TRACKER_DATA_LABEL)?.[0];

    const thisMonthData = data.find(({ startDateTime }) => {
        const thisMonthName = moment().utc().month(month).format('MMM');
        const summaryMonthStartName = moment(startDateTime).utc().format('MMM');
        return (thisMonthName === summaryMonthStartName);
    });

    const [newItem, setNewItem] = useState(null);

    const monthSummaryJSON = JSON.parse(thisMonthData?.summaryJSON || '{}');

    // console.log('your monthSummaryJSON', monthSummaryJSON);
    const outreachColumns = monthSummaryJSON?.outreachColumns || [];
    const outreachColumn = outreachColumns.find(({ columnKey: ck }) => columnKey === ck);

    const outreachTitle = outreachColumn?.[outreachColumnTitle?.dataLabel];

    const [editableTitle, setEditableTitle] = useState(outreachTitle || columnName);
    const onTitleKeyDown = (e) => {
        if (e.key === 'Enter') {
            updateOutreachColumn();
            e.target.blur();
        }
    };

    const onTitleInputBlur = () => {
        if (outreachTitle !== editableTitle) {
            updateOutreachColumn();
        }
    };

    const updateOutreachColumn = () => {
        if (!editableTitle?.replace(/\s+/g, '')?.length) {
            alert('You must enter a value');
            setEditableTitle(outreachTitle || columnName);
            return;
        }
        updateOutreachItem({
            monthName: month,
            outreachItemIndex,
            columnKey,
            fieldKey: outreachColumnTitle?.dataLabel,
            value: editableTitle,
        });
    };

    return (
        <Paper className={classes.root}>
            <div
                style={
                    {
                        padding: '0.5rem',
                        textAlign: 'center',
                        color: 'white',
                        fontWeight: 'bold',
                        borderTopLeftRadius: '0.25rem',
                        borderTopRightRadius: '0.25rem',
                        backgroundColor: '#000',
                        display: 'flex',
                        justifyContent: 'center',
                    }
                }
            >
                <Input
                    type="text"
                    className="bg-black flex justify-center text-center p-2 w-full capitalize"
                    onChange={e => setEditableTitle(e.target.value)}
                    value={editableTitle}
                    onKeyDown={onTitleKeyDown}
                    onBlur={onTitleInputBlur}
                    required
                />
            </div>
            <div style={{ padding: '0.5rem' }}>

                {
                    filteredOutreachColumnFields.map(({ displayLabel: fieldLabel, dataLabel: fieldKey, isCurrencyValue }, index) => (
                        <OutreachItem
                            key={`${fieldLabel}-${outreachColumn?.[fieldKey]}`}
                            updateState={setNewItem}
                            displayLabel={fieldLabel}
                            value={outreachColumn?.[fieldKey]}
                            isCurrencyValue={isCurrencyValue}
                            currency={currency}
                            onClickSave={(name, value) => updateOutreachItem({
                                monthName: month,
                                outreachItemIndex,
                                columnKey,
                                fieldKey,
                                value: parseInt(value),
                                onComplete: () => {
                                    console.log('new item should be cleared');
                                    setNewItem(null);
                                },
                            })}
                        />
                    ))
                }

                <OutreachItem
                    key={`${columnKey}-attemptsToMeetings`}
                    updateState={setNewItem}
                    displayLabel="Attempts to Meetings"
                    isEditable={false}
                    value={`${
                        ((get(outreachColumn, 'meetingsSet', 0) * 100
                            / get(outreachColumn, 'attempts', 0)) || 0).toFixed(0)
                    }%`}
                />

                <OutreachItem
                    key={`${columnKey}-meetingsToClients`}
                    updateState={setNewItem}
                    displayLabel="Meetings to Clients"
                    isEditable={false}
                    value={`${
                        ((get(outreachColumn, 'clientsClosed', 0) * 100
                            / get(outreachColumn, 'meetingsSet', 0)) || 0).toFixed(0)
                    }%`}
                />

                {/* { */}
                {/*    columnFinanceItems.map(financeItem => ( */}
                {/*        <OutreachItem */}
                {/*            key={`${financeItem?.id}-${financeItem?.name}-${financeItem?.value}`} */}
                {/*            id={financeItem?.id} */}
                {/*            name={financeItem?.name} */}
                {/*            value={financeItem?.value} */}
                {/*            // updateState={setNewItem} */}
                {/*            onClickSave={(name, value, deleteItem = false) => updateOutreachItem({ */}
                {/*                id: financeItem.id, // need id to update */}
                {/*                monthName: month, */}
                {/*                outreachItemIndex, */}
                {/*                financeItemTitle: displayLabel, */}
                {/*                name, */}
                {/*                value, */}
                {/*                deleteItem, */}
                {/*                onComplete: () => { */}
                {/*                    // console.log('new item should be cleared'); */}
                {/*                    // setNewItem(null); */}
                {/*                }, */}
                {/*            })} */}
                {/*            currency={currency} */}
                {/*        /> */}
                {/*    )) */}
                {/* } */}
                {/* <Table */}
                {/*    data={[...data, { */}
                {/*        item: '', */}
                {/*        amount: '', */}
                {/*    }]} */}
                {/*    header */}
                {/*    year={year} */}
                {/*    month={month} */}
                {/*    col="revenue" */}
                {/*    updateData={updateData} */}
                {/*    deleteRow={deleteRow} */}
                {/*    currency={currency} */}
                {/* /> */}
            </div>
        </Paper>
    );
};

export default withOutreachColumn(withStyles(styles)(Index));
