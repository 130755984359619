import React, { useContext, useEffect, useState } from 'react';

import moment from 'moment';
import { fill, parseInt } from 'lodash';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { v1 as uuidv1 } from 'uuid';
import rowLabels from './rowLabels';
import GetCalendarEntriesQuery from '../../../../api/getCalendarEntries';
import CreateUpdateCalendarEntryMutation from '../../../../api/createUpdateCalendarEntry';
import { SummaryProvider } from './providers/SummaryProvider';
import { UserDataContext } from '../../../../providers/UserDataProvider';

const withFinancialPlanner = Component => (props) => {
    const [year, setYear] = useState(moment().format('YYYY'));
    const [anchorEl, setAnchorEl] = useState(undefined);

    const [selected, setSelected] = useState(1);
    const { userCurrency } = useContext(UserDataContext);

    const {
        data: calendarResponseData,
        error: calendarEntriesError,
        loading: calendarEntriesLoading,
    } = useQuery(GetCalendarEntriesQuery, {
        variables: {
            entryType: 'summary',
        },
    });


    const [createUpdateCalendarEntry] = useMutation(CreateUpdateCalendarEntryMutation, {
        // update(cache, { data: { createUpdateCalendarEntry: { data: createUpdateResponseData } } }) {
        //     const readResults = cache.readQuery({
        //         query: GetCalendarEntriesQuery,
        //         variables: {},
        //     });
        //
        //     const updatedEntry = {
        //         getCalendarEntries: {
        //             ...readResults.getCalendarEntries,
        //             data: uniqWith([
        //                 createUpdateResponseData,
        //                 ...readResults.getCalendarEntries.data,
        //             ], ({ PK, SK }, { PK: PK2, SK: SK2 }) => PK === PK2 && SK === SK2),
        //         },
        //     };
        //
        //     cache.writeQuery({
        //         query: GetCalendarEntriesQuery,
        //         data: {
        //             ...updatedEntry,
        //         },
        //     });
        // },
        awaitRefetchQueries: true,
        refetchQueries: [{
            query: GetCalendarEntriesQuery,
            variables: {
                entryType: 'summary',
            },
        }],
    });

    // financeIndex, defaultTitle
    // 0, revenue
    // 1, software
    // 2, staff
    // 3, other
    const updateSummary = (monthName, summaryData) => {
        const currentSelectedMonth = moment.months()[selected - 2];
        const utcMonth = moment().utc().month(currentSelectedMonth);
        const startDateTime = utcMonth.startOf('month').toISOString();
        const endDateTime = utcMonth.endOf('month').toISOString();
        const variables = {
            startDateTime,
            endDateTime,
            entryType: 'summary',
            title: monthName.toLowerCase(),
            summaryJSON: JSON.stringify(summaryData),
            // colour: eventToSave.colour,
            // allDay: eventToSave?.allDay || false,
            // complete: eventToSave?.complete || false,
        };
        console.log(`updating summary for ${monthName}`, variables);
        // createUpdateCalendarEntry({
        //     variables: {
        //         startDateTime: eventToSave.startDateTime,
        //         endDateTime: eventToSave.endDateTime,
        //         entryType: eventToSave.entryType,
        //         title: eventToSave.title,
        //         colour: eventToSave.colour,
        //         allDay: eventToSave?.allDay || false,
        //         complete: eventToSave?.complete || false,
        //     },
        // }).then(() => {
        //     console.log('yo')
        // });
    };

    const calendar = calendarResponseData?.getCalendarEntries?.calendar || {};
    const calendarSummaries = (calendarResponseData?.getCalendarEntries?.data || []).map(({ startDateTime, endDateTime, ...rest }) => ({
        ...rest,
        startDateTime: new Date(startDateTime),
        endDateTime: new Date(endDateTime),
    }));

    const updateFinanceItemTitle = (monthName, financeItemIndex, financeItemTitle, onComplete) => {
        const currentSelectedMonth = moment.months()[selected - 2];
        const currentSelectedMonthUTC = moment().utc().month(currentSelectedMonth);
        currentSelectedMonthUTC.set({ year });
        const startDateTime = currentSelectedMonthUTC.startOf('month').toISOString();
        const endDateTime = currentSelectedMonthUTC.endOf('month').startOf('day').toISOString();


        const monthToUpdateData = calendarSummaries
            .find(({ startDateTime }) => {
                const thisMonthName = moment().utc().month(monthName).format('MMM');

                const summaryMonthStartName = moment.utc(startDateTime).format('MMM');
                const summaryMonthStartYear = moment.utc(startDateTime).format('YYYY');

                return (thisMonthName === summaryMonthStartName)
                    && parseInt(year) === parseInt(summaryMonthStartYear);
            });
        const columnFinanceItems = JSON.parse(monthToUpdateData?.summaryJSON || '{}')?.financeItems;

        const getOrCreateFinanceItems = columnFinanceItems?.length > 0
            ? columnFinanceItems
            : fill(Array(4), { title: '', items: [] });


        const summaryJSON = JSON.stringify({
            financeItems: [
                ...getOrCreateFinanceItems.map((financeItem, index) => {
                    if (index === financeItemIndex) {
                        return {
                            title: financeItemTitle,
                            items: [
                                ...(financeItem?.items : []),
                            ],
                        };
                    }

                    return financeItem;
                }),
            ],
        });

        delete monthToUpdateData?.__typename;
        const entry = monthToUpdateData
            ? {
                ...monthToUpdateData,
                summaryJSON,
                startDateTime: moment.utc(monthToUpdateData?.startDateTime),
                endDateTime: moment.utc(monthToUpdateData?.endDateTime),
            }
            : {
                startDateTime: moment.utc(startDateTime),
                endDateTime: moment.utc(endDateTime),
                entryType: 'summary',
                title: `${monthName.toLowerCase()} ${year}`,
                summaryJSON,
            };

        console.log('entry', entry);
        createUpdateCalendarEntry({
            variables: { entry },
        }).then(onComplete);
    };

    const addOrUpdateFinanceItem = ({
        id = false, monthName, financeItemIndex, financeItemTitle, name, value, deleteItem = false, onComplete,
    }) => {
        const currentSelectedMonth = moment.months()[selected - 2];
        const currentSelectedMonthUTC = moment().utc().month(currentSelectedMonth);
        currentSelectedMonthUTC.set({ year });
        const startDateTime = currentSelectedMonthUTC.startOf('month').toISOString();
        const endDateTime = currentSelectedMonthUTC.endOf('month').startOf('day').toISOString();


        const monthToUpdateData = calendarSummaries
            .find(({ startDateTime }) => {
                const thisMonthName = moment().utc().month(monthName).format('MMM');

                const summaryMonthStartName = moment.utc(startDateTime).format('MMM');
                const summaryMonthStartYear = moment.utc(startDateTime).format('YYYY');

                return (thisMonthName === summaryMonthStartName)
                    && parseInt(year) === parseInt(summaryMonthStartYear);
            });
        const columnFinanceItems = JSON.parse(monthToUpdateData?.summaryJSON || '{}')?.financeItems;

        const getOrCreateFinanceItems = columnFinanceItems?.length > 0
            ? columnFinanceItems
            : fill(Array(4), { title: '', items: [] });


        console.log('updating', {
            id, monthName, financeItemIndex, financeItemTitle, name, value,
        });
        const summaryJSON = JSON.stringify({
            financeItems: [
                ...getOrCreateFinanceItems.map((financeItem, index) => {
                    if (index === financeItemIndex) {
                        let items = [
                            ...(financeItem?.items : []),
                        ];

                        if (id) {
                            // update existing item
                            items = items.map((item) => {
                                if (item?.id === id) {
                                    if (deleteItem) {
                                        return null;
                                    }
                                    return {
                                        ...item,
                                        name,
                                        value: parseFloat(value),
                                    };
                                }
                                return item;
                            }).filter(Boolean);
                        } else {
                            // add new item
                            items = [
                                ...items,
                                {
                                    id: uuidv1(),
                                    name,
                                    value: parseFloat(value),
                                },
                            ];
                        }
                        return {
                            title: financeItemTitle,
                            items,
                        };
                    }

                    return financeItem;
                }),
            ],
        });

        console.log('your updated summaryJSON');
        console.log(JSON.parse(summaryJSON));

        delete monthToUpdateData?.__typename;
        const entry = monthToUpdateData
            ? {
                ...monthToUpdateData,
                summaryJSON,
                startDateTime: moment.utc(monthToUpdateData?.startDateTime),
                endDateTime: moment.utc(monthToUpdateData?.endDateTime),
            }
            : {
                startDateTime: moment.utc(startDateTime),
                endDateTime: moment.utc(endDateTime),
                entryType: 'summary',
                title: `${monthName.toLowerCase()} ${year}`,
                summaryJSON,
            };

        console.log('entry', entry);
        createUpdateCalendarEntry({
            variables: { entry },
        }).then(onComplete);
    };

    const addSummaryMetadata = (monthName, updatedSummaryMetadata, onComplete) => {
        const currentSelectedMonth = moment.months()[selected - 2];
        const currentSelectedMonthUTC = moment().utc().month(currentSelectedMonth);
        currentSelectedMonthUTC.set({ year });
        const startDateTime = currentSelectedMonthUTC.startOf('month').toISOString();
        const endDateTime = currentSelectedMonthUTC.endOf('month').startOf('day').toISOString();

        const monthToUpdateData = calendarSummaries
            .find(({ startDateTime }) => {
                const thisMonthName = moment().utc().month(monthName).format('MMM');

                const summaryMonthStartName = moment(startDateTime).format('MMM');
                const summaryMonthStartYear = moment(startDateTime).format('YYYY');

                return (thisMonthName === summaryMonthStartName)
                    && parseInt(year) === parseInt(summaryMonthStartYear);
            });
        const thisMonthSummaryJSON = JSON.parse(monthToUpdateData?.summaryJSON || '{}');


        const summaryJSON = JSON.stringify({
            ...thisMonthSummaryJSON,
            ...updatedSummaryMetadata,
        });

        delete monthToUpdateData?.__typename;
        const entry = monthToUpdateData
            ? {
                ...monthToUpdateData,
                summaryJSON,
            }
            : {
                startDateTime,
                endDateTime,
                entryType: 'summary',
                title: `${monthName.toLowerCase()} ${year}`,
                summaryJSON,
            };

        createUpdateCalendarEntry({
            variables: { entry },
        }).then(onComplete);
    };


    const years = [2021, 2022, 2023, 2024, 2025];
    const labels = [
        <span>
            <UnfoldMoreIcon />
            {' '}
            {year}
        </span>,
        'Year Summary',
        ...moment.months(),
    ];


    const handleSelect = (e, newValue) => (newValue > 0
        ? setSelected(newValue)
        : setAnchorEl(e.currentTarget));

    useEffect(() => {
        setTimeout(() => {
            document.documentElement.scrollTop = 0;
            document.scrollingElement.scrollTop = 0;
        }, 1);
    }, [selected]);

    const onMenuClose = () => setAnchorEl(undefined);

    const selectYear = (newYear) => {
        setYear(newYear);
        onMenuClose();
    };


    const cashFlowProps = {
        labels,
        summaryData: calendarSummaries,
        year,
        selected,
        handleSelect,
        anchorEl,
        onMenuClose,
        years,
        selectYear,
        rowLabels,
        updateSummary,
        addOrUpdateFinanceItem,
        addSummaryMetadata,
        updateFinanceItemTitle,
        currency: userCurrency,
        loading: calendarEntriesLoading,
        ...props,
    };

    return (
        <SummaryProvider config={{
            updateSummary,
            addOrUpdateFinanceItem,
            updateFinanceItemTitle,
            addSummaryMetadata,
            data: calendarSummaries.filter((summaryItem) => {
                // Filter data by year
                const { startDateTime } = summaryItem;
                const startYear = moment(startDateTime).utc().year();
                return startYear === parseInt(year);
            }),
            year,
        }}
        >
            <Component {...cashFlowProps} />
        </SummaryProvider>
    );
};

export default withFinancialPlanner;
