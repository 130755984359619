import React from 'react';
import './style.scss';
import Container from 'reactstrap/lib/Container';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';

const sections = [
    {
        available: true, sectionName: 'Step 1: Set Up Business Manager',
    },
    {
        available: true, sectionName: 'Step 2: Create Business Page',
    },
    {
        available: true, sectionName: 'Step 3: Add The New Business Page To Business Manager',
    },
    {
        available: true, sectionName: 'Step 4: Create 3 Ad Accounts',
    },
    {
        available: true, sectionName: 'Step 5: Create A Facebook Pixel',
    },
];

const title = "FB Business & Ad Manager"

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                // selectedSection={selectedSection}
                                // setSelectedSection={onSelectSection}
                                // filter={filter}
                                // selectFilter={selectFilter}
                                // subFilter={subFilter}
                                // selectSubFilter={selectSubFilter}

                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div className="plug-block">  
                        <h2 className="mb-4">{title}</h2>

                            <p>
                                We need to create a business Facebook page for your agency as well as Business Manager for you to hold client ad accounts in, their pages in & their pixels.
                            </p>

                            <p>
                                You’ll also need a business manager, ad account & pixel for when you run cheap $5 a day ads later.
                            </p>
                        </div>

                        <div ref={refs[0]} className="plug-block">
                            <div className="plug-block-subheading">
                                Step 1: Set Up Business Manager
                            </div>

                            <p>
                                Sign up to Facebook Business manager. Click
                                <a href="https://business.facebook.com/" target="_blank" rel="noopener noreferrer">HERE</a>
                            </p>
                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">Step 2: Create Business Page </div>

                            <p>
                                Now you need to create a business page for your agency. To see a full step by step blog post that shows you how to do it -
                                {' '}
                                <a href="https://blog.hootsuite.com/steps-to-create-a-facebook-business-page/" target="_blank" rel="noopener noreferrer">Click HERE</a>
                            </p>

                        </div>
                        <div ref={refs[2]} className="plug-block">
                            <div className="plug-block-subheading">
                                Step 3: Add The New Business Page To Business Manager
                            </div>

                            <p>
                                Now you need to link your business manager to your business page in order to be able to run ads from that page’s name.
                            </p>

                            <p>
                                Here’s how you do it:
                            </p>

                            <ol>
                                <li>
                                    Go to
                                    {' '}
                                    <a href="https://business.facebook.com/settings" target="_blank" rel="noopener noreferrer">Business Settings</a>
                                    .
                                </li>
                                <li>
                                    Below Accounts, click Pages.

                                </li>
                                <li>
                                    Click + Add.
                                </li>
                                <li>
                                    Select Add a Page.

                                </li>
                                <li>
                                    Enter the Facebook Page name or URL.

                                </li>
                            </ol>

                        </div>

                        <div className="plug-block">
                            <div className="plug-block-subheading">
                                Step 4: Create 3 Ad Accounts
                            </div>

                            <p>
                                We need to create an ad account for ourselves and then create 2 backup ones too. In order to be able to create the 2 backup ones, Facebook has to first charge your card successfully at least once. So, after you run your first ad, you will be able to create the 2 backup ones.
                            </p>

                            <p>
                                Here’s how you do it:
                            </p>

                            <ol>
                                <li>
                                    Go to
                                    {' '}
                                    <a href="https://business.facebook.com/settings" target="_blank" rel="noopener noreferrer">Business Settings</a>
                                    .
                                </li>
                                <li>
                                    Click Accounts and click Ad Accounts.
                                </li>
                                <li>
                                    Click + Add.
                                </li>
                                <li>
                                    Choose Create a new ad account.
                                </li>
                                <li>
                                    Repeat this process two times after Facebook has successfully charged your card once, to give you a total of 3 ad accounts (2 backups).
                                </li>
                            </ol>
                        </div>

                        <div ref={refs[4]} className="plug-block">
                            <div className="plug-block-subheading">
                                Step 5: Create A Facebook Pixel
                            </div>

                            <ol>
                                <li>
                                    Go to your
                                    <a href="https://www.facebook.com/ads/manager/pixel/facebook_pixel" target="_blank" rel="noopener noreferrer">Pixels</a>
                                    {' '}
                                    tab in Events Manager.
                                </li>
                                <li>
                                    Make sure you have your Business Manager selected. Sometimes Facebook automatically select your personal profile - which is not the one we want. You can double check that by clicking in your picture on the left portion of the screen and making sure the right Business Manager is selected.
                                </li>
                                <li>
                                    Click Create a pixel.
                                </li>
                            </ol>

                            <p>
                                You don’t need to setup or install the pixel for now. Just leave it there and we’ll install it on the website later down the line.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
