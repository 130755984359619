export const TRACKABLES = [
    {
        label: 'Login',
        value: 'login',
    },
    {
        label: 'Signup',
        value: 'register',
    },
    {
        label: 'Home',
        value: 'programs',
    },
    {
        label: 'Home',
        value: 'programs',
    },
    {
        label: 'Tools',
        value: 'tools',
    },
    {
        label: 'Upcoming Q&A',
        value: 'upcoming-q-a',
    },
    {
        label: 'Q&A Recordings',
        value: 'qa',
    },
    {
        label: 'Admin',
        value: 'Admin',
    },
    {
        label: 'Plug & Play',
        value: 'plug-and-play',
    },
    {
        label: 'Profile',
        value: 'Profile',
    },
];


export default {
    TRACKABLES,
};
