import gql from 'graphql-tag';

export default gql`
    query ($entryType: String){
        getCalendarEntries (entryType: $entryType) {
            calendar {
                PK
                SK
                type
                startDateTime
            }

            data {
                PK
                SK
                startDateTime
                endDateTime
                calendarName
                entryType
                type
                title
                allDay
                complete
                colour
                summaryJSON
            }
            error
        }
    }
`;
