import React, { useState, useEffect, createContext } from 'react';
import moment from 'moment';
import { parseInt, flatten } from 'lodash';

const SummaryContext = createContext(null);
const { Provider, Consumer: SummaryConsumer } = SummaryContext;

const SummaryProvider = ({ children, config }) => {
    const [store, setStore] = useState({ ...config, update: null });

    useEffect(() => {
        // console.log('store here', store);
    }, [store]);

    // Updater
    const update = (key, data) => {
        // console.log('Update called, updating store: ', key, data);
        setStore(prevState => ({
            ...prevState,
            [key]: data,
        }));
    };

    // When config changes, re-initilise
    useEffect(() => {
        setStore({ ...config, update });
        // console.log('Configured store');
    }, [config]);

    const getSummaryData = (month = null) => {
        const isYearSummary = !month;
        const { data, year } = store;

        const timeRangeSummaryItems = isYearSummary
            ? data.filter(({ startDateTime }) => {
                const summaryMonthStartYear = moment(startDateTime).utc().format('YYYY');
                return parseInt(year) === parseInt(summaryMonthStartYear);
            })?.filter((item, index, arr) => arr?.map(arrItem => arrItem?.title)?.indexOf(item?.title) === index)
            : [data.find(({ startDateTime }) => {
                const thisMonthName = moment().utc().month(month).format('MMM');
                const summaryMonthStartName = moment(startDateTime).utc().format('MMM');
                const summaryMonthStartYear = moment(startDateTime).utc().format('YYYY');
                return (thisMonthName === summaryMonthStartName)
                    && parseInt(year) === parseInt(summaryMonthStartYear);
            })];


        const columnFinanceDatas = timeRangeSummaryItems.map(timeRangeSummary => JSON.parse(timeRangeSummary?.summaryJSON || '{}'));

        const columnFinanceItems = columnFinanceDatas.map(columnFinanceData => columnFinanceData?.financeItems || []);

        const columnDividendReleaseValues = columnFinanceDatas.map(columnFinanceData => columnFinanceData?.dividendsReleased || 0);
        const columnOpeningBalanceValues = columnFinanceDatas.map(columnFinanceData => columnFinanceData?.openingBalance || 0);
        const columnClosingBalanceValues = columnFinanceDatas.map(columnFinanceData => columnFinanceData?.closingBalance || 0);

        // Assumption of 4 finance items where the 1st is revenue and the other 3 are expsnes
        const revenueColumnFinanceData = flatten(columnFinanceItems.map(columnFinanceItem => columnFinanceItem.slice(0, 1)));
        const expensesColumnFinanceData = flatten(columnFinanceItems.map(columnFinanceItem => columnFinanceItem.slice(1, 4)));

        const totalRevenue = flatten(revenueColumnFinanceData.map(({ items }) => items))
            .map(({ value }) => value)
            .reduce((acc, value) => acc + parseFloat(value || 0), 0) || 0;

        const totalExpenses = flatten(expensesColumnFinanceData.map(({ items }) => items))
            .map(({ value }) => value)
            .reduce((acc, value) => acc + parseFloat(value || 0), 0) || 0;

        const netCashFlow = totalRevenue - totalExpenses;
        const dividendsReleased = columnDividendReleaseValues.reduce((acc, value) => acc + parseFloat(value || 0), 0) || 0;
        const retainedEarnings = netCashFlow - dividendsReleased;
        const openingBalance = columnOpeningBalanceValues.reduce((acc, value) => acc + parseFloat(value || 0), 0) || 0;
        const closingBalance = columnClosingBalanceValues.reduce((acc, value) => acc + parseFloat(value || 0), 0) || 0;


        return {
            totalRevenue,
            totalExpenses,
            netCashFlow,
            dividendsReleased,
            retainedEarnings,
            openingBalance,
            closingBalance,
        };
    };

    return (
        <Provider value={{ ...store, getSummaryData }}>
            {children}
        </Provider>
    );
};


export {
    SummaryContext,
    SummaryProvider,
    SummaryConsumer,
};
