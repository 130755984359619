import React, { useState, useEffect, createContext } from 'react';

const TaskContext = createContext(null);
const { Provider, Consumer: TaskConsumer } = TaskContext;

const TaskProvider = ({ children, config }) => {
    const [store, setStore] = useState({ ...config, update: null });

    useEffect(() => {
        // console.log(store);
    }, [store]);

    // Updater
    const update = (key, data) => {
        console.log('Update called, updating store: ', key, data);
        setStore(prevState => ({
            ...prevState,
            [key]: data,
        }));
    };

    // When config changes, re-initilise
    useEffect(() => {
        setStore({ ...config, update });
        // console.log('Configured store');
    }, [config]);

    return (
        <Provider value={store}>
            {children}
        </Provider>
    );
};


export {
    TaskContext,
    TaskProvider,
    TaskConsumer,
};
