import React from 'react';
import { Link } from 'react-router-dom';
import {
    NavItem,
    NavLink,
    Nav,
    Container,
    Row,
    Col,
} from 'reactstrap';
import brand from './brand.svg';

export default () => (
    <div className="footerBorder mt-12">
        <footer className="w-10/12 mx-auto py-8 footer">
            <div className="flex flex-row justify-between">
                <div className="flex flex-row items-center">
                    <Link to="/">
                        <img
                            alt="gya"
                            src={brand}
                        />
                    </Link>
                    <a className="text-white body5 ml-2" href="mailto:support@growyouragency.com">Support</a>
                </div>
                <div className="body5 text-white flex items-center">
                    Copyright   © Grow Your Agency
                    {' '}
                    {new Date().getFullYear()}
                    {' '}
                </div>
            </div>        
        </footer>
    </div>
);
