import React from 'react';
import './style.scss';
import Container from 'reactstrap/lib/Container';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';

import image1 from '../../../../../assets/img/plugandplay/gyms/1.png';
import image2 from '../../../../../assets/img/plugandplay/gyms/2.png';
import image3 from '../../../../../assets/img/plugandplay/gyms/3.png';
import image4 from '../../../../../assets/img/plugandplay/gyms/4.png';
import image5 from '../../../../../assets/img/plugandplay/gyms/5.png';
import image6 from '../../../../../assets/img/plugandplay/gyms/6.png';
import image7 from '../../../../../assets/img/plugandplay/gyms/7.png';
import image8 from '../../../../../assets/img/plugandplay/gyms/8.png';
import image9 from '../../../../../assets/img/plugandplay/gyms/9.png';
import image10 from '../../../../../assets/img/plugandplay/gyms/10.png';
import image11 from '../../../../../assets/img/plugandplay/gyms/11.png';
import image12 from '../../../../../assets/img/plugandplay/gyms/12.png';
import image13 from '../../../../../assets/img/plugandplay/gyms/13.png';

const sections = [
    {
        available: true,
        sectionName: 'Offer ideas for local gyms',
    },
    {
        available: true,
        sectionName: 'Targeting',
    },
];

const title = "Industry Specific: gyms"
export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div className="plug-block">  
                        <h2 className="mb-4">{title}</h2>
                            <div className="plug-block-subheading">In today's teaching</div>

                            <ul>
                                <li>Offer ideas for local gyms</li>
                                <li>Lead automation Plug n Play</li>
                            </ul>
                        </div>

                        <div ref={refs[0]} className="plug-block">
                            <div className="plug-block-subheading">Offer ideas for local gyms</div>

                            <p>
                                Similar to gyms you need an offer to attract people and make them take actions and
                                although you can get away with being less aggressive in this niche as people usually
                                come to fix a problem they already have. The niche is still competitive and you have
                                to persuade them into going to your clients clinic so here are some offers that work
                                well for it:
                            </p>

                            <p>
                                With that being said there is some exceptions were people have managed to make the
                                ads work without any sort of front end offer by using better creatives like video
                                testimonials that show the gym and invite people to give them a call or fill out a
                                Lead form.
                            </p>

                            <img src={image1} alt="" />

                            <p>
                                Here are some ideas that we've gathered from our own experience and in the ads
                                library.
                            </p>
                            <p>
                                Join for $1 with contract or join with no contract, meaning month by month normal
                                price.
                            </p>

                            <img src={image2} alt="" />
                            <p>Free fitness device + rest of the month free</p>

                            <img src={image3} alt="" />

                            <p>4 weeks, $4</p>

                            <img src={image4} alt="" />

                            <p>1 week free trial</p>

                            <img src={image5} alt="" />

                            <p>
                                Free niche-specific trial class. This works great for gyms specialized in
                                gymnastics, pilates, dancing and other specific types of training.
                            </p>

                            <img src={image6} alt="" />

                            <p>
                                This ad has some great points to highlight. One it is gender specific, the more
                                specific the more identified and talked to the prospect would feel. It is location
                                specific, it has a great offer and it has scarcity mixed into the copy by stating
                                they will only give 25 free sessions. They are not limited to 25 and will attend
                                much more leads than that, but it is a great psychological trick to push people to
                                take action as they feel it is exclusive and time sensitive.
                            </p>

                            <img src={image7} alt="" />

                            <p>Value offer, with coaches nutritional plans and different add-ons to their gym.</p>

                            <img src={image8} alt="" />
                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">Targeting</div>

                            <p>
                                Following the same fundamentals of the local lead generation in terms of
                                geographical targeting here are some global interests that might get you better
                                results than just going broad. With that being said don't forget that oftentimes the
                                simpler the better and you can get away with just going with no 'additional
                                targeting' meaning no interests, lookalikes or custom audiences.
                            </p>

                            <img src={image9} alt="" />
                            <img src={image10} alt="" />
                            <img src={image11} alt="" />

                            <p>Here are some results we've gotten with those audiences.</p>
                            <img src={image12} alt="" />

                            <p>
                                Cost per lead will vary greatly depending on the offer, time of the year,
                                geographical setting, etc.
                            </p>
                            <img src={image13} alt="" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
