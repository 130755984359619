import React from 'react';
import './style.scss';
import Container from 'reactstrap/lib/Container';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';

import image1 from '../../../../../assets/img/plugandplay/chiropractors/1.png';
import image2 from '../../../../../assets/img/plugandplay/chiropractors/2.png';
import image3 from '../../../../../assets/img/plugandplay/chiropractors/3.png';
import image4 from '../../../../../assets/img/plugandplay/chiropractors/4.png';
import image5 from '../../../../../assets/img/plugandplay/chiropractors/5.png';
import image6 from '../../../../../assets/img/plugandplay/chiropractors/6.png';
import image7 from '../../../../../assets/img/plugandplay/chiropractors/7.png';
import image8 from '../../../../../assets/img/plugandplay/chiropractors/8.png';
import image9 from '../../../../../assets/img/plugandplay/chiropractors/9.png';
import image10 from '../../../../../assets/img/plugandplay/chiropractors/10.png';
import image11 from '../../../../../assets/img/plugandplay/chiropractors/11.png';
import image12 from '../../../../../assets/img/plugandplay/chiropractors/12.png';
import image13 from '../../../../../assets/img/plugandplay/chiropractors/13.png';
import image14 from '../../../../../assets/img/plugandplay/chiropractors/14.png';

const sections = [
    {
        available: true,
        sectionName: 'Offers',
    },
    {
        available: true,
        sectionName: 'Targeting',
    },
    {
        available: true,
        sectionName: 'Special Chiropractor specific remarks',
    },
];

const title = "Industry Specific: Chiropractors"

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
         <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div  className="plug-block">  
                        <h2 className="mb-4">{title}</h2>
                            <div className="plug-block-subheading">In today's teaching</div>

                            <ul>
                                <li> Importance of automating lead flow</li>
                                <li> Lead automation Plug n Play</li>
                            </ul>
                        </div>

                        <div ref={refs[0]} className="plug-block">
                            <div className="plug-block-subheading">Offers</div>

                            <p>
                                Similar to gyms you need an offer to attract people and make them take actions and
                                although you can get away with being less aggressive in this niche as people usually
                                come to fix a problem they already have. The niche is still competitive and you have
                                to persuade them into going to your clients clinic so here are some offers that work
                                well for it:
                            </p>
                            <p>
                                $21 dollar scans offering to find out which problems do you have. This is clearly an
                                entry offer to then offer the procedures that will fix these issues. It is also
                                mentioned in the copy that this scans would normally cost $150 so it classifies in
                                the 'Discount' type of offer and can be done with other procedures. Worth mentioning
                                that the video is well done and professional giving the confidence about the
                                knowledge on their field.
                            </p>

                            <img src={image1} alt="" />

                            <p>
                                $37 dollar adjustment with scarcity in the amount of offers available. Again similar
                                to that recent gym ad we analyzed this scarcity is artificial but works wonders when
                                pushing people to take action.
                            </p>

                            <img src={image2} alt="" />

                            <p>
                                $37 dollar adjustment for back and neck pain. Same as the last one there is a
                                limited amount of vouchers for the discounted procedure.
                            </p>

                            <img src={image3} alt="" />

                            <p>
                                Similar to the Women specific offer we explained on the module "Industry Specific:
                                Gyms" here they are going for the military angle giving a free adjustment and some
                                other services. The landing page has the same theme and they even got a specific
                                domain for it which is a great idea.
                            </p>

                            <img src={image4} alt="" />

                            <p>
                                They are also most likely using some detailed targeting of military veterans
                                available on Facebook.
                            </p>

                            <img src={image5} alt="" />

                            <p>
                                As with the others we liked to include an angle that has no specific offer but just
                                talks about the practice, the service and leaves some testimonials about the
                                experience.
                            </p>

                            <img src={image6} alt="" />

                            <p>
                                Some use the athletes angle, some use the nurses able to withstand the long standing
                                hours angle. Both for the same offer of a discount on the service. As always there
                                is some artificial scarcity involved in the offer.
                            </p>

                            <img src={image7} alt="" />

                            <p>
                                For the vast majority of clinics having a discount on the first session would be all
                                you need to get the leads flowing. It is the simplest and easiest type of offers you
                                can run. The numbers $37, $35 and $29 are the most common for this introductory
                                consultation and session.
                            </p>

                            <h3>Landing pages and form</h3>

                            <p>
                                This is the standard form you would create if you are not using Lead ads inside of
                                Facebook. It is important to put the location of the clinic so the leads are
                                familiar with the place and how to get there.
                            </p>
                            <img src={image8} alt="" />
                        </div>
                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">Targeting</div>
                            <p>
                                Here are some of the audiences we've found work. As always remember that 80% of the
                                time your best bet will be to go with broad and play around a bit with the age
                                ranges as younger people tend not to visit the chiropractor often.
                            </p>

                            <p>Usually the best demographics to go after is Women from ages 25 to 54.</p>

                            <img src={image9} alt="" />

                            <p>Here are some detailed targeting options you can use</p>

                            <img src={image10} alt="" />
                            <img src={image11} alt="" />
                            <img src={image12} alt="" />
                        </div>

                        <div ref={refs[2]} className="plug-block">
                            <div className="plug-block-subheading">Special Chiropractor specific remarks</div>

                            <p>
                                Because you're dealing with people's personal issues, pains and medical conditions
                                you have to be guided by the Facebook ad policies which include the following:
                            </p>

                            <p>Don't use questions about the medical condition:</p>

                            <img src={image13} alt="" />

                            <p>Don't use pictures with red areas of pain</p>

                            <img src={image14} alt="" />

                            <p>
                                Don't insinuate cures or that the issue will be resolved as it falls into Facebook's
                                misleading claims policy.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
