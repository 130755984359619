import React from 'react';
import './style.scss';
import Container from 'reactstrap/lib/Container';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';

const sections = [
    {
        available: true, sectionName: 'Things to keep in mind',
    },
    {
        available: true, sectionName: 'How To Get Good Sleep',
    },
    {
        available: true, sectionName: 'Hacks For Good Sleep',
    },
    {
        available: true, sectionName: 'Waking Up Early/Same Time',
    },
];

const title = "6-Figure Sleep"

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]}  className="plug-block"> 
                            <div className="plug-block-subheading">Things to keep in mind:</div>

                            <p>I’m a freak when it comes to optimisation and eeking out efficiency from my environment as well as myself. I can truly say that sleep is the biggest key to doing so for me personally.</p>

                            <p>
                                If you haven’t already, I strongly recommend you go ahead and pick up an
                                {' '}
                                <a href="https://ouraring.com/" target="_blank" rel="noopener noreferrer">Oura Ring.</a>
                            </p>

                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">How To Get Good Sleep</div>

                            <p>I’m going to first start off by diving deep into how to actually get good sleep. This is a high-level view and the key things you need to have in place to make sure you’re performing at your best.</p>

                            <p className="bold">Wake up at the same time everyday:</p>

                            <p>This is definitely an absolute must. You need a proper boot up process for the beginning of the day and this gives it to you. If you have a little discrepancy in the time that you go to bed, that’s fine but never ever change the time that you wake up. If you had a dinner the night before or genuinely had something come up and only managed to get 3-4 hours of sleep, still get up and do your morning work then have a nap later.</p>

                            <p className="bold">Make your bedroom a sanctuary:</p>

                            <p>I see so many people watching Netflix in their bed or doing work in their bedroom. I understand that for some of you there’s literally nowhere else to work than your bedroom but try your absolute best to refrain from working there. Your bedroom should be used for two things and two things only… sleep & sex. This way, your brain actually associates being in your bedroom to sleeping.</p>

                            <p className="bold">Have a night time routine:</p>

                            <p>One of the easiest ways to signal to your brain that it’s time to go to bed is by having a strong night time routine. This is a way for you to start getting sleepy & making sure that you have everything locked and loaded for your next day. </p>

                            <p className="bold">Block out bad lights:</p>

                            <p>You want to block out as much blue light as possible using different tools on your computer, on your phone, blue light blocking glasses, etc.</p>

                            <p className="bold">Make it a priority:</p>

                            <p>I think people think that sleep is something that’s an annoyance. I look forward to deep restorative sleep and I know that it’ll make me more money, make me smarter and bring me more happiness. </p>

                        </div>
                        <div ref={refs[2]} className="plug-block">
                            <div className="plug-block-subheading">Hacks For Good Sleep</div>

                            <p>Now I’m going to give you some more nitty-gritty hacks for optimised sleep. This is stuff that you can implement asap and reap the rewards immediately. </p>

                            <p className="bold">Loose-Leaf Tea:</p>

                            <p>I got a tea-pot with an infuser in the middle from a company called T2 for like £35 and used it for years now. Everynight a put a teaspoon of loose leaf chamomile tea inside and pour hot water to brew for 3-4 minutes. I’ve tried super expensive sleep supplements + tech before and I can honestly say that this has the biggest improvement on my sleep by far. </p>

                            <p className="bold">Blue-Light Blockers:</p>

                            <p>This will help you block out all of the pesky blue-lights that inhibit the creation of melatonin. You can get some industrial ones that make you look like a robot, you can get some effective ones that still look funny and then you can get some pairs that actually look really nice too!</p>

                            <p>
                                As many of you know, I spent years developing the best selling Gadzhi G1s which you can find here:
                                {' '}
                                <a href="https://gadzhi.com/" target="blank" rel="noopener noreferrer">https://gadzhi.com/</a>
                            </p>

                            <p className="bold">Flux & Night Shift:</p>

                            <p>Apple computers have a native night-shift but they’re really bad. That’s why I use Flux instead. It’s an app that automatically boots up when you launch your computer and automatically shifts the colour temperature as you get closer to bed. By the time it’s 3-4 hours before bed time your computer literally looks orange. </p>

                            <p>On iPhones I haven’t found anything that you can use apart from Apple’s native night shift mode. Just go into settings, turn it on and then make sure the night shift is as intense as possible.</p>

                            <p className="bold">Incense Sticks:</p>

                            <p>When I’m drinking my loose-leaf tea before bed, I also light an incense stick because this also helps me get into a sleepy state. Incense forces you to focus on your breath which helps make you more present to the moment, drown out the chatter most people experience before bed and generally allows you to sleep better.</p>

                        </div>
                        <div ref={refs[3]} className="plug-block">
                            <div className="plug-block-subheading">Waking Up Early/Same Time</div>

                            <p>There is so much BS out there on how to actually effectively wake up at the same time everyday. I spent years trying to develop a system to wake up early predictably until I finally found this system.</p>

                            <div className="examples-block">
                                <ol>
                                    <li><i><span className="bold">Night Routine:</span>
                                    {' '}
                                    Go through your night routine with the tea, incense and most importantly - Plan out your day the night before and lay out all of your clothes for the morning so you don’t have to think about it. </i></li>

                                    <li><i><span className="bold">Get Good Sleep:</span>
                                    {' '}
                                    Follow all the instructions on how to get good sleep by having a night routine, not having caffeine after 2pm, finish eating min. 3 hours before bed, etc - It’s a lot easier to wake up early when you get good sleep.</i></li>

                                    <li><i><span className="bold">Wake Up Light:</span>
                                    {' '}
                                    Get a wake up light from Amazon that’ll slowly start to go from dark to dim to fully bright in the 30 minutes leading up to you waking up. This’ll help mirror the effect of the sun coming up. Especially useful in dark countries or in the winter.</i></li>

                                    <li><i><span className="bold">Alarmy:</span>
                                    {' '}
                                    Go on the app store and download an app called Alarmy. The best feature of it is that you CANNOT turn it off until you complete a predetermined mission. You can have quite a few different missions on it but the main one I use is to scan a barcode. I keep this barcode in my kitchen so I literally have to get out of my bed & walk all the way to my kitchen to turn this alarm off. It’s like nothing else out there. </i></li>
                                </ol>
                            </div>

                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};