import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import { Link } from 'react-router-dom';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        available: true,
        sectionName: 'Introduction:',
    },
    {
        available: true,
        sectionName: 'Funnels for lead generation:',
    },
];

const title = '9 Funnels For Lead Gen & How To Set Them Up';

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]}  className="plug-block">  
                        <h2 className="mb-4">{title}</h2>
                            <div className="plug-block-subheading">Introduction:</div>

                            <p>
                                Let's quickly visit the definition of a funnel.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/nineFunnels1.png" alt="" />

                            <p>
                                In most instances, a customer becomes aware of a brand, interacts with it (through either visiting the website or watching content), they express interest (by signing up to a newsletter, adding to cart, signing up to a discount etc.) and finally by taking action (normally purchasing).
                            </p>

                            <p>
                                This process is depicted as a funnel because customers are ‘lost’ the further down the funnel they move. Although this has negative connotations, it can be positive: for example, in the case of lead generation, a funnel should actively look to dispose of unqualified customers.
                            </p>

                            <p>
                                Funnels can take thousands of forms and they can also be considered conceptually as well as practically. That is, mainstream brands like Audi or Nike have separate campaigns, budgets, and agencies for each ‘stage’ of their funnel: for example, they run out-of-home campaigns to encourage awareness. Then localised targeting through newspapers, and social media. Then online configurators, and finally sales teams in dealerships. They do not track purchases from billboards to dealerships, instead they assume the funnel theory is taking place.
                            </p>

                            <p>
                                In contrast, smaller businesses and many online deal with a very concrete, practical funnel where they can track users at each stage of the funnel.
                            </p>

                            <p>
                                The funnels below will fall into the latter category. .
                            </p>
                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">
                                Funnels for lead generation:
                            </div>

                            <p>
                                The following funnels are presented in order of use and effectiveness.
                            </p>

                            <p className="bold">
                                Facebook Native Lead Forms:
                            </p>

                            <p>
                                Facebook native lead forms are covered in
                                {' '}
                                <Link to="/resources/plug-and-play/core-concepts-to-keep-in-mind" />
                                {' '}
                                ‘Core Concepts’. We’ll cover how to set them up in the
                                {' '}
                                <Link to="/resources/plug-and-play/facebook-native-vs-funnel-lead-gen">next module</Link>
                                !
                            </p>

                            <p className="bold">
                                Step Offer Squeeze page:
                            </p>

                            <p>
                                In this funnel, a simple value offer - normally a PDF guide, or eBook - is exchanged for the user’s contact information.
                            </p>

                            <p>These pages are usually short, comprising a headline, an image or video, and bullet points presenting the offer and its benefits. It also has a thank you page which is used after the prospects submits their information, this page is used to introduce more information to the lead about the offer, for instance you could have a video explaining what the offer is about, and  you could have text and images that will entice the lead to be excited when contacted. This step is often overlooked but it is extremely important and is the difference between a good lead that will convert into a customer and an unqualified lead, or one that does not expect an offer to be made. </p>

                            <p>Here are a few  examples of this type of funnels:</p>

                            <a target="_blank" rel="noopener noreferrer" href="https://marketplace.clickfunnels.com/templates/45 ">https://marketplace.clickfunnels.com/templates/45 </a>
                            <a target="_blank" rel="noopener noreferrer" href="https://marketplace.clickfunnels.com/templates/303 ">https://marketplace.clickfunnels.com/templates/303 </a>
                            <a target="_blank" rel="noopener noreferrer" href="https://marketplace.clickfunnels.com/templates/1739 ">https://marketplace.clickfunnels.com/templates/1739 </a>
                            <a target="_blank" rel="noopener noreferrer" href="https://marketplace.clickfunnels.com/templates/1163">https://marketplace.clickfunnels.com/templates/1163</a>

                            <p className="bold">Video letter: </p>

                            <p>
                                VIdeo letters are similar to the squeeze page, but instead of text and images,  a video is the most prominent feature.
                            </p>

                            <p>
                                They tend to convert better than simple  squeeze pages - when the videos have a good angle and are produced well - but are more suited to specific niches, especially where a visual aid is necessary to explain the offer, or the audience is particularly uneducated about the topic.
                            </p>

                            <p>
                                Examples:
                            </p>

                            <a target="_blank" rel="noopener noreferrer" href="https://marketplace.clickfunnels.com/templates/47 ">https://marketplace.clickfunnels.com/templates/47 </a>
                            <a target="_blank" rel="noopener noreferrer" href="https://marketplace.clickfunnels.com/templates/2890">https://marketplace.clickfunnels.com/templates/2890</a>
                            {' '}
                            <p>(without the add to cart but an opt-in instead)</p>
                            <a target="_blank" rel="noopener noreferrer" href=" https://marketplace.clickfunnels.com/templates/3413"> https://marketplace.clickfunnels.com/templates/3413</a>
                            {' '}
                            <p>(without the add to cart but an opt-in instead)</p>

                            <p className="bold">
                                Messaging funnel:
                            </p>

                            <p>
                                This is an often-overlooked but extremely effective way of bringing customers is through messaging ads.
                            </p>

                            <p>
                                Prospects are prompted to send a message to the Facebook page or using WhatsApp business if it is more popular in the country where you are advertising.
                            </p>

                            <p>
                                Facebook ads offer a direct way to connect with WhatsApp or messenger and people go from the ad straight to the messaging platform. This method needs human interaction - and because of SMMA’s natural inclination to automation, this is easy to neglect.
                            </p>

                            <p>
                                One of the major downsides of a messaging funnel is that it does not ‘nurture’ a lead in the way a landing page may (with more information, testimonials, VSLs etc’. However, it’s possible to replicate this process through an automation tool like ManyChat which allows you to include automated responses, and prompts for more information.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/nineFunnels2.png" alt="" />

                            <p className="bold">
                                Lead Magnet:
                            </p>

                            <p>
                                A lead magnet funnel is very similar to a squeeze page, but in this case you offer the prospect something for free, often digital, that they can have right away or delivered to their email.
                            </p>

                            <p>
                                Typical examples include eBooks, guides, valuations, and case studies.
                            </p>

                            <p>
                                The premise is simple: you're exchanging free value in the form of a digital asset (although it is not limited to this and you can get creative with what you give away) for contact information of the prospect. The success of this method will greatly depend on how enticing the freebie is. The lead magnet is sent to the email of the prospect, that way you ensure that you're getting real emails and the only way for them to get it is by putting in their real email.
                            </p>

                            <p>
                                Some people decide to ignore this and give the free lead magnet away on the thank you page, this is also an option, you can make an informed decision on what's best seeing the quality of the leads you're getting. This type of funnel works for any niche. You can for instance have a weight loss guide if you run ads for a local gym, you can have a 10 foods to lose weight guide or to build muscle, or how to stop night cravings guide, you get the gist.
                                {' '}
                                <a target="_blank" rel="noopener noreferrer" href="https://marketplace.clickfunnels.com/templates/2082 https://marketplace.clickfunnels.com/templates/2956">****https://marketplace.clickfunnels.com/templates/2082 https://marketplace.clickfunnels.com/templates/2956</a>
                            </p>

                            <p className="bold">
                                Quiz funnel:
                            </p>

                            <p>
                                This is an extremely powerful funnel type, and one favoured by IAG Media. Quiz funnels provide an interactive and highly personalized way of getting leads. It prompts the prospect to answer simple questions that will tailor the offer to their specific needs and demographics.
                            </p>

                            <p>
                                Some examples are:
                            </p>

                            <ul>
                                <li>
                                    Body types for gyms
                                </li>
                                <li>
                                    Pain/ injury profiles for chiropractors
                                </li>
                                <li>
                                    Dream car/perfect car for dealerships
                                </li>
                            </ul>

                            <p>IAG Media often uses Typeform to create these quizzes, but there are multiple softwares you can use - and include greater logic sequences in.</p>

                            <p>
                                {' '}
                                You can find a link to some of them on the plug n play of this module where you'll also find a template for the funnel itself.
                            </p>

                            <a href="https://marketplace.clickfunnels.com/templates/2831 ">https://marketplace.clickfunnels.com/templates/2831 </a>
                            <a href="https://marketplace.clickfunnels.com/templates/1738  ">https://marketplace.clickfunnels.com/templates/1738  </a>
                            <a href="https://marketplace.clickfunnels.com/templates/1087">https://marketplace.clickfunnels.com/templates/1087 </a>

                            <img src="https://img.genflow.com/gya/plugandplays/nineFunnels3.png" alt="" />

                            <p className="bold">
                                "Lead" letter:
                            </p>

                            <p>
                                This is the squeeze page’s big brother. The main difference between the two is the amount of information that’s included on a lead letter: this page has more sections and is scrollable meaning there's information below the fold of the page and you have to scroll to see it all. These sections often include testimonials and/or logos of companies that have worked with your client, pictures of the service or the facility of the business, a brief presentation of the team behind the business, an in depth explanation of the offer in the form of a paragraph and more. How many of these sections you add is up to you and you'll need to tailor this to the type of offer and client you work with.
                            </p>

                            <div className="examples-block">
                                <p>
                                    The fact that it has more information does not guarantee cheaper leads or better conversion rates. It often can even work against you, by providing more information to the prospect that might overthink decisions, get bored and not read all and ultimately not become a lead.
                                </p>
                            </div>

                            <a href="https://marketplace.clickfunnels.com/templates/2859 ">https://marketplace.clickfunnels.com/templates/2859 </a>
                            <a href="https://marketplace.clickfunnels.com/templates/3393">https://marketplace.clickfunnels.com/templates/3393</a>

                            <p className="bold">Facebook Group funnel: </p>

                            <p>
                                This is similar to a lead magnet, but instead of collecting emails, you require leads to join a Facebook group - linked on the landing page - in order to access the download.
                            </p>

                            <p>
                                This can be more effective than a traditional lead magnet as you are able to create a community, and ongoing relationship with your leads.
                            </p>

                            <p className="bold">
                                Quiz + Ebook:
                            </p>

                            <p>
                                This is an effective hybrid of quiz and lead magnet funnels. In this funnel, the lead receives a specific download that corresponds to the result of their quiz. For example, how to gain muscle as an ectomorph.
                            </p>

                            <a href="https://marketplace.clickfunnels.com/templates/2831">https://marketplace.clickfunnels.com/templates/2831</a>

                            <img src="https://img.genflow.com/gya/plugandplays/nineFunnels4.png" alt="" />
                            <img src="https://img.genflow.com/gya/plugandplays/nineFunnels5.png" alt="" />

                            <div className="examples-block">
                                <p className="bold">Important:</p>

                                <p>
                                    Different funnels go in-and-out of fashion and effectiveness. For example, webinar funnels, once the darling of digital marketers, are now easily-identifiable by many consumers, leading them to lose their effectiveness.
                                </p>

                                <p>
                                    Funnels should always be trialled - and dispensed with if they don’t work for your clients.
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
