import { Card, CardBody, Progress } from 'reactstrap';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import useCourse from '@genflow/web-courses/hooks/useCourse';
import useCompleteLesson from '@genflow/web-courses/hooks/useCompleteLesson';
import { useConfig } from '@genflow/core';
import { createBrowserHistory as history } from 'history';
import { get } from 'lodash';
import { getIsDiscountedGYAUser, redirectToCheckout } from '../utils/stripe';
import LoadingButton from './LoadingButton';
import { getUser } from '../utils/auth';
import getCognitoUserAttribute from '../utils/getCognitoUserAttribute';
import { LockClosedIcon } from '@heroicons/react/outline'
import Number2Words from 'react-number2words';


const getButtonText = (access, comingSoon) => {
    if (access) {
        return 'Continue';
    }
    if (comingSoon) {
        return 'By Invitation Only';
    }
    return 'Join';
};

const getButtonColor = (access, comingSoon) => {
    if (access) {
        return 'primary';
    }
    if (comingSoon) {
        return 'light';
    }
    return 'success';
};


const isBrowser = typeof window !== 'undefined';


export default ({
    phase,
    title,
    videos,
    productID,
    vidOrSec
}) => {
    const { vendorUuid } = useConfig();
    const { data, loading, authoriseError } = useCourse(vendorUuid, productID, {
        skip: !productID,
        fetchPolicy: 'network-only',
    });

    const { stripeProductPlans } = useConfig();
    const access = !!productID && authoriseError === null;
    const totalLessons = get(data, 'sections', [])
        .map(({ lessons }) => lessons.length)
        .reduce((acc, value) => acc + value, 0);
    const { totalCompletedLessons } = useCompleteLesson(productID);

    const progressPercentage = totalCompletedLessons * 100 / totalLessons;
    const onSelectSubscription = async () => {
        try {
            const cognitoUser = await getUser();
            const cognitoEmail = cognitoUser?.attributes?.email;
            const data = await getIsDiscountedGYAUser(cognitoEmail);
            await redirectToCheckout(
                data?.data?.isDiscounted
                    ? stripeProductPlans.agencyNavigatorExistingStudents
                    : stripeProductPlans.agencyNavigator,
            );
        } catch (e) {
            console.log(e);
        }
    };

    return (
            <div className="phaseBox relative px-4 flex flex-col justify-center">
{/*                <h6 className="text-white uppercase mt-4">phase &nbsp;
                    <span>
                        <Number2Words value={phase} />
                    </span>
                </h6>*/}
                <h4 className="text-white uppercase mt-6 w-full h-20 whitespace-normal">{title}</h4> 
                <div className="text-white body4 mt-8">{videos}&nbsp;{vidOrSec}</div>
            </div>

    );
};
