const styles = () => ({
    root: {
        width: '100%',
        backgroundColor:' #1C1C1C !important',
        height: '100%',
    },
    header: {
        textAlign: 'center',
        margin: '1rem 0',
        cursor: 'pointer',
        flex: 1,
        fontSize: '1.2rem',
    },
      inputHeader: {
        textAlign: 'center',
        margin: '1rem 0',
        cursor: 'pointer',
        flex: 1,
        fontSize: '1.2rem',
        background:' #373737',
        color: 'white',
        paddingLeft: '0.3rem',
        borderRadius: '0.25rem'
    },
    typographyHeader: {
        textAlign: 'center',
        fontWeight: 700,
        marginTop: '1rem',
        marginBottom: '1rem',
        marginRight: '0.5rem',
        cursor: 'pointer',
    },
    financeItemContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },

    financeItemInputGroup: {
        marginLeft: '0.5rem',
    },

    financeItemInput: {
        flex: 1,
        width: '50%',
        background:' #373737',
        color: 'white',
        paddingLeft: '0.3rem',
        borderRadius: '0.25rem'

    },

    columnHeader: {


    },
});

export default styles;
