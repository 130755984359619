/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';
import { Link } from 'react-router-dom';

import image1 from '../../../../../assets/img/plugandplay/youtube-ads-playbook/1.png';
import image2 from '../../../../../assets/img/plugandplay/youtube-ads-playbook/2.png';
import image3 from '../../../../../assets/img/plugandplay/youtube-ads-playbook/3.png';
import image4 from '../../../../../assets/img/plugandplay/youtube-ads-playbook/4.png';
import image5 from '../../../../../assets/img/plugandplay/youtube-ads-playbook/5.png';
import image6 from '../../../../../assets/img/plugandplay/youtube-ads-playbook/6.png';
import image7 from '../../../../../assets/img/plugandplay/youtube-ads-playbook/7.png';
import image8 from '../../../../../assets/img/plugandplay/youtube-ads-playbook/8.png';
import image9 from '../../../../../assets/img/plugandplay/youtube-ads-playbook/9.png';
import image10 from '../../../../../assets/img/plugandplay/youtube-ads-playbook/10.png';
import image11 from '../../../../../assets/img/plugandplay/youtube-ads-playbook/11.png';
import image12 from '../../../../../assets/img/plugandplay/youtube-ads-playbook/12.png';
import image13 from '../../../../../assets/img/plugandplay/youtube-ads-playbook/13.png';
import image14 from '../../../../../assets/img/plugandplay/youtube-ads-playbook/14.png';
import image15 from '../../../../../assets/img/plugandplay/youtube-ads-playbook/15.png';
import image16 from '../../../../../assets/img/plugandplay/youtube-ads-playbook/16.png';
import image17 from '../../../../../assets/img/plugandplay/youtube-ads-playbook/17.png';
import image18 from '../../../../../assets/img/plugandplay/youtube-ads-playbook/18.png';
import image19 from '../../../../../assets/img/plugandplay/youtube-ads-playbook/19.png';
import image20 from '../../../../../assets/img/plugandplay/youtube-ads-playbook/20.png';
import image21 from '../../../../../assets/img/plugandplay/youtube-ads-playbook/21.png';
import image22 from '../../../../../assets/img/plugandplay/youtube-ads-playbook/22.png';
import image23 from '../../../../../assets/img/plugandplay/youtube-ads-playbook/23.png';
import image24 from '../../../../../assets/img/plugandplay/youtube-ads-playbook/24.png';
import image25 from '../../../../../assets/img/plugandplay/youtube-ads-playbook/25.png';

const sections = [
    {
        available: true,
        sectionName: 'Introduction',
    },
    {
        available: true,
        sectionName: 'Youtube Ads Script',
    },
    {
        available: true,
        sectionName: 'Types of audiences and the best ones to create',
    },
    {
        available: true,
        sectionName: 'Walkthrough creating a Youtube Campaign',
    },
];

const title = 'Youtube Ads Playbook';

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]}  className="plug-block"> 
                            <div className="plug-block-subheading">Introduction</div>

                            <p>
                                YouTube ads are extremely useful for all kinds of businesses (including local
                                businesses, and we will see a specific strategy for those with a powerful Google
                                tool).
                            </p>

                            <p>
                                Previously, in {" "}
                                <Link to="/resources/plug-and-play/scaling-horiz-vs-vertical">
                                    Scaling Vertically vs Horizontally
                                </Link>
                                , we covered that on Google, including YouTube of course, scaling was very easy.
                            </p>

                            <p>
                                So it begs the question why then Facebook still seems to be the dominant platform,
                                and the one which so many people prefer to advertise on.
                            </p>

                            <p>
                                Ultimately, this comes down to complexity: getting YouTube to work means running
                                video ads - regardless of whether it’s a suggested video or a display ad, YouTube
                                means videos.
                            </p>

                            <p>We often tell client</p>

                            <p>
                                <span style={{ fontStyle: 'italic', marginLeft: '2rem' }}>
                                    When it works it works extremely well, but get it to work...
                                </span>
                            </p>

                            <p>
                                If your client has the capacity and creativity to make videos, YouTube is an
                                excellent option. But for most, Facebook offers lower hanging fruits when it comes
                                to advertising.
                            </p>
                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">Youtube Ads Script</div>

                            <p>
                                As with any other script, this should be taken with a pinch of salt, adapted and
                                improved.
                            </p>

                            <p>
                                Always keep in mind:
                                <br />
                                <br />
                                1.YouTube ads cannot be skipped for the first 5 seconds, meaning everyone has to see
                                them
                                <br />
                                2.You only get charged after someone watches at least 30 seconds of your ad
                            </p>

                            <h4>Hook</h4>

                            <p>
                                The first 5 seconds of your ad are CRUCIAL to getting viewers who are relevant to
                                your offer to stop what they’re doing and not click the “Skip Ad” button.
                            </p>

                            <p>
                                We are trying to make relevant viewers interested enough so they stick around past
                                when that “skip ad” button pops up 5 seconds into the video.
                            </p>

                            <p>There are a few different ways to hook in the viewer.</p>

                            <ul>
                                <li>Pattern interrupts like smoke, an unusual sign, an unusual location</li>
                                <li>Appeal to desire and ‘WOW’ - a nice car, a good view</li>
                                <li>
                                    Curiosity gap: ‘Here’s why Instagram is going to be taken offline in six months’
                                    time’
                                </li>
                            </ul>

                            <h4>Disqualification</h4>

                            <p>
                                After those 5 seconds the next 25 are important because you only pay after for the
                                impression after the viewer has reached the 30 second mark. What you want to ideally
                                do is disqualify the viewers that are not a good fit for your offer. This can be
                                achieved by clearly stating who will be benefited by the information you're going to
                                present and stating who is this for.
                            </p>

                            <h4>Emotion</h4>

                            <p>
                                Although these last few parts are interchangeable, what you ideally want to achieve
                                is trigger the emotions of the viewer and make sure they are somehow emotionally
                                invested about the problem you're trying to fix. Call out the common pains that you
                                fix or dreams that you help achieve. Be it having a new home, selling their home,
                                starting their own business, getting the body they always wanted, etc.
                            </p>

                            <h4>Value</h4>

                            <p>
                                Finally it is important to show the prospect that you are the right person to help
                                them with whatever you are offering, this can be achieved in various ways. For
                                instance, testimonials of previous works done or products sold are a good way to
                                show that your client has done this in the past and is good at it. Other ways of
                                showing expertise can include high quality information that only a person who knows
                                what they are talking about would know.
                            </p>

                            <h4>Call to action</h4>

                            <p>
                                At the end it is important to let the interested viewer know what they can do to
                                learn more about your offer, it is very important that this call to action is long
                                so they have enough time to find and click the buttons. Keep in mind that once the
                                ad ends the video they were watching will autoplay so make sure the end has enough
                                time to let them click on it.
                            </p>
                        </div>

                        <div ref={refs[2]} className="plug-block">
                            <div className="plug-block-subheading">
                                Types of audiences and the best ones to create
                            </div>

                            <h4>Retargeting audiences</h4>

                            <p>
                                We will shortly visit cold audiences in depth, but prior to doing this, it’s worth
                                focusing on retargeting and setting up your audiences straight away.
                            </p>

                            <p>
                                Google only goes ‘back in time’ for 30 days to prefill your audience lists. This
                                means that even if you create an audience with a window of the last six months,
                                Google will only populate it to include the last 30 days, and then populate it
                                moving forward. So it makes sense to create the audiences you wish to use as quickly
                                as possible.
                            </p>
                            <img src={image1} alt="" />

                            <p>
                                As with Facebook, you can retarget people who have visited your website or people
                                that have engaged in Youtube. We have a similar approach to Facebook creating big
                                retargeting audiences that don't leave out a lot of people.
                            </p>

                            <img src={image2} alt="" />

                            <p>Go to the Audience Manager</p>

                            <img src={image3} alt="" />

                            <p>
                                You can select '+' sign on the remarketing section and select 'website visitors'
                            </p>

                            <img src={image4} alt="" />

                            <p>
                                On the audience name we use 'All visitors 180D' which refers to 180 days of everyone
                                that has visited a page were the google tag was installed wither via Google tag
                                manager as we explained or manually through code.
                            </p>

                            <p>
                                Then you select the tag, meaning the source of the info → A parallel to the Facebook
                                pixel
                            </p>

                            <img src={image5} alt="" />

                            <p>
                                For the List of members, we usually leave as ‘visitors of a page,’ similar to
                                Facebook, you can create this audience with specific links or landing pages, this
                                can make sense when your client has very different offers.
                            </p>

                            <img src={image6} alt="" />

                            <p style={{ background: '#ff0' }}>
                                {' '}
                                As a side note: When our clients have different offers we choose the best one and
                                focus all our efforts on one. Usually when you try to chase two rabbits you get
                                none. Focus on one product at a time, it has its exceptions for example aggressive
                                dropshipping stores that rotate many products.
                            </p>

                            <p>
                                Remember you can target different urls and play around with exclusions with AND and
                                OR
                            </p>

                            <p>AND means that the person should have visited both pages (URLs) that you put.</p>

                            <p>OR means that the person should have visited AT LEAST one of the URLs.</p>
                            <p>
                                Because we keep it simple for most of our clients in this example we will show that
                                we just grab the 'base' or root of the URL → The domain and make sure it is set as
                                'contains' which means that the URL that the person visited has to have that domain
                                but it can have anything else after it.
                            </p>
                            <p>
                                There's other matching options you can have for the domain, for this example you
                                could use starts with as well. Contains refers to any part of the URL, starts with
                                strictly to the beginning.
                            </p>

                            <img src={image7} alt="" />

                            <img src={image8} alt="" />

                            <p>
                                <b>
                                    Remember: when you select the option 'Visitors of a page during specific dates'
                                    you can only go back 30 days.
                                </b>
                            </p>

                            <img src={image9} alt="" />

                            <p>When you select after you can select any date in the future.</p>
                            <p>(IAG Media does not use this function)</p>

                            <img src={image10} alt="" />

                            <p>Finally you have to select the membership duration and the pre-fill options.</p>

                            <p>
                                Membership duration refers to how long a user stays in that audience. We usually
                                have 180 and 90 days as our durations. Maximum membership duration is 540 days, or
                                about one year and a half.
                            </p>

                            <img src={image11} alt="" />

                            <h4>Youtube Audiences</h4>

                            <p>
                                You can also create remarketing audiences. If your client has a good YouTube
                                presence, you can leverage this audience as people will already know them and have
                                interest in whatever you are selling. These audiences will often find the highest
                                ROI but you can only scale so far as their size is limited. The bigger the audience,
                                the more you can spend naturally.
                            </p>

                            <img src={image12} alt="" />

                            <p>
                                To create an audience you just select YouTube users as a source and follow the next
                                steps:
                            </p>

                            <img src={image13} alt="" />

                            <p>
                                Add a name to your audience, the name should give you all the information you need
                                about the audience. For example you can name your audience as follows: Viewed any
                                video - 180 days
                            </p>
                            <p>
                                This would be an audience with people that have watched any video in the last six
                                months, remember that similar to the other audiences this six months only refer to
                                how long people stay in the audience. Five months after creation this audience will
                                truly have six months worth of traffic.
                            </p>
                            <p>
                                On the segment members you will find the following options. It is up to you what you
                                select and what you find will suit your client more. We've split tested a lot of the
                                variations that make sense, specially for info-product clients and the audiences
                                that we've found the most valuable were, Viewed any video from a channel and
                                Subscribed to a channel.
                            </p>
                            <p>
                                We usually manage bigger time frames like 90, 180 or even 360 days for our audience
                                due to the higher price point offers we usually sell through YouTube.
                            </p>

                            <img src={image14} alt="" />

                            <p>
                                On pre-fill options always select the past 30 days as you want to have the most
                                amount of data as possible unless there's a major reason why you wouldn't. For
                                example a rebrand or change of offer where all the old data has nothing to do with
                                the new offer.
                            </p>
                            <p>
                                The membership duration can be tweaked depending on the product you are selling. If
                                the price point is low a 30 day audience will make more sense than a 90 or more day
                                audience.
                            </p>

                            <img src={image15} alt="" />

                            <h4>Cold audiences</h4>

                            <p>
                                We will get this cold audiences section started with a powerful tool that will give
                                you clarity on all the power and possibilities that you can have while running
                                YouTube ads. This will be especially useful for local businesses but can really be
                                used for any type of business.
                            </p>

                            <p>By going into the 'audience manager'</p>

                            <img src={image3} alt="" />

                            <p>Then by going into custom audiences you can create a new custom audience.</p>

                            <img src={image16} alt="" />

                            <p>
                                Now let's remember that there are two big types of 'selling' online. The first one
                                is when you are trying to sell to people that already have the intent to buy.
                            </p>
                            <p>
                                This means that they are actively looking for the product or service you have, let's
                                say you just bought a car and are looking for insurance, you already need insurance,
                                no one really had to convince you that you needed it.
                                {' '}
                            </p>
                            <p>
                                When you look for companies you will look for the best offer out there for you,
                                maybe you want the fastest company in giving it, or you want the one with the
                                fastest response on incidents, etc.
                            </p>
                            <p>
                                All the insurance companies have to tell you why and convince you that they are the
                                best choice for you. They do not need to convince you that you need the insurance.
                            </p>

                            <p>
                                Then there are other cases in which you have to do both. You need to convince them
                                that they need the product or service and that you are also the best choice for it.
                            </p>

                            <p>
                                For example imagine you are an athlete and you get an ad for a massage gun. you
                                don't necessarily need one
                            </p>

                            <img src={image17} alt="" />

                            <p>
                                Because no one is actively searching for your product or service, platforms like
                                Facebook and different social media will be the best fit.
                            </p>
                            <p>
                                YouTube comes into a grey category because no one really actively searches for
                                things to buy in it but a lot of people search for reviews or for the topic itself.
                                Even though people are not buying or looking to buy on YouTube you still have the
                                ability to target people on YouTube who are searching for your product or service on
                                Google.
                            </p>
                            <p>
                                To do this you create a custom audience with people who've searched for any keyword
                                on Google.
                            </p>
                            <p>
                                You can add them similarly to the ones you would use on google search by being
                                specific about the service or product. This way you can use Youtube which isn't
                                inherently a platform to use for marketing for people that already have intent, as a
                                platform to advertise this way.
                            </p>

                            <img src={image18} alt="" />
                            <img src={image19} alt="" />

                            <p>
                                On the custom audience section you can also put URLs of competitors or websites your
                                target audience would visit as well as apps they use and places they frequent.
                            </p>
                            <img src={image20} alt="" />

                            <p>
                                This becomes really useful as you are able to target people looking for things on
                                Google, without having to compete for the keyword in Google search and only having
                                to compete with people who:
                            </p>

                            <p> 1) know about this feature and,</p>
                            <p>2) are able to create a quality video as an ad.</p>
                            <p>
                                This audience can be selected afterwards on the campaign creation, similarly to what
                                you do when selecting the audience on a Facebook ad set.
                            </p>

                            <h4>Other cold audiences</h4>
                            <p>
                                There is plenty of interest, keywords, topics and segments you can target. I will go
                                over them now and which ones I recommend. Just keep in mind that these ones are
                                found only when creating a campaign and not in the audience section. Shortly I will
                                be doing a campaign creation walkthrough so you see where the audiences are selected
                                but for now I want to review all the possibilities and what we use at the agency
                                first.
                            </p>
                            <p>
                                <i>Demographics:</i>
                            </p>
                            <p>
                                Unlike Facebook you can't select custom age ranges but you have to select the
                                intervals they offer. For YouTube campaigns I DO recommend you select the age ranges
                                that make sense for your offering. We don't do that on Facebook but on YouTube we
                                do, for our clients we are usually between 18 and 34 years old so we select the
                                first do age ranges. We also select the gender that makes more sense if it is a
                                product that sells better in one of the two genders
                            </p>
                            <p>
                                Household income targeting is only available when you target the US. We don't use
                                this targeting feature with our US clients.
                            </p>

                            <img src={image21} alt="" />

                            <h4>Audience segments:</h4>
                            <p>Custom segments are the audiences we created based on the keywords previously.</p>
                            <p>
                                Your data are the remarketing audiences we created from YouTube users, website
                                visitors or customer lists you've uploaded.
                            </p>

                            <img src={image22} alt="" />

                            <p>
                                On interests & detailed demographics you will find a similar section to what
                                Facebook detailed targeting section shows. On the search tab you will find relevant
                                suggestions based on your previous campaigns targeting. These are the ones that are
                                showing up for one of our clients. They are relevant and some of them will work, in
                                this case they seem too broad and we wouldn't use them.
                            </p>
                            <img src={image23} alt="" />

                            <div style={{ height: 350 }}>
                                <iframe
                                    id="videoPlayer"
                                    src="https://videos.sproutvideo.com/embed/119dd7ba1f1de0cd98/b5b839444db75c39?type=hd"
                                    frameBorder="0"
                                    allowFullScreen
                                    width="100%"
                                    height="100%"
                                />
                            </div>

                            <p>
                                Similarly to Facebook, Google offers many categories that you could classify as
                                interests that are not as specific and varied but more broad as you can see here. On
                                most of them you can find subcategories that might be useful for your audience. If
                                you need anything more specific use the custom segment audiences with the specific
                                keywords you want to use.
                            </p>
                        </div>

                        <div ref={refs[3]} className="plug-block">
                            <div className="plug-block-subheading">Walkthrough creating a Youtube Campaign</div>

                            <div style={{ height: 350 }}>
                                <iframe
                                    id="videoPlayer"
                                    src="https://videos.sproutvideo.com/embed/ea9dd7ba1f1de0c263/e702a28a5fb86312?type=hd"
                                    frameBorder="0"
                                    allowFullScreen
                                    width="100%"
                                    height="100%"
                                />
                            </div>

                            <p>
                                We always use Website traffic when creating a Youtube campaign. This is the
                                equivalent of optimizing for conversions in facebook and is the best goal to select.
                            </p>

                            <p>After that you select a video as this is a YouTube video campaign.</p>

                            <p>
                                The name we don't have specific conversion for but we use a similar convention to
                                Facebook. On the campaign level what matters to us is knowing the offer and if it is
                                cold or re-marketing.
                            </p>

                            <h4>Budgets</h4>

                            <p>
                                Unlike Facebook Google is good at managing bigger budgets, higher than $100 and you
                                can get up to $2000 per campaign, this means that when you find something that is
                                working you can scale vertically by just increasing the budget on your campaign
                                without a significant hit on performance.
                            </p>

                            <p>
                                Select the countries and/or cities you want to target, language and inventory type.
                                The inventory refers to videos that are in special categories for example explicit
                                videos, we usually leave it as standard but you can use the expanded if it makes
                                sense on what you are offering. We would for example use expanded when advertising
                                to a dating platform.
                            </p>

                            <p>
                                The ad group name should refer to the audience you are targeting and if you are
                                using any specific placements. We will go over the placements shortly. In this case
                                we leave them open as we want to be shown to everyone in the audience we selected.
                                Here an example is shown were you can make you ad appear in specific channels.
                            </p>

                            <p>
                                To create the ad you need a youtube link that can be public or unlisted, a landing
                                URL, a short and long headline and that's it! You are ready to go.
                            </p>

                            <h4>Extension on placements.</h4>

                            <p>
                                You can show your ads only when people search on youtube for specific keywords,
                                similarly to what google search ads are, you can also get keyword ideas by putting
                                your or your competition’s websites.
                            </p>

                            <img src={image24} alt="" />

                            <p>
                                If you select a topic your ads will only be shown when someone is watching a video
                                on that specific topic, if you selected a topic and an audience they must be in both
                                to be shown the ad.
                            </p>

                            <p>
                                Finally, you can show up on specific videos and channels. This is a more tricky
                                placement to select, and although you can select your competitions videos or
                                channels it is usually not a good idea as they are already invested on that other
                                channel. If you are going to use this feature it is recommended to use related
                                channels instead of direct competition.
                            </p>

                            <img src={image25} alt="" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
