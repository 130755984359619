import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
// import { Link } from 'react-router-dom';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        available: true,
        sectionName: 'Walkthrough',
    },
];

const title = "How to Set Up an UpWork Account"
export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]}  className="plug-block"> 
                            <div className="plug-block-subheading">Before you start:</div>

                            <p>
                                Go to
                                {' '}
                                <a
                                    href="https://www.upwork.com/nx/signup/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    UpWork.com
                                    {' '}
                                </a>
                                to create your account.
                            </p>

                            <img
                                src="https://genflow-images-prod-eu-west-1.s3.eu-west-1.amazonaws.com/gya/plugandplays/image2.png"
                                alt=""
                            />

                            <p>
                                Fill in your details and click that you want to ‘Work as a
                                freelancer’.
                            </p>

                            <p>
                                Next, verify your email address - you’ll be taken to this page.
                            </p>

                            <img
                                src="https://genflow-images-prod-eu-west-1.s3.eu-west-1.amazonaws.com/gya/plugandplays/image6.png"
                                alt=""
                            />

                            <p>Click ‘continue’.</p>

                            <img
                                src="https://genflow-images-prod-eu-west-1.s3.eu-west-1.amazonaws.com/gya/plugandplays/image7.png"
                                alt=""
                            />

                            <p>
                                Fill out ‘Sales & Marketing’ as your category and SMM as your
                                sub-category (depending on your niche).
                            </p>

                            <img
                                src="https://genflow-images-prod-eu-west-1.s3.eu-west-1.amazonaws.com/gya/plugandplays/image3.png"
                                alt=""
                            />

                            <p>Select up to 9 skills and tailor them to your niche.</p>

                            <img
                                src="https://genflow-images-prod-eu-west-1.s3.eu-west-1.amazonaws.com/gya/plugandplays/image5.png"
                                alt=""
                            />

                            <p>Click ‘Expert’.</p>

                            <p>Ignore employment.</p>

                            <p>
                                Input your education - your highest level, and keep it vague.
                            </p>

                            <p>Choose English as native or bilingual. </p>

                            <p>Preview your profile, and then submit it. </p>

                            <img
                                src="https://genflow-images-prod-eu-west-1.s3.eu-west-1.amazonaws.com/gya/plugandplays/image1.png"
                                alt=""
                            />

                            <p>
                                <span className="bold">Important:</span>
                                Your hourly rate does not directly reflect how much you will
                                charge clients.
                            </p>
                        </div>

                        <p>
                            This is merely an indicator of the price of your retainer to
                            clients.
                        </p>

                        <p>
                            As you first start, choose an hourly rate between $10-$20: this
                            will indicate that you are affordable yet skilled.
                        </p>

                        <img
                            src="https://genflow-images-prod-eu-west-1.s3.eu-west-1.amazonaws.com/gya/plugandplays/image4.png"
                            alt=""
                        />

                        <p>Input your title and professional overview.</p>

                        <p>
                            Upload your profile photo, input your address and phone number.
                        </p>

                        <p>Read an example overview here:</p>

                        <div className="examples-block">
                            <p>
                                I'm a Facebook and Google marketing expert, working exclusively
                                with e-commerce brands to quickly scale using cold and
                                retargeting advertising.
                            </p>

                            <p>
                                I'm one of the few so-called experts in the industry who has
                                spent millions of dollars across Facebook and Google in the last
                                few years - and has generated astonishing results and returns
                                for my clients in the process.
                            </p>

                            <p>
                                I don't offer social media management or content creation - so
                                if you're looking to get started or slowly build up your
                                Instagram, have a look at some of the other wonderful
                                professionals that UpWork has to offer.
                            </p>

                            <p>
                                But if you're looking to capitalise on your existing brand with
                                an existing social media following, web presence and consistent
                                traffic to your website: I won't just be able to help, I'll be
                                able to ensure your sales skyrocket.
                            </p>

                            <p>
                                Here's a quick overview of some of the clients we've worked with
                                previously and currently:
                            </p>

                            <ul>
                                <li>
                                    One of the original wearable tech brands with millions in
                                    revenue
                                </li>
                                <li>A boutique swimwear brand based in London, UK</li>
                                <li>Germany's leading Design Sprint academy</li>
                                <li>Another London-based clothing and eyewear brand</li>
                                <li>An international bespoke jewellery brand</li>
                                <li>An innovative percussive sports massage device</li>
                            </ul>

                            <p>
                                I'm able to guarantee you incredible results - but will only do
                                so if we're the right fit for one another.
                            </p>

                            <p>
                                That's why I'm happy to offer a free consultation with myself to
                                get a good handle on your business, and if we can help you.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
