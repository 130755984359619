import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    // {
    //     available: true,
    //     sectionName: '',
    // },
];

const title = '[Paid  Version] How To Use Our Client Bible Template And Connect It With Supermetrics';

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                // selectedSection={selectedSection}
                                // setSelectedSection={onSelectSection}
                                // filter={filter}
                                // selectFilter={selectFilter}
                                // subFilter={subFilter}
                                // selectSubFilter={selectSubFilter}

                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]} className="plug-block">
                            <h2 className="mb-4">{title}</h2>   

                            <ol>
                                <li>
                                    Access the template here:
                                    <br />
                                    {' '}
                                    Datastudio for Lead Gen clients:
                                    <br />
                                    {' '}
                                    <a className="break-words" href="https://datastudio.google.com/reporting/69d24ba3-0e85-4e8e-ae57-888400425788" target="_blank" rel="noopener noreferrer">https://datastudio.google.com/reporting/69d24ba3-0e85-4e8e-ae57-888400425788</a>
                                    {' '}
                                    <br />
                                    {' '}
                                    Datastudio for Ecom clients:
                                    {' '}
                                    <br />
                                    <a className="break-words" href="https://datastudio.google.com/reporting/e3b9e432-71e9-47f8-9904-e3775837f175" target="_blank" rel="noopener noreferrer">https://datastudio.google.com/reporting/e3b9e432-71e9-47f8-9904-e3775837f175</a>
                                </li>
                                <li>
                                    Make a copy of our template by clicking on the “make a copy” button at the top right corner, as shown in the image below
                                    <img src="https://img.genflow.com/gya/plugandplays/paidClientBible1.png" alt="" />
                                </li>
                                <li>
                                    A pop-up will open asking you to select your own databases. Those are the databases that Datastudio will pull the data from. You can see under “Original Data Source” that there are two data sources. The first one represents the Supermetrics connector, which pulls the data directly from your Facebook Ads Account. The second one represents the Campaign Changelog spreadsheet. We now have to change those to your databases, so that Datastudio can read your data. To do that, click on the first row saying “select a datasource…” under “New Data Source”. After you clicked, click on “Create new data source”, as shown in the pictures below:

                                    <img src="https://img.genflow.com/gya/plugandplays/paidClientBible2.png" alt="" />
                                    <img src="https://img.genflow.com/gya/plugandplays/paidClientBible3.png" alt="" />

                                </li>
                                <li>
                                    Now, we have to select the Supermetrics Facebook Ads connector. It’s usually the 2nd one under “Partner Connectors”. You can also search for it using the search bar. Once you find it, just click on it.

                                    <img src="https://img.genflow.com/gya/plugandplays/paidClientBible4.png" alt="" />
                                </li>
                                <li>
                                    If that’s the first time you’re connecting to Supermetrics, it will request authorization to use your Google Account. You just click on “Authorize”. A new tab will open, where you just click “Allow”.

                                    <img src="https://img.genflow.com/gya/plugandplays/paidClientBible5.png" alt="" />
                                </li>
                                <li>
                                    Now Supermetrics will request authorization from your Facebook account. You should click on “authorize”. Again, a new tab will show up where you just have to click on “Continue with Facebook” and connect your Facebook account.

                                    <img src="https://img.genflow.com/gya/plugandplays/paidClientBible6.png" alt="" />
                                </li>
                                <li>
                                    Once you connected to both your Google and Facebook accounts, you’ll now have to select the ad account that you want to pull the data from. To do that, you just click on the “Select Account” dropdown and select your desired ad account. You can leave the other options as they are, so once you select the ad account you just click on “Connect” at the top right corner.

                                    <img src="https://img.genflow.com/gya/plugandplays/paidClientBible7.png" alt="" />

                                </li>
                                <li>
                                    On the next screen you don’t really need to change anything, just click on “add to report”. Datastudio should then go back to the same “copy report” pop-up as we were before, just now with your new database selected.
                                    <br />
                                    (important: Datastudio sometimes glitches in this part of this process and just go back straight to the reporting, without the “copy this report” pop-up open. When this happens, you have to refresh the page and repeat all these same steps that we just performed. If the error persists, you might need to give it a few minutes - it will eventually work. That has been a known bug for a while and they’re working on fixing it.

                                    <img src="https://img.genflow.com/gya/plugandplays/paidClientBible8.png" alt="" />
                                </li>
                                <li>
                                    Now we have to select our second database: the campaign changelog spreadsheet. (You can access the template here and make a copy of it:
                                    <a className="break-words" href="https://docs.google.com/spreadsheets/d/1bRmiCc45WUvxgVE5AJms9ru99e5bOMalNZoRYbM2rrg/edit?usp=sharing)" target="_blank" rel="noopener noreferrer">https://docs.google.com/spreadsheets/d/1bRmiCc45WUvxgVE5AJms9ru99e5bOMalNZoRYbM2rrg/edit?usp=sharing)</a>
                                    . Now, under “New Data Source” you should click on the second row and then on “Create new data source”
                                </li>
                                <li>
                                    Now we have to select our Google Sheets connector. It’s the 3rd one under “Google Connectors”. Click on it.

                                    <img src="https://img.genflow.com/gya/plugandplays/paidClientBible9.png" alt="" />
                                </li>
                                <li>
                                    Then you select the client bible reporting sheet which you made a copy of on step #7. You first select the spreadsheet itself and then the worksheet (the page, really) that we called “Campaign Changelog”. After that’s done, you just click on “connect” at the top right corner.

                                    <img src="https://img.genflow.com/gya/plugandplays/paidClientBible10.png" alt="" />

                                </li>
                                <li>
                                    On this next page, we don’t really need to change anything. So you just click on “add to report” at the top right corner.


                                </li>
                                <li>
                                    And now Datastudio will again go back to the initial “copy this report” pop-up. We have everything ready to create our copy, so you just have to click on the “copy report” button.


                                </li>
                                <li>
                                    And your reporting is created! In case you want to change any metric, you can just click on the metric that you want changed and use the drag and drop at the right portion of your screen to do that.

                                </li>
                            </ol>

                            <div className="plug-block-subheading">
                                Weekly workflow:
                            </div>

                            <p>
                                Each monday you need to fill out the ‘A&R report’ in which you’ll write any and every important change or trend that you’re seeing with the ad account. For instance, if you found new winner audiences you can state it there, or if ads got disapproved it should also be there.
                            </p>

                            <p>We do this to ease communication with our clients and also for them to know we are working hard for them. </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
