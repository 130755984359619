import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import { Link } from 'react-router-dom';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        available: true,
        sectionName: 'In today\'s teaching',
    },
    {
        available: true,
        sectionName: 'What is CTR',
    },
    {
        available: true,
        sectionName: 'How to practically use this metrics and their meaning',
    },
];

const title = 'The Two King Metrics And Their Math';

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]}  className="plug-block"> 
                            <div className="plug-block-subheading">
                                In today's teaching
                            </div>

                            <ul>
                                <li>
                                    What is CPM
                                </li>
                                <li>
                                    What is CTR
                                </li>
                                <li>
                                    How to practically use this metrics and their meaning
                                </li>
                                <li>
                                    Some industry standards
                                </li>
                                <li>
                                    Troubleshooting CPM
                                </li>
                                <li>
                                    Troubleshooting CTR
                                </li>
                            </ul>

                            <p>
                                CPM means cost per 1000 impressions, it is often useful to determine the cost of the ad platform of your choice. Facebook and other online advertising systems are based on an auction process where thousands of advertisers are bidding for the same advertising real estate. It is a simple supply and demand dynamic where the more advertisers that want to buy ad space the more it costs, and the more advertising space available the less it does.
                            </p>

                            <p>
                                And even within each platform the CPMs will vary depending on other factors like the audience you chose, the objective or optimization event, country and - especially on Facebook -  how good your ad is.
                            </p>

                            <p>
                                So even though the objective, country audience and all those factors are the same you can have radically different CPMs
                            </p>

                            <p>
                                It is also very common  to advertise for one client in the US and have $5-$7 CPMs but when advertising with the same interests and country for another client account to find $15+ CPMs.
                            </p>

                            <p>
                                Also CPMs are a metric specific to each ad: each one has a different CPM, even in the same ad set.
                            </p>

                            <p>
                                You can see here some ads have almost twice what the lowest CPM ads has (which just happens to be the top-performing ad for this client).
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/twoKingMetrics1.png" alt="" />
                            <img src="https://img.genflow.com/gya/plugandplays/twoKingMetrics2.png" alt="" />

                            <p>
                                What this means is that Facebook values as well the type of experience their users are having with the ads, they have metrics and ways of determining this that they don't disclose but have to do with their 'Quality ranking', 'Engagement rate ranking' and 'Conversion rate ranking'.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/twoKingMetrics3.png" alt="" />

                            <p>
                                It is not clear how this weighs exactly on the CPM calculation.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/twoKingMetrics4.png" alt="" />

                            <p>
                                All the metrics are ranked as above average, average, below average (bottom 35% of ads), below average (bottom 20% of ads) and below average (bottom 10% of ads).
                            </p>

                            <p>
                                Practically this means that they're telling you how you compare to the other ads and advertisers that are competing for the same audiences as you are. All these rankings are only available after your ad has gotten at least 500 impressions.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/twoKingMetrics5.png" alt="" />

                            <p>
                                Quality Ranking: Quality is measured using feedback on your ads and the post click experience. This feedback includes the number of times that your ad has been hidden and assessments of clickbait, engagement bait, and other negative experiences (like people reporting your ads for being misleading, spam, untrue, etc).
                            </p>

                            <p>
                                Conversion Rate Ranking: A ranking of your ad's expected conversion rate, it estimates the probability that the optimization event you chose will be completed and you are competing against people that chose the same goal. We've tested the optimizing for something different than purchases - as everybody optimizes for purchases - but even if the conversion ranking was better the CPA was higher in every test.
                            </p>

                            <p>
                                These estimates are based on the audience you chose and how your ad is doing in the auction process.
                            </p>

                            <p>
                                Engagement Rate Ranking: A ranking of your ad's expected engagement rate. Engagement includes all clicks, likes, comments and shares. Every interaction counts on this one, you want to want the users attention, if they expand the image, play the video, click on the page, click on see more on the text, react, like your page, etc.
                            </p>

                            <div style={{ height: 350 }}>
                                <iframe
                                    id="videoPlayer"
                                    className="sproutvideo-player"
                                    src="https://videos.sproutvideo.com/embed/d39dd7bd1f1ce3c25a/7f6486c1501a402c?playerTheme=dark&amp;playerColor=263238"
                                    frameBorder="0"
                                    allowFullScreen
                                    width="100%"
                                    height="100%"
                                />
                            </div>


                            <p>Now practically all these metrics do say something but it is not so easy to determine how to use them. In general a good ad will perform well in this metrics but it is not always intuitive and you'll find often that the ads you feel would do best end up not and the ones you were less confident in doing better. The better these three metrics are,  the lower CPM's you'll get.</p>
                        </div>

                        <div className="plug-block">
                            <div className="plug-block-subheading">
                                What is CTR
                            </div>

                            <p>
                                CTR stands for Click Through Rate, it is a percentage calculated based on the amounts of clicks on your ad. It indicates how many clicks you're getting against the amount of impressions you're getting. This is the formula used to calculate CTR
                            </p>

                            <p>
                                $CTR=(LinkClicks/Impressions)*100$
                            </p>

                            <p>
                                You can use this screenshot to see that Facebook uses the same formula:
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/twoKingMetrics6.png" alt="" />

                            <p>
                                CTR in a more practical sense, measures how good your ad is.
                            </p>

                            <p>
                                However, it also reflects other factors such as the audience you choose, and less deterministic factors like luck.
                            </p>

                            <p>
                                CTR doesn't include any price metrics, which means it is independent from CPM, you might have a high CPM and a good CTR (A good CTR is a high one, the higher the better).
                            </p>

                            <p>
                                If people are not clicking on the ad there won't be enough volume of traffic on the websites and no matter how good your conversion rate it will be very difficult to make it work.
                            </p>

                            <p>
                                There are many nuances to this metric, you can get it skewed by using clickbait images, promising things that are not actually on the landing page or that you don't actually offer and there are some other products or offers that just by nature will appeal to less people.
                            </p>

                            <p>
                                Even if this is the case Facebook will find the best suited people or you will do it with your targeting. With all these things in mind we will still give some ball-park numbers you should aim for.
                            </p>

                            <p>
                                Our goal with every client is to get at least a 1.5% CTR, no matter the niche, price point, offer, etc. This is achieved mainly with the ad itself, we tweak angles (copy and images/videos) until we get our desired CTR.
                            </p>

                            <p>
                                You can see the CTR of each ad, you will notice that each ad will get a different CTR and even the same ad on different ad sets will get different CTR's.
                            </p>

                            <p>
                                This means that you should really aim is for an average CTR of around 1.5%
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/twoKingMetrics7.png" alt="" style={{ width: '50%' }} />

                            <p>
                                With that being said we have clients that we run profitably with CTR's of 0,67% and we have clients that have over 4%. This is only a metric to gage the ad, but it doesn't mean it will make or break the whole thing. Remember that this only means how many people are clicking the ad, but with a high CPM, a bad conversion rate on the website a 10% CTR won't make it work.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/twoKingMetrics8.png" alt="" style={{ width: '51%' }} />
                            <img src="https://img.genflow.com/gya/plugandplays/twoKingMetrics9.png" style={{ width: '50%' }} alt="" />
                        </div>

                        <div className="plug-block">
                            <div className="plug-block subheading">
                                How to practically use this metrics and their meaning
                            </div>

                            <p>
                                CTR and CPM are really measuring the media buying side of things: they're telling you how good your ad is and how good it is in relation to your audience.
                            </p>

                            <p>
                                Although intuitively, and considering the formula of CPM, it seems like the only variable is the audience and the objective of the campaign, the truth is that it is almost a metric on how good your ad is as well. Facebook rewards ads that people interact with and like with lower CPMs as they are giving a better experience to the users. If you're having high CPMs or low CTRs there's a few things you can do.
                            </p>

                            <p>
                                If you followed our whole process you will have angles that have excelled more than others, in this case your best bet is that out of the four to six angles you tried you keep the best two and write another two to four to test. You need to radically change your approach and make sure you're thinking differently with your creatives and what you're saying through your ad.
                            </p>

                            <p>
                                Test new interests and more ad sets. As described previously all Facebook's ranking systems work as a competition which means that you're competing with everyone else that is targeting your audience. When you change audiences you'll get different kinds of results, sometimes the same ads will work on different ad sets and you get unlucky on your first try. After watching the module
                                {' '}
                                <Link to="/resources/plug-and-play/how-facebook-works-on-the-inside">"How Facebook works on the inside"</Link>
                                {' '}
                                you will be able to understand why you could even relaunch the same ad set and get different results. A lot of the time it doesn't work right away and you need a couple tries before on the same audience.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
