import gql from 'graphql-tag';

export default gql`
    query ($vendorUuid: ID!, $status: String){
        getAdminClientCustomerPayments(vendorUuid: $vendorUuid, status: $status) {
            errors
            data {
                cognitoUser {
                    Username
                    Attributes {
                        Name
                        Value
                    }
                    UserCreateDate
                    UserLastModifiedDate
                }
                payments {
                    id
                    createdTimestamp
                    updatedTimestamp
                    paymentTitle
                    paymentType
                    totalValue
                    totalCurrency
                    metadata
                    clientCustomerId
                    paymentSchedule
                }
            }
        }
    }
`;
