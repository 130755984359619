import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    TabContent,
    TabPane,
} from 'reactstrap';
// core components
import Footer from 'components/Footer';
import useCourse from '@genflow/web-courses/hooks/useCourse';
import { useConfig } from '@genflow/core';
import { get, parseInt } from 'lodash';
import { useParams } from 'react-router';

export default () => {
    const [navState] = useState({ tabs: 2 });

    const { lessonID: articleID } = useParams();
    const { vendorUuid, resourceLibraryProductID, resourcesArticlesSectionID } = useConfig();
    const { data } = useCourse(vendorUuid, resourceLibraryProductID);
    const articlesSection = get(data, 'sections', []).find(({ id }) => parseInt(id) === resourcesArticlesSectionID);
    const article = get(articlesSection, 'lessons', []).find(({ id }) => parseInt(id) === parseInt(articleID));

    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, []);

    const description = get(article, 'assets[0].description') || '';
    const url = get(article, 'assets[0].url', '');
    const urlIsYouTubeEmbed = url.includes('youtube.com/embed');

    return (
        <>
            <main className="profile-page">


                <section className="section-profile-cover section-shaped my-0">
                    <Container>
                        <Row>
                            <Col lg={12} className="text-center">
                                <h1>
                                    {get(article, 'name', '')}
                                    .
                                </h1>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="section section-lg pt-lg-0 mt--5">
                    <Container>
                        <Row className="justify-content-center">

                            <div className="articles">
                                {
                                    urlIsYouTubeEmbed && (
                                        <video src={url} />
                                    )
                                }
                                <p dangerouslySetInnerHTML={{ __html: description.replace(/\r\n|\n|\r/gm, '<br />', '<br />') }} />

                            </div>
                            <Card className="w-100 resources-card">
                                <CardBody>
                                    <TabContent activeTab={`tabs${navState.tabs}`}>
                                        {
                                            navState.tabs === 1 && (
                                                <TabPane tabId="tabs3">
                                                    <Row className="video-library">
                                                        <Col lg={6}>
                                                            <iframe title="3 Secret SMMA" src="https://www.youtube.com/embed/wWbjo3YYbWk" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                                                            <p>3 Secret SMMA Niches for 2020</p>
                                                        </Col>
                                                        <Col lg={6}>
                                                            <iframe title="3 Secret SMMA" src="https://www.youtube.com/embed/wWbjo3YYbWk" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                                                            <p>3 Secret SMMA Niches for 2020</p>
                                                        </Col>
                                                        <Col lg={6}>
                                                            <iframe title="3 Secret SMMA" src="https://www.youtube.com/embed/wWbjo3YYbWk" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                                                            <p>3 Secret SMMA Niches for 2020</p>
                                                        </Col>
                                                        <Col lg={6}>
                                                            <iframe title="3 Secret SMMA" src="https://www.youtube.com/embed/wWbjo3YYbWk" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                                                            <p>3 Secret SMMA Niches for 2020</p>
                                                        </Col>

                                                    </Row>

                                                    <nav aria-label="...">
                                                        <Pagination className="pagination">
                                                            <PaginationItem className="disabled">
                                                                <PaginationLink
                                                                    href="#pablo"
                                                                    onClick={e => e.preventDefault()}
                                                                    tabIndex="-1"
                                                                >
                                                                    <i className="fa fa-angle-left" />
                                                                    <span className="sr-only">Previous</span>
                                                                </PaginationLink>
                                                            </PaginationItem>
                                                            <PaginationItem>
                                                                <PaginationLink href="#pablo" onClick={e => e.preventDefault()}>
                                                                    1
                                                                </PaginationLink>
                                                            </PaginationItem>
                                                            <PaginationItem className="active">
                                                                <PaginationLink href="#" onClick={e => e.preventDefault()}>
                                                                    2
                                                                    {' '}
                                                                    <span className="sr-only">(current)</span>
                                                                </PaginationLink>
                                                            </PaginationItem>
                                                            <PaginationItem>
                                                                <PaginationLink href="#" onClick={e => e.preventDefault()}>
                                                                    3
                                                                </PaginationLink>
                                                            </PaginationItem>
                                                            <PaginationItem>
                                                                <PaginationLink href="#" onClick={e => e.preventDefault()}>
                                                                    <i className="fa fa-angle-right" />
                                                                    <span className="sr-only">Next</span>
                                                                </PaginationLink>
                                                            </PaginationItem>
                                                        </Pagination>
                                                    </nav>
                                                </TabPane>
                                            )
                                        }
                                        {
                                            navState.tabs === 2 && (
                                                <TabPane tabId="tabs2">
                                                    <Row className="articles" />
                                                    <nav aria-label="...">
                                                        {/* <Pagination className="pagination"> */}
                                                        {/*  <PaginationItem> */}
                                                        {/*    <PaginationLink */}
                                                        {/*        href="#" */}
                                                        {/*        onClick={e => e.preventDefault()} */}
                                                        {/*        tabIndex="-1" */}
                                                        {/*    > */}
                                                        {/*      <i className="fa fa-angle-left" /> */}
                                                        {/*      <span className="sr-only">Previous</span> */}
                                                        {/*    </PaginationLink> */}
                                                        {/*  </PaginationItem> */}
                                                        {/*  <PaginationItem className="active"> */}
                                                        {/*    <PaginationLink href="#" onClick={e => e.preventDefault()}> */}
                                                        {/*      1 */}
                                                        {/*    </PaginationLink> */}
                                                        {/*  </PaginationItem> */}
                                                        {/*  <PaginationItem> */}
                                                        {/*    <PaginationLink href="#" onClick={e => e.preventDefault()}> */}
                                                        {/*      2 <span className="sr-only">(current)</span> */}
                                                        {/*    </PaginationLink> */}
                                                        {/*  </PaginationItem> */}
                                                        {/*  <PaginationItem> */}
                                                        {/*    <PaginationLink href="#pablo" onClick={e => e.preventDefault()}> */}
                                                        {/*      3 */}
                                                        {/*    </PaginationLink> */}
                                                        {/*  </PaginationItem> */}
                                                        {/*  <PaginationItem> */}
                                                        {/*    <PaginationLink href="#" onClick={e => e.preventDefault()}> */}
                                                        {/*      <i className="fa fa-angle-right" /> */}
                                                        {/*      <span className="sr-only">Next</span> */}
                                                        {/*    </PaginationLink> */}
                                                        {/*  </PaginationItem> */}
                                                        {/* </Pagination> */}
                                                    </nav>
                                                </TabPane>
                                            )
                                        }
                                        <TabPane tabId="tabs1">
                                            <Row className="video-library">
                                                <Col lg={6}>
                                                    <iframe title="Video Name" src="https://www.youtube.com/embed/w2CgCWKoNA0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                                                    <p>Video Name</p>
                                                </Col>
                                                <Col lg={6}>
                                                    <iframe title="Video Name" src="https://www.youtube.com/embed/w2CgCWKoNA0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                                                    <p>Video Name</p>
                                                </Col>
                                                <Col lg={6}>
                                                    <iframe title="Video Name" src="https://www.youtube.com/embed/w2CgCWKoNA0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                                                    <p>Video Name</p>
                                                </Col>
                                                <Col lg={6}>
                                                    <iframe title="Video Name" src="https://www.youtube.com/embed/w2CgCWKoNA0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                                                    <p>Video Name</p>
                                                </Col>
                                            </Row>

                                            <nav aria-label="...">
                                                <Pagination className="pagination">
                                                    <PaginationItem className="disabled">
                                                        <PaginationLink
                                                            href="#pablo"
                                                            onClick={e => e.preventDefault()}
                                                            tabIndex="-1"
                                                        >
                                                            <i className="fa fa-angle-left" />
                                                            <span className="sr-only">Previous</span>
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                    <PaginationItem>
                                                        <PaginationLink href="#pablo" onClick={e => e.preventDefault()}>
                                                            1
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                    <PaginationItem className="active">
                                                        <PaginationLink href="#" onClick={e => e.preventDefault()}>
                                                            2
                                                            {' '}
                                                            <span className="sr-only">(current)</span>
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                    <PaginationItem>
                                                        <PaginationLink href="#" onClick={e => e.preventDefault()}>
                                                            3
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                    <PaginationItem>
                                                        <PaginationLink href="#" onClick={e => e.preventDefault()}>
                                                            <i className="fa fa-angle-right" />
                                                            <span className="sr-only">Next</span>
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                </Pagination>
                                            </nav>

                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>

                            <Container className="p-0 mt-5">
                                <Card className="bg-gradient-primary shadow-lg border-0">
                                    <div className="p-5">
                                        <Row className="align-items-center">
                                            <Col lg="8">
                                                <h3 className="text-white">
                                                    Book an upcoming zoom call.
                                                </h3>
                                            </Col>
                                            <Col className="ml-lg-auto" lg="3">
                                                <Button
                                                    block
                                                    className="btn-white"
                                                    color="default"
                                                    href="#"
                                                    size="lg"
                                                >
                                                    <i className="fa fa-phone" />
                                                    {' '}
                                                    Book Zoom Call
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Container>
                        </Row>
                    </Container>
                </section>
            </main>
            <Footer />
        </>
    );
};
