import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
// import { Link } from 'react-router-dom';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        sectionName: 'Before you start:', available: true,
    },
    {
        sectionName: 'MyIP.ms', available: true,
    },
    {
        sectionName: 'Department Stores, Amazon', available: true,
    },
    {
        sectionName: 'Media, Awards', available: true,
    },
    {
        sectionName: 'Instagram', available: true,
    },
    {
        sectionName: 'Facebook Ad Library', available: true,
    },
    {
        sectionName: 'KickStarter', available: true,
    },
];

const title = "Finding Leads for eCommerce"

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]}  className="plug-block">  
                        <h2 className="mb-4">{title}</h2>
                            <div className="plug-block-subheading">
                                Before you start:
                            </div>

                            <p>
                                What you’ll need:
                            </p>

                            <ul>
                                <li><a href="https://myip.ms/browse/sites" target="_blank" rel="noopener noreferrer">MyIP.MS</a></li>
                                <li>
                                    Instagram Account
                                </li>
                                <li>
                                    Facebook  Account
                                </li>
                            </ul>

                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">
                                MyIP.ms

                            </div>

                            <p>
                                <a href="https://myip.ms/browse/sites" target="_blank" rel="noopener noreferrer">MyIP.MS</a>
                                {' '}
                                is a website database that allows you to search websites via a number of filters including host, visitors, location and site name.
                                {' '}
                            </p>

                            <p>
                                As Shopfiy is the most popular ecommerce platform, this allows you to search via this filter to find ecommerce businesses.
                            </p>

                            <p>Use the following filter settings:</p>

                            <img src="https://genflow-images-prod-eu-west-1.s3.eu-west-1.amazonaws.com/gya/plugandplays/image4-copy.png" alt="" />

                            <p>
                                <span className="bold">
                                    Top Level Host:
                                </span>
                                {' '}
                                Shopify
                            </p>
                            <p>
                                <span className="bold">
                                    Popularity (visitors per day):
                                </span>
                                {' '}
                                300-3000
                            </p>
                            <p>
                                <span className="bold">
                                    Full/ Part Website Name: within the niche
                                </span>
                                {' '}
                                Search for your niche or popular names
                            </p>

                            <p>
                                You can then export these leads as a spreadsheet using this:
                            </p>

                            <img src="https://genflow-images-prod-eu-west-1.s3.eu-west-1.amazonaws.com/gya/plugandplays/image1-copy.png" alt="" />

                            <div style={{height: 350}}>
                            <iframe
                                                id="videoPlayer"
                                                className="sproutvideo-player"
                                                src={`https://videos.sproutvideo.com/embed/069ddab8151be0c58f/01a8fd7258208515?type=hd`}
                                                frameBorder="0"
                                                allowFullScreen
                                                width="100%"
                                                height="100%"
                                            />
                            </div>
                        </div>


                        <div ref={refs[2]} className="plug-block">
                            <div className="plug-block-subheading">
                                Department Stores, Amazon

                            </div>

                            <p>
                                Department stores websites - or even Amazon - are great sources for leads who also offer direct-to-consumer sales via eCommerce.
                            </p>

                            <p>
                                For example, in the UK, you could go to Selfridges and
                                <a href="https://www.selfridges.com/GB/en/cat/?freeText=EDIT+PE+VEGAN&srch=Y" target="_blank" rel="noopener noreferrer">search for ‘Vegan’</a>
                                {' '}
                                or on Amazon and
                                <a href="https://www.amazon.co.uk/s?k=smart+watch&ref=nb_sb_noss_2" target="_blank" rel="noopener noreferrer">search for ‘Smart Watches’</a>
                                {' '}
                                .
                                {' '}
                            </p>
                        </div>


                        <div ref={refs[3]} className="plug-block">
                            <div className="plug-block-subheading">
                                Media, Awards

                            </div>

                            <p>
                                This is a handy technique both for lead sourcing, and for outreach.
                            </p>

                            <p>
                                Search on Google for:
                            </p>

                            <ul>
                                <li>
                                    Best *** Companies in the UK/ USA etc., 2021
                                </li>
                                <li>
                                    Fastest Growing *** Companies 2021
                                </li>
                                <li>
                                    New *** Companies 2021
                                </li>
                            </ul>

                            <p>
                                Take a look at
                                <a href="https://ecowarriorprincess.net/2020/01/10-ethical-vegan-fashion-brands-in-2020/" target="_blank" rel="noopener noreferrer">this example</a>
                                {' '}
                                :
                            </p>

                            <img src="" alt="" />

                            <p>
                                Even though this is a low yield, you have an easy angle to email these companies: let them know where you found them (i.e. the article): this shows them you have an organic reason for reaching out.
                            </p>

                        </div>


                        <div ref={refs[4]} className="plug-block">
                            <div className="plug-block-subheading">
                                Instagram

                            </div>

                            <p>
                                The Instagram algorithm is almost always spoken about negatively but this simple method allows you to use it for your benefit.
                            </p>

                            <p>
                                Start by going to the Instagram account of a company that you would like to work with - or one which is similar. This doesn’t mean you should go to Nike’s page if you want to work with a small start-up (try and pick a smaller brand that’s more similar to the type you want to go after).
                            </p>

                            <p>
                                For example, Will’s Vegan Shoes. If you’re looking to work with ethical retailers, there are larger examples: like Stella McCartney but these are mega brands which are nearly impossible to sign.
                            </p>

                            <p>Head over to their Instagram page:</p>

                            <img src="https://genflow-images-prod-eu-west-1.s3.eu-west-1.amazonaws.com/gya/plugandplays/image-7.png" alt="" />

                            <p>
                                When you click ‘follow’, a bar will appear above the Story Highlights:
                            </p>

                            <img src="https://genflow-images-prod-eu-west-1.s3.eu-west-1.amazonaws.com/gya/plugandplays/image5-2.png" alt="" />

                            <p>
                                Click ‘See All’ and Instagram will provide you with a list of similar businesses based off similar followers:
                            </p>

                            <img src="" alt="" />
                        </div>


                        <div ref={refs[5]} className="plug-block">
                            <div className="plug-block-subheading">
                                Facebook Ad Library

                            </div>


                            <p>
                                The
                                <a href="https://www.facebook.com/ads/library/?active_status=all&ad_type=political_and_issue_ads&country=GB&media_type=all" target="_blank" rel="noopener noreferrer">Facebook Ads Library</a>
                                {' '}
                                now allows you to search by keyword.
                            </p>
                        </div>

                        <p>
                            For example, you may search ‘dog’ and be served with thousands of potential leads:
                        </p>

                        <img src="https://genflow-images-prod-eu-west-1.s3.eu-west-1.amazonaws.com/gya/plugandplays/image-9.png" alt="" />

                        <p>
                            When you hover over the Facebook page, it will display the number of likes. This will allow you to steer clear of mega brands.
                        </p>

                        <p>
                            Next, click through to the Facebook page and perform an audit as you would usually.
                        </p>


                        <div ref={refs[6]} className="plug-block">
                            <div className="plug-block-subheading">
                                KickStarter

                            </div>

                            <p>KickStarter is an incredible database of fully-to- partially-funded businesses which you can filter by industry.</p>

                            <p>
                                Take a look at
                                <a href="https://www.kickstarter.com/discover/advanced?category_id=16&raised=2&sort=magic&seed=2700254&page=1" target="_blank" rel="noopener noreferrer">technology products which have been fully-funded.</a>
                            </p>

                            <p>
                                Again, this is a natural angle for outreach.
                            </p>

                            <p>
                                Go to their{' '}
                                <a href="https://www.kickstarter.com/discover/pwl?ref=discovery_overlay" target="_blank" rel="noopener noreferrer">search function</a>
                                {' '}
                                , choose your category, and filter by ‘>100% funded’ to see complete projects.
                            </p>

                            <img src="https://genflow-images-prod-eu-west-1.s3.eu-west-1.amazonaws.com/gya/plugandplays/image-3.png" alt="" />
                        </div>


                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
