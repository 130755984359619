import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { get as _get, has as _has } from 'lodash';
import { UserDataContext } from '../providers/UserDataProvider';
import ListEntitlementsQuery from '../api/listEntitlements';

export default (productID) => {
    console.log(productID);
    const { userProfile, setUserProfile } = useContext(UserDataContext);
    const accessMap = {
        3351: ['agency_navigator', 'agency_navigator_only', 'agency_navigator+hamza', 'apex'],
        5283: ['agency_navigator+hamza', 'hamza', 'apex'],
        4630: ['apex'],
        4765: ['apex'],
        4766: ['apex'],
        5326: ['agency_navigator', 'agency_navigator+hamza', 'apex', 'agency_navigator_fasttrack'], // AN Fast Track
        4771: ['apex', 'the_vault'], // The Vault
        4772: ['apex'],
        4773: ['apex', 'pen_to_profit'], // Pen to profit
        5604: ['digital_launchpad', 'agency_navigator', 'apex'], // Digital Launchpad
        '/resources/qa': ['apex', 'apex_only'], // Q&A RECORDINGS
    };

    const [validAccess, setvalidAccess] = useState(false);

    const {
        data, error: entitlementError, loading,
    } = useQuery(ListEntitlementsQuery);

    useEffect(() => {
        console.log(data?.listEntitlements?.body, entitlementError, loading);
        // const responseDayData = get(data, 'getToolData.data');
        if (!loading) {
            const entitlementData = !!data?.listEntitlements?.body && JSON.parse(JSON.parse(data?.listEntitlements?.body));
            const newEntitlements = entitlementData?.entitlements;

            if (newEntitlements) {
                // save entitlements to local storage
                setUserProfile({
                    ...userProfile,
                    data: {
                        ...userProfile?.data, newEntitlements,
                    },
                });
            }

            // user entitlement would be from admin entitlement or local storage
            const userEntitlements = newEntitlements || userProfile?.data?.newEntitlements;
            // setEntitlements(newEntitlements);

            const productAccess = _get(accessMap, productID, []);

            for (const access of productAccess) {
                console.log(access);
                if (_has(userEntitlements, access)) {
                    console.log('ok');
                    setvalidAccess(true);
                }
            }
            // console.log('data', userEntitlements);
        }

        // if (responseDayData) {
        //     console.log('yo');
        //     // setEntitlements(responseDayData.map(({ data }) => JSON.parse(data)));
        // }
    }, [data, loading]);

    return {
        validAccess,
        loading,
        entitlementError,
    };
};
