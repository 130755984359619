/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import {
    Card,
    CardBody,
    Col,
    Container,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
} from 'reactstrap';
import { parseInt } from 'lodash';
import accounting from 'accounting';

import Footer from '../../../components/Footer';
import '../../../assets/scss/simple-tools.scss';
import GYAFooterScene from '../../../assets/img/footer-scene-gya.png';
import { UserDataContext } from '../../../providers/UserDataProvider';
import CurrencySelector from '../../../components/CurrencySelector';

export default () => {
    const [apv, setAPV] = useState(100);
    const [cost, setCost] = useState(50);
    const [rpy, setRPY] = useState(4);
    const [term, setTerm] = useState(2);
    const [nRef, setNRef] = useState(1);
    const { userCurrency } = useContext(UserDataContext);
    const ltv = parseInt(apv)
    * (1 - parseInt(cost) / 100)
    * parseInt(rpy)
    * parseInt(term)
    * (parseInt(nRef) + 1);

    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, []);

    return (
        <>
            <main className="simple-tools-page text-white">
                <section className="section-profile-cover section-shaped my-10 sm:my-12">
                    <Container>
                        <Row>
                            <Col lg={12} className="text-center">
                                <h1 className="hidden sm:block">Lifetime Value Calculator (LTV)</h1>
                                <h3 style={{fontSize: '26px'}} className="block sm:hidden">Lifetime Value Calculator (LTV)</h3>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="section section-lg pt-lg-3 pt-8">
                    <Container>
                        <Row className="mb-4 shadow shadow--hover">
                            <Col lg={12}>
                                <Row className="flex flex-col sm:flex-row w-11/12 sm:w-8/12 mx-auto shadow-md card">
                                    <Col lg={7} className="w-full sm:w-7/12 p-4 sm:p-8 ">
                                        <div className="left-input-card ">
                                            <CardBody>
                                                <CurrencySelector />
                                                <div className="mt-8 block sm:hidden"></div>
                                                <strong className= "body1">
                                                    Please enter the Metrics, Rates and Leads below:
                                                </strong>
                                                <Row className="tool-form-row flex flex-col sm:flex-row justify-between">
                                                    <div  className="tool-form-label body2">
                                                        Average Purchase Value
                                                    </div>
                                                    <Col sm={3} className="tool-form-input ml-0 mr-0 sm:mr-4">
                                                        <InputGroup className="flex flex-row items-center bg-transparent inputBox" style={{borderBottomColor: "#434343", marginBottom: "0px"}}>
                                                            <InputGroupAddon addonType="prepend" className="bg-transparent inputBox" style={{ marginBottom: "0px"}}>
                                                                <InputGroupText>{userCurrency}</InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder="100"
                                                                type="number"
                                                                value={apv}
                                                                className="bg-transparent p-2 w-16 sm:w-28 inputBox body2"
                                                                onChange={e => setAPV(e.target.value)}
                                                                style={{ marginBottom: "0px"}}
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                                <Row className="tool-form-row mb-8 flex flex-col sm:flex-row justify-between">
                                                    <Col sm={9} className="tool-form-label body2">
                                                        Cost of Goods/Services Sold
                                                    </Col>
                                                    <Col sm={3} className="tool-form-input ml-0 mr-0 sm:mr-4">
                                                        <InputGroup className="flex flex-row items-center bg-transparent justify-between inputBox" style={{borderBottomColor: "#434343", marginBottom: "0px"}}>
                                                            <Input
                                                                placeholder="50"
                                                                type="number"
                                                                value={cost}
                                                                onChange={e => setCost(e.target.value)}
                                                                className="bg-transparent p-2 w-16 sm:w-28 inputBox body2"
                                                                style={{ marginBottom: "0px"}}
                                                            />
                                                            <InputGroupAddon addonType="append" className="bg-transparent inputBox" style={{ marginBottom: "0px"}}>
                                                                <InputGroupText>%</InputGroupText>
                                                            </InputGroupAddon>
                                                        </InputGroup>
                                                    </Col>
                                                </Row>

                                                <strong className= "body1">Enter Customer Behaviour:</strong>
                                                <Row className="tool-form-row flex flex-col sm:flex-row justify-between">
                                                    <Col sm={9} className="tool-form-label body2">
                                                        Returns Per Year
                                                    </Col>
                                                    <Col sm={3} className="tool-form-input ml-0 mr-0 sm:mr-4">
                                                        <Input
                                                            placeholder="4"
                                                            type="number"
                                                            value={rpy}
                                                            onChange={e => setRPY(e.target.value)}
                                                            className="bg-transparent p-2 w-full sm:w-32 inputBox body2"
                                                            style={{borderBottomColor: "#434343", marginBottom: "0px"}}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="tool-form-row flex flex-col sm:flex-row justify-between">
                                                    <Col sm={9} className="tool-form-label body2">
                                                        Customer Term In Years
                                                    </Col>
                                                    <Col sm={3} className="tool-form-input ml-0 mr-0 sm:mr-4">
                                                        <Input
                                                            placeholder="2"
                                                            type="number"
                                                            value={term}
                                                            onChange={e => setTerm(e.target.value)}
                                                            className="bg-transparent p-2 w-full sm:w-32 inputBox body2"
                                                            style={{borderBottomColor: "#434343", marginBottom: "0px"}}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="tool-form-row flex flex-col sm:flex-row justify-between">
                                                    <Col sm={9} className="tool-form-label body2">
                                                        Number of Referrals
                                                    </Col>
                                                    <Col sm={3} className="tool-form-input ml-0 mr-0 sm:mr-4">
                                                        <Input
                                                            placeholder="1"
                                                            type="number"
                                                            value={nRef}
                                                            onChange={e => setNRef(e.target.value)}
                                                            className="bg-transparent p-2 w-full sm:w-32 inputBox body2"
                                                            style={{borderBottomColor: "#434343", marginBottom: "0px"}}
                                                        />
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </div>
                                    </Col>
                                    <Col lg={5} className="right-input-col p-0 w-full sm:w-5/12">
                                        <Card className="right-input-card flex items-center justify-center">
                                            <CardBody className="p-0">
                                                <div className="flex-col items-center z-30 justify-center hidden sm:flex">
                                                    <h2 className="text-white text-center mb-4">Lifetime Value</h2>
                                                    <h2 className="text-white">
                                                        {`${accounting
                                                            .formatMoney(ltv, userCurrency)
                                                            .replace('.00', '')}`}
                                                    </h2>
                                                </div>
                                                <div className="flex-col items-center z-30 justify-center p-3 flex sm:hidden">
                                                    <h3 className="text-white text-center mb-4">Lifetime Value</h3>
                                                    <h3 className="text-white">
                                                        {`${accounting
                                                            .formatMoney(ltv, userCurrency)
                                                            .replace('.00', '')}`}
                                                    </h3>
                                                </div>
   {/*                                             <img
                                                    alt="footer"
                                                    src={GYAFooterScene}
                                                    className="footer-scene-image-gya"
                                                />*/}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </main>
            <Footer />
        </>
    );
};
