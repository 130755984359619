import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { Link } from 'react-router-dom';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        available: true,
        sectionName: 'Start',
    },
    {
        available: true,
        sectionName: 'How to respond to leads',
    },
];

const title = "How to Find Leads and Respond to them on Upwork"

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]}  className="plug-block">  
                        <h2 className="mb-4">{title}</h2>
                            <p> You’ll need:</p>

                            <p>
                                A
                                {' '}
                                <Link to="/resources/plug-and-play/how-to-set-up-an-upwork-account">
                                    completed UpWork Profile
                                </Link>
                            </p>

                            <hr />

                            <p>
                                Login to UpWork and go to your
                                {' '}
                                <a href="https://www.upwork.com/ab/find-work/domestic">
                                    home page
                                </a>
                                .
                            </p>
                            <img
                                src="https://genflow-images-prod-eu-west-1.s3.eu-west-1.amazonaws.com/gya/plugandplays/imag22.png"
                                alt=""
                            />

                            <p>
                                In the ‘Search for jobs’ tab, try a variety of keywords to find
                                jobs posted that you can respond to. For example, ‘social media
                                marketing’.
                            </p>
                            <img
                                src="https://genflow-images-prod-eu-west-1.s3.eu-west-1.amazonaws.com/gya/plugandplays/imag24.png"
                                alt=""
                            />

                            <p>
                                Remember, just like how the price listed in your profile is an
                                indicator of the general price of your services, you should also
                                consider the price listed, as well as the length of the contract
                                and the services required in the same way.
                            </p>
                            <p>
                                For example, if a client lists that they want full digital
                                marketing management for their brand, including SEO, email
                                marketing, social media management, community management and
                                more, it does not mean that they are not interested in paid
                                advertising - or vice versa.
                            </p>
                            <p>
                                It’s important to read between the lines in job postings. In
                                specific instances, companies are looking to fulfill certain
                                requirements (for example gaining over 10,000 Twitter
                                followers)- or even whitelabel services for their own agency.
                                But the majority of postings are looking to result in a single
                                outcome: to make more money.
                            </p>

                            <p>Let’s look at some examples of job postings:</p>

                            <img
                                src="https://genflow-images-prod-eu-west-1.s3.eu-west-1.amazonaws.com/gya/plugandplays/imag25.png"
                                alt=""
                            />

                            <p>
                                {' '}
                                This is an example of a poorly-qualified job posting. The poster
                                is most likely speculating and investigating whether the
                                standard of responses and work available on UpWork. They have
                                not even created their business yet, and it’s likely they do not
                                have a significant amount of budget to set aside.
                            </p>

                            <img
                                src="https://genflow-images-prod-eu-west-1.s3.eu-west-1.amazonaws.com/gya/plugandplays/imag23.png"
                                alt=""
                            />

                            <p>
                                {' '}
                                This is an example of an established business (albeit one that
                                may not have a substantial business) which is looking for
                                marketing on Facebook and Instagram. The poster seems to have
                                little understanding of this area of digital marketing but may
                                be interested in a results-based service such as Facebook
                                advertising, rather than what appears to be a request for social
                                media management.
                            </p>

                            <img
                                src="https://genflow-images-prod-eu-west-1.s3.eu-west-1.amazonaws.com/gya/plugandplays/imag27.png"
                                alt=""
                            />

                            <p>
                                This job post is explicitly looking for a Facebook ads
                                specialist. Whilst this is explicitly the service you offer,
                                also beware that this account may only be looking for a media
                                buyer/ Facebook advertiser rather than an agency.
                            </p>

                            <hr />

                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <h2>
                                How to respond to leads
                            </h2>
                            <p> Once you find a lead to respond to, click:</p>

                            <img
                                src="https://genflow-images-prod-eu-west-1.s3.eu-west-1.amazonaws.com/gya/plugandplays/imag21.png"
                                alt=""
                            />

                            <p>
                                At this point, you will be given the opportunity to submit a
                                proposal using ‘Connects’ - Upwork’s digital tokens.
                            </p>
                            <p>
                                Each new account receives a limited number of tokens, and can
                                purchase more tokens to apply for new jobs.
                            </p>
                            <p>Most jobs require six tokens to respond.</p>

                            <img
                                src="https://genflow-images-prod-eu-west-1.s3.eu-west-1.amazonaws.com/gya/plugandplays/imag26.png"
                                alt=""
                            />

                            <p>
                                Remember, price is just indicative. Select the same hourly rate
                                as your profile. Next, it’s time to write your cover letter.
                                There are five components of a successful cover letter. You
                                should write this once, and then modify and replicate it for
                                each response.
                            </p>

                            <b>1. Introduction & Repetition</b>

                            <p>
                                Treat this as you would the opening of an email. Thank the
                                poster for putting their job requirement up.
                            </p>
                            <p>
                                Next, repeat what they have put. Reiterate it, or use the same
                                words so they feel you have understood their post. For example:
                            </p>

                            <div className="examples-block">
                                Thank you for posting your job requirement for lead generation
                                for your insurance business. It’s really important for you to
                                have a consistent flow of leads and I’d love to help you get
                                these.
                            </div>

                            <b>2. Differentiation 1</b>
                            <p>
                                {' '}
                                Now it’s time to differentiate yourself from other respondents.
                                Implant the idea that your response is genuine, and that you can
                                actually help this individual. For example:
                            </p>

                            <div className="examples-block">
                                I’m sure you’ll get quite a few responses to this post that
                                don’t quite offer what you’re looking for. Rest assured: I’ve
                                had the chance to evaluate your requirements and I’m really
                                confident - and excited - that I could fulfill these.
                            </div>

                            <b> 3. Identity</b>

                            <p>Establish your credentials.</p>

                            <div className="examples-block">
                                My name’s Will and I run a digital marketing agency that helps
                                businesses generate leads using Facebook and Google advertising.
                                We really only do one thing - so you can rest assured that we
                                know how to do it well.
                            </div>

                            <b>4. Results & Recommendations</b>

                            <p> Establish your credibility.</p>

                            <div className="examples-block">
                                {' '}
                                We work with a number of businesses to provide consistent and
                                reliable lead generation. Most recently, we’ve achieved
                                fantastic results for a funeral planning business, creating a
                                lead magnet, back-end email system and series of Facebook and
                                Google adverts, achieving an extremely low cost-per-lead and
                                simultaneously ensuring leads are highly-qualified and
                                high-quality. I would love to show you this example in
                                particular, and share some of the great feedback we’ve had from
                                this client.
                            </div>

                            <b>5. Differentiation 2</b>

                            <p>
                                As with your initial differentiation, this is another
                                opportunity to distinguish and elevate your response from and
                                above others.
                            </p>
                            <div className="examples-block">
                                <p>
                                    As I’m sure you’ve noticed, working with someone on Upwork
                                    isn’t always the most straight-foward proposition, so I’d like
                                    to take the chance to reassure you that if we did work
                                    together, it would be an incredibly simple process.
                                </p>
                                <p>
                                    There would be no endless back-and-forth, or long delays in
                                    getting going. You would instantly receive a single
                                    questionnaire which you would use to fill in your details,
                                    objectives, domain information and more.
                                </p>
                                <p>
                                    From there, within just a few days, we would be live and
                                    getting you results. We’ll provide weekly reporting, and
                                    frequent check-ins, and deliver your leads directly into your
                                    CRM as soon as they are received.
                                </p>
                            </div>

                            <b>6. CTA</b>

                            <p>
                                {' '}
                                Finally, if possible, steer the conversation away from UpWork,
                                and make the interaction as unburdensome as possible.
                            </p>

                            <div>
                                <p>
                                    It’s really important to me that I can guarantee you a
                                    consistent and high-quality lead flow, so even though I’m
                                    extremely confident this is possible, I’m also conscious that
                                    you may also have questions about me and the process so I’d
                                    love to offer you a quick 15 minute call before we decide to
                                    work together.
                                </p>

                                <p>
                                    I can offer you some free advice - and even if we decide it’s
                                    not the best fit, I’m sure you would benefit from this
                                    enormously. Feel free to choose a time that works for you
                                    here:
                                </p>

                                <p><a href="#  ">www.agency.com/call</a></p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
