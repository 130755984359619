import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper/Paper';
import { OUTREACH_TRACKER_DATA_LABEL } from '../../../../../../constants/outreachTracker';
import SummaryList from '../SummaryList';
import { SummaryContext } from '../../providers/SummaryProvider';


const MonthSummaryItem = ({
    disablePrevMonthComparison, label, currency, data, prevMonthData, ENABLED_OUTREACH_COLUMN_FIELDS,
}) => {
    const SummaryItem = ({
        label, value, gain, loss, isCurrencyValue,
    }) => {
        let color = 'white';

        if (!disablePrevMonthComparison && gain) {
            color = '#65b456';
        }

        if (!disablePrevMonthComparison && loss) {
            color = '#CE3153';
        }

        return (
            <div style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '0.5rem',
                fontSize: '0.8rem',
            }}
            >
                <div style={{
                }}
                >
                    {label}
                </div>
                <div style={{
                    textAlign: 'right',
                    fontWeight: 'bold',
                    color,
                }}
                >
                    {isCurrencyValue ? `${currency} ${value.toLocaleString()}` : value}
                </div>
            </div>
        );
    };

    return (
        <Grid item xs={12} sm={12} md={12} lg={2}>
            <Paper>
                <div
                    style={
                        {
                            padding: '0.5rem',
                            textAlign: 'center',
                            color: 'white',
                            fontWeight: 'bold',
                            backgroundColor: '#000',
                            borderTopLeftRadius: '0.25rem',
                            borderTopRightRadius: '0.25rem',
                        }
                    }
                >
                    {label}
                </div>

                <Grid item container xs={12} sm={12} md={12} className="grey800Bg text-white">
                    {
                        ENABLED_OUTREACH_COLUMN_FIELDS.map(({ displayLabel, dataLabel, isCurrencyValue }) => (
                            <SummaryItem
                                label={displayLabel}
                                value={data[dataLabel]}
                                isCurrencyValue={isCurrencyValue}
                                gain={data[dataLabel] > (prevMonthData?.[dataLabel] || 0)}
                                loss={data[dataLabel] < (prevMonthData?.[dataLabel] || 0)}
                            />
                        ))
                    }
                </Grid>
            </Paper>
        </Grid>
    );
};
const YearSummary = ({
    currency,
    allMonthLabels,
}) => {
    const { getSummaryData, ENABLED_OUTREACH_COLUMN_FIELDS } = useContext(SummaryContext);
    const FILTERED_ENABLED_OUTREACH_COLUMN_FIELDS = ENABLED_OUTREACH_COLUMN_FIELDS?.filter(item => item?.dataLabel !== OUTREACH_TRACKER_DATA_LABEL);

    const topRowMonthLabels = allMonthLabels.slice(0, 6);
    const bottomRowMonthLabels = allMonthLabels.slice(6, 12);

    const allMonthsData = allMonthLabels.map(month => getSummaryData(month));


    return (
        <>
            <Grid container spacing={2} justify="center">
                <Grid item container spacing={2} xs={12} sm={12} md={12} lg={10}>
                    {topRowMonthLabels.map(monthLabel => (
                        <MonthSummaryItem
                            ENABLED_OUTREACH_COLUMN_FIELDS={FILTERED_ENABLED_OUTREACH_COLUMN_FIELDS}
                            label={monthLabel}
                            currency={currency}
                            data={allMonthsData[allMonthLabels.indexOf(monthLabel)]}
                            prevMonthData={allMonthsData[allMonthLabels.indexOf(monthLabel) - 1]}
                            disablePrevMonthComparison={allMonthLabels.indexOf(monthLabel) === 0}
                        />
                    ))}
                    {bottomRowMonthLabels.map(monthLabel => (
                        <MonthSummaryItem
                            ENABLED_OUTREACH_COLUMN_FIELDS={FILTERED_ENABLED_OUTREACH_COLUMN_FIELDS}
                            label={monthLabel}
                            currency={currency}
                            data={allMonthsData[allMonthLabels.indexOf(monthLabel)]}
                            prevMonthData={allMonthsData[allMonthLabels.indexOf(monthLabel) - 1]}
                        />
                    ))}
                </Grid>

                {}
                <Grid item xs={12} sm={12} md={12} lg={2}>
                    <SummaryList
                        currency={currency}
                        yearView
                    />
                </Grid>
            </Grid>


        </>
    );
};

export default YearSummary;
