import React, { useContext, useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { parseInt } from 'lodash';
import CurrencyInput from 'react-currency-input-field';
import { Spinner } from 'reactstrap';
import styles from './styles';
import { SummaryContext } from '../../providers/SummaryProvider';
import withSummaryList from './withSumaryList';

const SummaryItem = ({
    isInput, name, value, currency, onKeyDown,
}) => {
    const [editedValue, setEditedValue] = useState(null);
    const [editTitleLoading, setEditTitleLoading] = useState(false);
    useEffect(() => {
        if (!editedValue) {
            setEditTitleLoading(false);
        }
    }, [editedValue]);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '1rem 0.5rem',
        }}
        >
            <div style={{
                fontSize: '.9rem',
                width: '50%',
                // backgroundColor: 'red'
            }}
            >
                {name}
            </div>

            {
                isInput
                    ? (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            // width: '45%',
                        }}
                        >
                            <CurrencyInput
                            // key={currency}
                                className="form-control"
                                prefix={currency}
                                placeholder={`${currency}1,000`}
                                decimalsLimit={2}
                                onValueChange={val => setEditedValue(val)}
                                style={{
                                    flex: 1,
                                    background:' #373737',
                                    color: 'white',
                                    paddingRight: '0.3rem',
                                    borderRadius: '0.25rem',
                                    width: '5rem',
                                    textAlign: 'right',
                                    ...(editedValue !== null ? {
                                        border: 'solid 1px #1882de',
                                    } : {

                                    }),
                                }}
                                value={editedValue || value}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        setEditTitleLoading(true);
                                        onKeyDown(parseInt(editedValue), () => {
                                            console.log('CLEAR');
                                            setEditedValue(null);
                                        });
                                    }
                                }}
                            />
                            {
                                editTitleLoading && (
                                    <Spinner
                                        color="primary"
                                        type="grow"
                                        style={{ marginLeft: 10 }}
                                    />
                                )
                            }
                        </div>
                    )
                    : (
                        <div style={{
                            fontSize: '1.1rem',
                            fontWeight: '500',
                        }}
                        >
                            {value}
                        </div>
                    )
            }
        </div>
    );
};


const SummaryList = ({
    classes,
    yearView,
    currency,
    month,
}) => {
    const { getSummaryData, addSummaryMetadata, year } = useContext(SummaryContext);
    const {
        totalRevenue,
        totalExpenses,
        netCashFlow,
        dividendsReleased,
        retainedEarnings,
        openingBalance,
        closingBalance,
    } = getSummaryData(yearView ? null : month);
    return (
        <Paper className={classes.root}>
            <div
                style={
                    {
                        padding: '0.5rem',
                        textAlign: 'center',
                        color: 'white',
                        backgroundColor: '#1882de',
                        borderTopLeftRadius: '0.25rem',
                        borderTopRightRadius: '0.25rem',
                        fontFamily: 'Inter',
                        fontSize: '10px',
                        fontWeight: '600',
                        lineHeight: '15px',
                        letterSpacing: '0.4000000059604645px',
                        textTransform: 'uppercase',
                    }
                }
            >
                {yearView ? 'Year Summary' : 'Month Summary'}
            </div>
            {!yearView
                ?  <div className="pt-6 p-2 pb-0" style={{ backgroundColor: "#1C1C1C"}}>
                        <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',


                            }}
                            >
                                <h5 className={classes.header}>
                                    {month}
                                </h5>
                        </div>
                    </div>
                : null
            }
            <div style={{ padding: '0.5rem', height: '100%',  backgroundColor: "#1C1C1C" }}>
                <SummaryItem
                    key={`${month} ${year} - revenue`}
                    name="Revenue"
                    value={`${currency} ${totalRevenue.toLocaleString()}`}
                />

                <SummaryItem
                    key={`${month} ${year} - expenses`}
                    name="Expenses"
                    value={`${currency} ${totalExpenses.toLocaleString()}`}
                />

                <SummaryItem
                    key={`${month} ${year} - net cash flow`}
                    name="Net Cash Flow"
                    value={`${currency} ${netCashFlow.toLocaleString()}`}
                />

                <SummaryItem
                    key={`${month} ${year} - dividend  released`}
                    name="Dividends Released"
                    value={dividendsReleased}
                    isInput={!yearView}
                    currency={currency}
                    onKeyDown={(val, onComplete) => {
                        addSummaryMetadata(month,
                            { dividendsReleased: val }, onComplete);
                    }}
                />

                <SummaryItem
                    key={`${month} ${year} - retained earnings`}
                    name="Retained Earnings"
                    value={`${currency} ${retainedEarnings.toLocaleString()}`}
                />

                <SummaryItem
                    key={`${month} ${year} - opening balance`}
                    name="Opening Balance"
                    value={openingBalance}
                    isInput={!yearView}
                    currency={currency}
                    onKeyDown={(val, onComplete) => {
                        addSummaryMetadata(month,
                            { openingBalance: val }, onComplete);
                    }}
                />

                <SummaryItem
                    key={`${month} ${year} - closing balance`}
                    name="Closing Balance"
                    value={closingBalance}
                    isInput={!yearView}
                    currency={currency}
                    onKeyDown={(val, onComplete) => {
                        addSummaryMetadata(month,
                            { closingBalance: val }, onComplete);
                    }}
                />
            </div>
        </Paper>
    );
};

export default withSummaryList(withStyles(styles)(SummaryList));
