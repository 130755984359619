import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import { Link } from 'react-router-dom';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        available: true,
        sectionName: 'Introduction:',
    },
    {
        available: true,
        sectionName: 'Setting up Facebook Native Lead Ads',
    },
    {
        available: true,
        sectionName: 'Action Step',
    },
];

const title = 'Facebook Native vs Funnel Lead Gen: Setting Them Up';

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]}  className="plug-block">  
                        <h2 className="mb-4">{title}</h2>
                            <div className="plug-block-subheading">Introduction:</div>

                            <p>
                                There are two main types of lead gen on Facebook: native lead forms,  and landing  page lead generation.
                            </p>

                            <p>
                                The latter extends to any other platform you use to run traffic, you can use google, social media platforms and others to run traffic to landing pages and collect the data there.
                            </p>

                            <p>
                                Lead forms inside of Facebook offer a few advantages and disadvantages.
                                {' '}
                                <Link to="/resources/plug-and-play/core-concepts-to-keep-in-mind">Remind yourself of these here</Link>
                                .
                            </p>
                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">Setting up Facebook Native Lead Ads</div>

                            <p className="bold">
                                Creating our lead form
                            </p>

                            <p>
                                Here are the steps and nuances you need to take into account at the time creating your lead form.
                            </p>

                            <ol>
                                <li>
                                    Go to your Facebook page


                                </li>
                                <li>
                                    On the left menu, click on publishing tools


                                </li>
                                <li>
                                    On the new left menu, you can find a section called Lead Ads Forms
                                </li>
                                <img src="https://img.genflow.com/gya/plugandplays/fbNative1.png" alt="" style={{ width: '50%' }} />
                                <li>
                                    Go to Forms Library


                                </li>
                                <li>
                                    From there click on create
                                </li>

                                <img src="https://img.genflow.com/gya/plugandplays/fbNative2.png" alt="" style={{ width: '50%' }} />

                                <li>
                                    Let's follow this video on creating a good looking lead gen form.
                                </li>

                                <div style={{ height: 350 }}>
                                    <iframe
                                        id="videoPlayer"
                                        className="sproutvideo-player"
                                        src="https://videos.sproutvideo.com/embed/ea9dd7be1d1be7c563/acb280bdfd5bb708?playerTheme=dark&amp;playerColor=263238"
                                        frameBorder="0"
                                        allowFullScreen
                                        width="100%"
                                        height="100%"
                                    />


                                </div>
                            </ol>

                            <p>
                                For the headline we suggest using the same headline of your ad, in this case the example is: Do you make these 3 food mistakes?
                            </p>

                            <p>
                                For the copy we use a very similar text of our ad angles, this means that we usually have a different form per angle although most people use the same one and just tweak the ad copy. On the copy of the form it is important to add some of the instructions and explicitly explain the offer and what they are redeeming. This information will be communicated to them through phone and/or email as well.
                            </p>

                            <p>
                                This is our copy for the form:
                            </p>

                            <p>
                                Copy
                            </p>

                            <p>We're giving away a 1 week free trial!</p>

                            <p>
                                To celebrate the anniversary of our in-house nutritionist, we're giving away a FREE one week trial at our gym. Just fill in the form below and you'll be sent your membership info. Register now to receive a free guide to common food mistakes that everyone mistakes - mistakes that are stopping you reaching your goals.See you there!
                            </p>

                            <img style={{ width: '40%' }} src="https://img.genflow.com/gya/plugandplays/fbNative6.png" alt="" />


                            <p>
                                Add a description to let people know they will be contacted to redeem their offer.
                            </p>

                            <p>
                                Here we show an example on some questions you can do to make your form more interactive and maybe personalized.
                            </p>

                            <p>On the questions, ask for all the information you need, keep in mind that there is always an inverse relation with questions and volume, the more questions the less volume, less questions more volume.</p>

                            <p>
                                These are the questions we recommend adding, you can add to them or take from them. You can play around with more playful questions to add engagement to the form and make it more interesting.
                            </p>

                            <p>
                                Add your privacy and policy, we created one for you guys:
                                {' '}
                                <a href="https://docs.google.com/document/d/10OLizMkxnbBTxLTfVRi3RgMIAd_7UF-shcfw-0D2Izw/edit?usp=sharing" target="_blank" rel="noopener noreferrer">https://docs.google.com/document/d/10OLizMkxnbBTxLTfVRi3RgMIAd_7UF-shcfw-0D2Izw/edit?usp=sharing</a>
                            </p>

                            <p>
                                Or you can generate a standard one in a website like this one:
                                {' '}
                                <a target="_blank" rel="noopener noreferrer" href="https://connectio.io/privacy-policy-generator/">https://connectio.io/privacy-policy-generator/</a>
                            </p>

                            <p>
                                Remember that this must be a website that you own. It can be hosted on your agency's website.
                            </p>

                            <p>
                                You can add a custom disclaimer in case you plan to do something out of the policy with the leads. Read Facebook's own policy data to know how to manage the information correctly:
                                {' '}
                                <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/policy.php">https://www.facebook.com/policy.php</a>
                            </p>

                            <p>Add custom information to the 'thank you' page to entice the audience to go through and be excited about the offer they just claimed!</p>

                            <p>
                                On settings, leave the form open, so it can be shared, rest of the fields can be left on default.
                            </p>
                            {' '}
                            <p className="bold">
                                Creating our ad campaign
                            </p>

                            <p>
                                Follow this video along as we create our first campaign:
                            </p>

                            <p>
                                After selecting lead generation as our conversion event, which means that we're telling facebook that we want them to find the people most likely to fill out and submit the form, we have to get into naming our campaign, ad set and ad. As of right now we will only create one of those and will put our naming convention. Campaign structure for lead gen specifically will be explained in our next module. For now we are just creating one campaign, one ad set and one ad.
                            </p>

                            <p>
                                Campaign name: [IAG] NY | Cold | Free Week and guide | Leads
                            </p>

                            <p>Agency, main targeting (country or city), offer, conversion optimization.</p>

                            <p>
                                Ad set name: Broad | Women | 20 - 60 | 7km
                            </p>

                            <p>Audience</p>

                            <p>
                                Ad name: Nutritionist anniversary | 3 food mistakes Angle and form
                            </p>

                            <p>
                                Once names we go to the ad set, there we have to accept the terms of managing data associated with the lead forms. You can accept this on behalf of your client, make sure you give it a read.
                            </p>

                            <p>
                                Budget wise it will depend on your clients pocket and the amount of leads they want and/or can handle. You can expect to get leads from $1 dollar to around $15 although this can be higher. You want to give each ad set enough budget to get you 1 lead at the very least. Ideally way more. We will set up $30 a day for this example. Then you can enter your clients location or where they want the leads to be from. You can select multiple locations at once. If for example your client can operate in many places or has more than one venue it is better to put them all in the same ad set, unless explicitly arrange with your client because they want the leads separate. As a rule of thumb keep this in mind, in general the bigger the audience the better as you give more information to Facebook from where to choose from.
                            </p>

                            <p>
                                Radius is 7, can vary from city to city
                            </p>

                            <p>
                                We get a lot of questions about audience sizes, and there's people that say that there should be a minimum. For lead gen don't overthink it, do it only based on how far people would travel for the offer and that's it. Because our offer is tailored to weight loss it usually appeals more to women. You can split test which gender works best but in this case because we've tested before we're going for women in the 20-60 age range. No interests, hence the 'Broad' in the ad set name. Should you add interests? not really, adding them should only come in the scenario that it is not working without them. If it isn't there's easy big interests that will fit this offer. For example, some that we've tested successfully are 'weight loss', 'weight watchers' and 'physical fitness' . Find the ones that work for you. Targeting expansion off. We've split tested it, no conclusive results, sometimes it is better and sometimes it isn't, in our case we concluded it doesn't make a difference and even less if you're using broad targeting.
                            </p>

                            <p>
                                On placements as our copy and image are one of our biggest strengths we like to only select the placements where you can clearly read the first bits of the copy. Automatic it is said gives you lower CPM's but when you do the breakdown they're only lower because now you're appearing on messenger or messenger stories where there are no clicks or conversions and CPM's are dirt cheap.
                            </p>

                            <p className="bold">The ad</p>

                            <p>
                                Select the page! Be careful, we've made the mistake of selecting the wrong clients page, don't make it yourself.
                            </p>


                            <p>Upload and select the image of your ad.</p>

                            <p>
                                Put the copy where it says "Primary Text".
                            </p>

                            <p>
                                We're giving away a 1 week free trial!
                            </p>

                            <p>
                                To celebrate the anniversary of our in-house nutritionist, we're giving away a FREE one week trial at our gym. Just fill in the form below and you'll be sent your membership info. Register now to receive a free guide to common food mistakes that everyone mistakes - mistakes that are stopping you reaching your goals.See you there!
                            </p>

                            <p>Put your headline and description. We're leaving the description empty for this one.</p>

                            <p>
                                On the corner you'll find it says 1 of 5. This makes a reference to dynamic ads, which means that you put different variations of your copy and let Facebook show and select the ones they consider best based on their data. We've already talked about angles and how important it is to have different angles and test them. If you've seen our structured video, you know that we recommend NOT using dynamic ads as they don't show you complete data and you won't or have complete control over them. For example you won't be able to turn some of them off, you won't be able to only duplicate the better ones, etc. So for now just leave one piece of copy per ad.
                            </p>

                            <p>
                                Headline: The 3 Food Mistakes
                            </p>
                            <p>
                                Copy:
                            </p>

                            <p>
                                We're giving away a 1 week free trial!
                            </p>

                            <p>
                                To celebrate the anniversary of our in-house nutritionist, we're giving away a FREE one week trial at our gym. Just fill in the form below and you'll be sent your membership info. Register now to receive a free guide to common food mistakes that everyone mistakes - mistakes that are stopping you reaching your goals.
                            </p>

                            <p>
                                See you there!
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/fbNative3.png" alt="" style={{ width: '50%' }} />

                            <p>
                                Select the call to action button. We've split tested multiple of these ones and the results do not vary much. According to our tests, the best buttons you can choose are the following:
                            </p>

                            <p>
                                For lead magnet: Download
                            </p>

                            <p>
                                For offer: Learn More
                            </p>

                            <p>
                                Then select your instant form
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/fbNative4.png" alt="" style={{ width: '50%' }} />


                            <p>
                                As you can see right after the text of the form you have the custom questions and you can imagine how this can skyrocket your conversions and set it apart from the competition.
                            </p>

                            <p>You guys know that quizzes work wonders for us, you can implement a quiz on here as well and frame it as such so people get a more personalised experience.</p>

                            <img src="https://img.genflow.com/gya/plugandplays/fbNative5.png" alt="" style={{ width: '51%' }} />

                            <p className="bold">Landing Page ad</p>

                            <p>
                                The process is very similar and it is almost all the same.
                            </p>

                            <p>
                                On the campaign level the difference is the type of campaign you'll run. In this case you'll select conversions. Nothing else is currently working and nothing else has worked in the past 3 years of running my agency. Always select conversions and always select the furthest conversion you want. This will tell Facebook that you want them to send your way the people most likely to complete that action and the furthest you choose the higher quality people you'll find. In this case and because you've already gone over the "All About Tracking '' module and already have the event's installed in your website, you'll be choosing conversions.
                            </p>

                            <p>On the ad set level you need to select which type of conversion you'll be optimizing for, here you select "Complete registrations" or whatever event you put on the thank you page (page after they opted in and where leads are redirected to)</p>

                            <p>
                                For broad targeting the interest expansion doesn't make any difference.
                            </p>

                            <p>Change the conversion window for the longest possible, currently it is "7 day click 1 day view". Which means that people can convert within 7 days of clicking the ad or within 1 day of viewing it, if they do the conversion will get attributed to your ad.</p>

                            <p>On the ad level the only difference is that instead of choosing a lead form, you'll need to provide a link. This is the link of your landing page where people can find the lead magnet or the offer and can opt-in with their contact information. Make sure you have dynamic creatives off. And make your ad normally.</p>

                            <p>
                                We see that warning at the bottom that the domains don't coincide, make sure you don't have that. It is happening here as we're using it as a test only.
                            </p>
                        </div>

                        <div ref={refs[2]} className="plug-block">
                            <div className="plug-block-subheading">
                                Action step
                            </div>

                            <p>
                                Time to create your own campaigns, go ahead and create one lead from a campaign and one conversion campaign. Do these now, believe me when I say it this will take all the pressure and nervousness off your shoulders when you do it for real. If you don't currently have a client, pick a mock one one teh niche you've chosen.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
