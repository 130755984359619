const styles = () => ({
    calendarContainer: {
        margin: '0 auto',
        maxWidth: '100%',
    },
    gridContainer: {
        maxWidth: '100%',
        padding: '0 1rem',
    },
    calendarGridItem: {
        height: '100vh',
        overflowX: 'auto',
        paddingRight: '1rem',
    },
    yearMenu: {
        maxHeight: 300,
    },
    speedDial: {
        position: 'fixed',
        bottom: 24,
        right: 24,
    },
});

export default styles;
