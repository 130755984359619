import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        available: true,
        sectionName: 'Introduction:',
    },
    {
        available: true,
        sectionName: 'Tone to Establish:',
    },
    {
        available: true,
        sectionName: 'Who Should Be On the Call?',
    },
    {
        available: true,
        sectionName: 'Structure:',
    },
];

const title = 'Kick-Off Call';

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]}  className="plug-block">  
                        <h2 className="mb-4">{title}</h2>
                            <div className="plug-block-subheading">Introduction:</div>

                            <p>
                                The ‘kick-off’ call takes place after the client has been signed, has gone through the on-boarding process, and has paid their invoice.
                            </p>
                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">Tone to Establish:</div>

                            <p>
                                The ‘kick off’ call is where the real work begins. Prior to this, the contract has been signed and the invoice is paid, so there are no difficult conversations to be had.
                            </p>

                            <p>It’s the last step of the on-boarding process:</p>

                            <a href="https://iag-media.com/demo-onboarding-1" target="_blank" rel="noopener noreferrer">https://iag-media.com/demo-onboarding-1</a>

                            <p>
                                However, it’s an incredibly important call because it allows you to set the tone for the duration of your working relationship, introduce team members, paint a picture in their minds about your agency’s workflow and processes, give realistic timelines for output, and also establish the client’s responsibilities. Remember: don’t overpromise, the client will take what you say in this call as gospel.
                            </p>
                        </div>

                        <div ref={refs[2]} className="plug-block">
                            <div className="plug-block-subheading">Who Should Be On the Call?</div>

                            <p>
                                At IAG Media, the following members are present:
                            </p>

                            <p>
                                Iman Gadzhi - Founder
                            </p>

                            <p>
                                Dany Benavides - CMO
                            </p>

                            <p>
                                Luis Berger - Performance Marketer
                            </p>

                            <p>
                                Ciaran Anderson - Copywriter
                            </p>

                            <p>
                                Most SMMAs have nowhere near this many team members. In fact, the majority of the time it will just be you and your media buyer, or other contractor.
                            </p>

                            <p>
                                Anyone who has client contact should turn up to be introduced.
                            </p>
                        </div>

                        <div ref={refs[3]} className="plug-block">
                            <div className="plug-block-subheading">Structure:</div>

                            <ul>
                                <li>
                                    Getting acquainted
                                </li>
                                <li>Reporting</li>
                                <li>
                                    Copywriting questions
                                </li>
                                <li>
                                    Weekly meeting
                                </li>
                                <li>
                                    Wrap up & Timeline
                                </li>
                            </ul>

                            <p className="bold">
                                Getting Acquainted
                            </p>

                            <p>
                                Although you have most likely spoken to the client previously, this is the first time you will have done so outside of a sales environment. And it is also most likely the first time they will have spoken to your other team members.
                            </p>

                            <p>
                                So it’s important to put them at ease and establish new rapport. Keep it simple, mention the weather, introduce your team members - and make particular note of where they may be in the world. This is an easy way to establish common ground.
                            </p>

                            <p>
                                Make sure to compliment your team members and instill confidence in the client.
                            </p>

                            <p className="bold">Reporting</p>

                            <p>
                                After the introduction, the next step is to demonstrate your reporting system to the client: this could be the client bible, a data dashboard, or another system.
                            </p>

                            <p>
                                It’s useful to pull up a blank report, and then an example from another client (whilst maintaining confidentiality).
                            </p>

                            <p>
                                For example:
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/kickOff1.png" alt="" />

                            <p>
                                In IAG Media meetings, Dany explains that the two most important metrics are money coming in, and money going out. This underlines the data-driven nature of an agency.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/kickOff2.png" alt="" />

                            <p>
                                You may also wish to demonstrate how the dashboard may look over a longer-term period: for example if you have a  three, or six month minimum.
                            </p>

                            <p>
                                Read on for transcript examples from IAG Media kick-off calls.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/kickOff3.png" alt="" />

                            <div className="examples-block">
                                <p>
                                    "In case you're a geek like us and like to see the charts of performance and get some interesting insights you can see here ad spend vs revenue, Add To Cart Ratio and its conversion to purchases and also how the CPC and the CPA behave with each other. On the bottom you can also see how the ad account is performing each day, you can also sort it by CPA (Cost Per Acquisition) or any other metric for that matter. You can see here that APR X we paid the most for a purchase and in APR Y we paid the least"
                                </p>

                                <img src="https://img.genflow.com/gya/plugandplays/kickOff4.png" alt="" />

                                <p>
                                    "Here on page 4 we can see an overview of the audiences we're using [keep in mind this is the case because we name the ad sets that way, we'll touch more on this on the Naming Conventions Module ahead] and we also see a device, age, gender and country breakdown"
                                </p>

                                <p>
                                    "Finally in page 5 we have a change-log that we fill each time something big of notice happens with the ad account. For example bans, changes of pixel or something that isn't allowing us to do our work properly"
                                </p>
                            </div>

                            <p className="bold">
                                Copywriting Questions
                            </p>

                            <p>
                                Next, it’s time to consider copywriting. Depending on the service offered, or the nature of the client, this can be more or less important. But the questions indicated below often help other members of the team decide on other aspects of service delivery.
                            </p>

                            <p>
                                You should look to ascertain brand dos and don'ts, the formality and lexicon of the business, and industry-specific terms.
                            </p>

                            <p>
                                Make sure all team members have familiarised themselves with the onboarding funnel prior to this call, to supplement these questions and understand the answers further.
                            </p>

                            <ul>
                                <li>
                                    Please describe your customer’s normal purchase journey from initial awareness to checkout
                                </li>
                                <li>
                                    Can you please describe your normal customer by demographic and interests
                                </li>
                                <li>
                                    Can you identify your USP, and why your customers would come to you, instead of a competitor
                                </li>
                                <li>
                                    Are there any buzzwords or specific language that you would use?
                                </li>
                            </ul>

                            <p className="bold">Weekly Meeting</p>

                            <p>Next it’s time to book a weekly meeting. It’s easiest to find a time that suits everyone whilst live on the call. </p>

                            <p>If you use Zoom, make sure to change the location to reflect this, and make it a recurring weekly event.</p>

                            <img src="https://img.genflow.com/gya/plugandplays/kickOff5.png" style={{ width: '50%'}} alt="" />
                            <img src="https://img.genflow.com/gya/plugandplays/kickOff6.png" alt="" />

                            <p>
                                With that done you can save the event and make sure on the prompt you say yes to sending emails with the invites to everyone.
                            </p>

                            <p className="bold">
                                Wrap Up & Timeline
                            </p>

                            <p>
                                Finally, you need to present the timeline to the client.
                            </p>

                            <p>
                                For example:
                            </p>

                            <ul>
                                <li>
                                    Within 48 hours, you will receive your personalised reporting sheet
                                </li>
                                <li>Within 4 days, you can expect the first drafts of copy written and sent for approval</li>
                                <li>
                                    Within 5 days, you can expect the first ads to be live and everything started
                                </li>
                            </ul>

                            <p>
                                There are exceptions to this timeline: for example if the client is already running
                                A few caveats, depending on the situation, if the client is already running ads. We take over the ad account and start managing from day 0. And we would publish ads as well with their current material. The other caveat is that the client sometimes takes longer approving and/or translating the copy, so you have to let them know is important they do so quickly so the timelines don't change.
                            </p>

                            <p>
                                The real work starts and what represents the highest yield of your agency starts. Retention is far better than finding new clients. This in terms of time, peace of mind and stress and also financially, good results almost always create higher retention.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
