import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        available: true,
        sectionName: 'Introduction',
    },
    {
        available: true,
        sectionName: 'How to Create a Lookalike Audience',
    },
    {
        available: true,
        sectionName: 'The Best Lookalike Audiences',
    },
    {
        available: true,
        sectionName: 'How do we use lookalike audiences in our strategy?',
    },
    {
        available: true,
        sectionName: 'Action step',
    },
];

const title = 'Lookalike Audiences: How To and The Best Ones You Can Create';

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]}  className="plug-block">  
                        <h2 className="mb-4">{title}</h2>
                            <div className="plug-block-subheading">Introduction</div>

                            <div className="examples-block">

                                <p>"A Lookalike Audience is a way to reach new people who are likely to be interested in your business because they're similar to your best existing customers."</p>
                            </div>

                            <p>
                                But what does this mean in practice?
                            </p>

                            <p>
                                Lookalike audiences are extrapolated from a data source that you give Facebook (in the form of a custom audience). Facebook finds people who it deems similar to them - similar demographics, interests and behaviours.
                            </p>

                            <p>
                                This is a great way of using your existing data of people who may have already converted, and targeting similar people.
                            </p>

                            <p>
                                You can determine the size of the lookalike audience. The bigger, the less like your custom audience it will be, and vice versa.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/lookalikeAudiences1.png" alt="" />

                            <p>
                                Let's hypothesize for a moment on how this is done, this will help you visualize what is most likely happening.
                            </p>

                            <p>
                                Facebook, taking advantage of the Classification Machine Learning algorithms, processes all the data they have and the one you provide to create these audiences.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/lookalikeAudiences2.png" alt="" />

                            <p>
                                What is most likely happening behind the scenes is that they are creating a decision tree, a very common way of solving classification problems. You've given Facebook a list of people that you want. They also have databases of people that DON'T look like the people you want. They feed this data to a decision tree algorithm that spawns a tree like this one. The tree has decision nodes and leaf nodes. On each decision node there's a question that is binary, either yes or no. If it is yes you go right if it is no you go left. Whenever you reach a leaf node, a classification happens. In this case the classification either belongs to the LAL (Lookalike Audience) or doesn't belong to the LAL.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/lookalikeAudiences3.png" alt="" />

                            <p>
                                This is a very simple example of a decision tree. You can see there are three decision nodes and four  leaf nodes. On the leaf nodes a decision is taken, fit or unfit. Facebook will put the profiles and their data to go through this tree and classify people whether they look-a-like or not.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/lookalikeAudiences4.png" alt="" style={{ width: '50%' }} />
                            <img src="https://img.genflow.com/gya/plugandplays/lookalikeAudiences5.png" alt="" />

                            <p>
                                Now this is obviously an oversimplification of what is going on, this algorithms have much more complexity and you can add for example weights to the nodes or even having non-binary answers to visit more dimentions. We won't go in more depth on this topic but I hope it was useful to visualize and understand a little bit what goes behind the scenes.
                            </p>

                            <a href="https://towardsdatascience.com/getting-acquainted-with-k-nearest-neighbors-ba0a9ecf354f" target="_blank" rel="noopener noreferrer">Introduction to classification</a>

                            <img src="https://img.genflow.com/gya/plugandplays/lookalikeAudiences6.png" alt="" />
                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">
                                How to Create a Lookalike Audience
                            </div>

                            <p>
                                To create a lookalike audience, go to the
                                {' '}
                                <a href="https://business.facebook.com/adsmanager/audiences" target="_blank" rel="noopener noreferrer">Audiences section</a>
                                {' '}
                                of the Business Manager.
                            </p>

                            <p>
                                There are two types of lookalike audiences we can create, you can either create them from the purchases on the pixel (value based) or create them from another audience.
                            </p>

                            <p>
                                Now let's follow this video on how to create the lookalike audiences.
                            </p>

                            <div style={{ height: 350 }}>
                                <iframe
                                    id="videoPlayer"
                                    className="sproutvideo-player"
                                    src={'https://videos.sproutvideo.com/embed/709dd8b61613e4c0f9/ae4788a7cf876268?playerTheme=dark&amp;playerColor=263238'}
                                    frameBorder="0"
                                    allowFullScreen
                                    width="100%"
                                    height="100%"
                                />
                            </div>


                            <p>
                                You can select multiple countries as well. You can create multiple lookalike audiences at the same time as long as they don't overlap with each other. At the top you see the size of the audience. This size corresponds to the % of Facebook users in the country or countries you've selected. For instance for the UK and US each percent of the users corresponds to 3.1M people, if you select an audience that has a 2% gap it will have 6.2M people and so on. To make this clear even an audience that is from 9% to 10% because the gap is 1% it will have 3.1M people
                            </p>

                            <p>
                                The furthest the % from 0 the less alike the audience will be.
                            </p>

                            <p>
                                Here is a video on how to create audiences from other custom audiences, this audience and how to create them was explained on the last module.
                            </p>

                            <div style={{ height: 350 }}>
                                <iframe
                                    id="videoPlayer"
                                    className="sproutvideo-player"
                                    src="https://videos.sproutvideo.com/embed/a79dd8b61613e4c32e/c50243c78f8fdd15?playerTheme=dark&amp;playerColor=263238"
                                    frameBorder="0"
                                    allowFullScreen
                                    width="100%"
                                    height="100%"
                                />
                            </div>

                            <p>After you've created your LALs, they will have a fixed name that has Lookalike, then the countries which they spawned of from in this case the abbreviations from GreatBritain and UnitedStates, after that there is the source in this case the pixel and the event used which was purchases</p>


                            <p>
                                Remember that you can only use 'value based' events, which means that they have some value, for example an add to cart has value, the value would be the items added to the cart. If you want to use an event that doesn't have a value, you can create a custom audience for it and then create a lookalike form that custom audience.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/lookalikeAudiences7.png" alt="" />
                        </div>

                        <div ref={refs[2]} className="plug-block">
                            <div className="plug-block-subheading">The Best Lookalike Audiences</div>

                            <p>
                                As great as it would be to have a definitive answer… there is none.
                            </p>

                            <p>
                                After you watch the module
                                {' '}
                                <a href="https://docs.google.com/document/d/105Fh3nLyHs6tSr8xHFQBW-iGl_ZPX5sWTgQD1nUlscc/edit?usp=sharing" target="_blank" rel="noopener noreferrer">"How Facebook Works On The Inside"</a>
                                {' '}
                                , you will understand why and understand the lack of consistency that audiences will give you, and there is a fair amount of randomness involved in results as there is with everything. With that being said, there are some key pointers, IAG Media have found more success with lookalikes closer to the source, meaning from 0% to 3%. Likewise,  we also use mostly audiences that are 1% in size although we would increase this if the population of the country we are advertising in is smaller. For example in Sweden we use 2% and 3% audience sizes.
                            </p>

                            <p>
                                With the sources is a trial and error thing, we've tested all of it and sometimes it works and sometimes it doesn't.
                            </p>
                        </div>


                        <div ref={refs[3]} className="plug-block">
                            <div className="plug-block-subheading">
                                How do we use lookalike audiences in our strategy?
                            </div>

                            <p>
                                In order to determine which lookalike audience works best, it’s necessary to test each separately.
                            </p>

                            <p>
                                Another strategy that works well - with very high ad spend -  is to add a lot of consecutive lookalikes from 2 different sources into a CBO campaign. It’s not necessary to alter or turn off ad sets as Facebook automatically prioritises winning ones.
                            </p>
                        </div>

                        <div ref={refs[4]} className="plug-block">
                            <div className="plug-block-subheading">
                                Action step
                            </div>

                            <p>
                                Create lookalike audiences from the audiences you created on the last module. You can choose your countries based on wherever you want to advertise or if you don't, use US and UK as a base (this are the best performing countries for most of our clients)
                            </p>

                            <p>
                                This are the percentages you have to use:
                            </p>

                            <p>
                                0%-1%
                            </p>
                            <p>
                                1%-2%
                            </p>
                            <p>
                                2%-3%
                            </p>
                            <p>
                                0%-2%
                            </p>
                            <p>2%-4%</p>

                            <p>
                                Do this with 2 sources and if you're able to test it and post your results in our community. These results should be compared with other campaigns you already have running.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
