/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

import image1 from '../../../../../assets/img/plugandplay/google-ads/1.png';
import image2 from '../../../../../assets/img/plugandplay/google-ads/2.png';
import image3 from '../../../../../assets/img/plugandplay/google-ads/3.png';
import image4 from '../../../../../assets/img/plugandplay/google-ads/4.png';
import image5 from '../../../../../assets/img/plugandplay/google-ads/5.png';
import image6 from '../../../../../assets/img/plugandplay/google-ads/6.png';
import image7 from '../../../../../assets/img/plugandplay/google-ads/7.png';
import image8 from '../../../../../assets/img/plugandplay/google-ads/8.png';
import image9 from '../../../../../assets/img/plugandplay/google-ads/9.png';
import image10 from '../../../../../assets/img/plugandplay/google-ads/10.png';

const sections = [
    {
        available: true,
        sectionName: 'Ad account access',
    },
    {
        available: true,
        sectionName: 'Ad account creation',
    },
];

const title = 'Getting Setup - Google Ads';

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div  className="plug-block">  
                        <h2 className="mb-4">{title}</h2>
                            <div className="plug-block-subheading">Introduction</div>
                            <p>
                                If you already have a
                                {' '}
                                <a href="https://accounts.google.com/SignUp?hl=en">Google Ad account</a>
                                , you do not
                                need to create a new one for each client. Instead, like Facebook ad accounts, your
                                client should create their own Google Ad account and grant you partner access.
                            </p>
                        </div>

                        <div ref={refs[0]} className="plug-block">
                            <div className="plug-block-subheading">Ad account access</div>
                            <p>
                                Once your client has created their account, they need to make sure they are on
                                ‘Advanced mode’ rather than ‘Smart mode’
                            </p>

                            <h4>Switching from Smart Mode to Expert Mode</h4>

                            <p>
                                Businesses of all sizes can benefit from the automation technology and time saving
                                benefits of Smart campaigns. However, if you desire more advanced features or other
                                campaign types in Google Ads, here’s how you can switch to Expert Mode.
                            </p>

                            <p>
                                1. Once signed into your Google Ads account, click the Tools icon in the top right
                                corner.
                                <br />
                                2. Select Switch to Expert Mode.
                            </p>

                            <p>Once in expert mode your client needs to go to the ‘tools & sections’ menu.</p>

                            <img src={image1} alt="" />

                            <p>
                                After the submenu is displayed they need to go into ‘Access and security’ under the
                                setup section.
                            </p>

                            <img src={image2} alt="" />
                            <p>Next, they need to click on ‘Security’</p>
                            <img src={image3} alt="" />

                            <p>
                                On security add your domain as an allowed domain. That way you can then invite your
                                agency’s email to the account with no issues later down the line.
                            </p>
                            <img src={image4} alt="" />

                            <p>Finally go back to users, add your email and give it admin access.</p>

                            <img src={image5} alt="" />

                            <p>
                                You will receive an email with the invitation and just by clicking on it you will
                                access the account and will be able to manage it fully.
                            </p>

                            <img src={image6} alt="" />
                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">Ad account creation</div>
                            <p>
                                To create a Facebook ads account you need to go to
                                {' '}
                                <a href="https://ads.google.com/home/#!/">Google’s ads home page</a>
                                .
                            </p>

                            <p>
                                There click on start now and then log in. It will give you the option to create a
                                new google ads account.
                            </p>

                            <img src={image7} alt="" />

                            <p>
                                Right away it will prompt you to create a campaign, which you can do as an empty
                                search campaign. The easiest way to do it is at the bottom to ‘Switch to Expert
                                Mode’
                            </p>

                            <img src={image8} alt="" />

                            <p>
                                Then at the bottom find the link that says ‘Create an account without a campaign’
                            </p>

                            <img src={image9} alt="" />

                            <p>
                                Finally you will need to input your business information and your account will be
                                set.
                            </p>

                            <p>
                                Make sure that your client has input their information and their credit card number
                                for the ads billing, if they haven’t you can tell them to do it on the ‘Tools &
                                Settings’ menu under the billing section.
                            </p>

                            <p>Click summary and then payment methods.</p>

                            <img src={image10} alt="" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
