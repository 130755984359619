import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import AppSyncConfig from '../init/appsync-configure';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createAppSyncLink } from './appSyncLink';

const apolloClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([
        createAppSyncLink(),
        createHttpLink({
            uri: AppSyncConfig.url,
        }),
    ]),
});

export default apolloClient;
