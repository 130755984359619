import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        available: true,
        sectionName: 'How it works:',
    },
    {
        available: true,
        sectionName: 'What we need:',
    },
];

const title = 'iOS14 Update True Tracking';

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]}  className="plug-block"> 
                            <p>
                                In this Plug N Play you are going to learn how to use UTM parameters in order to match your Shopify sales with the campaign data from Facebook, giving you a full and accurate view of the real numbers of your client’s businesses (or your own).
                            </p>

                            <div className="plug-block-subheading">
                                How it works:
                            </div>

                            <p>
                                UTMs add information to the end of your URL. This information is usually the traffic source the click came from, the campaign ID, the adset ID, and the ad ID. We do that through what we call UTM parameters, which are nothing more than a piece of text appended to the end of your website URL. It usually looks similar to this:
                            </p>

                            <p className="break-words">
                                YourWebsite.com/?utm_source=facebook&utm_medium=facebook-newsfeed&utm_campaign=prospecting-campaign&utm_content=broad-adset&utm_term=blue-ad
                            </p>

                            <p>
                                This is how you can do it dynamically:
                            </p>

                            <p className="break-words">
                                ?utm_source=facebook&utm_medium=cpc&utm_campaign=[[campaign.id]]&utm_content=[[adset.id]]
                            </p>

                            <p>
                                And the real beauty of this is that Facebook dynamically updates this information for each click with the exact campaign, adset and ad that led to the click.
                            </p>

                            <p>
                                Then, Shopify automatically captures these UTM parameters from all the clicks that land on your website, and tags them to the user. Then, if this user buys right away or after a few days, you can see exactly the campaign, adset, and ad that drove the customer to your website.
                            </p>

                            <p>
                                Then, we use Data Studio to match the campaign data from Facebook with the transaction data from Shopify. This way, you can see the real return on ad spend each campaign, adset and ad is bringing.
                            </p>

                            <p>
                                You are going to notice that for the majority of clients, the real return on ad spend is far higher than the return on ad spend you see inside Facebook.
                            </p>
                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">What we need:</div>

                            <ul>
                                <li>
                                    First, we will need to add the UTM parameters to your ads
                                </li>
                                <li>
                                    Second, we will have to create a Google Data Studio dashboard using the template we give you.
                                </li>
                                <li>
                                    Third, we will need to connect Supermetrics to Shopify and Facebook in order to pull the data from both sources.
                                </li>
                                <li>
                                    And lastly, we’ll blend the data on Google Data Studio and organize the dashboard so that you have all the visualizations you need.
                                </li>
                            </ul>

                            <p className="bold">
                                Step-by-step instructions:
                            </p>

                            <p className="bold">
                                1) Adding The UTM Parameters to Your Ads:
                            </p>

                            <ul>
                                <li>
                                    Create you ad normally, as you always do
                                </li>
                                <li>
                                    Click to edit the ad and scroll until you see the "Tracking" options.
                                </li>
                            </ul>

                            <img src="https://img.genflow.com/gya/plugandplays/trueTracking1.png" style={{ width: '50%' }} alt="" />

                            <ul>
                                <li>
                                    There, just paste the code below:
                                </li>
                                <li className="break-words">utm_source=facebook&utm_medium=[[placement]]&utm_campaign=[[campaign.id]]&utm_content=[[adset.id]]&utm_term=[[ad.id]]</li>
                            </ul>

                            <p className="bold">
                                2) Connecting Supermetrics for Data Studio to Facebook
                            </p>

                            <ul>
                                <li>
                                    First you will need to get a license for Supermetrics for Data Studio. You can get a 14-day free trial here:
                                </li>
                                <li>
                                    Then, you’ll have to connect your Facebook account to Supermetrics for Data Studio. In order to do that, you should log into Google Data Studio on
                                    <a href="http://datastudio.google.com" target="_blank" rel="noopener noreferrer">datastudio.google.com</a>
                                    {' '}
                                    and then click on “create” and select “data source” from the dropdown.

                                </li>

                                <img src="https://img.genflow.com/gya/plugandplays/trueTracking2-6.png" alt="" />

                                <li>Scroll down to the Partner Connectors sections and search for Facebook Ads. Alternatively, you can search for Facebook Ads at the search bar on top. </li>

                                <img src="https://img.genflow.com/gya/plugandplays/trueTracking3.png" alt="" />

                                <li>Next, authorize the connector by clicking the “Authorize” button and connecting to your Facebook Account.</li>

                                <img src="https://img.genflow.com/gya/plugandplays/trueTracking4.png" alt="" />

                                <li>After you have connected your Facebook account, select the ad accounts you want to pull data from. You can pull data from multiple accounts at the same time. We suggest you keep one client per report.</li>

                                <img src="https://img.genflow.com/gya/plugandplays/trueTracking5.png" alt="" />

                                <li>Finally, rename your report at the top left corner where it says “Untitled Data Source”. We suggest you name it “Facebook Ads + Client Name” to keep things organized.</li>

                                <li>
                                    If you have any problems on this step, you can refer to this guide for troubleshooting:
                                    <a className="break-words" href="https://support.supermetrics.com/support/solutions/articles/19000109662-how-to-authenticate-re-authenticate-facebook-ads-in-google-sheets-google-data-studio-microsoft-exc#authDS" target="_blank" rel="noopener noreferrer">https://support.supermetrics.com/support/solutions/articles/19000109662-how-to-authenticate-re-authenticate-facebook-ads-in-google-sheets-google-data-studio-microsoft-exc#authDS </a>


                                </li>
                            </ul>

                            <p className="bold">3) Connecting Supermetrics for Data Studio to Shopify</p>

                            <ul>
                                <li>
                                    Again, log into Google Data Studio on
                                    <a href="http://datastudio.google.com" target="_blank" rel="noopener noreferrer">datastudio.google.com</a>
                                    {' '}
                                    and then click on “create” and select “data source” from the dropdown.
                                </li>

                                <img src="https://img.genflow.com/gya/plugandplays/trueTracking2-6.png" alt="" />

                                <li>Search for Shopify by Supermetrics and click on it.</li>

                                <img src="https://img.genflow.com/gya/plugandplays/trueTracking7.png" alt="" />

                                <li>On the next page, click on Authorize.</li>

                                <img src="https://img.genflow.com/gya/plugandplays/trueTracking8.png" alt="" />

                                <li>Insert the store name and click on start.</li>

                                <img src="https://img.genflow.com/gya/plugandplays/trueTracking9.png" alt="" />

                                <li>Insert your login email address and click on next, then insert your password and click on log in. </li>

                                <img src="https://img.genflow.com/gya/plugandplays/trueTracking10.png" alt="" />

                                <li>
                                    If you are authenticating Shopify for the first time, you will need to list the Shopify app. Please note that this step occurs only the first time you authenticate the data source, you do not need to install the app again. To do so, click on Install unlisted app, as below:
                                </li>

                                <img src="https://img.genflow.com/gya/plugandplays/trueTracking11.png" alt="" />

                                <li>Then make sure the account you want to pull data from is selected.</li>

                                <img src="https://img.genflow.com/gya/plugandplays/trueTracking12.png" alt="" />

                                <li>Finally, rename your report at the top left corner where it says “Untitled Data Source”. We suggest you name it “Shopify + Store Name” to keep things organized.</li>
                            </ul>

                            <p className="bold">4) Creating Your Report On Data Studio and Adding the Data Sources</p>

                            <ul>
                                <li>
                                    Log into Google Data Studio at
                                    <a href="http://datastudio.google.com" target="_blank" rel="noopener noreferrer">datastudio.google.com </a>

                                </li>

                                <li>Click on one of the template that you like under the template gallery tab. We usually go with the “Google Ads Overview” template.</li>

                                <li>Click on “Use Template” at the top right corner</li>

                                <img src="https://img.genflow.com/gya/plugandplays/trueTracking13.png" alt="" />

                                <li>Then click on “Copy Report”</li>

                                <img src="https://img.genflow.com/gya/plugandplays/trueTracking14.png" alt="" />

                                <li>Now let’s first clean up this report and get rid of what we don’t need. Select all the widgets that are below the report header. Then, click on the delete button on your keyboard to get rid of them. </li>

                                <img src="https://img.genflow.com/gya/plugandplays/trueTracking15.png" alt="" />

                                <li>

                                    You can also delete the Google Ads logo on top and the data source selector at the top middle.
                                </li>

                                <img src="https://img.genflow.com/gya/plugandplays/trueTracking16.png" alt="" />

                                <p>Now, let’s add the data sources we created earlier to this report. To do so, click on “Resources” and then “Managed Added Data Sources”. You can then click on “Add a data source”. </p>

                                <img src="https://img.genflow.com/gya/plugandplays/trueTracking17.png" alt="" />

                                <li>Next, click on “My Data Sources” at the top of the window that shows up and then select your Facebook data source that you created earlier. Then, click the blue “Add” button at the bottom right corner. </li>

                                <img src="https://img.genflow.com/gya/plugandplays/trueTracking18.png" alt="" />

                                <li>Finally, click on “Add to Report” and the data source will be added to your Google Data Studio report.</li>

                                <img src="https://img.genflow.com/gya/plugandplays/trueTracking19.png" alt="" />

                                <li>
                                    Repeat this same process for the Shopify Data Source you created earlier.
                                </li>
                                <li>You can then close the data sources window and go back to the report. </li>
                            </ul>

                            <p className="bold">5) Adding Charts To Your Google Data Studio Report</p>

                            <ul>
                                <li>Click on the “Add a chart” button and select “Table” from the dropdown.</li>

                                <img src="https://img.genflow.com/gya/plugandplays/trueTracking20.png" alt="" />

                                <li>Drop it on the report and resize the Table to the desired size.</li>

                                <img src="https://img.genflow.com/gya/plugandplays/trueTracking21.png" alt="" />

                                <li>Select at your table and then click on “Blend Data” at the editing menu you have on the right portion of your screen.</li>

                                <img src="https://img.genflow.com/gya/plugandplays/trueTracking22.png" alt="" />

                                <li>
                                    Click on “add another data source” and select your shopify Data Source in case that the data source you already have selected is the Facebook ads one, as in the screenshot below. If the data source you already have selected there is the Shopify one, just select the Facebook ads source then.
                                </li>

                                <img src="https://img.genflow.com/gya/plugandplays/trueTracking23.png" alt="" />

                                <li>You should then have your two data sources selected as in the screenshot below</li>

                                <img src="https://img.genflow.com/gya/plugandplays/trueTracking24.png" alt="" />

                                <li>Now, on the Facebook Data Source you have to search for the following fields and drag and drop then to “Join Keys”: Campaign ID, Ad set ID, Date. If you don’t see the option to select search for these fields, you just have to click on the little arrow on top, right next to the name of the data source. It should then look like this:</li>

                                <img src="https://img.genflow.com/gya/plugandplays/trueTracking25.png" alt="" />

                                <li>Now on the Shopify data sources we’re going to drag and drop the “UTM campaign name” and “UTM content” fields on where it says “Missing” in red. </li>

                                <img src="https://img.genflow.com/gya/plugandplays/trueTracking26.png" alt="" />
                                >

                                <li>
                                    It’s important that you have the fields in this exact order as in the screenshot above. What we just did is telling data studio that the “Campaign ID” field from the Facebook Ads source matches the “UTM campaign name” from the Shopify source. The same for the “Ad set ID” matching with the “UTM content”, as well as the dates from both. This is how data studio is going to blend the rest of the data from the two data sources together so that we can see the real transaction value reported by shopify together with the ad spend reported by Facebook, per campaign and adset. It’s also worth noting that the Campaign ID matches the “UTM campaign name” and the “Ad set ID” matches the “UTM content” because that’s the data that we’ve added to the ads URLs in the first step of this guide.
                                </li>

                                <li>Now what we have to do is adding the rest of the fields that we want to have on our report. Starting by the Facebook Ads source, we usually add Campaign Name and Ad set Name on the Dimensions, and Amount Spent and Purchase Conversion Value.</li>

                                <img src="https://img.genflow.com/gya/plugandplays/trueTracking27.png" alt="" />

                                <li>Now on the Shopify data source we just add Net Sales on the metrics. After all those fields are added, we just click on the “save” button. </li>

                                <img src="https://img.genflow.com/gya/plugandplays/trueTracking28.png" alt="" />

                                <li>Now your Table we’ll probably be looking all weird with a bunch of data fields added to it. As in the screenshot below. All we have to do now is organize and select the fields we want to see. </li>

                                <img src="https://img.genflow.com/gya/plugandplays/trueTracking29.png" alt="" />

                                <li>
                                    We like to leave it with only the campaign name on Dimensions and then Amount Spent, Purchase Conversion Value, and Net Sales on the Metrics. We always like to manually calculate the real ROAS as well. You can do that by clicking on “Add Metric” and then
                                    “Create field”
                                </li>

                                <img src="https://img.genflow.com/gya/plugandplays/trueTracking30.png" alt="" />

                                <li>You can then name the metric “ROAS” and add as the following as the formula: SUM(Net sales)/SUM(Purchase conversion value). With that done, you just click on Apply. This is going to automatically calculate the ROAS using the transaction data from shopify and ad spend data from Facebook, so that you can finally have the real ROAS of your campaigns.</li>

                                <img src="https://img.genflow.com/gya/plugandplays/trueTracking31.png" alt="" />

                                <li>Finally, your report should look something like this! You can see your real ROAS there, as well as compare the discrepancy in revenue between what is reported by Facebook versus what is reported by Shopify. </li>

                                <li>A few other adjustments we like to make are, first, changing the date filter to “today”. You can do that by selecting the filter and changing from last 28 days to today. This will make your report load a lot faster. </li>

                                <img src="https://img.genflow.com/gya/plugandplays/trueTracking32.png" alt="" />

                                <li>Second, we like to add a dropdown list control. And add “Campaign Name” as the control field. This way, we can select to show only the data from the campaigns we want. This is useful when you have a table that only shows you your adsets. This way, you can see how each adset inside the campaign are performing. </li>

                                <img src="https://img.genflow.com/gya/plugandplays/trueTracking33.png" alt="" />

                                <li>
                                    And the third one is actually creating the Table that shows adset data. To do that, you can just copy and paste the table that we already created and just change the campaign name dimension to ad set name.
                                </li>

                                <img src="https://img.genflow.com/gya/plugandplays/trueTracking34.png" alt="" />
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
