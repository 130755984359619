import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
// import { Link } from 'react-router-dom';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        sectionName: 'Before you start:', available: true,
    },
    {
        sectionName: 'D7 Lead Finder', available: true,
    },
    {
        sectionName: 'Google Maps', available: true,
    },
    {
        sectionName: 'Groupon', available: true,
    },
    {
        sectionName: 'Facebook Groups', available: true,
    },
];

const title = "Finding Leads for Local Businesses"

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]}  className="plug-block">  
                        <h2 className="mb-4">{title}</h2>
                            <div className="plug-block-subheading">
                                Before you start:
                            </div>

                            <p>
                                What you’ll need:
                            </p>

                            <ul>
                                <li>
                                    Facebook Account
                                </li>
                                <li>
                                    Instagram Account
                                </li>
                            </ul>
                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">D7 Lead Finder</div>

                            <p>
                                D7 Lead Finder is a tool which scrapes search engines and other databases to provide a database to your specifications.
                            </p>

                            <img src="https://genflow-images-prod-eu-west-1.s3.eu-west-1.amazonaws.com/gya/plugandplays/image-6.png" alt="" />

                            <p>
                                There are multiple tiers. The starter is more than appropriate for beginner agency owners and allows 10 daily searches and 3,500 daily leads.
                            </p>

                            <p>
                                Other tiers offer extra functionality such as detecting Facebook pixels, and whether companies are running Facebook or Google adverts - but are much more expensive.
                            </p>

                            <img src="https://genflow-images-prod-eu-west-1.s3.eu-west-1.amazonaws.com/gya/plugandplays/image2-copy.png" alt="" />

                            <p>
                                Using D7 Lead Finder is easy - simply choose your keyword (D7 Lead Finder offers a complete list) and the city of your choice.
                            </p>

                            <img src="" alt="" />

                            <p>
                                From here, you can further filter results, and export the list for manual outreach or for automated outreach.
                            </p>
                        </div>

                        <div ref={refs[2]} className="plug-block">
                            <div className="plug-block-subheading">
                                Google Maps
                            </div>
                        </div>

                        <p>
                            Google Maps is the largest database of businesses in the world. However, unlike D7 Lead Finder, it’s  catered towards consumers. Of course, this doesn’t mean that you can’t use it to find leads too.
                        </p>

                        <img src="" alt="" />

                        <p>
                            Search for the business type and location that you want to generate leads for in your area, or any area.
                        </p>

                        <div ref={refs[3]} className="plug-block">
                            <div className="plug-block-subheading">
                                Groupon
                            </div>

                            <p>
                                Groupon is a notoriously bad marketing partner to businesses: they take a massive percentage of each sale and can wipe out any profit that businesses make.
                            </p>

                            <p>
                                Search in your local area for your niche, for example,
                                <a href="https://www.groupon.com/browse/lakeland?lat=27.665&lng=-81.516&administrative_area=FL&query=dentist&address=Florida%2C+USA&division=lakeland&locale=en_US" target="_blank" rel="noopener noreferrer">dentists in Florida. </a>
                            </p>

                            <img src="https://genflow-images-prod-eu-west-1.s3.eu-west-1.amazonaws.com/gya/plugandplays/image-5.png" alt="" />

                            <p>
                                You’ll get a full list of businesses which are using Groupon to acquire new customers and an idea of the cost per acquisition they are content with to do this.
                            </p>

                            <p>This gives you a wealth of information and another angle to approach these businesses: ask them how their Groupon adverts are going. </p>

                            <p>If they’re not working - suggest your services as an alternative.</p>
                        </div>

                        <div ref={refs[4]} className="plug-block">
                            <div className="plug-block-subheading">
                                Facebook Groups
                            </div>

                            <p>
                                Most cities have dedicated Facebook groups for either business owners, or niches within this: for example dentists in Colorado.
                            </p>

                            <p>
                                Whilst many groups require that you are a business owner, or operate in that niche, others are ‘open’ which allow you to join without vetting.
                            </p>

                            <p>
                                However, entry to these groups is the easy part.
                            </p>

                            <img src="https://genflow-images-prod-eu-west-1.s3.eu-west-1.amazonaws.com/gya/plugandplays/image-4.png" alt="" />

                            <p>
                                Next, you have to decide your angle and dedicate time to listening to the group.
                            </p>

                            <p>
                                For example, you can add members of the groups as friends after seeing a post, and respond to them specifically. If a group member talks about an event that they have coming up, message them and offer to help promote the event via Facebook ads.
                                {' '}
                            </p>

                            <p>
                                You might also offer lead magnets in the group: like a free social media audit.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
