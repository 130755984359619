import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';
import GettingPaid1 from '../../../../../assets/img/plugandplay/GettingPaid1.png';
import GettingPaid2 from '../../../../../assets/img/plugandplay/GettingPaid2.png';
import GettingPaid3 from '../../../../../assets/img/plugandplay/GettingPaid3.png';
import GettingPaid4 from '../../../../../assets/img/plugandplay/GettingPaid4.png';
import GettingPaid5 from '../../../../../assets/img/plugandplay/GettingPaid5.png';
import GettingPaid6 from '../../../../../assets/img/plugandplay/GettingPaid6.png';
import GettingPaid7 from '../../../../../assets/img/plugandplay/GettingPaid7.png';
import GettingPaid8 from '../../../../../assets/img/plugandplay/GettingPaid8.png';
import GettingPaid9 from '../../../../../assets/img/plugandplay/GettingPaid9.png';
import GettingPaid10 from '../../../../../assets/img/plugandplay/GettingPaid10.png';
import GettingPaid11 from '../../../../../assets/img/plugandplay/GettingPaid11.png';
import GettingPaid12 from '../../../../../assets/img/plugandplay/GettingPaid12.png';
import GettingPaid13 from '../../../../../assets/img/plugandplay/GettingPaid13.png';
import GettingPaid14 from '../../../../../assets/img/plugandplay/GettingPaid14.png';

const sections = [
    {
        available: true, sectionName: 'Set Up a Bank Account',
    },

];

const title = "Getting Paid"

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]}  className="plug-block">  
                        <h2 className="mb-4">{title}</h2>
                            <div className="plug-block-subheading">
                                Set Up a Bank Account

                            </div>

                            <p>
                                The first step is to get a bank account sorted. This is really location dependent but should be a fairly straightforward process. Especially if you choose a digital banking solution.

                            </p>

                            <p>
                                Now you can pick pretty much anyone you like but if you want to get setup super quick - banks like Revolut, Monzo & Starling are really really solid options. With most of them you can get setup within 24hours to 7 days.


                            </p>

                            <p>
                                Also bear in mind that if you’re under the age of 18 - you should have no problems in 99% of cases. In that rare 1% - then you’ll need to figure out some sort of arrangement with your parents, etc.


                            </p>
                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">
                                Setting Up Stripe


                            </div>

                            <p>
                                Once you have your bank account created, you just need to connect it to Stripe.

                            </p>

                            <p>
                                Stripe is by far the best merchant processor in the world. I’ve processed millions through their system and it’s great.


                            </p>

                            <p>
                                They charge a 2.9% merchant fee and it takes 2-10 days to arrive in your bank depending on where you are in the world. TRUST ME though, it’s worth it. The ability to auto-bill your clients will literally half your stress level as an agency owner.


                            </p>

                            <p>
                                Registering on Stripe is quick and easy. Here’s a video tutorial straight from the Stripe support team going over the whole process:
                            </p>

                            <a href="https://youtu.be/u9YcbWDioC4 " target="_blank" rel="noopener noreferrer">https://youtu.be/u9YcbWDioC4 </a>

                            <p>If you need further instructions on it, you can visit this tutorial: </p>

                            <a href="https://support.stripe.com/questions/getting-started-with-stripe-create-or-connect-an-account?locale=en-US " target="_blank" rel="noopener noreferrer">https://support.stripe.com/questions/getting-started-with-stripe-create-or-connect-an-account?locale=en-US </a>

                            <p>
                                Once you set up Stripe, it’ll take a few days for you to get accepted. Stripe DOES allow you to use the platform if you’re under 18 which is amazing. They might ask for your parents to send in an email confirming they allow you to sign up.
                            </p>

                            <p>
                                Also - You can sign up to Stripe if you’re just registered as a Sole Trader or a registered business
                            </p>

                            <p>
                                <strong>
                                    Stripe Alternatives:
                                </strong>
                            </p>

                            <p>
                                If Stripe isn’t yet supported in your country, here’s a few alternatives:


                            </p>

                            <ul>
                                <li>
                                    Square - This merchant is supported in the same countries as Stripe but is a decent alternative if you don’t want to use Stripe. That said, I still prefer Stripe.


                                </li>
                                <li>
                                    GoCardLess - This is a UK only company and once again, might be an option for some of you outside of Stripe.


                                </li>
                                <li>
                                    PayPal - One of the biggest merchants on earth, but one with a lot of problems usually. PayPal is a great option if you truly can’t use any of the other merchants but it’s a last resort. They’re known for holding money, generally having terrible customer service and high processing fees, especially if you’re converting currencies on your transactions. That said - If you have no other option then this is still the best option.
                                </li>
                            </ul>
                        </div>

                        <div ref={refs[2]} className="plug-block">
                            <div className="plug-block-subheading">
                                Connecting Stripe To AgenciFlow


                            </div>

                            <p>
                                The next step is to connect Stripe to your AgenciFlow account.
                            </p>

                            <p>
                                That way - when you send an invoice to a client using AgenciFlow & they pay with their card, it’ll automatically get paid into your Stripe account which then automatically gets paid into your bank account in 2-7 days depending on where you are in the world.


                            </p>

                            <p>
                                To do so, you first need to go to the “Invoices” section on AgenciFlow.


                            </p>

                            <img src={GettingPaid1} alt="" />

                            <p>
                                If it is your first time in this section, you’re going to see this blue bar at the top asking to connect to your payment processor. So just click on “Connect”.
                            </p>

                            <p>You’ll be redirected to the settings tab. Alternatively, if you don’t see this bar at the top, just click on “settings” tab at the left menu.</p>

                            <p>
                                There, you’ll have the option to connect your Stripe account. Click on “Get Paid With Stripe” to configure the integration.
                            </p>

                            <img src={GettingPaid2} alt="" />

                            <p>
                                On this next page, just enter the same email address you have used to create your stripe account on the previous step. Hit continue.
                            </p>

                            <img src={GettingPaid3} alt="" />

                            <p>Type your password and hit continue.</p>

                            <p>You’ll probably be asked for a 2 factor authentication code. The code will be sent to the phone number you have registered on Stripe. Just take a look at your messages on your phone and type the code you received. </p>

                            <img src={GettingPaid4} alt="" />

                            <p>
                                Then, select the account that you want to connect with AgenciFlow (in case that you have more than one).
                            </p>

                            <img src={GettingPaid5} alt="" />

                            <p>
                                Your stripe account is now connected to AgenciFlow and ready to send invoices and receive payments!
                            </p>
                        </div>

                        <div ref={refs[3]} className="plug-block">
                            <div className="plug-block-subheading">
                                Creating Your First Invoice


                            </div>

                            <p>
                                Now, let’s go back to the main invoices section and create your first invoice.


                            </p>

                            <img src={GettingPaid6} alt="" />

                            <p>Under “Type” you can select if you want to send this invoice once or set it up as a retainer. Let’s say we want to do retainer. Simply select “retainers”. </p>

                            <p>
                                Under “Currency”, select your desired currency.
                            </p>

                            <p>
                                Under “Client”, simply input your client’s company name and their email address.
                            </p>

                            <img src={GettingPaid7} alt="" />

                            <p>
                                Now, under “Schedule”, we can set up the frequency you want your client to be billed. Let’s say we’re billing a client monthly and we want to start doing so today, with no end date.
                            </p>

                            <p>
                                I’m simply going to select “Monthly” as the interval, set the start date for today and put the end date for a year from now.
                            </p>

                            <img src={GettingPaid8} alt="" />

                            <p>
                                On “Invoice description” you simply want to describe your service, add its price and quantity. In our example, we’ll use “Media buying” and a $3.000 retainer. Quantity will be set to 1, because we’re only providing then with one “unity” of this service.
                            </p>

                            <p>
                                You also have the option to add more services if needed.
                            </p>

                            <img src={GettingPaid9} alt="" />

                            <p>
                                Additionally, you can also add notes to the invoices as well as discounts and sales taxes.
                            </p>

                            <img src={GettingPaid10} alt="" />

                            <p>
                                Now, let’s add a logo to our invoice. In the preview invoice section, click on “Add your logo”.
                            </p>

                            <img src={GettingPaid11} alt="" />

                            <p>
                                Then, simply select your logo from your computer.
                            </p>

                            <img src={GettingPaid12} alt="" />

                            <p>
                                Finally, click on “Create Subscription”.
                            </p>

                            <p>Your subscription is now created and your client will receive an invoice in their email monthly. The first one will be sent at the start date you have set. </p>

                            <p>
                                You can always click on summary at the left menu to see if the invoice has been sent or if it got paid already.
                            </p>

                            <img src={GettingPaid13} alt="" />

                            <p>
                                You can also click on the little eye icon to see what your invoice will look like from your client’s end. Here’s a spoiler:
                            </p>

                            <img src={GettingPaid14} alt="" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
