import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';

import { startCase } from 'lodash';
import { HuePicker as ColourPicker } from 'react-color';
import { Input } from 'reactstrap';
import { EventForm, TaskForm } from './components';
import withEventDialog from './withEventDialog';
import styles from './styles';


const EventDialog = ({
    classes,
    event,
    handleClose,
    onTitleChange,
    handleStartChange,
    handleEndChange,
    handleStartEndChange,
    handleDone,
    handleAllDayChange,
    deleteEvent,
    handleEventColourUpdate,
}) => {
    const colour = event?.colour;

    const [colourHexText, setColourHexText] = useState(colour);
    useEffect(() => { setColourHexText(colour); }, []);

    useEffect(() => {
        if (!!colourHexText && colourHexText.match(/^#[a-f0-9]{6}$/i) !== null) {
            handleEventColourUpdate(colourHexText);
        }
    }, [colourHexText]);

    const closeEvent = () => {
        handleClose();
        if (event?.deselect) {
            event.deselect();
        }
    };
    return (
        <Dialog open={!!event} onClose={handleClose} fullWidth maxWidth="sm" style={{ paddingTop: 0, color:"white" }} className="bodyBg">
            <DialogTitle className={classes.dialogTitle} style={{ color:"white" }} >
                {event?.isNewEvent ? `New ${startCase(event?.entryType)}` : `Edit ${startCase(event?.entryType)}`}
            </DialogTitle>
            <DialogContent className="text-white" style={{ color:"white" }}>

                {/* { */}
                {/*    event?.isNewEvent && ( */}
                {/*        <Tabs */}
                {/*            className={classes.tabs} */}
                {/*            value={event?.entryType === 'event' ? 0 : 1} */}
                {/*            variant="fullWidth" */}
                {/*            textColor="primary" */}
                {/*            indicatorColor="primary" */}
                {/*        > */}
                {/*            <Tab label="Event" onClick={() => changeType('event')} /> */}
                {/*            <Tab label="Task" onClick={() => changeType('task')} /> */}
                {/*        </Tabs> */}
                {/*    ) */}
                {/* } */}

                <TextField
                    autoFocus
                    id="title"
                    label="Title"
                    value={event?.title}
                    style={{ color:"white" }}
                    className="text-white"
                    onChange={onTitleChange}
                    type="text"
                    fullWidth
                />


                {event?.entryType === 'event'
                    ? (
                        <EventForm
                            classes={classes}
                            style={{ color:`white !important` }}
                            event={event}
                            handleStartChange={handleStartChange}
                            handleEndChange={handleEndChange}
                            handleStartEndChange={handleStartEndChange}
                            handleAllDayChange={handleAllDayChange}
                        />
                    )
                    : (
                        <TaskForm
                            classes={classes}
                            style={{ color:"white" }}
                            event={event}
                            handleStartEndChange={handleStartEndChange}
                        />
                    )
                }
                <div className="color-picker" style={{ color:"white" }}>
                    <ColourPicker
                        color={colour}
                        onChange={({ hex }) => setColourHexText(hex)}
                        onChangeComplete={({ hex }) => setColourHexText(hex)}
                    />
                    <div className="color-picker-input-container text-black">
                        <div className="event-colour-icon" style={{ background: colour }} />
                        <Input
                            type="text"
                            defaultValue={colour}
                            value={colourHexText}
                            onChange={(e) => {
                                setColourHexText(e.target.value);
                            }}
                        />
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                {
                    !event?.isNewEvent && (
                        <div className={classes.deleteContainer}>
                            <Button onClick={() => { deleteEvent(event); closeEvent(); }} variant="outlined">
                                Delete
                            </Button>
                        </div>
                    )
                }
                <Button
                    onClick={() => {
                        closeEvent();
                    }}
                   style={{ backgroundColor: "white"}}
                   className="buttonHover"
                >
                    Cancel
                </Button>
                <Button onClick={handleDone} style={{backgroundColor: "black", color:"white" }} variant="contained">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default withEventDialog(withStyles(styles)(EventDialog));
