/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import {
    Card,
    CardBody,
    Col,
    Container,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
} from 'reactstrap';
import accounting from 'accounting';
import Footer from '../../../components/Footer';
import '../../../assets/scss/simple-tools.scss';
import GYAFooterScene from '../../../assets/img/footer-scene-gya.png';
import CurrencySelector from '../../../components/CurrencySelector';
import { UserDataContext } from '../../../providers/UserDataProvider';

export default () => {
    const [ltv, setLTV] = useState(2000); // #
    const [cpl, setCPL] = useState(5); // %
    const [sur, setSUR] = useState(20); // #
    const [cr, setCR] = useState(20); // #
    const [leads, setLeads] = useState(100); // #
    const { userCurrency } = useContext(UserDataContext);

    const cpsu = (cpl * leads) / ((leads * sur) / 100);
    const cpc = (cpl * leads) / ((((leads * sur) / 100) * cr) / 100);
    const ltc = (((cr / 100) * sur) / 100) * 100;
    const ltvLeads = ((leads * ltc) / 100) * ltv;

    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, []);
    return (
        <>
            <main className="simple-tools-page text-white">
                <section className="section-profile-cover section-shaped my-10 sm:my-12">
                    <Container>
                        <Row>
                            <Col lg={12} className="text-center">
                                <h1 className="hidden sm:block">Lead Generation Success Calculator</h1>
                                <h3 style={{fontSize: '26px'}} className="block sm:hidden">Lead Generation Success Calculator</h3>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="section section-lg pt-lg-3 pt-8">
                    <Container>
                        <Row className="mb-4 shadow shadow--hover">
                            <Col lg={12}>
                                <Row className="flex flex-col sm:flex-row w-11/12 sm:w-8/12 mx-auto shadow-md card">
                                    <Col lg={7} className="w-full sm:w-7/12 md:w-10/12 p-4 sm:p-8">
                                        <div className="left-input-card ">
                                            <CardBody>
                                                <CurrencySelector />
                                                <div className="mt-8 block sm:hidden"></div>
                                                <strong>
                                                    Please enter the Metrics, Rates and Leads below:
                                                </strong>
                                                <Row className="tool-form-row flex flex-col sm:flex-row items-start sm:items-end justify-between py-4 body2">
                                                    <Col sm={9} className="tool-form-label">
                                                        Lifetime Value (LTV)
                                                    </Col>
                                                    <Col sm={3} className="tool-form-input w-full sm:w-auto">
                                                        <InputGroup className="flex flex-row items-end bg-transparent inputBox" style={{borderBottomColor: "#434343", marginBottom: "0px"}}>
                                                            <InputGroupAddon addonType="prepend" className="bg-transparent inputBox" style={{ marginBottom: "0px"}}>
                                                                <InputGroupText>{userCurrency}</InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder={`${userCurrency}100`}
                                                                type="number"
                                                                value={ltv}
                                                                className="bg-transparent p-2 pb-0 w-full sm:w-24 inputBox"
                                                                style={{marginBottom: "0px"}}
                                                                onChange={e => setLTV(e.target.value)}
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                                <Row className="tool-form-row flex flex-col sm:flex-row items-start sm:items-end justify-between py-4 body2">
                                                    <Col sm={9} className="tool-form-label">
                                                        Cost of per Lead (CPL)
                                                    </Col>
                                                    <Col sm={3} className="tool-form-input w-full sm:w-auto">
                                                        <InputGroup className="flex flex-row items-end bg-transparent inputBox" style={{borderBottomColor: "#434343", marginBottom: "0px"}}>
                                                            <InputGroupAddon addonType="prepend" className="bg-transparent inputBox" style={{ marginBottom: "0px"}}>
                                                                <InputGroupText>{userCurrency}</InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder={`${userCurrency}100`}
                                                                type="number"
                                                                value={cpl}
                                                                className="bg-transparent p-2 pb-0 w-full sm:w-24 inputBox"
                                                                style={{marginBottom: "0px"}}
                                                                onChange={e => setCPL(e.target.value)}
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                </Row>

                                                <Row className="tool-form-row flex flex-col sm:flex-row items-start sm:items-end justify-between py-4 body2">
                                                    <Col sm={9} className="tool-form-label">
                                                        Show Up Rate
                                                    </Col>
                                                    <Col sm={3} className="tool-form-input w-full sm:w-auto">
                                                        <InputGroup className="flex flex-row items-center bg-transparent inputBox" style={{borderBottomColor: "#434343", marginBottom: "0px"}}>
                                                            <Input
                                                                placeholder="50"
                                                                type="number"
                                                                value={sur}
                                                                className="bg-transparent p-2 pb-0 w-full sm:w-24 inputBox"
                                                                style={{marginBottom: "0px"}}
                                                                onChange={e => setSUR(e.target.value)}
                                                            />
                                                            <InputGroupAddon addonType="append" className="bg-transparent inputBox" style={{ marginBottom: "0px"}}>
                                                                <InputGroupText>%</InputGroupText>
                                                            </InputGroupAddon>
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                                <Row className="tool-form-row flex flex-col sm:flex-row items-start sm:items-end justify-between py-4 body2">
                                                    <Col sm={9} className="tool-form-label">
                                                        Conversion Rate
                                                    </Col>
                                                    <Col sm={3} className="tool-form-input w-full sm:w-auto">
                                                        <InputGroup className="flex flex-row items-center bg-transparent inputBox" style={{borderBottomColor: "#434343", marginBottom: "0px"}}>
                                                            <Input
                                                                placeholder="50"
                                                                type="number"
                                                                value={cr}
                                                                className="bg-transparent p-2 pb-0 w-full sm:w-24 inputBox"
                                                                style={{marginBottom: "0px"}}
                                                                onChange={e => setCR(e.target.value)}
                                                            />
                                                            <InputGroupAddon addonType="append" className="bg-transparent inputBox" style={{ marginBottom: "0px"}}>
                                                                <InputGroupText>%</InputGroupText>
                                                            </InputGroupAddon>
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                                <Row className="tool-form-row flex flex-col sm:flex-row items-start sm:items-end justify-between py-4 body2">
                                                    <Col sm={9} className="tool-form-label">
                                                        Leads from that month
                                                    </Col>
                                                    <Col sm={3} className="tool-form-input w-full sm:w-auto">
                                                        <Input
                                                            placeholder="1"
                                                            type="number"
                                                            value={leads}
                                                            className="bg-transparent p-2 w-full sm:w-28 pb-0 inputBox"
                                                            style={{borderBottomColor: "#434343", marginBottom: "0px"}}
                                                            onChange={e => setLeads(e.target.value)}
                                                        />
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </div>
                                    </Col>
                                    <Col lg={5} className="right-input-col p-0 w-full sm:w-5/12 md:w-7/12">
                                        <Card className="right-input-card flex items-center justify-center">
                                            <CardBody className="p-0">
                                                <div className="result">
                                                    <h2 className="text-white result-title mb-4 hidden sm:block">
                                                        Result
                                                    </h2>
                                                    <h3 className="text-white result-title mb-4 block sm:hidden">
                                                        Result
                                                    </h3>
                                                    <Row className="result-grid-row flex flex-row justify-around">
                                                        <Col className="w-auto sm:w-44">
                                                            <div className="result-grid-value">
                                                                {`${accounting
                                                                    .formatMoney(cpsu, userCurrency)
                                                                    .replace('.00', '')}`}
                                                            </div>
                                                            <div className="result-grid-label">
                                                                Cost Per Show Up (CPSU)
                                                            </div>
                                                        </Col>
                                                        <Col className="w-auto sm:w-44">
                                                            <div className="result-grid-value">
                                                                {`${accounting
                                                                    .formatMoney(cpc, userCurrency)
                                                                    .replace('.00', '')}`}
                                                            </div>
                                                            <div className="result-grid-label">
                                                                Cost Per Conversion
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row className="result-grid-row flex flex-row justify-around">
                                                        <Col className="w-auto sm:w-44">
                                                            <div className="result-grid-value">
                                                                {`${ltc
                                                                    .toFixed(2)
                                                                    .replace('.00', '')}%`}
                                                            </div>
                                                            <div className="result-grid-label">
                                                                Lead to Conversion
                                                            </div>
                                                        </Col>
                                                        <Col className="w-auto sm:w-44">
                                                            <div className="result-grid-value">
                                                                {`${accounting
                                                                    .formatMoney(ltvLeads, userCurrency)
                                                                    .replace('.00', '')}`}
                                                            </div>
                                                            <div className="result-grid-label">
                                                                LTV of Leads
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </main>
            <Footer />
        </>
    );
};
