import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { Link } from 'react-router-dom';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        available: true, sectionName: 'Create a new campaign',
    },

];
const title = "How To Set Up A Lemlist Campaign"

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div  className="plug-block">  
                        <h2 className="mb-4">{title}</h2>
                            <p>
                                What you’ll need:

                            </p>

                            <ul>
                                <li><Link to="/resources/plug-and-play/warming-up-your-email-address">A warmed-up email address</Link></li>
                                <li><a href="https://docs.google.com/document/d/1psYYJWKfhoHnAcK8P8RK1q0qoXCEFxfsH0trN-xEKy0/edit" target="_blank" rel="noopener noreferrer">A set up LemList Account</a></li>
                                <li><Link to="/resources/plug-and-play/how-to-create-your-email-script">An email script</Link></li>
                            </ul>
                        </div>
                        <div ref={refs[0]} className="plug-block">
                            <div className="plug-block-subheading">
                                Create a new campaign
                            </div>

                            <p>
                                When you first access
                                {' '}
                                <a href="https://app.lemlist.com/" target="_blank" rel="noopener noreferrer">app.lemlist.com</a>
                                {' '}
                                , you’ll be presented with this screen:
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/lemlist-campaign-1-9f34a643-jpg.jpg" alt="" style={{ marginBottom: '1rem' }} />

                            <p>
                                In the bottom left-hand corner, click
                                {' '}
                                <a href="https://app.lemlist.com/campaigns/cam_vw3mC3TJb9bmcTcxo/wizard/name?wizard=1" target="_blank" rel="noopener noreferrer">‘New Campaign’</a>
                                .
                            </p>


                            <img src="https://img.genflow.com/gya/plugandplays/lemlist-campaign-2-685107f3-jpg.jpg" alt="" style={{ marginBottom: '1rem' }} />

                            <p>Use your own naming convention, select an emoji (unimportant) and create a label. Labels are useful for analysing reporting later. </p>

                            <p>
                                Then click ‘Next’.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/lemlist-campaign-3-ebac8f82-jpg.jpg" alt="" style={{ marginBottom: '1rem' }} />

                            <p>
                                Click ‘CSV’ - or enter emails manually.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/lemlist-campaign-4-3f846800-jpg.jpg" alt="" style={{ marginBottom: '1rem' }} />

                            <p>
                                If you upload a .CSV file, LemList will automatically match fields. Click ‘Next’.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/lemlist-campaign-5-dbba5d43-jpg.jpg" alt="" style={{ marginBottom: '1rem' }} />

                            <p>Whilst LemList offers a vast selection of email inspirations, you should still create your own templates and scripts that you are confident in. </p>

                            <p>
                                Select ‘Blank’.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/lemlist-campaign-6-ea7ff48a-jpg.jpg" alt="" style={{ marginBottom: '1rem' }} />

                            <p>Next, add your first email outreach. Make sure to include a subject line as this can be used for future emails in your sequence. </p>

                            <p>
                                Once you have inputted this, click ‘add a new step’.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/lemlist-campaign-7-1b07e633-jpg.jpg" alt="" style={{ marginBottom: '1rem' }} />

                            <p>
                                You can leave the subject line blank if you would like to use the same subject line as the previous step.
                            </p>

                            <p>
                                Choose the number of days you would like to wait to send the next step (note, you can select that if a lead replies, they will not receive the next step).
                            </p>


                            <p>
                                Then input the next message.
                            </p>

                            <p>
                                You can repeat this process with multiple messages. Click ‘Next’.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/lemlist-campaign-8-dc9ba06f-jpg.jpg" alt="" style={{ marginBottom: '1rem' }} />

                            <p>
                                Choose a schedule - either your local time zone, or the one that would best apply to the location you are reaching out to.
                            </p>

                            <p>
                                Next, select that you will only send messages on weekdays. Leave in the default times and waiting period between sending messages.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/lemlist-campaign-9-49b7554a-jpg.jpg" alt="" style={{ marginBottom: '1rem' }} />

                            <p>
                                Select the email address you will send the email through, and to stop sending messages when a lead replies.
                            </p>

                            <p>Then click ‘Create the campaign’.</p>

                            <img src="https://img.genflow.com/gya/plugandplays/lemlist-campaign-10-082a73b6-jpg.jpg" alt="" style={{ marginBottom: '1rem' }} />

                            <p>
                                Finally, review the emails to be sent to each individual lead. Whilst you can insert individual icebreakers, this is your chance to include final notes or variations per lead.
                            </p>

                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
