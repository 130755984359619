import React from 'react';
import { DateTimePicker, MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { ThemeProvider } from '@material-ui/core/styles';
import pickerTheme from '../../pickerTheme';

const EventForm = ({
    classes, event, handleStartChange, handleEndChange, handleAllDayChange, handleStartEndChange,
}) => {
    const Picker = event?.allDay ? DatePicker : DateTimePicker;
    const format = event?.allDay ? 'dddd, MMMM Do YYYY' : 'dddd, MMMM Do YYYY';

    return (
        <>
            <MuiPickersUtilsProvider utils={MomentUtils} style={{ color: "white" }} className="underline-36">
                <ThemeProvider theme={pickerTheme} style={{ color: "white" }} className="underline-36">
                    <Picker
                        variant="inline"
                        label="Start Time"
                        value={new Date(event?.startDateTime)}
                        onChange={(date) => {
                            if (event?.allDay) {
                                handleStartEndChange(date);
                            } else {
                                handleStartChange(date);
                            }
                        }}
                        fullWidth
                        ampm={false}
                        format={format}
                        style={{ marginTop: 20, color: "white" }}
                        className="underline-36"
                    />
                    {
                        !event?.allDay && (
                            <Picker
                                variant="inline"
                                label="End Time"
                                value={new Date(event?.endDateTime)}
                                onChange={handleEndChange}
                                fullWidth
                                ampm={false}
                                format={format}
                                style={{ marginTop: 20, color: "white" }}
                                className="underline-36"
                            />
                        )
                    }
                </ThemeProvider>
            </MuiPickersUtilsProvider>
            {/* <div className={`custom-control custom-checkbox mb-3 ${classes.checkbox}`}> */}
            {/*    <input */}
            {/*        className="custom-control-input" */}
            {/*        id="customCheck1" */}
            {/*        type="checkbox" */}
            {/*        onChange={handleAllDayChange} */}
            {/*        checked={event?.allDay} */}
            {/*    /> */}
            {/*    <label className="custom-control-label" htmlFor="customCheck1"> */}
            {/*        All day */}
            {/*    </label> */}
            {/* </div> */}
        </>
    );
};

export default EventForm;
