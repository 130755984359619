import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        available: true,
        sectionName: 'How to manage your budget: Split between campaigns and ad sets',
    },
    {
        available: true,
        sectionName: 'When to kill an ad set and what to do with the money',
    },
];

const title = 'Load Balancing Budgets';

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]}  className="plug-block">  
                        <h2 className="mb-4">{title}</h2>
                            <div className="plug-block-subheading">How to manage your budget: Split between campaigns and ad sets</div>

                            <p>Companies usually create set budgets for marketing that a lot of agencies have to abide by. But many newly-founded businesses without formal annual budgets and a sole focus on ‘scaling’ are happy to spend as much money as possible. </p>

                            <p>
                                Regardless of the company’s situation, your job is to optimize the budget and spend as much as possible while making them money.
                            </p>

                            <p>
                                Most of the time, if you have a set budget, but you're making your client money they will be happy to raise the budget or give you a no limit budget as long as you're profitable. This is the ideal situation as you don't have to be worried about either overspending or underspending.
                            </p>

                            <p>
                                When you talk to your clients about their budget, you should always focus on returns. It’s important that they understand that unless they are seeing returns, it’s unwise for them to spend any allocated budget. And if they are, it makes sense to spend more than this.
                            </p>

                            <p>
                                Unfortunately, even if your campaigns are highly-profitable, it’s difficult to scale predictably using Facebook’s ads manager. Many people consider Facebook like currency mint, constantly printing money with the touch of a lever.
                            </p>

                            <p>
                                Of course, for many clients this is the case. The problem is what happens when you pull the lever harder and faster: Facebook doesn’t act proportionately or relative to your inputs.
                            </p>

                            <p>
                                Because Facebook works on an ‘auction’ basis - and there’s limited eyeballs on the platform - this won’t happen.
                            </p>

                            <div className="examples-block">
                                <p>Let’s say you have a budget of $5,000 a month to spend on ads. This is roughly $166 a day. If this campaign is successful, you’ll want the $5,000 to be allocated as efficiently as possible, allowing you to maximize your returns. This is usually achieved with turning ‘off’ ad sets that are underperforming. </p>

                                <p>
                                    So if you have ten ad sets, all spending $20 a day - equalling $200 a day - even if they are all profitable, you’ll have to turn off your two least successful ad sets to stay within budget and to maximise your returns. If you had no budget, the most sensible course of action would be to keep all ad sets running, as long as they were profitable.
                                </p>
                            </div>

                            <p>
                                Given everything we know about testing, campaign structure, angles and audiences, is there a better way to allocate budgets across ad sets?
                            </p>

                            <p>
                                Let’s look at more ex
                            </p>

                            <p>
                                It begs the question after everything we've seen on testing, campaign structure, seeing which angles work, which audiences work, etc. How do we manage the budget, where do we put more, where do we put less.
                            </p>

                            <p>
                                If you have a set budget for the month it is wise to spend more at the beginning to get an idea of what works and speed up the process. There is no fixed split but I hope this example clarifies everything and it allows you to extrapolate to every case.
                            </p>

                            <p>
                                Let's have a $5000 dollar budget per month.
                            </p>

                            <p>
                                With what we've said about budgets we want to give Facebook at least enough money to get us sales, but not too much it doesn't know what to do with it.
                            </p>

                            <p>
                                $166 per day
                            </p>

                            <p>
                                That means 8 → 20 dollar ad sets or 4 → 40 dollar ad sets or 2 → 80 dollar ad sets.
                            </p>

                            <p>
                                Depending on the price point of the product you want to choose one of these options. Let's pick the middle ground and assume we expect a $20 CPA or ideally lower, with this we would be giving Facebook enough money to get us around 2 purchases a day. Let's also assume that we have 2 20 dollar adsets dedicated to retargeting, one for sociales one for website visitors. This leaves us with 3 ad sets. You can keep these 3 ad sets running for a couple days and some of them will perform and some of them won't.
                            </p>
                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">When to kill an ad set and what to do with the money</div>

                            <p>
                                The ad sets that are not performing (and we will define this don't worry) you kill and the ones that are performing you keep. If you kill one of the three cold ad sets you have, let's say at the third day of starting, you will at the end of the month have spent $4000, $1000 away from your target. This simply means that every time you kill an adset you have to replace it. You replace it with another interest you think could do well, another lookalike, another broad audience (no targeting). Or you change countries, genders, age ranges or the ads themselves. After you make a new ad set with that new variable you launch it and you're back on track to your monthly ad spend.
                            </p>

                            <p>
                                This is a fair strategy and I hope it was well explained.
                            </p>

                            <p>
                                This process can be made faster. You want at the beginning to test more angles, more interests, more lookalikes. That way you get your winners earlier and you keep them using less budget for the rest of the month and running for longer. This in the end is probably the same mathematically but you want to get results faster and see if you need to implement new things to make the ads work. Also with more ad spend the feedback loop is faster and you improve more often.
                            </p>

                            <p>
                                How this looks like in the example above is like this.
                            </p>

                            <p>
                                Instead of having 3 → $40 dollar ad sets and 2 → $20 dollar retargeting ad sets you have for the first week 6 → $40 dollar ad sets, this way you're testing twice as much. In the second week let's say that you only keep the best performing ones, you can for example keep 4 of them, on the third week you keep 2 of them and on the fourth you keep those 2 as well.
                            </p>

                            <p>
                                That way you spent the same but by week 2 you already knew your winners. And if you didn't you had the time in the first week to kill them and try other ones.
                            </p>

                            <p>
                                Usually good ads will perform from the get go, you will see it on the engagement, on the CTR, on the CPMs. To kill an ad set you should let them spend roughly 3 times the expected CPA and if there's no purchases or leads or whatever you are optimizing for you should kill the ad set. It is not a hard rule. Let's say it spent 3x the expected CPA and it has ROAS under 1 or no ROAS in the case of leads or calls, etc. it is up to you what to do, we usually let it run for another day, if it hasn't picked up anymore events we kill it. You can also make your decision based on other proxies like CTR, CPC, number of add to carts, etc. If you see lot's of those you can let it run for a little longer. A common mistake is holding on to an ad set for longer just hoping it will work because it has a lot of add to carts or a cheap CPC. Don't hold onto them as long and launch new ones and test other things.
                            </p>

                            <p>
                                In the scenario where there is no budget which is the case with most of our clients we usually have our testing campaign running with a lot of ad sets trying all sorts of combinations, this way we test extremely fast and have in a couple days an idea of what works and what doesn't. What does work we just put in a new campaign and keep running it then, at similar budgets. Remember Facebook doesn't like ad sets with too high budgets.
                            </p>

                            <p>
                                This should've given you a good idea on how to use your budget and how to play around with it.
                            </p>

                            <p>
                                Real life example of a workflow
                            </p>

                            <p>Because we don't have to at the moment of recording to turn off any ad set. We are here sorting by ROAS and shutting down the ads that are below 1.5, in this case they were quite many as we were testing a new country.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
