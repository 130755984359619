import React from 'react';
import { Link } from 'react-router-dom';
import { selectSubscription } from 'utils/stripe';

export default ({
    children, productID, brandSrc, onClick, invite, access, progress, currentSection, currentVideo,
}) => (
    <>
        {
            (invite || (!access && productID != 3351)) && (
                <a onClick={() => onClick(true)} className="cursor-pointer">{children}</a>
            )
        }
        {
            !access && (productID == 3351) && (
                <a onClick={() => selectSubscription()} className="cursor-pointer">{children}</a>
            )
        }
        {

            !invite && access && !progress && (<Link to={{ pathname: `/course/${productID}`, state: { productID, brandSrc } }}>{children}</Link>)
        }
        {
            !invite && access && !!progress && (<Link to={{ pathname: `/course/${productID}/phase/${currentSection}/lesson/${currentVideo}`, state: { productID, brandSrc } }}>{children}</Link>)
        }
    </>
);
