import React, { useContext, useState } from 'react';
import { UserDataContext } from '../../../providers/UserDataProvider';
import countries from '../../../constants/countries.json';
import './index.css';

const onlineBusinessOptions = [
    {
        label: 'Yes',
        value: 'yes',
    },
    {
        label: 'No',
        value: 'no',
    },
];

const agencyLocationOptions = countries?.countries?.map(country => ({
    code: country?.code,
    label: country?.name,
    value: country?.name,
    id: country?.id,
}));

const OnboardingAgencyInfo = ({ next }) => {
    const [onlineBusiness, setOnlineBusiness] = useState(onlineBusinessOptions?.[0]?.value);
    const { userProfile, setUserProfile } = useContext(UserDataContext);
    const [agencyInfo, setAgencyInfo] = useState({});
    const [userLocation, setUserLocation] = useState(agencyLocationOptions?.find(({ code }) => code === 'GB')?.value || agencyLocationOptions?.[0]?.value);

    const handleBusinessChange = (e) => {
        setOnlineBusiness(e.target.value);
    };

    const handleChange = (e) => {
        const { value } = e.target;
        setAgencyInfo({
            ...agencyInfo,
            [e.target.id]: value,
        });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        setUserProfile({
            ...userProfile,
            data: {
                ...userProfile?.data, ...agencyInfo, userLocation, onlineBusiness,
            },
        });
        next();
    };

    return (
        <div className="w-full flex flex-col">
            <form onSubmit={onSubmit}>
                <div className="mb-6 w-full">
                    <div htmlFor="name" className="block mb-2 text-sm font-medium text-white w-full ">
                        Have you started an online business before (or currently have one)?
                        <div className="bg-[#393939] pr-2.5 rounded-full overflow-hidden mt-2">
                            <select value={onlineBusiness} onChange={handleBusinessChange} className="bg-[#393939] text-white text-sm  focus:outline-none focus:border-blue-500 block w-full !p-2.5">
                                {onlineBusinessOptions.map((option, id) => (
                                    <option value={option?.value} key={id}>{option?.label}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="mb-6 w-full">
                    <label htmlFor="monthlyIncome" className="block mb-2 text-sm font-medium text-white w-full ">
                        How much are you currently making a month? (In US dollars)
                        <input type="number" id="monthlyIncome" onChange={handleChange} className="rounded-full mt-2 bg-[#393939] text-white text-sm focus:ring-blue-500 focus:outline-none block w-full p-2.5 " placeholder="10" required />
                    </label>
                </div>
                <div className="mb-6 w-full">
                    <div htmlFor="name" className="block mb-2 text-sm font-medium text-white w-full ">
                        Where are you located (country)?
                        <div className="bg-[#393939] pr-2.5 rounded-full overflow-hidden mt-2">
                            <select value={userLocation} id="userLocation" onChange={e => setUserLocation(e?.target?.value)} className="bg-[#393939] text-white text-sm  focus:outline-none focus:border-blue-500 block w-full !p-2.5">
                                {agencyLocationOptions.map(option => (
                                    <option value={option?.value} key={option?.id}>{option?.label}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>

                <button type="submit" className="border-2 rounded-full text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium w-full px-5 py-2.5 text-center ">Next Step</button>
            </form>
        </div>
    );
};

export default OnboardingAgencyInfo;
