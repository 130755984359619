import React, {
    useCallback, useContext, useEffect, useState,
} from 'react';
import { Link } from 'react-router-dom';
import { useConfig } from '@genflow/core';
import { useLocation, useParams } from 'react-router';
import useCourse from '@genflow/web-courses/hooks/useCourse';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import useCompleteLesson from '@genflow/web-courses/hooks/useCompleteLesson';
import {
    get,
    has,
    parseInt,
    findIndex,
} from 'lodash';
import { UserDataContext } from 'providers/UserDataProvider';
import PhaseProduct from '../../components/PhaseProduct';
import CustomLoader from '../../components/CustomLoader';
import { trackPageView } from '../../utils/useAnalytics';
import { OnboardingContext } from '../../providers/OnboardingProvider';

function filterSubsections(sections) {
    if (!sections) return [];

    const filteredSubsections = [];

    sections.forEach((item, i) => {
        if (item.parent_id) {
            const parentIndex = findIndex(sections, i2 => i2.id == item.parent_id);

            if (has(filteredSubsections, `[${parentIndex}].subSections`)) {
                filteredSubsections[parentIndex].subSections.push(item);
            } else {
                filteredSubsections[parentIndex] = { ...filteredSubsections[parentIndex], subSections: [item] };
            }
        } else {
            filteredSubsections[i] = { ...item };
        }
    });

    return filteredSubsections;
}

export default () => {
    const { id: productID } = useParams();

    const { completeLesson, data: completedLessons } = useCompleteLesson(productID);

    const [progressPercentage, setProgressPercentage] = useState('60');
    const { vendorUuid } = useConfig();
    const { data: course, loading, authoriseError } = useCourse(
        vendorUuid,
        productID,
    );

    let brandSrc = loading ? false : course.meta_data && JSON.parse(course.meta_data)?.productLogo;
    let description = loading ? '' : course.description;

    const sections = filterSubsections(get(course, 'sections', null));

    const nonDownloadable = ['Transcript', 'Video', 'Audio'];
    const transcripts = ['Transcript', 'Audio'];

    const getVideos = (listAssets) => {
        const videoAssets = assets => assets.filter(item => item.type === 'Video');

        const downloadableAssets = assets => assets.filter(item => !nonDownloadable?.includes(item?.type));


        const metadataAsset = v => (typeof v === 'string' ? JSON.parse(v) : null);

        const getTranscript = assets => assets.filter(item => transcripts?.includes(item?.type));

        return listAssets.reduce((result, lesson) => {
            const metadata = metadataAsset(videoAssets(lesson.assets)[0]?.meta_data);

            const availableVideo = metadata?.isLive;

            if (availableVideo) {
                result.push({
                    id: lesson.id,
                    name: lesson.name,
                    metadata,
                    url: videoAssets(lesson.assets)[0]?.embedUrl,
                    thumbnail: videoAssets(lesson.assets)[0]?.thumbnail,
                    sproutId: videoAssets(lesson.assets)[0]?.sproutId,
                    description: videoAssets(lesson.assets)[0]?.description || '',
                    downloads: downloadableAssets(lesson.assets),
                    transcript: getTranscript(lesson.assets) || null,
                    complete: !!completedLessons.find(({ id: cId }) => parseInt(cId) === parseInt(lesson.id)),
                });
            }

            return result;
        }, []);
    };

    const isSectionAvailable = ({ id, name, lessons: l }) => {
        const videos = getVideos(l);

        const availableSection = !!videos.filter(item => item.metadata?.isLive).length;

        if (availableSection) {
            return {
                id,
                name,
                videos,
            };
        }
        return false;
    };

    const videoSections = sections.reduce((arraySections, section) => {
        if (section.subSections?.length) {
            const s = section.subSections.reduce((result, item) => {
                if (isSectionAvailable(item)) {
                    result.push(isSectionAvailable(item));
                }
                return result;
            }, []);

            arraySections.push({
                id: section.id,
                name: section.name,
                subSections: s,
                videos: [],
            });
        } else {
            const s = isSectionAvailable(section);

            if (s) {
                arraySections.push(s);
            }
        }

        return arraySections;
    }, []);

    const flatVideosList = videoSections.reduce((acc, section) => {
        if (section.subSections) {
            section.subSections.map((item) => {
                acc = [...acc, ...item.videos];
            });
        } else {
            acc = [...acc, ...section.videos];
        }
        return acc;
    }, []);

    useEffect(() => {
        let counter = 0;
        for (let i = 0; i < flatVideosList.length; i++) {
            if (flatVideosList[i].complete === true) counter++;
        }
        setProgressPercentage(Math.round((counter / flatVideosList.length) * 100));
    }, [flatVideosList]);

    const location = useLocation();
    const analytics = useCallback(() => {
        trackPageView(location.pathname, 'Program');
    }, [location.pathname]);

    useEffect(() => {
        analytics();
    }, [analytics]);

    // check if onboarding not completed
    const { userProfile } = useContext(UserDataContext);
    const { setActivePage, setActiveInfoType } = useContext(OnboardingContext);
    useEffect(() => {
        if (!userProfile?.data?.tourCompleted?.advanceApply && !!userProfile?.data?.tourCompleted?.firstVideo && userProfile?.data?.onboardingCompleted) {
            setActiveInfoType('programs');
            setActivePage(1);
        }
    }, [userProfile]);

    const currentSection = userProfile?.data?.progress?.[productID]?.lesson;
    const currentVideo = userProfile?.data?.progress?.[productID]?.video;

    return (
        <div className="pb-16">
            <CustomLoader loading={loading}>

                <Link to={{ pathname: `/course/${productID}/phase/${currentSection}/lesson/${currentVideo}`, state: { productID, brandSrc } }} onClick={e => (!currentSection || !currentVideo) && e.preventDefault()}>
                    <div className="w-full heroBg py-4 sm:py-12">
                        <div className="w-10/12 mx-auto h-full flex flex-col justify-center">
                            <div className="mt-8 sm:mt-12 w-8/12 sm:w-3/12">
                                <img
                                    src={brandSrc}
                                    alt="hero"

                                />
                            </div>
                            <div className="body2 hidden sm:block w-full sm:w-5/12 mt-6 text-white">
                                {description}
                            </div>
                            <div className="body5 block sm:hidden  w-full sm:w-5/12 mt-3 text-white">
                                {description}
                            </div>
                            <div className="flex flex-col sm:flex-row items-start sm:items-center mt-4 sm:mt-9 mb-4">
                                <div className="progressBg w-full sm:w-3/12 h-2 rounded-lg">
                                    <div className="progressBar h-full rounded-l-lg" style={{ width: `${progressPercentage}%` }} />
                                </div>
                                <div className="ml-0 sm:ml-3 mt-3 sm:mt-0 text-white hidden sm:block">
                                    {' '}
                                    {progressPercentage}
                                    % completed
                                    {' '}
                                </div>
                                <div className="body5 ml-0 sm:ml-3 mt-3 sm:mt-0 text-white block sm:hidden">
                                    {' '}
                                    {progressPercentage}
                                    % completed
                                    {' '}
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>

                <div className="phaseMargin w-10/12 sm:w-auto">
                    <div className="relative">
                        <h2 className="text-white mt-16 mb-8 hidden sm:block">Phases</h2>
                        <h5 className="text-white mt-10 mb-2 block sm:hidden">Phases</h5>
                        <div className=" hidden sm:flex flex-row items-center absolute top-0 right-36">
                            <ChevronLeftIcon
                                className="h-5 w-5 mr-2 grey500 cursor-pointer"
                                onClick={() => {
                                    document.getElementById('scrollmenu').scrollLeft -= 800;
                                }}

                            />
                            <ChevronRightIcon
                                className="h-5 w-5 mr-2 grey500 cursor-pointer"
                                onClick={() => {
                                    document.getElementById('scrollmenu').scrollLeft += 800;
                                }}

                            />
                        </div>
                    </div>
                    <div className="relative">
                        {' '}
                        <div className="flex flex-col sm:flex-row scrollmenu layout-cell layout-scrollbar" id="scrollmenu">
                            {!loading && videoSections.map((item, i) => (
                                <Link key={i} to={{ pathname: `/course/${productID}/phase/${item?.id}`, state: { item, brandSrc, productID } }} className="mr-0 sm:mr-6">
                                    <PhaseProduct
                                        key={i}
                                        phase={i}
                                        title={item.name}
                                        videos={item.videos.length > 0 ? item.videos.length : item.subSections.length}
                                        vidOrSec={item.videos.length > 0 ? 'videos' : 'sections'}
                                    />
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>

            </CustomLoader>
        </div>


    );
};
