import React, { useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player';
import ButtonMUI from '@material-ui/core/Button';
import { Slider } from '@material-ui/core';
import pauseAudio from '../../../../assets/img/icons/pause.svg';
import volumeAudio from '../../../../assets/img/icons/volume.svg';
import { PauseIcon } from '@heroicons/react/outline'
import { VolumeUpIcon, VolumeOffIcon } from '@heroicons/react/solid'
import { trackEvent } from '../../../../utils/useAnalytics';
import play from '../../../../assets/img/icons/play.svg';

export default ({ audioURL, showPlayer, name = '' }) => {
    const mp3PlayerRef = useRef(null);

    const [mp3Ready, setMp3Ready] = useState(false);
    const [playing, setPlaying] = useState(false);
    const [audioPlayed, setAudioPlayed] = useState(0);
    const [volumeOn, setVolumeOn] = useState(true);
    const [volumeOff, setVolumeOff] = useState(false);
    const [audioVolume, setAudioVolume] = useState(100);
    const [prevVolume, setPrevVolume] = useState(null);
    const [audioDuration, setAudioDuration] = useState(0);
    const [audioPlayerState, setAudioPlayerState] = useState({
        loaded: 0,
        loadedSeconds: 0,
        played: 0,
        playedSeconds: 0,
    });

    const handlePlayPause = () => {
        setPlaying(!playing);
    };

    const handleProgress = (state) => {
        setAudioPlayerState(state);
        setAudioPlayed(state.played * 100);
    };

    const handleChangeAudio = (event, newValue) => {
        setAudioPlayed(newValue);

        mp3PlayerRef.current.seekTo(newValue / 100);
    };

    const handleDuration = (duration) => {
        setAudioDuration(duration);
    };

    const handleVolumeChange = (event, newValue) => {
        setAudioVolume(newValue);
    };

    const secondsToHms = (seconds) => {
        const d = Number(seconds);

        const h = Math.floor(d / 3600);
        const m = Math.floor((d % 3600) / 60);
        const s = Math.floor((d % 3600) % 60);

        return `${`0${h}`.slice(-2)}:${`0${m}`.slice(-2)}:${`0${s}`.slice(-2)}`;
    };

    // track when you start playing a new audio
    useEffect(() => {
        if (audioURL?.length && playing && audioPlayed < 1) {
            trackEvent({
                action: 'Module_Audio_Triggered',
                category: 'Session_Name',
                label: name,
            });
        }
    }, [audioURL, playing]);

    return (
        <>
            <div 
                className="mp3-player w-full grey800Bg p-8 mt-8 sm:mt-12 text-white rounded-lg" 
                style={{ display: showPlayer ? 'block' : 'none' }}
            >
                {mp3Ready ? (
                    <>
                        <div>
                            <h3 className="hidden sm:block">Audio</h3>
                            <h5 className="block sm:hidden">Audio</h5>
                            <div className="mp3-progress">
                                <div className="audioProgressBar">
                                    <Slider
                                        value={audioPlayed}
                                        style={{ color: 'white' }}
                                        onChange={handleChangeAudio}
                                        aria-labelledby="continuous-slider"
                                    />
                                </div>

                                <div className="audioProgressLabel ml-8 sm:ml-0">
                                    {secondsToHms(audioPlayerState.playedSeconds)}
                                    {' '}
                                    /
                                    {' '}
                                    {secondsToHms(audioDuration)}
                                </div>
                            </div>

                            <div className="mp3-buttons">
                                <ButtonMUI className="mp3-play-pause" onClick={handlePlayPause} color="white" >
                                    {playing
                                       ? <PauseIcon className="text-white h-10 w-10" />
                                       : <img src={play} alt="" />
                                    }
                                </ButtonMUI>

                                <div className="mp3-volume">
                                    <div className="mp3-volume-icon">
                                        {volumeOn &&
                                            <VolumeUpIcon 
                                                className="h-7 w-7 text-white" 
                                                onClick={() =>{
                                                    setPrevVolume(audioVolume)
                                                    setAudioVolume(0)
                                                    setVolumeOff(true) 
                                                    setVolumeOn(false) 
                                                }} 
                                            />
                                        }
                                        {volumeOff &&
                                            <VolumeOffIcon 
                                                className="h-7 w-7 text-white" 
                                                 onClick={() =>{
                                                    setAudioVolume(prevVolume)
                                                    setVolumeOff(false) 
                                                    setVolumeOn(true) 
                                                }} 
                                            />
                                        }
                                    </div>

                                    <div className="mp3-volume-slider">
                                        <Slider
                                            value={audioVolume}
                                            style={{ color: 'white' }}
                                            onChange={handleVolumeChange}
                                            aria-labelledby="continuous-slider"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <h2>Loading ...</h2>
                )}
            </div>

            <ReactPlayer
                ref={mp3PlayerRef}
                url={audioURL}
                volume={(audioVolume / 100) || 1}
                style={{ display: 'none' }}
                className="react-player"
                onReady={() => setMp3Ready(true)}
                onStart={() => console.log('onStart')}
                playing={playing}
                onProgress={handleProgress}
                onDuration={handleDuration}
            />
        </>
    );
};
