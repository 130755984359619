/* eslint-disable no-restricted-globals */
/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import {
    Card,
    CardBody,
    Col,
    Container,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
} from 'reactstrap';
import accounting from 'accounting';

import FormControl from '@material-ui/core/FormControl';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
// import { parseInt } from 'lodash';
import Footer from '../../../components/Footer';
import '../../../assets/scss/simple-tools.scss';
import GYAFooterScene from '../../../assets/img/footer-scene-gya.png';
import { UserDataContext } from '../../../providers/UserDataProvider';
import CurrencySelector from '../../../components/CurrencySelector';

const BootstrapInput = withStyles(theme => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '8px 10px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

const useStyles = makeStyles(theme => ({
    formControl: {
        padding: 4,
        minWidth: 120,
    },

    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const industries = [
    {
        name: 'Sports and Recreation',
        rate: 1.75,
    },
    {
        name: 'Arts and Crafts',
        rate: 3.79,
    },
    {
        name: 'Baby & Child',
        rate: 0.99,
    },
    {
        name: 'Cars & Motorcycling',
        rate: 1.55,
    },
    {
        name: 'Electrical & Commercial Equipment',
        rate: 2.23,
    },
    {
        name: 'Fashion Clothing & Accessories',
        rate: 2.44,
    },
    {
        name: 'Food & Drink',
        rate: 2.37,
    },
    {
        name: 'Health & Beauty',
        rate: 3.08,
    },
    {
        name: 'Home Accessories & Giftware',
        rate: 2.16,
    },
    {
        name: 'Kitchen & Home Appliances',
        rate: 2.48,
    },
    {
        name: 'Pet Care',
        rate: 3.28,
    },
    {
        name: 'Toys & Games',
        rate: 2.43,
    },
    {
        name: 'Jewelry & Accessories',
        rate: 2.14,
    },
    {
        name: 'Home & Garden',
        rate: 1.81,
    },
    {
        name: 'Clothing & Fashion',
        rate: 2.44,
    },
    {
        name: 'Electronics',
        rate: 1.27,
    },
    {
        name: 'Furniture',
        rate: 0.68,
    },
];

const tiers = [
    {
        name: 'Low Tier',
        rate: 0.25,
    },
    {
        name: 'Mid Tier',
        rate: 0.5,
    },
    {
        name: 'High Tier',
        rate: 1,
    },
];

export default () => {
    const classes = useStyles();

    const { userCurrency } = useContext(UserDataContext);

    const [state, setState] = useState({
        ecommerce: {
            // user inputs
            websiteVisitors: '',
            averageOrder: '',
            industry: { name: '', rate: 0 },
            // results
            estimatedRevenue: '-',
        },
        infoProduct: {
            // user inputs
            websiteVisitors: '',
            averageOrder: '',
            tier: { name: '', rate: 0 },
            // results
            estimatedRevenue: '-',
        },
    });

    const handleChangeEcommerce = (value, name) => {
        const s = state.ecommerce;

        const validValue = !isNaN(value * 1);

        s[name] = value || 0;
        if (validValue) {
            s[name] = value * 1;

            s.estimatedRevenue = s.websiteVisitors * s.averageOrder * s.industry.rate / 100;
        }

        console.log(s);

        setState({ ...state, ecommerce: { ...s } });
    };

    const handleChangeInfoProduct = (value, name) => {
        const s = state.infoProduct;

        const validValue = !isNaN(value * 1);

        s[name] = value || 0;
        if (validValue) {
            s[name] = value * 1;

            s.estimatedRevenue = s.websiteVisitors * s.averageOrder * s.tier.rate / 100;
        }

        console.log(s);

        setState({ ...state, infoProduct: { ...s } });
    };

    const handleChangeIndustry = (event) => {
        const { value } = event.target;
        const industry = industries.filter(item => item.name === value)[0];
        const s = state.ecommerce;
        s.industry = industry;
        s.estimatedRevenue = s.websiteVisitors * s.averageOrder * s.industry.rate / 100;
        setState({ ...state, ecommerce: { ...s } });
    };

    const handleChangeTier = (event) => {
        const { value } = event.target;
        const tier = tiers.filter(item => item.name === value)[0];
        const s = state.infoProduct;
        s.tier = tier;
        s.estimatedRevenue = s.websiteVisitors * s.averageOrder * s.tier.rate / 100;
        setState({ ...state, infoProduct: { ...s } });
    };

    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, []);

    return (
        <>
            <main className="simple-tools-page text-white">
                <section className="section-profile-cover section-shaped my-10 sm:my-12">
                    <Container>
                        <Row>
                            <Col lg={12} className="text-center">
                                <h1 className="hidden sm:block">
                                    Client Revenue Calculator
                                </h1>
                                <h3 style={{fontSize: '26px'}} className="block sm:hidden">
                                    Client Revenue Calculator
                                </h3>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="section section-lg pt-lg-3 pt-8">
                    <Container>
                        <Row className="mb-4 shadow shadow--hover">
                            <Col lg={12}>
                                <Row className="flex flex-col sm:flex-row w-11/12 sm:w-8/12 mx-auto shadow-md card">
                                    <Col lg={7} className="w-full sm:w-7/12 p-4 sm:p-8">
                                        <div className="left-input-card ">
                                            <CardBody>
                                                <CurrencySelector />
                                                <div className="mt-8 block sm:hidden"></div>
                                                <strong>E-Commerce Client Revenue Calculator</strong>
                                                <Row className="tool-form-row flex flex-col sm:flex-row items-start sm:items-end justify-between py-4 body2">
                                                    <Col sm={6} className="tool-form-label">
                                                        Website visitors
                                                    </Col>
                                                    <Col sm={6} className="tool-form-input w-full sm:w-auto">
                                                        <InputGroup>
                                                            <Input
                                                                placeholder="50"
                                                                type="number"
                                                                value={state.ecommerce.websiteVisitors}
                                                                className="bg-transparent p-2 w-full sm:w-28 pb-0 inputBox"
                                                                style={{borderBottomColor: "#434343", marginBottom: "0px"}}
                                                                onChange={e => handleChangeEcommerce(e.target.value, 'websiteVisitors')
                                                                }
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                                <Row className="tool-form-row flex flex-col sm:flex-row items-start sm:items-end justify-between py-4 body2">
                                                    <Col sm={6} className="tool-form-label">
                                                        Average Order Value
                                                    </Col>
                                                    <Col sm={6} className="tool-form-input w-full sm:w-auto">
                                                        <InputGroup className="flex flex-row items-end bg-transparent inputBox" style={{borderBottomColor: "#434343", marginBottom: "0px"}}>
                                                            <InputGroupAddon addonType="prepend" className="bg-transparent inputBox" style={{ marginBottom: "0px"}}>
                                                                <InputGroupText>{userCurrency}</InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder="100"
                                                                type="number"
                                                                value={state.ecommerce.averageOrder}
                                                                className="bg-transparent p-2 pb-0 w-full sm:w-24 inputBox"
                                                                style={{marginBottom: "0px"}}
                                                                onChange={e => handleChangeEcommerce(
                                                                    e.target.value,
                                                                    'averageOrder',
                                                                )
                                                                }
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                                <Row className="tool-form-row flex flex-col sm:flex-row items-start sm:items-end justify-between py-4 body2">
                                                    <Col sm={4} className="tool-form-label">
                                                        Industry
                                                    </Col>
                                                    <Col sm={8} className="tool-form-input d-flex justify-content-end w-full sm:w-auto">
                                                        <FormControl
                                                            variant="outlined"
                                                            className={classes.formControl}
                                                        >
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                className='bg-transparent p-2 pl-0 sm:pl-2 w-full sm:w-40 pb-0 inputBox'
                                                                style={{borderBottomColor: "#434343", marginBottom: "0px"}}
                                                                value={state.ecommerce.industry.name}
                                                                onChange={handleChangeIndustry}
                                                                input={<BootstrapInput />}
                                                                displayEmpty
                                                            >
                                                                <MenuItem value="" disabled className="body3 bg-transparent menuSet">
                                                                    Select Industry
                                                                </MenuItem>
                                                                {industries.map(item => (
                                                                    <MenuItem value={item.name}>
                                                                        {item.name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                </Row>
                                                <Row className="tool-form-row mb-4">
                                                    <Col sm={6} className="tool-form-label">
                                                        Conversion Rate
                                                    </Col>
                                                    <Col sm={6} className="tool-form-input text-left sm:text-right">
                                                        {state.ecommerce.industry.rate}
                                                        {' '}
                                                        %
                                                    </Col>
                                                </Row>
                                                <strong>Infoproduct Client Revenue Calculator</strong>
                                                <Row className="tool-form-row flex flex-col sm:flex-row items-start sm:items-end justify-between py-4 body2">
                                                    <Col sm={6} className="tool-form-label">
                                                        Website visitors
                                                    </Col>
                                                    <Col sm={6} className="tool-form-input w-full sm:w-auto">
                                                        <InputGroup>
                                                            <Input
                                                                placeholder="50"
                                                                type="number"
                                                                value={state.infoProduct.websiteVisitors}
                                                                className="bg-transparent p-2 w-full sm:w-28 pb-0 inputBox"
                                                                style={{borderBottomColor: "#434343", marginBottom: "0px"}}
                                                                onChange={e => handleChangeInfoProduct(e.target.value, 'websiteVisitors')
                                                                }
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                                <Row className="tool-form-row flex flex-col sm:flex-row items-start sm:items-end justify-between py-4 body2">
                                                    <Col sm={6} className="tool-form-label">
                                                        Average Order Value
                                                    </Col>
                                                    <Col sm={6} className="tool-form-input w-full sm:w-auto">
                                                        <InputGroup className="flex flex-row items-end bg-transparent inputBox" style={{borderBottomColor: "#434343", marginBottom: "0px"}}>
                                                            <InputGroupAddon addonType="prepend" className="bg-transparent inputBox" style={{ marginBottom: "0px"}}>
                                                                <InputGroupText>{userCurrency}</InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder="100"
                                                                type="number"
                                                                value={state.infoProduct.averageOrder}
                                                                className="bg-transparent p-2 pb-0 w-full sm:w-24 inputBox"
                                                                style={{marginBottom: "0px"}}
                                                                onChange={e => handleChangeInfoProduct(
                                                                    e.target.value,
                                                                    'averageOrder',
                                                                )
                                                                }
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                                <Row className="tool-form-row flex flex-col sm:flex-row items-start sm:items-end justify-between py-4 body2">
                                                    <Col sm={4} className="tool-form-label">
                                                        Tier
                                                    </Col>
                                                    <Col sm={8} className="tool-form-input d-flex justify-content-end w-full sm:w-auto">
                                                        <FormControl
                                                            variant="outlined"
                                                            className={classes.formControl}
                                                        >
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                className='bg-transparent p-2 pl-0 sm:pl-2 w-full sm:w-40 pb-0 inputBox'
                                                                style={{borderBottomColor: "#434343", marginBottom: "0px"}}
                                                                value={state.infoProduct.tier.name}
                                                                onChange={handleChangeTier}
                                                                input={<BootstrapInput />}
                                                                displayEmpty
                                                            >
                                                                <MenuItem value="" disabled className="body3 bg-transparent menuSet">
                                                                    Select Tier
                                                                </MenuItem>
                                                                {tiers.map(item => (
                                                                    <MenuItem value={item.name}>
                                                                        {item.name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                </Row>
                                                <Row className="tool-form-row">
                                                    <Col sm={6} className="tool-form-label">
                                                        Conversion Rate
                                                    </Col>
                                                    <Col sm={6} className="tool-form-input text-left sm:text-right">
                                                        {state.infoProduct.tier.rate}
                                                        {' '}
                                                        %
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </div>
                                    </Col>
                                    <Col lg={5} className="right-input-col p-0 w-full sm:w-5/12">
                                        <Card className="right-input-card">
                                            <CardBody className="p-2">

                                                <div className="result mb-6">
                                                    <h4 className="text-white text-center">
                                                        E-commerce - Estimated Revenue
                                                        {' '}
                                                    </h4>
                                                    <h4 className="text-white">
                                                        {!isFinite(state.ecommerce.estimatedRevenue)
                                                            ? '-'
                                                            : `${accounting
                                                                .formatMoney(
                                                                    state.ecommerce.estimatedRevenue,
                                                                    userCurrency,
                                                                )
                                                                .replace('.00', '')}`}
                                                    </h4>
                                                </div>
                                                <div className="result mt-6">
                                                    <h4 className="text-white text-center">
                                                        Infoproduct - Estimated Revenue
                                                        {' '}
                                                    </h4>
                                                    <h4 className="text-white">
                                                        {!isFinite(state.infoProduct.estimatedRevenue)
                                                            ? '-'
                                                            : `${accounting
                                                                .formatMoney(
                                                                    state.infoProduct.estimatedRevenue,
                                                                    userCurrency,
                                                                )
                                                                .replace('.00', '')}`}
                                                    </h4>
                                                </div>

                                               
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </main>
            <Footer />
        </>
    );
};
