import React from 'react';

export default ({ LeftComponent, RightComponent }) => (
    <div style={{
        padding: '1rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    }}
    >
        {LeftComponent}
        {RightComponent}
    </div>
);
