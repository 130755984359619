import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        available: true,
        sectionName: 'Introduction: Structure of an ad account',
    },
    {
        available: true,
        sectionName: 'How many angles?',
    },
    {
        available: true,
        sectionName: 'How many ad sets?',
    },
    {
        available: true,
        sectionName: 'How many campaigns?',
    },
    {
        available: true,
        sectionName: 'How it all comes together',
    },
];

const title = 'How many of things: Campaign Structure';

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]}  className="plug-block">  
                        <h2 className="mb-4">{title}</h2>
                            <div className="plug-block-subheading">
                                Introduction: Structure of an ad account
                            </div>

                            <p>
                                An ad account as you saw on the intro has 3 main things on its structure.
                            </p>

                            <p>
                                Campaigns. Ad sets. Ads
                            </p>

                            <p>
                                You have freedom on how you structure your campaign and depending on this structure results will defer greatly. This goes hand in hand with load balancing budgets. Say for instance you have an imaginary budget of $1000 a day to spend on ads. Results will greatly vary depending on how you decide to spend them. Let's say you go with a more "All in" approach and you create one campaign with one ad set and some ads inside of the ad set. Facebook has no upper limit on the budget per ad set. You can decide to put the $1000 dollars in that ad set and spend it all there.
                            </p>

                            <p>On the other hand you can go and create 3 campaigns, each campaign has 5 ad sets and you set them all at an equal 66 dollars per day. This way you also spend the $1000 dollars. You can get more creative and spend $100 dollars a day in some ad sets and $10 in some others. Now you see that the possibilities are endless and depending on the approach you take results will greatly vary. For instance I can guarantee you now that if you spend all the money in one ad set vs in many you'll heavily underperform, and not only that you'll be affected by 'logistics'</p>

                            <p>
                                What I mean by that is that if your ad set is underperforming you have nothing to do. If you had chosen the alternative you will find that some of the ad sets you made are working and some others aren't. In this case you will be able to turn off the ones that are not performing and keep the ones that are performing. That way if things don't go well right away you still have some winners and you will still be making money the next day.
                            </p>

                            <p className="bold">
                                What is an ad?
                            </p>

                            <p>
                                So what is an ad: We've all seen these and it is literally what appears on our feeds whenever we scroll through social media platforms. The ad is what the prospect sees. This are the parts of an ad:
                            </p>

                            <p>
                                There is the ad name, which is used INTERNALLY as a way of identifying it.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/howManyOfThings1.png" alt="" />

                            <p>
                                There is the identity which is the page or the account which the ad is running under.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/howManyOfThings2.png" alt="" />

                            <p>
                                There is an ad set up. In this one you select if you want to use a new ad, or an existing one.
                            </p>

                            <p>If you create a new ad you'll input all the information about this ad, like its creative, copy, links, etc.</p>

                            <p>
                                If you decided to select an existing post, you will be able to use an ad that you've created in the past ( although this is uncommon, the common practice is finding the ad in the ads manager and duplicating it) or you want to run a post that has been posted on the Facebook page as an ad.
                            </p>

                            <p>
                                In most circumstances you will be creating new ads and whenever you want to re use them in other ad sets and campaigns you just duplicate them. We will go over this in another module as it has its nuances on the best way to do it in cases where you want to create new ad sets or campaigns and you want to keep the engagement of the ad (it's likes, comments, reactions, etc).
                            </p>

                            <p>
                                This is found in the module Load Balancing Budgets in the How to Advertise section.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/howManyOfThings3.png" alt="" />
                            <img src="https://img.genflow.com/gya/plugandplays/howManyOfThings4.png" style={{ width: '50%' }} alt="" />

                            <p>
                                There is that checkbox to add an instant experience which is the same as Collection ads. On Phase 6 Advances strategies we dedicated a whole module on how to use them and in which cases we've used them and found success.
                            </p>

                            <p>
                                In the format section you can select Single Image or Video, our most popular type of ad that encompasses 95% of the ads we've made. Carousels are useful in some specific cases where there's usually a variety of products in the store, or when the creative is witty and deserves it. Finally, collections that open up right on facebook with a similar instant experience. These types of ads are usually made for ecommerce stores with multiple products and MUST be connected to a catalogue of products that you have to import from the stores. Let's take a quick look at how these type of ads look.
                            </p>

                            <p>
                                Facebook offers multiple templates on how to create these. Will go more in depth in its dedicated module.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/howManyOfThings5.png" style={{ width: '50%' }} alt="" />

                            <p>
                                Then there is the ad creative, which has the video or image you want to run (multiple images in the case of carousels) The ad creative will have inside of it different ratios depending on the placement, which you can customize to your liking. Be careful with this feature, if you don't leave it as default, engagement won't be shared.
                            </p>

                            <p>
                                It has the primary text which we refer to as copy. The headline. The description. The UR. The button. Which are all self explanatory.
                            </p>

                            <p>
                                The display link is the link that appears above the headline, with it you can make your URL look cleaner and shorter. You can't use a different domain when using this feature.
                            </p>

                            <p>
                                Call extension shows a button at the bottom of your website where people can click and call a phone number. We've never used this feature before, albeit it looks interesting.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/howManyOfThings6.png" style={{ width: '51%' }} alt="" />
                            <img src="https://img.genflow.com/gya/plugandplays/howManyOfThings7.png" style={{ width: '50%' }} alt="" />

                            <p className="bold">
                                What is an ad set?
                            </p>

                            <p>
                                As the name indicates an ad set is a grouping of ads, an ad set has 1 or more ads inside of it. All the ads grouped in the same ad set will share the same audience or targeting.
                            </p>

                            <p>
                                This are the parts of an ad set
                            </p>

                            <p>
                                Ad set name, analogous to the ad name it is the way you identify your ad set, this is an internal metric that only you can see from the ads manager. This ad set of the image is optimizing for conversions, which is what we recommend in 90+% of the cases, we will go over the other options shortly. In the ad set you select the pixel and the conversion event you're optimizing for. (The action you're telling Facebook you don't want people doing on your website. This is usually leads or purchases.
                            </p>

                            <p>
                                You have the dynamic creatives, which we never use and never suggest using. The reason why we don't like them is simple, if you have this option on managing the ads will be a nightmare and it will be more difficult to know which of your ads is performing. It also makes it so you can't really turn off the bad performers or duplicate ONLY the winner ads to another ad set for instance. We recommend you don't use this feature and keep your ads independent, you keep them independent by simply creating them independently as well inside your ad set.
                            </p>

                            <p>
                                Audience definition is an estimate on the size of the audience you selected (you select this below and we will go over it) for ecommerce we usually run with 200k+ audiences but there's no fixed rule. For lead gen the size matters less.
                            </p>

                            <p>
                                Estimated daily results will give you a projection on what Facebook thinks the results will be. Don't pay attention to these, these numbers are off 98% of the time, these projections also work with recent data from your pixel so if your pixel is new or has been inactive for more than 7 days it will most likely tell you you won't get any conversions. Don't get alarmed by this and just ignore it.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/howManyOfThings8.png" alt="" />

                            <p>
                                We have the Budget, refer to the Load Balancing budgets module to get a full view on how important this is. As a rule of thumb think about it this way, you wan't to give Facebook enough money to get you what you want, but not too much because it won't know what to do with it.
                            </p>

                            <p>
                                What I mean by this is that if you want leads for let's say $10 dollars, you need to give Facebook at least $10 dollars otherwise don't expect to get any results. Or if the average CPA of a product is $30 dollars, give Facebook at least $30 dollars to get you a purchase, don't make it difficult on it by asking it to make magic and do more with less money. On the flip side, don't give it $200 dollars for that ad set, it will become careless and spend it on whoever, we will see ways of finding that sweet spot.
                            </p>

                            <p>
                                Never set End dates to your ads, we've tested this many times and it never works better than on going. If you have an end date, just log in that date and turn the campaign or ad set off. Always keep your schedule going.
                            </p>

                            <p>
                                Also don't get fancy and turn off ads during the night or mondays, that is overkill. Simple is best.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/howManyOfThings9.png" alt="" />

                            <p>
                                Will skim through this as this has its own dedicated module, but here on the ad set level you create or choose an audience. An audience has geographical places that you target, be it countries, citys, adresseses, coordinates, neighborhoods or even continents. You also choose demographic data.
                            </p>

                            <p>
                                And that little button, that lets you save audiences for later. We rarely use it as we usually just duplicate the ad set we want to use the same audience, but it might be useful in some cases.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/howManyOfThings10.png" alt="" />

                            <p>
                                Placements. It is where you want your ads to show up, Facebook ads a quick little overview on what the placement is. As a rule of thumb know that either use automatic placements, which means that you appear on every placement (which usually gives you a lower CPM, but more on this on the module "The Two King Metrics And Their Math") or we manually select all the placements in which you are able to read the copy. For info product clients and lead gen clients we always use manual placements with this in mind. For ecomm clients that have good creatives and visual products we might use automatic placements.
                            </p>

                            <div style={{ height: 350 }}>
                                <iframe
                                    id="videoPlayer"
                                    className="sproutvideo-player"
                                    src={'https://videos.sproutvideo.com/embed/119dd8b61611e3c898/c9176dabc57385cd?playerTheme=dark&amp;playerColor=263238'}
                                    frameBorder="0"
                                    allowFullScreen
                                    width="100%"
                                    height="100%"
                                />
                            </div>


                            <p>
                                On the more options part of the placements section you can go deeper and choose which kind of devices you want to show up on or add filters for brand safety. We don't use any of these options. Important notice: Selecting on devices only android to bypass the iOS tracking restrictions, will not improve your ads, we recommend you try it on your own but it didn't work for multiple tests we ran.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/howManyOfThings11.png" alt="" />

                            <p>
                                Finally there is optimisation and delivery, keep the optimisation as default on conversions and for the conversion window (meaning how much time do people have to complete actions in your website and it be tracked) go with the more generous one, 7 days click one day view, as this allows your agency to be attributed as much as possible. There's a case where optimisation for one day click might improve your results but this is rarely the case.
                            </p>

                            <p>We've tested this multiple times and it only makes sense with very low ticket e-commerce products where people take the buying decision on the spot. With that being said, the improvement is marginal and in general you'll loose conversions attributed to the ads.</p>

                            <img src="https://img.genflow.com/gya/plugandplays/howManyOfThings12.png" alt="" />

                            <p className="bold">
                                What is a campaign?
                            </p>

                            <p>
                                A campaign is a grouping of ad sets. They have in common the optimisation objective, which is in most cases conversions. Because most of you and us only use conversions this doesn't mean that you only need one campaign, you can have as many of them. We use them more as an organizational feature, we group ad sets based on the warmth of the audience (refer to "Traffic Warmth" module for more information on it) or to group different products we're offering or even to have stand alone campaigns that we only run for special dates like Valentines day, black friday, christmas, mother's and fathers day, etc.
                            </p>

                            <p>
                                It can also have a budget, you can have your budget on the campaign level as opposed to the ad set level, this is another strategy in and of itself that we dedicated a whole module as well. Refer to the module "Utilising CBOs".
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/howManyOfThings13.png" alt="" />

                            <p>
                                Always select auction as the buying type. Reach and frequency will only get you bad quality traffic. Also remember that all these recommendations are based on our experience and what we've learned throughout our years. You're welcome to test anything you want and share your experience in our community, there are always new things to find and learn.
                            </p>

                            <p>
                                You can select the following objectives for your campaigns.
                            </p>

                            <p>
                                I won't go into detail of any of those as the names are pretty self explanatory and will only mention the ones that we've used or consider worth using. Engagement, although it used to be a popular strategy, using engagement campaigns and then retargeting conversion campaigns to those who engaged (learn how to create this audiences on our module "Custom Audiences, Exclusions and Combinations, What Is Worth It?") this strategy is no longer useful and you'll be most likely just wasting money on the engagement campaigns. Also keep in mind this won't yield any conversions. Same with traffic campaigns they will rarely get conversions because of the quality of the traffic. One thing tho, they will get you dirt cheap CPMs but this is not worth it either way. Tried and tested.
                            </p>

                            <p>
                                CPMs are highly dependent on the type of objective you choose, but in a world where everyone chooses conversions (because literally none other really works) this doesn't matter much.
                            </p>

                            <p>
                                Let's keep going with the objectives
                            </p>

                            <p>
                                Lead generation, which is very very useful for local businesses and lead based businesses in general. We created a whole module on this. Refer to "Facebook Native vs Funnel Lead Gen: Setting Them Up".
                            </p>

                            <p>
                                Messages, for some clients that have messaging funnels. Very good option that integrates with Facebook messenger and Whatsapp. Our average cost per message is $9 with this campaign objective.
                            </p>

                            <p>
                                Finally conversions, they are king.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/howManyOfThings14.png" alt="" />

                            <p>
                                You can also name your campaign, we go through conventions in a later module. Refer to "Perfect Naming Conventions to Accelerate Workflow and Communication". Remember this is for internal management purposes.
                            </p>

                            <p>
                                You have the campaign details and objectives which once published can't be changed and you'd need to create a new campaign in case you want to change them.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/howManyOfThings15.png" alt="" />

                            <p>Also make sure you select a special ad category if you're in any of these niches:</p>

                            <p>
                                In our case it'd only be for the real state niche, but maybe some of you work with some of the others.
                            </p>

                            <p>
                                Funnily enough we've found that you can get away with more things in the copy when you enlist yourself in these categories. (don't select them if you don't belong tho). Basically it limits the kind of targeting you can do. It is never a big deal with real estate.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/howManyOfThings16.png" alt="" />

                            <p>
                                Campaigns also have this A/B test option. We don't recommend using it. It limits the kind of edits you can do to anything and it also puts an end date. Besides there is no need to use it because we test in a very different way, as said in previous modules, all our ad angles are radically different from each other and the ones that work well we keep using and the ones that don't we ditch and replace for new ones as a way to get more winners. No need to split test minutiae like which headline works best and do combinations of everything. Angles should be unique and wouldn't make sense if combined.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/howManyOfThings17.png" alt="" />
                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">
                                How many angles?
                            </div>

                            <p>
                                Depending on the type of ad. For ecommerce we recommend at least 4 different angles.
                            </p>

                            <p>
                                For info we recommend at least 5 different angles
                            </p>

                            <p>
                                For leadgen we recommend at least 3 different angles.
                            </p>

                            <p>Keep in mind all the previous modules where we defined what an angle is exactly and talked about offers and creatives.</p>

                            <p>There is no upper limit but the more you have the more the budget will run thin and the least data you'll have. We recommend never going beyond 8 even if you have very high budgets.</p>

                            <p>Facebook will give most of the budget to the ads that it considers best. This is not always right but most of the time it is.</p>
                        </div>

                        <div ref={refs[2]} className="plug-block">
                            <div className="plug-block-subheading">
                                How many ad sets?
                            </div>

                            <p>
                                The number of ad sets will be strictly dependent on the budget. In this case you'll have as many ad sets as your budget allows. The budget per adset as discussed previously needs to be above the expected CPA (cost per acquisition). Also never go too high. Our budgets are always in the $20-$60 dollar range per ad set.
                            </p>
                        </div>

                        <div ref={refs[3]} className="plug-block">
                            <div className="plug-block-subheading">
                                How many campaigns?
                            </div>

                            <p>
                                We usually run with two or three main campaigns. A testing one (where we have angle and audiences we're testing), a cold one where we have all our winners usually have the same budgets than the testing one but with more ad sets, meaning more of the same. More on this on our scaling modules. And a retargeting one that runs at the lowest spend and usually with different ads.
                            </p>

                            <p>
                                As said previously you can have any amount of campaigns and if you use the CBO strategy you'll have many of them. You can group ad sets in campaigns by products or offers and promotions or even have specific campaigns when you're testing new angles, new price points, new landing pages, funnels, pop up on the funnel, upsells, cross sells, etc.
                            </p>
                        </div>

                        <div ref={refs[4]} className="plug-block">
                            <div className="plug-block-subheading">
                                How it all comes together
                            </div>

                            <p>
                                Combining all the concepts that we've visited we come to a couple different structures that you can make. Keep in mind these are the ones that we use and what works best. We've tested hundreds of variations and approaches and we've come down to this one.
                            </p>

                            <p>
                                For Ecommerce:
                            </p>

                            <p>
                                Campaign 1, Retargeting → 2 Ad Sets in it → Each ad set around 4 different angles. (usually they differ from cold and have some sort of discount or show different features of the product)
                            </p>

                            <p>
                                Campaign 2, Testing (or Sandbox) → Any number of ad sets that make sense with what we want to test, we would have around 5 ad sets running at the same time when running tests → 3-6 angles we're testing or that have been tested but we're testing another variable, like the landing page, the price, etc.
                            </p>

                            <p>
                                Campaign 3, Cold → All the ad sets with the audiences that have proven to be winners, we go in depth on this in the two scaling modules we've made on the Advanced Strategies section. → All the angles inside these ads have been proven to work and we have the best of the best in there.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
