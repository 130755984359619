import React, {
    useContext, useEffect, useMemo, useState,
} from 'react';
import {
    Button,
    Row, // UncontrolledCollapse,
} from 'reactstrap';
import {
    Typography, Grid, Switch, withStyles,
} from '@material-ui/core';

import { Link, useLocation, useParams } from 'react-router-dom';
import { get, sortBy } from 'lodash';
import upsertUserProfileData from 'api/upsertUserProfileData';
import { useMutation } from '@apollo/react-hooks';
import './style.scss';
import '../../assets/css/sproutvideo.css';
import {
    ChevronLeftIcon, CheckIcon, ExternalLinkIcon, CheckCircleIcon as EmptyCheck,
} from '@heroicons/react/outline';

import { Disclosure } from '@headlessui/react';
import { trackEvent } from '../../utils/useAnalytics';
import Mp3Player from './components/mp3Player';
import CollapsibleSection from './components/CollapsibleSection';
import CustomLoader from '../CustomLoader';
import useStickyState from '../../utils/useStickyState';
import SubSections from './components/SubSections';
import { UserDataContext } from '../../providers/UserDataProvider';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}


const AntSwitch = withStyles(theme => ({
    root: {
        width: 44,
        height: 24,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 2,
        color: 'white',
        '&$checked': {
            transform: 'translateX(20px)',
            color: 'white',
            '& + $track': {
                opacity: 1,
                backgroundColor: 'black',
                borderColor: 'white',
            },
        },
    },
    thumb: {
        width: 20,
        height: 20,
        boxShadow: 'none',
    },
    track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 20,
        opacity: 1,
        backgroundColor: 'black',
    },
    checked: {},
}))(Switch);

const whichVideoPlay = (videos) => {
    let video = null;

    videos.map((v) => {
        if (!v.complete && !video) {
            video = v;
        }
    });

    return video || videos[0];
};


export default ({
    name = 'Video Page',
    sections = [],
    loading = false,
    showSectionIndex = true,
    enableCompleteVideo = true,
    onToggleComplete,
    nextButtonLabel = 'Next Video',
}) => {
    const { productID, phaseId, lessonID } = useParams();
    const item = useMemo(() => sections?.find(({ id }) => id === phaseId), [phaseId, sections, phaseId]) || sections?.[0];

    const brandSrc = '';
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [transcriptEnabled, setTranscriptEnabled] = useState(false);
    const [transcriptAssets, setTranscriptAssets] = useState({ audio: null, txt: null });

    const [transcriptTxt, setTranscriptTxt] = useState('');
    const [visibleSection, setVisibleSection] = useState(true);

    const [mp3Player, setMp3Player] = useState(false);
    const [autoPlay, setAutoPlay] = useStickyState(true, 'autoPlay');

    const [phases, setPhases] = useState(true);
    const [downloads, setDownload] = useState(false);
    const [pandp, setPandp] = useState(false);

    const [phaseBg, setPhaseBg] = useState('#434343');
    const [downloadBg, setDownloadBg] = useState('#1C1C1C');
    const [pandpBg, setPandpBg] = useState('#1C1C1C');
    const [audioBg, setAudioBg] = useState('#1C1C1C');
    const { userProfile, setUserProfile } = useContext(UserDataContext);
    const [upsertProfileData] = useMutation(upsertUserProfileData, {});

    // const [playerLoaded, setPlayerLoaded] = useState(false);
    const flatVideosList = sections.reduce((acc, section) => {
        if (section.subSections) {
            section.subSections.map((sectionItem) => {
                acc = [...acc, ...sectionItem.videos];
            });
        } else {
            acc = [...acc, ...section.videos];
        }
        return acc;
    }, []);


    const selectedVideoIndex = flatVideosList.findIndex(
        ({ id: videoId }) => videoId === selectedVideo?.id,
    );

    useEffect(() => {
        // save current phase & video on product key to profile for tracking progress
        if (selectedVideo?.id) {
            const data = { ...userProfile?.data, progress: { ...userProfile?.data?.progress, [productID]: { lesson: item?.id, video: selectedVideo?.id } } };
            setUserProfile({
                ...userProfile,
                data,
            });
            upsertProfileData({
                variables: {
                    data: JSON.stringify(data),
                },
            });
        }
    }, [selectedVideo, item, productID]);

    const hasNextVideo = flatVideosList.length - 1 > selectedVideoIndex;

    const whichVideoPlayFromPhase = (videos) => {
        let video = null;


        videos.map((v) => {
            if (item?.videos?.[0] && item?.videos?.[0]?.id === v.id) {
                video = v;
            } else if (item?.subSections && item?.subSections?.[2]?.videos?.[3]?.id === v.id) {
                video = v;
            }
        });

        return video || videos[0];
    };

    useEffect(() => {
        if (!loading && !selectedVideo && !!item) {
            if (lessonID) {
                setSelectedVideo(flatVideosList.find(section => section.id === lessonID));
                return;
            }
            setSelectedVideo(whichVideoPlayFromPhase(flatVideosList));
        } else if (!loading && !selectedVideo) {
            setSelectedVideo(whichVideoPlay(flatVideosList));
        }
    }, [loading, sections, item, phaseId]);


    useEffect(() => {
        const transcript = { audio: null, text: null };

        if (selectedVideo) {
            selectedVideo.transcript.map((transcriptItem) => {
                if (transcriptItem.type === 'Audio') {
                    transcript.audio = transcriptItem;
                }

                if (transcriptItem.type === 'Transcript') {
                    extractTranscriptText(transcriptItem.url);
                    transcript.txt = transcriptItem;
                }
            });

            setTranscriptAssets(transcript);
        }
    }, [selectedVideo]);

    const goNextVideo = () => {
        if (hasNextVideo) {
            setSelectedVideo(flatVideosList[selectedVideoIndex + 1]);
        }
    };

    useEffect(() => {
        if (selectedVideo) {
            setSelectedVideo(flatVideosList.find(section => section.id === selectedVideo.id));
        }
    }, [flatVideosList]);


    const NavBar = () => (
        <Link to={{ pathname: `/course/${productID}`, state: { productID, brandSrc, phaseId } }}>
            <div className="w-10/12 mx-auto relative justify-center">
                <div className="flex flex-row items-center text-white opacity-50 absolute top-0 lg:top-1">
                    <ChevronLeftIcon className="h-4 w-4 mt-1" />
                    <div className="body3 ml-1 mt-1">Back</div>
                </div>
                <div>
                    <h3 className="text-white text-center mt-6 hidden lg:block">{name}</h3>
                    <h5 className="text-white text-center mt-6 block lg:hidden">{name}</h5>
                </div>
                {' '}

            </div>
        </Link>
    );

    const urlVideo = get(selectedVideo, 'url', '');

    const metadata = get(selectedVideo, 'metadata', '');
    const plugAndPlay = metadata === '' || metadata === [] ? null : metadata;

    const filterValidPlugs = (p) => {
        if (!p?.plugAndPlaySlug) {
            return null;
        }

        const plugs = p?.plugAndPlaySlug?.filter(pItem => !!pItem.url);
        if (!plugs?.length) return null;
        return plugs;
    };

    const extractTranscriptText = async (url) => {
        try {
            fetch(url)
                .then(r => r.text())
                .then((text) => {
                    setTranscriptTxt(text);
                })
                .catch((e) => {
                    console.log(e);
                });
        } catch (e) {
            console.log(e);
        }
    };

    // track when a new video starts
    useEffect(() => {
        if (selectedVideo) {
            trackEvent({
                action: 'Module_Started',
                category: 'Module_Name',
                label: selectedVideo?.name,
            });
        }
    }, [selectedVideo]);

    const downloadableAssets = useMemo(() => (sortBy(selectedVideo?.downloads, 'order')), [selectedVideo?.downloads]);

    return (
        <div>
            <NavBar />
            <main className="video-page">

                <section className="section lesson section-lg pt-lg-0">
                    <CustomLoader loading={loading}>
                        <Row className="flex flex-row w-10/12 mx-auto">
                            <div className="main-content w-full lg:w-3/4 video-col">
                                <h2 className="text-white mb-4 hidden lg:block">{get(selectedVideo, 'name', '')}</h2>
                                <h5 className="text-white mb-4 block lg:hidden">{get(selectedVideo, 'name', '')}</h5>
                                <div className="sprout" style={{ background: '#101010' }}>
                                    {urlVideo && (
                                        <iframe
                                            id="videoPlayer"
                                            title="video"
                                            className="sproutvideo-player"
                                            src={`${urlVideo}?cc=eng&autoPlay=${autoPlay}`}
                                            style={{ background: '#101010' }}
                                            frameBorder="0"
                                            allowFullScreen
                                            width="100%"
                                            height="100%"
                                            // onLoad={() => setPlayerLoaded(true)}
                                        />
                                    )}
                                </div>
                                <div className="block lg:hidden flex flex-row scrollmenu layout-cell layout-scrollbar mt-8" id="scrollmenu">
                                    <div
                                        onClick={() => {
                                            if (phases === false) {
                                                setPhases(true);
                                                setDownload(false);
                                                setPandp(false);
                                                setMp3Player(false);
                                                setPhaseBg('#434343');
                                                setPandpBg('#1C1C1C');
                                                setDownloadBg('#1C1C1C');
                                                setAudioBg('#1C1C1C');
                                            }
                                        }}
                                        className="grey800Bg but text-white body3 w-fit h-fit p-3 rounded-2xl cursor-pointer"
                                        style={{ backgroundColor: phaseBg }}
                                    >
                                        Phases

                                    </div>
                                    {!!selectedVideo?.downloads?.length
                                    && (
                                        <div
                                            onClick={() => {
                                                if (downloads === false) {
                                                    setPhases(false);
                                                    setDownload(true);
                                                    setPandp(false);
                                                    setMp3Player(false);
                                                    setPhaseBg('#1C1C1C');
                                                    setPandpBg('#1C1C1C');
                                                    setDownloadBg('#434343');
                                                    setAudioBg('#1C1C1C');
                                                }
                                            }}
                                            className="grey800Bg but text-white body3 w-fit h-fit p-3 rounded-2xl ml-4 cursor-pointer"
                                            style={{ backgroundColor: downloadBg }}
                                        >
                                            Downloads

                                        </div>
                                    )}
                                    {!!plugAndPlay?.plugAndPlaySlug?.length
                                && !!filterValidPlugs(plugAndPlay)
                                && (
                                    <div
                                        onClick={() => {
                                            if (pandp === false) {
                                                setPhases(false);
                                                setDownload(false);
                                                setPandp(true);
                                                setMp3Player(false);
                                                setPhaseBg('#1C1C1C');
                                                setPandpBg('#434343');
                                                setDownloadBg('#1C1C1C');
                                                setAudioBg('#1C1C1C');
                                            }
                                        }}
                                        className="grey800Bg but text-white body3 w-fit h-fit p-3 rounded-2xl ml-4 cursor-pointer"
                                        style={{ backgroundColor: pandpBg }}
                                    >
                                        Plug & Plays

                                    </div>
                                )}
                                    <div
                                        onClick={() => {
                                            if (mp3Player === false) {
                                                setPhases(false);
                                                setDownload(false);
                                                setPandp(false);
                                                setMp3Player(true);
                                                setPhaseBg('#1C1C1C');
                                                setPandpBg('#1C1C1C');
                                                setDownloadBg('#1C1C1C');
                                                setAudioBg('#434343');
                                            }
                                        }}


                                        className="grey800Bg but text-white body3 w-fit h-fit p-3 rounded-2xl ml-4 cursor-pointer"
                                        style={{ backgroundColor: audioBg }}
                                    >
                                        Audio

                                    </div>
                                </div>
                                { phases
        && (
            <>
                <div className="w-full grey800Bg p-5 mt-8 text-white rounded-lg block lg:hidden">
                    <div>
                        {hasNextVideo && (
                            <div className="flex-row flex items-center justify-between">
                                <div
                                    className=" flex form-group"
                                    style={{ marginRight: 16 }}
                                >
                                    <input
                                    // checked={autoPlay}
                                        className="styled-checkbox"
                                        id="customCheckLogin2"
                                        type="checkbox"
                                        onClick={e => setAutoPlay(e.target.checked)}
                                    />
                                    <label htmlFor="customCheckLogin2" className="body4 text-white">
                                        Auto-Play
                                    </label>
                                </div>
                                <Button
                                    className="blkButton text-white body4 h-fit px-4 py-2"
                                    color="primary"
                                    onClick={() => {
                                        goNextVideo();
                                    }}
                                >
                                    {nextButtonLabel}
                                </Button>
                            </div>
                        )}
                    </div>

                    { !!item
                        && (
                            <div className="w-full block lg:hidden">
                                <div className="sidebar w-full mt-4 lg:mt-32 rounded-t-lg layout-cell layout-scrollbar" style={{ height: '39rem' }}>
                                    <div className="list-group text-white">

                                        {sections.map((section) => {
                                            if (section.name === item?.name) {
                                                return (
                                                    <div className="">

                                                        <>

                                                            <div className="list-group w-full text-white sticky top-0 z-20">

                                                                <div className="section-title">
                                                                    <div className="body3 text-white">{section.name}</div>
                                                                    <div className="body5 grey500 w-20 lg:w-32 flex flex-row items-center justify-end">
                                                                        {section.subSections ? '' : `${section.videos.length} videos`}

                                                                    </div>

                                                                </div>

                                                            </div>

                                                            <div className="">
                                                                <div className="">


                                                                    {section.subSections ? (
                                                                        <SubSections
                                                                            sectionID={section.id}
                                                                            subSections={section.subSections}
                                                                            setSelectedVideo={setSelectedVideo}
                                                                            selectedVideo={selectedVideo}
                                                                            onToggleComplete={onToggleComplete}
                                                                        />
                                                                    ) : (
                                                                        <>
                                                                            {section.videos.map((video) => {
                                                                            // const lessonComplete = completedLessons.find(({ id }) => parseInt(id) === parseInt(lesson.id));
                                                                                const lessonComplete = video?.complete;
                                                                                // if (index === 0 && sectionIndex === 0) {
                                                                                //     setSelectedVideo(video);
                                                                                // }
                                                                                return (
                                                                                    <div
                                                                                        className={`list-group-item sidebarBg relative hover:cursor-pointer ${
                                                                                            video.id === get(selectedVideo, 'id') ? 'active' : ''
                                                                                        }`}
                                                                                        onClick={() => {
                                                                                            setSelectedVideo(video);
                                                                                        }}

                                                                                    >
                                                                                        <div className="flex flex-row items-center sidebarBg z-10 py-4">
                                                                                            {enableCompleteVideo && (
                                                                                                <div className="custom-control custom-checkbox">
                                                                                                    <input
                                                                                                        className="custom-control-input m-0"
                                                                                                        checked={lessonComplete}
                                                                                                        key={`lesson-check-${video.id}-${lessonComplete}`}
                                                                                                        id={`lesson-check-${video.id}`}
                                                                                                        type="checkbox"
                                                                                                        onChange={() => {
                                                                                                            onToggleComplete(video);
                                                                                                        }}
                                                                                                    />
                                                                                                    <label
                                                                                                        className="custom-control-label"
                                                                                                        htmlFor={`lesson-check-${video.id}`}
                                                                                                    />
                                                                                                </div>
                                                                                            )}
                                                                                            <div className="w-36 h-16 lg:h-20 mx-3 lg:mx-6 rounded-md relative overflow-hidden">
                                                                                                <img src={video?.thumbnail} alt="" />
                                                                                                {/*                                                                        <div className="absolute bg-white h-1 w-full bottom-0 rounded-b-md"></div>
*/}
                                                                                            </div>
                                                                                            <div className="my-auto w-40">
                                                                                                <div className="flex flex-col">
                                                                                                    <div className="body5 text-white">
                                                                                                        {`${video.name}`}
                                                                                                        <span className="badge badge-success" />
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                        </>
                                                                    )}

                                                                </div>
                                                            </div>
                                                        </>

                                                    </div>
                                                );
                                            }
                                        })}
                                    </div>
                                </div>
                                {/* <h3 className="text-white mt-12">{get(selectedVideo, 'name', '')}</h3> */}
                                <div className="text-white body2 mt-4" />
                            </div>
                        )

                    }


                    {!item

                        && (
                            <div className="w-full block lg:hidden">
                                <div className="sidebar w-full h-96 mt-4 lg:mt-32 rounded-t-lg layout-cell layout-scrollbar">
                                    <div className="list-group text-white">

                                        {sections.map(section => (
                                            <Disclosure as="div" key={section.name} className="">
                                                {({ open }) => (
                                                    <>

                                                        <Disclosure.Button className="list-group w-full text-white ">

                                                            <div className="section-title">
                                                                <div className="body3 text-white">{section.name}</div>
                                                                <div className="body5 grey500 w-20 lg:w-32 flex flex-row items-center justify-end">
                                                                    {section.subSections ? '' : `${section.videos.length} videos`}

                                                                </div>

                                                            </div>

                                                        </Disclosure.Button>

                                                        <Disclosure.Panel as="dd" className="">
                                                            <div className="">


                                                                {section.subSections ? (
                                                                    <SubSections
                                                                        sectionID={section.id}
                                                                        subSections={section.subSections}
                                                                        setSelectedVideo={setSelectedVideo}
                                                                        selectedVideo={selectedVideo}
                                                                        onToggleComplete={onToggleComplete}
                                                                    />
                                                                ) : (
                                                                    <>
                                                                        {section.videos.map((video) => {
                                                                        // const lessonComplete = completedLessons.find(({ id }) => parseInt(id) === parseInt(lesson.id));
                                                                            const lessonComplete = video?.complete;
                                                                            // if (index === 0 && sectionIndex === 0) {
                                                                            //     setSelectedVideo(video);
                                                                            // }
                                                                            return (
                                                                                <div
                                                                                    className={`list-group-item sidebarBg relative hover:cursor-pointer ${
                                                                                        video.id === get(selectedVideo, 'id') ? 'active' : ''
                                                                                    }`}
                                                                                    onClick={() => {
                                                                                        setSelectedVideo(video);
                                                                                    }}

                                                                                >

                                                                                    <div className="flex flex-row items-center sidebarBg z-10 py-4">
                                                                                        {enableCompleteVideo && (
                                                                                            <div className="custom-control custom-checkbox">
                                                                                                <input
                                                                                                    className="custom-control-input m-0"
                                                                                                    checked={lessonComplete}
                                                                                                    key={`lesson-check-${video.id}-${lessonComplete}`}
                                                                                                    id={`lesson-check-${video.id}`}
                                                                                                    type="checkbox"
                                                                                                    onChange={() => {
                                                                                                        onToggleComplete(video);
                                                                                                    }}
                                                                                                />
                                                                                                <label
                                                                                                    className="custom-control-label"
                                                                                                    htmlFor={`lesson-check-${video.id}`}
                                                                                                />
                                                                                            </div>
                                                                                        )}
                                                                                        <div className="w-48 h-16 lg:h-28 mx-3 lg:mx-6 rounded-md relative overflow-hidden">
                                                                                            <img src={video?.thumbnail} alt="" />
                                                                                            {/*                                                                        <div className="absolute bg-white h-1 w-full bottom-0 rounded-b-md"></div>
*/}
                                                                                            {' '}

                                                                                        </div>
                                                                                        <div className="mb-auto w-40">
                                                                                            <div className="flex flex-col">
                                                                                                <div className="body5 text-white">
                                                                                                    {`${video.name}`}
                                                                                                    <span className="badge badge-success" />
                                                                                                </div>
                                                                                                {/*                                                                            <div className="body6 text-white">00 min</div>
*/}
                                                                                                {' '}

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </>
                                                                )}

                                                            </div>
                                                        </Disclosure.Panel>
                                                    </>
                                                )}
                                            </Disclosure>
                                        ))}
                                    </div>
                                </div>
                                <h3 className="text-white mt-12">{get(selectedVideo, 'name', '')}</h3>
                                <div className="text-white body2 mt-4" />
                            </div>
                        )

                    }


                </div>
            </>
        )

                                }

                                {downloads
                                && !!selectedVideo?.downloads?.length && ((
                                    <>
                                        <div className="w-full grey800Bg p-8 mt-8 mb-[30%] text-white rounded-lg block lg:hidden">
                                            <h5 className="mb-6">Downloads</h5>
                                            <div className="grey500 pb-4 grey700Border body6">FILE NAME</div>
                                            {downloadableAssets?.map((asset, index) => (
                                                <div className="grey700Border w-full body3 flex flex-col items-start">
                                                    <div className=" grey500 py-4">{asset.title}</div>
                                                    <div className=" text-white flex justify-end  py-4">
                                                        <a
                                                            className="blkButton mx-auto w-fit body5 px-4 py-6 cursor-pointer"
                                                            type="button"
                                                            rel="tooltip"
                                                            data-original-title=""
                                                            title=""
                                                            href={asset.url}
                                                            target="_blank"
                                                            download
                                                        >
                                                            <span
                                                                onClick={() => trackEvent({
                                                                    action: 'Download',
                                                                    category: 'Module_Name',
                                                                    label: selectedVideo?.name,
                                                                })
                                                                }
                                                            >
                                                                <i className="ni ni-cloud-download-95 " />
                                                                Download
                                                            </span>

                                                        </a>
                                                    </div>
                                                </div>
                                            ))}

                                        </div>
                                    </>
                                )
                                )
                                }

                                {pandp
                                && !!plugAndPlay?.plugAndPlaySlug?.length
                                && !!filterValidPlugs(plugAndPlay)

    && ((
        <>


            <div className="w-full grey800Bg p-8 mt-8 text-white rounded-lg block lg:hidden">
                <h5 className="mb-6">Plug & Play</h5>
                <div className="grey500 pb-4 grey700Border body6">FILE NAME</div>
                {plugAndPlay?.plugAndPlaySlug?.length && !!filterValidPlugs(plugAndPlay)
           && (
               <>
                   {filterValidPlugs(plugAndPlay).map((pItem) => {
                       const externalUrl = pItem?.url && pItem?.url?.includes('https');
                       return (
                           <div className="grey700Border w-full body3 flex flex-col items-start">
                               <div className=" grey500 py-4">{pItem.plugName}</div>
                               <div className=" text-white flex justify-end  py-4">
                                   {externalUrl
                                       ? (
                                           <a href={pItem.url} target="_blank" rel="noreferrer" className="blkButton mx-auto w-fit body5 px-4 py-2 lg:py-6 cursor-pointer">
                                               View
                                               <ExternalLinkIcon className="h-5 w-5 text-white ml-2" aria-hidden="true" />
                                           </a>
                                       ) : (
                                           <Link to={`/${pItem.url}`} target="_blank" className="blkButton mx-auto w-fit body5 px-4 py-2 lg:py-6 cursor-pointer">
                                               View
                                               <ExternalLinkIcon className="h-5 w-5 text-white ml-2" aria-hidden="true" />
                                           </Link>
                                       )
                                   }
                               </div>
                           </div>
                       );
                   })}
               </>
           )
                }

            </div>
        </>
    )
    )
                                }


                                <div className="video-features" style={{ background: '#101010' }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                        }}
                                    >
                                        {enableCompleteVideo && (
                                            <div
                                                color="default"
                                                outline={!get(selectedVideo, 'complete')}
                                                className="blkButton text-white body4 h-fit px-4 py-2 cursor-pointer"
                                                onClick={() => {
                                                    const newComplete = !selectedVideo?.complete;
                                                    onToggleComplete(selectedVideo);
                                                    if (newComplete) {
                                                        goNextVideo();
                                                        trackEvent({
                                                            action: 'Module_Complete',
                                                            category: 'Session_Name',
                                                            label: name,
                                                        });
                                                    }
                                                }}
                                            >
                                                <CheckIcon className="h-6 w-6 mr-2" />
                                                <i className="fa fa-check" />
                                                {selectedVideo?.complete ? ' Completed' : ' Mark as complete'}
                                            </div>
                                        )}

                                        {transcriptAssets.audio ? (
                                            <div
                                                key={null}
                                                color="default"
                                                outline={!mp3Player}
                                                className={mp3Player ? 'whiteBtn text-black h-fit px-4 py-2.5 body4 cursor-pointer' : 'blkButton text-white h-fit px-4 py-2.5 body4 cursor-pointer'}
                                                onClick={() => {
                                                    setMp3Player(!mp3Player);
                                                }}
                                                style={{
                                                    marginLeft: 10,
                                                }}
                                            >
                                                Audio
                                            </div>
                                        ) : null}

                                        {transcriptAssets.txt ? (
                                            <div className="body4 text-white py-2 ml-3">
                                                <Typography component="div">
                                                    <Grid component="label" container alignItems="center" spacing={1}>
                                                        <Grid item>
                                                            <AntSwitch
                                                                checked={transcriptEnabled}
                                                                className="bg-black"
                                                                onChange={() => {
                                                                    setTranscriptEnabled(!transcriptEnabled);
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item className="body4 text-white">
                                                            Transcript
                                                        </Grid>
                                                    </Grid>
                                                </Typography>
                                            </div>
                                        ) : null}
                                    </div>

                                    {hasNextVideo && (
                                        <div className="flex-row flex items-center">
                                            <div
                                                className=" flex form-group"
                                                style={{ marginRight: 16 }}
                                            >
                                                <input
                                                    // checked={autoPlay}
                                                    className="styled-checkbox"
                                                    id="customCheckLogin2"
                                                    type="checkbox"
                                                    onClick={e => setAutoPlay(e.target.checked)}
                                                />
                                                <label htmlFor="customCheckLogin2" className="body4 text-white">
                                                    Auto-Play
                                                </label>
                                            </div>
                                            <Button
                                                className="blkButton text-white body4 h-fit px-4 py-2"
                                                color="primary"
                                                onClick={() => {
                                                    goNextVideo();
                                                }}
                                            >
                                                {nextButtonLabel}
                                            </Button>
                                        </div>
                                    )}
                                </div>

                                {transcriptAssets.audio && (
                                    <Mp3Player
                                        audioURL={transcriptAssets.audio.url}
                                        showPlayer={mp3Player}
                                        style={{ background: '#101010' }}
                                        name={selectedVideo?.name}
                                    />
                                )}

                                <div
                                    className="transcript-box text-white"
                                    style={{ display: transcriptEnabled ? 'block' : 'none' }}
                                >
                                    <h5>Transcript</h5>

                                    <br />
                                    <br />

                                    <div className="transcript-block">
                                        <div className="transcript-block-content">{transcriptTxt}</div>
                                    </div>
                                </div>

                                {!!selectedVideo?.downloads?.length
                                && (
                                    <div className="w-full grey800Bg p-8 mt-8 text-white rounded-lg hidden lg:block">
                                        <h3 className="mb-6">Downloads</h3>

                                        <table className="table table-hover w-full">
                                            <thead>
                                                <tr className="grey700Border w-full pb-4 body6">
                                                    <th className="w-1/3 grey500 pb-4">FILE NAME</th>
                                                    <th className="w-1/3 grey500 text-center pb-4">TYPE</th>
                                                    <th className="w-1/3 pb-4" />
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {downloadableAssets?.map((asset, index) => (
                                                    <tr className="grey700Border w-full body3 ">
                                                        <td className="w-1/3 grey500 py-4">{asset.title}</td>
                                                        <td className="w-1/3 grey500 text-center py-4">{get(asset, 'type', '')}</td>
                                                        <td className="w-1/3 text-white flex justify-end ml-auto py-4">
                                                            <a
                                                                className="blkButton mx-auto w-fit px-4 py-2"
                                                                type="button"
                                                                rel="tooltip"
                                                                data-original-title=""
                                                                title=""
                                                                href={asset.url}
                                                                target="_blank"
                                                                download
                                                            >
                                                                <span
                                                                    onClick={() => trackEvent({
                                                                        action: 'Download',
                                                                        category: 'Module_Name',
                                                                        label: selectedVideo?.name,
                                                                    })
                                                                    }
                                                                >
                                                                    <i className="ni ni-cloud-download-95 " />
                                                                    Download
                                                                </span>

                                                            </a>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )
                                }

                                {!!plugAndPlay?.plugAndPlaySlug?.length
                                && !!filterValidPlugs(plugAndPlay)
                                && (
                                    <div className="w-full grey800Bg p-8 mt-8 text-white rounded-lg hidden lg:block">
                                        <h3 className="mb-6">Plug & Play</h3>

                                        <table className="table table-hover w-full">
                                            <thead>
                                                <tr className="grey700Border w-full pb-4 body6">
                                                    <th className="w-1/3 grey500 pb-4">FILE NAME</th>
                                                    <th className="w-1/3 grey500 text-center pb-4" />
                                                    <th className="w-1/3 pb-4" />
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {plugAndPlay?.plugAndPlaySlug?.length && !!filterValidPlugs(plugAndPlay)
            && (
                <>
                    {filterValidPlugs(plugAndPlay).map((pItem) => {
                        const externalUrl = pItem?.url && pItem?.url?.includes('https');
                        return (
                            <tr className="grey700Border w-full body3 ">
                                <td className="w-1/3 grey500 py-4">{pItem.plugName}</td>
                                <td className="w-1/3 grey500 text-center py-4" />
                                <td className="w-1/3 text-white flex justify-end ml-auto py-4">
                                    {
                                        externalUrl
                                            ? (
                                                <a href={pItem.url} target="_blank" rel="noreferrer" className="blkButton mx-auto w-fit px-4 py-2">
                                                    View
                                                    <ExternalLinkIcon className="h-5 w-5 text-white ml-2" aria-hidden="true" />
                                                </a>
                                            )
                                            : (
                                                <Link to={`/${pItem.url}`} target="_blank" className="blkButton mx-auto w-fit px-4 py-2">
                                                    View
                                                    <ExternalLinkIcon className="h-5 w-5 text-white ml-2" aria-hidden="true" />
                                                </Link>
                                            )
                                    }
                                </td>
                            </tr>
                        );
                    })}
                </>
            )
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </div>
                            { !!item
                        && (
                            <div className="w-1/4 hidden lg:block">
                                <div className="sidebar w-full mt-32 rounded-t-lg layout-cell layout-scrollbar" style={{ height: '39rem' }}>
                                    <div className="list-group text-white">

                                        {sections.map((section) => {
                                            if (section.name === item?.name) {
                                                return (
                                                    <div className="">

                                                        <>

                                                            <div className="list-group w-full text-white sticky top-0 z-20">

                                                                <div className="section-title">
                                                                    <div className="body3 text-white">{section.name}</div>
                                                                    <div className="body5 grey500 w-32 flex flex-row items-center justify-end">
                                                                        {section.subSections ? '' : `${section.videos.length} videos`}

                                                                    </div>

                                                                </div>

                                                            </div>

                                                            <div className="">
                                                                <div className="">


                                                                    {section.subSections ? (
                                                                        <SubSections
                                                                            sectionID={section.id}
                                                                            subSections={section.subSections}
                                                                            setSelectedVideo={setSelectedVideo}
                                                                            selectedVideo={selectedVideo}
                                                                            onToggleComplete={onToggleComplete}
                                                                        />
                                                                    ) : (
                                                                        <>
                                                                            {section.videos.map((video) => {
                                                                            // const lessonComplete = completedLessons.find(({ id }) => parseInt(id) === parseInt(lesson.id));
                                                                                const lessonComplete = video?.complete;
                                                                                // if (index === 0 && sectionIndex === 0) {
                                                                                //     setSelectedVideo(video);
                                                                                // }
                                                                                return (
                                                                                    <div
                                                                                        className={`list-group-item bodyBg relative hover:cursor-pointer ${
                                                                                            video.id === get(selectedVideo, 'id') ? 'active' : ''
                                                                                        }`}
                                                                                        onClick={() => {
                                                                                            setSelectedVideo(video);
                                                                                        }}
                                                                                    >
                                                                                        <div className="flex flex-row items-center bodyBg z-21 py-4">
                                                                                            {enableCompleteVideo && (
                                                                                                <div className="custom-control custom-checkbox">
                                                                                                    <input
                                                                                                        className="custom-control-input m-0"
                                                                                                        checked={lessonComplete}
                                                                                                        key={`lesson-check-${video.id}-${lessonComplete}`}
                                                                                                        id={`lesson-check-${video.id}`}
                                                                                                        type="checkbox"
                                                                                                        onChange={() => {
                                                                                                            onToggleComplete(video);
                                                                                                        }}
                                                                                                    />
                                                                                                    <label
                                                                                                        className="custom-control-label"
                                                                                                        htmlFor={`lesson-check-${video.id}`}
                                                                                                    />
                                                                                                </div>
                                                                                            )}
                                                                                            <div className="w-24 h-12 mx-2 rounded-md relative overflow-hidden">
                                                                                                <img src={video?.thumbnail} alt="" />
                                                                                                {/*                                                                        <div className="absolute bg-white h-1 w-full bottom-0 rounded-b-md"></div>
*/}
                                                                                            </div>
                                                                                            <div className="my-auto w-40">
                                                                                                <div className="flex flex-col">
                                                                                                    <div className="body5 text-white">
                                                                                                        {`${video.name}`}
                                                                                                        <span className="badge badge-success" />
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                        </>
                                                                    )}

                                                                </div>
                                                            </div>
                                                        </>

                                                    </div>
                                                );
                                            }
                                        })}
                                    </div>
                                </div>
                                {/* <h3 className="text-white mt-12">{get(selectedVideo, 'name', '')}</h3> */}
                                {/* <div className="text-white body2 mt-4" dangerouslySetInnerHTML={{ __html: get(selectedVideo, 'description', '') }}> */}
                                {/* </div> */}
                            </div>
                        )

                            }


                            { !item

                        && (
                            <div className="w-1/3 hidden lg:block">
                                <div className="sidebar w-full h-96 mt-32 rounded-t-lg layout-cell layout-scrollbar">
                                    <div className="list-group text-white">

                                        {sections.map(section => (
                                            <Disclosure as="div" key={section.name} className="">
                                                {({ open }) => (
                                                    <>

                                                        <Disclosure.Button className="list-group w-full text-white ">

                                                            <div className="section-title">
                                                                <div className="body3 text-white">{section.name}</div>
                                                                <div className="body5 grey500 w-32 flex flex-row items-center justify-end">
                                                                    {section.subSections ? '' : `${section.videos.length} videos`}

                                                                </div>

                                                            </div>

                                                        </Disclosure.Button>

                                                        <Disclosure.Panel as="dd" className="">
                                                            <div className="">


                                                                {section.subSections ? (
                                                                    <SubSections
                                                                        sectionID={section.id}
                                                                        subSections={section.subSections}
                                                                        setSelectedVideo={setSelectedVideo}
                                                                        selectedVideo={selectedVideo}
                                                                        onToggleComplete={onToggleComplete}
                                                                    />
                                                                ) : (
                                                                    <>
                                                                        {section.videos.map((video) => {
                                                                        // const lessonComplete = completedLessons.find(({ id }) => parseInt(id) === parseInt(lesson.id));
                                                                            const lessonComplete = video?.complete;
                                                                            // if (index === 0 && sectionIndex === 0) {
                                                                            //     setSelectedVideo(video);
                                                                            // }
                                                                            return (
                                                                                <div
                                                                                    className={`list-group-item bodyBg relative hover:cursor-pointer ${
                                                                                        video.id === get(selectedVideo, 'id') ? 'active' : ''
                                                                                    }`}
                                                                                    onClick={() => {
                                                                                        setSelectedVideo(video);
                                                                                    }}

                                                                                >
                                                                                    <div className="flex flex-row items-center bodyBg z-10 py-4">
                                                                                        {enableCompleteVideo && (
                                                                                            <div className="custom-control custom-checkbox">
                                                                                                <input
                                                                                                    className="custom-control-input m-0"
                                                                                                    checked={lessonComplete}
                                                                                                    key={`lesson-check-${video.id}-${lessonComplete}`}
                                                                                                    id={`lesson-check-${video.id}`}
                                                                                                    type="checkbox"
                                                                                                    onChange={() => {
                                                                                                        onToggleComplete(video);
                                                                                                    }}
                                                                                                />
                                                                                                <label
                                                                                                    className="custom-control-label"
                                                                                                    htmlFor={`lesson-check-${video.id}`}
                                                                                                />
                                                                                            </div>
                                                                                        )}
                                                                                        <div className="w-48 h-28 mx-6 rounded-md relative" overflow-hidden>
                                                                                            <img src={video?.thumbnail} alt="" />
                                                                                            {/*                                                                        <div className="absolute bg-white h-1 w-full bottom-0 rounded-b-md"></div>
*/}
                                                                                            {' '}

                                                                                        </div>
                                                                                        <div className="mb-auto w-40">
                                                                                            <div className="flex flex-col">
                                                                                                <div className="body5 text-white">
                                                                                                    {`${video.name}`}
                                                                                                    <span className="badge badge-success" />
                                                                                                </div>
                                                                                                {/*                                                                            <div className="body6 text-white">00 min</div>
*/}
                                                                                                {' '}

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </>
                                                                )}

                                                            </div>
                                                        </Disclosure.Panel>
                                                    </>
                                                )}
                                            </Disclosure>
                                        ))}
                                    </div>
                                </div>
                                <h3 className="text-white mt-12">{get(selectedVideo, 'name', '')}</h3>
                                <div className="text-white body2 mt-4" />
                            </div>
                        )

                            }
                            {' '}


                            {' '}

                        </Row>
                    </CustomLoader>
                </section>
            </main>
        </div>
    );
};
