import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useConfig } from '@genflow/core';
import { useParams } from 'react-router';
import useBoughtProducts from '@genflow/web-courses/hooks/useBoughtProducts';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import useCourse from '@genflow/web-courses/hooks/useCourse';
import useGetCourses from '@genflow/web-courses/hooks/useGetCourses';
import useCompleteLesson from '@genflow/web-courses/hooks/useCompleteLesson';
import {
    // filter,
    flatten,
    get,
    lowerCase,
    orderBy,
    parseInt,
} from 'lodash';
import CustomLoader from '../../components/CustomLoader';

import CourseProgressProduct from '../../components/CourseProgressProduct';
import CourseProduct from '../../components/CourseProduct';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { OnboardingContext } from '../../providers/OnboardingProvider';
import { hosts } from './UpcomingQA';
import useAccess from 'hooks/useAccess';
import moment from 'moment';
import HomeQA from '../../components/home/HomeQA';
import { UserDataContext } from 'providers/UserDataProvider';
import useUpcomingQA from 'hooks/useUpcomingQA';

function getSectionIndex(productId, group) {
    let x = -1;

    group.map((item, index) => {
        if (item.id === productId) {
            x = index;
        }
    });

    return x;
}

function filterSubsections(sections) {
    const newSections = [];

    sections.map((item) => {
        if (!item.parent_id) {
            newSections.push(item);
        } else {
            const sectionIndex = getSectionIndex(item.product_id, sections);
            const newSectionIndex = getSectionIndex(item.product_id, newSections);

            if (newSectionIndex !== -1) {
                if (newSections[newSectionIndex].subSections) {
                    newSections[newSectionIndex].subSections.push(item);
                } else {
                    newSections[newSectionIndex] = { ...newSections[newSectionIndex], subSections: [item] };
                }
            } else {
                newSections.push({ ...sections[sectionIndex], subSections: [item] });
            }
        }
    });

    return newSections;
}

export default () => {
    const { remountKey } = useParams();
    const {
        copyPasteAgencyCourseProductID,
        agencyNavigatorCourseProductID,
        vendorUuid,
        stripeProductPlans,
    } = useConfig();
    const progess = 80;
    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, []);

    const courses = useGetCourses(vendorUuid);

    const productID = agencyNavigatorCourseProductID;
    const { data: completedLessons } = useCompleteLesson(productID);

    const { data: course, loading, authoriseError, error } = useCourse(vendorUuid, productID);

    const access = !(!loading && authoriseError);

    const sections = get(course, 'sections', []);

    const { userProfile } = useContext(UserDataContext);

    const userProgress = userProfile?.data?.progress;
    const progress = useMemo(()=>{
        if(!!userProgress){
            return Object?.keys(userProgress)
        }
        return []
    } ,[userProfile])

    const coursesInprogress = () => {
        if (!courses.loading && access) {
            const filteredProgressCourses = courses?.data?.filter((item)=> progress?.includes(item?.id)) 
            return filteredProgressCourses?.map((item)=> ({
                ...item,
                lesson: userProgress?.[item?.id]?.lesson,
                video: userProgress?.[item?.id]?.video
            }))
        }
        return [];
    };

    const history = useHistory();
    const gotoNavigator = () => history.push('/course/3351');

    const {
        setActiveInfoType,
    } = useContext(OnboardingContext);

    const getOrder = (id) => {
        switch (id) {
        case '3351':
            return 1;
        case '5326':
            return 1;
        case '5283':
            return 2;
        case '4773':
            return 2.1;
        case '4630':
            return 3;
        case '4765':
            return 4;
        case '4766':
            return 5;
        case '4767':
            return 6;
        case '4771':
            return 7;
        case '4772':
            return 8;
        case '4773':
            return 9;
        default:
            return 0;
        }
    };

   
    const orderedCourses = useMemo(()=> courses?.data?.map((course)=>({
        ...course,
        order: getOrder(course?.id)
    }) ) ,[courses])


    // use for daily QA
    let apexAccess = false;
    const { loading: accessLoading, validAccess } = useAccess(4630); //apex access
    if (!accessLoading) {
        apexAccess = validAccess;
    }

    const {todayQA}= useUpcomingQA(apexAccess);


    return (
        <CustomLoader loading={loading}>
            <div className="pb-16">
                <div className="w-10/12 mx-auto">
                    <div className="w-full h-48 sm:h-96 banner flex flex-col justify-center pl-4 md:pl-10 pb-6 sm:pb-0">
                        <h1 className="text-white hidden sm:block">Welcome To Your Student Platform</h1>
                        <h3 className="text-white block sm:hidden mt-8">Welcome To Your Student Platform</h3>
                        <div className="hidden sm:block body2 mt-4 md:mt-7 text-white md:w-1/2 w-full md:pr-0 pr-10">Here you’ll find all of GrowYourAgency’s courses content, as well as the Plug N Plays, Tools, Q&A Calls Recordings, and Community Access.</div>
                        <div className="body5 block sm:hidden mt-4 md:mt-7 text-white md:w-1/2 w-full md:pr-0 pr-4">Here you’ll find all of GrowYourAgency’s courses content, as well as the Plug N Plays, Tools, Q&A Calls Recordings, and Community Access.</div>
                        <button style={{ width: '155px', padding: '0px' }} className="buttonLgLight h-fit mt-4 md:mt-7 hidden sm:flex">Start Watching</button>
                        <button style={{ width: '140px', padding: '4px' }} className="buttonLgLight h-10 mt-4 md:mt-7 flex sm:hidden mb-3">Start Watching</button>
                    </div>
                    {' '}
                
                    {!!todayQA?.length && (
                    <>
                    <h2 className="text-white mt-3 md:mt-12 sm:block">Today’s Q&A</h2>
                    <div className="grey500 body4 mt-4 mb-8 sm:block">
                        Reserve your spot and get your questions answered today
                    </div>
                    {orderBy(todayQA, 'time')?.map((props, index) => (<HomeQA key={index} {...props} apexAccess={apexAccess} />))}
                    </>
                    )}
                </div>
                <div style={{ marginLeft: '8.33333%' }}>
                    {!!coursesInprogress()?.length && 
                    <>
                    <h2 className="text-white md:mt-16 mb-8 md:mb-0 block ">In Progress</h2>
                    <div className="grey500 body4 mt-4 hidden sm:block">
                        Continue watching your program
                    </div>
                    <div className="flex flex-row  scrollmenu linear layout-cell layout-scrollbar pt-8">
                        {!loading && coursesInprogress()?.map((item, i) =>
                            (
                                <div className={i > 0 && `ml-6`} key={i}>
                                    <CourseProgressProduct
                                        key={i}
                                        name={item.name}
                                        imageSrc={item.background_image}
                                        brandSrc={JSON.parse(item.meta_data)?.productLogo}
                                        description={item.description}
                                        productID={item.id}
                                        sections={item.sections}
                                        invite={JSON.parse(item.meta_data)?.blocked}
                                        access={access}
                                        extraImgStyling={{ width: '315px' }}
                                        lesson={item?.lesson}
                                        video={item?.video}
                                        progress
                                    />
                                </div>
                            ))}
                    </div>
                    </>
                    }
                </div>
                <div style={{ marginLeft: '8.33333%' }}>
                    <div className="relative">
                        <h2 className="text-white mt-16 mb-8 sm:mb-0 hidden sm:block">
                            All Programs
                            <InfoOutlinedIcon className="white500 ml-2 cursor-pointer" onClick={()=> setActiveInfoType('programs')}/>
                        </h2>
                        <h5 className="text-white mt-10 mb-4 sm:mb-0 block sm:hidden" >
                            All Programs
                            <InfoOutlinedIcon className="white500 ml-2 cursor-pointer" onClick={()=> setActiveInfoType('programs')}/>
                        </h5>
                        <div className="grey500 body4 mt-4 hidden sm:block">
                            {' '}
                            All the programs we offer at GrowYourAgency
                            {' '}
                        </div>
                        <div className="flex flex-row items-center absolute top-0 right-12 md:right-36">
                            <ChevronLeftIcon
                                className="h-5 w-5 mr-2 grey500 cursor-pointer"
                                onClick={() => {
                                    document.getElementById('scrollmenu').scrollLeft -= 800;
                                }}
                            />
                            <ChevronRightIcon
                                className="h-5 w-5 mr-2 grey500 cursor-pointer"
                                onClick={() => {
                                    document.getElementById('scrollmenu').scrollLeft += 800;
                                }}
                            />
                        </div>
                    </div>
                    <div className="relative">
                        <div
                            className="flex flex-row scrollmenu layout-cell layout-scrollbar"
                            id="scrollmenu"
                        >
                            {orderBy(orderedCourses, 'order'
                                )?.filter(item => !JSON.parse(item.meta_data)?.hidden)
                                .map((item, i) => (
                                    <div className={` pt-8 ${i > 0 && `ml-6`}`} key={i} id={`${i === 0 && 'course-programs'}`}>
                                        <CourseProduct
                                            key={i}
                                            name={item.name}
                                            imageSrc={item.background_image}
                                            brandSrc={JSON.parse(item.meta_data)?.productLogo}
                                            description={item.description}
                                            productID={item.id}
                                            invite={JSON.parse(item.meta_data)?.blocked}
                                            // extraStyling={{"marginBottom": "-2.5rem"}}
                                            extraImgStyling={{ width: '315px' }}
                                            item={item}
                                        />
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </CustomLoader>
    );
};
