import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import { Link } from 'react-router-dom';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        available: true,
        sectionName: 'Zapier Integration',
    },
    {
        available: true,
        sectionName: '#1 Setting up the Zaps',
    },
    {
        available: true,
        sectionName: '#2 Choosing your notification delivery method',
    },
];

const title = 'Lead Automation';

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div className="plug-block">  
                        <h2 className="mb-4">{title}</h2>
                            <p>
                                Lead Ads are great for generating leads, however they have a major drawback. They do not trigger any notification when a new lead has been added to the Form. The only way for the Facebook Page Manager or Advertise to see the leads, is to go to Publishing Settings > Form Leads and then download the entries in CVS or XLS format.
                            </p>

                            <p>
                                This could work for campaigns aiming to gather emails but when Lead Ads are used for time sensitive marketing objectives, the lack of notification is a major problem.
                            </p>

                            <p>
                                We are going to go over a few different ways to set up automations so that you are never late to a lead again!
                            </p>

                            <p>
                                We will be discussing different methods as well as different delivery channels.
                            </p>
                        </div>

                        <div ref={refs[0]} className="plug-block">
                            <div className="plug-block-subheading">Zapier Integration</div>

                            <p>
                                Zapier is one of the most commonly used integrations as it is very easy to set up. Using the Facebook Lead Ads requires premium subscription, but it’s not expensive and well worth it.
                            </p>

                            <p>
                                Zapier gives you the option of 3 different notification delivery methods:
                                {' '}
                                <strong>SMS, Email & Slack.</strong>
                            </p>

                            <p>
                                Firstly, we need to setup the zaps.
                            </p>
                        </div>


                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">
                                #1 Setting up the Zaps
                            </div>

                            <p>
                                We need to start by creating a Zap first - a zapier workflow.
                            </p>

                            <p>
                                There are 2 ways to do this. On facebook or on the zapier site.
                            </p>

                            <p className="bold">
                                In Facebook:
                            </p>

                            <p>
                                Open your Facebook Page where you are collecting the relevant leads
                            </p>

                            <p>
                                Open the Ad account where you are generating your leads.
                            </p>

                            <p>
                                Then go to your business suite by clicking the burger menu and scrolling down to ‘Business Suite’ highlighted in red.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation1.png" alt="" />

                            <p>
                                Once you arrive in the business suite, on  the left click ‘More Tools’ then select ‘Instant Forms’.
                            </p>

                            <div>
                                <img style={{ width: '25%' }} src="https://img.genflow.com/gya/plugandplays/leadAutomation2-1.png" alt="" />

                                <span style={{ margin: '0 2rem' }}>➟</span>


                                <img style={{ width: '40%' }} src="https://img.genflow.com/gya/plugandplays/leadAutomation2-2.png" alt="" />
                            </div>

                            <p>
                                You’ll be taken to the Lead Ads Forms page. Once there click ‘CRM Setup’ at the top.
                            </p>


                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation3.png" alt="" />

                            <p>
                                You’ll now have the option to search for 1000’s of integrations. The one we will be searching for is ‘Zapier’.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation4-9.png" alt="" />

                        </div>

                        <div className="plug-block" ref={refs[2]}>
                            <div className="plug-block-subheading">
                                #2 Choosing your notification delivery method
                            </div>

                            <p>
                                As mentioned there’s 3 different delivery types that we will focus on, let’s get into how to setup each method with zapier.
                            </p>

                            <p className="bold">
                                a. SMS
                            </p>

                            <p>
                                SMS will always be the quickest way to be notified about new leads.
                            </p>

                            <p>
                                Choose the ‘SMS by Zapier’ option in the search drop-down.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation3.png" alt="" />

                            <p>
                                Click ‘Connect’.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation6.png" alt="" />

                            <p>
                                Then ‘Continue with Facebook’.
                            </p>

                            <p>
                                You will be prompted to either create or sign into your zapier account, get that setup.
                            </p>

                            <p>
                                After that you’ll come to this screen.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation7.png" alt="" />

                            <p>
                                Now you’ll have to authenticate it with your cell number.
                            </p>

                            <p>
                                Then in the Zap, click the + button on the right of the Message box to add the details you want from your Facebook Lead Ad.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation8.png" alt="" />

                            <p>
                                Turn the Zap on, and you'll get an SMS message every time your ad is filled out.
                            </p>

                            <p>
                                Outside the US or Canada? You'll need to use another phone service to get SMS messages from Zapier, such as Twilio which costs $1/month for a phone number then around 1-3¢ per SMS message.
                            </p>

                            <p className="bold">
                                b. Email
                            </p>

                            <p>
                                To set up email notifications you’ll need to select the ‘Email by Zapier’ option.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation4-9.png" alt="" />

                            <p>
                                Then you’ll need to select the correct ad account
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation9.png" alt="" />

                            <p>
                                Click ‘continue’.
                            </p>

                            <p>
                                Then you’ll need to setup the Trigger.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation10.png" alt="" />

                            <p>
                                Select your correct page and then the relevant lead forms. Once  you’ve selected the relevant form click ‘Continue’.
                            </p>

                            <p>
                                Then you can test your trigger to make sure it’s working correctly.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation11.png" alt="" />

                            <p>
                                Now that the test is complete and working, scroll down and click ‘Send Outbound Email’
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation12.png" alt="" />

                            <p>
                                It should open up and look like this:
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation13.png" alt="" />

                            <p>
                                In the first ‘To’ field you’ll want to enter up to 5 emails that you want to receive the notification.
                            </p>

                            <p>
                                Next, in the  Subject field, that’s predefined and will be the subject of your email.
                            </p>

                            <p>
                                For the body of your Email you will have the Facebook data fields that will correspond to your form data. You’ll be able to choose which of those ‘data fields’ to add to your email notification.
                            </p>

                            <p>
                                I’ve just picked these data fields for example. You can also type and structure the email with flexibility.
                            </p>

                            <p>
                                Ideally you’d have their contact details in the form which you’d be able to select as a ‘data field’ in your email so that you can call the lead immediately.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation14.png" alt="" />

                            <p>
                                Then let’s scroll down to fill in the next fields.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation15.png" alt="" />

                            <p>
                                I usually leave the ‘Attachment’ blank.
                            </p>

                            <p>
                                ‘From Name’ will be the name of the ‘Sender’.
                            </p>

                            <p>
                                I don't use the ‘Reply To’ function because these are notifications.
                            </p>

                            <p>
                                You can leave these on default too.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation16.png" alt="" />

                            <p>
                                Click continue.
                            </p>

                            <p>
                                Test action, here we are going to test our email notification is formatted correctly.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation17.png" alt="" />

                            <p>
                                Once the ‘Test action’ section opens, scroll to the bottom and click Test action/Retest action.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation18.png" alt="" />

                            <p>
                                After you click Test action you should receive a test email notification.
                            </p>

                            <p>
                                You can keep editing and Retesting until you are happy with the format.
                            </p>

                            <p>
                                Once you are happy, it’s time to ‘Turn on Zap’.
                            </p>


                            <p className="bold">c. Slack</p>

                            <p>
                                If you work with a team, Slack is a great open, it’s what I would use. You can even dedicate it to come to a specific channel.
                            </p>

                            <p>
                                In order to setup this one just we need to do it on their website
                            </p>

                            <a href="https://zapier.com" target="_blank" rel="noopener noreferrer">
                                Zapier.com
                            </a>

                            <p>
                                You’ll need to create an account or login if you already have an account.
                            </p>

                            <p>
                                Once you’ve done that you’ll be taken to your dashboard
                            </p>

                            <a href="https://zapier.com/app/dashboard" target="_blank" rel="noopener noreferrer">https://zapier.com/app/dashboard</a>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation19.png" alt="" />

                            <p>
                                Click the ‘Make A Zap’ button on the top left.
                            </p>

                            <p>
                                First thing’s first, on the top left, name the zap something relevant. I named it Facebook Leads Notification.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation20.png" alt="" />

                            <p>
                                Now type in Facebook
                            </p>

                            <p>
                                Select the Facebook Lead Ads
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation21.png" alt="" />

                            <p>
                                When it loads it will ask you to select a Trigger event.
                            </p>

                            <p>
                                Choose ‘New Lead’ like this:
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation22.png" alt="" />

                            <p>
                                Click continue.
                            </p>

                            <p>
                                Then you’ll need to choose the Facebook Ad account, like this:
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation23.png" alt="" />

                            <p>
                                Then click continue.
                            </p>

                            <p>
                                Now we need to set up the trigger. Select the page you are generating leads for and below it, select the relevant lead form.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation24.png" alt="" />

                            <p>
                                Click ‘Continue’ and then ‘Test trigger’ like before.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation25.png" alt="" />

                            <p>
                                Now we are done setting up the trigger.
                            </p>

                            <p>
                                It’s time to set up the ‘Action’. At the bottom click the ‘Action’ section where it says Google Sheets. Set by default so we need to change it.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation26.png" alt="" />

                            <p>
                                Once it opens, change it from Google sheets to Slack.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation27.png" alt="" />

                            <p>
                                Now you need to choose an ‘Action Event’.
                            </p>

                            <p>
                                Click the dropdown and you’ll see all these options!
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation28.png" alt="" />

                            <p>
                                Now it’s  really your choice which option you choose and the setup is similar.
                            </p>

                            <p>
                                I like to send the notifications to a dedicated channel in Slack, that way when a lead comes through, it’s a team win and one of the members can get to it if you’re not around.
                            </p>

                            <p>
                                I’m going to show you how to set it up like that.
                            </p>

                            <p>
                                Choose ‘Send Channel Message’ like this:
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation29.png" alt="" />

                            <p>
                                Click Continue, then you will need to select your ‘Slack account’ from the dropdown, like this.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation30.png" alt="" />

                            <p>
                                Click ‘Continue’ and then we will need to fill in the details.
                            </p>

                            <p>
                                First, choose a channel and you want the notifications to go to.
                            </p>

                            <p>
                                Side note: I like to create a new slack channel dedicated to leads, whether it’s for you or your customers.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation31.png" alt="" />

                            <p>
                                I named the dedicated slack channel I created for the leads ‘outbound’ so that’s what I selected.
                            </p>

                            <p>
                                In the ‘Message Text’ field below, create the message you want to display in the slack channel when you get a lead.
                            </p>

                            <p>
                                This is how I set mine up, once again you will have custom form data fields that you can add to the slack message such as Name and Number.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation32.png" alt="" />

                            <p>
                                Send as a Bot, Yes.
                            </p>

                            <p>
                                Choose a relevant Bot Name.
                            </p>

                            <p>
                                This is how I set up the rest of the form. I just changed ‘Auto-expand links’ to no and not to ‘Include a link to this zap’.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation33.png" alt="" />

                            <p>
                                You can leave these on default too.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation34.png" alt="" />

                            <p>
                                Now click ‘Continue’.
                            </p>

                            <p>
                                Then click ‘Test & Continue’
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation35.png" alt="" />

                            <p>
                                Then finally ‘Turn on Zap’
                            </p>

                            <p>
                                You will now get a notification in your desired slack channel.
                            </p>

                            <p>
                                And that’s that for Zapier!
                            </p>

                            <div className="plug-block-subheading">
                                #2 Cheaper Method using Wyzebulb & Zapier
                            </div>

                            <p>
                                This method combines 2 applications, and uses Google Sheets, let’s dive in.
                            </p>

                            <p>
                                The previous methods, sent leads directly to your phone, email and slack. This method works by sending the leads to Google sheets first, then sends you a notification.
                            </p>

                            <p>
                                This way you have the lead recorded in Google sheets, a notification and is half the price.
                            </p>

                            <p className="bold">
                                Wyzebulb
                            </p>

                            <p>
                                First go to
                                {' '}
                                <a target="_blank" rel="noopener noreferrer" href="https://www.wyzebulb.com/">https://www.wyzebulb.com/</a>
                            </p>

                            <p>
                                Start by creating an account or signing in with your Google account.
                            </p>

                            <p>
                                You’ll then be redirected to this page. Type in the integrations as follows:
                            </p>

                            <p>
                                First ‘Facebook Lead Ads’ then the second one ‘ Google Sheets’ then click ‘Create Flow’.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation36.png" alt="" />

                            <p>
                                You’ll be taken to the next page, where you’ll have to select your action and trigger.
                            </p>

                            <p className="bold">Trigger Setup</p>

                            <p>
                                Let’s start with the ‘Trigger’ highlighted, click on it.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation37.png" alt="" />

                            <p>
                                Only one option will appear, ‘New Lead through Lead Ad form fill’ select that one.
                            </p>

                            <p>
                                Then click Continue & Save
                            </p>

                            <p>
                                Next we have to select our relevant ad account.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation38.png" alt="" />

                            <p>
                                Click it, and then proceed to ‘Connect Facebook Leads ads account’.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation39.png" alt="" />

                            <p>
                                Once that’s done you’ll need to set up event options.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation40.png" alt="" />

                            <p>
                                A pop-up will appear and fill in your relevant page and form using the dropdown. Then click save and continue.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation41.png" alt="" />

                            <p>
                                Last thing to do is test it. Click it, select the Test and click ‘Continue’.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation42.png" alt="" />

                            <p>
                                Now we are done with the ‘Trigger section’.
                            </p>

                            <p className="bold">
                                Action
                            </p>

                            <p>
                                First off, we need to choose the action we will be taking.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation43.png" alt="" />

                            <p>
                                A pop-up will appear, under ‘Select Google Sheets Action’
                            </p>

                            <p>
                                select the only option, which is ‘Create a new row in Google Sheets’.
                            </p>

                            <p>
                                Click ‘Save & Continue’.
                            </p>

                            <p>
                                Now, we need to select our account - click on it.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation44.png" alt="" />

                            <p>
                                After you’ve clicked ‘Account’, it will prompt you to sign into your relevant google account where your google sheet is stored.
                            </p>

                            <p>
                                Once that’s done, click save.
                            </p>

                            <p>
                                Next we need to set up the action template, click it to continue.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation45.png" alt="" />

                            <p>
                                Once it opens, you’ll need to select the spreadsheet you’re using for your leads from the dropdown, like below:
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation46.png" alt="" />

                            <p>
                                Next you’ll need to select the’Worksheet’ in that ‘Spreadsheet’, the ‘optional’ field I leave blank.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation47.png" alt="" />

                            <p>
                                Click Save & Continue
                            </p>

                            <p>
                                Lastly you have ‘Test’ it like before and then click ‘Save Flow’ in the top right corner.
                            </p>

                            <p>
                                Now we have Facebook sending leads to Google Sheets, now we need to send them as a notification to Slack.
                            </p>

                            <p>
                                Let’s head over to Zapier.
                            </p>

                            <p className="bold">Zapier</p>

                            <p>
                                Ok now we have to set up Zap.
                            </p>

                            <p>
                                Like before, go to zapier.com
                            </p>

                            <p>
                                Then click ‘Make A Zap’
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation48.png" alt="" />

                            <p>
                                First things first, we need to name the Zap in the top left corner.
                            </p>

                            <p className="bold">
                                Trigger Setup
                            </p>

                            <p>
                                Then we need to select a trigger. Find Google Sheets and click it.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation49.png" alt="" />

                            <p>
                                Next we must select the ‘Trigger event’.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation50.png" alt="" />

                            <p>
                                Select ‘New Spreadsheet Row’. If you are working out of Google Team Drive, select ‘New Spreadsheet Row (Team Drive)’
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation51.png" alt="" />

                            <p>
                                Then click continue.
                            </p>

                            <p>
                                Then you’ll be prompted to sign into the google account.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation52.png" alt="" />

                            <p>
                                Follow the prompts and sign in.
                            </p>

                            <p>
                                Next we can to finalize the trigger set up.
                            </p>

                            <p>
                                Select your relevant spreadsheet from the dropdown and then below it, select the exact worksheet that you used when setting up Wyzebulb.
                            </p>

                            <p>
                                The worksheet where your Facebook leads are going.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation53.png" alt="" />

                            <p>
                                Click ‘Continue’.
                            </p>

                            <p>
                                {' '}
                                Lastly, don’t forget to get your trigger. Click ‘Test trigger’.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation54.png" alt="" />

                            <p>
                                Once your test is complete it will take you to set up your Action.
                            </p>

                            <p className="bold">
                                Action Setup
                            </p>

                            <p>
                                Choose Slack from the predefined applications.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation55.png" alt="" />

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation27.png" alt="" />

                            <p>
                                Now you need to choose an ‘Action Event’.
                            </p>

                            <p>
                                Click the dropdown and you’ll see these lots of options!
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation28.png" alt="" />

                            <p>
                                Now it’s  really your choice which option you choose and the setup is similar.
                            </p>

                            <p>
                                I like to send it to a dedicated channel in slack, that way it’s a team win and one of the members can get to it if you’re not around.
                            </p>

                            <p>
                                I’m going to show you how to set it up like that.
                            </p>

                            <p>
                                Choose ‘Send Channel Message’ like this:
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation29.png" alt="" />

                            <p>
                                Click Continue, then you will need to select your ‘Slack account’ from the dropdown, like this.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation30.png" alt="" />

                            <p>
                                Click ‘Continue’ and then we will need to fill in the details.
                            </p>

                            <p>
                                First, choose a channel and you want the notifications to go to.
                            </p>

                            <p>
                                If you missed it I like to create a new slack channel dedicated to leads, whether it’s for you or your customers.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation31.png" alt="" />

                            <p>
                                I named the dedicated slack channel I created for the leads ‘outbound’ so that’s what I selected.
                            </p>

                            <p>
                                In the Message Text field below, create the message you want to display in the slack channel when you get a lead. You can type and enter predefined data fields from your Google Spreadsheet.
                            </p>

                            <p>
                                This is how I set mine up, once again you will have custom form data fields that you can add to the slack message such as Name and Number.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation32.png" alt="" />

                            <p>
                                Send as a Bot, Yes.
                            </p>

                            <p>
                                Choose a relevant Bot Name.
                            </p>

                            <p>
                                This is how I set up the rest of the form. I just changed ‘Auto-expand links’ to no and not to ‘Include a link to this zap’.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation33.png" alt="" />

                            <p>
                                You can leave these on default too.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation34.png" alt="" />

                            <p>
                                Now click ‘continue’.
                            </p>

                            <p>
                                Then click ‘Test & Continue’
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/leadAutomation35.png" alt="" />

                            <p>
                                You will now get a notification in your desired slack channel
                            </p>

                            <p>
                                Lastly just ‘Turn on Zap’.
                            </p>

                            <p>
                                And now you have automated lead flow so you’ll never miss a lead again!
                            </p>
                        </div>

                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
