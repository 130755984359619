import React, { useContext, useState, useEffect } from 'react';
import useStickyState from '../../utils/useStickyState';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import styles from './styles';
import { UserDataContext } from '../../providers/UserDataProvider';


const BootstrapInput = withStyles(theme => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
        maxWidth: 100,
    },
    input: {
     
        position: 'relative',
        backgroundColor: 'transparent',
        border: '2px solid white',
        color: 'white',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '150px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            'Inter',
        ].join(','),
        '&:focus': {
            borderRadius: '150px',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

const CurrencySelector = ({
    classes,
    ...props
}) => {
    const { userCurrency, setUserCurrency, allCurrencies } = useContext(UserDataContext);
    console.log("userCurrency", userCurrency)
    const [labelCurrency, setLabelCurrency] = useState(userCurrency);    

    useEffect(() => {
    
    if(userCurrency === '£') {
        setLabelCurrency('£ GBP')
    } else if(userCurrency === '$') {
         setLabelCurrency('$ USD')
    } else if(userCurrency ==='€') {
        setLabelCurrency('€ EUR')
    }
}, [userCurrency])

    return (
        <div className={classes.container}>
            <div className={classes.spacer} />
            <FormControl className={classes.currencySelector} {...props}>
{/*                <InputLabel style={{color: "white"}} id="demo-customized-select-label">Currency</InputLabel>
*/}                <Select
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                    value={labelCurrency}
                    onChange={event => {
                        setUserCurrency(event.target.value.charAt(0))
                        // setLabelCurrency(event.target.value)
                    }}
                    input={<BootstrapInput className="text-white" />}
                >
                    {allCurrencies.map((symbol, i) => (
                        <MenuItem key={i} style={{ maxHeight: 40 }} value={symbol}>{symbol}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

export default withStyles(styles)(CurrencySelector);
