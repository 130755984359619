import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        available: true,
        sectionName: 'Introduction:',
    },
    {
        available: true,
        sectionName: 'What is direct response advertising? ',
    },
    {
        available: true,
        sectionName: 'Direct Response Advertising Style',
    },
    {
        available: true,
        sectionName: 'Headlines',
    },
    {
        available: true,
        sectionName: 'Body Copy',
    },
];

const title = 'Copywriting: The Fundamentals';

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                // selectedSection={selectedSection}
                                // setSelectedSection={onSelectSection}
                                // filter={filter}
                                // selectFilter={selectFilter}
                                // subFilter={subFilter}
                                // selectSubFilter={selectSubFilter}

                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]} className="plug-block">
                            <h2 className="mb-4">{title}</h2> 
                            <div className="plug-block-subheading">Introduction:</div>

                            <p>
                                Copywriting is one of the most essential skills a digital marketer can master. It’s also hugely-misunderstood and constantly misrepresented.
                            </p>

                            <p>
                                Advertising and media is one of the few industries where the end output is consumed directly - and constantly - by the general public. The result is that most people have an opinion on advertising, what it is, what makes good or ‘effective’ advertising.
                            </p>

                            <p>
                                The problem comes as the advertising that most people are exposed to is big-budget, brand awareness advertising from Nike, Coca Cola, Apple and Mercedes. They expect advertising to be brazen, showy, intricately-designed, and full of witty slogans and catchphrases.
                            </p>

                            <p>
                                The result is that when smaller businesses advertise, they are expected to match these adverts. But what very few laypeople (people who don’t work in marketing) realise is that this style of advertising is extremely ineffective.
                            </p>

                            <p>
                                Whilst the companies mentioned above are some of the most valuable - and recognisable - in the world, this comes at great expense. It is almost impossible to replicate without huge investment and decades of reinforcement.
                            </p>

                            <p>
                                Another style of advertising - the one that we will cover here - is called direct response advertising. And when we talk about ‘copywriting’ we will be referring to copywriting in the style of direct response advertising.
                            </p>
                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">
                                What is direct response advertising?
                            </div>

                            <p>
                                The clue is in the name: it can elicit a direct response from the consumer. For example, an advert which requests a user to call a number, use a discount code, or click on a link, is a direct response advert.
                            </p>

                            <p>
                                For example, this advert from Samsung:
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/copywriting1.png" alt="" />

                            <p>
                                This is not a direct-response advert. Although users technically can click on the link to find more about the product, this does not lead users down any path to purchasing the product - the Samsung website is not a retail website.
                            </p>

                            <p>
                                Compare it with this advert, also from Samsung:
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/copywriting2.png" alt="" />

                            <p>
                                There is a clear response to this advert: to shop for this television, and to claim £500 cashback.
                            </p>
                        </div>

                        <div ref={refs[2]} className="plug-block">
                            <div className="plug-block-subheading">Direct Response Advertising Style</div>

                            <p>
                                Direct response advertising copywriting is usually similar to editorial copy in style: with prominent headlines, static images, long-form body text and a clear call to action.
                            </p>

                            <p>
                                For example:
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/copywriting3.png" alt="" />

                            <p>
                                Note: it is possible for an advert to be direct response in nature, but not style.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/copywriting4.png" alt="" />
                        </div>

                        <div ref={refs[3]} className="plug-block">
                            <div className="plug-block-subheading">
                                Headlines
                            </div>

                            <p>
                                The headline is the most important element of any advert.
                            </p>

                            <p>
                                There are multiple statistics that float around stressing their importance but the most important to acknowledge is that more than five times as many people will read your headline as they will your body copy (the long form copy).
                            </p>

                            <p>
                                The importance of headlines was well understood in classic direct-response copywriting in traditional media such as newspapers or magazines.
                            </p>

                            <p>
                                Initially, much of advertising on the internet took the form of display ads with flashing banners and simplistic slogans. It was actually Facebook that revolutionised the approach to online advertising.
                            </p>

                            <p>
                                Prior to 2004, adverts on the platform were called flyers and looked like this:
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/copywriting5.png" alt="" />

                            <p>
                                In 2007, these were replaced with the adverts which we still see today. These resemble traditional print advertising with a headline, creative and copy.
                            </p>

                            <p>
                                This is fantastic news for copywriters as we are able to draw on the experience of decades of copywriting expertise to create compelling headlines.
                            </p>

                            <p>
                                Whilst there are hard and fast rules to writing headlines, and included are examples of successful headlines used by IAG Media, it’s also important to understand the theory of a good headline.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/copywriting6.png" alt="" />

                            <p>
                                The best headlines on the internet are to be found at the bottom of newspaper or magazine articles, hosted on Taboola or Outbrain. Whilst they are -rightly - labelled as clickbait, they remain the most effective and efficient way of driving traffic to websites.
                            </p>

                            <p>
                                The majority of these headlines use a simple technique in copywriting known as the ‘curiosity gap’.
                            </p>

                            <p>
                                These headlines tease you with a suggestion of something unusual, exciting, interesting or counterintuitive but do not satisfy this curiosity. Instead, they leave you with only one option if you wish to ‘close the gap’: click the link.
                            </p>

                            <p>
                                Obviously, copywriting for Facebook advertising, emails or other platforms differs. ‘Clickbait’ only works in getting people to click on a link in the first place. There is also a professional requirement not to appear disingenuous, breach brand guidelines - and to avoid being banned on the platform.
                            </p>

                            <p>
                                Instead, headlines have an important purpose in Facebook advertising: to draw attention to the body copy, and, ultimately, the CTA.
                            </p>

                            <p>
                                Consider the following headlines:
                            </p>

                            <div className="examples-block">
                                <ul>
                                    <li>
                                        This ‘weird’ trick stops [Problem]
                                    </li>
                                    <li>
                                        Why you don’t hear this anymore
                                    </li>
                                    <li>
                                        Why you should NEVER do this
                                    </li>
                                    <li>
                                        Warning: [Instruction/ Warning]
                                    </li>
                                    <li>
                                        At Last, The Secret to [Desired outcome]
                                    </li>
                                    <li>Little-Known [Industry] Hack Leads To [Success]</li>
                                </ul>
                            </div>

                            <p />

                            <p>
                                There are other methods or styles that can be used to write headlines. Problem solving or how-to headlines are popular and appear more editorial than promotional: users would rather be informed or entertained than be sold to. For example:
                            </p>

                            <div className="examples-block">
                                <ul>
                                    <li>
                                        How to [fix/stop] [customer problem]
                                    </li>
                                    <li>
                                        The ULTIMATE guide to [customer problem]
                                    </li>
                                    <li>
                                        3 Ways to STOP [customer problem]
                                    </li>
                                    <li>
                                        THIS stops [customer problem]
                                    </li>
                                    <li>
                                        How to [fix problem] in 5 Minutes
                                    </li>
                                    <li>
                                        The easiest way to [fix problem]
                                    </li>
                                    <li>
                                        Here’s What [Your Customers] Are Doing To Save Thousands
                                    </li>
                                    <li>
                                        How You Can [Stop problem] Almost Instantly
                                    </li>
                                    <li>
                                        How to get [solution] when you’re [customer’s position]
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div ref={refs[4]} className="plug-block">
                            <div className="plug-block-subheading">Body Copy</div>

                            <p>
                                Broadly speaking, long-form copy outperforms short-form copy. There are limited circumstances where the opposite is true: for example in an aggressive retargeting campaign where users are further down the funnel, or where discounts are more likely to garner attention: for example Black Friday.
                            </p>

                            <p>
                                However, long form copy is difficult to write. Unlike short, snappy slogans, long form copy requires thought and consideration.
                            </p>

                            <p>
                                For experienced copywriters, writing long form copy often comes naturally. Others may prefer a formula in order to write the body copy. For example:
                            </p>

                            <p>
                                HOOK
                            </p>
                            <p>Benefit</p>
                            <p>
                                Differentiator
                            </p>
                            <p>
                                Scarcity/ Justification
                            </p>
                            <p>CTA</p>

                            <p className="bold">
                                Let’s look at this in action:
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/copywriting7.png" alt="" />

                            <div className="examples-block">
                                <p className="bold">Hook</p>

                                <p>Most people who get mugged don’t even know it 😱</p>

                                <p>The truth is that the same technology designed to make our lives easier - contactless credit cards, ePassports, and Apple or Google Pay…</p>

                                <p>
                                    … also makes us incredibly vulnerable to identity theft, and financial fraud.
                                </p>

                                <p>
                                    That’s because most modern cards and ID are fitted with ‘RFID’ transmitters: a tiny chip that transmits your data wirelessly over a short distance.
                                </p>

                                <p>
                                    Whereas yesterday's criminals would have to physically possess your wallet, purse, cards, cash and ID to steal from you, now all it takes is a simple scanner that’s available on eBay.
                                </p>
                            </div>

                            <p>
                                The hook comes first: after the headline, it’s the first thing people will read, and it has an important job: to get people to continue to read.
                            </p>

                            <p>
                                The technique of grabbing attention here is similar to that of a headline: a curiosity gap.
                            </p>

                            <p>
                                Hooks can be dramatic, counter-intuitive, emotional or even offensive. The trick is to get the reader to… keep reading.
                            </p>

                            <div className="examples-block">
                                <p className="bold">Benefit</p>
                                <p>
                                    But now there’s a simple solution. Bluebridge’s quality wallets contain an innovative mesh filter that prevents your cards being cloned and your identity stolen.
                                </p>
                                <p>
                                    Because the mesh is so fine, it’s impossible to tell the difference between this, and a normal wallet.
                                </p>
                            </div>

                            <p>
                                In this advert, the hook has done a great job of introducing you to a problem you didn’t even know you had. The benefit is therefore clear: that the wallets can stop you from having your information stolen.
                            </p>

                            <p>
                                If this advert were about a skincare product, this would be where you would explain how well it moisturises your skin - and why.
                            </p>

                            <div className="examples-block">
                                <p className="bold">
                                    Differentiator
                                </p>

                                <p>Made with full-grain, Italian leather, you’d want this in your pocket even if it didn’t protect your identity. </p>
                            </div>

                            <p>The differentiator’s role is to subtly implant in the reader’s head that your product is better than anyone else’s. This is difficult in this piece as it is largely for a cold audience, i.e., people not familiar with RFID-blocking technology. </p>

                            <p>
                                But mentioning that the wallets are full-grain leather does a similar job.
                            </p>

                            <div className="examples-block">
                                <p className="bold">
                                    Scarcity/ Justification
                                </p>

                                <p>
                                    Right now, Blueridge have an amazing 30% off their entire range of RFID-blocking wallets!
                                </p>

                            </div>

                            <p>
                                Scarcity is one of the oldest techniques in marketing. You can use this when you have a limited-time only sale, or where there are a limited number of items.
                            </p>

                            <p>
                                Justification is similar: in this instance, the justification (reason for the advert) is that there’s a 30% sale.
                            </p>

                            <div className="examples-block">
                                <p className="bold">CTA</p>
                                <p>
                                    Browse the range and protect yourself here:
                                </p>

                                <a href="http://www.blueridge.com/rfid-blockers" target="_blank" rel="noopener noreferrer">www.blueridge.com/rfid-blockers</a>
                            </div>

                            <p>
                                The call-to-action is simple: you’re giving a reason for people to click through to your website.
                            </p>

                            <p>
                                Here, ‘Browse the range’ is used to make clicking through non-committal. Whilst ‘protect yourself’ carries an emotive element: it would be foolish not to click this link.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
