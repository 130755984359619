import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '@genflow/web-auth';
import { Redirect, useHistory } from 'react-router';
import axios from 'axios';
import { useCountries } from 'use-react-countries';
import { sortBy } from 'lodash';
import queryString from 'query-string';
import { v1 as uuidv4 } from 'uuid';
import { Auth } from 'aws-amplify';
import { useConfig } from '@genflow/core';
import { UserIcon, LockClosedIcon, MailIcon } from '@heroicons/react/outline';
import InstagramIcon from '@material-ui/icons/Instagram';
import { trackEvent } from '../../../utils/useAnalytics';
import useStickyState from '../../../utils/useStickyState';
import { getToken, loginPasswordless } from '../../../utils/auth';
import config from '../../../config';
import env from '../../../env';
import LoadingButton from '../../../components/LoadingButton';
import { selectSubscription } from '../../../utils/stripe';
import CodeInput from './components/CodeInput';
import countries from '../../../constants/registration/countriesData.json';


export default ({ location }) => {
    const defaultFormData = queryString.parse(location?.search);
    const { vendorUuid } = useConfig();
    const { useIsLoggedIn } = useAuth();
    const isLoggedIn = useIsLoggedIn(true);
    const [registering, setRegistering] = useState(false);
    const [firstName, setFirstName] = useStickyState(defaultFormData?.firstName || '', 'registerFirstName', false);
    const [lastName, setLastName] = useStickyState(defaultFormData?.lastName || '', 'registerLastName', false);
    const [emailAddress, setEmailAddress] = useStickyState(defaultFormData?.email || '', 'emailAddress', false);
    const [confirmEmailAddress, setConfirmEmailAddress] = useState('');
    const [phoneNumber, setPhoneNumber] = useStickyState(defaultFormData?.phone || '', 'phoneNumber', false);
    const [instagram, setInstagram] = useStickyState(defaultFormData?.instagram || '', 'instagram', false);
    const [validationMessage, setValidationMessage] = useState(null);
    const [unverifiedUser, setUnverifiedUser] = useState(null);
    const [codeValue, setCodeValue] = useState();
    const [borderColor1, setBorderColor1] = useState('#434343');
    const [borderColor2, setBorderColor2] = useState('#434343');
    const [borderColor3, setBorderColor3] = useState('#434343');
    const [borderColor4, setBorderColor4] = useState('#434343');
    const [selectedCountry, setSelectedCountry] = useState('United Kingdom');
    const activeCountry = useMemo(() => countries?.length && countries?.find(({ name }) => name === selectedCountry), [countries, selectedCountry]);

    if (isLoggedIn === null) return null;

    if (isLoggedIn && !registering && !unverifiedUser) {
        selectSubscription();
    }

    const handleRegister = async () => {
        setValidationMessage('');
        if (!emailAddress.replace(/\s+/g, '') || !confirmEmailAddress.replace(/\s+/g, '')
        || !firstName.replace(/\s+/g, '') || !lastName.replace(/\s+/g, '') || !phoneNumber.replace(/\s+/g, '')) {
            setValidationMessage('You must input all fields before creating an account.');
            return;
        }

        if (firstName.replace(/\s+/g, '')?.length < 3 || lastName.replace(/\s+/g, '')?.length < 3) {
            setValidationMessage('First and last names require at least 3 characters.');
            return;
        }

        if (emailAddress !== confirmEmailAddress) {
            setValidationMessage('Your emails does not match.');
            return;
        }

        try {
            if (!!unverifiedUser && !!codeValue) {
                setRegistering(true);
                try {
                    await Auth.sendCustomChallengeAnswer(
                        unverifiedUser, codeValue,
                    );
                    // This will throw an error if the user is not yet authenticated:
                    await Auth.currentSession();
                    await selectSubscription();
                } catch (error) {
                    console.log(error);
                    if (error.code === 'NotAuthorizedException') {
                        setValidationMessage('Your code seems to have expired, please try again.');
                        setUnverifiedUser(null);
                    } else {
                        setValidationMessage('Your code seems to be incorrect!');
                        console.warn(error);
                    }
                }
            } else {
                setRegistering(true);
                const password = uuidv4();

                const email = emailAddress.toLowerCase().trim();
                const emailWithVendor = `${email}#${vendorUuid}`;

                const response = await Auth.signUp({
                    password,
                    username: emailWithVendor,
                    attributes: {
                        email,
                        given_name: firstName,
                        family_name: lastName,
                    },
                });

                trackEvent({
                    action: 'User_Registered',
                    category: 'Users',
                });
                try {
                    const customer = {
                        email,
                        firstName,
                        lastName,
                        phoneNumber: phoneNumber?.includes(activeCountry?.countryCallingCode) ? phoneNumber : `${activeCountry?.countryCallingCode}${phoneNumber}`,
                        instagram,
                    };

                    await axios.post('/.netlify/functions/register', customer);
                } catch (e) {
                    console.log('Register failed');
                }


                const passwordLoginResponse = await loginPasswordless(email).catch((error) => {
                    setRegistering(false);
                    setValidationMessage(error);
                });

                setUnverifiedUser(passwordLoginResponse);
            }
            setRegistering(false);
        } catch (err) {
            if (err?.code === 'UsernameExistsException') {
                setValidationMessage('This email already exists, why not login instead?');
            } else {
                console.log(err);
                setValidationMessage('Registration failed!');
            }
            setRegistering(false);
        }
    };

    const clearValidationMessage = () => {
        if (validationMessage) setValidationMessage(null);
    };

    const canRegister = unverifiedUser
        ? codeValue?.length === 6
        : (
            !!firstName
            && !!lastName
            && !!emailAddress
            && !!confirmEmailAddress
            && !!phoneNumber
            && emailAddress === confirmEmailAddress
        );

    const handleChange = (event) => {
        setSelectedCountry(event.target.value);
    };

    const handleKeyPress = (event) => {
        const pattern = /[0-9]/;
        const inputChar = String.fromCharCode(event.charCode);
        if (!pattern.test(inputChar)) {
            event.preventDefault();
        }
    };

    return (
        <div className="flex flex-col">
            <div className="mx-auto p-16">
                <img
                    alt="genflow"
                    src="../Logo.svg"
                />
            </div>
            <h2 className="text-white my-7 text-center">Create an account</h2>

            <div style={{ width: '378px' }} className="mx-auto grey800Bg text-white">
                <div className="flex flex-col p-4" role="form">
                    <div className="flex flex-row items-center inputBox w-full mt-4" style={{ borderBottomColor: borderColor1 }}>
                        <UserIcon className="h-5 w-5 mb-2 mr-4" style={{ color: borderColor1 }} aria-hidden="true" />
                        <input
                            disabled={!!unverifiedUser}
                            placeholder="First Name (Required)"
                            type="text"
                            className="border border-transparent bg-transparent pb-2 body3 w-full outlineRemoved"
                            onClick={() => {
                                setBorderColor1('white');
                                setBorderColor2('#434343');
                                setBorderColor3('#434343');
                                setBorderColor4('#434343');
                            }}
                            value={firstName}
                            onChange={(e) => {
                                clearValidationMessage();
                                setFirstName(e.target.value);
                            }}
                        />
                    </div>
                    <div className="flex flex-row items-center inputBox w-full mt-4" style={{ borderBottomColor: borderColor2 }}>
                        <UserIcon className="h-5 w-5 mb-2 mr-4" style={{ color: borderColor2 }} aria-hidden="true" />
                        <input
                            disabled={!!unverifiedUser}
                            placeholder="Last Name (Required)"
                            type="text"
                            className="border border-transparent bg-transparent pb-2 body3 w-full outlineRemoved"
                            onClick={() => {
                                setBorderColor1('#434343');
                                setBorderColor2('white');
                                setBorderColor3('#434343');
                                setBorderColor4('#434343');
                            }}
                            value={lastName}
                            onChange={(e) => {
                                clearValidationMessage();
                                setLastName(e.target.value);
                            }}
                        />
                    </div>
                    <div className="flex flex-row items-center inputBox w-full mt-4" style={{ borderBottomColor: borderColor3 }}>
                        <MailIcon className="h-5 w-5  mb-2 mr-4" style={{ color: borderColor3 }} aria-hidden="true" />
                        <input
                            disabled={!!unverifiedUser}
                            placeholder="Email (Required)"
                            type="emailAddress"
                            className="border border-transparent bg-transparent pb-2 body3 w-full outlineRemoved"
                            onClick={() => {
                                setBorderColor1('#434343');
                                setBorderColor2('#434343');
                                setBorderColor3('white');
                                setBorderColor4('#434343');
                            }}
                            value={emailAddress}
                            onChange={(e) => {
                                clearValidationMessage();
                                setEmailAddress(e.target.value);
                            }}
                        />
                    </div>
                    <div className="flex flex-row items-center inputBox w-full mt-4" style={{ borderBottomColor: borderColor4 }}>
                        <LockClosedIcon className="h-5 w-5  mb-2 mr-4" style={{ color: borderColor4 }} aria-hidden="true" />
                        <input
                            disabled={!!unverifiedUser}
                            placeholder="Confirm Email (Required)"
                            type="emailAddress"
                            className="border border-transparent bg-transparent pb-2 body3 w-full outlineRemoved"
                            onClick={() => {
                                setBorderColor1('#434343');
                                setBorderColor2('#434343');
                                setBorderColor3('#434343');
                                setBorderColor4('white');
                            }}
                            value={confirmEmailAddress}
                            onChange={(e) => {
                                clearValidationMessage();
                                setConfirmEmailAddress(e.target.value);
                            }}
                        />
                    </div>
                    <div className="flex flex-row items-center inputBox w-full mt-4" style={{ borderBottomColor: borderColor4 }}>
                        <div className="pb-2 relative flex flex-row">
                            <select className="w-8 bg-transparent absolute outline-0 text-transparent" value={selectedCountry} onChange={handleChange}>
                                {
                                sortBy(countries, 'name')?.map(item => (
                                    <option value={item?.name}>
                                        {item?.name}
                                    </option>
                                ))
                                }
                            </select>
                            <img src={activeCountry?.flags?.png} alt="React Logo" className="w-5 h-5 object-contain" />
                            <p className="ml-3 body3">
                                {`(${activeCountry?.countryCallingCode})`}
                            </p>
                        </div>
                        <input
                            disabled={!!unverifiedUser}
                            placeholder="Phone Number (Required)"
                            type="text"
                            className="border border-transparent bg-transparent pb-2 body3  outlineRemoved ml-1"
                            onClick={() => {
                                setBorderColor1('#434343');
                                setBorderColor2('#434343');
                                setBorderColor3('#434343');
                                setBorderColor4('white');
                            }}
                            value={phoneNumber}
                            onChange={(e) => {
                                clearValidationMessage();
                                setPhoneNumber(e.target.value);
                            }}
                            onKeyPress={handleKeyPress}
                        />
                    </div>
                    <div className="flex flex-row items-center inputBox w-full mt-4" style={{ borderBottomColor: borderColor3 }}>
                        <InstagramIcon className="h-5 w-5 mb-2 mr-3" style={{ color: borderColor2 }} aria-hidden="true" />
                        <input
                            disabled={!!unverifiedUser}
                            placeholder="@Instagram username"
                            type="text"
                            className="border border-transparent bg-transparent pb-2 body3 w-full outlineRemoved"
                            onClick={() => {
                                setBorderColor1('#434343');
                                setBorderColor2('#434343');
                                setBorderColor3('white');
                                setBorderColor4('#434343');
                            }}
                            value={instagram}
                            onChange={(e) => {
                                clearValidationMessage();
                                setInstagram(e.target.value);
                            }}
                        />
                    </div>
                    {
                        !!unverifiedUser && <CodeInput onChange={setCodeValue} />
                    }

                    {
                        !!validationMessage && (
                            <div className="text-center text-red mt-3 mb-3 small">
                                {validationMessage}
                            </div>
                        )
                    }
                    <div className="text-center">
                        <LoadingButton
                            className="createButton body1 mt-4"
                            loading={registering}
                            disabled={!canRegister}
                            color="primary"
                            type="button"
                            onClick={handleRegister}
                        >
                            Create Account
                        </LoadingButton>
                    </div>
                </div>
            </div>
        </div>
    );
};
