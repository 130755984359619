import React from 'react';

// reactstrap components
import {
    Button,
    Card,
    Container,
    Row,
    Col,
    CardBody,
} from 'reactstrap';

import Footer from '../../components/Footer';

const CourseOverview = () => (
    <>
        <main className="course-overview-page" ref="main">


            <section className="section-profile-cover section-shaped my-0">
                <Container>
                    <Row>
                        <Col lg={12} className="text-center">
                            <h1>Six Figure SMMA</h1>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="section section-lg pt-lg-0 mt--4">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg="4">

                            <Card className="shadow border-0">
                                <div className="cardheader">
                                    <img alt="card header" src="https://cdn-ippei.pressidium.com/wp-content/uploads/2020/04/AIbyIG-logo-02.png" />
                                    {/* <div className="lock">
                            <i class="fa fa-lock"></i>
                          </div> */}
                                </div>

                                <CardBody className="py-4">

                                    <h6 className="text-default text-uppercase">
                                        Agency Incubator
                                    </h6>
                                    <p className="description mt-3">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    </p>
                                    <hr />
                                    <h6 className="text-default text-uppercase">
                                        Course Summary
                                    </h6>
                                    <ul className="description">
                                        <li>6 modules</li>
                                        <li>560 minutes</li>
                                        <li>120 videos</li>
                                    </ul>
                                    <Button
                                        className="mt-4"
                                        color="primary"
                                        herf="#"
                                        // to="/course" tag={Link}
                                    >
                                        Buy Now - $991.00
                                    </Button>
                                </CardBody>
                            </Card>

                        </Col>
                        <Col lg="8">


                            <h4 className="mb-4">Course preview</h4>

                            <div className="course-overview-list list-group list-group-chat list-group-flush">

                                <div className="module-title">
                                    <h4>Week 1: Foundations</h4>
                                </div>
                                {
                                    [0, 0, 0, 0, 0, 0, 0, 0].map(() => (
                                        <span className="list-group-item">
                                            <div className="media">
                                                <div className="media-body ml-2">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <h6 className="mb-0">Lecture name</h6>
                                                        <div>
                                                            <button type="button" rel="tooltip" className="btn btn-success btn-icon btn-sm " data-original-title="" title="">
                                                                <i className="fa fa-play" />
                                                                {' '}
                                                                FREE
                                                            </button>
                                                            <small className="text-muted">
                                                                <i className="fa fa-play-circle" />
                                                                {' '}
                                                                10:27
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </span>
                                    ))
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </main>
        <Footer />
    </>
);


export default CourseOverview;
