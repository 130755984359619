import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-responsive-modal';
import { Button, Card, Input } from 'reactstrap';
import './style.scss';
import { useMutation } from '@apollo/react-hooks';
import { useConfig } from '@genflow/core';
import Select from 'react-select';
import { trackEvent } from '../../../../../../utils/useAnalytics';
import CreateUpdateClientCustomerMutation from '../../../../../../api/createUpdateClientCustomer';
import deleteClientCustomerMutation from '../../../../../../api/deleteClientCustomer';
import GetClientCustomersQuery from '../../../../../../api/getClientCustomers';
import LoadingButton from '../../../../../../components/LoadingButton';


const options = [
    { label: 'eCommerce', value: 'eCommerce' }, 
    { label: 'Real Estate', value: 'Real Estate' }, 
    { label: 'Restaurants', value: 'Restaurants' }, 
    { label: 'Gyms', value: 'Gyms' }, 
    { label: 'Personal Trainers', value: 'Personal Trainers' }, 
    { label: 'Business Consultants', value: 'Business Consultants' }, 
    { label: 'Medical/Health', value: 'Medical/Health' }, 
    { label: 'Spa, In-Person Treatments', value: 'Spa, In-Person Treatments' }, 
    { label: 'Dental', value: 'Dental' }, 
    { label: 'Legal', value: 'Legal' }, 
    { label: 'Watch Dealers', value: 'Watch Dealers' }, 
    { label: 'Home Improvement', value: 'Home Improvement' }, 
    { label: 'Automotive ', value: 'Automotive ' }, 
    { label: 'Car Dealerships', value: 'Car Dealerships' }, 
    { label: 'Pest Control', value: 'Pest Control' }, 
    { label: 'SaaS', value: 'SaaS' }, 
    { label: 'Info Products', value: 'Info Products' }, 
    { label: 'Publishing', value: 'Publishing' }, 
    { label: 'Hotels', value: 'Hotels' }, 
    { label: 'Publishing', value: 'Publishing' }, 
    { label: 'Home Improvement', value: 'Home Improvement' },
    { label: 'Other', value: 'Other' }
];

const NewClientModal = ({ open, onClose, client, setClient }) => {
    const { vendorUuid } = useConfig();
    const [borderColor1, setBorderColor1] = useState("#434343");
    const [borderColor2, setBorderColor2] = useState("#434343");
    const [clientName, setClientName] = useState('');
    const [niche, setNiche] = useState(null);
    const [createUpdateClientCustomer, { loading }] = useMutation(CreateUpdateClientCustomerMutation, {
        refetchQueries: [{
            query: GetClientCustomersQuery,
            variables: {
                vendorUuid,
            },
        }],
    });
    const [deleteClientCustomer] = useMutation(deleteClientCustomerMutation, {
        refetchQueries: [{
            query: GetClientCustomersQuery,
            variables: {
                vendorUuid,
            },
        }],
    });

    const onCreateClient = async () => {
        if (!clientName?.replace(/\s+/g, '')) {
            return alert('You must provide a client name!');
        }

        const response = await createUpdateClientCustomer({
            variables: {
                vendorUuid,
                clientCustomer: {
                    clientCustomerName: clientName,
                    niche: niche?.value,
                },
            },
        });

        trackEvent({
            action: 'Add_Client',
            category: 'Client_Name',
            label: clientName,
        });

        if (!response?.data?.errors) {
            setClientName('');
            setNiche(null);
            onClose();
        }
    };

    const onEditClient = async () => {
        if (!clientName?.replace(/\s+/g, '')) {
            return alert('You must provide a client name!');
        }
        const response = await createUpdateClientCustomer({
            variables: {
                vendorUuid,
                clientCustomer: {
                    id: client?.id,
                    clientCustomerName: clientName,
                    niche: niche?.value,
                },
            },
        });
        trackEvent({
            action: 'Edit_Client',
            category: 'Client_Name',
            label: clientName,
        });
        if (!response?.data?.errors) {
            setClientName('');
            setNiche(null);
            setClient(null);
            onClose();
        }
    };

    const onClientDelete = async () => {
        const response = await deleteClientCustomer({
            variables: {
                vendorUuid,
                clientCustomerUuid: client?.id,
            },
        });
        trackEvent({
            action: 'Delete_Client',
            category: 'Client_Name',
            label: clientName,
        });
        if (!response?.data?.errors) {
            setClientName('');
            setNiche(null);
            setClient(null);
            onClose();
        }
    };

    useEffect(() => {
        if (client) {
            setClientName(client?.clientCustomerName);
            setNiche(options?.find(item => item?.value === client?.niche));
        }
    }, [client]);

    return (
        <Modal
            open={!!open}
            onClose={onClose}
            center
            className="grey800Bg"
        >
            <div

                style={{
                // padding: '1rem'
                }}
            >
                <div
                    className="clientTitle"

                >
                    {client ? 'Edit Client' : 'New Client?'}
                </div>
                <Input
                    placeholder="Client Name..."
                    className="border border-transparent bg-transparent pb-2 body3 w-full inputBox pl-3 placeholderText"
                    type="text"
                    value={clientName}
                    style={{
                        margin: '1rem 0',
                        borderBottomColor: borderColor1,

                    }}
                    onChange={e => setClientName(e.target.value)}
                    onClick={() => {
                        setBorderColor1("white")
                        setBorderColor2("#434343")
                    }}
                />
                <Select
                    value={niche}
                    onChange={newNiche => setNiche(newNiche)}
                    className="border border-transparent  pb-2 body3 w-full inputBox"
                    options={options}
                    styles={{
                        menuPortal: base => ({ ...base, zIndex: 9999, color:'white'  }),
                        menu: (provided, state) => ({
                            ...provided,
                            backgroundColor: '#373737',
                        }),
                        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                            return {
                              ...styles,
                              backgroundColor: isDisabled
                                ? undefined
                                : isSelected
                                ? '#1C1C1C'
                                : isFocused
                                ? '#1C1C1C'
                                : undefined,
                              color: isDisabled
                                ? '#ccc'
                                : isSelected,
                                
                              cursor: isDisabled ? 'not-allowed' : 'default',

                              ':active': {
                                ...styles[':active'],
                                backgroundColor: !isDisabled
                                  ? isSelected
                                    ? '#1C1C1C'
                                    : "blue"
                                  : undefined,
                              },
                            };
                          },
                      
                    }}
                    menuPortalTarget={document.body}
                    style={{
                        borderBottomColor: borderColor2,


                    }}
                />
                <div className='flex row center'>
                    <LoadingButton
                        loading={loading}
                        disabled={!clientName}
                        color="primary"
                        type="button"
                        onClick={client ? onEditClient : onCreateClient}
                        style={{
                            margin: '1rem 0',
                        }}
                        className="blkButton h-fit px-4 text-sm py-1"
                    >
                        {client ? 'Update Client' : 'Add Client'}
                    </LoadingButton>
                    <div className='h-4 w-2'/>
                    {client && (
                        <LoadingButton
                            loading={loading}
                            color="primary"
                            type="button"
                            onClick={onClientDelete}
                            style={{
                                margin: '1rem 0',
                            }}
                            className=" h-fit px-4 text-sm py-1 ml-2"
                        >
                            Delete  Client
                        </LoadingButton>
                    )}
                </div>
            </div>
        </Modal>
    );
};

NewClientModal.propTypes = {

};

export default NewClientModal;
