import React, { useEffect, useState } from 'react';
// import moment from 'moment';
import {
    Button, Card, Col, Container, Row,
} from 'reactstrap';
// core components
// import { get } from 'lodash';
import { useHistory } from 'react-router';
import './style.scss';
import NavBar from '../../../../components/Navbar/NavBar';
import PlugAndPlayNavigationPane from './components/PlugAndPlayNavigationPane';
import { ExternalLinkIcon } from '@heroicons/react/outline'

import useAdmin from '../../../../hooks/useAdmin';
import useCourse from '@genflow/web-courses/hooks/useCourse';
import { useConfig } from '@genflow/core';
import CustomLoader from '../../../../components/CustomLoader';
import { trackEvent } from '../../../../utils/useAnalytics';

export const PLUG_AND_PLAYS = [
    // {
    //     sectionName: 'Agency Incubator',
    //     filters: [
    //         'Foundations',
    //         'Mindsets',
    //         'Systems and Processes',
    //         'Finding Leads & Setting Meetings',
    //         'Sales',
    //         'Service Delivery',
    //         'Operational Supremacy',
    //         'The Next Evolution',
    //     ],
    //     plugAndPlays: [
    //         {
    //             name: 'FaBo Local Businesses',
    //             url: '/resources/plug-and-play/fa-bo-local-businesses',
    //             tags: [
    //                 'Mindsets',
    //             ],
    //         },
    //     ],
    // },
    {
        sectionName: 'Agency Navigator',
        available: true,
        plugAndPlays: [
            {
                name: 'Creating Your LinkedIn Profile',
                url: '/resources/plug-and-play/creating-your-linkedin-profile',
                tags: ['Finding Leads & Setting Meetings'],
                searchTerms: 'LinkedIn, LinkedIn Profile',
            },
            {
                name: 'Example Linkedin Content Schedule',
                url: '/resources/plug-and-play/example-linkedin-content-schedule',
                tags: ['Finding Leads & Setting Meetings'],
                searchTerms: 'LinkedIn, Content, Schedule',
            },
            {
                name: 'LinkedIn Automation',
                url: '/resources/plug-and-play/linkedin-automation',
                tags: ['Finding Leads & Setting Meetings'],
                searchTerms: 'LinkedIn, Automation',
            },
            {
                name: 'LinkedIn Content',
                url: '/resources/plug-and-play/linkedin-content',
                tags: ['Finding Leads & Setting Meetings'],
                searchTerms: 'LinkedIn, Content',
            },
            {
                name: 'LinkedIn Keywords',
                url: '/resources/plug-and-play/linkedin-keywords',
                tags: ['Finding Leads & Setting Meetings'],
                searchTerms: 'LinkedIn, Keywords',
            },
            {
                name: 'Sales Navigator Industry List',
                url: '/resources/plug-and-play/sales-navigator-industry-list',
                tags: ['Finding Leads & Setting Meetings'],
                searchTerms: 'Sales, Sales Navigator, Industry List',
            },
            {
                name: 'Sending LinkedIn Messages',
                url: '/resources/plug-and-play/sending-linkedin-messages',
                tags: ['Finding Leads & Setting Meetings'],
                searchTerms: 'LinkedIn, Sending LinkedIn Messages',
            },
            {
                name: 'Using Sales Navigator',
                url: '/resources/plug-and-play/using-sales-navigator',
                tags: ['Finding Leads & Setting Meetings'],
                searchTerms: 'Sales, Using Sales Navigator',
            },
            {
                name: 'Email Personalisation',
                url: '/resources/plug-and-play/email-personalisation',
                tags: ['Finding Leads & Setting Meetings'],
                searchTerms: 'Email, Email Personalisation',
            },
            {
                name: 'How To Create Your Email Script',
                url: '/resources/plug-and-play/how-to-create-your-email-script',
                tags: ['Finding Leads & Setting Meetings'],
                searchTerms: 'Email, How To Create Your Email Script, How To',
            },
            {
                name: 'How To Set Up A Lemlist Campaign',
                url: '/resources/plug-and-play/how-to-set-up-a-lemlist-campaign',
                tags: ['Finding Leads & Setting Meetings'],
                searchTerms: 'Lemlist, How To Set Up A Lemlist Campaign, How To, Campaign',
            },
            {
                name: 'How to Set Up Your Email Account',
                url: '/resources/plug-and-play/how-to-set-up-your-email-account',
                tags: ['Finding Leads & Setting Meetings'],
                searchTerms: 'Email, How to Set Up Your Email Account, How to',
            },
            {
                name: 'How to Set Up Your Lemlist Account',
                url: '/resources/plug-and-play/how-to-set-up-your-lemlist-account',
                tags: ['Finding Leads & Setting Meetings'],
                searchTerms: 'Lemlist, How to Set Up Your Lemlist Account, How to',
            },
            {
                name: 'Agency Software Stack',
                url: '/resources/plug-and-play/agency-software-stack',
                tags: ['Foundations'],
                searchTerms: 'Agency , Software Stack',
            },
            {
                name: 'FB Business & Ad Manager',
                url: '/resources/plug-and-play/fb-business-and-ad-manager',
                tags: ['Foundations', 'Fundamentals'],
                searchTerms: 'FB , Ad Manager, FB Business',
            },
            {
                name: 'Website Setup',
                url: '/resources/plug-and-play/website-setup',
                tags: ['Foundations', 'Fundamentals'],
                searchTerms: 'Website, Setup',
            },
            {
                name: 'Cleaning Your Social Profiles',
                url: '/resources/plug-and-play/cleaning-your-social-profiles',
                tags: ['Foundations'],
                searchTerms: 'Social , Profiles, Cleaning',
            },
            {
                name: 'Tracking Emails for Manual Outreach',
                url: '/resources/plug-and-play/tracking-emails-for-manual-outreach',
                tags: ['Finding Leads & Setting Meetings'],
                searchTerms: 'Tracking , Emails, Manual Outreach',
            },
            {
                name: 'Warming Up Your Email Address',
                url: '/resources/plug-and-play/warming-up-your-email-address',
                tags: ['Finding Leads & Setting Meetings'],
                searchTerms: 'Tracking , Emails, Manual Outreach',
            },
            {
                name: '6 Figure Sleep',
                url: '/resources/plug-and-play/6-figure-sleep',
                tags: ['Mindsets'],
                searchTerms: 'Tracking , Sleep',
            },
            {
                name: 'Monk Mode',
                url: '/resources/plug-and-play/monk-mode',
                tags: ['Mindsets'],
                searchTerms: 'Monk , Sleep',
            },
            {
                name: 'Niche Research',
                url: '/resources/plug-and-play/niche-research',
                tags: ['Foundations'],
                searchTerms: 'Niche , Research',
            },
            {
                name: 'Profile Funnel',
                url: '/resources/plug-and-play/profile-funnel',
                tags: ['Finding Leads & Setting Meetings'],
                searchTerms: 'Profile , Funnel',
            },
            {
                name: 'Storing & Organising Leads',
                url: '/resources/plug-and-play/storing-and-organising-leads',
                tags: ['Finding Leads & Setting Meetings'],
                searchTerms: 'Leads , Organising, Storing',
            },
            {
                name: 'How to Set Up an UpWork Account',
                url: '/resources/plug-and-play/how-to-set-up-an-upwork-account',
                tags: ['Finding Leads & Setting Meetings'],
                searchTerms: 'UpWork ,How to',
            },
            {
                name: 'How to Find Leads and Respond to them on Upwork',
                url: '/resources/plug-and-play/how-to-find-leads-upwork',
                tags: ['Finding Leads & Setting Meetings'],
                searchTerms: 'Leads, Upwork, How to',
            },
            {
                name: 'How to Qualify Clients',
                url: '/resources/plug-and-play/how-to-qualify-clients',
                tags: ['Finding Leads & Setting Meetings'],
                searchTerms: 'Clients ,Qualify, How to',
            },
            {
                name: 'Finding Leads for Local Businesses',
                url: '/resources/plug-and-play/finding-leads-for-local-businesses',
                tags: ['Finding Leads & Setting Meetings'],
                searchTerms: 'Leads ,Local Businesses, How to',
            },
            {
                name: 'Finding Leads for eCommerce',
                url: '/resources/plug-and-play/finding-leads-for-ecommerce',
                tags: ['Finding Leads & Setting Meetings'],
                searchTerms: 'Leads ,eCommerce, How to',
            },
            {
                name: 'Client Communication',
                url: '/resources/plug-and-play/client-communication',
                tags: ['Systems and Processes'],
                searchTerms: 'Client ,Communication',
            },
            {
                name: 'Getting Paid',
                url: '/resources/plug-and-play/getting-paid',
                tags: ['Systems and Processes'],
                searchTerms: 'Paid',
            },
            {
                name: 'Onboarding Funnel',
                url: '/resources/plug-and-play/onboarding-funnel',
                tags: ['Systems and Processes'],
                searchTerms: 'Onboarding, Funnel',
            },
            {
                name: 'Objection Handling',
                url: '/resources/plug-and-play/objection-handling',
                tags: ['Sales'],
                searchTerms: 'Objection',
            },
            {
                name: '2CC Sales Script',
                url: '/resources/plug-and-play/2cc-sales-script',
                tags: ['Sales'],
                searchTerms: '2CC, Sales',
            },
            {
                name: '1CC Sales Script',
                url: '/resources/plug-and-play/1cc-sales-script',
                tags: ['Sales'],
                searchTerms: '1CC, Sales',
            },
            {
                name: '[Free Version] Client Bible Template',
                url: '/resources/plug-and-play/free-client-bible-template',
                tags: ['Service Delivery', 'Fundamentals'],
                searchTerms: 'Free, Client, Client Bible',
            },
            {
                name: 'How To Use Our Client Bible Template',
                url: '/resources/plug-and-play/client-bible',
                tags: ['Service Delivery', 'Fundamentals'],
                searchTerms: 'Client, Client Bible, How To',
            },
            {
                name: 'All About Tracking',
                url: '/resources/plug-and-play/all-about-tracking',
                tags: ['Service Delivery', 'Fundamentals'],
                searchTerms: 'Tracking',
            },
            {
                name: 'Kick-Off Call',
                url: '/resources/plug-and-play/kick-off-call',
                tags: ['Service Delivery', 'Fundamentals'],
                searchTerms: 'Kick-Off, Call',
            },
            {
                name: 'Facebook Ads Fundamentals',
                url: '/resources/plug-and-play/facebook-ads-fundamentals',
                tags: ['Service Delivery', 'Fundamentals'],
                searchTerms: 'Ads, Facebook, Fundamentals',
            },
            {
                name: 'The Ultimate Facebook Contingency Guide',
                url: '/resources/plug-and-play/the-ultimate-facebook-contingency-guide',
                tags: ['Service Delivery', 'Fundamentals'],
                searchTerms: 'Facebook, Contingency, Guide',
            },
            {
                name: 'Facebook Hygiene & Avoiding Bans',
                url: '/resources/plug-and-play/facebook-hygiene-and-avoiding-bans',
                tags: ['Service Delivery', 'Fundamentals'],
                searchTerms: 'Facebook, Avoiding Bans',
            },
            {
                name: 'iOS14 Update',
                url: '/resources/plug-and-play/ios-update',
                tags: ['Service Delivery', 'Fundamentals'],
                searchTerms: 'iOS14',
            },
            {
                name: 'Tag Manager Setup',
                url: '/resources/plug-and-play/tag-manager-setup',
                tags: ['Service Delivery', 'Fundamentals'],
                searchTerms: 'Tag Manager, How to',
            },
            {
                name: 'The Conversion API',
                url: '/resources/plug-and-play/the-conversion-api',
                tags: ['Service Delivery', 'Fundamentals'],
                searchTerms: 'Conversion',
            },
            {
                name: 'True Tracking',
                url: '/resources/plug-and-play/true-tracking',
                tags: ['Service Delivery', 'Fundamentals'],
                searchTerms: 'Tracking',
            },
            {
                name: 'Funnels & Offer Fundamentals',
                url: '/resources/plug-and-play/funnels-and-offer-fundamentals',
                tags: ['Service Delivery', 'Fundamentals'],
                searchTerms: 'Funnels,Fundamentals',
            },
            {
                name: 'Copywriting: The Fundamentals',
                url: '/resources/plug-and-play/copywriting-fundamentals',
                tags: ['Service Delivery', 'Fundamentals'],
                searchTerms: 'Copywriting, Fundamentals',
            },
            {
                name: 'Science Behind Creatives That Sells',
                url: '/resources/plug-and-play/science-behind-creatives-that-sells',
                tags: ['Service Delivery', 'Fundamentals'],
                searchTerms: 'Creatives, Selling, Science',
            },
            {
                name: 'How Many of Things',
                url: '/resources/plug-and-play/how-many-of-things',
                tags: ['Service Delivery', 'Fundamentals'],
                searchTerms: 'How Many',
            },
            {
                name: 'Reporting 101',
                url: '/resources/plug-and-play/reporting-101',
                tags: ['Service Delivery', 'Fundamentals'],
                searchTerms: 'Reporting',
            },
            {
                name: 'Traffic Warmth',
                url: '/resources/plug-and-play/traffic-warmth',
                tags: ['Service Delivery', 'How To Advertise'],
                searchTerms: 'Traffic, Warmth',
            },
            {
                name: 'Interests: Sizes And How To Find The Best Ones For Your Case',
                url: '/resources/plug-and-play/interests',
                tags: ['Service Delivery', 'How To Advertise'],
                searchTerms: 'Interests, How to',
            },
            {
                name: 'Custom Audiences, Exclusions and Combinations, What Is Worth It?',
                url: '/resources/plug-and-play/custom-audiences',
                tags: ['Service Delivery', 'How To Advertise'],
                searchTerms: 'Audiences, Exclusions, Combinations',
            },
            {
                name: 'Lookalike Audiences: How To and The Best Ones You Can Create',
                url: '/resources/plug-and-play/lookalike-audiences',
                tags: ['Service Delivery', 'How To Advertise'],
                searchTerms: 'Audiences, How To',
            },
            {
                name: 'Launching Your First Ad',
                url: '/resources/plug-and-play/launching-your-first-ad',
                tags: ['Service Delivery', 'How To Advertise'],
                searchTerms: 'Launching, First Ad',
            },
            {
                name: 'How To Test Audiences and Find Winners',
                url: '/resources/plug-and-play/how-to-test-audiences-and-find-winners',
                tags: ['Service Delivery', 'How To Advertise'],
                searchTerms: 'Audiences, Audiences, How To, Winners',
            },
            {
                name: 'Load Balancing Budgets',
                url: '/resources/plug-and-play/load-balancing-budgets',
                tags: ['Service Delivery', 'How To Advertise'],
                searchTerms: 'Budgets, Budgets, Balancing',
            },
            {
                name: 'Utilising CBOs',
                url: '/resources/plug-and-play/utilising-cbos',
                tags: ['Service Delivery', 'How To Advertise'],
                searchTerms: 'CBOs',
            },
            {
                name: 'The Two King Metrics And Their Math',
                url: '/resources/plug-and-play/the-two-king-metrics-and-their-math',
                tags: ['Service Delivery', 'How To Advertise'],
                searchTerms: 'Two King Metrics',
            },
            {
                name: 'Dream Dashboard Set Up',
                url: '/resources/plug-and-play/dream-dashboard-set-up',
                tags: ['Service Delivery', 'How To Advertise'],
                searchTerms: 'Dashboard',
            },
            {
                name: 'How To Read Your Dashboard for Cold',
                url: '/resources/plug-and-play/how-to-read-your-dashboard-for-cold',
                tags: ['Service Delivery', 'How To Advertise'],
                searchTerms: 'Dashboard, How To',
            },
            {
                name: 'Re-Targeting 101',
                url: '/resources/plug-and-play/re-targeting-101',
                tags: ['Service Delivery', 'How To Advertise'],
                searchTerms: 'Re-Targeting, 101',
            },
            {
                name: 'Communication Schedule & Keeping Clients In The Loop',
                url: '/resources/plug-and-play/communication-schedule',
                tags: ['Service Delivery', 'Fundamentals'],
                searchTerms: 'Communication, Clients',
            },
            {
                name: 'Ad Selection',
                url: '/resources/plug-and-play/ad-selection',
                tags: ['Service Delivery', 'Fundamentals'],
                searchTerms: 'Ad',
            },
            {
                name: 'Core Concepts To Keep In Mind',
                url: '/resources/plug-and-play/core-concepts-to-keep-in-mind',
                tags: ['Service Delivery', 'Local Businesses or Lead Gen'],
                searchTerms: 'Core Concepts',
            },
            {
                name: '9 Funnels For Lead Gen & How To Set Them Up',
                url: '/resources/plug-and-play/9-funnels-for-lead-gen',
                tags: ['Service Delivery', 'Local Businesses or Lead Gen'],
                searchTerms: 'Funnels,Lead, How To',
            },
            {
                name: 'Facebook Native vs Funnel Lead Gen: Setting Them Up',
                url: '/resources/plug-and-play/facebook-native-vs-funnel-lead-gen',
                tags: ['Service Delivery', 'Local Businesses or Lead Gen'],
                searchTerms: 'Facebook, Funnel',
            },
            {
                name: 'Content & Creatives',
                url: '/resources/plug-and-play/content-and-creatives',
                tags: ['Service Delivery', 'Local Businesses or Lead Gen'],
                searchTerms: 'Content, Creatives',
            },
            {
                name: 'Automate Lead Flow to Your Client',
                url: '/resources/plug-and-play/automate-lead-flow',
                tags: ['Service Delivery', 'Local Businesses or Lead Gen'],
                searchTerms: 'Automate, Lead, Client',
            },
            {
                name: 'Industry Specific: Chiropractors',
                url: '/resources/plug-and-play/industry-chiropractors',
                tags: ['Service Delivery', 'Local Businesses or Lead Gen'],
                searchTerms: 'Chiropractors, Industry',
            },
            {
                name: 'Industry Specific: Gyms',
                url: '/resources/plug-and-play/industry-gyms',
                tags: ['Service Delivery', 'Local Businesses or Lead Gen'],
                searchTerms: 'Gyms, Industry',
            },
            {
                name: 'Examples of ads Leadgen',
                url: '/resources/plug-and-play/example-ads-leadgen',
                tags: ['Service Delivery', 'Local Businesses or Lead Gen'],
                searchTerms: 'ads, Leadgen',
            },
            {
                name: 'Onboarding Funnel',
                url: '/resources/plug-and-play/onboarding-funnel',
                tags: ['Service Delivery', 'Local Businesses or Lead Gen'],
                searchTerms: 'Onboarding',
            },
            {
                name: 'Youtube Ads Playbook',
                url: '/resources/plug-and-play/youtube-ads-playbook',
                tags: ['Service Delivery', 'Advanced Strategies'],
                searchTerms: 'Youtube, Ads',
            },
            {
                name: 'Scaling Horizontally vs Scaling Vertically',
                url: '/resources/plug-and-play/scaling-horiz-vs-vertical',
                tags: ['Service Delivery', 'Advanced Strategies'],
                searchTerms: 'Scaling, Horizontally, Vertically',
            },
            {
                name: 'Google Display Network',
                url: '/resources/plug-and-play/google-display-network',
                tags: ['Service Delivery', 'Advanced Strategies'],
                searchTerms: 'Google, Network',
            },
            {
                name: 'Facebook Rules, Automations & Naming conventions',
                url: '/resources/plug-and-play/facebook-rules-automations-naming',
                tags: ['Service Delivery', 'Advanced Strategies'],
                searchTerms: 'Facebook, Automations, Naming',
            },
            {
                name: 'Getting Setup - Google Ads',
                url: '/resources/plug-and-play/google-ads-setup',
                tags: ['Service Delivery', 'Advanced Strategies'],
                searchTerms: 'Google, Ads',
            },
            {
                name: 'Google Search Network',
                url: '/resources/plug-and-play/google-search-network',
                tags: ['Service Delivery', 'Advanced Strategies'],
                searchTerms: 'Google, Search, Network',
            },
            {
                name: 'Google Shopping Campaigns',
                url: '/resources/plug-and-play/google-shopping-campaigns',
                tags: ['Service Delivery', 'Advanced Strategies'],
                searchTerms: 'Google, Shopping, Campaigns',
            },
            {
                name: 'How Facebook Works On The Inside',
                url: '/resources/plug-and-play/how-fb-works-inside',
                tags: ['Service Delivery', 'Advanced Strategies'],
                searchTerms: 'Facebook',
            },
            {
                name: 'iOS Move To Privacy & Implications',
                url: '/resources/plug-and-play/ios-privacy-implications',
                tags: ['Service Delivery', 'Advanced Strategies'],
                searchTerms: 'Privacy,Implications',
            },
            {
                name: 'Lead Automation',
                url: '/resources/plug-and-play/lead-automation',
                tags: ['Service Delivery', 'Local Businesses or Lead Gen'],
                searchTerms: 'Lead,Automation',
            },
        ],
        filters: [
            { name: 'Foundations', available: true },
            { name: 'Mindsets', available: true },
            { name: 'Systems and Processes', available: true },
            { name: 'Finding Leads & Setting Meetings', available: true },
            { name: 'Sales', available: true },
            {
                name: 'Service Delivery',
                available: true,
                subFilters: [
                    'Fundamentals',
                    'How To Advertise',
                    'Local Businesses or Lead Gen',
                    'Advanced Strategies',
                ],
            },
            // { name: 'Operational Supremacy', available: false },
            // { name: 'The Next Evolution', available: false },
        ],
    },
    // {
    //     sectionName: 'Copy Paste Agency',
    //     available: false,
    //     plugAndPlays: [
    //         {
    //             name: 'FaBo Local Businesses',
    //             url: '/resources/plug-and-play/fa-bo-local-businesses',
    //         },
    //         {
    //             name: 'Creating Your LinkedIn Profile',
    //             url: '/resources/plug-and-play/creating-your-linkedin-profile',
    //         },
    //         {
    //             name: 'FaBo Local Businesses',
    //             url: '/resources/plug-and-play/fa-bo-local-businesses',
    //         },
    //         {
    //             name: 'Creating Your LinkedIn Profile',
    //             url: '/resources/plug-and-play/creating-your-linkedin-profile',
    //         },
    //         {
    //             name: 'FaBo Local Businesses',
    //             url: '/resources/plug-and-play/fa-bo-local-businesses',
    //         },
    //     ],
    // },
];

export default () => {
    const history = useHistory();
    const { isAdmin } = useAdmin();
    const [filter, setFilter] = useState(null);
    const [subFilter, setSubFilter] = useState(null);

    const [selectedSection, setSelectedSection] = useState(null);

    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, []);

    useEffect(() => {
        if (!selectedSection) {
            setSelectedSection(sections?.[0]);
        }
        setFilter(null);
    }, [selectedSection]);

    useEffect(() => {
        setSubFilter(null);
    }, [filter]);

    // Assumes 4 digit year (e.g. 2021)
    const sections = PLUG_AND_PLAYS;

    const onSelectSection = (section) => {
        setSelectedSection(section);
    };

    const selectFilter = (programFilter) => {
        setSubFilter(null);
        setFilter(programFilter);
    };

    const selectSubFilter = (programSubFilter) => {
        setSubFilter(programSubFilter);
    };

    const filterAvailablePnPs = (pnps) => {
        if (pnps?.plugAndPlays) {
            const p = pnps.plugAndPlays.filter((item) => {
                if (pnps.filters && !isAdmin) {
                    const x = pnps.filters.filter(i => i.name === item.tags[0])[0];
                    return x?.available && item;
                }
                return true; // is admin, or this section doesnt have filters
            });
            return { ...pnps, plugAndPlays: p };
        }
    };

    const filteredPlugAndPlays = filter
        ? filterAvailablePnPs(selectedSection)?.plugAndPlays?.filter(item => item?.tags?.includes(filter))
        : filterAvailablePnPs(selectedSection)?.plugAndPlays;

    const subFilteredPlugAndPlays = subFilter
        ? filteredPlugAndPlays.filter(item => item?.tags?.includes(subFilter))
        : filteredPlugAndPlays;

    const filteredSections = {
        ...selectedSection,
        plugAndPlays: subFilteredPlugAndPlays,
    };

    const { vendorUuid, agencyNavigatorCourseProductID, stripeProductPlans } = useConfig();
    const { loading, authoriseError } = useCourse(
        vendorUuid,
        agencyNavigatorCourseProductID,
    );

    return (
        <CustomLoader loading={loading}>
            <main className="resources-qa-page text-white w-10/12 mx-auto">
                <section className="section-profile-cover section-shaped my-0">
                    <Container>
                        <Row>
                            <Col lg={12} className="text-center">
                                <h2 className="mt-8">Plug & Play</h2>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <Container className="content-container">
                    <Row className="flex flex-col sm:flex-row" >
                        <Col className="w-full sm:w-1/4 mb-2">
                            <PlugAndPlayNavigationPane
                                selectedSection={selectedSection}
                                setSelectedSection={onSelectSection}
                                sections={sections}
                                filter={filter}
                                selectFilter={selectFilter}
                                subFilter={subFilter}
                                selectSubFilter={selectSubFilter}
                            />
                        </Col>
                        <Col className="w-full sm:w-3/4">
                            <Card className="recording-items">
                                <Row className="grey800Bg rounded-lg">
                                    {!!selectedSection
                    && filteredSections?.plugAndPlays.map(plugAndPlay => (
                        <Col
                            className="plug-and-play-card"
                            className="grey800Bg rounded-lg px-8"
                            md={4}
                            style={{
                                
                            }}
                        >
                            <div
                                className="shadow grey800Bg flex flex-row items-center"
                                style={{
                                  
                                    padding: '2rem 0',
                                    height: '100%',
                                    borderBottom: '1px solid #373737'
                                }}
                            >
                                <div
                                    style={{
                                       
                                        fontWeight: '600',
                                        textAlign: 'left',
                                        flex: 1,
                                    }}
                                >
                                    {plugAndPlay?.name}
                                </div>

                                <span
                                    onClick={() => trackEvent({
                                        action: 'P&P_Viewed',
                                        category: 'Name',
                                        label: plugAndPlay?.name,
                                    })
                                    }
                                >
                                    <a className="blkButton mx-auto w-fit px-4 py-2 ml-4 cursor-pointer !no-underline" href={plugAndPlay?.url}>View
                                        <ExternalLinkIcon className="h-5 w-5 text-white ml-2" aria-hidden="true" /> 
                                    </a>
                                </span>
                            </div>
                        </Col>
                    ))}
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </main>
        </CustomLoader>
    );
};