import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';
import ClientCommunication1 from '../../../../../assets/img/plugandplay/ClientCommunication1.png';
import ClientCommunication2 from '../../../../../assets/img/plugandplay/ClientCommunication2.png';
import ClientCommunication3 from '../../../../../assets/img/plugandplay/ClientCommunication3.png';
import ClientCommunication4 from '../../../../../assets/img/plugandplay/ClientCommunication4.png';

const sections = [
    {
        available: true, sectionName: 'Setting Up Your Client On AgenciFlow',
    },

];

const title = "Client Communication"

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                // selectedSection={selectedSection}
                                // setSelectedSection={onSelectSection}
                                // filter={filter}
                                // selectFilter={selectFilter}
                                // subFilter={subFilter}
                                // selectSubFilter={selectSubFilter}

                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]} className="plug-block">
                            <h2 className="mb-4">{title}</h2>  


                            <p>
                                Most agency’s communication is shambles. Their clients feel like they can call them, text them, WhatsApp them, any time of the day at any hour.
                            </p>

                            <p>
                                You need to understand that people will follow the protocol that you set for them. You just have to set the boundaries. You need to tell your clients your exact protocol & tell them why it’s in their best interest.

                            </p>

                            <p>
                                That’s why our communication will be on one platform, AgenciFlow, fully separated from their personal lives. You don’t use the same card for both personal & biz expenses, so why use the same communication platform.

                            </p>
                        </div>

                        <div ref={refs[0]} className="plug-block">
                            <div className="plug-block-subheading">
                                Setting Up Your Client On AgenciFlow


                            </div>

                            <p>
                                First, we need to create the client inside AgenciFlow. To do so, you just need to go into your “Clients” tab at the top navigation menu. Once inside the Clients tab, click on “Create New Client”.

                            </p>

                            <img src={ClientCommunication1} alt="" />

                            <p>
                                On the new window, you’ll put all your client’s information.


                            </p>

                            <p>
                                First, you should insert your client’s company name. Let’s say I have a local business client called “Jasper’s Market”.


                            </p>

                            <p>
                                Second, we have to fill out the brief section. This section might seem of little use at first, but it’s actually one of the most useful sections inside your client. That’s because here is the perfect place to store all information related to this client that you and your team may ever need, so you always have it at hand.
                            </p>

                            <p>
                                For example, I like to store the client’s website, the link for a drive folder containing all the logos, pictures and other images and creative assets for this client. It’s also useful for storing design guidelines and the login credentials for all the different systems the client uses, such as their CRM, landing page builder, email automation tool, etc. Use this section wisely and it will improve your team's productivity by a lot.
                            </p>

                            <p>
                                Client tag is a way of segmenting your clients by the type of service you provide them, by their industry, or however you like to segment the different types of clients you serve. Let’s say in this case we’re doing Facebook Ads for them. We would add “Facebook Ads” as a client tag. You can also attribute a color to each tag.
                            </p>

                            <p>
                                On client color, just select the main color from your client’s brand.
                            </p>

                            <p>Under team members you select everyone from your internal team that will be part of this project. </p>

                            <p>
                                On invite client, type your client’s email address to invite them to join this workspace on AgenciFlow. They will only be able to see the things inside this specific “Client” you’re currently creating. You can also invite their team members here through their email addresses.
                            </p>

                            <img src={ClientCommunication2} alt="" />

                            <p>And your new client is now created! Everyone who has been invited to the client will receive an email with their login credentials to join your workspace. </p>

                            <img src={ClientCommunication3} alt="" />

                            <p>
                                Inside of it you’ll be able to manage tasks, share notes and most importantly communicate with your client through the chat.
                            </p>

                            <img src={ClientCommunication4} alt="" />

                            <p>
                                Here you will be able to chat with everyone who is invited to this client workspace, send voice messages, emojis, gifs, etc.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
