/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

import image1 from '../../../../../assets/img/plugandplay/google-shopping-campaigns/1.png';
import image2 from '../../../../../assets/img/plugandplay/google-shopping-campaigns/2.png';
import image3 from '../../../../../assets/img/plugandplay/google-shopping-campaigns/3.png';
import image4 from '../../../../../assets/img/plugandplay/google-shopping-campaigns/4.png';
import image5 from '../../../../../assets/img/plugandplay/google-shopping-campaigns/5.png';
import image6 from '../../../../../assets/img/plugandplay/google-shopping-campaigns/6.png';
import image7 from '../../../../../assets/img/plugandplay/google-shopping-campaigns/7.png';
import image8 from '../../../../../assets/img/plugandplay/google-shopping-campaigns/8.png';
import image9 from '../../../../../assets/img/plugandplay/google-shopping-campaigns/9.png';
import image10 from '../../../../../assets/img/plugandplay/google-shopping-campaigns/10.png';

const sections = [
    {
        available: true,
        sectionName: 'Introduction',
    },
    {
        available: true,
        sectionName: 'How To Create a Google Shopping Campaign',
    },
];

const title = 'Google Shopping Campaigns';

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div className="plug-block">  
                        <h2 className="mb-4">{title}</h2>
                            <div ref={refs[0]} className="plug-block-subheading">Introduction</div>
                            <p>
                                A Google shopping campaign is a smart type of campaign that uses both the Google
                                Search and Google Display networks to run ads for your ecommerce store (or your
                                client’s).
                            </p>

                            <p>
                                If you’re not familiar, the ads for Google Shopping campaigns are those that show up
                                at the top of the search results when you search for products on Google.
                            </p>

                            <img src={image1} alt="" />

                            <p>
                                In order to run this type of ads, you have to connect the store to Google Merchant
                                Center, so that it pulls the data feed for the products with their pricing,
                                availability, images, headlines, links, etc.
                            </p>

                            <p>
                                Depending on the platform your client uses to run their store, there will be
                                different ways to connect it to Google Merchant center. The main ecommerce platforms
                                all have integrations that make this process easy.
                            </p>

                            <p>
                                For Shopify, you can check the
                                {' '}
                                <a href="https://help.shopify.com/en/manual/promoting-marketing/create-marketing/google/setup">
                                    step-by-step
                                </a>
                                {' '}
                                guide on how to connect your client’s store to the Merchant Center Account directly
                                on Shopify’s website.
                            </p>

                            <p>
                                <a href="https://support.google.com/google-ads/answer/10431635?hl=en">
                                    You can also take a look at the tutorial from Google.
                                </a>
                            </p>

                            <p>
                                Don’t forget to also make sure that your Google Ads account is connected to the
                                Google Merchant Center account. If you’re not sure how to do that,
                                <a href="https://support.google.com/google-ads/answer/6159060?hl=en">
                                    refer to this tutorial.
                                </a>
                            </p>
                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">How To Create a Google Shopping Campaign</div>
                            <p>With all the pre-set up done, now it’s time to create your campaign.</p>

                            <p>First, click on the “+ New Campaign” button on our main dashboard.</p>

                            <img src={image2} alt="" />

                            <p>For the campaign objective, select sales.</p>

                            <img src={image3} alt="" />

                            <p>On campaign type, select “Shopping”.</p>

                            <img src={image4} alt="" />

                            <p>
                                Then, select your merchant center account and the country you want to advertise in.
                            </p>

                            <img src={image5} alt="" />

                            <p>
                                And for the campaign subtype, select “Standard Shopping campaign”. Hit continue.
                            </p>

                            <img src={image6} alt="" />

                            <p>Enter a name for your campaign. Keep it simple: “Standard Shopping”</p>

                            <img src={image7} alt="" />

                            <p>
                                Under Bidding and budget, on the bidding option select “Manual CPC”. Leave the
                                checkbox checked and select “Optimize for conversions”.
                            </p>

                            <p>On budget, you input your desired daily budget.</p>

                            <p>On campaign priority, leave it as “Low (default)”.</p>

                            <img src={image8} alt="" />

                            <p>
                                For the targeting, you can uncheck Google Search partners and leave YouTube, Gmail
                                and Discover checked.
                            </p>

                            <p>On devices, leave it as default to show on all devices.</p>

                            <p>On locations, select the countries you want to run your ads in.</p>

                            <img src={image9} alt="" />

                            <p>Finally, just name your ad group and select a CPC bid.</p>

                            <p>
                                This bidding is really particular to your niche, product and country. We usually
                                like to start at $1.50 and optimize as the campaign starts to run.
                            </p>

                            <p>
                                If we see the campaign is not spending we increase the bids to get it to spend and
                                if we see the campaign is spending its daily budget way too fast, we decrease the
                                bidding to get more clicks for a lower CPC throughout the day.
                            </p>

                            <img src={image10} alt="" />

                            <p>
                                With that done, just hit “Create Campaign” and your campaign will be ready to go.
                                Google will automatically pull the data from your product feed to create your ads
                                and target the keywords that are relevant to it, based on your product’s headline
                                and description.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
