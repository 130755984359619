/* eslint-disable no-restricted-globals */
/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import {
    Card,
    CardBody,
    Col,
    Container,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
} from 'reactstrap';
import accounting from 'accounting';
// import { parseInt } from 'lodash';
import Footer from '../../../components/Footer';
import '../../../assets/scss/simple-tools.scss';
import GYAFooterScene from '../../../assets/img/footer-scene-gya.png';
import { UserDataContext } from '../../../providers/UserDataProvider';
import CurrencySelector from '../../../components/CurrencySelector';

export default () => {
    const { userCurrency } = useContext(UserDataContext);

    const [state, setState] = useState({
    // user inputs
        day1Cash: '',
        ltv: '',
        cpl: '',
        showUpRate: '',
        showUpCustomer: '',

        // results
        costPerShowUp: '-',
        cpa: '-',
        day1Roi: '-',
        lifetimeRoi: '-',
    });

    const handleChange = (value, name) => {
        const s = state;

        const validValue = !isNaN(value * 1);

        s[name] = value || 0;
        if (validValue) {
            s[name] = value * 1;

            s.costPerShowUp = (s.cpl / (s.showUpRate / 100));

            s.cpa = (s.costPerShowUp / (s.showUpCustomer / 100));

            s.day1Roi = (s.day1Cash / (s.cpa / 100));

            s.lifetimeRoi = (s.ltv / (s.cpa / 100));
        }

        setState({ ...s });
    };

    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, []);

    return (
        <>
            <main className="simple-tools-page text-white">
                <section className="section-profile-cover section-shaped my-10 sm:my-12">
                    <Container>
                        <Row>
                            <Col lg={12} className="text-center">
                                <h1 className="hidden sm:block">
                                    Local Business ROI Calculator
                                </h1>
                                <h3  style={{fontSize: '26px'}} className="block sm:hidden">
                                    Local Business ROI Calculator
                                </h3>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="section section-lg pt-lg-3 pt-8">
                    <Container>
                        <Row className="mb-4 shadow shadow--hover">
                            <Col lg={12}>
                                <Row className="flex flex-col sm:flex-row w-11/12 sm:w-8/12 mx-auto shadow-md card">
                                    <Col lg={7} className="w-full sm:w-7/12 p-4 sm:p-8 ">
                                        <div className="left-input-card ">
                                           <CardBody>
                                                <CurrencySelector />
                                                <div className="mt-8 block sm:hidden"></div>
                                                <strong>Revenue numbers:</strong>
                                                <Row className="tool-form-row flex flex-col sm:flex-row items-start sm:items-end justify-between py-4 body2">
                                                    <Col sm={9} className="tool-form-label">
                                                        Day 1 Cash Collected
                                                    </Col>
                                                    <Col sm={3} className="tool-form-input w-full sm:w-auto">
                                                        <InputGroup className="flex flex-row items-end bg-transparent inputBox" style={{borderBottomColor: "#434343", marginBottom: "0px"}}>
                                                            <InputGroupAddon addonType="prepend" className="bg-transparent inputBox" style={{ marginBottom: "0px"}}>
                                                                <InputGroupText>{userCurrency}</InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder="100"
                                                                type="number"
                                                                value={state.day1Cash}
                                                                className="bg-transparent p-2 pb-0 w-full sm:w-24 inputBox"
                                                                style={{marginBottom: "0px"}}
                                                                onChange={e => handleChange(e.target.value, 'day1Cash')
                                                                }
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                                <Row className="tool-form-row mb-4 flex flex-col sm:flex-row items-start sm:items-end justify-between py-4 body2">
                                                    <Col sm={9} className="tool-form-label">
                                                        Customer LTV
                                                    </Col>
                                                    <Col sm={3} className="tool-form-input w-full sm:w-auto">
                                                        <InputGroup className="flex flex-row items-end bg-transparent inputBox" style={{borderBottomColor: "#434343", marginBottom: "0px"}}>
                                                            <InputGroupAddon addonType="prepend" className="bg-transparent inputBox" style={{ marginBottom: "0px"}}>
                                                                <InputGroupText>{userCurrency}</InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder="100"
                                                                type="number"
                                                                value={state.ltv}
                                                                className="bg-transparent p-2 pb-0 w-full sm:w-24 inputBox"
                                                                style={{marginBottom: "0px"}}
                                                                onChange={e => handleChange(e.target.value, 'ltv')
                                                                }
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                </Row>

                                                <strong>Costs</strong>
                                                <Row className="tool-form-row flex flex-col sm:flex-row items-start sm:items-end justify-between py-4 body2">
                                                    <Col sm={9} className="tool-form-label">
                                                        CPL
                                                    </Col>
                                                    <Col sm={3} className="tool-form-input w-full sm:w-auto">
                                                        <InputGroup className="flex flex-row items-end bg-transparent inputBox" style={{borderBottomColor: "#434343", marginBottom: "0px"}}>
                                                            <InputGroupAddon addonType="prepend" className="bg-transparent inputBox" style={{ marginBottom: "0px"}}>
                                                                <InputGroupText>{userCurrency}</InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder="100"
                                                                type="number"
                                                                value={state.cpl}
                                                                className="bg-transparent p-2 pb-0 w-full sm:w-24 inputBox"
                                                                style={{marginBottom: "0px"}}
                                                                onChange={e => handleChange(e.target.value, 'cpl')
                                                                }
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                                <Row className="tool-form-row flex flex-col sm:flex-row items-start sm:items-end justify-between py-4 body2">
                                                    <Col sm={9} className="tool-form-label">
                                                        Show Up Rate
                                                    </Col>
                                                    <Col sm={3} className="tool-form-input w-full sm:w-auto">
                                                        <InputGroup className="flex flex-row items-center bg-transparent inputBox" style={{borderBottomColor: "#434343", marginBottom: "0px"}}>
                                                            <Input
                                                                placeholder="50"
                                                                type="number"
                                                                value={state.showUpRate}
                                                                className="bg-transparent p-2 pb-0 w-full sm:w-24 inputBox"
                                                                style={{marginBottom: "0px"}}
                                                                onChange={e => handleChange(e.target.value, 'showUpRate')
                                                                }
                                                            />
                                                            <InputGroupAddon addonType="append" className="bg-transparent inputBox" style={{ marginBottom: "0px"}}>
                                                                <InputGroupText>%</InputGroupText>
                                                            </InputGroupAddon>
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                                <Row className="tool-form-row flex flex-col sm:flex-row items-start sm:items-end justify-between py-4 body2">
                                                    <Col sm={9} className="tool-form-label">
                                                        Show Up to Customer
                                                    </Col>
                                                    <Col sm={3} className="tool-form-input w-full sm:w-auto">
                                                        <InputGroup className="flex flex-row items-center bg-transparent inputBox" style={{borderBottomColor: "#434343", marginBottom: "0px"}}>
                                                            <Input
                                                                placeholder="50"
                                                                type="number"
                                                                value={state.showUpCustomer}
                                                                className="bg-transparent p-2 pb-0 w-full sm:w-24 inputBox"
                                                                style={{marginBottom: "0px"}}
                                                                onChange={e => handleChange(e.target.value, 'showUpCustomer')
                                                                }
                                                            />
                                                            <InputGroupAddon addonType="append" className="bg-transparent inputBox" style={{ marginBottom: "0px"}}>
                                                                <InputGroupText>%</InputGroupText>
                                                            </InputGroupAddon>
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </div>
                                    </Col>
                                    <Col lg={5} className="right-input-col p-0 w-full sm:w-5/12">
                                        <Card className="right-input-card">
                                            <CardBody className="p-0 calculated-results">
                                                <div className="result">
                                                    <h4 className="text-white text-center mb-4">Cost Per Show Up </h4>
                                                    <h4 className="text-white mb-4">
                                                        {!isFinite(state.costPerShowUp)
                                                            ? '-'
                                                            : `${accounting
                                                                .formatMoney(
                                                                    state.costPerShowUp,
                                                                    userCurrency,
                                                                )
                                                                .replace('.00', '')}`}
                                                    </h4>
                                                </div>
                                                <div className="result">
                                                    <h4 className="text-white mb-4">CPA</h4>
                                                    <h4 className="text-white mb-4">
                                                        {!isFinite(state.cpa)
                                                            ? '-'
                                                            : `${accounting
                                                                .formatMoney(state.cpa, userCurrency)
                                                                .replace('.00', '')}`}
                                                    </h4>
                                                </div>
                                                <div className="result">
                                                    <h4 className="text-white mb-4">Day 1 ROI</h4>
                                                    <h4 className="text-white mb-4">
                                                        {!isFinite(state.day1Roi)
                                                            ? '-'
                                                            : `${accounting
                                                                .formatNumber(state.day1Roi, 2)
                                                            } %`}
                                                    </h4>
                                                </div>
                                                <div className="result">
                                                    <h4 className="text-white mb-4">Lifetime ROI</h4>
                                                    <h4 className="text-white mb-4">
                                                        {!isFinite(state.lifetimeRoi)
                                                            ? '-'
                                                            : `${accounting
                                                                .formatNumber(state.lifetimeRoi, 2)} %`}
                                                    </h4>
                                                </div>
                                                
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </main>
            <Footer />
        </>
    );
};
