import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        available: true, sectionName: 'Things to keep in mind',
    },
    {
        available: true, sectionName: 'Website/Funnel Builder',
    },
    {
        available: true, sectionName: 'Communication: AgenciFlow',
    },
    {
        available: true, sectionName: 'Invoicing: AgenciFlow',
    },
    {
        available: true, sectionName: 'Project Management: AgenciFlow',
    },
    {
        available: true, sectionName: 'Contracts: HelloSign',
    },
    {
        available: true, sectionName: 'Screen Recording/Sharing: Loom',
    },
    {
        available: true, sectionName: 'Video Hosting: Vimeo',
    },
    {
        available: true, sectionName: 'Scheduling Software: Calendly',
    },
    {
        available: true, sectionName: 'Full Suite: Google Suite',
    },
    {
        available: true, sectionName: 'Online/Remote Calls: Zoom',
    },
];

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (

        <PlugAndPlayWrapper title="Agency Software Stack">
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                // selectedSection={selectedSection}
                                // setSelectedSection={onSelectSection}
                                // filter={filter}
                                // selectFilter={selectFilter}
                                // subFilter={subFilter}
                                // selectSubFilter={selectSubFilter}

                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]} className="plug-block">
                            <h2 className="mb-4">Agency Software Stack</h2>

                            <div className="plug-block-subheading">Things to keep in mind:</div>

                            <p>
                                Before I get into the recommendations, the main thing you need to remember is that there’s a big difference between an investment & an expense.
                                :
                            </p>

                            <p>
                                I know that for most of you - you’re frustrated at the thought of software cost. Don’t be. It’s an investment into your business and just remember that you can upgrade your setup as the months go along!
                            </p>

                            <p>In this Plug N Play I’m going to give you paid, cheap & free options for pretty much every single software so bear that in mind… you won’t be boxed into any specific software.</p>

                            <p>
                                The other thing I want you to keep in mind is that many of these softwares you really only need once you ACTUALLY sign a client. So the running cost while you’re in the early stages isn’t much, but once you actually sign a client then you can use it!
                            </p>

                            <p>
                                Finally, the thing to really bear in mind is that even a $100-$200/pm cost to run the business is nothing. Most business owners would laugh at you if you told them that’s all it cost to run your business. You need to remember that most business owners are spending $20,000-$50,000 per month just to get their business off the ground.
                            </p>
                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">Website/Funnel Builder: Clickfunnels</div>

                            <p>
                                <span className="bold">Fee:</span>
                                {' '}
                                $97/pm after a full 14-day free trial available through exclusively this link
                            </p>

                            <a href="https://growyouragency.com/clickfunnelstrail" target="_blank" rel="noopener noreferrer"><p>https://growyouragency.com/clickfunnelstrail</p></a>

                            <p>
                                <span className="bold">Severity: </span>
                                10/10
                            </p>

                            <p>
                                <span>When To Use:</span>
                                {' '}
                                Sign up NOW! This tool is a game changer because I literally get to give you half of my agency for you to COPY + PASTE using the funnel links. The 20+ website templates + 5 on-boarding funnels are exclusively available via ClickFunnels.
                            </p>

                            <p>
                                If you want to get started with a 14-day free trial click
                                {' '}
                                <a href="https://growyouragency.com/clickfunnelstrail" target="_blank" rel="noopener noreferrer">HERE</a>
                                .
                            </p>

                        </div>
                        <div ref={refs[2]} className="plug-block">
                            <div className="plug-block-subheading">Communication: AgenciFlow</div>


                            <p>
                                <span className="bold">Alternative:</span>
                                {' '}
                                Slack (Between $20-$60/pm to manage 3-5 clients)
                            </p>

                            <p>
                                <span className="bold">
                                    Fee:
                                </span>
                                {' '}
                                $69/pm (27% OFF with the code GYA2022) with unlimited clients & also all the features of AgenciFlow included.
                            </p>

                            <p>
                                <div>
                                    <span className="bold">AgenciFlow: </span>
                                    <a href="https://www.agenciflow.com" target="_blank"> https://www.agenciflow.com </a>
                                </div>
                            </p>

                            <p>
                                <span className="bold">Severity: </span>
                                10/10
                            </p>

                            <p>
                                <span className="bold">When To Use:</span>
                                {' '}
                                Immediately after signing your first client - AgenciFlow will allow you to communicate seamlessly with all your clients & contractors, both on desktop & mobile - including voice notes & GIFs natively.
                            </p>

                            <p>
                                <span className="bold">How To Use The Software:</span>
                                {' '}
                                Once you’ve created an account, start creating projects to manage all internal tasks with your team. After creating projects it’s time to take care of all aspects of your clients work flow. Simply create a client inside the platform and invite them and their team to start collaborating. You will be able to manage all your communications and tasks to make sure you are always up-to-date and never miss messages from your clients again!

                            </p>

                        </div>

                        <div ref={refs[3]} className="plug-block">
                            <div className="plug-block-subheading">Invoicing: AgenciFlow</div>

                            <p>
                                <span className="bold">Alternative: </span>
                                Use SimpleInvoices ($10/pm)
                            </p>

                            <p>
                                <span className="bold">Fee: </span>
                                $69/pm (27% OFF with the code GYA2022) - but you get the communication & project management features are included anyways.
                            </p>

                            <p>
                                <div>
                                    <span className="bold">AgenciFlow: </span>
                                    <a href="https://www.agenciflow.com" target="_blank"> https://www.agenciflow.com </a>
                                </div>
                            </p>

                            <p>
                                <span className="bold">Severity: </span>
                                10/10
                            </p>

                            <p>
                                <span className="bold">When To Use: </span>
                                Take advantage of the 2 week free trial immediately after signing your first client - Connect stripe to AgenciFlow and send your invoice.
                            </p>

                            <p>
                                <span className="bold">How To Use The Software: </span>
                                Visit Module 8 - Operational Excellency 101 Powered By Agenciflow.
                            </p>
                        </div>

                        <div ref={refs[4]} className="plug-block">
                            <div className="plug-block-subheading">Project Management: AgenciFlow</div>

                            <p>
                                <span className="bold">Alternative:</span>
                                {' '}
                                Asana ($30/pm for you + contractor)
                            </p>

                            <p>
                                <span className="bold">Fee:</span>
                                {' '}
                                $69/pm (27% OFF with the code GYA2022) - but you get the communication & invoicing features are included anyways.
                            </p>

                            <p>
                                <div>
                                    <span className="bold">AgenciFlow: </span>
                                    <a href="https://www.agenciflow.com" target="_blank"> https://www.agenciflow.com </a>
                                </div>
                            </p>

                            <p>
                                <span className="bold">Severity: </span>
                                10/10
                            </p>

                            <p>
                                <span className="bold">When To Use:</span>
                                {' '}
                                Once you have clients, a contractor or both on board - it’s time to commit to project management. AgenciFlow has you covered with a simple to use interface that links to each client directly.
                            </p>

                            <p>
                                <span className="bold">How To Use The Software:</span>
                                {' '}
                                Visit Module 8 - Operational Excellency 101 Powered By Agenciflow.
                            </p>
                        </div>

                        <div ref={refs[5]} className="plug-block">
                            <div className="plug-block-subheading">Contracts: HelloSign</div>

                            <p>
                                <span className="bold">Alternative:</span>
                                {' '}
                                Manually send clients the contract, they sign it and scan, then you sign it and scan. Absolute nightmare.
                            </p>

                            <p>
                                <span className="bold">Fee:</span>
                                {' '}
                                $15/pm and is available
                                {' '}
                                <a href="https://www.hellosign.com/" target="_blank" rel="noopener noreferrer">HERE</a>
                            </p>

                            <p>
                                <span className="bold">Severity: </span>
                                8/10
                            </p>

                            <p>
                                <span className="bold">When To Use:</span>
                                {' '}
                                When you’re about to sign your first client, sign up to this service ASAP and make sure you throw it into your on-boarding module (more on this later).
                            </p>

                            <p>
                                <span className="bold">How To Use The Software: </span>
                                Coming in later modules.
                            </p>
                        </div>

                        <div ref={refs[6]} className="plug-block">
                            <div className="plug-block-subheading">Screen Recording/Sharing: Loom</div>
                            <p>
                                <span className="bold">Alternative:</span>
                                {' '}
                                Use Loom’s free version.
                            </p>

                            <p>
                                <span className="bold">Fee:</span>
                                {' '}
                                $10/pm and is available
                                <a href="https://www.loom.com/" target="_blank" rel="noopener noreferrer">HERE</a>
                            </p>

                            <p>
                                <span className="bold">Severity:</span>
                                {' '}
                                7/10
                            </p>

                            <p>
                                <span className="bold">When To Use:</span>
                                {' '}
                                Once you get near 100 recorded videos so you want a backlog of them all stored. Loom pro allows you the app that lets you record 4k & HD screen capture. It also stores more than 100 videos which is all you get with the basic version.
                            </p>

                            <p>
                                <span className="bold">How To Use The Software:</span>
                                {' '}
                                Self explanatory.
                            </p>
                        </div>

                        <div ref={refs[7]} className="plug-block">
                            <div className="plug-block-subheading">Video Hosting: Vimeo</div>
                            <p>
                                <span className="bold">Fee:</span>
                                {' '}
                                Free (500mb per week limit) - Click
                                {' '}
                                <a href="https://vimeo.com/" target="_blank" rel="noopener noreferrer">HERE</a>
                                {' '}
                            </p>

                            <p>
                                <span className="bold">When To Use:</span>
                                {' '}
                                Sign up once you shoot the website scheduling page video (coming soon) and you’ll also need it when you film the on-boarding modules.
                            </p>

                            <p>
                                <span className="bold">How To Use The Software:</span>
                                {' '}
                                Self explanatory.
                            </p>
                        </div>

                        <div ref={refs[8]} className="plug-block">
                            <div className="plug-block-subheading">Scheduling Software: Calendly</div>

                            <p>
                                <span className="bold">Alternative:</span>
                                {' '}
                                Use Google Calendar. With this option you can’t allow someone to just book in a time automatically from your available slots. You’ll have to actually ask them - ‘What time works for you?’ And ask them what email is best for the Cal invite.
                                When you’re face to face or on the phone with someone, this is the best option but it’s so important to have an automatic scheduler where someone can just book in a Discovery Call
                            </p>

                            <p>
                                <span className="bold">
                                    Fee:
                                </span>
                                {' '}
                                $8/pm and is available
                                {' '}
                                <a href="https://calendly.com/" target="_blank" rel="noopener noreferrer">HERE</a>
                            </p>

                            <p>
                                <span className="bold">Severity:</span>
                                {' '}
                                9/10
                            </p>

                            <p>
                                <span className="bold">When To Use: </span>
                                Once you start reaching out to clients because you’ll need a scheduling link for them to book in a time to speak with you
                            </p>

                            <p>
                                <span className="bold">How To Use The Software: </span>
                                IMPORTANT! Make sure you integrate Zoom with your Calendly, 
                                <a href="https://help.calendly.com/hc/en-us/articles/360010008093-Getting-started-with-Zoom" target="_blank" rel="noopener noreferrer"> learn how here</a>. You can follow Calendly’s new user guide <a href="https://help.calendly.com/hc/en-us/articles/5325066629655-New-user-guide#new-user-guide-0-0" target="_blank" rel="noopener noreferrer">HERE</a>.
                            </p>

                            <p>
                                <span className="bold">
                                    Questions For Discovery Calls:
                                </span>
                            </p>

                            <ol>
                                <li>What’s your website (Type ‘N/A’ if you don’t have one) [Textbox small]</li>
                                <li>Your business social media links (As many as possible, hit shift for extra space) [Textbox medium]</li>
                                <li>What’s your currently monthly revenue? [Dropdown list]</li>
                                <p>$10k-$25k per month</p>
                                <p>$25k-$50k per month</p>
                                <p>$50k-$100k per month</p>
                                <p>$100k-$250k per month</p>
                                <p>$250k-$500k per month</p>
                                <p>More than $500,000+ per month</p>
                                <li>What’s your desired monthly revenue in 12 months? [Textbox small]</li>
                                <li>
                                    If you're accepted how soon can you get started? [Textbox small]
                                </li>
                                <li>Have you ever worked with an agency before? (If yes, what was your experience with them?) [Textbox medium]</li>
                                <li>
                                    What sets you apart from other applicants wanting to work with us? [Textbox medium]
                                </li>
                            </ol>
                        </div>

                        <div ref={refs[9]} className="plug-block">
                            <div className="plug-block-subheading">Full Suite: Google Suite</div>

                            <p>
                                <span className="bold"> Fee:</span>
                                {' '}
                                $6/pm for basic plan (All you need) - Click
                                {' '}
                                <a href="https://gsuite.google.com" target="_blank" rel="noopener noreferrer">HERE</a>
                                {' '}
                            </p>

                            <p>
                                <span className="bold">Severity: </span>
                                10/10
                            </p>

                            <p>
                                <span className="bold">When To Use: </span>
                                DO NOT sign up now, wait until the next module where you would’ve already bought a domain and that makes it easier to sign up to GSuite once you have your registered domain, business email, etc
                            </p>

                            <p>
                                <span className="bold">How To Use The Software: </span>
                                Coming in later modules.
                            </p>
                        </div>

                        <div ref={refs[10]} className="plug-block">
                            <div className="plug-block-subheading">
                                Online/Remote Calls: Zoom
                            </div>

                            <p>
                                <span className="bold">Alternative: </span>
                                {' '}
                                Just use the free version - Hands down the best way to have online meetings with clients. Unique zoom meeting ID allows you to put the link to join the call right in the confirmation email of Zoom. The only issue with the free version is that it’s got a 40-minute max meeting length when there are more than 2 people in the meeting.
                            </p>

                            <p>
                                <span className="bold">Fee: </span>
                                $15/pm and is available
                                {' '}
                                <a href="https://zoom.us/" target="_blank" rel="noopener noreferrer">HERE</a>
                            </p>

                            <p>
                                <span className="bold">Severity: </span>
                                {' '}
                                8/10
                            </p>

                            <p>
                                <span className="bold">When To Use: </span>
                                {' '}
                                If you have the money and want to save embarrassment from meeting ,ending after 40min if they bring a business partner. With Zoom pro literally the only notable difference is that you can have group meetings longer than 40 minutes. This is super important if they bring a business partner or you have your strategy session once they become a client with a contractor or their business partner present.
                            </p>

                            <p>
                                <span className="bold">How To Use The Software: </span>
                                {' '}
                                Self explanatory.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
