import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useConfig } from '@genflow/core';
import { useQuery } from '@apollo/react-hooks';
import { orderBy } from 'lodash';
import PaymentItem from './PaymentItem';
import GetClientCustomerPaymentsQuery from '../../../../api/getClientCustomerPayments';
import NewPaymentModal from './modals/NewPaymentModal';
import FirstPaymentSuccessModal from './modals/FirstPaymentSuccessModal';
import CustomLoader from '../../../../components/CustomLoader';

const ClientPayments = ({
    selectedClient,
    openNewPayment,
    onCloseNewPayment,
    openFirstPaymentSuccess,
    onCloseFirstPaymentSuccess,
    setOpenFirstPaymentSuccess,
}) => {
    const { vendorUuid } = useConfig();
    const {
        data, error: toolError, loading, refetch,
    } = useQuery(GetClientCustomerPaymentsQuery, {
        variables: {
            vendorUuid,
            clientCustomerUuid: selectedClient?.id,
        },
        skip: !selectedClient,
        notifyOnNetworkStatusChange: true,
    });

    const payments = orderBy(data?.getClientCustomerPayments?.data || [], ['createdTimestamp'], ['desc']);

    return (
        <div style={{
            padding: '0.5rem',
        }}
        >
            <CustomLoader
                loading={loading}
            >
                {
                    payments?.length
                        ? (
                            <div className="body2 text-white pl-4"
                            >
                                Payments
                            </div>
                        )
                        : null
                }

                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    margin: '0 1rem',
                }}
                >
                    {(payments || []).map(payment => (
                        <PaymentItem payment={payment} />
                    ))}
                </div>
            </CustomLoader>

            <NewPaymentModal
                selectedClient={selectedClient}
                open={openNewPayment}
                onClose={onCloseNewPayment}
                firstPayment={!payments?.length}
                onFirstPaymentCreated={() => setOpenFirstPaymentSuccess(true)}
            />


            <FirstPaymentSuccessModal
                open={openFirstPaymentSuccess}
                onClose={onCloseFirstPaymentSuccess}
            />
        </div>
    );
};

ClientPayments.propTypes = {

};

export default ClientPayments;
