import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        available: true, sectionName: 'Installation',
    },
    {
        available: true, sectionName: 'Tracking',
    },
];
 
const title = "Tracking Emails for Manual Outreach"

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div className="plug-block"> 
                            <p>If you automate your cold email outreach using LemList, you can avail yourself of their reporting tools built into the platform. </p>

                            <p>
                                Manual outreach requires a plug-in that you install on your browser.
                            </p>

                            <p>
                                <a href="https://chrome.google.com/webstore/detail/unlimited-email-tracker/gojogohjgpelafgaeejgelmplndppifh?utm_source=referral&utm_medium=landing&utm_campaign=secondary_extensions&utm_content=tracker&_gl=1*1lk3brs*_ga*MTc4ODA4NzI3OC4xNTkxMDIyMjA4*_ga_BNRTCNFP5Y*MTYyNDI3MDIyMC4xNy4wLjE2MjQyNzAyMjAuNjA." target="_blank" rel="noopener noreferrer">Snov.io</a>
                                {' '}
                                offers a subscription-free, unlimited email tracking plugin.
                            </p>
                        </div>
                        <div ref={refs[0]} className="plug-block">
                            <div className="plug-block-subheading">
                                Installation
                            </div>

                            <p>Click the link above to install the Chrome plugin on your browser. Next, navigate to Gmail. </p>

                            <p>
                                You will receive a prompt asking you if you want to turn this on for your inbox.
                            </p>

                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">
                                Tracking

                            </div>

                            <p>
                                All emails sent from the browser (not from your phone, or an email client like Outlook) will be tracked.
                            </p>

                            <p>This will be displayed as:</p>

                            <img src="https://img.genflow.com/gya/plugandplays/tracking-emails-1-9e273a83-jpg.jpg" alt="" style={{ width: '50%' }} />
                        </div>

                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
