import { createMuiTheme } from '@material-ui/core';

const PRIMARY = '#1882de';
const SECONDARY = '#2dce89';
const TEXT_LIGHT = '#ffffff';
const TEXT_DARK = 'rgba(0, 0, 0, 0.95)';
const SELECTED_BG = 'linear-gradient(35deg, #1882de 0, #1830de 100%)';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: PRIMARY,
        },
        secondary: {
            main: SECONDARY,
            contrastText: TEXT_LIGHT,
        },
        action: {
            disabled: '#6c6c6c',
            disabledOpacity: 0.65,
            disabledBorder: '1px solid #6c6c6c',
        },
    },
    typography: {
        fontFamily: '-apple-system,system-ui,BlinkMacSystemFont,',
    },
    props: {
        MuiButtonBase: {
            disableRipple: true,
        },
        MuiTabs: {
            TabIndicatorProps: {
                style: {
                    display: 'none',
                },
            },
        },
    },
    overrides: {
        MuiButton: {
            root: {
                fontSize: '0.875rem',
                fontFamily: 'inherit',
                fontWeight: 600,
                padding: '10px 20px !important',
                '&:hover': {
                    backgroundColor: PRIMARY,
                    color: TEXT_LIGHT,
                    boxShadow: '0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08)',
                    transform: 'translateY(-1px)',
                },
            },
            outlined: {
                '&:hover': {
                    backgroundColor: PRIMARY,
                    color: `${TEXT_LIGHT} !important`,
                    boxShadow: '0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08)',
                },
            },
            contained: {
                '&:hover': {
                    backgroundColor: PRIMARY,
                    color: `${TEXT_LIGHT} !important`,
                    boxShadow: '0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08)',
                },
            },
            outlinedPrimary: {
                border: `1px solid ${PRIMARY}`,
                '&:hover': {
                    backgroundColor: PRIMARY,
                    color: `${TEXT_LIGHT} !important`,
                    boxShadow: '0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08)',
                },
            },

        },
        MuiPaper: {
            elevation1: {
                boxShadow: '0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07)',
            },
            elevation4: {
                boxShadow: '0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07)',
                '&:hover': {
                    boxShadow: '0 1rem 3rem rgba(0, 0, 0, 0.175)',
                },
            },
        },
        MuiTab: {
            root: {
                fontSize: '0.9rem',
                fontWeight: 700,
                margin: '16px 0',
                color: TEXT_DARK,
                '&.MuiTab-textColorPrimary': {
                    color: TEXT_DARK,
                },
                '&.MuiTab-textColorPrimary.Mui-selected': {
                    color: TEXT_LIGHT,
                    background: SELECTED_BG,
                },
                '&.PrivateTabIndicator-root-1': {
                    display: 'none',
                },
            },
        },
        MuiList: {
            root: {
                padding: 0,
            },
            padding: {
                paddingTop: 0,
            },
        },
        MuiListItem: {
            root: {
                height: 80,
                borderBottom: '1px solid rgba(224, 224, 224, 1)',
            },
        },
        MuiDrawer: {
            root: {
                height: 'calc(100%-220px)',
                maxHeight: 600,
            },
            paperAnchorDockedLeft: {
                maxHeight: 600,
                top: 299,
            },
        },
        MuiCssBaseline: {
            '@global': {
                '.Mui-disabled': {
                    opacity: 0.5,
                    border: '1px solid #6c6c6c',
                },
            },
        },
    },

});

export default theme;
