import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import { Link } from 'react-router-dom';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';
import Image5 from '../../../../../assets/img/plugandplay/using-sales-navigator-5.gif';
import Image7 from '../../../../../assets/img/plugandplay/using-sales-navigator-7.png';
import Image9 from '../../../../../assets/img/plugandplay/using-sales-navigator-9.gif';


const sections = [
    {
        available: true, sectionName: 'Before you start',
    },
    {
        available: true, sectionName: 'Your settings',
    },
    {
        available: true, sectionName: 'The basics',
    },
    {
        available: true, sectionName: 'Searching',
    },
    {
        available: true, sectionName: 'Search results',
    },
    {
        available: true, sectionName: 'Saving a search',
    },
    {
        available: true, sectionName: 'Saving leads to a list',
    },
];

const title = "Using Sales Navigator"

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]}  className="plug-block"> 
                            <div className="plug-block-subheading">
                                Before you start:

                            </div>

                            <p>
                                What you’ll need:
                            </p>

                            <ul>
                                <li>
                                    <Link to="/resources/plug-and-play/creating-your-linkedin-profile">An optimised LinkedIn account</Link>
                                </li>
                            </ul>

                            <p>
                                Using Sales Navigator requires a premium LinkedIn subscription.
                            </p>

                            <p>
                                Go to
                                {' '}
                                <a href="https://www.linkedin.com/premium/products/?indexIntentType=explore&intentType=explore" target="_blank" rel="noopener noreferrer">LinkedIn Premium and select</a>
                                {' '}
                                ‘Sales’.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/using-sales-navigator-1-ec269e7f-jpg.jpg" alt="" style={{ marginBottom: '1rem', width: '50%' }} />
                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">
                                Your settings
                            </div>


                            <p>
                                Before you do anything else, make sure you review your settings.
                            </p>

                            <p>
                                Go to the the
                                {' '}
                                <a href="https://www.linkedin.com/sales/settings" target="_blank" rel="noopener noreferrer">settings section</a>
                                {' '}
                                of Sales Navigator and select::
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/using-sales-navigator-2-63acefc1-jpg.jpg" alt="" style={{ marginBottom: '1rem' }} />

                            <p>
                                ‘Your name, headline, and location’.
                            </p>


                            <p>
                                Next, head to Sales Preferences where you have the following options:

                            </p>

                            <p className="bold">Geographies</p>

                            <p>
                                This is self explanatory: this is where you select your preferences by country, state, city, town or region. You can select multiple areas.
                            </p>

                            <p className="bold">
                                Industry
                            </p>

                            <p>
                                Whilst you may only work in one niche, it might make sense to make a selection of multiple industries. Please note, LinkedIn only offers selection from an established list.
                                {' '}
                                <Link to="/resources/plug-and-play/sales-navigator-industry-list">You can read this here</Link>

                                .
                            </p>

                            <p className="bold">
                                Company size
                            </p>

                            <p>
                                You can choose between: Self-employed, 1-10, 11-50, 51-200, 201-500, 501-1000, 1001-5000, 5001-10,000, 10,000+
                            </p>

                            <p>
                                It’s down to you to decide which company size to pursue but most SMMA owners will prefer to work with businesses between 1-150.
                            </p>

                            <p className="bold">
                                Function
                            </p>

                            <p>
                                Function describes the individuals ‘function’ or role within a business: for instance, they may work in Real Estate, but as an accountant, or in human resources - chances are you wouldn’t want to reach out to them.
                            </p>

                            <p>
                                Instead, you’re better to leave this unselected, or select ‘marketing’.
                            </p>

                            <ul>
                                <li>Accounting</li>
                                <li>Administrative</li>
                                <li>Arts and Design</li>
                                <li>
                                    Business Development
                                </li>
                                <li>
                                    Community and Social Services
                                </li>
                                <li>
                                    Consulting
                                </li>
                                <li>
                                    Education
                                </li>
                                <li>Engineering</li>
                                <li>Entrepreneurship</li>
                                <li>Finance</li>
                                <li>
                                    Healthcare Services
                                </li>
                                <li>
                                    Human Resources
                                </li>
                                <li>
                                    Information Technology
                                </li>
                                <li>
                                    Legal
                                </li>
                                <li>
                                    Marketing
                                </li>
                                <li>
                                    Media and Communication
                                </li>
                                <li>
                                    Military and Protective Services
                                </li>
                                <li>Operations</li>
                                <li>
                                    Product Management
                                </li>
                                <li>
                                    Program and Project Management
                                </li>
                                <li>Purchasing</li>
                                <li>Quality Assurance</li>
                                <li>
                                    Real Estate
                                </li>
                                <li>Research</li>
                                <li>Sales</li>
                                <li>Support</li>
                            </ul>

                            <p className="bold">
                                Seniority level
                            </p>

                            <p>
                                Once you’re inside  the
                                {' '}
                                <a href="https://www.linkedin.com/sales/homepage" target="_blank" rel="noopener noreferrer">Sales Navigator</a>
                                , you’ll see the ‘Home page’ - complete with updates across accounts and leads you follow, as well as insights into your profile.
                            </p>

                            <p>
                                You can ignore this and head straight to the top tab where you’ll see:
                            </p>

                            <ul>
                                <li>
                                    Account lists
                                </li>
                                <li>
                                    Lead lists
                                </li>
                                <li>Messaging</li>
                                <li>Admin</li>
                            </ul>

                            <p>
                                Accounts are businesses that you follow. This is mostly irrelevant when it comes to outreach, where you’re more likely to follow individual leads rather than entire businesses: you should identify a single decision maker as part of your research.
                            </p>

                            <p>
                                In contrast, leads are individuals: the personal profiles of people on LinkedIn.
                            </p>

                        </div>
                        <div ref={refs[2]} className="plug-block">
                            <div className="plug-block-subheading">
                                Searching
                            </div>

                            <p>
                                The search function on Sales Navigator is the most important one.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/using-sales-navigator-3-07d32818-jpg.jpg" alt="" style={{ marginBottom: '1rem' }} />

                            <p>
                                You can either search through the top search bar, or click
                                {' '}
                                <a href="https://www.linkedin.com/sales/search/people?viewAllFilters=true" target="_blank" rel="noopener noreferrer">‘All filters’</a>
                                {' '}
                                to open up this box:
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/using-sales-navigator-4-b132ace4-jpg.jpg" alt="" style={{ marginBottom: '1rem' }} />

                            <p>
                                If you have filled in you sales preferences, you can use the toggle on the top right to automatically fill in your preferences:
                            </p>

                            <img src={Image5} alt="" style={{ marginBottom: '1rem' }} />

                            <p>As your sales preferences are unlikely to change with each search, the most important element of each search is ‘Keywords’. </p>

                            <p>
                                This is also where you can use a technique known as ‘Boolean Search’.
                            </p>

                            <p>
                                This sounds complicated and imposing: don’t worry it’s not.
                            </p>

                            <p>
                                It’s as simple as including the terms:
                            </p>

                            <ul>
                                <li>OR</li>
                                <li>AND</li>
                                <li>NOT</li>
                            </ul>

                            <p>
                                In keywords, along with the variable of inserting terms into quotation marks, or brackets.
                            </p>

                            <ul>
                                <li>
                                    Quotation marks are used to group keywords into a phrase, for example “ethical footwear”
                                </li>
                                <li>
                                    Brackets are used to group Boolean parameters, for example Vegan NOT (food OR catering)
                                </li>
                            </ul>

                            <p>
                                The majority of searches you perform will
                                {' '}
                                <span className="bold">not</span>
                                {' '}
                                require Boolean search terms.
                            </p>


                        </div>
                        <div ref={refs[3]} className="plug-block">
                            <div className="plug-block-subheading">
                                Search results
                            </div>

                            <p>
                                Once you have completed a search, with your terms and keywords you will be presented with a list of results:
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/using-sales-navigator-6-054eb887-jpg.jpg" alt="" style={{ marginBottom: '1rem' }} />

                            <p>
                                With your results, you can either:
                            </p>

                            <ul>
                                <li>
                                    Filter further using the Filters tab on the left
                                </li>
                                <li>
                                    Save the search
                                </li>
                                <li>
                                    Save individual leads to a list
                                </li>
                                <li>
                                    Save multiple leads to a list
                                </li>
                            </ul>

                        </div>

                        <div ref={refs[4]} className="plug-block">
                            <div className="plug-block-subheading">
                                Saving a search
                            </div>

                            <p>
                                If a search proves useful or yields positive results, you can save an entire search - this will auto-update.
                            </p>

                            <p>Click:</p>

                            <img src={Image7} alt="" style={{ marginBottom: '1rem', width: '50%' }} />

                            <p>
                                And you will be presented with:
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/using-sales-navigator-8-41898c27-jpg.jpg" alt="" style={{ marginBottom: '1rem' }} />

                            <p>
                                Name the search and choose the frequency that you’ll be updated on the list. It’s okay to choose ‘never’.
                            </p>
                        </div>

                        <div ref={refs[5]} className="plug-block">
                            <div className="plug-block-subheading">
                                Saving leads to a list
                            </div>

                            <p>
                                Whilst saving a search will provide you with your entire list of results, it’s possible to save an individual lead to a list. This is important for automation but also in collating leads.
                            </p>

                            <img src={Image9} alt="" style={{ marginBottom: '1rem' }} />

                            <p>
                                Note: you can add a lead that you have previously saved to another list by clicking ‘Add to another list’.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
