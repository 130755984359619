import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import { Link } from 'react-router-dom';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        available: true,
        sectionName: 'In today\'s teaching',
    },
    {
        available: true,
        sectionName: 'Setting up your dashboard',
    },
    {
        available: true,
        sectionName: 'What to read your dashboard',
    },
    {
        available: true,
        sectionName: 'Action step',
    },
];

const title = 'How To Read Your Dashboard for Cold';

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]}  className="plug-block">  
                        <h2 className="mb-4">{title}</h2>
                            <div className="plug-block-subheading">
                                In today's teaching
                            </div>

                            <ul>
                                <li>
                                    Setting up your dashboard
                                </li>
                                <li>
                                    What to read your dashboard
                                </li>
                            </ul>
                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">
                                Setting up your dashboard
                            </div>

                            <p>
                                Read the full Plug & Play for
                                {' '}
                                <Link to="/resources/plug-and-play/dream-dashboard-set-up">setting up your dashboard</Link>
                                .
                            </p>
                        </div>

                        <div ref={refs[2]} className="plug-block">
                            <div className="plug-block-subheading">
                                What to read your dashboard
                            </div>

                            <p>
                                After creating our dashboard there are a few things we have to understand on how to read it.
                            </p>

                            <div style={{ height: 350 }}>
                                <iframe
                                    id="videoPlayer"
                                    className="sproutvideo-player"
                                    src={'https://videos.sproutvideo.com/embed/119dd8b61613e0c998/0485801919392564?playerTheme=dark&amp;playerColor=263238'}
                                    frameBorder="0"
                                    allowFullScreen
                                    width="100%"
                                    height="100%"
                                />
                            </div>


                            <p>Here are the columns and their meaning</p>

                            <ol>
                                <li>
                                    <span className="bold">Results:</span>
                                    {' '}
                                    The result column tells you how much you've achieved of whatever you're optimizing for, if you're optimizing for leads it will tell you how many leads you've got, if you're optimizing for purchases it will tell you how many purchases you've got. If you are optimizing for reach it will tell you how many people you've reached, etc.
                                </li>
                                <li>
                                    <span className="bold">Cost per result:</span>
                                    {' '}
                                    It tells you how much how much you're spending per result. There is no benchmark for this as it depends completely on your client and their margins. Some clients are fine with $700 purchases, some can't go over $20. Some clients are dine with $40 dollar leads, some shouldn't go over $1
                                </li>
                                <li>
                                    <span className="bold">Reach:</span>
                                    {' '}
                                    how many different people have seen your ad. We don't look at this metric with detail but it is nice to know how many people are you reaching each day, week, etc.
                                </li>
                                <li>
                                    <span className="bold">Impressions: </span>
                                    {' '}
                                    how many times has your ad been shown. Regardless if someone has seen your ad more than once, it counts every time.
                                </li>
                                <li>
                                    <span className="bold">Frequency:</span>
                                    {' '}
                                    Impressions/reach: On cold we don't usually care about our frequency as none of our clients has small audiences but on retargeting we never go over 10 on a 14 day window.
                                </li>
                                <li>
                                    Amount spent
                                </li>
                                <li>
                                    Budget: Important to see how much you're spending per ad set or campaign
                                </li>
                                <li>
                                    Ad set name
                                </li>
                                <li>
                                    Quality Ranking
                                </li>
                                <li>
                                    Engagement ranking
                                </li>
                                <li>
                                    Conversion rate ranking
                                </li>
                                <li>
                                    Link clicks
                                </li>
                                <li>Website purchases</li>
                                <li>ROAS</li>
                                <li>CTR</li>
                                <li>CPM</li>
                                <li>
                                    Conversion rate
                                </li>
                                <li>
                                    Conversion rate unique
                                </li>
                                <li>
                                    AOV (Average order value)
                                </li>
                                <li>
                                    Funnel steps
                                </li>
                            </ol>
                        </div>

                        <div ref={refs[3]} className="plug-block">
                            <div className="plug-block-subheading">
                                Action step
                            </div>

                            <p>Create your own dashboard for you or your clients, make sure you save them. Remember this is a one time job that will save you a lot of time whenever you manage your ads and only needs to be done at the beginning. We see a lot of people that every time they're going to create a metric they go and change their columns, this is a bad practice that takes a lot of time in the long run.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
