/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';
import { Link } from 'react-router-dom';


import image1 from '../../../../../assets/img/plugandplay/scaling-horiz-vertical/1.png';
import image2 from '../../../../../assets/img/plugandplay/scaling-horiz-vertical/2.png';
import image3 from '../../../../../assets/img/plugandplay/scaling-horiz-vertical/3.png';

const sections = [
    {
        available: true,
        sectionName: 'What is vertical scaling?',
    },
    {
        available: true,
        sectionName: 'How To Create a Google Shopping Campaign',
    },
];

const title = 'Scaling Horizontally vs Scaling Vertically';

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]}  className="plug-block">  
                        <h2 className="mb-4">{title}</h2>
                            <div className="plug-block-subheading">What is vertical scaling?</div>

                            <p>
                                Scaling is simple: it’s making something bigger. And in advertising, it’s spending
                                more. It sounds very appealing when you have an ad set running at let's say $50/day
                                and it has 2 ROAS to scale it.
                            </p>

                            <p>
                                However, ‘vertically’ scaling - spending more on this ad set, and changing the
                                budget to $70 or $100 a day breaks two of the rules we’ve established in previous
                                modules, including ‘Load Balancing Budgets’: You should never touch anything that is
                                working and you never put too much money in one thing.
                            </p>

                            <p>
                                It sounds very easy to spend twice as much but more often than not what ends up
                                happening is that the ad set stops performing as well, and that your 2x ROAS can
                                turn to 1.2.
                            </p>

                            <p>
                                However, you shouldn’t dismiss this strategy just yet. We will see in the last
                                section of this module how we've implemented vertical scaling successfully to our
                                big ecomm campaigns using CBO's.
                            </p>

                            <p>
                                As a side note, Google is much better at handling big concentrated budgets and
                                scaling vertically works fantastically in there, as with anything it has its limits
                                on how much you can stress that system and that audience but it works surprisingly
                                well.
                            </p>

                            <p>
                                The way to do it is increasing the budget periodically until results start dropping
                                and the numbers are less favorable.
                            </p>

                            <img src={image1} alt="" />

                            <p>
                                By now you've watched the module
                                <Link to="/resources/plug-and-play/how-fb-works-inside">
                                    "How Facebook Works On The Inside"
                                </Link>
                                with that understanding: one theory as to why Facebook is not as good at handling
                                big budgets is that it has to get out of your cluster quicker - and your cluster
                                moves too quickly.
                            </p>
                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">What is horizontal scaling?</div>

                            <p>
                                Horizontal scaling can be referred to as doing more of the same, without each unit
                                getting bigger. In simpler terms it is basically having more ad sets or more
                                campaigns of whatever is working right now.
                            </p>

                            <p>
                                For example, if you have found that ‘broad’ works well for your ad bundle, you
                                should launch five more of those broad ad sets. THe same applies for lookalike
                                audiences, or interests that work.
                            </p>

                            <p>
                                Remember: for reasons explained Inside
                                <Link to="/resources/plug-and-play/how-fb-works-inside">
                                    "How Facebook Works On The Inside"
                                </Link>
                                - some of these ad sets won’t work for a number of reasons. So make sure to turn off
                                the ones that don’t.
                            </p>

                            <img src={image2} alt="" />
                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">Horizontal vs Vertical</div>

                            <p>If it’s not obvious yet - there is a clear preference for horizontal scaling!</p>

                            <p>
                                This process works across nearly every system: replicating smaller and simpler
                                things almost always outperforms increasing the complexity and output of a single
                                ‘cluster’.
                            </p>

                            <p>
                                As a rule of thumb complexity shouldn't be so high that a single person can't
                                understand the whole system.
                            </p>

                            <img src={image3} alt="" />
                        </div>

                        <div ref={refs[2]} className="plug-block">
                            <div className="plug-block-subheading">Scaling Ecomm IAG Media's way</div>

                            <p>
                                As explained on the video of "
                                <Link to="/resources/plug-and-play/utilising-cbos">Utilising CBOs</Link>
                                ", when trying to spend a
                                lot of money you do need to use a different strategy than just ad set budget
                                optimization.
                            </p>

                            <p>
                                When using CBOs, you can use more budget per campaign - for instance we would have
                                campaigns with around five ad sets and we would have a combined budget of over $500.
                                This would make over $100 per ad set.
                            </p>

                            <p>
                                When the CBO is performing well, you have two options. The first one is to
                                horizontally scale that specific campaign which, in layman’s terms, just means
                                duplicating it with the same budget and letting it run.
                            </p>

                            <p>
                                The other strategy is to vertically scale which roughly means to increase the budget
                                by around 20%.
                            </p>

                            <p>
                                As you can see in this video there are multiple campaigns with different numbers
                                which means that we duplicated a lot of them many times. You can also notice that we
                                have different budgets for each one of these campaigns. This is because some of them
                                had started at a lower budget and we had to increase them.
                            </p>

                            <p>
                                We think that because bills have multiple audiences inside of them the budget is
                                better spread by letting Facebook choose what to do with it without forcing all the
                                budget into a cluster, this is of course just a hypothesis no one really knows how
                                Facebook manages all the budgets on the CBOs.
                            </p>

                            <div style={{ height: 350 }}>
                                <iframe
                                    id="videoPlayer"
                                    src="https://videos.sproutvideo.com/embed/449dd7ba1f1ce7cacd/54d667f97ee145a3?type=hd"
                                    frameBorder="0"
                                    allowFullScreen
                                    width="100%"
                                    height="100%"
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
