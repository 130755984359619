import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useConfig } from '@genflow/core';
import { useQuery } from '@apollo/react-hooks';
import { orderBy } from 'lodash';
import AdminPaymentItem from './AdminPaymentItem';
import GetClientCustomerPaymentsQuery from '../../../../api/getClientCustomerPayments';
import AdminReviewPaymentModal from './modals/AdminReviewPaymentModal';
import FirstPaymentSuccessModal from './modals/FirstPaymentSuccessModal';
import CustomLoader from '../../../../components/CustomLoader';
import NewPaymentModal from '../../Profile/components/modals/NewPaymentModal';

const AdminUserPayments = ({
    selectedUser,
}) => {
    const { vendorUuid } = useConfig();
    const [payments, setPayments] = useState([]);
    const [selectedPayment, setSelectedPayment] = useState();

    useEffect(() => {
        if (selectedUser) {
            let updatedPayments = selectedUser?.payments || [];
            updatedPayments = orderBy(updatedPayments || [], ['createdTimestamp'], ['desc']);
            setPayments(updatedPayments);
        } else {
            setPayments([]);
        }
    }, [selectedUser]);

    const onClickReview = payment => setSelectedPayment(payment);

    const onReviewDecision = (updatedPayment) => {
        console.log('updatedPayment', updatedPayment);
        setSelectedPayment(null);
    };

    return (
        <div style={{
            padding: '1rem',
        }}
        >
            <CustomLoader
                loading={!selectedUser}
            >
                {
                    payments?.length
                        ? (
                            <div style={{
                                fontSize: 20,
                                fontWeight: 'bold',
                            }}
                            >
                                Payments
                            </div>
                        )
                        : (
                            <div style={{
                                fontSize: 20,
                                fontWeight: 'bold',
                            }}
                            >
                                No payments made...
                            </div>
                        )
                }

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        margin: '0 -0.5rem',
                    }}
                >
                    {payments?.map(payment => (
                        <AdminPaymentItem payment={payment} onClickReview={() => onClickReview(payment)} />
                    ))}
                </div>
            </CustomLoader>

            <AdminReviewPaymentModal
                selectedUser={selectedUser}
                open={!!selectedPayment}
                onClose={() => setSelectedPayment(null)}
                selectedPayment={selectedPayment}
                onReviewDecision={onReviewDecision}
            />
        </div>
    );
};

AdminUserPayments.propTypes = {

};

export default AdminUserPayments;
