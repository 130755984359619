import elasticlunr from 'elasticlunr';
import tools from '../../assets/toolsContent';
import phases from '../../constants/phaseContent';
import plugAndPlay from '../../constants/plugAndPlayContent';

const index = elasticlunr(function () {
    this.addField('title');
    this.addField('body');
    this.addField('searchTerms');
    this.setRef('id');
});

const docs = [
    {
        title: 'Agency Navigator',
        body: 'The step by step process to starting & quickly growing your agency to $10,000/pm with structure & focus.',
        link: '/course/3351',
        searchTerms: '',
    },
    // {
    //     title: 'Copy Paste Agency',
    //     body: 'Join the upper echelon of agency owners clocking in six figure months by automating client attraction, refining service delivery & smoothing out operations.',
    //     link: '/lessons/2084',
    //     searchTerms: '',
    // },
    // {
    //     title: 'Meridian Mastermind',
    //     body: 'Transcend the game. Evolve from an agency to a private equity/incubator hybrid. Don’t just service clients, have ownership in them too. The straight path to 8-figs.',
    //     link: '/programs',
    //     searchTerms: '',
    // },
    ...tools,
    ...phases,
    ...plugAndPlay,
];

docs.forEach((doc, id) => index.addDoc({ ...doc, id }));

const addDoc = (title, body, link, searchTerms) => {
    if (!docs.find(doc => doc.title === title)) {
        const doc = {
            title,
            body,
            link,
            searchTerms,
            id: Object.keys(index.documentStore.docs).length,
        };
        index.addDoc(doc);
        docs.push(doc);
    }
};

export { docs, addDoc };
export default index;
