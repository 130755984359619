import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        available: true, sectionName: 'Domain',
    },
    {
        available: true, sectionName: 'Your Profile',
    },
    {
        available: true, sectionName: 'Your Signature',
    },
];


const title = "How to Set Up Your Email Account"

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                // selectedSection={selectedSection}
                                // setSelectedSection={onSelectSection}
                                // filter={filter}
                                // selectFilter={selectFilter}
                                // subFilter={subFilter}
                                // selectSubFilter={selectSubFilter}

                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div className="plug-block">
                            <p>
                                While you may already have an email account for your agency, you can still follow many of these steps to ensure maximum deliverability.
                            </p>
                        </div>
                        <div ref={refs[0]} className="plug-block">
                            <div className="plug-block-subheading">
                                Domain:
                            </div>

                            <p>
                                Remember: the name of your agency isn’t important, nor is the domain.

                            </p>

                            <p>
                                Don’t try and purchase something expensive: keep it simple.

                            </p>

                            <p>
                                And where possible, stick with a ‘.com’ suffix
                            </p>

                            <p>
                                <a href="https://domains.google.com/registrar/search" target="_blank" rel="noopener noreferrer">Purchase your domain via Google Domains</a>
                                , using your existing email account: this could be your personal email address, which even if it does not end with ‘@gmail’, can be used to create a gmail account.
                            </p>

                            <p>
                                Select your domain and click ‘add to basket’.
                            </p>

                            <p>
                                Next, go to your basket and add ‘Google Workspace’ with ‘Business Standard’.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/setup-email-account-1-43b0021a-jpg.jpg" alt="" style={{ marginBottom: '1rem' }} />

                            <p>
                                There is little difference between Starter and Standard: but with standard you receive a massive amount of storage within the subscription, as opposed to only 30GB. This can eliminate the cost of DropBox or other tools.
                            </p>

                            <p>
                                Once you have checked out, you should receive an email to your primary email address with your receipt, and logins for your new email account.
                            </p>
                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">
                                Your Profile:
                            </div>

                            <p>
                                Although Google does not reveal the rules behind deliverability, we can guess that the more complete a profile, the more likely Google will deliver your email into the main inbox, rather than categories or spam.

                            </p>

                            <p>
                                And something as simple as an approachable profile photo can hugely increase the chances your email will be opened on a desktop.
                            </p>

                            <p>
                                Once you have logged into your new email, go to the
                                {' '}
                                <a href="https://myaccount.google.com/personal-info" target="_blank" rel="noopener noreferrer">profile of the account</a>
                                {' '}
                                that you have just created.

                            </p>

                            <p>
                                Make sure you fill in your:
                            </p>

                            <ul>
                                <li>
                                    Full name
                                </li>
                                <li>
                                    Date of birth
                                </li>
                                <li>Gender</li>
                            </ul>

                            <p>
                                Next, go to the
                                {' '}
                                <a href="https://admin.google.com/ac/users" target="_blank" rel="noopener noreferrer">users section</a>
                                {' '}
                                of Google Workspace.
                            </p>

                            <ul>
                                <li>
                                    Upload a smiling image of yourself: make it friendly and approachable.
                                </li>
                            </ul>
                        </div>

                        <div ref={refs[2]} className="plug-block">
                            <div className="plug-block-subheading">
                                Your Signature:

                            </div>

                            <p>
                                Your email signature is another example of the small increments and subtext that can make outreach successful.
                            </p>

                            <p>
                                It can also affect deliverability unless implemented correctly.
                            </p>

                            <ul>
                                <li>
                                    Don’t use images, it can affect deliverability
                                </li>
                                <li>
                                    Don’t use a link in your outreach email - the more links in an email, the worse the deliverability will be
                                </li>
                                <li>
                                    Keep it simple and clean
                                </li>
                                <li>
                                    Demonstrate ethos (credibility) with a review, testimonial or feature
                                </li>
                            </ul>

                            <p>
                                For example:
                            </p>

                            <p>
                                <span className="bold">
                                    James Walker
                                </span>
                                {' '}
                                Founder | The Pet Agency
                            </p>

                            <p>
                                <span className="bold">w: </span>
                                {' '}
                                growyouragency.com
                            </p>

                            <p>
                                <span className="bold">e: </span>
                                {' '}
                                james@thepetagency.com
                            </p>

                            <p>
                                <span className="bold"> Testimonials:</span>
                                {' '}
                                Happy Paws Co. | Natural Animal Nutrition | BespokeCollar
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
