import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        available: true,
        sectionName: 'Things to keep in mind:',
    },
    {
        available: true,
        sectionName: 'Google Tag Manager Set Up:',
    },
    {
        available: true,
        sectionName: 'Setting Up The Triggers:',
    },
    {
        available: true,
        sectionName: 'Setting Up The Tags:',
    },
    {
        available: true,
        sectionName: 'Publishing:',
    },
    {
        available: true,
        sectionName: 'Verifying & Troubleshooting:',
    },
    {
        available: true,
        sectionName: 'BONUS SECTIONS',
    },
];

const title = 'Google Tag Manager';

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]}  className="plug-block">  
                        <h2 className="mb-4">{title}</h2>
                            <div className="plug-block-subheading">Things to keep in mind:</div>

                            <p>Setting up Google Tag Manager (GTM) might lead to more work upfront, but it will save a lot of time in the long run, while keeping all your clients' structure way more organized. </p>

                            <p>Google Tag Manager behaves like a “bucket” that holds all your pixels/tags/events from Facebook Ads, Google Ads, Google Analytics, Pinterest Ads, TikTok Ads, etc, all in one place and then injects them onto your pages. </p>

                            <p>This way, you avoid having to install each one of these tags individually every time you create a new page. After the initial set up is done, you’ll only ever have to install the GTM code on your pages, instead of having to install dozens of tags/pixels/events from different services and ad networks each time.</p>
                        </div>

                        <div className="plug-block" ref={refs[1]}>
                            <div className="plug-block-subheading">Google Tag Manager Set Up:</div>

                            <p>
                                The first thing we need to do is create an account on GTM. Keep in mind that this account will be created under your Google Account and that you can have multiple GTM accounts under one Google Account.
                            </p>

                            <p>
                                We recommend that you set up one GTM account per client, to keep things separate and organized, as well as one account for your own website.
                            </p>

                            <p>
                                You can go into
                                <a className="break-words" href="http://tagmanager.google.com" target="_blank" rel="noopener noreferrer">tagmanager.google.com</a>
                                {' '}
                                to create your accounts. Simply enough, all you have to do is click on “Create Account” in the initial page.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/googleTagManager1.png" alt="" />

                            <p>
                                After clicking it, you will be taken to a “add a new account window”. Here all you have to do is fill the boxes with your information in case you are creating the account for yourself, or fill it with your client’s information in case you’re creating the account for one of your clients.
                            </p>

                            <p>
                                “Account name” should be filled with either your agency’s name or your client’s business name in case you are creating it for a client.
                            </p>

                            <p>
                                In “Country” you just select the country related to the account.
                            </p>

                            <p>
                                In “Container Name” you have to put your own website if you are setting this up for yourself, or your client’s website if you are setting this up for a client. It doesn’t really need to be a real working website - that information is just for internal reference so that you know which project belongs to that container.
                            </p>

                            <p>In “Target Platform” you just select “Web”. </p>

                            <p>
                                After filling all this information you can click on “Create”.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/googleTagManager2.png" alt="" />

                            <p>You can then read the terms that will show up on your screen and accept them if you agree (make sure you actually read them). </p>

                            <img src="https://img.genflow.com/gya/plugandplays/googleTagManager3.png" alt="" />


                            <p>
                                Now you will be taken to the main homepage of the Google Tag Manager dashboard and it will ask you right away to install the Google Tag Manager Code on your pages.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/googleTagManager4.png" alt="" />

                            <p>
                                Let’s say I’m using Clickfunnels to manage my pages. All I would have to do in order to install the code is select my desired funnel on Clickfunnels and then go into the settings tab. There, you will have the “Head Tracking Code” field where you should paste the first code that Google Tag Manager gave you, and you will also have the “Body Tracking Code” field, where you should paste the second code that Google Tag Manager gave you.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/googleTagManager5.png" alt="" />

                            <p>
                                You can see the instructions on how to install GTM on other landing page builders at the end of this plug & play.
                            </p>
                        </div>

                        <div className="plug-block" ref={refs[2]}>
                            <div className="plug-block-subheading">Setting Up The Triggers:</div>

                            <p>Now, before installing your pixels and tags with GTM, we must set up the triggers. This is where you define in which pages you want your pixels, tags and events to fire. </p>

                            <p>As a rule of thumb, you probably want your Facebook Ads Pixel, Google Ads Tag, and all other conversion tracking pixels to fire on all pages of your website, so that you can track all your visitors. With that in mind, we will create a trigger that fires on all pages for those conversion tracking pixels.</p>

                            <p>Now, there’s also a few conversion events that you don’t want to fire on all pages, but just on specific web pages. Think about a purchase event or a lead event - you will only want them to fire on specific thank you pages. For these conversion events, we will create a trigger that only fires on one specific web page. </p>

                            <p>So, here’s how you do it:</p>

                            <p>1. In your GTM homepage, click into the “Triggers” tab, then click into “New”  </p>

                            <img src="https://img.genflow.com/gya/plugandplays/googleTagManager6.png" alt="" />

                            <p>2. In the new window that pops up, the first thing you should do is change the name of the trigger at the top of the page. Let’s call this first trigger “All Pages” </p>

                            <img src="https://img.genflow.com/gya/plugandplays/googleTagManager7.png" alt="" />

                            <p>3. Now click on the big white box that says “Choose a trigger type to begin setup” and then on the window that pops up you want to chose the 1st option “Page view” </p>

                            <img src="https://img.genflow.com/gya/plugandplays/googleTagManager8.png" alt="" />

                            <p>4. Then, where it says “This trigger fires on” you have to select “All Pages”. You can click save after that. And that’s it for our first trigger, it’s ready to go!</p>

                            <img src="https://img.genflow.com/gya/plugandplays/googleTagManager9.png" alt="" />

                            <p>5. Now let’s create our 2nd trigger that only fires on a specific page. Under the same triggers tab you can click on “New” again </p>

                            <img src="https://img.genflow.com/gya/plugandplays/googleTagManager10.png" alt="" />

                            <p>6. Let’s say that this time I want to create a Trigger that only fires on a Thank You Page that people are redirected to after they opt-in on my website. So, on the window that pops up you can name this trigger “Thank You Page”</p>

                            <img src="https://img.genflow.com/gya/plugandplays/googleTagManager11.png" alt="" />

                            <p>7. Again, you can click on the big white box that says “Choose a trigger type to begin setup” and then choose “Page View” again.</p>

                            <img src="https://img.genflow.com/gya/plugandplays/googleTagManager12.png" alt="" />

                            <p className="break-words" >
                                8. This time you want to select the option “Some Page Views”. These will give you a few additional configurations. There, you want to select “Page URL” “contains” and then on the box you can input the link to the page that you want your trigger to fire on. Let’s say that in my case, this page would have the following URL “example.com/thank-you”. That’s what I’d put there. Just type your specific page URL there, without the “https://” or “www.”
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/googleTagManager13.png" alt="" />

                            <p>In case you want this trigger to fire on more than one page, you just have to click on the little “+” Icon and repeat the process to the other Pages URLs. Once you are done, you can just click save. </p>

                        </div>

                        <div className="plug-block" ref={refs[3]}>
                            <div className="plug-block-subheading">Setting Up The Tags:</div>

                            <p>
                                The tags are the actual code for your pixels, tags and events. Let’s say we want to set up a Facebook Pixel tag. You would follow these steps:
                            </p>


                            <p>
                                1. The tags are the actual code for your pixels, tags and events. Let’s say we want to set up a Facebook Pixel tag. You would follow these steps:
                            </p>


                            <img src="https://img.genflow.com/gya/plugandplays/googleTagManager14.png" alt="" />

                            <p>2. On the window that pops up you can name this tag “Facebook Pixel” and then click on “choose a tag type to begin setup”</p>

                            <img src="https://img.genflow.com/gya/plugandplays/googleTagManager15.png" alt="" />

                            <p>3. You can then select “Custom HTML” on the new window that pops up </p>

                            <img src="https://img.genflow.com/gya/plugandplays/googleTagManager16.png" alt="" />

                            <p>4. Now you have to paste here your Facebook Pixel code. Here’s how to grab it in case you don’t know. </p>

                            <p>
                                a. You can visit this link
                                <a className="break-words" href="https://developers.facebook.com/docs/facebook-pixel/implementation/" target="_blank" rel="noopener noreferrer">https://developers.facebook.com/docs/facebook-pixel/implementation/</a>
                                {' '}
                                and copy the base code.
                            </p>

                            <p>b. Then inside the base code there’s two places where it says [your-pixel-id-goes-here]. You can replace this snippet with your pixel ID.</p>

                            <img src="https://img.genflow.com/gya/plugandplays/googleTagManager17.png" alt="" />

                            <p>
                                c. In case you don’t know your Pixel ID, you can get it from the Events Manager tab inside your Facebook Business Manager
                                <a className="break-words" href="https://business.facebook.com/events_manager2" target="_blank" rel="noopener noreferrer">(https://business.facebook.com/events_manager2) </a>
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/googleTagManager18.png" alt="" />

                            <p>5. Once you have the final code with your pixel ID, just paste it on the Google Tag Manger’s window </p>

                            <img src="https://img.genflow.com/gya/plugandplays/googleTagManager19.png" alt="" />

                            <p>6. Then under the triggering box you can click on “Choose a trigger to make this tag fire”. On the window that pops up you simply select the “All Pages” trigger. </p>
                            <img src="https://img.genflow.com/gya/plugandplays/googleTagManager20.png" alt="" />

                            <p>
                                7. Once that’s done you can just hit save. You now have your Facebook Pixel installed on all the pages. Now, there’s one thing missing: setting an event to fire on our thank you page. For this example, ‘Lead Event’ is used, since this thank you page is shown for people that opted-in on this website. You can see a full list of all available standard events from Facebook here:
                                <a className="break-words" href="https://en-gb.facebook.com/business/help/402791146561655?id=1205376682832142" target="_blank" rel="noopener noreferrer">https://en-gb.facebook.com/business/help/402791146561655?id=1205376682832142</a>
                            </p>

                            <p>8. From the same list above, copy the standard event code for your desired event</p>
                            <img src="https://img.genflow.com/gya/plugandplays/googleTagManager21.png" alt="" />

                            <p>9. Now, go back to GTM and click on “New” to add a new tag. </p>

                            <img src="https://img.genflow.com/gya/plugandplays/googleTagManager22.png" alt="" />

                            <p>Name the tag whatever fits best in your case. In our example we named it “Lead Facebook Event”. You can then click on “choose a tag type to begin setup”</p>

                            <img src="https://img.genflow.com/gya/plugandplays/googleTagManager23.png" alt="" />

                            <p>10. And again, you can select “Custom HTML”. In the window that pops up, you can paste your event code inside the HTML input. And now you should add the code code “script” (without the quotation marks) before your event code and add the code `script` (without the quotation marks) after your event code. Should look like this:</p>

                            <img src="https://img.genflow.com/gya/plugandplays/googleTagManager24.png" alt="" />

                            <p>11. Now under the triggering box, you can click on “Choose a trigger to make this tag fire”. On the window the pops up you can select the “Thank You Page” trigger (or whatever the trigger is that you created for your specific pages that you want to add a conversion event to). </p>

                            <img src="https://img.genflow.com/gya/plugandplays/googleTagManager25.png" alt="" />

                            <p>
                                12. And now we are done installing our Facebook Pixel and conversion events! Keep in mind that if you are using Clickfunnels, any new page that you add to your funnel will already have the Facebook Pixel implemented on it! The same applies to any landing page builder where you can globally edit the 'head' tag for all the pages, as we did with clickfunnels.
                            </p>
                        </div>

                        <div className="plug-block" ref={refs[4]}>
                            <div className="plug-block-subheading">Publishing:</div>

                            <p>
                                Now, there’s one last step to do before your changes go live to your website. All the changes we did until now are saved as a draft on Google Tag Manager, so we have to publish them. Here’s how to do that.
                            </p>

                            <p>1. On the main page for Google Tag Manager you can click on the “submit” button at the right portion of your screen. </p>

                            <img src="https://img.genflow.com/gya/plugandplays/googleTagManager26.png" alt="" />

                            <p>2. Then all you have to do in the window that pops up is naming this version so that you know what you changed. In my case, I named it “Added Facebook Pixel and Lead Event” </p>

                            <img src="https://img.genflow.com/gya/plugandplays/googleTagManager27.png" alt="" />

                            <p>
                                3. You can then click Publish and your updates will go live!
                            </p>
                        </div>

                        <div className="plug-block" ref={refs[5]}>
                            <div className="plug-block-subheading">Verifying & Troubleshooting:</div>

                            <p>
                                Always after publishing make sure that you verify if your updates worked. If you’re installing a pixel or an event, the ad network usually has a chrome extension to check if the pixel is working correctly. For Facebook, that’s the Facebook Pixel Helper. For Google that is the Google Tag Assistant.
                            </p>

                            <p>
                                On the Facebook Pixel Helper, that’s what a healthy pixel installation should look like:
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/googleTagManager28.png" alt="" />

                            <p>
                                If you have any standard events on the specific page you are looking at, the standard event should also show up there with the green checkmark in front of its name.
                            </p>

                            <p>On Google Tag Assistant, this is what a healthy Google Tag installation should look like:</p>

                            <img src="https://img.genflow.com/gya/plugandplays/googleTagManager29.png" alt="" />

                            <p>
                                You can also use Google Tag Manager’s own troubleshooting tool. To use it, you just have to go to GTM’s homepage and click on “preview” right beside the “publish” button.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/googleTagManager30.png" alt="" />

                            <p>
                                It will take you to a new window where you have to input the website that you want to troubleshoot:
                                {' '}
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/googleTagManager31.png" alt="" />

                            <p>
                                After you input your website and click connect, it will take you to a page that shows all the tags that were fired on your page, as well as the ones that are present on your GTM installation but haven’t fired on the page. If you click on them, you can see what prevented them from firing.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/googleTagManager32.png" alt="" />

                            <p>You can also navigate through the page on a different tab and see how your interactions affect the tags from GTM. This is useful in case you have tags that should only fire on a button click, for example.</p>

                        </div>

                        <div className="plug-block" ref={refs[6]}>
                            <div className="plug-block-subheading">BONUS SECTIONS</div>

                            <p className="bold">
                                How To Install Google Analytics using GTM
                            </p>

                            <p>
                                This is a pretty straightforward process. All you have to do is click on the tags section at the left menu on Google Tag Manager’s main page, then click on “New” to add a new tag.
                            </p>


                            <p>
                                You can name it “Google Analytics Tag” and then click on “Choose a tag type to begin setup”
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/googleTagManager33.png" alt="" />

                            <p>On the new tab that pops up, you can select the first option “Google Analytics: Universal Analytics”. You can then select the track type as “pageview” and on the Google Analytics setting you can select “New Variable”.</p>

                            <img src="https://img.genflow.com/gya/plugandplays/googleTagManager34.png" alt="" />

                            <p>
                                You can then name this new variable “Google Analytics Tracking ID” and then you just have to paste your Analytics Tracking ID on the “Tracking ID” field. You can get your Analytics Tracking ID by logging into your Google Analytics account and going into the Admin section. There, under the property menu you can click on “Tracking Info” > “Tracking Code”. Your Tracking ID will be at the top of the tab.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/googleTagManager35.png" alt="" />

                            <p>
                                After pasting the Tracking ID on GTM you can just hit save. Then you can click on the triggering settings and select the “All Pages” trigger that we created earlier in this plug & play.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/googleTagManager36.png" alt="" />

                            <p>
                                Once that’s done, you can just click save and your Google Analytics Tag will be created. Once you publish it, your Google Analytics Tag will now be applied to all your pages.
                            </p>

                            <p className="bold">
                                Other Useful Documentation:


                            </p>

                            <p className="bold">How To Install Google Tag Manager on Shopify: </p>
                            <a className="break-words" href="https://help.shopify.com/en/manual/reports-and-analytics/google-analytics/google-tag-manager " target="_blank" rel="noopener noreferrer">https://help.shopify.com/en/manual/reports-and-analytics/google-analytics/google-tag-manager </a>


                            <p className="bold">How To Install Google Tag Manager on Wordpress: </p>
                            <a className="break-words" href="https://www.wpbeginner.com/beginners-guide/how-to-install-and-setup-google-tag-manager-in-wordpress/ " target="_blank" rel="noopener noreferrer">https://www.wpbeginner.com/beginners-guide/how-to-install-and-setup-google-tag-manager-in-wordpress/  </a>


                            <p className="bold">How To Install Google Tag Manager on Wix:</p>
                            <a className="break-words" 
                                href="https://support.wix.com/en/article/adding-your-google-tag-manager-account-id-to-your-wix-site
 "
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                https://support.wix.com/en/article/adding-your-google-tag-manager-account-id-to-your-wix-site
                            </a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
