/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect } from 'react';
// reactstrap components
import {
    Card, CardBody, Col, Container, Row,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Button from '@material-ui/core/Button';

import useCourse from '@genflow/web-courses/hooks/useCourse';
import { useConfig } from '@genflow/core';
import Footer from '../../../components/Footer';
import toolsContent from '../../../assets/toolsContent';
import { redirectToCheckout } from '../../../utils/stripe';
import LoadingButton from '../../../components/LoadingButton';
import { ChartPieIcon } from '@heroicons/react/solid'
import { trackEvent } from '../../../utils/useAnalytics';
import { OnboardingContext } from '../../../providers/OnboardingProvider';
import { UserDataContext } from '../../../providers/UserDataProvider';



export default () => {
    const { vendorUuid, agencyNavigatorCourseProductID, stripeProductPlans } = useConfig();
    const { loading, authoriseError } = useCourse(
        vendorUuid,
        agencyNavigatorCourseProductID,
    );
    const access = authoriseError === null;

    const onClickJoin = () => {
        (async () => {
            try {
                await redirectToCheckout(stripeProductPlans.agencyNavigator);
            } catch (e) {
                console.log(e);
            }
        })();
    };

    const { userProfile } = useContext(UserDataContext);
    const { activePage, setActivePage, setActiveInfoType } = useContext(OnboardingContext);
    useEffect(() => {
        if (!userProfile?.data?.tourCompleted?.tools && activePage < 1 && userProfile?.data?.onboardingCompleted) {
            // show show onboarding after initial exit
            setActiveInfoType('tools');
            setActivePage(0);
        }
    }, [userProfile]);

    return (
        <>
            <main className="w-full text-white">

                <div className="w-10/12 mx-auto">
                    <h2 className="text-white mt-12 mb-8">Tools
                        <InfoOutlinedIcon className="white500 ml-2 cursor-pointer" onClick={() => setActiveInfoType('tools')} />
                    </h2>
                    <div className="mt-12 max-w-lg mx-auto grid gap-6 lg:grid-cols-3 lg:max-w-none">
                        {toolsContent.map(tool => {
                          if (tool.title !== "Ads Checker") {
                           
                            return (
                        <div className="grey800Bg text-white p-8 rounded-xl">
                            <div className="flex flex-row items-center">
                                <div className="h-12 w-12 grey700Bg mr-3 flex items-center justify-center">
                                {
                                    tool.iconFile
                                        ? <img src={tool.iconFile} alt="" className="h-6 w-6" />
                                        : (
                                        
                                            <div>
                                                <ChartPieIcon  className="h-6 w-6" />
                                            </div>
                                        )
                                }

                                </div>
                                <h5>{tool.title}</h5>
                            </div>
                            <div className="grey500 mt-6 body4 h-28">
                                {tool.body}
                            </div>
{/*                            <button 
                                className="cursor-pointer blkButton w-full py-2 mt-6">
                            Open
                            </button>*/}

                            {tool?.disabled ? (
                                    <div>
                                        {tool?.href ?
                                            <a
                                                className="cursor-pointer blkButton w-full py-2 mt-6"
                                                type="button"
                                                href={tool?.href}
                                                disabled
                                                component={tool?.href ? 'a' : Link}
                                            >
                                                Coming soon
                                            </a>
                                        :
                                            <Link
                                                className="cursor-pointer blkButton w-full py-2 mt-6"
                                                type="button"
                                                to={tool?.link}
                                                disabled
                                                component={tool?.href ? 'a' : Link}
                                            >
                                                Coming soon
                                            </Link>
                                        }
                                    </div>
                                ) : access ? (
                                    <div>
                                        {tool?.href ?
                                            <a
                                                className="cursor-pointer blkButton w-full py-2 mt-6"
                                                type="button"
                                                href={tool?.href}
                                                component={tool?.href ? 'a' : Link}
                                            >
                                                Open
                                            </a>
                                        :
                                            <Link
                                                className="cursor-pointer blkButton w-full py-2 mt-6"
                                                type="button"
                                                to={tool?.link}
                                            >
                                                <span
                                                        onClick={() => trackEvent({
                                                            action: 'Tool_Opened',
                                                            category: 'Tool_Name',
                                                            label: tool.title,
                                                        })
                                                        }
                                                >
                                                    Open
                                                </span>
                                            </Link>
                                        }

                                    </div>
                                ) : (
                                    <div>
                                        {tool?.href ? 
                                            <a
                                                loading={loading}
                                                className="cursor-pointer blkButton w-full py-2 mt-6"
                                                onClick={onClickJoin}

                                            >
                                                Join
                                            </a>
                                        :
                                            <Link
                                                loading={loading}
                                                className="cursor-pointer blkButton w-full py-2 mt-6"
                                                onClick={onClickJoin}
                                            >
                                                Join
                                            </Link>
                                        }
                                    </div>
                                )}
                        </div>
                        )}})}
                    </div>
                </div>

            </main>
            <Footer />
        </>
    );
};
