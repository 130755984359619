/* eslint-disable no-restricted-globals */
/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import {
    Card,
    CardBody,
    Col,
    Container,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
} from 'reactstrap';
import accounting from 'accounting';
// import { parseInt } from 'lodash';
import Footer from '../../../components/Footer';
import '../../../assets/scss/simple-tools.scss';
import GYAFooterScene from '../../../assets/img/footer-scene-gya.png';
import { UserDataContext } from '../../../providers/UserDataProvider';
import CurrencySelector from '../../../components/CurrencySelector';

export default () => {
    const { userCurrency } = useContext(UserDataContext);

    const [state, setState] = useState({
    // user inputs
        grossMargins: '',
        expectedAdSpend: '',
        serviceFee: '',

        // results
        breakevenRevenueWithFee: '-',
        breakevenRevenueWithouFee: '-',
        breakevenROASWithFee: '-',
        breakevenROASWithoutFee: '-',
    });

    const handleChange = (value, name) => {
        const s = state;

        const validValue = !isNaN(value * 1);

        s[name] = value || 0;
        if (validValue) {
            s[name] = value * 1;

            s.breakevenRevenueWithFee = (s.serviceFee + s.expectedAdSpend) / (s.grossMargins / 100);

            s.breakevenRevenueWithouFee = s.expectedAdSpend / (s.grossMargins / 100);

            s.breakevenROASWithFee = s.breakevenRevenueWithFee / s.expectedAdSpend;

            s.breakevenROASWithoutFee = s.breakevenRevenueWithFee / (s.serviceFee + s.expectedAdSpend);
        }

        setState({ ...s });
    };

    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, []);

    return (
        <>
            <main className="simple-tools-page text-white">
                <section className="section-profile-cover section-shaped my-12">
                    <Container>
                        <Row>
                            <Col lg={12} className="text-center">
                                <h1 className="hidden sm:block">Breakeven ROAS Calculator</h1>
                                <h3 style={{fontSize: '26px'}} className="block sm:hidden">Breakeven ROAS Calculator</h3>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="section section-lg pt-lg-3 pt-8">
                    <Container>
                        <Row className="mb-4 shadow shadow--hover">
                            <Col lg={12}>
                                <Row className="flex flex-col sm:flex-row w-11/12 sm:w-8/12 mx-auto shadow-md card">
                                    <Col lg={7} className="w-full sm:w-7/12 p-4 sm:p-8 flex sm:block items-center">
                                        <div className="left-input-card w-full mt-0 sm:mt-20">
                                          <CardBody>
                                                <CurrencySelector />
                                                <div className="mt-8 block sm:hidden"></div>
                                                <strong>Breakeven ROAS</strong>
                                                <Row className="tool-form-row flex flex-col sm:flex-row items-start sm:items-end justify-between py-4 body2">
                                                    <Col sm={9} className="tool-form-label">
                                                        Gross Margins
                                                    </Col>
                                                    <Col sm={3} className="tool-form-input w-full sm:w-auto">
                                                        <InputGroup className="flex flex-row items-center bg-transparent inputBox" style={{borderBottomColor: "#434343", marginBottom: "0px"}}>
                                                            <Input
                                                                placeholder="50"
                                                                type="number"
                                                                value={state.grossMargins}
                                                                className="bg-transparent p-2 pb-0 w-full sm:w-24 inputBox"
                                                                style={{marginBottom: "0px"}}
                                                                onChange={e => handleChange(e.target.value, 'grossMargins')
                                                                }
                                                            />
                                                            <InputGroupAddon addonType="append" className="bg-transparent inputBox" style={{ marginBottom: "0px"}}>
                                                                <InputGroupText>%</InputGroupText>
                                                            </InputGroupAddon>
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                                <Row className="tool-form-row flex flex-col sm:flex-row items-start sm:items-end justify-between py-4 body2">
                                                    <Col sm={9} className="tool-form-label">
                                                        Expected Ad Spend
                                                    </Col>
                                                    <Col sm={3} className="tool-form-input w-full sm:w-auto">
                                                        <InputGroup className="flex flex-row items-end bg-transparent inputBox" style={{borderBottomColor: "#434343", marginBottom: "0px"}}>
                                                            <InputGroupAddon addonType="prepend" className="bg-transparent inputBox" style={{ marginBottom: "0px"}}>
                                                                <InputGroupText>{userCurrency}</InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder="100"
                                                                type="number"
                                                                value={state.expectedAdSpend}
                                                                className="bg-transparent p-2 pb-0 w-full sm:w-24 inputBox"
                                                                style={{marginBottom: "0px"}}
                                                                onChange={e => handleChange(
                                                                    e.target.value,
                                                                    'expectedAdSpend',
                                                                )
                                                                }
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                                <Row className="tool-form-row flex flex-col sm:flex-row items-start sm:items-end justify-between py-4 body2">
                                                    <Col sm={9} className="tool-form-label">
                                                        Service Fee
                                                    </Col>
                                                    <Col sm={3} className="tool-form-input w-full sm:w-auto">
                                                        <InputGroup className="flex flex-row items-end bg-transparent inputBox" style={{borderBottomColor: "#434343", marginBottom: "0px"}}>
                                                            <InputGroupAddon addonType="prepend" className="bg-transparent inputBox" style={{ marginBottom: "0px"}}>
                                                                <InputGroupText>{userCurrency}</InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder="100"
                                                                type="number"
                                                                value={state.serviceFee}
                                                                className="bg-transparent p-2 pb-0 w-full sm:w-24 inputBox"
                                                                style={{marginBottom: "0px"}}
                                                                onChange={e => handleChange(e.target.value, 'serviceFee')
                                                                }
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </div>
                                    </Col>
                                    <Col lg={5} className="right-input-col p-0 w-full sm:w-5/12">
                                        <Card className="right-input-card">
                                            <CardBody className="p-0">
                                                <div className="result">
                                                    <h5 className="text-white mb-4 text-center p-2 sm:p-0">
                                                        Breakeven Revenue (With Fee)
                                                    </h5>
                                                    <h4 className="text-white mb-4">
                                                        {!isFinite(state.breakevenRevenueWithFee)
                                                            ? '-'
                                                            : `${accounting
                                                                .formatMoney(
                                                                    state.breakevenRevenueWithFee,
                                                                    userCurrency,
                                                                )
                                                                .replace('.00', '')}`}
                                                    </h4>
                                                </div>

                                                <div className="result">
                                                    <h5 className="text-white mb-4 text-center p-2 sm:p-0">
                                                        Breakeven Revenue (Without Fee)
                                                    </h5>
                                                    <h4 className="text-white mb-4">
                                                        {!isFinite(state.breakevenRevenueWithouFee)
                                                            ? '-'
                                                            : `${accounting
                                                                .formatMoney(
                                                                    state.breakevenRevenueWithouFee,
                                                                    userCurrency,
                                                                )
                                                                .replace('.00', '')}`}
                                                    </h4>
                                                </div>

                                                <div className="result">
                                                    <h5 className="text-white mb-4 text-center p-2 sm:p-0">
                                                        Breakeven ROAS (With Fee)
                                                    </h5>
                                                    <h4 className="text-white mb-4">
                                                        {!isFinite(state.breakevenROASWithFee)
                                                            ? '-'
                                                            : `${accounting.formatNumber(
                                                                state.breakevenROASWithFee,
                                                                2,
                                                            )}`}
                                                    </h4>
                                                </div>

                                                <div className="result">
                                                    <h5 className="text-white mb-4 text-center p-2 sm:p-0">
                                                        Breakeven ROAS (Without Fee)
                                                    </h5>
                                                    <h4 className="text-white">
                                                        {!isFinite(state.breakevenROASWithoutFee)
                                                            ? '-'
                                                            : `${accounting.formatNumber(
                                                                state.breakevenROASWithoutFee,
                                                                2,
                                                            )}`}
                                                    </h4>
                                                </div>
                                                
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </main>
            <Footer />
        </>
    );
};
