import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        available: true,
        sectionName: 'What Is The Conversion API and How To Set It Up',
    },
    {
        available: true,
        sectionName: 'How To Set Up The Conversion API',
    },
    {
        available: true,
        sectionName: 'How To Set Up The Conversion API Through Shopify Partners Integrations',
    },
    {
        available: true,
        sectionName: 'How To Set Up The Conversion API Through Wordpress',
    },

];

const title = 'The Conversion API';

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                // selectedSection={selectedSection}
                                // setSelectedSection={onSelectSection}
                                // filter={filter}
                                // selectFilter={selectFilter}
                                // subFilter={subFilter}
                                // selectSubFilter={selectSubFilter}

                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]} className="plug-block">
                            <h2 className="mb-4">{title}</h2>  

                            <div className="plug-block-subheading">What Is The Conversion API and How To Set It Up</div>

                            <p>
                                Since the IOS14 update on Apple’s devices, Facebook has been forced to adapt the way they do tracking. With that, a lot of changes and new things come up for us advertisers.
                            </p>

                            <p>
                                One of the major changes that Facebook is enforcing and encouraging users to adopt is the usage of Conversion API (CAPI).
                            </p>

                            <p>
                                The Conversion API is an alternative to the standard tracking method: the Facebook Pixel.
                            </p>

                            <p>
                                Essentially, the way the Facebook Pixel works is by inserting a little piece of code in all of your website pages, which then tracks the users and their behaviours on your website. That is what we call “3rd Party Tracking”, because it is not your own site that is actively tracking the users, but rather an extension of Facebook who is tracking users through your website.
                            </p>

                            <p>
                                With the Conversion API, the dynamic here changes. The conversion API is a way to track users from the server-side and then send this data back to Facebook. Meaning: your own website is the one that tracks the user, which then sends the tracked data back to Facebook. This is considered “1st Party Tracking”, because it’s your own server tracking things, rather than a 3rd party.
                            </p>

                            <p>
                                With this, you’re able to track conversions that the Facebook Pixel could have missed due to browser blocking cookies and other issues.
                            </p>

                            <p>
                                In layman’s terms, what this means is that implementing the Conversion API is a way to make our tracking more consistent and accurate.
                            </p>

                            <p>
                                Disclaimer: the Conversion API does NOT fix the issue of tracking the users that have chosen to opt-out of tracking on Facebook. Although you can technically track those users from server-side, when you send their data back to Facebook, Facebook is not legally allowed to accept it - since that would break Apple’s privacy policies for opted out users.
                            </p>
                        </div>

                        <div className="plug-block" ref={refs[1]}>
                            <div className="plug-block-subheading">
                                How To Set Up The Conversion API
                            </div>

                            <p>
                                In most cases, the website builders have already added a partner integration that allows you to easily set up the Conversion API with a few simple steps.
                            </p>

                            <p>
                                In this plug n play we will cover how to set up the Conversion API through Shopify’s partner integrations and through a Wordpress plugin - since those two are the platforms the majority of our students use.
                            </p>

                            <p>
                                However, there are literally countless possible tech stacks that people use, and each has a slightly different way of setting up the Conversion API. If the one you currently use is not Shopify or Wordpress, I recommend you Google “How To Set Up Facebook Conversion API on [Insert Name Of Your Platform]”. There will probably be dozens of tutorials already teaching you how to do that.
                            </p>
                        </div>

                        <div className="plug-block" ref={refs[2]}>
                            <div className="plug-block-subheading">
                                How To Set Up The Conversion API Through
                                Shopify Partners Integrations
                            </div>

                            <p>
                                To set up the Conversion API on Shopify you first need to install the Facebook Channel.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/conversionApi1.png" alt="" />

                            <p>
                                Then, Shopify will ask you to connect it to your Facebook Account. We recommend you do that through the store owner’s Facebook account.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/conversionApi2.png" alt="" />

                            <p>
                                Then on the next step you’ll simply need to select your Facebook Page. Once that’s done, you’ll go to the “Data Sharing” tab, which is where we actually set up the Conversion API.
                            </p>

                            <p>
                                All you have to do really, is select the “Maximum” option.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/conversionApi3.png" alt="" />

                            <p>
                                Once that’s done, you just finish the set up and the Conversion API will be up and running on your store!
                            </p>

                        </div>

                        <div className="plug-block" ref={refs[3]}>
                            <div className="plug-block-subheading">
                                How To Set Up The Conversion API Through Wordpress
                            </div>

                            <p>
                                Here, we will a plugin called “PixelYourSite”. To set up the Conversions API using PixelYourSite, first you’ll need to install and activate the PixelYourSite plugin. In your WordPress dashboard, select Plugins in the left-hand menu. Then click Add New.
                            </p>

                            <p>
                                Search for PixelYourSite in the search bar. You want the plugin titled “PixelYourSite–Your smart PIXEL (TAG) Manager.” Click Install Now, and when it finishes loading, click Activate.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/conversionApi4.png" alt="" />

                            <p>When the plugin is activated, it’ll appear in the left-hand menu in WordPress. Select the plugin and go to the plugin dashboard.</p>

                            <img src="https://img.genflow.com/gya/plugandplays/conversionApi5.png" alt="" />

                            <p>
                                Next, go into Facebook Events Manager and copy your Facebook pixel ID. Then paste it into the Facebook Pixel ID field in the PixelYourSite dashboard.
                            </p>

                            <p>
                                Now go back into Facebook Events Manager. Under Settings, scroll down to Conversions API and click Generate Access Token.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/conversionApi6.png" alt="" />

                            <p>
                                Copy the access token.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/conversionApi7.png" alt="" />

                            <p>Paste the access token into the API Token field in the PixelYourSite dashboard and check the box “Send Events Directly From Your Web Server to Facebook through the Conversions API.”</p>

                            <img src="https://img.genflow.com/gya/plugandplays/conversionApi8.png" alt="" />

                            <p>
                                Now you need to test your setup. Go back into Facebook Events Manager and go to the Test Events tab. Copy the test code under Test Server Events.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/conversionApi9.png" alt="" />

                            <p>
                                Next, go back into the PixelYourSite dashboard and paste the test code in the “test_event_code” field. Scroll to the bottom and click Save Settings.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/conversionApi10.png" alt="" />

                            <p>
                                To run the test, open a page of your website in a different tab.
                            </p>

                            <p>
                                To see if the test fired correctly, go back into the Test Events tab in Events Manager. (You may need to refresh the page if it’s still open from the previous steps.) If the test fired successfully, the Test Your Events window will show the events received and “Receiving activity” with a green dot.
                            </p>

                            <p>
                                If the browser event fired as well, you’ll see the browser event as processed and the server event as “Deduplicated.”
                            </p>

                            <p>
                                If only the server event fired, you’ll see just the Server event processed.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/conversionApi11.png" alt="" />


                            <p>
                                If the test fired correctly, go back into the PixelYourSite dashboard and delete the test code. Click Save Settings when you’re finished.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
