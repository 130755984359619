import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        available: true,
        sectionName: '#1 Verify Your Domain',
    },
    {
        available: true,
        sectionName: '#2 Configure Web Events',
    },
];

const title = 'iOS14 Update';

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]}  className="plug-block">  
                        <h2 className="mb-4">{title}</h2>
                            <div className="plug-block-subheading">#1 Verify Your Domain</div>

                            <p>If you run Facebook ads optimized for website events, the first thing you want to do is confirm your domain is verified.</p>

                            <p>
                                To do this, you’ll need to go to Domains. In Business Manager, open Business Settings, select Brand Safety in the left-hand menu, and then select Domains.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/ios14-1.png" alt="" />

                            <p>
                                Alternatively, if you have the prompt in the Resource Center to verify your domain, click the Verify Domain button (pictured below) and it’ll take you straight to Domains.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/ios14-2.png" alt="" />

                            <p>
                                Once you are in the domains page, you will either see a verified domain with a green dot, meaning you have completed this setup.
                            </p>

                            <p>If you don’t have a verified domain, you won’t see any domains. In that case you’ll need to add a domain.</p>

                            <p>
                                Click Add and then type in your domain name and click Add Domain. If the domain doesn’t appear in the main box after clicking Add Domain, refresh the page.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/ios14-3.png" alt="" />

                            <p>
                                Now we need to actually verify the domain.
                            </p>

                            <p>
                                Now choose your preferred method to verify your domain. Facebook gives you three options: DNS Verification, HTML File Upload, and Meta-tag Verification.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/ios14-4.png" alt="" />

                            <p>
                                Follow the instructions for the method you chose to finish verifying your domain. When your domain is successfully verified, you’ll see it listed with a green dot.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/ios14-5.png" alt="" />

                            <p>
                                Please Note: It can take up to 72 hours until your DNS configuration changes have spread across your website’s domain servers.
                            </p>
                        </div>

                        <div className="plug-block">
                            <div className="plug-block-subheading">
                                #2 Configure Web Events
                            </div>

                            <p>
                                Now we need to navigate to Events Manager.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/ios14-6.png" alt="" />

                            <p>Pick your pixel and you’ll see a new tab that appears called Aggregated Event Measurement. Click on it.</p>

                            <img src="https://img.genflow.com/gya/plugandplays/ios14-7.png" alt="" />
                            <img src="https://img.genflow.com/gya/plugandplays/ios14-8.png" alt="" />

                            <p>Once you’ve clicked on the Aggregated Event Measurement tab, click on the only button on the page called ‘Configure Web Events’.</p>

                            <p>
                                You’ll be able to see all the domains that are attached to your business manager.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/ios14-9.png" alt="" />

                            <p>
                                Choose the one you’d like to set up and click the drop down arrow on the right.
                            </p>

                            <p>
                                Click, ‘Manage Events’.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/ios14-10.png" alt="" />

                            <p>
                                Now select all the events you use on your page/website, in order of importance.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/ios14-11.png" alt="" />

                            <p>
                                Then click submit.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
