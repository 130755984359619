import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container'; 
import { Link, useLocation } from 'react-router-dom';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        available: true, sectionName: 'Before you start',
    },
    {
        available: true, sectionName: 'Cover photo',
    },
    {
        available: true, sectionName: 'Profile Headline',
    },
    {
        available: true, sectionName: 'Profile Summary',
    },
    {
        available: true, sectionName: 'Connections',
    },
    {
        available: true, sectionName: 'Skills/Endorsements',
    },
    {
        available: true, sectionName: 'Reccomendations',
    },
    {
        available: true, sectionName: 'Personalised URL',
    },
    {
        available: true, sectionName: 'Visibility',
    },
];

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const location = useLocation()


    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title="Creating Your LinkedIn Profile.">
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                // selectedSection={selectedSection}
                                // setSelectedSection={onSelectSection}
                                // filter={filter}
                                // selectFilter={selectFilter}
                                // subFilter={subFilter}
                                // selectSubFilter={selectSubFilter}

                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]} className="plug-block">
                            <h2 className="mb-4">Creating Your LinkedIn Profile.</h2>
                            
                            <div>

                                    <div className="plug-block-subheading">Before you start:</div>

                                    <p>What you’ll need:</p>

                                    <ul>
                                        <li>A professional profile photo</li>
                                        <li>A Canva.com account</li>
                                        <li><Link to="/resources/plug-and-play/linkedin-keywords">A list of keywords for your niche and offering</Link></li>
                                    </ul>

                                    <p>Even if you’re not performing outreach on LinkedIn, remember: if someone searches for your name or company name after sending them a DM or email, 90% of the time, your LinkedIn profile will appear on the first page of Google, often in the first results. So make sure you perform some housekeeping basics.</p>
                                </div>

                                <div ref={refs[1]} className="plug-block">
                                    <div className="plug-block-subheading">Cover photo</div>

                                    <p>This is one of the first things your connections will see. A cover photo isn’t a necessity but if you do use one, make sure it’s graphically designed and looks professional.</p>

                                    <p>Use Canva.com and make sure to keep your color scheme and font consistent.</p>

                                    <a href="https://drive.google.com/drive/u/0/folders/1duipqRBEjRYls7omyLXBqGi7v0XtYnmk" target="_blank" rel="noopener noreferrer">See here for examples.</a>

                                </div>
                                <div ref={refs[2]} className="plug-block">
                                    <div className="plug-block-subheading">Profile Headline</div>


                                    <p>The headline is the most important element of your headline. It signals to your connections who you are, if you can help them, and if you’re worth their time.</p>

                                    <p>Don’t try to sound cool or impressive: if you work in the plumbing niche, you’d rather impress someone who can fix a toilet, rather than your friends.</p>

                                    <p>If you have any accolades or obvious accomplishments, feel free to mention them here.</p>

                                    <p>Make sure to include key terms - don’t try and squeeze them in artificially, make them sound natural.</p>

                                    <p><strong>Examples below:</strong></p>

                                    <div className="examples-block">
                                        <p><i>Founder @IAG Media | eCommerce Growth Master | FB Advertiser</i></p>

                                        <p><i>SEO Expert | I’ll Get Your Brand #1 on Google</i></p>

                                        <p><i>Email Copywriter | Increasing Clicks & Conversions</i></p>

                                        <p><i>“UK’s #1 Fitness Marketer” | I Build Brands and Businesses for Personal Trainers</i></p>
                                    </div>

                                </div>
                                <div ref={refs[3]} className="plug-block">
                                    <div className="plug-block-subheading">Profile Summary</div>

                                    <p>Just like in a newspaper or online article, the headline’s job is to grab attention and set the tone: the main body of text, or the summary is there to inform, educate and persuade visitors to your profile.</p>

                                    <p>You have a 2,000 character limit, with the first 200-250 characters appearing above ‘show more’.</p>

                                    <p>It’s important to be:</p>

                                    <ul>
                                        <li>Professional: LinkedIn is a professional platform and this is your chance to convey your skills and abilities.</li>
                                        <li>Personal: You still need to offer enough of your personality to be considered ‘human’ and personal. Outreach relies on personality and building rapport. Mentioning that you support a football team or have a dog could help you build this, especially in industries where this might be relevant</li>
                                        <li>Impressive: If someone has made it to your summary, you need to show them something that you are proud of, and that will impress them</li>
                                        <li>Relevant: Use keywords and be specific to the niche you operate in.</li>
                                    </ul>
                                    <p>At the end of your summary, include a call-to-action. Make it soft, don’t ask people to book a call, or speak to you about how you can improve their business: ask people to check your most recent article, an amazing statistic/ graphic on your website, or to message you and ask you for free advice.</p>

                                    <p>Make sure you include keywords pertaining to your industry.</p>

                                    <p><strong>Example below:</strong></p>

                                    <div className="examples-block">
                                        <p><i>I’m a bit obsessive when it comes to health and wellbeing.</i></p>

                                        <p><i>My day starts with yoga, ends with chamomile tea* and is spent helping build new and emerging businesses who want to help people build better lives.</i></p>

                                        <p><i>I own a renowned digital marketing agency which specialises in using highly-targeted, and efficient paid traffic to drive conversions to websites via Facebook and Google advertising.</i></p>

                                        <p><i>That sounds a bit boring and corporate… so let me give you a real-life example! Earlier this year, we started to work with a skincare brand that used 100% natural ingredients and championed well-being.</i></p>

                                        <p><i>In the space of just three months, we grew their website conversions by 86%, and turned their initial $5,556 advertising investment into $43,982.</i></p>

                                        <p><i>We didn’t use any funny tricks or hacks to do this - we just put a great product in front of an audience we knew would find it useful...</i></p>

                                        <p><i>And that’s what I’m most proud of: giving a spotlight and a chance for success to businesses who are trying to do good and help people.</i></p>

                                        <p><i>If you’re one of those businesses, I’d love to give you some free tips/ pointers on how you could get in front of more people, quickly and cheaply!</i></p>

                                        <p><i>Send me a DM, or check out my website where I’ve got a quick three-point guide to the benefits of funnel creation in ecommerce.</i></p>

                                        <p><i>>> www.betterlifeagency.com</i></p>

                                        <p><i>*From an amazing brand called T2 - highly recommended.</i></p>
                                    </div>

                                </div>

                                <div ref={refs[4]} className="plug-block">
                                    <div className="plug-block-subheading">Connections</div>

                                    <p>Unlike Instagram where a high-following number is considered an important social proof, LinkedIn actually stops displaying the number of connections you have on your profile after 500+</p>

                                    <p>Whilst it’s great to build your connections as quickly as possible, be careful who you connect with, and who you accept.</p>

                                    <p>You should aim to create a LinkedIn network rich in your niche and industry not full of other agency owners.</p>

                                    <p>This being said, make sure you support fellow students when they join.</p>
                                </div>

                                <div ref={refs[5]} className="plug-block">
                                    <div className="plug-block-subheading">Skills/Endorsements</div>
                                    <p>Skills are similar to key terms. But you should consider them a tick-box exercise: list the applicable skills you have and seek endorsements from a few selected connections you trust.</p>
                                </div>

                                <div ref={refs[6]} className="plug-block">
                                    <div className="plug-block-subheading">Recommendations</div>
                                    <p>Recommendations are useful and a great example of social proof. However, a bad or obviously-fake recommendation is worse than no recommendations at all. If you have previously worked with a client who you trust, politely request a recommendation from them.</p>
                                </div>

                                <div ref={refs[7]} className="plug-block">
                                    <div className="plug-block-subheading">Personalised URL</div>
                                    <p>This is an easy win for your LinkedIn profile.</p>
                                    <a
                                        href="https://www.linkedin.com/help/linkedin/answer/87/customize-your-public-profile-url?lang=en"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Learn how to customise your URL here.
                                    </a>
                                </div>

                                <div ref={refs[8]} className="plug-block">
                                    <div className="plug-block-subheading">Visibility</div>

                                    <p>As you perform more and more outreach, you’ll learn that most people have the settings on their profile set to restrict anyone from seeing their contact information including their email address, and often from even letting people beyond their 1st connections from seeing anything on their profile.</p>

                                    <p>You need to make sure your profile is as visible as possible to avoid this.</p>

                                    <p>
                                        Go to the&nbsp;
                                        <a href="https://www.linkedin.com/psettings/account-preferences" target="_blank" rel="noopener noreferrer">Account Preferences</a>
                                        &nbsp;
                                        section and implement the following sections:
                                    </p>

                                    <p>Turn on:</p>
                                    <ul>
                                        <li>People Also Viewed > Yes</li>
                                        <li>Profile visibility via partners</li>
                                        <li>Maximum visibility including last name and profile picture in search results</li>
                                    </ul>
                                </div>
                            </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
