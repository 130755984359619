import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper/Paper';
import SummaryList from '../SummaryList';
import { SummaryContext } from '../../providers/SummaryProvider';


const MonthSummaryItem = ({
    disablePrevMonthComparison, label, currency, data, prevMonthData,
}) => {
    const {
        totalRevenue,
        totalExpenses,
        netCashFlow,
        dividendsReleased,
        retainedEarnings,
        openingBalance,
        closingBalance,
    } = data;


    const SummaryItem = ({
        label, value, gain, loss,
    }) => {
        let color = 'white';

        if (!disablePrevMonthComparison && gain) {
            color = '#65b456';
        }

        if (!disablePrevMonthComparison && loss) {
            color = '#CE3153';
        }

        return (
            <div style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '0.5rem',
                fontSize: '0.8rem',
            }}
            >
                <div style={{
                }}
                >
                    {label}
                </div>
                <div style={{
                    textAlign: 'right',
                    fontWeight: 'bold',
                    color,
                }}
                >
                    {value}
                </div>
            </div>
        );
    };

    return (
        <Grid item xs={12} sm={12} md={12} lg={2}>
            <Paper className="grey800Bg">
                <div
                    style={
                        {
                            padding: '0.5rem',
                            textAlign: 'center',
                            color: 'white',
                            backgroundColor: '#000',
                            borderTopLeftRadius: '0.25rem',
                            borderTopRightRadius: '0.25rem',
                            fontFamily: 'Inter',
                            fontSize: '10px',
                            fontWeight: '600',
                            lineHeight: '15px',
                            letterSpacing: '0.4000000059604645px',
                            textTransform: 'uppercase',

                        }
                    }
                >
                    {label}
                </div>

                <Grid item container xs={12} sm={12} md={12} style={{backgroundColor: "#1C1C1C"}} >
                    <SummaryItem
                        label="Revenue"
                        value={`${currency} ${totalRevenue.toLocaleString()}`}
                        gain={totalRevenue > (prevMonthData?.totalRevenue || 0)}
                        loss={totalRevenue < (prevMonthData?.totalRevenue || 0)}
                    />
                    <SummaryItem
                        label="Expenses"
                        value={`${currency} ${totalExpenses.toLocaleString()}`}
                        gain={totalExpenses < (prevMonthData?.totalExpenses || 0)}
                        loss={totalExpenses > (prevMonthData?.totalExpenses || 0)}
                    />
                    <SummaryItem
                        label="Net Cash Flow"
                        value={`${currency} ${netCashFlow.toLocaleString()}`}
                        gain={netCashFlow > (prevMonthData?.netCashFlow || 0)}
                        loss={netCashFlow < (prevMonthData?.netCashFlow || 0)}
                    />
                    <SummaryItem
                        label="Dividends Released"
                        value={`${currency} ${dividendsReleased.toLocaleString()}`}
                        gain={dividendsReleased > (prevMonthData?.dividendsReleased || 0)}
                        loss={dividendsReleased < (prevMonthData?.dividendsReleased || 0)}
                    />
                    <SummaryItem
                        label="Retained Earnings"
                        value={`${currency} ${retainedEarnings.toLocaleString()}`}
                        gain={retainedEarnings > (prevMonthData?.retainedEarnings || 0)}
                        loss={retainedEarnings < (prevMonthData?.retainedEarnings || 0)}
                    />
                    <SummaryItem
                        label="Opening Balance"
                        value={`${currency} ${openingBalance.toLocaleString()}`}
                        gain={openingBalance > (prevMonthData?.openingBalance || 0)}
                        loss={openingBalance < (prevMonthData?.openingBalance || 0)}
                    />
                    <SummaryItem
                        label="Closing Balance"
                        value={`${currency} ${closingBalance.toLocaleString()}`}
                        gain={closingBalance > (prevMonthData?.closingBalance || 0)}
                        loss={closingBalance < (prevMonthData?.closingBalance || 0)}
                    />
                </Grid>
            </Paper>
        </Grid>
    );
};
const YearSummary = ({
    className,
    selected,
    handleSelect,
    labels,
    rowLabels,
    index,
    data,
    sums,
    rows,
    currency,
    year,
    allMonthLabels,
}) => {
    const { getSummaryData } = useContext(SummaryContext);

    const topRowMonthLabels = allMonthLabels.slice(0, 6);
    const bottomRowMonthLabels = allMonthLabels.slice(6, 12);

    const allMonthsData = allMonthLabels.map(month => getSummaryData(month));


    return (
        <>
            <Grid container spacing={2} justify="center">
                <Grid item container spacing={2} xs={12} sm={12} md={12} lg={10}>
                    {topRowMonthLabels.map(monthLabel => (
                        <MonthSummaryItem
                            label={monthLabel}
                            currency={currency}
                            data={allMonthsData[allMonthLabels.indexOf(monthLabel)]}
                            prevMonthData={allMonthsData[allMonthLabels.indexOf(monthLabel) - 1]}
                            disablePrevMonthComparison={allMonthLabels.indexOf(monthLabel) === 0}
                        />
                    ))}
                    {bottomRowMonthLabels.map(monthLabel => (
                        <MonthSummaryItem
                            label={monthLabel}
                            currency={currency}
                            data={allMonthsData[allMonthLabels.indexOf(monthLabel)]}
                            prevMonthData={allMonthsData[allMonthLabels.indexOf(monthLabel) - 1]}
                        />
                    ))}
                </Grid>

                {}
                <Grid item xs={12} sm={12} md={12} lg={2}>
                    <SummaryList
                        currency={currency}
                        yearView
                    />
                </Grid>
            </Grid>


        </>
    );
};

export default YearSummary;
