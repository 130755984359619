const styles = () => ({
    tabs: {
        marginBottom: 36,
        marginTop: 36,
        // width: '100%',
        // backgroundColor: 'red',

    },
});

export default styles;
