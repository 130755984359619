import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
// import { Link } from 'react-router-dom';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        sectionName: 'Facebook Pixel:', available: true,
    },
    {
        sectionName: 'Website:', available: true,
    },
    {
        sectionName: 'Social media:', available: true,
    },
    {
        sectionName: 'Facebook ads:', available: true,
    },
];

const title = "How to Qualify Clients"

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]}  className="plug-block">  
                        <h2 className="mb-4">{title}</h2>
                            <div className="plug-block-subheading">Facebook Pixel:</div>

                            <p>
                                Make sure you have the
                                {' '}
                                <a href="https://chrome.google.com/webstore/detail/facebook-pixel-helper/fdgfkebogiimcoedlicjlajpkdmockpc" target="_blank" rel="noopener noreferrer">Facebook pixel helper tool</a>
                                {' '}
                                installed.
                            </p>

                            <ul>
                                <li>
                                    Does the website have a Facebook pixel installed?

                                </li>
                                <li>
                                    Does the website have multiple Facebook pixels installed?
                                </li>
                            </ul>

                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">
                                Website:

                            </div>

                            <p>
                                Use
                                {' '}
                                <a href="https://www.similarweb.com/" target="_blank" rel="noopener noreferrer">SimilarWeb</a>
                                {' '}
                                to search for traffic and acquisition.

                            </p>

                            <ul>
                                <li>
                                    Are they cookie-compliant in the EU (do they display a prominent opt-out feature for  cookies)?
                                </li>
                                <li>
                                    Does the website have a privacy policy?
                                </li>
                                <li>
                                    Does the website have a terms and conditions section?
                                </li>
                                <li>
                                    Has the website received more than 10,000 visits in the last six months according to SimilarWeb?
                                </li>
                                <li>
                                    Is the website well-designed and up-to-date?
                                </li>
                                <li>
                                    Does the website use clunky language or is there a suggestion that production descriptions have been copied and pasted?
                                </li>
                            </ul>
                        </div>


                        <div ref={refs[2]} className="plug-block">
                            <div className="plug-block-subheading">
                                Social media:

                            </div>

                            <ul>
                                <li>
                                    Does their Instagram page have a respectable following? (In excess of 5,000 followers)
                                </li>
                                <li>Do they have an active Facebook page?</li>
                                <li>
                                    Does their Instagram page post regularly?
                                </li>
                                <li>
                                    Does their Instagram page post high-quality content including lifestyle content or graphic-designed content (rather than pack shots)?
                                </li>
                            </ul>
                        </div>
                        <div ref={refs[3]} className="plug-block">
                            <div className="plug-block-subheading">
                                Facebook ads:
                            </div>

                            <p>
                                Look up the brand in the
                                {' '}
                                <a href="https://www.facebook.com/ads/library/?active_status=all&ad_type=political_and_issue_ads&country=GB" target="_blank" rel="noopener noreferrer">Facebook Ad Library</a>
                                {' '}
                                to see if they are running Facebook ads.

                            </p>

                            <ul>
                                <li>
                                    Does the company already run Facebook ads?
                                </li>
                                <li>
                                    Do they have more than three ads running?
                                </li>
                                <li>
                                    Are they using multiple creatives and copy?
                                </li>
                                <li>
                                    Are their creatives low-quality or packshots?
                                </li>
                                <li>
                                    Is their copy clunky, short-form or unlikely to convert?
                                </li>
                                <li>
                                    Are they utilising offers, or different URLs, indicating a funnel?
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
