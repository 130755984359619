import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        available: true,
        sectionName: 'Introduction',
    },
    {
        available: true,
        sectionName: 'Types of lead gen',
    },
];

const title = 'Core Concepts To Keep In Mind';

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                // selectedSection={selectedSection}
                                // setSelectedSection={onSelectSection}
                                // filter={filter}
                                // selectFilter={selectFilter}
                                // subFilter={subFilter}
                                // selectSubFilter={selectSubFilter}

                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]} className="plug-block">
                            <h2 className="mb-4">{title}</h2> 

                            <div className="plug-block-subheading">Introduction</div>

                            <p>
                                Lead generation is a term relevant to local businesses and lead based businesses like (real estate, legal, plastic surgeons, insurance, loaners, senior care, etc) and is the act of generating information of prospective customers (leads) to later be converted into customers.
                            </p>

                            <p>
                                You are acquiring the contact information of these prospective customers. This is usually how a customer journey would look like:
                            </p>

                            <ol>
                                <li>
                                    Generate traffic to a lead form (where you collect the people's contact information)
                                </li>
                                <li>
                                    Contacting the leads, either via automation or personally.
                                </li>
                                <li>
                                    Converting those leads into customers
                                </li>
                            </ol>

                            <p>
                                Some people also add a middle step called nurturing of the leads. This means they send information to the lead or interact with it explaining the offering and its benefits before asking for anything in return. This can be done by someone or automated via nurturing sequences, which are sequences in the form of messages or emails that warm up the lead to the offer.
                            </p>

                            <p>
                                What qualifies as a lead is pretty broad, but for simplicity just keep in mind that the information that can be collected is the following: Email, Phone number, Street Address, city, Country, First name, Last Name, gender, date of birth, marital status, job title, work email, work phone number, company name.
                            </p>

                            <p>
                                More often than not, in the case of SMMA, information is collected in the following combinations:
                            </p>

                            <p>
                                First name + phone number + qualifying questions
                            </p>

                            <p>
                                First name + email + qualifying questions
                            </p>

                            <p>
                                We will go in depth on ‘qualifying questions’ and their use in the module "Quality vs Quantity Leads, Increase Lead Flow, Increase Quality" but they are essentially questions  which make sure  that  the right type  of person  is filling out the form: your target audience and the ideal customer for your client.
                            </p>

                            <p>
                                For example, if you’re generating leads for a real estate agent, you would include questions which would disqualify people who were not looking to sell their homes currently, or did not own a home.
                            </p>

                            <p>
                                Lead generation is not limited to the niches or businesses named above. In fact, it can be applied to nearly every business type. For example, many traditional ecommerce brands (businesses where customers can be converted automatically or digitally, and purchase a product as a discreet interaction) will also pay for leads.
                            </p>

                        </div>

                        <div className="plug-block">
                            <div className="plug-block-subheading">Types of lead gen</div>

                            <p>
                                There are two main types of lead gen on Facebook:
                            </p>

                            <p className="bold">
                                Landing page lead gen
                            </p>

                            <p>
                                This is
                            </p>

                            <p className="bold">
                                Native lead gen
                            </p>

                            <p>Facebook offers a ‘native’ -  integrated into the Facebook app -  lead generation form. This allows users to interact with the advert and have their information automatically populated. For example:</p>

                            <div style={{ height: 350 }}>
                                <iframe
                                    id="videoPlayer"
                                    src="https://videos.sproutvideo.com/embed/799dd8bb171eecccf0/8cbe4753f2d8f2b3?playerTheme=dark&amp;playerColor=263238"
                                    frameBorder="0"
                                    allowFullScreen
                                    width="100%"
                                    height="100%"
                                />
                            </div>


                            <p>
                                (Credit:
                                {' '}
                                <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/business/ads/lead-ads">Facebook</a>
                                )
                            </p>

                            <p>
                                Native lead gen has a few clear advantages over the alternative (described below):
                            </p>

                            <ul>
                                <li>
                                    It’s quick. Because the forms are integrated into the platform, there is virtually-zero loading time or delay.
                                </li>
                                <li>
                                    The adverts can encompass ‘instant experiences’ which provide more information without linking to an external website
                                </li>
                                <li>
                                    There is no need for the client to have a website. Whilst your client may already have a website or domain, this still avoids the need to create a landing page or optimise it
                                </li>
                                <li>
                                    Facebook will automatically-populated or prefill the following questions:
                                </li>
                            </ul>

                            <img src="https://img.genflow.com/gya/plugandplays/coreConcepts1.png" alt="" />

                            <p>
                                However, there are also a number of disadvantages:
                            </p>

                            <ul>
                                <li>There’s a lack of customization available. Although you can change the questions and fields, there are limitations to the design of the page or the method in which users submit information. For example, it’s impossible to integrate a quiz-style questionnaire with images, animations etc. </li>
                                <img src="https://img.genflow.com/gya/plugandplays/coreConcepts2.png" alt="" />
                                <li>
                                    Users don’t visit your website and your pixel does not benefit from traffic. This is less of an issue in the era of iOS14 but still worth noting
                                </li>
                                <li>
                                    You’re unable to see the behaviour of users via Google Analytics and diagnose any issues with your website
                                </li>
                                <li>
                                    You’re unable to nurture leads within the funnel, offer discounts, or introduce scarcity
                                </li>
                            </ul>

                            <p className="bold">
                                What kind of targeting does social media platforms allow?
                            </p>

                            <p>
                                One of the main advantages of Facebook is the degree to which you can target, demographically - for example within a small radius of a business’ location.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/coreConcepts3.png" alt="" style={{ width: '50%' }} />

                            <p>
                                You can further restrict this to people who live in an area, rather than those who are passing-through, visiting, or vacationing - this is handy for specific businesses like real estate where passing traffic leads are not qualified.
                            </p>

                            <p>
                                The majority of the time, it does not make sense to use any further demographic or interest targeting beyond location in a small city or area.
                            </p>

                            <p>
                                For example, you would not target users who expressed an interest in ‘pizza’ for a local pizzeria opening.
                            </p>

                            <p>
                                There are, however, exceptions such as female-only salons etc.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
