import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        available: true,
        sectionName: 'Introduction:',
    },
    {
        available: true,
        sectionName: 'When Do We Use CBOs?',
    },
    {
        available: true,
        sectionName: 'You Can Still Use CBOs… Why?',
    },
    {
        available: true,
        sectionName: 'How We Use CBOs',
    },
    {
        available: true,
        sectionName: 'How We Optimize CBOs',
    },
];

const title = 'Utilising CBOs';

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]}  className="plug-block"> 
                            <div className="plug-block-subheading">
                                Introduction:
                            </div>

                            <p>
                                CBO stands in for Campaign Budget Optimisation and it's a different concept to Adset Budget Optimisation.
                            </p>

                            <p>
                                A few years ago, Facebook introduced CBO - essentially a way to optimise a budget at the campaign level, rather than the ad set level. This means Facebook will do your job of allocating budgets to each ad set depending on their performance.
                            </p>

                            <p className="bold">
                                Allocation under ad set optimisation
                            </p>

                            <p>
                                This is what a typical ad set looks like:
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/utilisingCbos1.png" alt="" />

                            <img src="https://img.genflow.com/gya/plugandplays/utilisingCbos2.png" alt="" />

                            <p>
                                This happens in all scales and you'll find that some of the ads spend less than 5% than others on the same ad set.
                            </p>

                            <p>
                                Under the hood what is happening is that Facebook is choosing which ad they think is going to do best, and they choose their own winners and push that one forward and allocate most of the spend. In an ad set with five ads, you will usually see one or two 'winners' that will have most of the ad spend. Facebook uses various metrics to make this decision such as engagement, people stopping to read the ad, clicking, and time on the landing page.
                            </p>

                            <p>
                                Now this isn't bad and you usually trust their algorithms to be making the best decision, after all they have all the data to train them. What does this look like if we took it a step further?
                            </p>

                            <p>
                                A step further would be that Facebook not only chooses which ads get the money, now they also choose which ad sets get the money. Is an even more hands off approach and you trust Facebook to do the magic. And the same thing will happen, some ad sets will get more ad spend than others and some won't get much at all. Although the allocation of budgets is more even than in ads, it is still very skewed.
                            </p>

                            <p>
                                Although it sounds good on paper, as you will need to worry about less things and not even load-balancing budgets… it’s a strategy with multiple disadvantages.
                            </p>
                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">
                                When Do We Use CBOs?
                            </div>

                            <p>
                                As it currently stands Facebook's default of creating a campaign is Adset Budget. But prior to this, and for a long time, its default was CBO.
                            </p>

                            <p>
                                You can tell a lot of what Facebook wants by their defaults. Think about it this way. Facebook's business model is selling ads and the more people that buy the ad space the better for them because they can charge more (Your price is the CPM, how much they charge you to show up to 1000 people).
                            </p>

                            <p>Because their model is selling ad space, their ideal scenario to have more demand is that it is as easy as possible to buy it. This is what all brands do, make it easy for the customer so they can buy.</p>

                            <p>
                                Facebook has taken great efforts to make it as easy as possible to run ads in the past years, lowering the bar as much as they can. They made it so easy you don't even need to have a business manager and can run them from the Facebook page, even boosting posts.
                            </p>

                            <p>
                                To make it even easier they created CBOs. Their algorithm became so good that you didn't need to allocate budgets and you didn't need to even put audiences as ‘broad’.
                            </p>

                            <p>
                                However, most professional media buyers were still able to outperform CBOs with solid interests and allocation of budgets - largely because they retained control and were able to trial and give ad sets a chance that they knew would work.
                            </p>

                            <p>
                                Campaigns with larger budgets also underperformed with CBOs - Facebook doesn’t seem to be fond of these, regardless of whether you manage them, or they do.
                            </p>

                            <p>After iOS14 was introduced, Facebook changed their defaults from CBOs to ABOs again. This was largely because Facebook was no longer able to train its algorithms with as much data as previously. </p>

                            <p>
                                This was a good thing for agencies. It added an extra barrier to successful Facebook advertising - it made it more difficult for an amateur to create multiple ad sets with multiple angles, a campaign budget, and let Facebook do the rest.
                            </p>
                        </div>

                        <div ref={refs[2]} className="plug-block">
                            <div className="plug-block-subheading">
                                You Can Still Use CBOs… Why?
                            </div>

                            <p>
                                Sometimes CBOs still make sense.
                            </p>

                            <p>The most obvious example is when you have a client with extremely large budgets that require distribution across a large number of ad sets. </p>

                            <p>
                                With a client spending $10K-$12K a day, this could be as many as 250 to 300 ad sets!
                            </p>

                            <p>
                                Not only does this pose a problem for the number of ads you can run on a single page (because of page limits) but also… put simply… your time.
                            </p>

                            <p>
                                Managing and optimising this many ad sets is next to impossible. CBOs can help with this and actually outperform ad set optimisation.
                            </p>

                            <p>
                                For example, a $700 dollars a day CBO with five ad sets will almost always outperform five $140 a day ad sets.
                            </p>
                        </div>

                        <div ref={refs[3]} className="plug-block">
                            <div className="plug-block-subheading">
                                How We Use CBOs
                            </div>

                            <p>
                                Our campaign structure is very similar, on each CBO we would have from 5 to 12 ad sets and the budgets will range from $200 to $2000 a day.
                            </p>

                            <p>[Image of the campaign structure on the hobby presentation]</p>

                            <p>
                                These are our strategies:
                            </p>

                            <div className="examples-block">
                                <p>
                                    CBO where All the ad sets are lookalikes of similar sizes
                                </p>

                                <p>
                                    CBO where All the ad sets are broad targeting
                                </p>

                                <p>CBO where there's a even mix of broad and lookalikes</p>

                                <p>
                                    CBO where All the ad sets are single interests
                                </p>

                                <p>
                                    CBO where we randomly combine best performers of each of the above
                                </p>
                            </div>
                        </div>

                        <div ref={refs[4]} className="plug-block">
                            <div className="plug-block-subheading">
                                How We Optimize CBOs
                            </div>

                            <p>
                                The best way to optimise CBO-set-campaigns is to treat them as ad sets.
                            </p>

                            <p>
                                That means not turning them off if they are profitable - even if ad sets are performing badly inside. This is because we don’t wish to jeopardize the top-performing ad sets.
                            </p>

                            <p>
                                If a CBO is underperforming as a whole, we will duplicate this, turn the old one off and start over again.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
