import React from 'react';
import accounting from 'accounting';
import moment from 'moment';
import { Button } from 'reactstrap';

const PERFORMANCE_MAP = {
    TEXT: {
        PERFORMANCE_FEE: 'Performance Fee',
        ONE_TIME_FEE: 'One Time Fee',
        ROLLING_MONTHLY: 'Rolling Monthly Retainer',
    },
    COLOURS: {
        PERFORMANCE_FEE: '#81EDA5',
        ONE_TIME_FEE: '#F6A31B',
        ROLLING_MONTHLY: '#1882DE',
    },
};

const STATUS_MAP = {
    TEXT: {
        AWAITING_APPROVAL: 'Awaiting Approval',
        APPROVED: 'Approved',
        REJECTED: 'Rejected',
    },
};


const AdminPaymentItem = ({ payment, onClickReview }) => {
    const metadata = JSON.parse(payment?.metadata || '{}');
    return (
        <div style={{
            width: '48%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            background: '#F2F2F2',
            margin: '0.5rem',
            borderRadius: '0.5rem',
            overflow: 'hidden',
        }}
        >
            <div style={{
                backgroundColor: PERFORMANCE_MAP.COLOURS[metadata?.gyaPaymentType],
                width: '.5rem',
                height: '100%',
            }}
            />
            <div style={{
                padding: '.5rem',
                flex: 0.7,
            }}
            >

                <div style={{
                    fontSize: '1rem',
                    fontWeight: '600',
                }}
                >
                    {PERFORMANCE_MAP.TEXT[metadata?.gyaPaymentType]}
                </div>
                <div style={{
                    fontSize: '0.8rem',
                    fontStyle: 'italic',
                }}
                >
                    {moment(payment?.createdTimestamp).format('dddd, MMMM Do YYYY')}
                </div>
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                textAlign: 'flex-end',
                padding: '.5rem 1rem',
                flex: 0.35,
            }}
            >
                <div style={{
                    fontSize: '1.2rem',
                    fontWeight: '600',
                }}
                >

                    {`${accounting.formatMoney(payment?.totalValue).replace('.00', '')}`}
                </div>
                <div style={{
                    fontSize: '0.7rem',
                }}
                >
                    {STATUS_MAP.TEXT[metadata?.gyaPaymentStatus]}
                </div>
                <Button
                    color="warning"
                    type="button"
                    size="sm"
                    style={{
                        marginTop: '.5rem',
                    }}
                    onClick={onClickReview}
                >
                    Review
                </Button>
            </div>
        </div>
    );
};

AdminPaymentItem.propTypes = {

};

export default AdminPaymentItem;
