import React from "react";
import "./style.scss";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import Container from "reactstrap/lib/Container";
import { Link } from "react-router-dom";
import PlugAndPlayNavigationPane from "../components/PlugAndPlayNavigationPane2";
import PlugAndPlayWrapper from "./PlugAndPlayWrapper";

const sections = [
  {
    available: true,
    sectionName: "Why report?",
  },
  {
    available: true,
    sectionName: "Automated Reporting",
  },
  {
    available: true,
    sectionName: "Cookieless UTM Reporting",
  },
  {
    available: true,
    sectionName: "Action step",
  },
];

const title = "Reporting 101";

export default () => {
  const refs = sections.map((item) => React.createRef(item.sectionName));

  const scrollTo = (index) => {
    if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
  };

  return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]}  className="plug-block">  
                        <h2 className="mb-4">{title}</h2>
              <div className="plug-block-subheading">Why report?</div>

              <p>
                Reporting is a way of not only letting your clients know how everything in the ads side of things is
                going, but it is also a very good way to keep yourself and your team in check. When you're checking in
                daily you'll be faster to notice when things are declining and stopping to work, when you notice that
                you can take action and do something about it. It will happen to you, if you don't implement this system
                that you or your team will forget about an ad account and will let it run without supervision and as
                with everything it will eventually stop working. You won't realize until it's already late and lots of
                money has been spent with no particularly good results. Always check your accounts and always check the
                reporting, that way you'll know when something is off. When your client has access to a dashboard where
                they can easily see the recent and past performance of the ads you also streamline communication. You
                don't want endless threads daily to know how the ads are doing. What you also don't want is your client
                logging in on the ad account and trying to read it or messing with it. Most of the time clients come to
                an agency to get away from the day to day marketing and the need to be on top of it, they want a
                simplified way of looking at results on demand so they don't have to wait for any answers. Your client
                doesn't really care about the CTR of this specific angle, they don't want to see the engagement metrics
                or what the audience is doing. They usually want the simplified version, that is what they hired you
                for. If they want more info in detail we still got them covered on the secondary pages of our reporting
                sheets, but in all honesty they're not really going to watch those pages. As seen on our kick off & On
                boarding call it is also used as a sales tool and as a way to amaze your clients.
              </p>
            </div>

            <div ref={refs[1]} className="plug-block">
              <div className="plug-block-subheading">Automated Reporting</div>
              <p>
                Template:
                <a
                  className="break-words" 
                  href="https://datastudio.google.com/u/0/reporting/4aec6ad0-519f-4a70-849a-3be750c4ce44/page/SGbTB"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://datastudio.google.com/u/0/reporting/4aec6ad0-519f-4a70-849a-3be750c4ce44/page/SGbTB
                </a>
              </p>
              <p>
                We will go over our reporting dashboard and what each page means. It is similar to what we went over on
                the kick off call section, but this time we will explain it on a more technical side and what is
                happening behind the scenes. Then we will see how you can get the template that we made and connect it
                with your accounts for each client.
              </p>
              <img src="https://img.genflow.com/gya/plugandplays/reporting1.png" alt="" />
              <p>
                In this page we have a visual representation of our clients funnel. Remember that this templates are
                fully customizable to your clients needs. For example some clients only want leads which would make the
                funnel way shorter and some others might have some extra steps you want to track. It is all up to you.
              </p>
              <p>
                We have a date picker at the top which allows the data studio to know which data to pull from. The
                numbers you see in the blue funnel is how many people or (event's been triggered) have been on that step
                of the funnel. With the iOS tracking limitations this actually means now how many people ended up in
                that step of the funnel. Basically you can now only track 1 event per person, and Facebook will only
                track the latest one which means that if you have a purchase, the checkout, add to cart and view content
                won't be tracked. You can programmatically make the numbers fit by summing all the predecessors of each
                step but this makes the sheet load extremely slow. So in short, just keep in mind that the numbers are
                the number of people that ended their journey on the step they show up.
              </p>
              <p>
                The money in money out is data pulled directly from Facebook. If you get the paid solution
                (supermetrics) you can change this to data directly from shopify. If you use the free version which is
                adveronix you won't be able to do that.
              </p>
              <p>
                On the right we have how much it costs to get someone to each step of the funnel. Because what I already
                mentioned about only being able to track one event per person this numbers no longer signify the same
                thing and can be deleted if wanted to.
              </p>
              <img src="https://img.genflow.com/gya/plugandplays/reporting2.png" alt="" />
              <p>
                Here is the same funnel on a fixed 6 month period, you can customize which six months show up. If the
                month hasn't passed it will show up empt
              </p>
              <img src="https://img.genflow.com/gya/plugandplays/reporting3.png" alt="" />
              <p>Here are some other metrics that also update with the date picker on top.</p>
              <p>
                On top there are 3 graphs that show return on ad spend vs ad spend, Add To Cart rate and add To Cart to
                Purchase rate (first is how many of the total visitors of the page added to cart, second is how many of
                those ended up buying) you want to see the blue line higher than 50%, if this is not the case on a day
                make sure you check that the checkout flow is working. Cost Per Click and Cost per Acquisition (cost to
                get a new customer)
              </p>
              <p>
                At the bottom we have a breakdown of the time frame chosen into days, this way you or your client can
                look for more specific data and maybe see outliers or days that the ads did better and worse. If you
                click on a column it will sort it out for you from greater to smaller or the opposite.
              </p>
              <img src="https://img.genflow.com/gya/plugandplays/reporting4.png" alt="" />
              <p>
                Finally we have an audience breakdown, in the background this is only pulling the names of each ad set
                and showing it there, so make sure that your ad sets are named with the audience name. We speak more
                about conventions in the modules where we actually launch ads. This ad set breakdown pulls the best 11
                ad sets and the rest are shown on different pages on the corner below.
              </p>
              <p>
                Below the ad set breakdown we have pie charts that describe more about the audience, which devices they
                use, which age ranges they are in and their genders. We also added a map that shows which countries you
                are advertising in.
              </p>
              <p>
                If any of the things added in the sheet doesn't make sense to your case, feel free to edit it to your
                liking, you can also play around with more of the data and display more information if you wish to. My
                best advice is take what has been given and only add things if you find your clients asking for it, you
                don't want to spend your time developing features no one will use.
              </p>
              <p className="bold">How to set up this reporting sheet</p>
              <img src="https://img.genflow.com/gya/plugandplays/reporting5.png" alt="" />
              <p>
                The reporting sheet uses a free service from google called Data Studio which allows you to display
                information in cool ways like that funnel or the charts or the pie charts or tables.
              </p>
              <p>
                We created the template so you can duplicate it for each of your clients and we also made a free and a
                paid solution
              </p>
              <p>
                We're not going into the technicalities of the set up as this changes often with the updates. For easier
                use refer to our plug n play where we show you exactly how to set up both solutions.
              </p>
              Free version Plug n Play (Difficult): <Link to="/resources/plug-and-play/client-bible">View here</Link>
              <p>
                Paid version Plug n Play (Easier):{" "}
                <Link to="/resources/plug-and-play/free-client-bible-template">View here</Link>
              </p>
            </div>

            <div ref={refs[2]} className="plug-block">
              <div className="plug-block-subheading">Cookieless UTM Reporting</div>

              <p>Bonus how to set up reporting with UTM data for ecommerce straight from Shopify:</p>

              <Link to="/resources/plug-and-play/true-tracking">
                <p>View here</p>
              </Link>

              <p>This is the same plug n play we shared on the 'All about tracking module as they work together!</p>
            </div>

            <div ref={refs[3]} className="plug-block">
              <div className="plug-block-subheading">Action step</div>

              <p>
                Duplicate your template on your own google drive and follow the steps of the plug n play. Make sure you
                have a functioning reporting sheet, that way once a new client signs you know exactly how to set them up
                and you will save a lot of time on it.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </PlugAndPlayWrapper>
  );
};
