/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import { get } from 'lodash';

import './style.scss';

export default ({
    video,
    selectedVideo,
    enableCompleteVideo = true,
    setSelectedVideo,
    onToggleComplete,
    section,
    subsection,
}) => {
    const lessonComplete = video?.complete;
    console.log("video", video)
    return (
        <div
            className={`list-group-item bodyBg relative ${video.id === get(selectedVideo, 'id') ? 'active' : ''}`}
            
        >
            <div 
                className="absolute w-full h-full bg-transparent z-20 cursor-pointer"
               onClick={() => {
                setSelectedVideo(video, { section, subsection });
            }}
            >
            </div>

            <div className="flex flex-row items-center bodyBg py-4">
                {enableCompleteVideo && (
                    <div>

{/*                    <div className="flex flex-row items-center mt-7">
                        <FullCheck className="h-8 w-8 text-white" aria-hidden="true" />
                        <div className="w-48 h-32 grey700Bg mx-6"></div>
                        <div className="flex flex-col mb-auto">
                            <div className="body5 text-white">The Apex Philosophy</div>
                            <div className="body6 text-white">00 min</div>
                        </div>
                    </div>*/}

                    <div className="custom-control custom-checkbox ml-2 lg:ml-0">
                        <input
                            className="custom-control-input m-0"
                            checked={lessonComplete}
                            key={`lesson-check-${video.id}-${lessonComplete}`}
                            id={`lesson-check-${video.id}`}
                            type="checkbox"
                            onChange={() => {
                                onToggleComplete(video, { section, subsection });
                            }}
                        />
                        <label className="custom-control-label" htmlFor={`lesson-check-${video.id}`} />
                    </div>

                    </div>
                )} 
                <div className="w-36 h-16 mx-3 lg:w-24 lg:h-12 lg:mx-2 rounded-md relative overflow-hidden">
                    <img src={video?.thumbnail} alt="" />
{/*                    <div className="absolute bg-white h-1 w-full bottom-0 rounded-b-md"></div>
*/}                </div>
                <div className="my-auto w-40">
                    <div className="flex flex-col">
                        <div className="body5 text-white">
                            {`${video.name}`}
                            <span className="badge badge-success" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    );
};
                    // <div className="flex flex-row items-center mt-7">
                    //     <FullCheck className="h-8 w-8 text-white" aria-hidden="true" />
                    //     <div className="w-48 h-32 grey700Bg mx-6"></div>
                    //     <div className="flex flex-col mb-auto">
                    //         <div className="body5 text-white">The Apex Philosophy</div>
                    //         <div className="body6 text-white">00 min</div>
                    //     </div>
                    // </div>