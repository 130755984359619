import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        available: true,
        sectionName: 'How to Use Our Client Bible Template and Connect with Google Sheet',
    },
];

const title = '[Free Version] Client Bible Template';

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]}  className="plug-block">  
                        <h2 className="mb-4">{title}</h2>
                            <div className="plug-block-subheading">[FREE Version] How to Use Our Client Bible Template and Connect with Google Sheet</div>

                            <ol>
                                <li>
                                    Make a copy of the following spreadsheet (you can do that by clicking on file -> make a copy):
                                    {' '}
                                    <a className="break-words" href="https://docs.google.com/spreadsheets/d/1bRmiCc45WUvxgVE5AJms9ru99e5bOMalNZoRYbM2rrg/edit?usp=sharing" target="_blank" rel="noopener noreferrer">https://docs.google.com/spreadsheets/d/1bRmiCc45WUvxgVE5AJms9ru99e5bOMalNZoRYbM2rrg/edit?usp=sharing</a>
                                    {' '}
                                    .
                                </li>
                                <li>
                                    On this spreadsheet, go into the page named “Ecom” if your client is an e-commerce store, or go into the page named “Lead Gen” if your client is a lead-based business.
                                </li>
                                <li>
                                    Now you will have to fill in the rows with your client’s data. To do that, open your client’s ad account on a new tab. We now need to set up our columns to look exactly as they look on our spreadsheet. To do that, inside the ads manager, go to columns -> customize columns.
                                </li>
                                <img src="https://img.genflow.com/gya/plugandplays/freeClientBible1.png" alt="" />
                                <li>
                                    A pop-up with your columns will open. You can now get rid of all the metrics that we are not using on our reporting. You can do that by clicking on the “x” icon next to each metric under the “columns selected” list, at the right portion of the pop-up.

                                </li>
                                <img src="https://img.genflow.com/gya/plugandplays/freeClientBible2.png" alt="" />
                                <li>
                                    Now, we have to select the metrics that we have on our spreadsheet. You can do that by searching for their name on the search bar and then checking the checkbox in front of the metric’s name.

                                </li>
                                <img src="https://img.genflow.com/gya/plugandplays/freeClientBible3.png" alt="" />
                                <li>
                                    After selecting all the metrics from our spreadsheet you can click on “apply”. In case you want to add any extra metrics, that’s fine. Just keep in mind that you’ll have to also add those to the spreadsheet as a new column.

                                    (just keep in mind that you should only report on absolute metrics. Metrics that are derived from other metrics - such as CTR, CPC, Frequency, CPL, Conv. Rates and costs in general - should not be reported here. We’ll create these metrics inside Datastudio using formulas later on.)
                                </li>
                                <li>
                                    Now, inside the ads manager, select the period that you want to report. Once you select the desired period, click on Breakdown -> By Time -> Day, as shown in the image below.

                                </li>
                                <img src="https://img.genflow.com/gya/plugandplays/freeClientBible4.png" alt="" />
                                <li>
                                    Now under the “campaign name” column, you can click on the arrow at the summary row (last row) to expand the results from your campaigns. This way, you’ll have a daily breakdown of all your metrics - exactly as we need to fill in the data in the client bible reporting spreadsheet.

                                </li>
                                <img src="https://img.genflow.com/gya/plugandplays/freeClientBible5.png" alt="" />

                                <li>
                                    You should now see a summary that looks like this:

                                    All you have to do right now is copy and paste this data into our spreadsheet. Make sure that each row in the spreadsheet represents a different day.


                                </li>
                                <img src="https://img.genflow.com/gya/plugandplays/freeClientBible6.png" alt="" />
                                <li>
                                    Once that’s done, your reporting sheet should look something similar to this (of course with your own data instead of the placeholder numbers we have here):

                                    <img src="https://img.genflow.com/gya/plugandplays/freeClientBible7.png" alt="" />


                                    In case you want to add any other metrics, you would add them to Column J, K, L, and so on. And always make sure the first row of each column is filled with the name of the metric. That’s important because Datastudio refers to the first row as a header.


                                </li>
                                <li>
                                    So now we will connect this spreadsheet to datastudio. To do so, first you should open the Datastudio that better fits you:
                                    <br />
                                    {' '}
                                    -> Datastudio for Ecom clients:
                                    <a className="break-words" href="https://datastudio.google.com/reporting/4aec6ad0-519f-4a70-849a-3be750c4ce44" target="_blank" rel="noopener noreferrer">https://datastudio.google.com/reporting/4aec6ad0-519f-4a70-849a-3be750c4ce44</a>
                                    {' '}
                                    <br />
                                    -> Datastudio for Lead Gen clients:
                                    {' '}
                                    <a className="break-words" href="https://datastudio.google.com/reporting/ab94eb1c-02f0-4f4e-a85b-7f9f37b3cae6" target="_blank" rel="noopener noreferrer">https://datastudio.google.com/reporting/ab94eb1c-02f0-4f4e-a85b-7f9f37b3cae6 </a>
                                    {' '}

                                </li>
                                <li>
                                    Once you have that open, make a copy of the template by clicking on the “make a copy” button at the top right corner, as shown in the image below


                                    <img src="https://img.genflow.com/gya/plugandplays/freeClientBible8.png" alt="" />
                                </li>
                                <li>
                                    A pop-up will open asking you to select your own databases. Those are the databases that Datastudio will pull the data from. You can see under “Original Data Source” that there are two data sources. The first one represents the sheet with the client data. The second one represents the Campaign Changelog sheet. We now have to change those to your databases, so that Datastudio can read your data. To do that, click on the first row saying “select a datasource…” under “New Data Source”. After you clicked, click on “Create new data source”, as shown in the pictures below:

                                    <img src="https://img.genflow.com/gya/plugandplays/freeClientBible9.png" alt="" />
                                    <img src="https://img.genflow.com/gya/plugandplays/freeClientBible10.png" alt="" />


                                </li>
                                <li>
                                    Now we have to select our Google Sheets connector. It’s the 3rd one under “Google Connectors”. Click on it.


                                    <img src="https://img.genflow.com/gya/plugandplays/freeClientBible11-19.png" alt="" />
                                </li>
                                <li>
                                    Then you select the client bible reporting sheet which you made a copy of on step #1. You first select the spreadsheet itself and then the worksheet (the page, really) that you filled with the client data. In case you’re doing this for Lead Gen clients, then you would select the page named “Lead Gen”. If it’s for an Ecom client, then you would select the page named “Ecom”. After that’s done, you just click on “connect” at the top right corner.

                                    <img src="https://img.genflow.com/gya/plugandplays/freeClientBible12.png" alt="" />
                                </li>
                                <li>
                                    Now here’s the important part. After clicking on “connect” you’ll be redirected to a new page. We are now going to create our custom formulas for CTR, CPC, CPA, etc. To do that, we click on “Add a Field”.

                                    <img src="https://img.genflow.com/gya/plugandplays/freeClientBible13.png" alt="" />
                                </li>
                                <li>
                                    Now, you will be redirected to a page where we can create these formulas. All you need to do here is copy the formulas that we already created. You can find them on the Google Sheet that you copied on step #1. If you’re reporting for Ecom clients, you go into the tab named “Ecom Formulas”. If you’re reporting for Lead Gen clients, you go into the tab named “Lead Gen Formulas”.

                                    <img src="https://img.genflow.com/gya/plugandplays/freeClientBible14.png" alt="" />

                                </li>
                                <li>
                                    Now, all you have to do is copy each one of those cells and paste them into their respective field in datastudio. We do that one row at a time. For example, I would first create the formula for CTR. To do so, I would first copy the Field Name and then paste it on Datastudio where it says “Field Name”. Then I would copy the Field ID and paste it on Datastudio where it says “Field ID”. And finally, I would copy the Field Formula and paste on Datastudio where it says “Field Formula”, as you can see in the picture below:

                                    <img src="https://img.genflow.com/gya/plugandplays/freeClientBible15.png" alt="" />
                                </li>
                                <li>
                                    Once that’s done, you can click on “Save”. Then, you go back to all fields by clicking on “All Fields” at the top left corner. You’ll then see the same screen that we saw in step #16. Now, we just have to repeat the same procedures from step #16 to step #18 with all the remaining formulas, until you have added them all. Once that’s done, your reporting should look something like this:

                                    <img src="https://img.genflow.com/gya/plugandplays/freeClientBible16.png" alt="" />

                                </li>
                                <li>
                                    Now, there’s only one thing left to do here. We have to make sure that under the “Type” column all of our metrics have the right type selected. For example, any metric that has “cost” in its name should be selected as a currency. And any metric that has “rate” or “ratio” in its name, should be selected as a percentage. You can change the metric type just by clicking on it and selecting the desired type.


                                </li>
                                <li>
                                    {' '}
                                    With that done, you just have to click on “Add to Report” at the top right corner.


                                </li>
                                <li>
                                    Data studio will take you back to the “copy report” pop-up, but now with the database you select for the first row. The only thing left is selecting the right database for the second row, which represents our campaign changelog report. To do that, again, click on the second row under “New Data Source” and then click on “Create New Datasource”, as shown in the pictures below:

                                    <img src="https://img.genflow.com/gya/plugandplays/FREECLIENTBIBLE17.png" alt="" />
                                    <img src="https://img.genflow.com/gya/plugandplays/freeClientBible18.png" alt="" />
                                </li>
                                <li>
                                    Again we select our Google Sheets connector.


                                    <img src="https://img.genflow.com/gya/plugandplays/freeClientBible11-19.png" alt="" />
                                </li>
                                <li>
                                    Then you select the client bible reporting sheet which you made a copy of on step #1. You first select the spreadsheet itself and then the worksheet (the page, really) that we called “Campaign Changelog”. After that’s done, you just click on “connect” at the top right corner.


                                </li>
                                <li>
                                    On this next page, we don’t really need to change anything. So you just click on “add to report” at the top right corner.


                                </li>
                                <li>
                                    And now Datastudio will again go back to the initial “copy this report” pop-up. We have everything ready to create our copy, so you just have to click on the “copy report” button.


                                </li>
                                <li>
                                    And your reporting is created! In case you want to change any metric, you can just click on the metric that you want changed and use the drag and drop at the right portion of your screen to do that.

                                    <img src="https://img.genflow.com/gya/plugandplays/freeClientBible20.png" alt="" />
                                </li>
                            </ol>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
