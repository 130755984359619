import PropTypes from 'prop-types';
import React from 'react';
import { findDOMNode } from 'react-dom';
import clsx from 'clsx';

import * as dates from 'react-big-calendar/lib/utils/dates';
import chunk from 'lodash/chunk';

import { navigate, views } from 'react-big-calendar/lib/utils/constants';
import { notify } from 'react-big-calendar/lib/utils/helpers';
import getPosition from 'dom-helpers/position';
import * as animationFrame from 'dom-helpers/animationFrame';

import Popup from 'react-big-calendar/lib/Popup';
import Overlay from 'react-overlays/Overlay';
import DateContentRow from 'react-big-calendar/lib/DateContentRow';
import Header from 'react-big-calendar/lib/Header';
import DateHeader from 'react-big-calendar/lib/DateHeader';

import { inRange, sortEvents } from 'react-big-calendar/lib/utils/eventLevels';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import MonthObjectives from '../MonthObjectives';
import ToDo from '../ToDo';
import { TaskContext } from '../../providers/TaskProvider';

const eventsForWeek = (evts, start, end, accessors) => evts.filter(e => inRange(e, start, end, accessors));
class MonthView extends React.Component {
    static contextType = TaskContext

    constructor(...args) {
        super(...args);

        this._bgRows = [];
        this._pendingSelection = [];
        this.slotRowRef = React.createRef();
        this.state = {
            rowLimit: 5,
            needLimitMeasure: true,
        };
    }

    UNSAFE_componentWillReceiveProps({ date }) {
        this.setState({
            needLimitMeasure: !dates.eq(date, this.props.date, 'month'),
        });
    }

    componentDidMount() {
        let running;

        if (this.state.needLimitMeasure) this.measureRowLimit(this.props);

        window.addEventListener(
            'resize',
            (this._resizeListener = () => {
                if (!running) {
                    animationFrame.request(() => {
                        running = false;
                        this.setState({ needLimitMeasure: true }) //eslint-disable-line
                    });
                }
            }),
            false,
        );
    }

    componentDidUpdate() {
        if (this.state.needLimitMeasure) this.measureRowLimit(this.props);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this._resizeListener, false);
    }

    getContainer = () => findDOMNode(this)

    render() {
        const {
            date, localizer, className, selectEvent, popup,
        } = this.props;
        const {
            classes, tasks, toggleTask, year,
        } = this.context;
        const month = dates.visibleDays(date, localizer);
        const weeks = chunk(month, 7);

        this._weekCount = weeks.length;


        return (
            <Grid
                className={classes.gridContainer}
                container
                justify="center"
            >
                <Grid
                    className={classes.calendarGridItem}
                    md={9}
                    style={{marginBottom: "2rem"}}
                >
                    <div
                        className="info-text"
                        style={{
                            margin: '1rem 0 1rem 0'
                        }}
                    >
                        Click on any date to add a task to your day.
                    </div>
                    <div
                        className={clsx('rbc-month-view', className)}
                        role="table"
                        aria-label="Month View"
                    >
                        <div className="rbc-row rbc-month-header" role="row">
                            {this.renderHeaders(weeks[0])}
                        </div>
                        {weeks.map(this.renderWeek)}
                        {popup && this.renderOverlay()}
                    </div>
                </Grid>
                <Grid md={3}>
                    <ToDo
                        toggleTask={toggleTask}
                        selectEvent={selectEvent}
                        monthDate={date}
                        year={year}
                    />

                  <div style={{ marginTop: '2rem'}}>
                      <MonthObjectives
                          toggleTask={toggleTask}
                          selectEvent={selectEvent}
                          monthDate={date}
                          year={year}
                      />
                  </div>
                </Grid>
            </Grid>
        );
    }

    renderWeek = (week, weekIdx) => {
        const {
            events,
            components,
            selectable,
            getNow,
            selected,
            date,
            localizer,
            longPressThreshold,
            accessors,
            getters,
            showAllEvents,
            resizable,
            rtl,
        } = this.props;

        const { needLimitMeasure, rowLimit } = this.state;

        const taskEvents = eventsForWeek(events.filter(({ entryType }) => entryType === 'task'), week[0], week[week.length - 1], accessors);

        taskEvents.sort((a, b) => sortEvents(a, b, accessors));

        return (
            <DateContentRow
                key={weekIdx}
                ref={weekIdx === 0 ? this.slotRowRef : undefined}
                container={this.getContainer}
                className="rbc-month-row"
                getNow={getNow}
                date={date}
                range={week}
                events={taskEvents}
                maxRows={showAllEvents ? Infinity : rowLimit}
                selected={selected}
                selectable={selectable}
                components={components}
                accessors={accessors}
                getters={getters}
                localizer={localizer}
                renderHeader={this.readerDateHeading}
                renderForMeasure={needLimitMeasure}
                onShowMore={this.handleShowMore}
                onSelect={this.handleSelectEvent}
                onDoubleClick={this.handleDoubleClickEvent}
                onKeyPress={this.handleKeyPressEvent}
                onSelectSlot={this.handleSelectSlot}
                longPressThreshold={longPressThreshold}
                rtl={rtl}
                resizable={resizable}
                showAllEvents={showAllEvents}
            />
        );
    }

    readerDateHeading = ({ date, className, ...props }) => {
        const { date: currentDate, getDrilldownView, localizer } = this.props;

        const isOffRange = dates.month(date) !== dates.month(currentDate);
        const isCurrent = dates.eq(date, currentDate, 'day');
        const drilldownView = getDrilldownView(date);
        const label = localizer.format(date, 'dateFormat');
        const DateHeaderComponent = this.props.components.dateHeader || DateHeader;

        return (
            <div
                {...props}
                className={clsx(
                    className,
                    isOffRange && 'rbc-off-range',
                    isCurrent && 'rbc-current',
                )}
                role="cell"
            >
                <DateHeaderComponent
                    label={label}
                    date={date}
                    drilldownView={drilldownView}
                    isOffRange={isOffRange}
                    onDrillDown={e => this.handleHeadingClick(date, drilldownView, e)}
                />
            </div>
        );
    }

    renderHeaders(row) {
        const { localizer, components } = this.props;
        const first = row?.[0];
        const last = row?.[row?.length - 1];
        const HeaderComponent = components.header || Header;

        return dates.range(first, last, 'day').map((day, idx) => (
            <div key={`header_${idx}`} className="rbc-header">
                <HeaderComponent
                    date={day}
                    localizer={localizer}
                    label={localizer.format(day, 'weekdayFormat')}
                />
            </div>
        ));
    }

    renderOverlay() {
        const overlay = (this.state && this.state.overlay) || {};
        const {
            accessors,
            localizer,
            components,
            getters,
            selected,
            popupOffset,
        } = this.props;

        return (
            <Overlay
                rootClose
                placement="bottom"
                show={!!overlay.position}
                onHide={() => this.setState({ overlay: null })}
                target={() => overlay.target}
            >
                {({ props }) => (
                    <Popup
                        {...props}
                        popupOffset={popupOffset}
                        accessors={accessors}
                        getters={getters}
                        selected={selected}
                        components={components}
                        localizer={localizer}
                        position={overlay.position}
                        show={this.overlayDisplay}
                        events={overlay.events}
                        slotStart={overlay.date}
                        slotEnd={overlay.end}
                        onSelect={this.handleSelectEvent}
                        onDoubleClick={this.handleDoubleClickEvent}
                        onKeyPress={this.handleKeyPressEvent}
                        handleDragStart={this.props.handleDragStart}
                    />
                )}
            </Overlay>
        );
    }

    measureRowLimit() {
        this.setState({
            needLimitMeasure: false,
            rowLimit: this.slotRowRef.current.getRowLimit(),
        });
    }

    handleSelectSlot = (range, slotInfo) => {
        this._pendingSelection = this._pendingSelection.concat(range);

        clearTimeout(this._selectTimer);
        this._selectTimer = setTimeout(() => this.selectDates(slotInfo));
    }

    handleHeadingClick = (date, view, e) => {
        e.preventDefault();
        this.clearSelection();
        notify(this.props.onDrillDown, [date, view]);
    }

    handleSelectEvent = (...args) => {
        this.clearSelection();
        notify(this.props.onSelectEvent, args);
    }

    handleDoubleClickEvent = (...args) => {
        this.clearSelection();
        notify(this.props.onDoubleClickEvent, args);
    }

    handleKeyPressEvent = (...args) => {
        this.clearSelection();
        notify(this.props.onKeyPressEvent, args);
    }

    handleShowMore = (events, date, cell, slot, target) => {
        const {
            popup, onDrillDown, onShowMore, getDrilldownView,
        } = this.props;
        // cancel any pending selections so only the event click goes through.
        this.clearSelection();

        if (popup) {
            const position = getPosition(cell, findDOMNode(this));

            this.setState({
                overlay: {
                    date, events, position, target,
                },
            });
        } else {
            notify(onDrillDown, [date, getDrilldownView(date) || views.DAY]);
        }

        notify(onShowMore, [events, date, slot]);
    }

    overlayDisplay = () => {
        this.setState({
            overlay: null,
        });
    }

    selectDates(slotInfo) {
        const slots = this._pendingSelection.slice();

        this._pendingSelection = [];

        slots.sort((a, b) => +a - +b);

        notify(this.props.onSelectSlot, {
            slots,
            startDateTime: moment(slots[0]),
            endDateTime: moment(slots[slots.length - 1]),
            action: slotInfo.action,
            bounds: slotInfo.bounds,
            box: slotInfo.box,
        });
    }

    clearSelection() {
        clearTimeout(this._selectTimer);
        this._pendingSelection = [];
    }
}

MonthView.propTypes = {
    events: PropTypes.array.isRequired,
    date: PropTypes.instanceOf(Date),

    min: PropTypes.instanceOf(Date),
    max: PropTypes.instanceOf(Date),

    step: PropTypes.number,
    getNow: PropTypes.func.isRequired,

    scrollToTime: PropTypes.instanceOf(Date),
    rtl: PropTypes.bool,
    resizable: PropTypes.bool,
    width: PropTypes.number,

    accessors: PropTypes.object.isRequired,
    components: PropTypes.object.isRequired,
    getters: PropTypes.object.isRequired,
    localizer: PropTypes.object.isRequired,

    selected: PropTypes.object,
    selectable: PropTypes.oneOf([true, false, 'ignoreEvents']),
    longPressThreshold: PropTypes.number,

    onNavigate: PropTypes.func,
    onSelectSlot: PropTypes.func,
    onSelectEvent: PropTypes.func,
    onDoubleClickEvent: PropTypes.func,
    onKeyPressEvent: PropTypes.func,
    onShowMore: PropTypes.func,
    showAllEvents: PropTypes.bool,
    onDrillDown: PropTypes.func,
    getDrilldownView: PropTypes.func.isRequired,

    popup: PropTypes.bool,
    handleDragStart: PropTypes.func,

    popupOffset: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number,
        }),
    ]),
};

MonthView.range = (date, { localizer }) => {
    const start = dates.firstVisibleDay(date, localizer);
    const end = dates.lastVisibleDay(date, localizer);
    return { start, end };
};

MonthView.navigate = (date, action) => {
    switch (action) {
    case navigate.PREVIOUS:
        return dates.add(date, -1, 'month');

    case navigate.NEXT:
        return dates.add(date, 1, 'month');

    default:
        return date;
    }
};

MonthView.title = (date, { localizer }) => localizer.format(date, 'monthHeaderFormat');

export default MonthView;
