import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import { Link } from 'react-router-dom';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        available: true,
        sectionName: 'Introduction',
    },
    {
        available: true,
        sectionName: 'What they are',
    },
    {
        available: true,
        sectionName: 'The Range of Interests Available',
    },
    {
        available: true,
        sectionName: 'How to Find Relevant Interests',
    },
    {
        available: true,
        sectionName: 'How to Run an Interest Based Ad',
    },
    {
        available: true,
        sectionName: 'Action step',
    },
];

const title = 'Interests: Sizes And How To Find The Best Ones For Your Case';

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]}  className="plug-block">  
                        <h2 className="mb-4">{title}</h2>
                            <div className="plug-block-subheading">
                                Introduction:
                            </div>

                            <p>
                                An ‘interest’ is a characteristic ascribed to a user by Facebook.
                            </p>

                            <p>
                                We bundle these together with all the 'standard' ways of targeting and they can be found in the Detailed Targeting section of each ad set.
                            </p>

                            <p>
                                You can think about these as 'buckets' of people that Facebook has classified based on their Demographics, Interests and Behaviours.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/interestsSizes20.png" alt="" />

                            <div className="examples-block">
                                <p>
                                    Any person can be in any number of 'buckets' at the same time which means there's an overlap between these buckets. The more you learn about Facebook advertising, the more you will hear people caution you not to ‘compete with yourself’: the reality is that this is all-but-impossible.
                                </p>

                                <p>
                                    Thousands of advertisers vy for the exact same interests at any point in time. Launching another ad set with similar audiences will not cause you to compete with yourself. In fact, according to our Facebook representative it’s actually impossible from the same sad set.
                                </p>

                                <p>
                                    But to avoid doubt you can launch two ad sets with the exact same targeting and see that the results will differ dramatically.
                                </p>
                            </div>

                            <p>
                                With that out of the way let's do a quick tour of the detailed targeting available.

                            </p>

                            <p>
                                It is important that you know all of these at least in the back of your head, that way you will have an idea of what Facebook is capable of.
                            </p>

                            <div style={{ height: 350 }}>
                                <iframe
                                    id="videoPlayer"
                                    className="sproutvideo-player"
                                    src="https://videos.sproutvideo.com/embed/4d9dd8b61611efc8c4/d4daff1dbba387bd?playerTheme=dark&amp;playerColor=263238"
                                    frameBorder="0"
                                    allowFullScreen
                                    width="100%"
                                    height="100%"
                                />
                            </div>
                        </div>


                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">
                                What they are
                            </div>

                            <p className="bold">
                                Targeting Attributes
                            </p>

                            <p>
                                There are three categories:
                            </p>

                            <ul>
                                <li>Demographics</li>
                                <li>Interests</li>
                                <li>
                                    Behaviours.
                                </li>
                            </ul>

                            <p>
                                This category will appear on the left on grey.  As a rule of thumb, 90% of the time it’s better to target ‘Interests’ rather than Demographics or Behaviours: mainly because interests are the only audiences with enough size for targeting to be effective.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/interestsSizes1.png" alt="" />

                            <p>
                                When you hover one of the options, it will show you the size of the interest and also the category and subcategories it belongs to. The size refers to its global size, meaning all the Facebook users, if you're targeting a specific country or countries (which you should) even if your client can ship worldwide this audience will narrow down.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/interestsSizes2.png" alt="" />

                            <p>
                                Once you've chosen an interest for example 'Digital marketing', with 175M people interested, on the top right corner it will show you the size of the audience that meets all the conditions you've put.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/interestsSizes3.png" alt="" />

                            <p>
                                In this case because we're targeting 4 countries the audience goes from 175M to 12M.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/interestsSizes4.png" alt="" />

                            <p>
                                Remember: There is no magic or minimum size of your interest. Ideally, you should look to use audiences in excess of 200K - but it’s possible to find success with as little as 50K.
                            </p>

                            <p>
                                If you have local business clients you have to worry even less about the size of the audience. Refer to the module
                                {' '}
                                <Link to="/resources/plug-and-play/facebook-native-vs-funnel-lead-gen">"Facebook Native vs Funnel Lead Gen: Setting Them Up"</Link>
                                {' '}
                                for more information on what kind of targeting to do for local businesses.
                            </p>
                        </div>

                        <div ref={refs[2]} className="plug-block">
                            <div className="plug-block-subheading">
                                The Range of Interests Available
                            </div>

                            <p>
                                The list of Facebook audiences is wide and ever-changing. If you’re looking for an interest, you’ll probably find it. Here are some examples:
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/interestsSizes5.png" alt="" />
                            <img src="https://img.genflow.com/gya/plugandplays/interestsSizes6.png" alt="" />
                            <img src="https://img.genflow.com/gya/plugandplays/interestsSizes7.png" alt="" />
                            <img src="https://img.genflow.com/gya/plugandplays/interestsSizes8.png" alt="" />
                            <img src="https://img.genflow.com/gya/plugandplays/interestsSizes9.png" alt="" />
                        </div>

                        <div ref={refs[3]} className="plug-block">
                            <div className="plug-block-subheading">
                                Where Does This Information Come From?
                            </div>

                            <p>
                                There are three ways Facebook gathers this data:
                            </p>

                            <ol>
                                <li>
                                    You gave it to them
                                </li>
                                <p>
                                    Did you add your marital status when you got married? Did you put your date of birth? Have you allowed your location to be tracked? Which school or uni you went to? Did you put where you work?
                                </p>

                                <li>
                                    You've interacted with content like it on their platforms
                                </li>
                                <p>
                                    Everything from liking a page about dog memes to joining an interior design  group informs Facebook of your interests.
                                </p>

                                <li>
                                    They bought it from a third party
                                </li>
                                <p>
                                    Facebook also buys data from thousands of other companies and websites to improve their targeting.
                                </p>
                            </ol>
                        </div>

                        <div ref={refs[4]} className="plug-block">
                            <div className="plug-block-subheading">
                                How to Find Relevant Interests
                            </div>

                            <p>
                                Consider the following questions if you’re trying to find relevant interests for your clients.
                            </p>

                            <ol>
                                <li>
                                    When or where would people use this product or service?
                                </li>
                                <li>
                                    What kind of people would use the product or service?
                                </li>
                                <li>
                                    How and why would people use the product or service?
                                </li>
                            </ol>

                            <p>
                                Let's circle back to one of the examples we visited on the module
                                {' '}
                                <Link to="/resources/plug-and-play/science-behind-creatives-that-sells">"Science Behind a Creative that Sells"</Link>
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/interestsSizes10.png" alt="" />

                            <p>
                                Here we have 4 angles, we will find and think about two interests per angle that make sense.
                            </p>

                            <p>
                                This doesn't mean that we will be only using the angles with their corresponding interest, keep in mind the structure of the ad set: all the angles go together in all ad sets. This is just a good way of finding interests that can work using the ideas you already worked on when working on the ads.
                            </p>

                            <p>
                                <span className="bold">Angle 1:</span>
                                {' '}
                                Sunny weather
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/interestsSizes11.png" alt="" />

                            <p>
                                Interests: Holidays, beach, summer vacation
                            </p>

                            <p>
                                <span className="bold">Angle 2:</span>
                                {' '}
                                Sun protection and skin cancer
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/interestsSizes12.png" alt="" />

                            <img src="https://img.genflow.com/gya/plugandplays/interestsSizes13.png" alt="" />
                            <img src="https://img.genflow.com/gya/plugandplays/interestsSizes14.png" alt="" />

                            <img src="https://img.genflow.com/gya/plugandplays/interestsSizes15.png" alt="" />

                            <p>
                                Interests: Skin cancer foundation, sunscreen, sun protective clothing
                            </p>

                            <p>
                                <span className="bold">Angle 3:</span>
                                {' '}
                                Fishing
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/interestsSizes16.png" alt="" />

                            <p>
                                Interests: Fishing and fishing techniques (as interest)
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/interestsSizes17.png" alt="" />

                            <p>
                                Interests: Bass pro shops (shop well known to fishers)
                            </p>

                            <p>
                                <span className="bold">   Angle 4:</span>
                                {' '}
                                Outdoor sports
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/interestsSizes18.png" alt="" />
                            <img src="https://img.genflow.com/gya/plugandplays/interestsSizes19.png" alt="" />
                        </div>

                        <div ref={refs[5]} className="plug-block">
                            <div className="plug-block-subheading">
                                How to Run an Interest Based Ad
                            </div>

                            <p>
                                After creating your list of interests, the challenge comes in testing them and running ads.
                            </p>

                            <p>
                                The easiest way to do this is to have a single interest per ad set and to let it run with the appropriate budget (usually around $20 to $70 a day).
                            </p>

                            <p>
                                Running these interests discretely allows you to test each one independently, rather than grouping them together and testing which works.
                            </p>

                            <p>
                                Once you work out which ones work, and which don’t, you’ll be able to turn them on/off and allocate more budget. We will explain how to make these decisions on the module
                                {' '}
                                <Link to="/resources/plug-and-play/load-balancing-budgets">"Load Balancing Budgets"</Link>
                            </p>

                            <div className="plug-block-subheading">
                                Action step
                            </div>

                            <p>
                                Repeat the same exercise we just did with either one of your current clients. Use the same angles you wrote on the module "Science Behind a Creative that Sells" previously visited.
                            </p>

                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
