import { Auth } from 'aws-amplify';
import config from '../config';

const { vendorUuid } = config;

const isBrowser = typeof window !== 'undefined';

const getUserName = email => `${email.toLowerCase().trim()}#${vendorUuid}`;

export const getUser = () => isBrowser && Auth.currentAuthenticatedUser();

export const getToken = () => isBrowser && Auth.currentSession();

export const register = async ({
    firstName, lastName, email, password,
}) => {
    const result = await Auth.signUp({
        username: getUserName(email),
        password,
        attributes: {
            email,
            given_name: firstName,
            family_name: lastName,
        },
    });

    if (result) {
        return await login({ email, password });
    }
};

export const login = async ({ email, password }) => {
    const result = await Auth.signIn(
        getUserName(email),
        password,
    );
    return result && result.signInUserSession;
};

export const loginPasswordless = emailAddress => new Promise((resolve, reject) => {
    Auth.signIn(getUserName(emailAddress))
        .then(resolve)
        .catch((error) => {
            console.log('Something went wrong!', error);
            if (error.code === 'UserNotFoundException') {
                reject('User does not exist!');
            } else if (error.code === 'NotAuthorizedException') {
                reject('Incorrect email address or password!');
            } else {
                reject(null);
            }
        });
});


export const logout = async () => {
    if (isBrowser) await Auth.signOut();
};

export const isAdmin = async () => {
    const user = await getUser();
    // console.log('user', user);
    if (user) {
        return user?.signInUserSession?.accessToken?.payload['cognito:groups']?.indexOf('Vendor-Admin') > -1;
    }
    return false;
};
