import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import { Link } from 'react-router-dom';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';
import Image3 from '../../../../../assets/img/plugandplay/lemlist-account-3.png';

const sections = [
    {
        available: true, sectionName: 'Before you start',
    },
    {
        available: true, sectionName: 'Connect Your Email Provider',
    },
    {
        available: true, sectionName: 'Connect Your Tracking Domain',
    },
];

const title = "How to Set Up Your Lemlist Account"

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]}  className="plug-block"> 
                            <div className="plug-block-subheading">
                                Before you start:
                            </div>

                            <ul>
                                <li>
                                    A domain
                                </li>
                                <li><Link to="/resources/plug-and-play/how-to-set-up-your-email-account">A fully setup email address</Link></li>
                            </ul>

                            <p>
                                LemList is a software which allows you to automate the delivery of multiple emails a day, schedule automatic follow-ups and monitor your email campaigns.
                            </p>

                            <p>
                                Click here to sign up to LemList with an exclusive lifetime 20% discount.
                            </p>

                            <p>At checkout, use the code 'CIARANENAGROWURAGENCYSCHOOL'</p>
                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">
                                Connect Your Email Provider
                            </div>

                            <p>
                                If you have created your domain and email using Google WorkSpace, you can easily connect this to your LemList account
                                {' '}
                                <a href="https://app.lemlist.com/settings/email-provider" target="_blank" rel="noopener noreferrer">here</a>
                            </p>
                        </div>

                        <div ref={refs[2]} className="plug-block">
                            <div className="plug-block-subheading">
                                Connect Your Email Provider
                            </div>

                            <p>
                                Go to the team section of settings and enter in your agency’s website here:
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/lemlist-account-1-9e6c79e9-jpg.jpg" alt="" style={{ marginBottom: '1rem' }} />

                            <p>
                                Once you have entered your domain, LemList will offer values to enter into your domain’s DNS settings.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/lemlist-account-2-0bec4e40-jpg.jpg" alt="" style={{ marginBottom: '1rem' }} />

                            <p>
                                If you have purchased your domain through Google, go to
                                {' '}
                                <a href="https://domains.google.com/registrar/" target="_blank" rel="noopener noreferrer">Google domains</a>
                                .
                            </p>

                            <p>Next to your list of domains, click ‘Manage’.</p>

                            <img src={Image3} alt="" style={{ marginBottom: '1rem', width: '50%' }} />

                            <p>
                                On the left-hand panel, go to ‘DNS’.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/lemlist-account-4-5fa890ab-jpg.jpg" alt="" style={{ marginBottom: '1rem', width: '75%' }} />

                            <p>
                                Scroll to the bottom of the page to ‘Custom Resource Records’
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/lemlist-account-5-01f6c1cd-jpg.jpg" alt="" style={{ marginBottom: '1rem' }} />

                            <p>
                                Change ‘A’ to ‘CNAME’.
                            </p>

                            <p>
                                Copy the ‘Host’ value (Trail) into the ‘Name’ box.
                            </p>

                            <p>Copy the ‘Value’ value (custom.lemlist.com) into the domain name. </p>

                            <p>Once you have entered this, click ‘Setup my tracking domain’. </p>

                            <p>
                                Please note this may take up to 72 hours to populate - so don’t worry if this doesn’t work straight away!
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
