import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { Link } from 'react-router-dom';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        available: true, sectionName: 'Before your start',
    },
    {
        available: true, sectionName: 'Newsletters',
    },
    {
        available: true, sectionName: 'Friends/ Family',
    },
    {
        available: true, sectionName: 'LemWarm',
    },
];

const title = "Warming Up Your Email Address"

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]}  className="plug-block"> 
                            <div className="plug-block-subheading">
                                Before you start:
                            </div>

                            <p>
                                What you’ll need:
                            </p>

                            <ul>
                                <li>
                                    A domain
                                </li>
                                <li><Link to="/resources/plug-and-play/how-to-set-up-your-email-account">A fully setup email address</Link></li>
                            </ul>

                            <p>
                                Email addresses aren’t like phone numbers: they don’t work out of the box.
                            </p>

                            <p>
                                To ensure deliverability, it’s important to ‘warm up’ the email you use. It doesn’t matter if you’ve used a separate inbox/ username on the same domain: each address needs to be warmed up.
                            </p>

                            <p>
                                But this step is particularly important for new email addresses from new domains.
                            </p>

                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">
                                Newsletters


                            </div>

                            <p>
                                Sign up to a few newsletters to increase the number of inbound emails in your inbox. This is an easy way to signal to your domain that you are using your inbox legitimately.
                            </p>
                        </div>

                        <div ref={refs[2]} className="plug-block">
                            <div className="plug-block-subheading">
                                Friends/ Family


                            </div>

                            <p>
                                Next: email your family and friends. If your email lands in their spam folder or in the ‘Categories’ section of their Gmail inbox, ask them to move it to their primary inbox.
                            </p>

                            <p>Make sure to conduct ‘conversations’ with email threads of between three-to-four emails each, replying. </p>

                        </div>

                        <div ref={refs[3]} className="plug-block">
                            <div className="plug-block-subheading">
                                LemWarm


                            </div>

                            <p>
                                LemWarm is a function offered by Lemlist which emails other Lemlist users and conducts ‘conversations’ complete with replies on your behalf.
                            </p>

                            <p>
                                If any of your emails make it to the categories, or Spam folder of the other user’s account, they are moved into the primary inbox.
                            </p>

                            <p>This is an excellent service and - regardless of whether you choose to use Lemlist for outreach or not - you should use this. </p>

                            <p>
                                LemWarm is offered as a separate, cheaper subscription to LemList but is included in standard plans. You only need this for one month.
                            </p>

                            <p>
                                <Link to="/resources/plug-and-play/how-to-set-up-your-lemlist-account">
                                    => How to Set Up LemList
                                </Link>
                            </p>

                            <p>
                                Once you have set up your LemList account, you can begin using LemWarm.
                            </p>

                            <p className="bold">
                                You will need to use this service for at least 14 days before sending cold emails but ideally you should wait 30 days before using this email address for outreach.
                            </p>

                            <p>
                                You only have two variables to select:
                            </p>

                            <ol>
                                <li>
                                    The number of warm-up emails sent per day after the initial ‘ramp-up’ period (i.e. the period in which LemWarm slowly sends more and more emails from your account.
                                </li>

                                <img src="https://img.genflow.com/gya/plugandplays/warming-up-email-2-3ea92b73-jpg.jpg" alt="" />

                                <p>
                                    LemList advises that if the email address (regardless of the domain it is attached to) is younger than six months, you should select 30, and if the email address is older than six months, you should select 40.
                                </p>

                                <li>
                                    The ramp-up increment per day (i.e. by the increment of emails LemWarm sends on a daily basis until it reaches the maximum number set by the previous variable)
                                </li>

                                <img src="https://img.genflow.com/gya/plugandplays/warming-up-email-1-56c0abb3-jpg.jpg" alt="" />

                                <p>
                                    Again, LemList advises that for email addresses younger than six months, you choose a more conservative value of 1, and for older than six months, 2 per day.
                                </p>

                                <p>After 30 days, simply ‘pause’ this function. If you have used LemList exclusively for this purpose, you can now cancel your subscription. </p>

                                <p>If you continue to use LemList, you can use this function during any period where you are not performing email outreach, to keep your email rates high. </p>
                            </ol>
                        </div>

                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
