import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import { Link } from 'react-router-dom';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';
import Image1 from '../../../../../assets/img/plugandplay/dream-dashboard-setup/image1.png';
import Image2 from '../../../../../assets/img/plugandplay/dream-dashboard-setup/image2.png';
import Image3 from '../../../../../assets/img/plugandplay/dream-dashboard-setup/image3.png';
import Image4 from '../../../../../assets/img/plugandplay/dream-dashboard-setup/image4.png';
import Image5 from '../../../../../assets/img/plugandplay/dream-dashboard-setup/image5.png';
import Image6 from '../../../../../assets/img/plugandplay/dream-dashboard-setup/image6.png';
import Image7 from '../../../../../assets/img/plugandplay/dream-dashboard-setup/image7.png';
import Image8 from '../../../../../assets/img/plugandplay/dream-dashboard-setup/image8.png';
import Image9 from '../../../../../assets/img/plugandplay/dream-dashboard-setup/image9.png';
import Image10 from '../../../../../assets/img/plugandplay/dream-dashboard-setup/image10.png';
import Image11 from '../../../../../assets/img/plugandplay/dream-dashboard-setup/image11.png';
import Image12 from '../../../../../assets/img/plugandplay/dream-dashboard-setup/image12.png';
import Image13 from '../../../../../assets/img/plugandplay/dream-dashboard-setup/image13.png';
import Image14 from '../../../../../assets/img/plugandplay/dream-dashboard-setup/image14.gif';
import Image16 from '../../../../../assets/img/plugandplay/dream-dashboard-setup/image16.png';

const sections = [
    {
        available: true,
        sectionName: 'Step 1: Go to your ads manager',
    },
    {
        available: true,
        sectionName: 'Step 2: Select columns',
    },
    {
        available: true,
        sectionName: 'Step 3: Go to “Customise Columns…”',
    },
    {
        available: true,
        sectionName: 'Step 4: Select the columns',
    },
    {
        available: true,
        sectionName: 'Step 5: Custom Metrics',
    },
    {
        available: true,
        sectionName: 'Step 6: Funnel overview',
    },
    {
        available: true,
        sectionName: 'Step 7: Save your dashboard',
    },
];

const title = 'Dream Dashboard Set Up';

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title={title}>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                            title={title}
                            sections={sections}
                            scrollTo={scrollTo}
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]} className="plug-block">
                            <div className="plug-block-subheading">
                                Step 1: Go to your ads manager
                            </div>
                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">
                                Step 2: Select columns
                            </div>

                            <img src={Image2} alt="" style={{ marginBottom: '1rem' }} />
                        </div>

                        <div ref={refs[2]} className="plug-block">
                            <div className="plug-block-subheading">
                                Step 3: Go to “Customise Columns…”
                            </div>

                            <img src={Image12} alt="" style={{ marginBottom: '1rem', width: '50%' }} />
                        </div>

                        <div ref={refs[3]} className="plug-block">
                            <div className="plug-block-subheading">
                                Step 4: Select the columns
                            </div>

                            <div>
                                <p>
                                    On the right hand side of the pop up window there’s a section that shows you the
                                    columns you’ve selected for your current view, you can drag these where the dots are
                                    and arrange them as you wish. The higher you arranged it the more to the left it will
                                    show on the actual ads manager.
                                </p>
                            </div>

                            <img src={Image1} alt="" style={{ marginBottom: '1rem', width: '50%' }} />

                            <div>
                                <p>
                                    You can also add new columns by searching for them on the search bar and then clicking on them,
                                </p>
                            </div>

                            <img src={Image5} alt="" style={{ marginBottom: '1rem', width: '50%' }} />

                            <div>
                                <p>The following are the columns we recommend having and their order:</p>
                                <ol>
                                    <li>Results</li>
                                    <li>Cost per result</li>
                                    <li>Reach</li>
                                    <li>Impressions</li>
                                    <li>Frequency</li>
                                    <li>Amount spent</li>
                                    <li>Budget</li>
                                    <li>Ad set name</li>
                                    <li>Quality ranking</li>
                                    <li>Engagement ranking</li>
                                    <li>Conversion rate ranking</li>
                                    <li>Link clicks</li>
                                    <li>Website purchases (In case you’re running for purchases)</li>
                                    <li>Website purchases ROAS (In case you’re running for purchases)</li>
                                    <li>CTR (link click-through rate) [As opposed to *ALL, all means that they count when someone clicks on your page, on see more or reacts, which is not the tru metric you want to see, you want to see how many people are going to your website)</li>
                                    <li>CPM</li>
                                </ol>

                            </div>
                        </div>

                        <div ref={refs[4]} className="plug-block">
                            <div className="plug-block-subheading">
                                Step 5: Custom Metrics
                            </div>

                            <div>
                                <p>
                                    Custom metrics are new columns based on formulas you can create to help you better understand your performance, for instance you can create things like Click Discrepancy which will tell you how many people are waiting for your page to load after clicking your ad. This metric which is not standard by Facebook will give you information based on your page speed and also tell you if your image is too clickbaity. If you see that a lot of people are not waiting for your page to load, it probably means that your page is loading too slow and you need to fix it.
                                    To do this on the right side of the search bar there is a button that says “Create custom metric”
                                </p>
                            </div>

                            <img src={Image7} alt="" style={{ marginBottom: '1rem' }} />

                            <div>
                                <p>
                                    A pop up will appear and let you create your metric, you can name them, choose its type (numeric, percentage or currency), add a description to it, specify the formula and select access. On the right hand side column you can select variables for your formula.
                                </p>
                            </div>

                            <img src={Image4} alt="" style={{ marginBottom: '1rem' }} />
                            <div>
                                <p>
                                    <span className="bold">Let’s create the following metrics:</span>
                                    <br />
                                </p>
                            </div>
                            <div className="examples-block" style={{ marginBottom: '1rem' }}>

                                <p>
                                    <span className="bold">Name:</span>
                                    {' '}
                                    Conversion Rate
                                    <br />
                                    <span className="bold">Format:</span>
                                    {' '}
                                    Percentage
                                    <br />
                                    <span className="bold">Description:</span>
                                    {' '}
                                    A percentage that tells you the proportion of people that bought in comparison to how many saw the offer
                                    <br />
                                    <span className="bold">Formula:</span>
                                </p>
                                <img src={Image3} alt="" style={{ marginBottom: '1rem' }} />
                                <div>
                                    <p>
                                        <span className="bold">Who can access this?:</span>
                                        {' '}
                                        Everyone with access to this Business
                                    </p>
                                </div>
                            </div>
                            <div className="examples-block" style={{ marginBottom: '1rem' }}>
                                <p>
                                    <span className="bold">Name:</span>
                                    {' '}
                                    Conversion Rate Unique
                                    <br />
                                    <span className="bold">Format:</span>
                                    {' '}
                                    Percentage
                                    <br />
                                    <span className="bold">Description:</span>
                                    {' '}
                                    A percentage that tells you the proportion of people that bought uniquely in comparison to how many saw the offer. Unique refers that it will only count one purchase per person, even if they bought more than once.
                                    <br />
                                    <span className="bold">Formula:</span>
                                </p>
                                <img src={Image9} alt="" style={{ marginBottom: '1rem' }} />
                                <div>
                                    <p>
                                        <span className="bold">Who can access this?:</span>
                                        {' '}
                                        Everyone with access to this Business
                                    </p>
                                </div>
                                <div className="examples-block" style={{ marginBottom: '1rem' }}>
                                    <p>
                                        <span className="bold">Pro tip:</span>
                                        {' '}
                                        When creating this formulas, you can start typing in what you’re searching for and it will show you suggestions that match your search. This is easier compared to searching the metrics on the right hand side column.
                                    </p>
                                </div>
                                <img src={Image16} alt="" style={{ marginBottom: '1rem', width: '50%' }} />
                            </div>
                            <div className="examples-block" style={{ marginBottom: '1rem' }}>
                                <p>
                                    <span className="bold">Name:</span>
                                    {' '}
                                    AOV
                                    <br />
                                    <span className="bold">Format:</span>
                                    {' '}
                                    Currency
                                    <br />
                                    <span className="bold">Description:</span>
                                    {' '}
                                    AOV - Average Order Value. This metric tells you how much are people spending on average on your store, this metric is useful to see if bundles, upsells and coresells are working as they should on the funnel or store.
                                    <br />
                                    <span className="bold">Formula:</span>
                                </p>
                                <img src={Image10} alt="" style={{ marginBottom: '1rem' }} />
                                <div>
                                    <p>
                                        <span className="bold">Who can access this?:</span>
                                        {' '}
                                        Everyone with access to this Business
                                    </p>
                                </div>
                            </div>
                            <div>
                                <p>Add all these custom metrics to your dashboard by selecting them as you selected all the other metrics. If they’re not showing up correctly, reload your page and try again, you won’t lose the current selected columns if you save them.</p>
                            </div>
                        </div>

                        <div ref={refs[5]} className="plug-block">
                            <div className="plug-block-subheading">
                                Step 6: Funnel overview
                            </div>
                            <div>
                                <p>
                                    The next columns to add are the ones that show you how many people have gone through the funnel. You need to map out your funnel in the columns, this means that you have to select all the columns that correspond to each step of your funnel in that order.
                                </p>
                                <p>For instance a store funnel in Facebook events looks like this:</p>
                                <ol>
                                    <li>Content view</li>
                                    <li>Add to cart</li>
                                    <li>Checkouts initiated</li>
                                    <li>Add payment info</li>
                                    <li>Purchases</li>
                                </ol>
                                <p>Add the columns that correspond to your funnel as you added them when you installed the tracking. If your client is using shopify the columns should coincide to the list gave above.</p>
                            </div>
                            <img src={Image11} alt="" style={{ marginBottom: '1rem', width: '50%' }} />
                            <p>*Deselect all the boxes</p>
                        </div>

                        <div ref={refs[6]} className="plug-block">
                            <div className="plug-block-subheading">
                                Step 7: Save your dashboard
                            </div>
                            <div>
                                <p>After your columns look like a version of this:</p>
                            </div>
                            <img src={Image14} alt="" style={{ marginBottom: '1rem', width: '50%' }} />
                            <div>
                                <p>Now you’ll need to save your configuration as a preset, name it as you wish, our name is “Dashboard”</p>
                            </div>
                            <img src={Image13} alt="" style={{ marginBottom: '1rem' }} />
                            <div>
                                <p>After it’s saved you will be able to see it on your columns section as a preset.</p>
                            </div>
                            <img src={Image6} alt="" style={{ marginBottom: '1rem', width: '50%' }} />
                            <div className="examples-block" style={{ marginBottom: '1rem' }}>
                                <p><span className="bold">Pro tip: Share set ups across ad accounts</span></p>
                                <p>Because all the presets are saved only on the ad account you will need to create all these Dashboards on every ad account we manage, to avoid that you can create it once on an ad account and load the set up. Once loaded you go to a different ad account on the same browser session and tab and the columns will stay the same, then you can go ahead and save them here:</p>
                            </div>
                            <img src={Image8} alt="" style={{ marginBottom: '1rem', width: '50%' }} />
                        </div>

                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
