import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import { Link } from 'react-router-dom';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';
import Image1 from '../../../../../assets/img/plugandplay/sending-linkedin-messages-1.gif';
import Image9 from '../../../../../assets/img/plugandplay/sending-linkedin-messages-9.gif';


const sections = [
    {
        available: true, sectionName: 'Before you start',
    },
    {
        available: true, sectionName: 'Connection requests',
    },
    {
        available: true, sectionName: 'New Connections',
    },
    {
        available: true, sectionName: 'Content Engagement',
    },
    {
        available: true, sectionName: 'Profile Views',
    },

];

const title = "Sending LinkedIn Messages"

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div className="plug-block">  
                        <h2 className="mb-4">{title}</h2>
                            <div className="plug-block-subheading">
                                PLEASE NOTE:
                            </div>

                            <p style={{ color: 'red' }}>
                                Templates and examples will be given in this Plug & Play. Do not copy templates word for word. Work to understand the theory behind templates and create your own.
                            </p>
                        </div>

                        <div ref={refs[0]} className="plug-block">
                            <div className="plug-block-subheading">
                                Before you start:

                            </div>

                            <p>
                                What you’ll need:
                            </p>

                            <ul>
                                <li>
                                    <Link to="/resources/plug-and-play/creating-your-linkedin-profile">An optimised LinkedIn account</Link>
                                </li>
                                <li><Link to="/resources/plug-and-play/using-sales-navigator">LinkedIn Sales Navigator</Link></li>
                            </ul>

                            <p>
                                Outreach on LinkedIn is a form of cold messaging like any other - but there are some peculiarities to the platform that are worth mentioning.
                            </p>

                            <ul>
                                <li>
                                    Unlike with emails or DMs (the requested inbox notwithstanding) it’s not possible to message someone on LinkedIn unless you are connected with them
                                </li>
                                <li>The exception to this is if you use InMail messages. The standard Premium Sales account grants entitles you to 20 InMail messages monthly. Some users have a setting turned on which prevents them from receiving InMail messages but this is unusual.</li>
                            </ul>
                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">
                                Connection requests
                            </div>


                            <p>
                                Although you can send connection requests without a personalised note, LinkedIn advises that you include one.
                            </p>

                            <p>
                                As you can see through this list of requests, requests with a message stand out and are more likely to be accepted:

                            </p>

                            <img src={Image1} alt="" style={{ marginBottom: '1rem' }} />

                            <p>
                                If you send a connection request via LinkedIn, you will be offered this when you click ‘connect’ on another user’s profile.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/sending-linkedin-messages-2-dddcc1da-jpg.jpg" alt="" style={{ marginBottom: '1rem' }} />

                            <p>
                                You will then be given an option to write the message when you click ‘Add a note’:
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/sending-linkedin-messages-3-6918b1b7-jpg.jpg" alt="" style={{ marginBottom: '1rem' }} />

                            <p>
                                If you choose to connect through the Sales Navigator, you will be given a slightly different prompt:
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/sending-linkedin-messages-4-25dd6d9c-jpg.jpg" alt="" style={{ marginBottom: '1rem' }} />

                            <p>
                                Connection request messages are limited to 300 characters. In these you have a limited space to convey why you are requesting to connect with an individual, and why they may benefit from this connection.
                            </p>

                            <p>
                                Here are some examples of
                                {' '}
                                <span className="bold">bad</span>
                                {' '}
                                connection requests:
                            </p>

                            <img src="img.genflow.com/gya/plugandplays/sending-linkedin-messages-5-4b09e98d-jpg.jpg" alt="" style={{ marginBottom: '1rem' }} />

                            <p>
                                This user has attempted a degree of personalisation: noting that Iman Gadzhi is a founder. However, the rest of the message indicates that they have made no effort otherwise to personalise the message or offer any compelling reason to connect.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/sending-linkedin-messages-6-fdc8fe70-jpg.jpg" alt="" style={{ marginBottom: '1rem' }} />

                            <p>
                                This user has made no attempt at personalisation and offers nothing in the way of value.
                            </p>

                            <p>
                                In contrast, this user has performed a high degree of personalisation, taking time to note an article written by Iman, and asking to refer him to a similar article for his thoughts.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/sending-linkedin-messages-7-3c9f5611-jpg.jpg" alt="" style={{ marginBottom: '1rem' }} />

                            <p>
                                There are no hard-and-fast rules or guarantees for getting connections, and whilst a message is much more likely to get your request accepted, it also depends on:
                            </p>

                            <ul>
                                <li>
                                    Not just having an optimised profile, but one which will appeal to the requested user
                                </li>
                                <li>
                                    Having mutual connections
                                </li>
                                <li>
                                    The popularity of the individual
                                </li>
                            </ul>

                            <p>
                                When it comes to crafting your own connection message, make sure you:
                            </p>

                            <ul>
                                <li>
                                    Personalise. Really personalise. Take the time to review the individual’s profile or anything you know about their business.
                                </li>
                                <li>
                                    Be genuine - don’t make up that you’ve bought their product/ watched their latest video/ read their latest article unless you haven
                                </li>
                                <li>
                                    Be friendly - not subservient - LinkedIn is for equals, you are a business owner. If you come across as obsequious, this will be off-putting as business owners will sense you want something from them.
                                </li>
                            </ul>

                            <p>
                                In messaging on Sales Navigator, you will be given relevant information about a lead that you can use to personalise. Let’s look at an example:
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/sending-linkedin-messages-8-5aad1c3f-jpg.jpg" alt="" style={{ marginBottom: '1rem' }} />

                            <p>
                                It takes only a few seconds to click on one of the three articles that Lee has shared, digest them and mention them in a message.
                            </p>

                            <p>
                                For example:
                            </p>

                            <div className="examples-block" style={{ marginBottom: '1rem' }}>
                                <p>
                                    Hi Lee,
                                </p>

                                <p>
                                    Great article you shared about a vegan diet making you more productive. Always have a bar of Green & Blacks Roasted Almond to hand when near a deadline.
                                </p>

                                <p>
                                    I work closely with vegan companies and would love to connect and see if I can share any more (non-chocolate) tips.
                                </p>

                                <p>
                                    Thanks,
                                </p>
                                <p>
                                    Iman
                                </p>
                            </div>

                            <p>
                                In this example, there is a clear personalisation and reference to something Lee has shared, a fun and familiar recommendation, and invitation to connect that is not imposing.
                            </p>

                            <p>
                                Example icebreakers are:
                            </p>

                            <ul>
                                <li>
                                    Honesty: the reason why you’re reaching out is that you’re looking to make more connections in the industry
                                </li>
                                <li>
                                    You saw one of their posts organically - this is credible if they have a popular/ viral post
                                </li>
                                <li>
                                    One of your shared connections has recommended them/ interacts with their content on LinkedIn
                                </li>
                                <li>
                                    You are a genuine fan of their business/ practice
                                </li>
                                <li>
                                    You are in a shared group
                                </li>
                            </ul>
                        </div>
                        <div ref={refs[2]} className="plug-block">
                            <div className="plug-block-subheading">
                                New Connections

                            </div>

                            <p>
                                As you make more connections on LinkedIn, and you are more active, you will start to receive inbound connection requests.

                            </p>

                            <p>
                                You should still remain discerning in who you choose to accept to your network - for instance, other agency owners may only cloud and clutter your network.
                            </p>

                            <p>
                                Also bear in mind that some connections are not of direct benefit to you: i.e. potential clients but are of an ancillary benefit: they are close to others in an industry, represent a trade body, magazine etc.
                            </p>

                            <p>
                                Tailor your messages to either type of connection: whilst you might wish to funnel a potential client to your agency website, case study, or to book in a call, you may wish for someone else to read and engage with more of your content.
                            </p>

                            <p>If a connection request comes in and you do choose to accept them, there are two options. </p>

                            <p className="bold">
                                If they have NOT included their own message request
                            </p>

                            <p>
                                This is a great opportunity to direct a new connection to something that may interest them, and display an interest in their business.
                            </p>

                            <p>
                                Do not bombard them with content or assume that them wanting to connect with you means that they want to set up a meeting with you.
                            </p>

                            <p>
                                Instead start a polite conversation with them based on:
                            </p>

                            <ul>
                                <li>
                                    Shared groups
                                </li>
                                <li>Shared connections</li>
                                <li>Anything from their profile you find noteworthy</li>
                                <li>
                                    Anything about their company you find noteworthy
                                </li>
                            </ul>

                            <p>
                                As the conversation progresses, you can then direct them to content.
                            </p>

                            <p>
                                For example:
                            </p>

                            <p className="bold">
                                Message 1:
                            </p>

                            <div className="examples-block" style={{ marginBottom: '1rem' }}>
                                <p>
                                    Hey Jade,
                                </p>

                                <p>Great to have you in my network! I’ve just had a quick chance to look at your website… and have to admit I didn’t realise the nasty stuff that’s actually in most candles. </p>

                                <p>Not really one for burning them myself but super interested in vegan companies so always nice to find a new one. </p>

                                <p>
                                    How did you end up deciding to start your own business?
                                </p>

                                <p>
                                    Iman
                                </p>
                            </div>

                            <p className="bold">
                                Message 2:
                            </p>

                            <div className="examples-block" style={{ marginBottom: '1rem' }}>
                                <p>
                                    That’s such a sweet story. Can’t believe you’ve made this much progress in two years - genuinely quite excited to see where you’ll go next.
                                </p>

                                <p>
                                    You actually remind me of one of our clients @ExampleCompany who we started working with a couple of months ago. They’ve got loads of powerful and moving reviews like yours.
                                </p>

                                <p>
                                    We’ve used the reviews loads in their advertising for social proof.
                                </p>

                                <p>
                                    Have you run ads before?
                                </p>

                                <p>Iman</p>
                            </div>

                            <p className="bold">
                                Message 3:
                            </p>

                            <div className="examples-block" style={{ marginBottom: '1rem' }}>
                                <p>
                                    No worries - yeah and I get why you haven’t, FB makes it out as being really easy but the reality is most businesses lose money running ads… not make it.
                                </p>

                                <p>
                                    If you want to see the general process behind running them for a company like @ExampleCompany, you can watch our case study, I’ll leave the link below.
                                </p>

                                <a href="www.agency.com/case-study" target="_blank" rel="noopener noreferrer">www.agency.com/case-study</a>

                                <p>
                                    Would be great to discuss if these would work for you too! If you’ve got 15 mins this week, you can book a call on my calendar using this link:
                                </p>

                                <p>
                                    Agency.com/15-min-call
                                </p>

                                <p>Iman</p>
                            </div>

                            <p className="bold">If they HAVE included their own message request</p>

                            <p>
                                Simply continue on in the same vein as their message. Why did they want to connect? Had you posted a piece of content, or are you in the same group?
                            </p>
                        </div>
                        <div ref={refs[3]} className="plug-block">
                            <div className="plug-block-subheading">
                                Content Engagement
                            </div>

                            <p>
                                As you share more and more content - in particular articles - you will notice engagement from outside of your network.
                            </p>

                            <p>
                                You can track this on posts:
                            </p>

                            <img src={Image9} alt="" style={{ marginBottom: '1rem' }} />

                            <p>
                                It’s worth opening up to 10 tabs at a time to view and inspect each profile. Do a basic check to see if you have messaged them previously.
                            </p>

                            <p>
                                Just as with incoming message requests, profiles will usually fall into two categories:
                            </p>

                            <ul>
                                <li>
                                    Potential leads
                                </li>
                                <li>
                                    Ancillary connections
                                </li>
                            </ul>

                            <p>
                                Or, alternatively, profiles that are not worth engaging with (competing agency owners, service providers in the same industry).
                            </p>

                            <p>In both instances, and if you have not messaged the individual previously, you can use this as an opportunity to build a connection with them, using a connection request referring to the content they have engaged with.</p>
                        </div>

                        <div ref={refs[4]} className="plug-block">
                            <div className="plug-block-subheading">
                                Profile Views
                            </div>

                            <p>
                                If you have a premium LinkedIn account, you will be able to see who has viewed your profile within the last 90 days.
                            </p>

                            <p>
                                LinkedIn offers prompts to show you how people found your profile including:
                            </p>

                            <ul>
                                <li>
                                    Via HomePage
                                </li>
                                <li>
                                    Via MyNetwork
                                </li>
                                <li>
                                    Via LinkedIn Company Page
                                </li>
                                <li>
                                    Via LinkedIn Profile
                                </li>
                                <li>
                                    Via LinkedIn Search
                                </li>
                            </ul>

                            <p>
                                As your LinkedIn Profile becomes associated with your agency and your name when leads search for you on Google, expect to be found via leads you have reached out to elsewhere.
                            </p>

                            <p>
                                If you are diligent in accepting/ rejecting requests, you will find that many of those who have viewed your profile have already sent you a connection request.
                            </p>

                            <p>
                                In the event that someone has viewed your profile, has not sent a request, you have not interacted with them elsewhere (via email for example) you can either send a connection request with a personalised message, or an InMail.
                            </p>

                            <p>
                                For example:
                            </p>

                            <div className="examples-block">
                                <p>
                                    Hey Colin,
                                </p>

                                <p>
                                    Hope you don’t mind me sending a message: I saw that you viewed my profile yesterday.
                                </p>

                                <p>
                                    I’m pretty active on LinkedIn when it comes to vegan companies - you might have seen my latest post about an easy way to increase conversions using testimonials.
                                </p>

                                <p>
                                    I’ve written quite an in-depth article about this which you may find useful! I’ll link to it below.
                                </p>

                                <p>LINK</p>

                                <p>Lovely to connect with you - hope we get the chance to have a virtual coffee soon. </p>

                                <p>
                                    Regards,
                                </p>

                                <p>Iman</p>
                            </div>

                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
