import React from 'react';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const ScrollTabs = ({
    value, handleChange, labels, ...props
}) => (
    <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
        {...props}
    >
        { labels.map((label, i) => <Tab label={label} key={i} />) }
    </Tabs>
);

export default ScrollTabs;
