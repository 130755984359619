import React from 'react';

export default function AccessModal({
    title = 'You don’t have access to this program!',
    subtitle1 = 'This is an application-only program for Agency Owners doing $10,000+ a month who want to scale to $50k, $70k, $100k+ and use their agencies as a launch pad to get into 8-figures business models.',
    subtitle2 = 'Interested in joining? Schedule a call below with our APEX Student Integration Manager, Max, to apply for a spot.',
    link = 'https://link.growyouragency.com/apex-apply',
    setShowAccessModal,
}) {
    const apply = () => {
        window.open(
            link,
            '_blank',
        );
        return null;
    };
    return (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none whitespace-normal text-white"
            >
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    {/* content */}
                    <div className="border-0 rounded-xl shadow-lg relative flex flex-col w-full bg-black outline-none focus:outline-none">
                        {/* header */}
                        <div className="flex items-start justify-between p-10 rounded-t">
                            <h3 className="text-3xl font-semibold py-8">
                                {title}
                            </h3>
                            <button
                                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => setShowAccessModal(false)}
                                type="button"
                            >
                                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                    ×
                                </span>
                            </button>
                        </div>
                        {/* body */}
                        <div className="relative p-10 flex-auto space-y-4">
                            <p>
                                {subtitle1}
                            </p>
                            <p>
                                {subtitle2}
                            </p>
                            <p>
                                <button onClick={apply} style={{ width: '155px', padding: '0px' }} className="buttonLgLight h-fit mt-7 sm:flex" type="button">Apply Now</button>
                            </p>
                        </div>
                        {/* footer */}
                        <div className="flex items-center justify-end p-10 rounded-b">
                            <button
                                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => setShowAccessModal(false)}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black" />
        </>
    );
}
