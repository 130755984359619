import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { withStyles } from '@material-ui/core';
import AddOutlined from '@material-ui/icons/AddOutlined';
import { Input, Spinner } from 'reactstrap';
import styles from '../../../../../FinancialPlanner/components/MonthView/components/FinanceColumn/styles';
import CheckBox from './checkBox';

const ToDoItem = withStyles(styles)(({
    classes, onClickSave, toDo,
}) => {
    const isNew = !toDo?.PK && !toDo?.SK;
    const [inputRef, setInputRef] = useState(null);
    const [itemSaveLoading, setItemSaveLoading] = useState(false);
    const [editedTitle, setEditedTitle] = useState(toDo?.title);

    useEffect(() => {
        if (inputRef) {
            inputRef.focus();
        } else {
            setItemSaveLoading(false);
        }
    }, [inputRef]);

    const createToDo = () => {
        if (editedTitle) {
            setItemSaveLoading(true);
            onClickSave(editedTitle);
        } else {
            alert('You must enter a task title');
        }
    };


    const toggleComplete = () => {
        if (editedTitle) {
            onClickSave(editedTitle, !toDo?.complete);
        } else {
            alert('You must enter a task title');
        }
    };

    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            createToDo();
        }
    };


    return (
        <div
            className={classes.financeItemContainer}
            style={
                isNew ? {
                    backgroundColor: 'rgb(28, 28, 28)',
                    borderColor: (editedTitle?.length > 0) ? 'rgba(24,130,222,0.44)' : '#e2e2e2',
                    borderWidth: (editedTitle?.length > 0) > 0 ? 2 : 0,
                    borderStyle: 'solid',
                    borderRadius: '0.25rem',
                    marginBottom: '1rem',
                    padding: '0.5rem',
                } : {
                    marginBottom: '1rem',
                }
            }
        >


            {
                itemSaveLoading && (
                    <Spinner
                        color="primary"
                        type="grow"
                        style={{ marginRight: 10 }}
                    />
                )
            }

            {
                !itemSaveLoading && !isNew && (
                    <CheckBox
                        checked={toDo?.complete}
                        onChange={toggleComplete}
                    />
                )
            }

            {
                !itemSaveLoading && isNew && (
                    <AddOutlined
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            padding: '0 .5rem',
                            opacity: 0.5,
                            fontSize: '1.8rem',
                        }}
                        onClick={createToDo}
                    />
                )
            }

            <Input
                innerRef={ref => setInputRef(ref)}
                className={classes.financeItemInput}
                placeholder="New To-Do..."
                type="text"
                value={editedTitle}
                onChange={e => setEditedTitle(e.target.value)}
                onKeyDown={onKeyDown}
            />

        </div>
    );
});

const Index = ({
    tasks, taskFilter, toggleTask, selectEvent, year, createToDo, monthDate,
}) => {
    const filteredTasks = tasks
        .filter((task) => {
            if (taskFilter === 'All') {
                return true;
            }
            return taskFilter === 'Done' ? task.complete : !task.complete;
        })
        .filter(({ startDateTime }) => {
            const currentMonthDateUTC = moment(monthDate).startOf('month').utc();
            const taskStartDateTimeMonthUTC = moment(startDateTime).startOf('month').utc();

            return currentMonthDateUTC.isSame(taskStartDateTimeMonthUTC, 'day');
        });
    return (
        <div style={{
            padding: '0.5rem',
        }}
        >
            <ToDoItem
                key={`${monthDate} - ${tasks?.length}`}
                onClickSave={(title, complete = false) => {
                    const currentSelectedMonthUTC = moment(monthDate);
                    currentSelectedMonthUTC.set({ year });
                    currentSelectedMonthUTC.startOf('month').utc();

                    createToDo({
                        isNew: true,
                        startDateTime: currentSelectedMonthUTC.toISOString(),
                        endDateTime: currentSelectedMonthUTC.toISOString(),
                        entryType: 'goal',
                        title,
                        complete,
                        summaryJSON: JSON.stringify({
                            goalType: 'smallToDo',
                        }),
                    });
                }}
            />

            {
                filteredTasks.map(task => (
                    <ToDoItem
                        key={`${task?.PK}-${task?.SK}-${task?.title}-${task?.complete}`}
                        toDo={task}
                        onClickSave={(title, complete = null) => {
                            const currentSelectedMonthUTC = moment(monthDate);
                            currentSelectedMonthUTC.set({ year });
                            currentSelectedMonthUTC.startOf('month').utc();

                            createToDo({
                                ...task,
                                isNew: false,
                                title,
                                complete: complete === null ? task?.complete : complete,
                            });
                        }}
                    />
                ))
            }
            {/* { */}
            {/*    filteredTasks.map((task, i) => ( */}
            {/*        <ListItem key={i}> */}
            {/*            <ListItemIcon> */}
            {/*                <CheckBox checked={task?.complete} onChange={() => toggleTask(task)} /> */}
            {/*            </ListItemIcon> */}
            {/*            <ListItemText */}
            {/*                primary={truncate(task?.title)} */}
            {/*                secondary={moment(task?.startDateTime) */}
            {/*                    .format('dddd, MMMM Do YYYY')} */}
            {/*            /> */}
            {/*            <ListItemSecondaryAction> */}

            {/*                <IconButton edge="end" aria-label="comments" onClick={() => selectEvent(task)}> */}
            {/*                    <EditIcon /> */}
            {/*                </IconButton> */}
            {/*            </ListItemSecondaryAction> */}
            {/*        </ListItem> */}
            {/*    ))} */}
        </div>
    );
};

export default Index;
