import gql from 'graphql-tag';

export default gql`
    mutation ($entry: CalendarEntryInput!) {
        createUpdateCalendarEntry(entry: $entry) {
            calendar {
                PK
                SK
                type
                startDateTime
            }

            data {
                PK
                SK
                startDateTime
                endDateTime
                calendarName
                entryType
                type
                title
                allDay
                complete
                colour
                summaryJSON
            }
            error
        }
    }

`;
