import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        available: true, sectionName: 'Individual Personalisation',
    },
    {
        available: true, sectionName: 'ABC Personalisation',
    },

];

const title = "Email Personalisation"

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                // selectedSection={selectedSection}
                                // setSelectedSection={onSelectSection}
                                // filter={filter}
                                // selectFilter={selectFilter}
                                // subFilter={subFilter}
                                // selectSubFilter={selectSubFilter}

                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div className="plug-block">  
                        <h2 className="mb-4">{title}</h2> 


                            <p>
                                Email personalisation is essential to a successful email campaign. Failure to do so will result in a quick dismissal of your email, even if it is opened.
                            </p>

                            <p>
                                Personalisations are often referred to as ‘icebreakers’.
                            </p>

                            <p>
                                They should be kept short, in one-or-two sentences, and genuine.
                            </p>
                        </div>
                        <div ref={refs[0]} className="plug-block">
                            <div className="plug-block-subheading">
                                Individual Personalisation

                            </div>

                            <p>
                                Individual personalisation is the most effective but most time consuming form of email personalisation.
                            </p>

                            <p>
                                It involves going through each one of your leads and researching their business enough to provide a personalised compliment.
                            </p>

                            <p>
                                The most obvious choices of personalisation are a company’s corporate social responsibility initiatives e.g., sustainability, charity.
                            </p>

                            <p>
                                For example:
                            </p>

                            <div className="examples-block">
                                <p>
                                    <span className="bold">
                                        Lead:
                                    </span>
                                    {' '}
                                    BULLDOG Skincare
                                </p>

                                <p>
                                    “Hey %F.Name%,
                                </p>

                                <p>
                                    Massive congratulations for achieving a carbon neutral certification for your moisturisers! That’s such an awesome thing you guys are doing for the environment.
                                </p>

                                <p>
                                    <span className="bold">
                                        Lead:
                                    </span>
                                    {' '}
                                    Neon Moon
                                </p>

                                <p>
                                    “Hey %F.Name%,
                                </p>

                                <p>
                                    You guys are awesome. Seriously. Seeing representation of so many different body types in marketing is incredibly important - thank you for helping so many improve their body confidence.
                                </p>
                            </div>

                            <p>However, note that for some brands or companies, it may be difficult to find a CSR angle. So instead, you could try another tactic like admiration, humour or something specific to the niche.</p>

                            <p>
                                For example:
                            </p>

                            <div className="examples-block">
                                <p>
                                    <span className="bold">Lead:</span>
                                    {' '}
                                    Mayors Watches London
                                </p>

                                <p>
                                    “Hey %F.Name%,
                                </p>

                                <p>
                                    Full transparency: it’s a guilty pleasure of mine to check out your latest additions when I break for lunch.. I came close to selling a kidney when I saw the Speedmaster ‘TinTin’ came in a few days ago...
                                </p>

                                <p>
                                    <span className="bold">Lead:</span>
                                    {' '}
                                    Super Smiles Boston
                                </p>

                                <p>
                                    “Hey %F.Name%,
                                </p>

                                <p>What’s the worst time to get a dentist’s appointment? </p>

                                <p>
                                    2.30. Get it? Tooth...hurty… Sorry. I’ll move on:
                                </p>
                            </div>

                            <p>
                                A common mistake agency owners make in outreach is to lie or exaggerate to leads. This comes across as incredibly disingenuous. For example, stating that you own - or intend to purchase a product. Note that in all the examples - this hasn’t been hinted, and the admiration appears genuine.
                            </p>

                            <p>
                                <span className="bold">Note:</span>
                                {' '}
                                You might also wish to personalise your message with reference to where you found the lead. For example, if you heard them on a podcast, read about them in an article, or on Groupon - reference this.
                            </p>
                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">ABC Personalisation</div>

                            <p>
                                Whilst individual personalisation is incredibly time consuming, ABC Personalisation is much more efficient and can be easily outsourced.
                            </p>

                            <p>
                                ABC Personalisation involves creating three personalisations which can be applied to multiple companies within one niche.
                            </p>

                            <p>
                                For example, if you were emailing companies in the babycare niche you could employ the following personalisations:
                            </p>

                            <p className="bold">
                                Personalisation A:
                            </p>

                            <div className="examples-block">
                                <p>
                                    “Hey %F.Name%,
                                </p>

                                <p>
                                    It’s incredible to see a company that puts such a focus on natural and raw materials into baby care products - massive kudos for this.”
                                </p>
                            </div>

                            <p>
                                Personalisation B:
                            </p>

                            <div className="examples-block">
                                <p>
                                    “Hey %F.Name%,
                                </p>

                                <p>
                                    I don’t think I’ve ever seen a babycare website with so many positive reviews. Thank you for improving the lives of new moms everywhere.”
                                </p>
                            </div>

                            <p className="bold">
                                Personalisation C:
                            </p>

                            <div className="examples-block">
                                <p>
                                    “Hey %F.Name%,
                                </p>

                                <p>
                                    It’s not often I come across a product designed for babies that I would quite like myself but in the case of %COMPANY% I think I’ve found it…”
                                </p>
                            </div>

                            <p>
                                Once you have created your ABC personalisations, you can then go through your leads and add ‘A’, ‘B’, or ‘C’ next to each name.
                            </p>

                            <p>There will still be outliers but this remains an effective way of creating mass personalisation. </p>

                        </div>


                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
