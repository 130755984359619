import React, { useState, useEffect } from 'react';
import moment from 'moment';

const withEventDialog = EventDialog => ({
    event: calendarEvent,
    saveEvent,
    handleClose,
    ...props
}) => {
    const [event, setEvent] = useState(calendarEvent);
    useEffect(() => setEvent(calendarEvent), [calendarEvent]);

    const handleTitleChange = e => setEvent({ ...event, title: e.target.value });
    const handleStartChange = newDate => setEvent({ ...event, startDateTime: moment(newDate).toISOString() });
    const handleEndChange = newDate => setEvent({ ...event, endDateTime: moment(newDate).toISOString() });
    const handleStartEndChange = newDate => setEvent({
        ...event,
        startDateTime: moment(newDate).toISOString(),
        endDateTime: moment(newDate).toISOString(),
    });

    const handleAllDayChange = (change) => {
        const { checked: allDay } = change.target;
        let extra = {};
        if (allDay) {
            extra = {
                endDateTime: moment(event.startDateTime).endOf('day').toISOString(),
            };
        }
        setEvent({ ...event, ...extra, allDay });
    };

    const handleDone = () => {
        saveEvent(event);
        setEvent(undefined);
    };

    const handleEventColourUpdate = (colour) => {
        setEvent({ ...event, colour });
    };

    return (
        <EventDialog
            onTitleChange={handleTitleChange}
            handleStartChange={handleStartChange}
            handleEndChange={handleEndChange}
            handleStartEndChange={handleStartEndChange}
            handleAllDayChange={handleAllDayChange}
            handleDone={handleDone}
            handleClose={handleClose}
            handleEventColourUpdate={handleEventColourUpdate}
            event={event}
            {...props}
        />
    );
};

export default withEventDialog;
