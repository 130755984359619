import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'reactstrap';
import LockIcon from '@material-ui/icons/Lock';
import useAdmin from '../../../../../hooks/useAdmin';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/outline'

export default ({
    title = 'By Program', sections, setSelectedSection = () => {}, selectedSection, scrollTo = null, selectFilter, filter, subFilter, selectSubFilter,
}) => {
    const { isAdmin } = useAdmin();

    const [accordian, setAccordian] = useState(true);
    const [item, setItem] = useState(null);

    const handleClick = (section, index) => {
        if (scrollTo) {
            scrollTo(index);
        } else {
            selectFilter(null);
            setSelectedSection(section);
        }
    };

   console.log("acc", accordian)
   

    return (


        <div
            className="mr-4"
        >
            <div>
               
            </div>
            <ul style={{ listStyle: 'none', padding: '0', textAlign: 'left' }} className="bodyBg">

                {sections.map((section, index) => {
                    const availableOrAdmin = isAdmin || section.available;
                    return (
                        <>
                            <li>
                                <div
                                    onClick={() => {
                                        availableOrAdmin && handleClick(section, index)
                                        accordian ? setAccordian(false) : setAccordian(true)
                                        setItem(section?.sectionName)
                                        //console.log("selectedSection?.sectionName", selectedSection?.sectionName)
                                        //console.log("section?.sectionName", section?.sectionName)
                                    }}
                                    className="rounded-full grey500 body1"
                                    style={{
                                        padding: '1rem',
                                        display: 'inline-block',
                                        cursor: 'pointer',
                                        
                                        ...(
                                            section?.sectionName === item
                                                ? {
                                                   
                                                    color: 'white',
                                                }
                                                : {}
                                        ),
                                        ...(!availableOrAdmin

                                            ? { cursor: 'no-drop' }
                                            : {}),
                                    }}
                                >
                                    { !availableOrAdmin ? (
                                        <LockIcon style={{
                                            color: '#777', width: 15, marginLeft: 10,
                                        }}

                                        />
                                    ) : null }
                                    {' '}
                                    {section?.sectionName}
                                    {selectedSection?.sectionName === section?.sectionName
                                        ? <> {accordian ? <ChevronDownIcon className="h-4 w-4 ml-2 text-white" /> : <ChevronUpIcon className="h-4 w-4 ml-2 text-white" /> } </>
                                        : null
                                    }
                                </div>
                            </li>
                                {accordian &&
                                    <ul 
                                        className="grey500"
                                        style={{
                                            display: 'flex', flexDirection: 'column', listStyle: 'none', padding: '0', textAlign: 'center',
                                        }}
                                    >
                                        {selectedSection?.sectionName === section?.sectionName && section?.filters?.map((item) => {
                                            const itemAvailableOrAdmin = isAdmin || item.available;

                                            return (
                                                <>
                                                    <div
                                                        style={{ width: '100%' }}
                                                        onClick={() => itemAvailableOrAdmin && selectFilter(item.name)}
                                                        className="bodyBg text-left mt-4 pl-4 body1"
                                                    >
                                                        <li style={{
                                                            width: '100%',
                                                            padding: '0.5rem',
                                                            display: 'inline-block',
                                                            cursor: 'pointer',
                                                           
                                                            // borderBottom: '1px solid #F2F2F2',
                                                            ...(
                                                                filter === item.name
                                                                    ? {
                                                                        color: 'white',
                                                                        // textDecorationLine: 'underline',
                                                                        
                                                                        
                                                                    }
                                                                    : {}
                                                            ),
                                                            ...(
                                                                !itemAvailableOrAdmin
                                                                    ? {
                                                                        
                                                                        color: '#777',
                                                                        cursor: 'no-drop',
                                                                    }
                                                                    : {}
                                                            ),
                                                        }}
                                                        >
                                                            {item.name}
                                                        </li>


                                                    </div>

                                                    {filter === item.name && item?.subFilters?.length > 0
                                                        ? (
                                                            <div 
                                                                className="bodyBg"
                                                                style={{ 
                                                                padding: '0 0 1rem 0',  }}>
                                                                {item?.subFilters?.map(subFilterItem => (
                                                                    <div
                                                                        onClick={() => selectSubFilter(subFilterItem)}
                                                                        key={subFilterItem}
                                                                        style={{
                                                                            fontSize: '0.75rem',
                                                                            marginBottom: '3px',
                                                                            display: 'block',
                                                                            cursor: 'pointer',
                                                                            textAlign: 'center',
                                                                            fontWeight: subFilter === subFilterItem && '700',
                                                                        }}
                                                                    >
                                                                        {subFilterItem}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )
                                                        : null }
                                                </>
                                            );
                                        })}
                                    </ul>
                                }
                        </>

                    );
                })}
            </ul>
        </div>
    );
};
