import React, { useState } from 'react';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { withStyles } from '@material-ui/core/styles';

import { useMutation, useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import { parseInt, uniqWith } from 'lodash';
import Grid from '@material-ui/core/Grid';
import styles from './styles';
import GetCalendarEntriesQuery from '../../../../../../api/getCalendarEntries';
import CreateUpdateCalendarEntryMutation from '../../../../../../api/createUpdateCalendarEntry';
import ToDoList from './components/ToDoList';

const ToDo = ({
    classes, tasks, toggleTask, selectEvent, monthDate, year,
}) => {
    const {
        data: calendarResponseData,
        error: calendarEntriesError,
        loading: calendarEntriesLoading,
    } = useQuery(GetCalendarEntriesQuery, {
        variables: {
            entryType: 'goal',
        },
    });

    const calendar = calendarResponseData?.getCalendarEntries?.calendar || {};
    const calendarSummaries = (calendarResponseData?.getCalendarEntries?.data || []).map(({ startDateTime, endDateTime, ...rest }) => ({
        ...rest,
        startDateTime: new Date(startDateTime),
        endDateTime: new Date(endDateTime),
    }));

    const smallToDos = calendarSummaries.filter(({ summaryJSON }) => JSON.parse(summaryJSON || '{}')?.goalType === 'smallToDo');

    const [createUpdateCalendarEntry] = useMutation(CreateUpdateCalendarEntryMutation, {
        update(cache, { data: { createUpdateCalendarEntry: { data: createUpdateResponseData } } }) {
            const readResults = cache.readQuery({
                query: GetCalendarEntriesQuery,
                variables: {},
            });

            const updatedEntry = {
                getCalendarEntries: {
                    ...readResults.getCalendarEntries,
                    data: uniqWith([
                        createUpdateResponseData,
                        ...readResults.getCalendarEntries.data,
                    ], ({ PK, SK }, { PK: PK2, SK: SK2 }) => PK === PK2 && SK === SK2),
                },
            };

            cache.writeQuery({
                query: GetCalendarEntriesQuery,
                data: {
                    ...updatedEntry,
                },
            });
        },
        awaitRefetchQueries: true,
        refetchQueries: [{
            query: GetCalendarEntriesQuery,
            variables: {
                entryType: 'goal',
            },
        }],
    });

    const [taskFilter, setTaskFilter] = useState('All');

    const createToDo = (toDo, onComplete = () => {}) => {
        const { __typename, isNew, ...noteEvent } = toDo;

        const extraQueryParams = {
            optimisticResponse: {
                createUpdateCalendarEntry: {
                    calendar,
                    data: { __typename, ...toDo },
                    error: null,
                    __typename: 'CalendarEntryResponse',
                },
            },
        };


        const variables = {
            entry: isNew ? {
                startDateTime: noteEvent.startDateTime,
                endDateTime: noteEvent.endDateTime,
                entryType: noteEvent.entryType,
                title: noteEvent.title,
                colour: noteEvent?.colour,
                allDay: noteEvent?.allDay || false,
                complete: noteEvent?.complete || false,
                summaryJSON: noteEvent?.summaryJSON,
            } : noteEvent,
        };

        const query = {
            variables,
            ...(isNew ? { } : extraQueryParams),
        };


        console.log('query', query);
        createUpdateCalendarEntry(query).then(onComplete);
    };

    return (
        <Paper className={classes.paper}>
            <div style={{
                padding: '0.5rem',
                textAlign: 'center',
                color: 'white',
                fontWeight: 'bold',
                backgroundColor: 'rgb(28, 28, 28)',
                borderTopLeftRadius: '0.25rem',
                borderTopRightRadius: '0.25rem',
            }}
            >
                <Typography className={classes.todoTitle} variant="h4">
                    Small To-Do
                </Typography>
                <ButtonGroup classes={{ root: classes.buttonGroup }} variant="text" color="default" size="small">
                    <Button selected classes={{ root: taskFilter === 'All' ? classes.buttonSelected : classes.button }} onClick={() => setTaskFilter('All')}>
                        All
                    </Button>
                    <Button classes={{ root: taskFilter === 'Done' ? classes.buttonSelected : classes.button }} onClick={() => setTaskFilter('Done')}>
                        Done
                    </Button>
                    <Button classes={{ root: taskFilter === 'Pending' ? classes.buttonSelected : classes.button }} onClick={() => setTaskFilter('Pending')}>
                        Pending
                    </Button>
                </ButtonGroup>
            </div>
            <div
                className="info-text"
                style={{
                    padding: '1rem 0.5rem 0.5rem 0.5rem',
                }}
            >
                Track a small to-do by providing a task title and then tapping the
                {' '}
                <i>Enter</i>
                {' '}
                key.
            </div>
            <ToDoList
                year={year}
                monthDate={monthDate}
                tasks={smallToDos}
                createToDo={createToDo}
                taskFilter={taskFilter}
                toggleTask={toggleTask}
                selectEvent={selectEvent}
            />
        </Paper>
    );
};

export default withStyles(styles)(ToDo);
