import { Auth } from 'aws-amplify';
import { setContext } from 'apollo-link-context';
import { Sha256 } from '@aws-crypto/sha256-universal';
import { SignatureV4 } from '@aws-sdk/signature-v4';
import { print } from 'graphql/language/printer';
import AppSyncConfig from '../init/appsync-configure';
import * as Url from 'url';

const AWS4_SERVICE = 'appsync';

/**
 * Cleans up any temporary variables to avoid polluting the AWS Signature V4 signinig process
 * @param {Object} - The variables object from the ApolloLink request
 * @returns {Object} Returns the cleaned variables object
 */
const removeTempVars = (variables) =>
    Object.keys(variables)
        .filter((k) => !k.startsWith("@@"))
        .reduce((acc, key) => {
            acc[key] = variables[key];
            return acc;
        }, {});

/**
 * Generates a concise request object that can be used by AWS Signature V4 signing
 * @param {Object} - The operationName, variables and query from an ApolloLink request object
 * @param {Object} - Key-value pairs of additional headers to be included on the request
 * @returns {Object} Retuns a request object for use with AWS Signature V4 signing
 */
const formatAsRequest = ({ operationName, variables, query }, headers) => {
    const body = {
        operationName,
        variables: removeTempVars(variables),
        query: print(query)
    };

    return {
        body: JSON.stringify(body),
        method: 'POST',
        headers: {
            accept: '*/*',
            'content-type': 'application/json; charset=UTF-8',
            ...headers
        },
    };
};

/**
 * Gets the request headers from a signed AWS Signature V4
 * @param {GraphQLRequest} - The Apollo graphql request passed by ApolloLink
 * @param {Number} clockDrift - The amount of clock drift in minutes, this can be found on the Amplify session.
 * @returns {Object} Returns the signed AWS V4 Headers that can be set directly on the Apollo context
 */
const getAws4SignedHeaders = async (request, clockDrift = 0) => {
    const { region, url } = AppSyncConfig
    const { accessKeyId, sessionToken, secretAccessKey } = await Auth.currentCredentials();
    const signatureV4 = new SignatureV4({
        credentials: { accessKeyId, sessionToken, secretAccessKey },
        region,
        service: AWS4_SERVICE,
        sha256: Sha256
    });
    const { host, path } = Url.parse(url);
    const reqHeaders = {
        Host: host
    };
    let signingDate = new Date();

    // if the client time is different to the server time then adjust
    //  asigning date to preventclock drift errors from AWS
    //  note: re-login is required if system clock is adjusted
    if (clockDrift && clockDrift != 0) {
        signingDate = new Date(new Date().getTime() - (clockDrift * 1000));
    }
    const formatted = {
        ...formatAsRequest(request, reqHeaders),
        service: AWS4_SERVICE, region, url, host, path
    };
    const { headers } = await signatureV4.sign(formatted, { signingDate });
    return headers;
};

/**
 * Create an ApolloLink that signes the request with AWS Signature V4 headers and a cognito token
 * @returns {ApolloLink} Returns an ApolloLink that can be added directly to the Link pipeline
 */
export const createAppSyncLink = () => setContext(async (request, previousContext) => {
    const sessionCredentials = await Auth.currentSession();
    const headers = await getAws4SignedHeaders(request, sessionCredentials?.clockDrift);
    if (sessionCredentials) {
        const cognitoToken = sessionCredentials.getIdToken().getJwtToken();
        return {
            headers: {
                ...previousContext.headers,
                ...headers,
                cognitoToken,
            },
        };
    }
    return {
        headers: {
            ...previousContext.headers,
            ...headers,
        },
    };
});
