import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';
import { Link } from 'react-router-dom';

const sections = [
    {
        available: true,
        sectionName: 'Intro (and importance)',
    },
    {
        available: true,
        sectionName: 'Ads disapprovals',
    },
    {
        available: true,
        sectionName: 'Types of bans and why you get them',
    },
    {
        available: true,
        sectionName: 'How to appeal bans',
    },
    {
        available: true,
        sectionName: 'Pro tips and things to avoid',
    },
    {
        available: true,
        sectionName: 'What to do if you\'ve already gotten banned and there\'s nothing you can do',
    },
];

const title = 'Facebook Hygiene & Avoiding Bans';

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]} className="plug-block">  
                        <h2 className="mb-4">{title}</h2>
                            <div className="plug-block-subheading">Intro (and importance)</div>

                            <img src="https://img.genflow.com/gya/plugandplays/fbHygiene1.png" alt="" />

                            <p>
                                A quick search through the previous Facebook group for Agency Incubator will reveal hundreds of posts about banned ad accounts. It's an astonishingly-common problem that plagues nearly every agency owner, regardless of niche.
                            </p>

                            <p>
                                If you run Facebook ads, there is a high degree of probability that you too will face a ban.
                            </p>

                            <p>But this should not cause you immense concern: first, it is possible to avoid bands following certain 'hygiene' practices. Second, there are steps you can take to undo or mitigate the resulting problems from a ban.</p>

                            <p>
                                It's also worth noting that while there are myriad of myths concerning Facebook ad accounts, personal advertising access, and business manager bans, including the need to change your IP address or computer, or use complicated proxy servers: the process of avoiding bans or undoing them is simple.
                            </p>

                            <p>
                                We've spoken directly to representatives at Facebook who have confirmed this.
                            </p>

                            <p>
                                The most important thing to remember is this:
                            </p>

                            <p>
                                Whatever caused your ban in the first place will cause it again. Don't repeat your mistake.
                            </p>
                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">Ads disapprovals</div>

                            <p>
                                Ad approvals are extremely common. Some niches lend themselves to disapprovals more than others: for example, infoproducts, real estate, employment, or certain niches within ecommerce.
                            </p>

                            <p>
                                Some businesses persist with multiple business managers, Facebook profiles and more - but this is inadvisable.
                            </p>

                            <p>
                                Nine times out of ten, ads being rejected are due to fairly obvious, common sense reasons.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/fbHygiene2.png" alt="" />

                            <p>
                                Make sure to familiarise yourself with
                                <a href="https://www.facebook.com/policies/ads" target="_blank" rel="noopener noreferrer">Facebook's ad policies</a>
                                . The most common reasons for being banned are covered below and are taken directly from these, and
                                <a href="https://www.facebook.com/communitystandards" target="_blank" rel="noopener noreferrer"> Facebook's community standards</a>
                                .
                            </p>

                            <div className="examples-block">
                                <p>
                                    N.B. Facebook doesn't just review your creative and copy, they also scan through your landing page: so a compliant landing page is as important as the advert itself.
                                </p>
                            </div>

                            <img src="https://img.genflow.com/gya/plugandplays/fbHygiene3.png" alt="" />

                            <ul>
                                <li>
                                    Illegal Products or Services


                                </li>
                                <li>
                                    Discriminatory Practices


                                </li>
                                <li>
                                    Tobacco and Related Products


                                </li>
                                <li>
                                    Drugs & Drug-Related Products


                                </li>
                                <li>
                                    Unsafe Supplements


                                </li>
                                <li>
                                    Weapons, Ammunition, or Explosives (There's things allowed here, if you're dealing with a client that sells accessories for weapons for example this can be sold)


                                </li>
                                <li>
                                    Adult Products or Services


                                </li>
                                <li>
                                    Adult Content


                                </li>
                                <li>
                                    Sensational Content or violent content


                                </li>
                                <li>
                                    Personal Attributes **(**See below for Facebook's examples. The key is to avoid using 'you' to suggest attribution of characteristics)


                                </li>
                                <li>
                                    Misinformation - Ads debunked by 'fact checkers'


                                </li>
                                <li>
                                    Non-Functional Landing Page - This is a common issue. This can be triggered by issues with your website, updated URLs, or re-directs. Do not try and post one URL, and change the content of the page after approval: Facebook periodically reviews landing pages.


                                </li>
                                <li>
                                    Cheating and Deceitful Practices - Services or products that promote cheating or interfering with systems. Also fake documents.


                                </li>
                                <li>
                                    Grammar & Profanity - Don't use excessive symbols, ALL CAPS, bad grammar or profanities.


                                </li>
                                <li>
                                    Nonexistent Functionality - Don't add any buttons to your creative, for example fake play buttons. This also applies to screenshots of apps or websites. For example, in the below, the '+' would lead to a disapproval.


                                </li>
                            </ul>

                            <img src="https://img.genflow.com/gya/plugandplays/fbHygiene4.png" style={{ width: '50%' }} alt="" />

                            <ul>
                                <li>
                                    Personal Health - Before and afters are a big NO.
                                </li>
                                <li>
                                    Payday Loans, Paycheck Advances, and Bail Bonds - Loans have to be greater than 90 days
                                </li>
                                <li>
                                    Multilevel Marketing - Most of infoproducts will be flagged within this category, even if they are not MLM. Avoid making claims, do not mention money or results. Instead, speak in the first person and tell a story.
                                </li>
                                <li>
                                    Penny Auctions
                                </li>
                                <li>Misleading Claims - Do not make exaggerated claims about results, or things your product or service can do.</li>
                                <li>
                                    Low Quality or Disruptive Content - Make sure you have enough content on your landing page and that it is easy to interact with. No weird pop ups or difficult interactions.
                                </li>
                                <li>
                                    Spyware or Malware
                                </li>
                                <li>
                                    Automatic Animation - This is important, make sure your page doesn't play videos or animations right away, all of this NEEDS to be prompted by the user. Same with sound, no sound should be automatically playing.
                                </li>
                                <li>
                                    Unacceptable Business Practices - No scams, self-explanatory
                                </li>
                                <li>
                                    Circumventing Systems - Trying to trick the Facebook algorithm by for example changing the text of your landing page with images so the system can't read it. You can actually get away with this but probably not for long: have sustainable business practices, don't try stuff that in the future is likely not going to work. Play by Facebook's rules.
                                </li>

                                <li>
                                    Prohibited Financial Products and Services - ICOs, binary options, contract for difference trading
                                </li>
                                <li>
                                    Vaccine Discouragement
                                </li>
                            </ul>

                            <p>
                                Make sure you fall within these guidelines otherwise you risk getting your ads rejected and depending on how new your account is or how bad Facebook thinks what you did is you can get your account and/or business manager banned.
                            </p>
                        </div>

                        <div ref={refs[2]} className="plug-block">
                            <div className="plug-block-subheading">Types of bans and why you get them</div>

                            <p>
                                Aside from rejected or disapproved ads, you can get four further bans:
                            </p>

                            <p className="bold">
                                Your ad account
                            </p>

                            <p>
                                You won't be able to advertise or interact with that ad account. These bans happen for the same reasons you get ads rejected. For ad account bans it really depends on how much ad spend you have spent on the account. In other words, the more you spend on the ad account, the less likely it is to be banned. It also depends on how 'bad' the violation was and if you've been warned before.
                            </p>

                            <p>Facebook considers the below the most serious violations:</p>

                            <div className="examples-block">
                                <p>
                                    Unacceptable Business Practices: Scams, money related services or products, overpromise, profanity
                                </p>

                                <p>
                                    Misleading Claims: False promises such as curing diseases that can't be cured (?)
                                </p>

                                <p>
                                    Non-Functional Landing Page: Same thing we went over earlier.
                                </p>

                                <p>
                                    Adult Content
                                </p>

                                <p>
                                    Repeated violations
                                </p>
                            </div>

                            <p className="bold">
                                Your business manager
                            </p>

                            <p>
                                You won't be able to interact with your business manager, which means none of the assets inside will be accessible. You will still be able to disconnect pages from it.
                            </p>

                            <p className="bold">
                                Your personal Facebook advertising access
                            </p>

                            <p>
                                This is the worst kind of ban and means that your whole profile was banned. It isn't clear why this bans happen because a lot of the time the business manager you created with your account and even your ad accounts will still be running and fine.
                            </p>

                            <p className="bold">
                                Your Facebook pages
                            </p>

                            <p>
                                This happens when you're not compliant with the same policies discussed earlier. You can always create a new one and start over using the same ad account and business manager. But do not make the same mistakes as previously.
                            </p>
                        </div>

                        <div ref={refs[3]} className="plug-block">
                            <div className="plug-block-subheading">How to appeal bans</div>

                            <p>
                                All the types of bans can be appealed
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/fbHygiene5.png" alt="" style={{ width: '50%' }} />

                            <p>
                                Appeals are a standard part of Facebook advertising - and necessary to gain back an ad account, business manager, page or personal access.
                            </p>

                            <div className="examples-block">
                                <p>
                                    Occasionally, Facebook will introduce a new algorithm to detect malpractice in advertising. When this happens, there is often a wave of bans - which you will hear about from your fellow agency owners. This usually takes a few days to resolve.
                                </p>
                            </div>

                            <p>
                                When you get banned you'll get a message like this and below the option to appeal.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/fbHygiene6.png" alt="" />

                            <p>
                                There are two options to choose from to appeal: apology, or innocence. Here are two templates for this:
                            </p>

                            <p className="bold">
                                Apologetic:
                            </p>

                            <div className="examples-block">
                                <p>
                                    I am sorry that I didn’t comply with Facebook’s advertising policies, I didn’t know what I was doing wrong until my access got restricted, it was a mistake that I take responsibility for. I have been advertising on the platform different products for 2 years without any problems but the last product had problems I didn’t know about at the time and I will never advertise that product again.
                                </p>
                                <p>
                                    I just carefully went over Facebook Policies and know that I made a mistake that I won’t make again.
                                </p>
                                <p>I still advertise physical products of my company that never had any problems and I would like to still promote them.</p>
                                <p>
                                    None of my ads are disapproved!
                                </p>
                                <p>
                                    Thank you!
                                </p>
                            </div>

                            <img src="https://img.genflow.com/gya/plugandplays/fbHygiene7.png" alt="" />

                            <p className="bold">
                                Defending your innocence:
                            </p>

                            <div className="examples-block">
                                <p>
                                    Thank you for this notification but I believe my Business Account has been disabled by mistake as none of my ads violate Facebook policy.The products I am advertising are “XXX products” and in any way my ads contain images or videos that promote adult content, shocking images or claims. Also, my ads do not focus on a person's weight loss issues and do not contain copyright material.
                                </p>

                                <p>
                                    It’s for the reasons mentioned above that I am kindly requesting my business account to be reviewed and that you allow me the privilege to continue advertising in your extraordinary platform.
                                </p>

                                <p>
                                    Thank you so much for your attention
                                </p>
                            </div>

                            <p>
                                Always be nice and let them know you know about the advertising policies.
                            </p>

                            <p>
                                This appeals work for personal access, business manager and ad accounts.
                            </p>

                            <p>
                                Appeals can take up to a week to be revised. Personal access restrictions can take months.
                            </p>
                        </div>

                        <div ref={refs[4]} className="plug-block">
                            <div className="plug-block-subheading">Pro tips and things to avoid</div>

                            <p>If you appeal an ad, and it is manually approved, it will most likely still be rejected if you use it in a seperate post or ad set.</p>

                            <p>
                                In this instance, it's better to tweak your advert based on an educated guess on what led to its disapproval.
                            </p>

                            <ul>
                                <li>
                                    Check the disapproval reason and change that: Facebook will tell you if it is the URL, the copy or the creative when they reject your ads, change whatever they're saying is wrong, go over the policies again and change whatever seems noncompliant.
                                </li>
                                <li>
                                    Never call out people with you or 'how to'
                                </li>
                                <li>
                                    Make sure your landing page is working, is high quality (enough text, looks good) and has no redirects.
                                </li>
                                <li>
                                    Have more than one person on your business manager, if you get your personal access restricted, the other person or profile will be able to still manage that business manager, otherwise no one will be able and the business manager will be lost if you don't get your access back
                                </li>
                                <li>
                                    Have back up profiles from family members so if you get banned you can start running on those. Have ad accounts that spend $1 a day in there so the accounts are warm and don't get banned.
                                </li>
                                <li>
                                    Don't use VPNs unless you know they're safe
                                </li>
                                <li>
                                    Do everything from Firefox
                                </li>
                                <li>
                                    Always use the same computer to login and ideally try not to change wifi's often.
                                </li>
                            </ul>
                        </div>

                        <div ref={refs[5]} className="plug-block">
                            <div className="plug-block-subheading">What to do if you've already gotten banned and there's nothing you can do</div>

                            <Link to="/resources/plug-and-play/the-ultimate-facebook-contingency-guide" >
                                The Ultimate Facebook Contingency Guide
                            </Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
