import React, { useMemo, useState } from 'react';
import Modal from 'react-responsive-modal';
import OnboardingProgress from '../../../components/onboarding/OnboardingProgress';
import OnboardingUserInfo from './OnboardingUserInfo';
import OnboardingAgencyInfo from './OnboardingAgencyInfo';
import OnboardingGoalInfo from './OnboardingGoalInfo';

export default function OnboardingQuestions({ open }) {
    const [activePage, setActivePage] = useState(0);

    const onboardingPages = [
        {
            id: 0,
            component: OnboardingUserInfo,
            completed: 10,
        },
        {
            id: 1,
            component: OnboardingAgencyInfo,
            completed: 50,
        },
        {
            id: 2,
            component: OnboardingGoalInfo,
            completed: 100,
        },
    ];

    const Page = useMemo(() => activePage < onboardingPages.length && activePage >= 0 && onboardingPages?.[activePage].component, [activePage]);
    const completed = useMemo(() => activePage < onboardingPages.length && activePage >= 0 && onboardingPages?.[activePage].completed, [activePage]);

    const next = (id) => {
        if (activePage === onboardingPages.length - 1) {
            return;
        }
        if (((id || activePage + 1) >= 0) && ((id || activePage + 1) < onboardingPages.length)) {
            if (typeof id === 'number') {
                setActivePage(id);
                return;
            }
            setActivePage(activePage + 1);
        }
    };


    return (
        <Modal
            open={open}
            center
            className="grey800Bg"
        >
            <div
                className="fixed top-0 left-0 inset-0 bg-black h-full w-full z-50 flex justify-center overflow-x-hidden overflow-y-auto"
                id="my-modal"
            >
                <div className="w-11/12 md:w-3/12">
                    <div
                        className="flex flex-col items-center self-center"
                    >
                        <div className="mx-auto py-8">
                            <img
                                alt="genflow"
                                src="../Logo.svg"
                            />
                        </div>
                        <h1 className="text-white text-2xl mb-3">Welcome to GYA!</h1>
                        <p className="text-white text-base text-center mb-4">{'We will ask you some questions before the start to \n better understand you.'}</p>

                        <OnboardingProgress completed={completed} />

                        <div className="w-full mt-[20px]">
                            {activePage < onboardingPages.length && activePage >= 0 && (
                                <Page
                                    key={onboardingPages?.[activePage]?.id}
                                    next={next}
                                />
                            )}
                        </div>
                    </div>

                </div>
            </div>
        </Modal>
    );
}
