import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import * as dates from 'react-big-calendar/lib/utils/dates';
import { navigate } from 'react-big-calendar/lib/utils/constants';
import AddOutlined from '@material-ui/icons/AddOutlined';
import CloseOutlined from '@material-ui/icons/CloseOutlined';
import { Input, Spinner } from 'reactstrap';
import Grid from '@material-ui/core/Grid';
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import './styles.scss';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { uniqWith, sortBy } from 'lodash';
import moment from 'moment';
import ScheduleOutlined from '@material-ui/icons/ScheduleOutlined';
import GetCalendarEntriesQuery from '../../../../../../api/getCalendarEntries';
import CreateUpdateCalendarEntryMutation from '../../../../../../api/createUpdateCalendarEntry';
import CheckBox from '../MonthObjectives/components/ObjectivesList/checkBox';
import DeleteCalendarEntryMutation from '../../../../../../api/deleteCalendarEntry';
import MonthObjectives from '../MonthObjectives';

const DayTimeListItem = ({
    id, name, date = new Date(), completed = false, onClickSave,
}) => {
    const isNew = !id;
    const [inputRef, setInputRef] = useState(null);
    const [itemSaveLoading, setItemSaveLoading] = useState(false);
    const [editedTitle, setEditedTitle] = useState(name);
    const [selectedDate, setSelectedDate] = useState(date);
    const [complete, setComplete] = useState(completed);
    const [showDeleteItem, setShowDeleteItem] = useState(false);

    useEffect(() => {
        if (inputRef) {
            inputRef.focus();
        } else {
            setItemSaveLoading(false);
        }
    }, [inputRef]);

    const createOrSaveDayObjective = () => {
        console.log('editedTitle', editedTitle);
        if (editedTitle) {
            setItemSaveLoading(true);
            onClickSave(selectedDate, editedTitle, complete);
        } else {
            alert('You must enter an objective title...');
        }
    };

    const deleteItem = () => {
        // eslint-disable-next-line no-restricted-globals
        const yes = confirm(`Are you sure you want to delete objective "${name}"?`);
        if (yes) {
            setItemSaveLoading(true);
            onClickSave(selectedDate, editedTitle, complete, true);
        }
    };

    const toggleComplete = () => {
        if (editedTitle) {
            const newComplete = !complete;
            setComplete(newComplete);
            onClickSave(selectedDate, editedTitle, newComplete);
        } else {
            alert('You must enter a task title');
        }
    };

    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            createOrSaveDayObjective();
        }
    };

    const onInputFocus = () => !isNew && setShowDeleteItem(true);
    const onInputBlur = () => !isNew && setTimeout(() => setShowDeleteItem(false), 250);


    const onDateChange = (newDate) => {
        if (!isNew && editedTitle) {
            setSelectedDate(newDate);
            onClickSave(newDate, editedTitle, complete);
        }
    };
    return (
        <div
            className="flex-col sm:flex-row"
            style={
                isNew ? {
                    // backgroundColor: '#e2e2e2',
                    // borderStyle: 'solid',
                    // borderRadius: '0.25rem',
                    marginBottom: '.25rem',
                    padding: editedTitle?.length > 0 ? '0 0.5rem 1rem 0' : '0.5rem',
                    marginLeft: editedTitle?.length > 0 ? '0.5rem' : 0,
                    display: 'flex',
                    alignItems: 'center',
                    color:'white',
                } : {
                    borderRadius: '0.25rem',
                    marginBottom: '.25rem',
                    padding: '0.5rem',
                    display: 'flex',
                    alignItems: 'center',
                    color:'white',
                }
            }
        >
        <div className="flex w-full sm:w-auto">
            <div style={{
                width: '3rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            >
                {
                    !itemSaveLoading && !isNew && (
                        <CheckBox
                            checked={complete}
                            onChange={toggleComplete}
                        />
                    )
                }

                {
                    !itemSaveLoading && isNew && (
                        <AddOutlined
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                                // paddingLeft: '.5rem',
                                opacity: 0.5,
                                fontSize: '1.8rem',
                                width: '42px',
                            }}
                            onClick={createOrSaveDayObjective}
                        />
                    )
                }


                {
                    itemSaveLoading && (
                        <Spinner
                            color="primary"
                            type="grow"
                        />
                    )
                }

            </div>

            <KeyboardTimePicker
                keyboardIcon={<ScheduleOutlined />}
                margin="normal"
                className="time-picker-input inputBox whiteTime timeWidth"
                value={selectedDate}
                onChange={onDateChange}
                KeyboardButtonProps={{
                    'aria-label': 'change time',
                }}
                ampm={false}
                style={{
                    display: 'block',
                    // width: '7rem',
                    // height: 'calc(1.5em + 1.25rem + 2px)',
                    padding: '0.3rem 0.75rem',
                    fontSize: '0.875rem',
                    fontWeight: 400,
                    lineHeight: 1.5,
                    color: 'white',
                    
                    backgroundClip: 'padding-box',
                    borderColor: 'transparent',
                    borderBottom: '1px solid white',
                    //borderRadius: '0.25rem',
                    boxShadow: 'none',
                    transition: 'all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55)',
                    margin: 0,
                }}
            />
            </div>

            {/* <Input */}
            {/*   className={classes.financeItemInput} */}
            {/*   placeholder="Value" */}
            {/*   type="number" */}
            {/*   value={value} */}
            {/*   onChange={e => updateState({ name, value: e.target.value })} */}
            {/* /> */}


            <Input
                placeholder="New day objective..."
                decimalsLimit={2}
                onChange={e => setEditedTitle(e.target.value)}
                style={{ 
                    // marginLeft: '0.5rem', 
                    flex: 1, 
                    padding: '0.55rem 0.75rem',
                    borderColor: 'transparent',
                    borderBottom: '1px solid white',
                    color: 'white',
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                    lineHeight: 1.5,
                }}
                value={editedTitle}
                onKeyDown={onKeyDown}
                onFocus={onInputFocus}
                onBlur={onInputBlur}
                className="objBx"
            />


            {
                showDeleteItem && !itemSaveLoading && !isNew && (
                    <CloseOutlined
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            paddingLeft: '.5rem',
                            opacity: 0.5,
                            fontSize: '1.8rem',
                        }}
                        onClick={deleteItem}
                    />
                )
            }
        </div>
    );
};


const DayTimeList = (props) => {
    const {
        data: calendarResponseData,
        error: calendarEntriesError,
        loading: calendarEntriesLoading,
    } = useQuery(GetCalendarEntriesQuery, {
        variables: {
            entryType: 'goal',
        },
    });

    const TODAY = new Date();
    const calendar = calendarResponseData?.getCalendarEntries?.calendar || {};
    const calendarSummaries = (calendarResponseData?.getCalendarEntries?.data || []).map(({ startDateTime, endDateTime, ...rest }) => ({
        ...rest,
        startDateTime: new Date(startDateTime),
        endDateTime: new Date(endDateTime),
    }));

    const dayObjectives = calendarSummaries.filter(({ summaryJSON }) => JSON.parse(summaryJSON || '{}')?.goalType === 'dayObjective');
    const monthlyObjectives = calendarSummaries.filter(({ summaryJSON }) => JSON.parse(summaryJSON || '{}')?.goalType === 'monthlyObjective');

    console.log('dayObjectives', dayObjectives);

    const [createUpdateCalendarEntry] = useMutation(CreateUpdateCalendarEntryMutation, {
        update(cache, { data: { createUpdateCalendarEntry: { data: createUpdateResponseData } } }) {
            const readResults = cache.readQuery({
                query: GetCalendarEntriesQuery,
                variables: {},
            });

            const updatedEntry = {
                getCalendarEntries: {
                    ...readResults.getCalendarEntries,
                    data: uniqWith([
                        createUpdateResponseData,
                        ...readResults.getCalendarEntries.data,
                    ], ({ PK, SK }, { PK: PK2, SK: SK2 }) => PK === PK2 && SK === SK2),
                },
            };

            cache.writeQuery({
                query: GetCalendarEntriesQuery,
                data: {
                    ...updatedEntry,
                },
            });
        },
        awaitRefetchQueries: true,
        refetchQueries: [{
            query: GetCalendarEntriesQuery,
            variables: {
                entryType: 'goal',
            },
        }],
    });

    const [deleteCalendarEntry] = useMutation(DeleteCalendarEntryMutation, {
        refetchQueries: [{
            query: GetCalendarEntriesQuery,
        }],
    });

    const deleteGoal = SK => deleteCalendarEntry({
        variables: { entry: { SK } },
    });


    const createOrUpdateDayObjective = async (dayObjective, onComplete = () => {}) => {
        const {
            __typename, isNew, PK, SK, deleteItem = false, ...objective
        } = dayObjective;

        const variables = {
            entry: {
                startDateTime: objective.startDateTime,
                endDateTime: objective.endDateTime,
                entryType: 'goal',
                title: objective.title,
                complete: objective?.complete || false,
                summaryJSON: objective?.summaryJSON,
            },
        };

        const query = {
            variables,
        };


        console.log('query', query);
        console.log('SK', SK);
        console.log('deleteItem', deleteItem);
        if (SK || deleteItem) {
            await deleteGoal(SK);
            if (deleteItem) {
                onComplete();
            }
        }

        if (!deleteItem) {
            await createUpdateCalendarEntry(query);
            onComplete();
        }
    };
    return (
        <div>
            <Grid
                // className={classes.gridContainer}
                container
                justify="center"
                style={{
                    minHeight: '50vh',
                }}
            >
                <Grid
                    item
                    // className={classes.calendarGridItem}
                    md={6}
                    style={{
                        paddingRight: '1rem',
                    }}
                >
                    <div
                        className="info-text"
                        style={{
                            margin: '1rem 0 1rem 3.5rem'
                        }}
                    >
                        Plan your new day objective by selecting a time, providing an objective title and then tapping the
                        {' '}
                        <i>Enter</i>
                        {' '}
                        key.
                    </div>
                    <DayTimeListItem
                        date={TODAY}
                        key={dayObjectives?.length}
                        onClickSave={(date, title, complete = false) => {
                            const currentSelectedDateTimeUTC = moment(date);
                            currentSelectedDateTimeUTC.utc();

                            const data = {
                                isNew: true,
                                startDateTime: currentSelectedDateTimeUTC.toISOString(),
                                endDateTime: currentSelectedDateTimeUTC.toISOString(),
                                entryType: 'goal',
                                title,
                                complete,
                                summaryJSON: JSON.stringify({
                                    goalType: 'dayObjective',
                                }),
                            };

                            createOrUpdateDayObjective(data);
                        }}
                    />

                    {
                        sortBy(dayObjectives, 'startDateTime').map(objective => (
                            <DayTimeListItem
                                id={`${objective?.SK}`}
                                key={`${dayObjectives?.length}-${objective?.SK}-${objective?.title}-${objective?.startDateTime.toISOString()}-${objective?.complete}}`}
                                name={objective?.title}
                                date={objective?.startDateTime}
                                completed={objective?.complete}
                                onClickSave={(date, title, complete = false, deleteItem = false) => {
                                    const currentSelectedDateTimeUTC = moment(date);
                                    currentSelectedDateTimeUTC.utc();

                                    const data = {
                                        PK: objective?.PK,
                                        SK: objective?.SK,
                                        isNew: false,
                                        startDateTime: currentSelectedDateTimeUTC.toISOString(),
                                        endDateTime: currentSelectedDateTimeUTC.toISOString(),
                                        title,
                                        complete,
                                        summaryJSON: JSON.stringify({
                                            goalType: 'dayObjective',
                                        }),
                                        deleteItem,
                                    };

                                    console.log('datadata', data);
                                    createOrUpdateDayObjective(data);
                                }}
                            />
                        ))
                    }
                </Grid>
                <Grid
                    item
                    md={3}
                >
                    <MonthObjectives
                        staticView
                        tasks={monthlyObjectives}
                        // toggleTask={toggleTask}
                        // selectEvent={selectEvent}
                        monthDate={TODAY}
                        // year={year}
                    />
                </Grid>
            </Grid>

        </div>
    );
};

class DayView extends React.Component {
    render() {
        const { date, ...props } = this.props;
        const range = DayView.range(date);

        return (
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <DayTimeList {...props} />
            </MuiPickersUtilsProvider>
        );
    }
}

DayView.propTypes = {
    date: PropTypes.instanceOf(Date).isRequired,
};

DayView.range = date => [dates.startOf(date, 'day')];

DayView.navigate = (date, action) => {
    switch (action) {
    case navigate.PREVIOUS:
        return dates.add(date, -1, 'day');

    case navigate.NEXT:
        return dates.add(date, 1, 'day');

    default:
        return date;
    }
};

DayView.title = (date, { localizer }) => localizer.format(date, 'dayHeaderFormat');

export default DayView;
