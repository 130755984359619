import React, { useContext, useEffect } from 'react';
import { Col } from 'reactstrap';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import useAccess from '../../../hooks/useAccess';
import { UserDataContext } from '../../../providers/UserDataProvider';
import HostsSection from './HostsSection';
import QaCard from './QaCard';
import './style.scss';
import CustomLoader from '../../../components/CustomLoader';
import { OnboardingContext } from '../../../providers/OnboardingProvider';
import useUpcomingQA from '../../../hooks/useUpcomingQA';

const cards = [
    {
        id: '#agency-navigator',
        img: 'https://assets.website-files.com/611ad280fe9eeb9b5e38c0d6/611b12c94ed1f6e6b18a50bb_92350346_909268106161571_9185725062062879613_n.jpg',
        titleImg: 'https://assets.website-files.com/611ad280fe9eeb9b5e38c0d6/611b0438ee6074360ad1b827_Light_FullLogo-p-500.png',
        description: 'Bi-weekly 60-minute Q&A calls with Ciaran, Luis & Pierre going over funnels, ads, sales and agency operations.',
        restriction: null,
    },
    {
        id: '#copy-paste-agency',
        img: 'https://assets.website-files.com/611ad280fe9eeb9b5e38c0d6/611b12712a00d42b00341e91_pic2.jpg',
        titleImg: 'https://assets.website-files.com/611ad280fe9eeb9b5e38c0d6/611ae38857bcfb85cf6f2d1f_Full_White%20(3)-p-500.png',
        description: 'Weekly Q&A calls with Iman & Dany with no time limit going over scaling, hiring, systems, advanced ad strategies.',
        restriction: 'By Application Only',
    },
    {
        img: 'https://assets.website-files.com/611ad280fe9eeb9b5e38c0d6/611ae719e87f2d1d9018ccbc_pic1.jpg',
        titleImg: 'https://assets.website-files.com/611ad280fe9eeb9b5e38c0d6/611b0583108b74adb735b032_mm3.png',
        description: '3-day in-person mastermind with Iman & the whole team going over how to structure private equity deals and take your agency to 8 figures.',
        restriction: 'By Invitation Only',
        disabled: true,
    },
];


const UpcomingQAPage = () => {
    // onboarding if unopened before initial close
    const { userProfile } = useContext(UserDataContext);
    const { activePage, setActivePage, setActiveInfoType } = useContext(OnboardingContext);
    useEffect(() => {
        if (!userProfile?.data?.tourCompleted?.qa && userProfile?.data?.onboardingCompleted) {
            if (activePage < 1) {
                setActiveInfoType('q&a');
                setActivePage(0);
            }
        }
    }, [userProfile]);

    let apexAccess = false;
    const { loading: accessLoading, validAccess } = useAccess(4630);
    if (!accessLoading) {
        apexAccess = validAccess;
    }

    const { loading, hosts } = useUpcomingQA(apexAccess);

    return (
        <CustomLoader loading={loading}>

            <div className="profile-page w-full lg:w-10/12 mx-auto text-white pb-8" id="q&a-onboarding-target">
                <section style={{ position: 'relative' }} className="section-profile-cover section-shaped my-0">

                    <Col style={{ margin: '5rem auto 4rem' }} lg={8} className="text-center">
                        <h1 className="mb-8">
                            Q&A Calls Calendar
                            <InfoOutlinedIcon className="white500 ml-2 cursor-pointer" onClick={() => setActiveInfoType('q&a')} />
                        </h1>
                        <p className="w-3/4 md:w-1/2 mx-auto">
                            The community Q&A calls are hosted by different professionals who work at GrowYourAgency, AgenciFlow, and partner companies so that you can get your questions answered by an specialist in each of the areas.
                        </p>
                    </Col>

                    {hosts.map((props, index) => (<HostsSection key={index} {...props} apexAccess={apexAccess} />))}


                    <h1 className="text-center m-auto">
                        Join The Upcoming Q&As
                    </h1>
                    <p className="w-3/4 md:w-1/2 mx-auto my-8 text-center">
                        We have multiple weekly Q&A Calls with different hosts to help you excel in each different area of your agency. Pick the brains of our team at GrowYourAgency and AgenciFlow and get your questions answered live.
                    </p>
                    <button className="my-8 mx-auto blkButton hosts-button" style={{ marginTop: '0 auto' }}>Reserve Your Spot</button>
                </section>

            </div>


        </CustomLoader>
    );
};

export default UpcomingQAPage;
