import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        available: true,
        sectionName: 'Campaigns, ad sets and ads',
    },
    {
        available: true,
        sectionName: 'What can be done on each level?',
    },
    {
        available: true,
        sectionName: 'Anatomy of a Facebook ad account',
    },
];

const title = 'Facebook Ads Fundamentals';

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                // selectedSection={selectedSection}
                                // setSelectedSection={onSelectSection}
                                // filter={filter}
                                // selectFilter={selectFilter}
                                // subFilter={subFilter}
                                // selectSubFilter={selectSubFilter}

                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]} className="plug-block">  
                        <h2 className="mb-4">{title}</h2> 

                            <p>
                                In this Plug & Play, we’ll go through the fundamentals of Facebook Advertising and explain each component of the Ad Manager inside your Business Manager.
                            </p>


                            <div className="plug-block-subheading">
                                Campaigns, ad sets and ads
                            </div>

                            <p>
                                A campaign has ad sets, an ad set has ads.
                            </p>

                            <p>
                                A campaign is the overarching structure of the ads manager. A campaign is the highest level of abstraction. You can think about it as the structure that organizes things with the same objective. For example you will have a campaign that organizes all your cold traffic (people that are not familiar with the company yet) ad sets. You will have remarketing campaigns where you remind people about your clients' products or services. Each campaign will also have an objective. For example, lead clicks, conversions, or engagement.
                            </p>

                            <p>An ad set is the structure that organizes the ads. This houses multiple ads, and will determine who sees your ad, and where. </p>

                            <p>
                                An ad is precisely what the customer will see: the copy, the image, the video, the carousel or collection. This is the front-facing side of your marketing strategy.
                            </p>
                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">
                                What can be done on each level?
                            </div>

                            <p className="bold">
                                Campaign
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/adsFundamentals1.png" alt="" />

                            <p>
                                On the campaign level you choose the buying type which can be 'Auction', 'Reach' or 'Frequency' and your objective. Generally, you should always use 'Auction' for the buying type as your objective will most certainly be getting tangible results to your client (for example conversions) and not the number of views or clicks you achieved.
                            </p>

                            <p>
                                Whilst there are multiple objectives, you should only ever look to use the following:
                            </p>

                            <p className="bold">
                                1. Engagement:
                            </p>

                            <p>
                                This is the least useful of the useful objectives. Facebook will prioritise displaying your advert to individuals most likely to engage with it: for example, liking, commenting or sharing on it, viewing a video, or clicking 'view more'.
                            </p>

                            <p>
                                This is extremely unlikely to result in any sales or landing page views. However, it does have two potential utilities: establishing social proof on your adverts, and creating initial engagement at the top of the funnel to retarget.
                            </p>

                            <p>
                                Social proof, in the form of existing engagement, lends credibility to your advert and adverts with higher engagement outperform those without it.
                            </p>

                            <p className="bold">1. Messages</p>

                            <p>
                                N.B. This feature is not available in many countries, especially in Europe because of their privacy policies.
                            </p>

                            <p>
                                Messaging is the ideal objective if your client operates a funnel or system which utilises the Facebook Messenger, or has team members who can promptly respond to messages.
                            </p>

                            <p>Respondents to the advert are prompted to send a specific message to your page, and there is an option to automate specific responses to this.</p>

                            <p>
                                If you do utilise this objective, it's really important to make sure messages are responded to quickly.
                            </p>

                            <p className="bold">
                                1. Conversions:
                            </p>

                            <p>
                                Conversions are the most useful objective of all. Facebook will serve your advert to users most likely to complete an action - or conversion - according to their algorithms.
                            </p>

                            <p>
                                In the world of e-commerce, this is most often purchases. But this can also include adding to cart, and other steps along the funnel.
                            </p>

                            <p>
                                This objective relies heavily on the data that you can feed to Facebook from your pixel or conversion API.
                            </p>

                            <p className="bold">Ad set</p>

                            <p className="bold">
                                On the ad set level you can do the following:
                            </p>

                            <p>
                                N.B. The first two are only available and relevant when you use conversion as your objective.
                            </p>

                            <ul>
                                <li>
                                    Select your pixel: We'll talk about tracking and pixels later on, but this refers to the piece of code you insert into a website to track conversions. This is where you choose the specific pixel you wish to track (you may have multiple).


                                </li>
                                <li>Conversion event: This is the event you wish Facebook to optimise for: for example purchases, or adding to cart.</li>
                            </ul>

                            <img src="https://img.genflow.com/gya/plugandplays/adsFundamentals2.png" alt="" />

                            <p>
                                Dynamic creative: This option allows you to give Facebook many creatives, headlines and pieces of copy and let them automatically combine and test these to see which performs best. This is an interesting concept but in practice, it's recommended that you avoid using these. It prevents adverts accumulating engagement (which is beneficial) and does not inform you which angle works best, so it's difficult to replicate this across other ad sets.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/adsFundamentals3.png" alt="" />

                            <ul>
                                <li>
                                    Offers: These should always be set 'off'. It's an untried, unreliable marketing strategy which allows Facebook to grant and remind users of offers.


                                </li>
                                <li>
                                    Budget and Schedule: Budget will be covered in-depth in
                                    <a href="https://docs.google.com/document/d/1_dBOuMWIPkkkjlBAphnfy7vrFvC6PEfRWD77jaEh2Dc/edit#heading=h.dyldogkwrr5z" target="_blank" rel="noopener noreferrer">Load Balancing Budgets'</a>
                                    {' '}
                                    '. Whilst budgets can be set at a campaign level, it's best practice to set this at the ad set level. This will be explained in full in the
                                    <a href="https://www.notion.so/Utilising-CBOs-9ba678783f384566bd6665642e8c2693" target="_blank" rel="noopener noreferrer">'Utilising CBOs'</a>
                                    {' '}
                                    module. We recommend that you do not use end dates - even if your campaign has a definitive for other reasons. This is based on the hypothesis that Facebook prefers campaigns without end dates, where users are likely to spend more, and may reward these campaigns favourably.


                                </li>
                                <li>
                                    Audience: Audiences should always be managed on the ad set level, allowing campaigns to reach different people based on the ones chosen in your individual ad sets.


                                </li>
                                <li>
                                    Placements: There is one hard-and-fast rule for placements: in cold audiences, you should only choose placements that display your body copy (primary text). For example feed posts, or story posts, rather than the audience network or in the right-hand column. However, with retargeting audiences, you should leave automatic placements as this sometimes helps decrease CPMs.
                                </li>
                            </ul>

                            <img src="https://img.genflow.com/gya/plugandplays/adsFundamentals4.png" alt="" />

                            <ul>
                                <li>
                                    Optimisation for ad delivery: If you click at the bottom on ‘show more options’ you'll find that you can change the optimisation for ad delivery. This should be left as 'conversion', or in the case of multiple products, value whereby Facebook will find users most likely to spend the most. However, this is an advanced feature.
                                </li>
                            </ul>


                            <img src="https://img.genflow.com/gya/plugandplays/adsFundamentals5.png" alt="" />

                            <ul>
                                <li>
                                    Attribution: You can also change the attribution setting: i.e., the window in which Facebook attributes a purchase to your advert. You should always select the longest time frame.
                                </li>
                            </ul>

                            <p className="bold">Ad</p>

                            <p>
                                On the ad level you can choose the FB page and IG page you want to run your ads from.
                            </p>

                            <p>
                                Then you can create your ad or use an old one you've created already.
                            </p>

                            <p>
                                To use an existing advert, you can either input the post ID (which can be found in the URL of the ad that you created), or alternately (and more easily) but duplicating the ad, which will also share engagement across adverts.
                            </p>

                            <p>
                                If you've chosen to create the ad it will ask you to choose the format. You can have single images, videos, carousels or collections.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/adsFundamentals6.png" alt="" />

                            <p>
                                Images and videos - are the bread and butter of Facebook advertising but there are instances where you may prefer to use a collection, and even more rarely, a carousel.
                            </p>

                            <p>
                                Next, you'll be given the chance to insert your creative (image or video), your primary text, headline, and URL (this is the URL of your landing page).
                            </p>

                            <p>
                                You can also select a call to action: learn more and shop now are the most popular and successful, without much difference between them.
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/adsFundamentals7.png" alt="" style={{ width: '50%' }} />

                            <p>
                                Finally, there's tracking. It's really important to ensure your pixel is connected and listed under 'Website events'.
                            </p>
                        </div>

                        <div className="plug-block" ref={refs[2]}>
                            <div className="plug-block-subheading">
                                Anatomy of a Facebook ad account
                            </div>

                            <ul>
                                <li>
                                    The 'ad' is the smallest unit in the Facebook ad account.
                                </li>
                                <li>
                                    For most clients, you should write a minimum of five different 'ads'
                                </li>
                                <li>
                                    An ad set groups those 5 ads, each ad set has an audience
                                </li>
                                <li>
                                    With each client, you should look to test anywhere from three to twenty different audiences at a time, depending on the budget and strategy
                                </li>
                                <li>
                                    So with five ad sets, each ad set will have the same adverts
                                </li>
                                <li>So in total, there will be 25 ad sets, with creatives, copy, and engagement duplicated across ad sets</li>
                                <li>
                                    All of these ad sets and ads are grouped together in a campaign
                                </li>
                            </ul>
                        </div>

                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
