import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';

import { Col, Container, Row } from 'reactstrap';
import { sortBy } from 'lodash';
import ScrollTabs from '../../../../components/scrollTabs';
import 'react-big-calendar/lib/sass/styles.scss';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss';


import withPlanOfAttack from './withPlanOfAttack';
import styles from './styles';
import './styles.css';
import { TaskProvider } from './providers/TaskProvider';
import MonthView from './components/MonthView';
import YearView from './components/YearView';
import EventList from './components/EventList';
import EventDialog from './components/EventDialog';
import CustomLoader from '../../../../components/CustomLoader';
import DayView from './components/DayView';

const localizer = momentLocalizer(moment);
localizer.formats.yearHeaderFormat = 'YYYY';

const DragAndDropCalendar = withDragAndDrop(Calendar);

const CustomMonthEvent = ({ event }) => (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {
            event.complete && (
                <span
                    className="alert-inner--icon"
                    style={{ marginRight: 5, display: 'flex' }}
                >
                    <i className="ni ni-check-bold" />
                </span>
            )
        }
        <span style={{ fontSize: 12, flex: 1 }}>{event.title}</span>
        {/* {!event?.allDay && ( */}
        {/*    <strong style={{ fontSize: 11, opacity: 0.7 }}> */}
        {/*        { */}
        {/*            `${moment(event.startDateTime).format('HH:mm')}` */}
        {/*        } */}
        {/*    </strong> */}
        {/* )} */}
    </div>
);

const CustomDayEvent = ({ event }) => (
    <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    }}
    >
        {
            event.complete && (
                <span
                    className="alert-inner--icon"
                    style={{ marginRight: 5, display: 'flex' }}
                >
                    <i className="ni ni-check-bold" />
                </span>
            )
        }
        <span style={{ fontSize: 14, flex: 1, marginBottom: 5 }}>{event.title}</span>
        {!event?.allDay && (
            <strong style={{ fontSize: 14, opacity: 0.7 }}>
                {
                    `${moment(event.startDateTime).format('HH:mm')} - ${moment(event.endDateTime).format('HH:mm')}`
                }
            </strong>
        )}
    </div>
);

const PlanOfAttack = ({
    classes,
    labels,
    years,
    isTodayYear,
    selected,
    anchorEl,
    onMenuClose,
    selectYear,
    year,
    handleTabSelect,
    selectedEvent,
    saveEvent,
    deleteEvent,
    clearEvent,
    calendarProps,
    tasks,
    toggleTask,
    selectEvent,
    fabOpen,
    setFabOpen,
    createEvent,
    loading,
    view,
}) => (
    <TaskProvider config={{
        classes,
        tasks,
        toggleTask,
        year,
        selectEvent,
    }}
    >
        <main className="profile-page text-white w-10/12 mx-auto">
            <section className="section-profile-cover section-shaped my-0">
                <Container className="mx-auto">
                    <Row>
                        <Col lg={12} className="text-left p-8 pb-0 sm: pb-8">
                            <h1 className="hidden sm:block">Plan of Attack.</h1>
                            <h3 style={{fontSize: '26px'}} className="block sm:hidden">Plan of Attack.</h3>
                        </Col>
                    </Row>
                </Container>
            </section>
            <CustomLoader loading={loading}>
                <ScrollTabs
                    labels={labels}
                    value={selected}
                    className=" px-4"
                    handleChange={handleTabSelect}
                    style={{
                        marginTop: '2rem',
                    }}
                />
                <Menu
                    className={classes.yearMenu}
                    open={!!anchorEl}
                    anchorEl={anchorEl}
                    onClose={onMenuClose}
                >
                    {years.map(yearOption => (
                        <MenuItem
                            key={yearOption}
                            selected={Number(yearOption) === Number(year)}
                            onClick={() => selectYear(yearOption)}
                            dense
                            style={{ maxHeight: 50 }}
                        >
                            { yearOption }
                        </MenuItem>
                    ))}
                </Menu>
                <div className={classes.calendarContainer}>
                    <DragAndDropCalendar
                        localizer={localizer}
                        startAccessor="startDateTime"
                        endAccessor="endDateTime"
                        views={{
                            day: isTodayYear ? DayView : false,
                            week: true,
                            month: MonthView,
                            year: YearView,
                        }}
                        onView={v => console.log(v)}
                        onNavigate={v => console.log(v)}
                        toolbar={false}
                        messages={{ year: 'Year' }}
                        selectable
                        resizable={false}
                        onDragStart={(dragStart) => {
                            console.log('dragStart', dragStart);
                        }}
                        components={{
                            day: {
                                event: CustomDayEvent,
                            },
                            month: {
                                event: CustomMonthEvent,
                            },
                        }}
                        {...calendarProps}
                        eventPropGetter={
                            (event) => {
                                const newStyle = {
                                    border: 'none',
                                    background: event?.colour,
                                };

                                return {
                                    className: 'task',
                                    style: newStyle,
                                };
                            }
                        }
                    />

                </div>
                {selected === 1
                && (
                    <EventList
                        events={sortBy(calendarProps.events
                            .filter(({ entryType }) => entryType === 'event'), 'startDateTime')}
                        year={year}
                        selectEvent={selectEvent}
                    />
                )
                }

                <EventDialog
                    event={selectedEvent}
                    handleClose={clearEvent}
                    saveEvent={saveEvent}
                    deleteEvent={deleteEvent}
                    view={view}
                />
                {/* <SpeedDial */}
                {/*    ariaLabel="SpeedDial example" */}
                {/*    className={classes.speedDial} */}
                {/*    icon={<SpeedDialIcon />} */}
                {/*    onClose={() => setFabOpen(false)} */}
                {/*    onOpen={() => setFabOpen(true)} */}
                {/*    open={fabOpen} */}
                {/* > */}
                {/*    <SpeedDialAction */}
                {/*        icon={<EventIcon />} */}
                {/*        tooltipTitle="New Event" */}
                {/*        onClick={() => createEvent('event')} */}
                {/*    /> */}
                {/*    <SpeedDialAction */}
                {/*        icon={<AssignmentTurnedInIcon />} */}
                {/*        tooltipTitle="New Task" */}
                {/*        onClick={() => createEvent('task')} */}
                {/*    /> */}
                {/* </SpeedDial> */}
            </CustomLoader>
        </main>
    </TaskProvider>
);

export default withPlanOfAttack(withStyles(styles)(PlanOfAttack));
