import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-responsive-modal';
import {
    Button, Card, Col, Form, FormGroup, Input, Label,
} from 'reactstrap';
import './style.scss';
import CurrencyInput from 'react-currency-input-field';
import { useMutation } from '@apollo/react-hooks';
import { useConfig } from '@genflow/core';
import CreateUpdateClientCustomerPaymentMutation from '../../../../../../api/createUpdateClientCustomerPayment';
import GetClientCustomerPaymentQuery from '../../../../../../api/getClientCustomerPayments';
import GetClientCustomersQuery from '../../../../../../api/getClientCustomers';
import LoadingButton from '../../../../../../components/LoadingButton';
import GetVendorLeaderboardQuery from '../../../../../../api/getVendorLeaderboard';
import getAdminClientCustomerPayments from "../../../../../../api/getAdminClientCustomerPayments";


const InputSection = ({ index, instruction, children }) => (
    <div style={{ marginBottom: '2rem' }}>
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: '1rem',
        }}
        >
            {!!index && (
                <div style={{
                    background: 'black',
                    width: '2.5rem',
                    height: '2.5rem',
                    borderRadius: '2rem',
                    color: 'white',
                    marginRight: '1rem',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontWeight: 'bold',
                }}
                >
                    {index}
                </div>
            )}
            <div style={{ flex: 1 }}>
                {instruction}
            </div>
        </div>
        {children}
    </div>
);

const NewPaymentModal = ({
    selectedClient, open, onClose, firstPayment = false, onFirstPaymentCreated,
}) => {
    const { vendorUuid } = useConfig();
    const [createUpdateClientCustomerPayment, { loading }] = useMutation(CreateUpdateClientCustomerPaymentMutation, {
        refetchQueries: [{
            query: GetClientCustomerPaymentQuery,
            variables: {
                vendorUuid,
                clientCustomerUuid: selectedClient?.id,
            },
        },
        {
            query: GetClientCustomersQuery,
            variables: {
                vendorUuid,
            },
        },
        {
            query: GetVendorLeaderboardQuery,
            variables: {
                vendorUuid,
            },
        },
        {
            query: getAdminClientCustomerPayments,
            variables: {
                vendorUuid,
                status: 'AWAITING_APPROVAL',
            },
        },
        ],
    });

    const [userPaymentData, setUserPaymentData] = useState({ totalCurrency: 'USD' });
    const updateUserPaymentData = changes => setUserPaymentData({
        ...userPaymentData,
        ...changes,
    });

    console.log(userPaymentData);

    const createPaymentDisabled = firstPayment
        ? (
            !userPaymentData?.loomURL
            || !userPaymentData?.totalValue
            || !userPaymentData?.totalCurrency
            || !userPaymentData?.gyaPaymentType
            || !userPaymentData?.securingMeetingStrategy
            || !userPaymentData?.salesProcessStrategy
            || !userPaymentData?.receivedObjections
            || !userPaymentData?.communityAdvice
            || !userPaymentData?.isAuthorised
        )
        : (
            !userPaymentData?.loomURL
            || !userPaymentData?.totalValue
            || !userPaymentData?.totalCurrency
            || !userPaymentData?.gyaPaymentType
        );

    console.log('userPaymentData', userPaymentData);
    const onCreatePayment = async () => {
        if (createPaymentDisabled) {
            return alert('You must enter all fields before creating a payment!');
        }


        const {
            totalValue, totalCurrency, ...metadata
        } = userPaymentData;
        const response = await createUpdateClientCustomerPayment({
            variables: {
                vendorUuid,
                clientCustomerUuid: selectedClient?.id,
                clientCustomerPayment: {
                    paymentType: 'DEBIT',
                    paymentSchedule: metadata?.gyaPaymentType.toLowerCase().indexOf('rolling') > -1 ? 'RECURRING' : 'ONE_TIME',
                    totalValue,
                    totalCurrency,
                    metadata: JSON.stringify({
                        ...metadata,
                        gyaPaymentStatus: 'AWAITING_APPROVAL',
                    }),
                },
            },
        });

        onClose();
        if (firstPayment) onFirstPaymentCreated();
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            center
            className="text-white"
        >
            <div 
            className="payModal"
            style={{
                // padding: '1rem'
            }}
            >
                <h2 
                    className="mb-8 hidden sm:block"
                >
                    New Payment
                </h2>
                <h3 
                    className="mb-8 block sm:hidden"
                >
                    New Payment
                </h3>

                <InputSection
                    index={1}
                    instruction="Please film a loom with proof of Stripe, PayPal or Bank payment from client. Also refresh the page. (This will NOT be shared with anyone except for GYA, to authentic the deal & add this to your tally for the yearly leaderboard prizes.)"
                >
                    <Input
                        placeholder="Loom URL..."
                        type="url"
                        className="w-full bg-transparent p-4 inputBox"
                        style={{borderBottomColor: "#434343"}}
                        onChange={e => updateUserPaymentData({
                            loomURL: e.target.value,
                        })}
                    />
                </InputSection>

                <InputSection
                    index={2}
                    instruction="How much is this deal (In $USD)?"
                >
                    <div 
                        className=" flex flex-col sm:flex-row items-start sm:items-center"
                    
                    >
                        <CurrencyInput
                            className="form-control bg-transparent p-4 inputBox payBx"
                            prefix="$"
                            placeholder="$1,000"
                            decimalsLimit={2}
                            style={{
                               
                                marginRight: '1rem',
                                borderBottomColor: "#434343"
                            }}
                            onValueChange={totalValue => updateUserPaymentData({ totalValue })}
                            // value={editedValue}
                            // onKeyDown={onKeyDown}
                            // onFocus={onInputFocus}
                            // onBlur={onInputBlur}
                        />
                        <Form
                            className="flex flex-row"
                        >
                            <FormGroup check inline className="w-1/3 p-2">
                                <Label check className="flex flex-col sm:flex-row items-center text-center">
                                    <Input
                                        type="radio"
                                        name="gyaPaymentType"
                                        className="mx-4 mb-2 sm:mb-0"
                                        onChange={(e) => {
                                            updateUserPaymentData({
                                                gyaPaymentType: 'ONE_TIME_FEE',
                                            });
                                        }}
                                    />
                                    {' '}
                                    One-time Fee
                                </Label>
                            </FormGroup>
                            <FormGroup check inline className="w-1/3 p-2">
                                <Label check className="flex flex-col sm:flex-row items-center text-center">
                                    <Input
                                        type="radio"
                                        name="gyaPaymentType"
                                        className="mx-4 mb-2 sm:mb-0"
                                        onChange={(e) => {
                                            updateUserPaymentData({
                                                gyaPaymentType: 'PERFORMANCE_FEE',
                                            });
                                        }}
                                    />
                                    {' '}
                                    Performance Fee
                                </Label>
                            </FormGroup>
                            <FormGroup check inline className="w-1/3 p-2">
                                <Label check className="flex flex-col sm:flex-row items-center text-center">
                                    <Input
                                        type="radio"
                                        name="gyaPaymentType"
                                        className="mx-4 mb-2 sm:mb-0"
                                        onChange={(e) => {
                                            updateUserPaymentData({
                                                gyaPaymentType: 'ROLLING_MONTHLY',
                                            });
                                        }}
                                    />
                                    {' '}
                                    Rolling Monthly Retainer
                                </Label>
                            </FormGroup>
                        </Form>

                    </div>
                </InputSection>

                {
                    firstPayment && (
                        <>
                            <InputSection
                                index={3}
                                instruction="How did you manage to secure a meeting with this client?"
                            >
                                <Input
                                    placeholder="I secured a meeting by..."
                                    className="bg-transparent p-4 inputBox w-full"
                                    style={{borderBottomColor: "#434343"}}
                                    type="textarea"
                                    onChange={e => updateUserPaymentData({
                                        securingMeetingStrategy: e.target.value,
                                    })}
                                />
                            </InputSection>

                            <InputSection
                                index={4}
                                instruction="What was your sales process like? One call, two, many?"
                            >
                                <Input
                                    placeholder="My sales process involved..."
                                    className="bg-transparent p-4 inputBox w-full"
                                    style={{borderBottomColor: "#434343"}}
                                    type="textarea"
                                    onChange={e => updateUserPaymentData({
                                        salesProcessStrategy: e.target.value,
                                    })}
                                />
                            </InputSection>

                            <InputSection
                                index={5}
                                instruction="What objections (if any) did they have and how did you overturn them?"
                            >
                                <Input
                                    placeholder="Some of the objections I received are..."
                                    className="bg-transparent p-4 inputBox w-full"
                                    style={{borderBottomColor: "#434343"}}
                                    type="textarea"
                                    onChange={e => updateUserPaymentData({
                                        receivedObjections: e.target.value,
                                    })}
                                />
                            </InputSection>

                            <InputSection
                                index={6}
                                instruction="What is one piece of advice you would share this week with the community?"
                            >
                                <Input
                                    placeholder="One piece of advice I would love to share..."
                                    className="bg-transparent p-4 inputBox w-full"
                                    style={{borderBottomColor: "#434343"}}
                                    type="textarea"
                                    onChange={e => updateUserPaymentData({
                                        communityAdvice: e.target.value,
                                    })}
                                />
                            </InputSection>
                        </>
                    )
                }

                {
                    firstPayment
                    && (

                            <div
                                 className="custom-control custom-control-alternative custom-checkbox remember-box flex flex-row items-center"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    margin: '0 0 1rem 1rem',
                                }}
                            >
                                <input
                                    // checked={autoPlay}
                                    className="styled-checkbox"
                                    id="customCheckLogin2"
                                    type="checkbox"
                                    onClick={e => updateUserPaymentData({
                                    isAuthorised: e.target.checked,
                                })}
                                />
                                <label for="customCheckLogin2" className="body4 text-white">
                                        I authorise GrowYourAgency to share my amazing client win with the community to help other students on their journey & so we can all celebrate wins together.
                                </label>
                            </div>
                    )
                }

                <LoadingButton
                    className="blkButton h-fit px-4 py-2 mt-12"
                    type="button"
                    disabled={createPaymentDisabled}
                    loading={loading}
                    onClick={onCreatePayment}
                >
                    Create Payment
                </LoadingButton>
            </div>
        </Modal>
    );
};

NewPaymentModal.propTypes = {

};

export default NewPaymentModal;
