import React from 'react';
import './style.scss';
import Container from 'reactstrap/lib/Container';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';

const sections = [
    {
        available: true, sectionName: 'What is is',
    },
    {
        available: true, sectionName: 'Non Negotiables',
    },
    {
        available: true, sectionName: 'Other Variables You Can Add',
    },
    {
        available: true, sectionName: 'Tracking',
    },
];

const title = "Monk Mode"

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div ref={refs[0]}  className="plug-block">  
                        <h2 className="mb-4">{title}</h2>
                            <div className="plug-block-subheading">What it is:</div>

                            <p>Monk mode is a period of immersion that you commit to. Unlike other protocols, you create your own variables aside from the few that are set for you. There are 3 variables that you need to abide by no matter what and then 2 which you select your own.</p>

                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">Non Negotiables:</div>

                            <div className="examples-block">
                                <ol>
                                    <li><i><span className="bold">Meditation (10m Per Day) -</span>
                                    {' '}
                                    You can do any kind you like, but it must be AT LEAST 10 minutes and it must be everyday.</i></li>

                                    <li><i><span className="bold">Exercise (30m Per Day) - </span>
                                    {' '}
                                    This can be a gym workout, yoga, run, basketball or even a brisk walk. Point is to get your heart rate up. It must be everyday. On days where you fear overtraining, do a long walk.</i></li>

                                    <li><i><span className="bold">No Alcohol or Weed -</span>
                                    {' '}
                                    Simple. Not even a beer. Cut it</i></li>
                                </ol>
                            </div>

                        </div>
                        <div ref={refs[2]} className="plug-block">
                            <div className="plug-block-subheading">Other Variables You Can Add:</div>

                            <ul>
                                <li>No caffeine (a personal favourite).</li>
                                <li>Stick to a diet (personal favourite & I do keto).</li>
                                <li>Pray x1 a day (personal favourite).</li>
                                <li>Do ‘x’ amount of ‘x type’ of outreach.</li>
                                <li>Screen time of less than ‘x’ per day.</li>
                                <li>Delete Instagram off phone & computer.</li>
                            </ul>

                        </div>
                        <div ref={refs[3]} className="plug-block">
                            <div className="plug-block-subheading">Tracking:</div>

                            <p>Write down your monk mode protocol on a whiteboard or save it as your desktop/wallpaper. Look at it every single day and at the end of each day keep a track of how many days' streak you have accomplished. Aim for 21 days in your first cycle. </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};