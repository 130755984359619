import React from 'react';
import moment from 'moment';
import { parseInt, sortBy } from 'lodash';
import { Col, Row } from 'reactstrap';

const dateFormat = 'dddd, MMMM Do YYYY';
const Index = ({ events, year, selectEvent }) => {
    const eventsArray = sortBy([
        ...events.filter(({ startDateTime }) => moment(startDateTime).year() === parseInt(year)),
    ], 'startDateTime');

    return (
        <Row style={{ margin: '0 1.5rem 3rem 1.5rem' }}>
            {eventsArray.map((event, i) => (
                <Col key={i} xs={12} sm={12} md={4} lg={4} style={{ padding: 10 }}>
                    <div className="event-list-item" onClick={() => selectEvent(event)}>
                        <div
                            className="event-colour-icon"
                            style={{
                                background: event.colour,
                            }}
                        />
                        <div
                            className="event-list-content"
                        >
                            <div>{event.title}</div>
                            <div className="event-list-day">
                                {`${moment(event.startDateTime).format(dateFormat)}${
                                    moment(event.startDateTime).isSame(moment(event.endDateTime), 'day')
                                        ? '' : ` to ${moment(event.endDateTime).format(dateFormat)}`
                                }`}
                            </div>
                        </div>
                    </div>
                </Col>
            ))}
        </Row>
    );
};

export default Index;
