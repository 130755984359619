import React, { useState } from 'react';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import { useMutation, useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import { parseInt, uniqWith } from 'lodash';
import ObjectivesList from './components/ObjectivesList';
import styles from './styles';
import GetCalendarEntriesQuery from '../../../../../../api/getCalendarEntries';
import CreateUpdateCalendarEntryMutation from '../../../../../../api/createUpdateCalendarEntry';

const MonthObjectives = ({
    classes, tasks, toggleTask, selectEvent, monthDate, year, staticView = false,
}) => {
    const {
        data: calendarResponseData,
        error: calendarEntriesError,
        loading: calendarEntriesLoading,
    } = useQuery(GetCalendarEntriesQuery, {
        variables: {
            entryType: 'goal',
        },
    });

    const calendar = calendarResponseData?.getCalendarEntries?.calendar || {};
    const calendarSummaries = (calendarResponseData?.getCalendarEntries?.data || []).map(({ startDateTime, endDateTime, ...rest }) => ({
        ...rest,
        startDateTime: new Date(startDateTime),
        endDateTime: new Date(endDateTime),
    }));

    const monthlyObjectives = calendarSummaries.filter(({ summaryJSON }) => JSON.parse(summaryJSON || '{}')?.goalType === 'monthlyObjective');

    const [createUpdateCalendarEntry] = useMutation(CreateUpdateCalendarEntryMutation, {
        update(cache, { data: { createUpdateCalendarEntry: { data: createUpdateResponseData } } }) {
            const readResults = cache.readQuery({
                query: GetCalendarEntriesQuery,
                variables: {},
            });

            const updatedEntry = {
                getCalendarEntries: {
                    ...readResults.getCalendarEntries,
                    data: uniqWith([
                        createUpdateResponseData,
                        ...readResults.getCalendarEntries.data,
                    ], ({ PK, SK }, { PK: PK2, SK: SK2 }) => PK === PK2 && SK === SK2),
                },
            };

            cache.writeQuery({
                query: GetCalendarEntriesQuery,
                data: {
                    ...updatedEntry,
                },
            });
        },
        awaitRefetchQueries: true,
        refetchQueries: [{
            query: GetCalendarEntriesQuery,
            variables: {
                entryType: 'goal',
            },
        }],
    });

    const [taskFilter, setTaskFilter] = useState('All');

    const createMonthObjective = (toDo, onComplete = () => {}) => {
        const { __typename, isNew, ...noteEvent } = toDo;

        const extraQueryParams = {
            optimisticResponse: {
                createUpdateCalendarEntry: {
                    calendar,
                    data: { __typename, ...toDo },
                    error: null,
                    __typename: 'CalendarEntryResponse',
                },
            },
        };


        const variables = {
            entry: isNew ? {
                startDateTime: noteEvent.startDateTime,
                endDateTime: noteEvent.endDateTime,
                entryType: noteEvent.entryType,
                title: noteEvent.title,
                colour: noteEvent?.colour,
                allDay: noteEvent?.allDay || false,
                complete: noteEvent?.complete || false,
                summaryJSON: noteEvent?.summaryJSON,
            } : noteEvent,
        };

        const query = {
            variables,
            ...(isNew ? { } : extraQueryParams),
        };


        console.log('query', query);
        createUpdateCalendarEntry(query).then(onComplete);
    };

    return (
        <Paper className={classes.paper}>
            <div style={{
                padding: '0.5rem',
                textAlign: 'center',
                color: 'white',
                fontWeight: 'bold',
                backgroundColor: '#1C1C1C',
                borderTopLeftRadius: '0.25rem',
                borderTopRightRadius: '0.25rem',
            }}
            >
                <Typography className={classes.todoTitle} variant="h4">
                    Monthly Objectives
                </Typography>
            </div>

            {
                staticView
                    ? (
                        <div
                            className="info-text"
                            style={{
                                padding: '1rem 1rem 0 1rem',
                            }}
                        >
                            Your current monthly objectives, navigate to the month to add or update any objectives.
                        </div>
                    )

                    : (
                        <div
                            className="info-text"
                            style={{
                                padding: '1rem 0.5rem 0.5rem 0.5rem',
                            }}
                        >
                            Track a monthly objective by providing an objective title and then tapping the
                            {' '}
                            <i>Enter</i>
                            {' '}
                            key.
                        </div>
                    )
            }
            <ObjectivesList
                staticView={staticView}
                year={year}
                monthDate={monthDate}
                tasks={monthlyObjectives}
                createMonthObjective={createMonthObjective}
                taskFilter={taskFilter}
                toggleTask={toggleTask}
                selectEvent={selectEvent}
            />
        </Paper>
    );
};

export default withStyles(styles)(MonthObjectives);
