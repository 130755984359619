import React from 'react';
import ReactGA from 'react-ga4';

export const trackEvent = (...params) => ReactGA.event(...params);

export const trackPageView = (path, label) => ReactGA.send(
    {
        hitType: 'pageview',
        page: path,
        title: label,
    },
);


export default { trackEvent, trackPageView };
