import React from 'react';
import './style.scss';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { Link } from 'react-router-dom';
import Container from 'reactstrap/lib/Container';
import PlugAndPlayNavigationPane from '../components/PlugAndPlayNavigationPane2';
import PlugAndPlayWrapper from './PlugAndPlayWrapper';

const sections = [
    {
        available: true, sectionName: 'Introduction',
    },
    {
        available: true, sectionName: 'Reporting',
    },
    {
        available: true, sectionName: 'Subject lines',
    },
    {
        available: true, sectionName: 'Icebreakers/ Personalisation',
    },
    {
        available: true, sectionName: 'Mail-Merge',
    },
    {
        available: true, sectionName: 'Segue',
    },
    {
        available: true, sectionName: 'Hooks',
    },
    {
        available: true, sectionName: 'CTAs',
    },
    {
        available: true, sectionName: 'Sign-Offs',
    },
    {
        available: true, sectionName: 'Follow-Ups/ Cadence/ Frequency',
    },
];

const title = "How To Create Your Email Script"

export default () => {
    const refs = sections.map(item => React.createRef(item.sectionName));

    const scrollTo = (index) => {
        if (refs[index].current) {
            refs[index].current.scrollIntoView();
        }
    };

    return (
        <PlugAndPlayWrapper title>
            <Container className="text-white">
                <Row style={{ marginTop: '2rem' }} className="flex flex-row">
                    <Col md={3} style={{}} className="hidden sm:block w-full sm:w-1/3">
                        <PlugAndPlayNavigationPane
                                // selectedSection={selectedSection}
                                // setSelectedSection={onSelectSection}
                                // filter={filter}
                                // selectFilter={selectFilter}
                                // subFilter={subFilter}
                                // selectSubFilter={selectSubFilter}

                                sections={sections}
                                scrollTo={scrollTo}
                                title="Links"
                        />
                    </Col>
                    <Col md={9} className="raised shadow w-full sm:w-2/3">
                        <div className="plug-block">  
                        <h2 className="mb-4">{title}</h2>       

                            <p>
                                What you’ll need:

                            </p>

                            <u>
                                <li><Link to="/resources/plug-and-play/warming-up-your-email-address">A warmed-up email address</Link></li>
                            </u>
                        </div>
                        <div ref={refs[0]} className="plug-block">
                            <div className="plug-block-subheading">
                                Introduction

                            </div>

                            <p>
                                There is no such thing as the perfect email script. And whilst you will find numerous examples of outreach scripts inside Agency Navigator, it’s important to note that even though one agency owner might achieve high open, click-through and response rate with one script, another might fail to achieve a single open.
                            </p>

                            <p>
                                There are multiple variables at work outside of the script including:
                            </p>

                            <ul>
                                <li>Niche</li>
                                <li>
                                    Service offering
                                </li>
                                <li>
                                    Lead quality
                                </li>
                                <li>Geography</li>
                                <li>
                                    Sending times
                                </li>
                            </ul>

                            <p>
                                So it’s much more important to understand the basics and fundamentals of creating a script rather than just following or importing someone else’s template.
                            </p>
                        </div>

                        <div ref={refs[1]} className="plug-block">
                            <div className="plug-block-subheading">Reporting</div>

                            <p>
                                The success of an email script is measured in three ways:

                            </p>

                            <ul>
                                <li>
                                    Open Rate
                                </li>
                                <li>
                                    Click-Through-Rate
                                </li>
                                <li>
                                    Response Rate
                                </li>
                            </ul>

                            <p>
                                Whilst these are the easiest and most obvious indicators of the success of an email script, and are listed in order of importance, you may find variations within this: for example, emails with lower open rates which yield greater response rates.
                            </p>
                        </div>

                        <div ref={refs[2]} className="plug-block">
                            <div className="plug-block-subheading">Subject lines</div>

                            <p>
                                As mentioned above, subject lines are the most important element of any email script.

                            </p>

                            <p>
                                Most email inboxes are deluged with thousands of emails a month from friends, family, and colleagues as well as updates for the hundreds of platforms they subscribe to, stores they shop from, and long-forgotten newsletters they once read.
                            </p>

                            <p>
                                The result is that unlike text messages, the majority of emails go unopened.

                            </p>

                            <p>
                                So standing out in someone’s email inbox comes down to three things:
                            </p>

                            <ul>
                                <li>
                                    Familiarity
                                </li>
                                <li>Urgency</li>
                                <li>
                                    Pattern Interrupts
                                </li>
                            </ul>

                            <p>
                                <span className="bold">Familiarity</span>
                                {' '}
                                is difficult to achieve with just your subject line. But to achieve it, ask the question: what would an email subject line from your mum or best friend look like?

                            </p>

                            <p>
                                Example:
                            </p>

                            <div className="examples-block">
                                <p>
                                    Can you check x
                                </p>
                                <p>
                                    We NEED this
                                </p>
                                <p>
                                    Drinks tomorrow??
                                </p>
                            </div>

                            <p>
                                <span className="bold">Urgency</span>
                                {' '}
                                is more straightforward but is easy to get wrong and can come across as spammy.

                            </p>

                            <p>
                                Example:
                            </p>

                            <div className="examples-block">
                                <p>Urgent: FAO %First.Name%</p>
                                <p>
                                    [Response Needed]
                                </p>
                                <p>
                                    Problem Detected: Fix Immediately
                                </p>
                            </div>

                            <p>
                                <span className="bold">Pattern Interrupts</span>
                                {' '}
                                are the most useful tool to deploy with subject lines. They require something which interrupts the pattern of the individual scanning their inbox. This can be achieved with unusual formatting, a select choice of words, numbers, symbols or something even more unusual. Importantly, these can be combined with genuine subject lines. These are less likely to wind people up or make them think they have been tricked (which can lead to an instant dismissal of an email once it is opened).
                            </p>

                            <p>Example:</p>

                            <div className="examples-block">
                                <p>
                                    [Coffee] - %First.Name% do you have time?
                                </p>
                                <p>
                                    only 16.3 seconds left :/
                                </p>
                                <p>
                                    we need to talk
                                </p>
                            </div>

                            <p>
                                <span className="bold">
                                    Note:
                                </span>
                                {' '}
                                these are not mutually exclusive and can be combined.
                            </p>
                        </div>


                        <div ref={refs[3]} className="plug-block">
                            <div className="plug-block-subheading">
                                Icebreakers/ Personalisation
                            </div>

                            <p>
                                Icebreakers are the first thing that your recipients will read. They are incredibly-important and vital not just to capture their attention, but to avoid the perception that your email has been sent out to multiple accounts.

                            </p>

                            <p>
                                Consider this email:

                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/how-to-create-your-email-script-1-57622f4f-jpg.jpg" alt="" style={{ marginBottom: '1rem' }} />

                            <p>
                                The company sending this has attempted to include an icebreaker after their initial introduction: ‘My team really likes what you do’.
                            </p>

                            <p>
                                This comes across as insincere and completely unpersonalised.
                            </p>

                            <p>
                                Now consider:
                            </p>

                            <img src="https://img.genflow.com/gya/plugandplays/how-to-create-your-email-script-2-7a5c2a2e-jpg.jpg" alt="" style={{ marginBottom: '1rem' }} />

                            <p>
                                This icebreaker suggests that the individual has actually gone to the lengths to watch Iman Gadzhi’s YouTube channel, binge watch its content, and compliment a specific video. This is far more likely to engender a response - or even the recipient reading the rest of the email.
                            </p>

                            <p>
                                Note: Icebreakers/ Personalisation is time-consuming.
                                {' '}
                                <Link to="/resources/plug-and-play/email-personalisation">Read our full guide to them here</Link>

                                .
                            </p>
                        </div>

                        <div ref={refs[4]} className="plug-block">
                            <div className="plug-block-subheading">
                                Mail-Merge
                            </div>

                            <p>
                                Automated email scripts should always include mail-merges.
                            </p>

                            <p>
                                This is
                                {' '}
                                <span style={{ textDecoration: 'underline' }}>vital</span>
                                {' '}
                                for the greeting salutation (Hi Iman) and
                                {' '}
                                <span style={{ textDecoration: 'underline' }}>useful-but-not-essential</span>
                                {' '}
                                for the body copy.
                            </p>

                            <p>
                                Emails should always begin with ‘Hi/Hey/ Greetings (First/Name)’. If you are using manual email outreach, this is easy to input. If you are using LemList, make sure to use their automated mail merge inputs. They are formatted as: [[firstName]].
                            </p>

                            <p>
                                In the body copy of the email, it is tempting to continue to use mail-merge to create the illusion of personalisation, and whilst this is useful, it can also backfire.
                            </p>

                            <p>
                                Example:
                            </p>

                            <div className="examples-block">
                                <p>
                                    And I hate to break it to you
                                    [[firstName]]
                                    , but I’m seeing a lot of these problems in your business.
                                </p>
                            </div>

                            <p>
                                This comes across as creepy, patronising and over-familiar. As a general rule, if you wouldn’t write something in an email to a friend, family member or colleague, you shouldn’t write it in an outreach email.
                            </p>

                            <p>
                                But it’s possible to use mail merge within other elements of the body copy.
                            </p>

                            <p>
                                Example:
                            </p>

                            <div className="examples-block">
                                <p>
                                    Pretty much every single one of [[companyName]]’s competitors are running Facebook ads.
                                </p>
                            </div>

                            <p>
                                This can be applied across hundreds of leads and does not appear overly-familiar but instead creates the impression that you have done your due diligence in researching your recipient’s business.
                            </p>
                        </div>

                        <div ref={refs[5]} className="plug-block">
                            <div className="plug-block-subheading">
                                Segue
                            </div>

                            <p>
                                The segue is a small but important part of your email.
                            </p>

                            <p>
                                It goes in between the icebreaker and the hook and it’s your way of conveying authentically how you came to be emailing the recipient.
                            </p>

                            <p>
                                Example:
                            </p>

                            <div className="examples-block">
                                <p>
                                    ...whilst I was on your website, I noticed you didn’t have a Facebook pixel installed?
                                </p>

                                <p>
                                    We’re looking at wearable tech currently and I was genuinely excited by the prospect of working with you.
                                </p>
                            </div>
                        </div>

                        <div ref={refs[6]} className="plug-block">
                            <div className="plug-block-subheading">
                                Hooks
                            </div>

                            <p>
                                Hooks are more accurately expressed as ‘Why Am I Sending This Email’ (WAISTE).
                            </p>

                            <p>
                                After your salutation and icebreaker, it’s time to explain to your recipient why you emailed them in the first place.
                            </p>

                            <p>
                                There are two hooks you can use in your email:
                            </p>

                            <ul>
                                <li>
                                    <span className="bold">Problem:</span>
                                    {' '}
                                    You explain that the recipient has a problem. For example, they don’t have a Facebook pixel installed, they do but they aren’t running Facebook ads, they aren’t running enough variations of Facebook ads, they have a newsletter but aren’t sending out regular emails, they don’t have a cart abandonment email, their CRO needs improvement etc.
                                </li>
                                <li>
                                    <span className="bold">Pitch:</span>
                                    {' '}
                                    You have an opportunity that the recipient needs to avail themselves of (for example, an offer, relevance to their industry), an outstanding case study, a ‘secret trick’ to achieving great results
                                </li>
                            </ul>

                            <p>
                                <span className="bold">Problem emails</span>
                                {' '}
                                are the bread and butter of email outreach. As discussed previously, it’s important to test each and every email you send with the following question: would you send it to a family member, a friend, or colleague? Problem emails pass this test easily: you would point out to someone you were close with an issue that they had with their website.
                            </p>

                            <p>
                                <span className="bold">Pitch emails</span>
                                {' '}
                                are more difficult to get right. They require impressive results or a timely opportunity. For example, if you sent an email letting someone know that, momentarily, you could get their Instagram account verified, or increase the conversion rate of their website by a high percentage, this would be a compelling enough hook.
                            </p>
                        </div>

                        <div ref={refs[7]} className="plug-block">
                            <div className="plug-block-subheading">
                                CTAs
                            </div>

                            <p>Call-to-Actions (CTAs) are the main objective of your email. They include:</p>

                            <ul>
                                <li>
                                    Asking the recipient to reply and affirm they are interested in receiving further correspondence, for example a Loom, link to a case study, or link to book call (Affirmation CTA)
                                </li>
                                <li>
                                    Asking the recipient to click through to your Calendly/ Acuity and make an appointment (Book a Call CTA)
                                </li>
                                <li>
                                    Asking the recipient to click through to your website to watch a case study  (Case Study CTA)
                                </li>
                            </ul>

                            <p>
                                CTAs are controversial in the world of cold emails and many people recommend that you only include CTAs after the first email in the chain or only use the affirmation CTA, and then follow-up with something that requires more commitment.
                            </p>

                            <p>
                                This is a personal decision but you should be aware that for most people, if they receive an email and they have to take further action to achieve information, they will most likely not do so.
                            </p>

                            <p>
                                Therefore it’s wise to include at least one obvious CTA that can be re-referred to in follow-up emails.
                            </p>
                        </div>

                        <div ref={refs[8]} className="plug-block">
                            <div className="plug-block-subheading">
                                Sign-Offs

                            </div>

                            <p>
                                They might seem incredibly straightforward but sign-offs form a vital part of microcommunication that will determine the way in which your email is interpreted.
                            </p>

                            <p>Keep it semi-formal, friendly and non-sycophantic for example:</p>

                            <div className="examples-block">
                                <p>
                                    Speak soon,
                                </p>
                                <p>Iman</p>
                            </div>
                            <div className="examples-block">
                                {' '}
                                <p>
                                    Thanks,

                                </p>
                                <p>Iman</p>
                            </div>

                            <p>
                                Next, consider a P.S.: People often skip to the end of emails, ignoring the body copy completely. It also serves as a final reminder and opportunity for you to make their response a ‘no-brainer’ or include a reminder for your CTA.
                            </p>
                        </div>

                        <div ref={refs[9]} className="plug-block">
                            <div className="plug-block-subheading">
                                Follow-Ups/ Cadence/ Frequency
                            </div>

                            <p>
                                Automated email scripts tend to follow a four-email format:
                            </p>

                            <p>
                                E0: This is the initial email
                            </p>
                            <p>
                                E1: This is the follow-up to the first email. It’s a polite nudge.
                            </p>
                            <p>
                                E2: This is the explainer email. The one that goes into more detail.
                            </p>
                            <p>
                                E3: This is the ‘break-up’ email. The one that gives the recipient one last chance to respond or take action.
                            </p>

                            <p>
                                These should be sent with the following cadence:
                            </p>

                            <p>
                                E0: Sent on Day 0
                            </p>
                            <p>
                                E1: Sent the day after you sent E0.
                            </p>
                            <p>
                                E2: Sent two days after you sent E1.
                            </p>
                            <p>
                                E3: Sent two days after you sent E2.
                            </p>

                            <p>
                                The follow-ups should be formatted as:
                            </p>

                            <p>
                                E0: Personalised Salutation, Icebreaker, Segue, Hook, CTA, Sign-Off
                            </p>

                            <p>
                                E1: Most automated email sequences receive a response to either E0 or E1. E1’s purpose is to provide a polite ‘nudge’ to the recipient: they might have already read they email, or intended to reply but haven’t done so yet. It serves a similar purpose to a retargeting advert.
                            </p>

                            <p>
                                E2: This email is your last chance to provide a thoughtful, considered argument for following through with your CTA. It should be longer-form, refer more to statistics, a narrative, competitors, and emphasise the ease and lack of commitment with following through to your CTA.
                            </p>

                            <p>
                                E3: This is a ‘breakup’ email. 90% of the time, it won’t be responded to but it is your final chance to establish your credibility and leave a long-lasting impression on leads. This should be short and stress that it is the severance of the relationship you have with your client - and that they are missing out by not contacting you again.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </PlugAndPlayWrapper>
    );
};
